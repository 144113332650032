import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TradabaseData } from "./interface";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import NumberFormatComponent from "../../components/NumberFormatComponent";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import {
  applyPartialTradabaseOrders,
  captureTradabaseOrder,
  getTradabaseOrdersDetail,
} from "../../services/tradabase-orders";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../components/ControlledTextField";
import AdminModalComponent from "../../components/AdminModalComponent";
import { Box } from "@mui/material";
import PermissionWrapper from "../../components/PermissionWrapper";
import {
  capturePartialPayment,
  createPaymentRequest,
  getPartialPayment,
} from "../../services/surplus-allocation";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { Sidebar } from "../../components/Sidebar";
import { useParams } from "react-router-dom";
import { convertDateObjectToString } from "../../services/common";
import { Helmet } from "react-helmet";
const schema = yup.object().shape({
  paymentFirst: yup.string(),
  paymentSecond: yup.string(),
});

const useStyles = makeStyles({
  captureButton: {
    background: "#69cdae!important",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  confirmButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  cardItem: {
    minHeight: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    background: "white",
    borderRadius: 10,
    padding: 20,
    width: "100%",
  },
  headerTitles: {
    fontSize: 20,
  },
  produceItem: {
    minHeight: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    background: "#f8f8f8",
    borderRadius: 10,
    padding: 20,
    width: "100%",
  },
});

const TradabaseOrderDetail = ({
  isFetch,
  setIsFetch,
  setShowDetail,
}: {
  isFetch: number;

  setSelectedOrder?: React.Dispatch<
    React.SetStateAction<TradabaseData | undefined>
  >;
  setIsFetch: React.Dispatch<React.SetStateAction<number>>;
  order?: TradabaseData;
  setShowDetail: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const { id } = useParams();
  const classes = useStyles();

  const watchFirstPayment = watch("paymentFirst");
  const watchSecondPayment = watch("paymentSecond");

  const [order, setSelectedOrder] = useState<TradabaseData>();
  const [detailLoading, setDetailLoading] = useState(false);
  const [showCreatePaymentModal, setShowCreatePaymentModal] =
    useState<boolean>(false);
  const [isCreatingPayment, setIsCreatingPayment] = useState<boolean>(false);
  const [isFirstCaptureDisabled, setIsFirstCaptureDisabled] = useState(false);
  const [isSecondCaptureDisabled, setIsSecondCaptureDisabled] = useState(false);
  const [captureLoading, setCaptureLoading] = useState(false);
  const [selectedPartialId, setSelectedPartialId] = useState("");
  const [selectedPartialType, setSelectedPartialType] = useState("");
  const [openCaptureConfirmModal, setOpenCaptureConfirmModal] = useState(false);
  const [partialLoading, setPartialLoading] = useState(false);
  const isDefaultPartialCheckedMemoized = useMemo(() => {
    return order?.offerPayment
      .map((i) => i.status)
      .some(
        (i) => i === "CAPTURED" || i === "BUYER_PAID" || i === "SELLER_PAID"
      );
  }, [order?.offerPayment.length, order, isFetch]);

  const totalPaymentRequestAmount = useMemo(() => {
    return order?.offerPayment
      .filter((i) => i.status !== "CANCELLED")
      .map((item) => item.amount)
      .reduce((prev, curr) => prev + curr, 0);
  }, [order?.offerPayment.length, order]);

  const cannotEditProduceOrCannotDiscountOrCreatePayment =
    order?.offer?.[0]?.status === "COMPLETED";

  useEffect(() => {
    if (id) {
      const getDetails = async () => {
        setDetailLoading(true);
        const response = await getTradabaseOrdersDetail(id);
        setDetailLoading(false);
        if (response?.success) {
          setSelectedOrder && setSelectedOrder(response?.data[0]);
        }
      };
      getDetails();
    }
  }, [id]);

  useEffect(() => {
    if (order?.offerPayment[0]?.amount) {
      setValue(
        "paymentFirst",
        order?.offerPayment[0]?.amount.toFixed(2).toString()
      );
    }
  }, [order?.offerPayment[0]?.amount]);

  useEffect(() => {
    if (
      parseFloat(watchFirstPayment || "") >
      (order?.offer[0]?.grandTotalAmount as any)
    ) {
      setValue("paymentFirst", (0).toString());
    }
  }, [watchFirstPayment]);

  useEffect(() => {
    if (watchFirstPayment) {
      setValue(
        "paymentSecond",
        (
          (order?.offer[0]?.grandTotalAmount as any) -
          (parseFloat(watchFirstPayment) as any)
        )
          .toFixed(2)
          .toString()
      );
    } else {
      setValue(
        "paymentSecond",
        order?.offer[0]?.grandTotalAmount.toFixed(2).toString()
      );
    }
  }, [watchFirstPayment]);

  const onGoBack = () => {
    window.location.href = "/tradabase-orders";
  };

  const onOpenCaptureModal = (id: string, type: string) => {
    setSelectedPartialType(type);
    setOpenCaptureConfirmModal(true);
    setSelectedPartialId(id);
  };

  const onOpenCreatePaymentModal = () => {
    setShowCreatePaymentModal(true);
  };

  const onCloseCreatePaymentModal = () => {
    setShowCreatePaymentModal(false);
  };

  const onConvertToFruPay = async () => {
    setIsCreatingPayment(true);
    const response = await createPaymentRequest(order?.offer?.[0]?._id || "");
    setIsCreatingPayment(false);
    if (response?.success) {
      setShowCreatePaymentModal(false);
      setDetailLoading(true);
      const response = await getTradabaseOrdersDetail(order?._id);
      setDetailLoading(false);
      if (response?.success) {
        setSelectedOrder && setSelectedOrder(response?.data[0]);
      }
    }
  };

  const onCaptureTrade = useCallback(async () => {
    // const finalData = {
    //   invoiceId: order?.invoice[0]?._id,
    //   type: "ISSUED",
    //   refs: order?.offer?.[0]?.refs,
    //   tradabase_webhook_dataId: order?._id,
    // };

    setCaptureLoading(true);
    const response = await capturePartialPayment(selectedPartialId || "");

    setCaptureLoading(false);
    if (response?.success) {
      setOpenCaptureConfirmModal(false);
      setDetailLoading(true);
      const response = await getTradabaseOrdersDetail(order?._id);
      setDetailLoading(false);
      if (response?.success) {
        setSelectedOrder && setSelectedOrder(response?.data[0]);
      }
    }
  }, [order, selectedPartialId]);

  const onApplyPartialPayment = useCallback(async () => {
    if (
      parseFloat(watchFirstPayment || "") === 0 ||
      parseFloat(watchSecondPayment || "") === 0
    ) {
      toast.error("Partial payment amount cannot be zero");
    } else {
      if (!order?.offerPayment.length) {
        toast.error("There are no offer payment found");
      } else {
        setPartialLoading(true);
        const finalData = {
          offerId: order?.offer[0]?._id,
          partialPayment: [
            {
              amount: parseFloat(watchFirstPayment || ""),
            },
            {
              amount: parseFloat(watchSecondPayment || ""),
            },
          ],
        };

        const applied = await applyPartialTradabaseOrders(finalData);
        setPartialLoading(false);

        if (applied) {
          setDetailLoading(true);
          const response = await getTradabaseOrdersDetail(order?._id);
          setDetailLoading(false);
          if (response?.success) {
            setSelectedOrder && setSelectedOrder(response?.data[0]);
          }
        }
      }
    }
  }, [order, watchFirstPayment, watchSecondPayment]);

  const renderCreatePaymentModalBody = () => {
    return (
      <>
        <div className="container">
          <span>
            Are you sure you want to create payment request for this order?
          </span>
          <div className={classes.filterModalButtons}>
            <AdminButtonComponent
              disabled={isCreatingPayment}
              className={classes.closeButton}
              onClick={onCloseCreatePaymentModal}
              title="Close"
            />{" "}
            <AdminButtonComponent
              disabled={isCreatingPayment}
              loading={isCreatingPayment}
              className={classes.confirmButton}
              onClick={onConvertToFruPay}
              title="Confirm"
            />
          </div>
        </div>
      </>
    );
  };

  const renderCaptureConfirmModalBody = () => {
    return (
      <>
        Are you sure you want to capture?
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={captureLoading}
            className={classes.closeButton}
            onClick={() => setOpenCaptureConfirmModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={captureLoading}
            disabled={captureLoading}
            className={classes.saveButton}
            onClick={onCaptureTrade}
            title="Submit"
          />
        </Box>
      </>
    );
  };

  return (
    <Sidebar pageTitle="Details">
       <Helmet>
          <title>Tradabase Detail | FruPro Admin Portal</title>
        </Helmet>
      <div className="p-2">
        {/* {captureLoading ? null : (
        <div onClick={onGoBack} style={{ cursor: "pointer" }}>
          <i className="ri-arrow-left-line"></i> <span>Go back</span>
        </div>
      )} */}

        <div className="row mt-1  g-3">
          {detailLoading ? (
            <Skeleton height={200} />
          ) : (
            <div className="col-md-6">
              <div className={classes.cardItem}>
                <span className={classes.headerTitles}>Tradabase</span>

                <hr className="mt-2" style={{ opacity: 0.07 }} />
                <div className="d-flex mt-2  flex-column gap-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Buyer : </span>
                    <span> {order?.webhookData.buyerCompany.companyName}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Seller : </span>
                    <span> {order?.webhookData.sellerCompany.companyName}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Owner : </span>
                    <span> {order?.webhookData.owners[0].fullname}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Delivery Date : </span>
                    <span>
                      {convertDateObjectToString(
                        order?.webhookData?.deliveryDate
                      )}
                    </span>
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Reference : </span>
                    <span>{order?.webhookData.tradabaseReference}</span>
                  </div> */}
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Due Date : </span>
                    <span>
                      {convertDateObjectToString(order?.webhookData?.dueDate)}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Total Amount : </span>
                    <span>
                      {order?.webhookData.produces[0].currency.symbol}
                      {
                        <NumberFormatComponent
                          value={order?.webhookData.totalAmount || 0}
                        />
                      }
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">FRUPAY : </span>
                    <span>{order?.webhookData.paymentTerm ? "YES" : "NO"}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Tradabase Ref. : </span>
                    <span>{order?.webhookData.tradabaseReference}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Webhook Data Id :</span>
                    <span>{order?._id}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {detailLoading ? (
            <Skeleton height={200} />
          ) : (
            <div className="col-md-6">
              <div className={classes.cardItem}>
                <span className={classes.headerTitles}>FruPro</span>
                <hr className="mt-2" style={{ opacity: 0.07 }} />
                <div className="d-flex mt-2 flex-column gap-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Buyer : </span>
                    <span> {order?.offer[0].buyerCompany?.companyName}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Seller : </span>
                    <span> {order?.offer[0].sellerCompany?.companyName}</span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Delivery Date : </span>
                    <span>
                      {moment(order?.invoice?.[0]?.deliveryDate).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Invoice Date : </span>
                    <span>
                      {moment(order?.invoice?.[0]?.invoiceDate).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Due Date : </span>
                    <span>
                      {moment(order?.invoice?.[0]?.dueDate).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Total Amount : </span>
                    <span>
                      {order?.webhookData?.produces?.[0]?.currency?.symbol}
                      {
                        <NumberFormatComponent
                          value={order?.offer?.[0]?.totalAmount || 0}
                        />
                      }
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Offer Code : </span>
                    <span>{order?.invoice?.[0]?.offerCode ?? "-"}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Invoice Code : </span>
                    <span>{order?.invoice?.[0]?.code ?? "-"}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Payment Code : </span>
                    <span>{order?.invoice?.[0]?.paymentCode ?? "-"}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">FRUPAY : </span>
                    <span>
                      {order?.offer?.[0]?.useRefactoring ? "YES" : "NO"}
                      {order?.offer?.[0]?.useRefactoring ? null : (
                        <AdminButtonComponent
                          disabled={
                            cannotEditProduceOrCannotDiscountOrCreatePayment
                          }
                          style={{ marginLeft: 10 }}
                          title="Create Payment"
                          onClick={onOpenCreatePaymentModal}
                        />
                      )}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-bold">Partial Payment : </span>
                    <span>
                      {order?.offerPayment?.length &&
                      order?.offerPayment?.length > 1
                        ? "YES"
                        : "NO"}
                    </span>
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-between">
                <span className="fw-bold">Payment Request : </span>
                <span>
                  {order?.webhookData.produces?.[0]?.currency?.symbol}
                  {<NumberFormatComponent value={totalPaymentRequestAmount||0} />}
                </span>
              </div> */}
                </div>
              </div>
            </div>
          )}
          {detailLoading ? (
            <Skeleton height={200} />
          ) : (
            <div className="col-md-12">
              <div className={`${classes.cardItem}`}>
                <span className={classes.headerTitles}>Produces</span>

                <hr className="mt-2" style={{ opacity: 0.07 }} />

                <div className="d-flex flex-column mt-2 gap-2">
                  {order?.webhookData?.produces?.map((p, index) => {
                    return (
                      <div
                        key={index}
                        className={`row ml-1 mt-1 ${classes.produceItem}`}
                      >
                        <div className="col-md-3">{p.name}</div>
                        <div className="col-md-3">{p.itemDescription}</div>
                        <div className="col-md-3">
                          {p.currency.symbol}
                          {<NumberFormatComponent value={p.priceGuide} />}x
                          {p.unit}
                        </div>
                        <div className="col-md-3">
                          Return price : {p.currency.symbol}
                          {
                            <NumberFormatComponent
                              value={p.dealPriceIndication}
                            />
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {detailLoading ? (
            <Skeleton height={200} />
          ) : (
            <div className="col-md-6">
              <div className={classes.cardItem}>
                <span className={classes.headerTitles}>Files</span>

                <hr className="mt-2" style={{ opacity: 0.07 }} />

                <div className="d-flex flex-column mt-2 gap-2">
                  {order?.offer[0].tradabaseFiles.map((item) => {
                    return (
                      <a
                        style={{ textDecoration: "underline" }}
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.fileName}
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {detailLoading ? (
            <Skeleton height={200} />
          ) : (
            <div className="col-md-6">
              <div className={classes.cardItem}>
                <span className={classes.headerTitles}>Payment</span>

                <hr className="mt-2" style={{ opacity: 0.07 }} />

                <div className="d-flex flex-column mt-2 gap-2">
                  <div className="d-flex align-items-center gap-4">
                    <span className="fw-bold">Partial Payment : </span>
                    <span>
                      {order?.offerPayment?.length &&
                      order?.offerPayment?.length > 1
                        ? "YES"
                        : "NO"}
                    </span>
                  </div>
                  <div className="d-flex align-items-center gap-4">
                    <span className="fw-bold">Payment Request : </span>
                    <span>
                      {order?.webhookData.produces?.[0]?.currency?.symbol}
                      {
                        <NumberFormatComponent
                          value={order?.offer[0]?.totalAmount || ""}
                        />
                      }
                    </span>
                  </div>

                  <div className="mt-2 d-flex flex-column gap-3">
                    <div className="col-md-8  d-flex flex-row gap-2 align-items-center">
                      <div className="col-md-6">
                        <ControlledTextField
                          disabled={
                            isDefaultPartialCheckedMemoized ||
                            isFirstCaptureDisabled
                          }
                          placeholder="First partial payment amount"
                          name="paymentFirst"
                          control={control}
                        />
                      </div>
                      <div className="col-md-2">
                        <AdminButtonComponent
                          disabled={
                            !!order?.errorMessage ||
                            parseFloat(watchFirstPayment || "") !==
                              parseFloat(
                                order?.offerPayment[0]?.amount as any
                              ) ||
                            partialLoading ||
                            !order?.offerPayment[0]?._id ||
                            order?.offerPayment[0]?.status === "CAPTURED" ||
                            order?.offerPayment[0]?.status === "BUYER_PAID" ||
                            order?.offerPayment[0]?.status === "SELLER_PAID" ||
                            !order.offer[0]?.useRefactoring
                          }
                          className={classes.captureButton}
                          onClick={() =>
                            onOpenCaptureModal(
                              order?.offerPayment[0]._id || "",
                              "first"
                            )
                          }
                          title={
                            order?.offerPayment[0]?.status === "CAPTURED"
                              ? "Captured"
                              : "Capture"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-8 d-flex flex-row gap-2 align-items-center">
                      <div className="col-md-6">
                        <ControlledTextField
                          disabled
                          placeholder="Second partial payment amount"
                          name="paymentSecond"
                          control={control}
                        />
                      </div>{" "}
                      <div className="col-md-2">
                        <AdminButtonComponent
                          disabled={
                            !!order?.errorMessage ||
                            parseFloat(watchSecondPayment || "") !==
                              parseFloat(
                                order?.offerPayment[1]?.amount as any
                              ) ||
                            partialLoading ||
                            !order?.offerPayment[1]?._id ||
                            order?.offerPayment[1]?.status === "CAPTURED" ||
                            order?.offerPayment[0]?.status === "BUYER_PAID" ||
                            order?.offerPayment[0]?.status === "SELLER_PAID" ||
                            !order.offer[0]?.useRefactoring
                          }
                          className={classes.captureButton}
                          onClick={() =>
                            onOpenCaptureModal(
                              order?.offerPayment[1]._id || "",
                              "second"
                            )
                          }
                          title={
                            order?.offerPayment[1]?.status === "CAPTURED"
                              ? "Captured"
                              : "Capture"
                          }
                        />
                      </div>
                    </div>
                    <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                      <AdminButtonComponent
                        disabled={
                          partialLoading ||
                          isDefaultPartialCheckedMemoized ||
                          !order?.offerPayment.length ||
                          !order.offer[0]?.useRefactoring
                        }
                        loading={partialLoading}
                        title={"Apply partial payment"}
                        onClick={onApplyPartialPayment}
                      />
                    </PermissionWrapper>
                  </div>
                  {/* <hr className="mt-3" />
              <div className="d-flex mt-3 justify-content-end">
                <AdminButtonComponent
                  disabled={
                    isCaptureDisabled ||
                    isDefaultPartialCheckedMemoized ||
                    captureLoading
                  }
                  title={
                    isCaptureDisabled || isDefaultPartialCheckedMemoized
                      ? "Captured"
                      : "Capture trade"
                  }
                  onClick={() => setOpenCaptureConfirmModal(true)}
                />
              </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
        <AdminModalComponent
          children={renderCaptureConfirmModalBody()}
          headerTitle="Are you sure"
          openModal={openCaptureConfirmModal}
          closeModal={() => setOpenCaptureConfirmModal(false)}
        />
        <AdminModalComponent
          children={renderCreatePaymentModalBody()}
          headerTitle="Are you sure"
          openModal={showCreatePaymentModal}
          closeModal={onCloseCreatePaymentModal}
        />
      </div>
    </Sidebar>
  );
};

export default TradabaseOrderDetail;
