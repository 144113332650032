import { yupResolver } from "@hookform/resolvers/yup";
import { Box, TableCell, TableRow, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import ControlledDateField from "../../components/ControlledDateField";
import ControlledTextField from "../../components/ControlledTextField";
import FilterBadge from "../../components/FilterBadge";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import { axiosApiInstance } from "../../services/axios-instance";
import { deleteEmail, getEmails, resendEmail } from "../../services/email";
import ActionMenu from "./components/ActionMenu";
import * as yup from "yup";
import AdminModalComponent from "../../components/AdminModalComponent";
import ControlledComboBox from "../../components/ControlledComboBox";
import { RestartAlt } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useConfirmDialog } from "../AdminGroups/SelectConfirmDialog";
import usePermissions from "../../hooks/usePermissions";
import PermissionWrapper from "../../components/PermissionWrapper";

interface Email {
  _id: string;
  to: string;
  cc: string;
  bcc: string;
  content_type: string;
  status: string;
  subject: string;
  createdAt: string;
  sendTime: string;
}

interface MetaData {
  page: number;
  limit: number;
  totalPage: number;
  total: number;
}

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  pendingChip: {
    background: "#E0A600!important",
    color: "white!important",
    minWidth: 100,
  },
  successChip: {
    color: "white!important",
    background: "#69cdae!important",
    minWidth: 100,
  },
  rejectChip: {
    color: "white!important",
    background: "red!important",
    minWidth: 100,
  },
  normalChip: {
    minWidth: 100,
  },
  successText: {
    fontWeight: 600,
    color: "#097969",
  },
  errorText: {
    fontWeight: 600,
    color: "red",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});

const emailFilterSchema = yup.object().shape({
  receipent: yup.string().nullable(),
  subject: yup.string().nullable(),
  createdAtFrom: yup.string().nullable(),
  createdAtTo: yup.string().nullable(),
  sendTimeFrom: yup.string().nullable(),
  sendTimeTo: yup.string().nullable(),
  status: yup
    .object({
      name: yup.string(),
      id: yup.string(),
    })
    .nullable(),
});

const sortSchema = yup.object().shape({
  sortType: yup.object(),
  sortName: yup.object(),
});

const EmailsContainer = () => {
  const { hasPermission } = usePermissions();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, ...rest },
  } = useForm({
    resolver: yupResolver(emailFilterSchema),
    defaultValues: {
      receipent: "",
      subject: "",
      createdAtFrom: "",
      createdAtTo: "",
      sendTimeFrom: "",
      sendTimeTo: "",
      status: {
        name: "",
        id: "",
      },
    },
  });

  const { control: sortControl, watch: sortWatch } = useForm({
    resolver: yupResolver(sortSchema),
    defaultValues: {
      sortName: { name: "Create Date", id: "createdAt" },
      sortType: { name: "Descending", id: "-" },
    },
  });

  const watchSortType = sortWatch("sortType");

  const classes = useStyles();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterData, setFilterData] = useState<any>({});
  const [isClearFilterKey, setIsClearFilterKey] = useState<number>(0);
  const [deletedFilterKey, setDeletedFilterKey] = useState<string>("");
  const [emails, setEmails] = useState<Email[]>([]);
  const [metaData, setMetaData] = useState<MetaData>({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });
  const [loading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const { ConfirmDialog: DeleteConfirmDialog, openDialog: openDeleteDialog } =
    useConfirmDialog({
      description: "Are you sure you want to delete this email?",
      title: "Delete Email",
    });

  const { ConfirmDialog: ResendConfirmDialog, openDialog: openResendDialog } =
    useConfirmDialog({
      description: "Are you sure you want to resend this email?",
      title: "Resend Email",
    });

  useEffect(() => {
    setValue(`${deletedFilterKey}` as any, undefined);
  }, [deletedFilterKey, isClearFilterKey]);

  const fetchEmails = useCallback(async () => {
    try {
      setLoading(true);
      let _filters = {
        ...filterData,
      };

      if (filterData.createdAtFrom) {
        _filters.createdAt = {
          ..._filters.createdAt,
          from: filterData.createdAtFrom,
        };

        delete _filters.createdAtFrom;
      }

      if (filterData.createdAtTo) {
        _filters.createdAt = {
          ..._filters.createdAt,
          to: filterData.createdAtTo,
        };

        delete _filters.createdAtTo;
      }

      if (filterData.sendTimeFrom) {
        _filters.sendTime = {
          ..._filters.sendTime,
          from: filterData.sendTimeFrom,
        };

        delete _filters.sendTimeFrom;
      }

      if (filterData.sendTimeTo) {
        _filters.sendTime = {
          ..._filters.sendTime,
          to: filterData.sendTimeTo,
        };

        delete _filters.sendTimeTo;
      }

      const response = await getEmails({
        page: metaData.page,
        filters: _filters,
        order: watchSortType,
      });
      const _emails = response.data;
      const _metaData = response.metaData;

      setEmails(_emails);
      setMetaData(_metaData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [metaData.page, filterData, watchSortType]);

  useEffect(() => {
    if (!hasPermission("EMAIL_QUEUE_VIEW")) return;

    fetchEmails();
  }, [fetchEmails, hasPermission("EMAIL_QUEUE_VIEW")]);

  const columns = useMemo(
    () => [
      {
        name: "Create Date",
        id: "createdAt",
      },
      {
        name: "Send Date",
        id: "sendTime",
      },
      {
        name: "Subject",
        id: "subject",
      },
      {
        name: "Receipents",
        id: "receipents",
      },
      {
        name: "Status",
        id: "status",
      },
      {
        name: "Actions",
        id: "actions",
      },
    ],
    []
  );

  const getActions = useCallback(
    (status: string) => {
      const hasTriggerPermission = hasPermission("EMAIL_QUEUE_TRIGGER");
      const hasDeletePermission = hasPermission("EMAIL_QUEUE_DELETE");

      const actions = [];
      switch (status) {
        case "WILL_NOT_BE_SENT":
          hasTriggerPermission &&
            actions.push({
              label: "Send",
              onClick: (id: string) => resendEmail(id),
            });
          break;

        case "PENDING":
          hasTriggerPermission &&
            actions.push({
              label: "Send",
              onClick: (id: string) => resendEmail(id),
            });

          hasDeletePermission &&
            actions.push({
              label: "Delete",
              onClick: (id: string) => deleteEmail(id),
              destructive: true,
            });
          break;

        case "FAILED":
          hasTriggerPermission &&
            actions.push({
              label: "Send",
              onClick: (id: string) => resendEmail(id),
            });
          hasDeletePermission &&
            actions.push({
              label: "Delete",
              onClick: (id: string) => deleteEmail(id),
            });
          break;

        default:
          break;
      }

      return actions;
    },
    [hasPermission("EMAIL_QUEUE_TRIGGER"), hasPermission("EMAIL_QUEUE_DELETE")]
  );

  const handleActions = async (action: any, id: string) => {
    try {
      let userConfirmedAction = false;
      let successMessage = "Action performed successfully.";

      switch (action.label) {
        case "Send":
          userConfirmedAction = await openResendDialog();
          successMessage = "Email has been sent successfully.";
          break;

        case "Delete":
          userConfirmedAction = await openDeleteDialog();
          successMessage = "Email has been deleted successfully.";
          break;

        default:
          break;
      }

      if (!userConfirmedAction) {
        return;
      }

      setIsProcessing(true);
      const actionFunc = action.onClick;
      const response = await actionFunc(id);

      if (!response.success) {
        toast.error("Failed to perform action.");
      }

      toast.success(successMessage);

      fetchEmails();
    } catch (error) {
      console.error(error);
      toast.error("Failed to perform action.");
    } finally {
      setIsProcessing(false);
    }
  };

  const getStatusBgColor = useCallback((status: string) => {
    switch (status) {
      case "PENDING":
        return "bg-yellow-500";

      case "SENT":
        return "bg-green-500";

      case "FAILED":
        return "bg-red-500";

      case "WILL_NOT_BE_SENT":
        return "bg-purple-500";

      default:
        return "bg-gray-500";
    }
  }, []);

  const getStatusText = useCallback((status: string) => {
    switch (status) {
      case "WILL_NOT_BE_SENT":
        return "NOT IN QUEUE";

      default:
        return status;
    }
  }, []);

  const renderTableBody = () => {
    return (
      <>
        {emails.map((e, index) => {
          const actions = getActions(e.status);
          const receipents = e.to.split(",");

          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" className={"text-center"}>
                {e.createdAt
                  ? moment(e.createdAt).format("DD/MM/YYYY HH:mm")
                  : "-"}
              </TableCell>
              <TableCell component="th" scope="row" className={"text-center"}>
                {e.sendTime
                  ? moment(e.sendTime).format("DD/MM/YYYY HH:mm")
                  : "-"}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                width={250}
                className={"text-center"}
              >
                {e.subject ?? "-"}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                width={300}
                className={"text-center"}
              >
                {receipents && receipents.length > 0
                  ? receipents.slice(0, 3).map((r, index) => (
                      <div
                        className="text-md text-slate-600"
                        key={`email-${r}-${index}`}
                      >
                        {r}
                      </div>
                    ))
                  : "-"}
                {receipents.length > 1 && (
                  <Tooltip
                    arrow
                    placement="left-end"
                    title={receipents.map((r, index) => (
                      <div
                        className="text-md"
                        key={`email-detail-${r}-${index}`}
                      >
                        {r}
                      </div>
                    ))}
                  >
                    <div className="text-md underline text-blue-600 cursor-pointer">
                      see all
                    </div>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                width={200}
                className="text-center"
              >
                <div className="flex w-[200px] items-center justify-center">
                  <div
                    className={`flex px-4 py-1 rounded-full font-bold text-white ${getStatusBgColor(
                      e.status
                    )}`}
                  >
                    {e.status ? getStatusText(e.status) : "-"}
                  </div>
                </div>
              </TableCell>
              <TableCell style={{ fontWeight: 600 }} component="th" scope="row">
                <ActionMenu
                  items={actions.map((action) => ({
                    ...action,
                    onClick: () => handleActions(action, e._id),
                  }))}
                  label={
                    <i className="ri-mail-settings-fill text-xl text-gray-500"></i>
                  }
                />
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const onOpenFilterModal = () => {
    setShowFilterModal(true);
  };

  const onCloseFilterModal = () => {
    setShowFilterModal(false);
  };

  const onFilter = (data: any) => {
    setMetaData({
      ...metaData,
      page: 1,
    });
    setShowFilterModal(false);
    setFilterData({
      subject: data?.subject ? data?.subject : undefined,
      receipent: data?.receipent ? data?.receipent : undefined,
      status: data?.status ? data?.status?.id : undefined,
      createdAtFrom: data?.createdAtFrom
        ? moment(data?.createdAtFrom).format("YYYY-MM-DD")
        : undefined,
      createdAtTo: data?.createdAtTo
        ? moment(data?.createdAtTo).format("YYYY-MM-DD")
        : undefined,
      sendTimeFrom: data?.sendTimeFrom
        ? moment(data?.sendTimeFrom).format("YYYY-MM-DD")
        : undefined,
      sendTimeTo: data?.sendTimeTo
        ? moment(data?.sendTimeTo).format("YYYY-MM-DD")
        : undefined,
    });
  };

  const renderFilterModalBody = () => {
    return (
      <>
        <ControlledTextField
          name="subject"
          textLabel="Subject"
          placeholder="Subject"
          control={control}
        />
        <ControlledTextField
          name="receipent"
          textLabel="Receipent"
          placeholder="Receipent (Email)"
          control={control}
        />
        <ControlledComboBox
          name="status"
          textLabel="Status"
          control={control}
          options={[
            { name: "PENDING", id: "PENDING" },
            { name: "SENT", id: "SENT" },
            { name: "FAILED", id: "FAILED" },
            { name: "WILL_NOT_BE_SENT", id: "WILL_NOT_BE_SENT" },
          ]}
        />
        <div className="row">
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Create Date From"
              control={control}
              name="createdAtFrom"
            />
          </div>
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Create Date To"
              control={control}
              name="createdAtTo"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Send Date From"
              control={control}
              name="sendTimeFrom"
            />
          </div>
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Send Date To"
              control={control}
              name="sendTimeTo"
            />
          </div>
        </div>

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setShowFilterModal(false)}
            title="Cancel"
          />
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={handleSubmit(onFilter)}
            title="Submit"
          />
        </Box>
      </>
    );
  };

  return (
    <Sidebar pageTitle="Emails">
      <Helmet>
        <title>Emails | FruPro Admin Portal</title>
      </Helmet>
      <PermissionWrapper permission="EMAIL_QUEUE_VIEW" unauthorizedComponent>
        {(loading || isProcessing) && <LoadingIndicator loading />}

        <TableComponent
          filterBadge={
            <FilterBadge
              isClearFilterKey={isClearFilterKey}
              setIsClearFilterKey={setIsClearFilterKey}
              filterData={filterData}
              setFilterData={setFilterData}
              setDeletedFilterKey={setDeletedFilterKey}
            />
          }
          showFilterIcon
          onOpenModal={onOpenFilterModal}
          control={sortControl}
          isShowSortingTypes
          isShowSortingNames
          sortOptions={[{ name: "CreateDate", id: "createdAt" }]}
          loading={loading}
          TableBodyComponent={renderTableBody}
          showRightButton={false}
          queryParams={metaData}
          setQueryParams={setMetaData}
          columns={columns}
          centerColumnHeaders
        />
        <AdminModalComponent
          children={renderFilterModalBody()}
          headerTitle="Filter Emails"
          openModal={showFilterModal}
          closeModal={onCloseFilterModal}
        />
        {DeleteConfirmDialog}
        {ResendConfirmDialog}
      </PermissionWrapper>
    </Sidebar>
  );
};

export default EmailsContainer;
