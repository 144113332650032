import React from "react";
import { Sidebar } from "../../components/Sidebar";
import { Box } from "@mui/material";
import PermissionWrapper from "../../components/PermissionWrapper";
import SubProductType from "./components/SubProductType";

const SubProductTypesContainer = () => {
  return (
    <Sidebar pageTitle="Subproduct Types">
      <Box sx={{ width: "100%" }}>
        <Box marginTop={2}>
          <PermissionWrapper unauthorizedComponent permission="TRADE_INFO">
            <SubProductType />
          </PermissionWrapper>
        </Box>
      </Box>
    </Sidebar>
  );
};

export default SubProductTypesContainer;
