import { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../components/ControlledTextField";
import ControlledDateField from "../../components/ControlledDateField";
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import dayjs from "dayjs";
import AdminModalComponent from "../../components/AdminModalComponent";
import { toast } from "react-toastify";
import { getAIInvoices } from "../../services/ai-invoices";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import { AIInvoceState, CompaniesStateType, UnitsState } from "./interface";
import ControlledComboBox from "../../components/ControlledComboBox";
import { aiInvoiceSchema } from "./schema";
import {
  CleanString,
  convertProduceName,
  convertToDayJsFormat,
  convertToNumberDecimalString,
  formatNumber,
  formattedStringWithoutCharacterNParanthesis,
  getBuyerSeller,
  getSubTypesWithProductTypeId,
  getSupplierInventories,
  getUnits,
  stringSimilarity,
} from "../../services/common";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import Fuse from "fuse.js";
import { SubProductType } from "../SurplusAllocation/interfaces";
import AddCompanyModalBody from "./AddCompanyModalBody";
import { useNavigate } from "react-router-dom";
import { setAIInvoice } from "../../slices/invoiceSlice";
import { getCompanyById } from "../../services/company";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Helmet } from "react-helmet";

export interface SearchResult {
  companyName: String;
  score: Number;
}

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  textField: {
    background: "#F8F8F8",
    fontSize: 20,
    borderRadius: 10,
    border: "none !important",
    width: "auto",
    height: 100,
    padding: 15,
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red !important",
    },
    "& .MuiInputBase-root": {
      background: "#f8f8f8",
      minHeight: 50,
      borderColor: "black !important",
      border: "none !important",
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  // comboBox: {
  //   background: "transparent",
  //   height: "auto",
  //   fontSize: 16,
  //   width: 150,

  //   "& .MuiInputBase-root": {
  //     background: "#f7f7f7",
  //     minHeight: 50,
  //     fontSize: "1rem",
  //     borderRadius: 10,
  //     overflow: "hidden",
  //     padding: "0 15px",
  //     "&::before": {
  //       display: "none",
  //     },
  //     "&::after": {
  //       display: "none",
  //     },
  //   },
  //   "& .MuiInputBase-input": {
  //     paddingTop: 9,
  //     paddingBottom: 9,
  //   },
  //   "& .MuiInputLabel-root": {
  //     transform: "unset",
  //     fontSize: 12,
  //     color: "black",
  //   },
  //   "& input:-webkit-autofill": {
  //     backgroundColor: `#69CDAE !important`,
  //   },
  //   "&::before": {
  //     borderColor: "#E8E8E8",
  //   },
  //   "& .MuiAutocomplete-tag": {
  //     width: 80,
  //     background: "#69CDAE",
  //     borderRadius: 20,
  //     color: "#FFF",
  //     "& .MuiChip-deleteIcon": {
  //       color: "#FFF",
  //     },
  //   },
  // },
  goBackText: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  mainBoxStyle: {
    padding: 20,
    borderRadius: 20,
    background: "white",
  },
  hr: {
    opacity: 0.03,
  },
  submitButton: {
    width: "100%",
    borderRadius: "10px!important",
    background: "#E77228!important",
  },
  addNew: {
    width: "150px",
    borderRadius: "10px!important",
    background: "#E77228!important",
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  trash: {
    cursor: "pointer",
    fontSize: 20,
    color: "red",
  },
});

const companyFuseOptions = {
  isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  includeMatches: true,
  //findAllMatches: false,
  minMatchCharLength: 4,
  //  location: 0,
  //  threshold: 0.4, // Eşleşme derecesi
  // tokenize: true, // Tokenization aktif
  // tokenSeparator: ' ',
  //  threshold: 0.6,
  //  distance: 1,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["companyName", "VATNumber"],
};

const companyFuseFunction = (name: string) => {
  return {
    isCaseSensitive: false,
    includeScore: true,
    shouldSort: true,
    includeMatches: true,
    //findAllMatches: false,
    minMatchCharLength: Math.floor(name.length / 2),
    //  location: 0,
    //  threshold: 0.4, // Eşleşme derecesi
    // tokenize: true, // Tokenization aktif
    // tokenSeparator: ' ',
    //  threshold: 0.6,
    //  distance: 1,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ["companyName", "VATNumber"],
  };
};

const subProductTypeFuseOptions = {
  isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  includeMatches: true,
  //findAllMatches: false,
  // minMatchCharLength: 1,
  //  location: 0,
  //  threshold: 0.4, // Eşleşme derecesi
  // tokenize: true, // Tokenization aktif
  // tokenSeparator: ' ',
  //  threshold: 0.6,
  //  distance: 1,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["name"],
};

const AIInvoicesContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(aiInvoiceSchema),
    defaultValues: {
      currency: {},
      newProduceType: {},
      companyNameSeller: {},
      companyNameBuyer: {},
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [companyLoading, setCompanyLoading] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<AIInvoceState>();
  const [units, setUnits] = useState<UnitsState[]>([]);
  const [companiesSeller, setCompaniesSeller] = useState<CompaniesStateType[]>(
    []
  );
  const [suppliers, setSuppliers] = useState<string[]>([]);
  const [openAccountWarningModal, setOpenAccountWarningModal] = useState(false);
  const [tempCompanies, setTempCompanies] = useState<CompaniesStateType[]>([]);
  const [companiesBuyer, setCompaniesBuyer] = useState<CompaniesStateType[]>(
    []
  );
  const [subProductTypes, setSubProductTypes] = useState<
    { name: string; id: string }[]
  >([]);
  const [sellerBankAccountInfo, setSellerBankAccountInfo] = useState<{
    bankAccountName: string;
    bankName: string;
    ibanNumber: string;
    swiftCode: string;
    accountNumber: string;
  }>();
  const [openNewCompanyModal, setOpenNewCompanyModal] =
    useState<boolean>(false);
  const [addCompanyModalType, setAddCompanyModalType] = useState<string>("");
  const [defaultSellerCompany, setDefaultSellerCompany] =
    useState<CompaniesStateType>();
  const [defaultBuyerCompany, setDefaultBuyerCompany] =
    useState<CompaniesStateType>();
  const [openAddNewItemModal, setOpenNewItemModal] = useState<boolean>(false);
  const [isSearchFinished, setIsSearchFinished] = useState<boolean>(false);
  const [isBuyerSearchFinished, setIsBuyerSearchFinished] =
    useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [totalProduceAmount, setTotalProduceAmount] = useState<number>(0);
  const [refreshCompanyRequest, setRefreshCompanyRequest] = useState<number>(0);
  const [totalCostsAmount, setTotalCostsAmount] = useState<number>(0);

  const { id } = useParams();

  const currencies = useAppSelector((state) =>
    state.auth.currencies.filter((item) => item.code !== "CAD")
  );

  const watchNewCostName = watch("newCostName");
  const watchNewCostAmount = watch("newCostAmount");
  const watchNewProduceName = watch("newProduceName");
  const watchNewProduceType = watch("newProduceType");
  const watchNewQuantity = watch("newQuantity");
  const watchNewProducePrice = watch("newProducePrice");
  const watchDiscount = watch("discount");

  const lineItemArr: any = [];

  const invoiceJSON: any =
    invoice?._id &&
    invoice?.readData
      .map((item) => {
        if (item.title === "line_item") {
          lineItemArr.push(
            item?.properties?.reduce(
              (obj, cur) => ({ ...obj, [cur.title]: cur }),
              {}
            )
          );

          const newObj = { title: item.title, lineItemArr };

          return newObj;
        } else {
          return item;
        }
      })
      .reduce((obj, cur) => ({ ...obj, [cur.title]: cur }), {});

  const defaultCurrency = invoiceJSON?.currency
    ? currencies?.find(
        (item) =>
          item.name === invoiceJSON?.currency?.text ||
          item.code === invoiceJSON?.currency?.text ||
          item.symbol === invoiceJSON?.currency?.text
      )
    : undefined;

  const grandTotal = totalCostsAmount
    ? parseFloat(
        (
          totalCostsAmount +
          totalProduceAmount -
          parseFloat(
            watchDiscount && !isNaN(parseFloat(watchDiscount))
              ? watchDiscount
              : "0"
          )
        ).toFixed(2)
      ).toLocaleString()
    : (
        totalProduceAmount -
        parseFloat(
          watchDiscount && !isNaN(parseFloat(watchDiscount))
            ? watchDiscount
            : "0"
        )
      ).toLocaleString();

  const onOpenModal = (type: string) => {
    setOpenNewItemModal(true);
    setModalType(type);
  };

  const onOpenAddCompanyModal = (type: string) => {
    setOpenNewCompanyModal(true);
    setAddCompanyModalType(type);
  };
  const onCloseModal = () => {
    setOpenNewItemModal(false);
  };
  const onCloseAccountWarningModal = () => {
    setOpenAccountWarningModal(false);
  };

  const onCloseAddCompanyModal = () => {
    setOpenNewCompanyModal(false);
    setAddCompanyModalType("");
  };

  const onGoBack = () => {
    window.location.href = "/document-AI-invoices";
  };

  //this function for setting total amount of produce when price qty changes
  const onChangePriceNQuantity = (index: number) => {
    setValue(
      `produces[${index}].amount` as any,

      (
        (watch(`produces[${index}].price` as any)
          ? parseFloat(watch(`produces[${index}].price` as any))
          : 0) *
        (watch(`produces[${index}].quantity` as any)
          ? parseFloat(watch(`produces[${index}].quantity` as any))
          : 0)
      ).toLocaleString()
    );

    setTotalProduceAmount(
      watch("produces")
        ?.map((item) =>
          item?.price && item?.quantity
            ? parseFloat(item?.price) * parseFloat(item?.quantity)
            : parseFloat(item?.amount)
        )
        .reduce((prev, curr) => prev + curr, 0) || 0
    );
  };

  //this function for setting total amount of cost when price qty changes
  const onChangeAmountForCosts = () => {
    setTotalCostsAmount(
      watch("costs")
        ?.map((item: any) => parseFloat(item?.amount || 0))
        .reduce((prev: any, curr: any) => prev + curr, 0)
    );
  };

  //get suppliers

  useEffect(() => {
    const getSuppliers = async () => {
      const resSuppliers = await getSupplierInventories();

      if (resSuppliers?.suppliers) {
        setSuppliers(resSuppliers?.suppliers);
      }
    };
    getSuppliers();
  }, []);

  useEffect(() => {
    if (invoice?._id && companiesSeller.length && tempCompanies.length) {
      let sellerResults: Array<CompaniesStateType> = [];

      for (const companyName of companiesSeller.map(
        (item) => item.companyName
      )) {
        const searchItemCompany = tempCompanies.find(
          (c) => c.companyName === companyName
        );

        let result: any = {
          ...searchItemCompany,
          score: Number(
            (
              Number(
                stringSimilarity(
                  formattedStringWithoutCharacterNParanthesis(
                    CleanString(invoiceJSON?.supplier_name?.text || "")
                  ),
                  CleanString(companyName),
                  2
                )
              ) * 100
            ).toFixed(2)
          ),
        };

        sellerResults.push(result);
      }

      const fResult = sellerResults.sort((a, b) => {
        const result = Number(a.score) - Number(b.score);
        return result * -1;
      });

      if (fResult?.length) {
        setCompaniesSeller(fResult.filter((item: any) => item.score > 20));
        setValue("companyNameSeller", {
          score: fResult[0]?.score,
          name: fResult[0]?.companyName,
          id: fResult[0]?._id,
        });

        setDefaultSellerCompany(fResult[0]);
      }

      setIsSearchFinished(true);
    }
    // if (invoice?._id && companiesSeller?.length) {
    //   const onSearchForSellerCompany = () => {
    //     const fuse = new Fuse(
    //       companiesSeller,
    //       companyFuseFunction(invoiceJSON?.supplier_name?.text)
    //     );

    //     if (invoiceJSON?.supplier_name?.text) {
    //       const searchCompanyNameRes = fuse.search(
    //         formattedStringWithoutCharacterNParanthesis(
    //           invoiceJSON?.supplier_name?.text
    //         ) || ""
    //       );

    //       setCompaniesSeller(
    //         searchCompanyNameRes.map((res) => ({
    //           ...res.item,
    //           score: res.score,
    //         }))
    //       );

    //       return searchCompanyNameRes;
    //     } else {
    //       return fuse.search(invoiceJSON?.supplier_tax_id?.text || "");
    //     }
    //   };

    //   const searchRes = onSearchForSellerCompany();

    //   if (searchRes?.length) {
    //     setValue("companyNameSeller", {
    //       score: searchRes[0]?.item?.score,
    //       name: searchRes[0]?.item?.companyName,
    //       id: searchRes[0]?.item?._id,
    //     });

    //     setDefaultSellerCompany(searchRes[0]?.item);
    //   }

    //   setIsSearchFinished(true);
    // }
  }, [
    invoice?._id,
    refreshCompanyRequest,
    companiesSeller.length,
    tempCompanies?.length,
    invoiceJSON?.supplier_name?.text,
    invoiceJSON?.supplier_tax_id?.text,
  ]);

  //this effect for search subproduct type
  useEffect(() => {
    if (
      !invoice?._id &&
      subProductTypes.length === 0 &&
      invoiceJSON?.line_item?.lineItemArr.length === 0
    )
      return;

    const onSearchForSubProductTypes = () => {
      const lineItemArr = invoiceJSON?.line_item?.lineItemArr;
      const results: { item: SubProductType | undefined; score: number }[] = [];

      lineItemArr?.forEach((item: any, index: number) => {
        const searchKey = convertProduceName(item?.description?.text);
        const fuse = new Fuse([searchKey], subProductTypeFuseOptions);

        const lineResults: any[] = [];

        subProductTypes?.forEach((item) => {
          const result = fuse.search(item.name);
          if (result.length === 0) return { item: undefined, score: 1 };

          lineResults.push({ item, score: result?.[0]?.score });
        });

        lineResults.sort((a, b) => a?.score - b?.score);
        lineResults.map((itm) =>
          itm.score < 0.51 ? itm : { item: undefined, score: 1 }
        );

        if (lineResults.length > 0) results.push(lineResults[0]);
      });

      return results;
    };

    const searchRes = onSearchForSubProductTypes();

    if (
      searchRes.length > 0 &&
      searchRes.length === invoiceJSON?.line_item?.lineItemArr.length
    ) {
      searchRes.forEach((item, index) => {
        if (item.score) {
          setValue(`produces[${index}].subProductType` as any, item.item);
        }
      });
    }
  }, [
    invoice?._id,
    invoiceJSON?.line_item?.lineItemArr?.length,
    subProductTypes.length,
  ]);

  //this effect for search buyer company with invoice data
  useEffect(() => {
    if (invoice?._id && companiesBuyer.length && tempCompanies.length) {
      let results: Array<CompaniesStateType> = [];

      for (const companyName of companiesBuyer.map(
        (item) => item.companyName
      )) {
        const searchItemCompany = tempCompanies.find(
          (c) => c.companyName === companyName
        );

        let result: any = {
          ...searchItemCompany,
          score: Number(
            (
              Number(
                stringSimilarity(
                  formattedStringWithoutCharacterNParanthesis(
                    CleanString(invoiceJSON?.receiver_name?.text || "")
                  ),
                  CleanString(companyName),
                  2
                )
              ) * 100
            ).toFixed(2)
          ),
        };

        results.push(result);
      }

      const finalResult = results.sort((a, b) => {
        const result = Number(a.score) - Number(b.score);
        return result * -1;
      });

      if (finalResult.length) {
        setCompaniesBuyer(finalResult.filter((item: any) => item.score > 20));

        setDefaultBuyerCompany(finalResult?.[0]);
        setValue("companyNameBuyer", {
          score: finalResult[0]?.score,
          name: finalResult[0]?.companyName,
          id: finalResult[0]?._id,
        });

        setIsBuyerSearchFinished(true);
      }
    }
    // if (invoice?._id && companiesBuyer?.length) {
    //   const onSearchForBuyerCompany = () => {
    //     const fuse = new Fuse(companiesBuyer, companyFuseOptions);

    //     if (invoiceJSON?.receiver_name?.text) {
    //       const responseBuyerSearch = fuse.search(
    //         formattedStringWithoutCharacterNParanthesis(
    //           invoiceJSON?.receiver_name?.text
    //         ) || ""
    //       );
    //       setCompaniesBuyer(
    //         responseBuyerSearch.map((res) => ({
    //           ...res.item,
    //           score: res.score,
    //         }))
    //       );

    //       return responseBuyerSearch;
    //     } else {
    //       return fuse.search(invoiceJSON?.receiver_tax_id?.text || "");
    //     }
    //   };

    //   const searchRes: any = onSearchForBuyerCompany();

    //   if (searchRes?.length) {
    //     setDefaultBuyerCompany(searchRes?.[0]?.item);
    //     setValue("companyNameBuyer", {
    //       score: searchRes[0]?.item?.score,
    //       name: searchRes[0]?.item?.companyName,
    //       id: searchRes[0]?.item?._id,
    //     });
    //   }

    //   setIsBuyerSearchFinished(true);
    // }
  }, [
    invoice?._id,
    companiesBuyer?.length,
    refreshCompanyRequest,
    tempCompanies?.length,
    invoiceJSON?.receiver_name?.text,
    invoiceJSON?.receiver_tax_id?.text,
  ]);

  //this effect for putting ai data to inputs
  useEffect(() => {
    if (invoice?._id) {
      setValue(
        "invoiceDate",
        invoiceJSON?.invoice_date?.text
          ? (convertToDayJsFormat(invoiceJSON?.invoice_date?.text) as any)
          : null
      );
      setValue(
        "deliveryDate",
        invoiceJSON?.delivery_date?.text
          ? (convertToDayJsFormat(invoiceJSON?.delivery_date?.text) as any)
          : null
      );
      setValue("refBuyer", invoiceJSON?.purchase_order?.text);
      setValue(
        "currency",
        defaultCurrency?.id
          ? {
              name: defaultCurrency?.code,
              id: defaultCurrency?.id,
            }
          : {}
      );
      setValue("refSeller", invoiceJSON?.invoice_id?.text);
      setValue("locationBuyer", invoiceJSON?.receiver_address?.text);
      setValue("locationSeller", invoiceJSON?.supplier_address?.text);
      setValue("companyAdminSeller", invoiceJSON?.supplier_user_name?.text);
      setValue("phoneNumberSeller", invoiceJSON?.supplier_phone?.text);
      setValue("vatBuyer", invoiceJSON?.receiver_tax_id?.text);
      setValue("vatSeller", invoiceJSON?.supplier_tax_id?.text);
      setValue("invoiceEmailSeller", invoiceJSON?.supplier_email?.text);
      setValue("companyAdminBuyer", invoiceJSON?.receiver_user_name?.text);
      setValue("phoneNumberBuyer", invoiceJSON?.receiver_phone?.text);
      setValue("invoiceEmailBuyer", invoiceJSON?.receiver_email?.text);
      setValue("accountName", invoiceJSON?.bank_account_name?.text);
      setValue("account", invoiceJSON?.bank_account_number?.text);
      setValue("bankName", invoiceJSON?.bank_bank_name?.text);
      invoiceJSON?.net_amount?.text &&
        parseFloat(invoiceJSON?.net_amount?.text) !==
          parseFloat(invoiceJSON?.total_amount?.text) &&
        setValue(
          "discount",
          (
            parseFloat(formatNumber(invoiceJSON?.net_amount?.text as any)) -
            parseFloat(formatNumber(invoiceJSON?.total_amount?.text as any))
          )
            .toFixed(2)
            .toString() as any
        );

      setValue(
        "IBAN",
        invoiceJSON?.bank_iban?.text || invoiceJSON?.supplier_iban?.text
      );
      setValue("BIC", invoiceJSON?.bank_bic?.text);

      setValue(
        "dueDate",
        invoiceJSON?.due_date?.text
          ? (convertToDayJsFormat(invoiceJSON?.due_date?.text) as any)
          : null
      );

      setValue(
        "produces",
        invoiceJSON?.line_item?.lineItemArr
          // ?.filter((p: any) => p?.unit_price?.text || p?.quantity?.text)
          ?.map((item: any) => ({
            unit:
              item?.unit?.text &&
              units.find((unit) => unit.name === item?.unit?.text)?.name &&
              units.find((unit) => unit.name === item?.unit?.text)?.id
                ? {
                    name: units.find((unit) => unit.name === item?.unit?.text)
                      ?.name,
                    id: units.find((unit) => unit.name === item?.unit?.text)
                      ?.id,
                  }
                : undefined,
            name: item?.description?.text?.toString(),
            id: Math.random().toString(),
            quantity: item?.quantity?.text
              ?.toString()
              ?.replace(/[^\d,\.]/g, ""),
            price: item?.unit_price?.text?.toString()?.replace(/[^\d,\.]/g, ""),
            amount: item?.amount?.text
              ? item?.amount?.text?.toString()?.replace(/[^\d,\.]/g, "")
              : (
                  parseFloat(item?.unit_price?.text?.replace(/[^\d,\.]/g, "")) *
                  parseFloat(item?.quantity?.text?.replace(/[^\d,\.]/g, ""))
                )
                  ?.toFixed(2)
                  ?.toString(),
          }))
      );
      setTotalProduceAmount(
        invoiceJSON?.line_item?.lineItemArr?.length
          ? invoiceJSON?.line_item?.lineItemArr
              ?.filter((p: any) => p?.unit_price?.text || p?.quantity?.text)
              ?.map((item: any) =>
                item?.unit_price?.text && item?.quantity?.text
                  ? parseFloat(
                      formatNumber(
                        item?.unit_price?.text.replace(/[^\d,\.]/g, "")
                      )
                    ) *
                    parseFloat(
                      formatNumber(item.quantity?.text.replace(/[^\d,\.]/g, ""))
                    )
                  : parseFloat(
                      formatNumber(item?.amount?.text.replace(/[^\d,\.]/g, ""))
                    )
              )
              .reduce((prev: any, curr: any) => prev + curr, 0)
          : 0
      );

      setValue(
        "costs",
        invoiceJSON?.line_item?.lineItemArr
          ?.filter((p: any) => !p?.unit_price?.text && !p?.quantity?.text)
          ?.map((item: any) => ({
            costName: item?.description?.text,
            id: Math.random().toString(),
            amount: item?.amount?.text?.toString(),
          }))
      );

      setTotalCostsAmount(
        invoiceJSON?.line_item?.lineItemArr?.length
          ? invoiceJSON?.line_item?.lineItemArr
              ?.filter((p: any) => !p?.unit_price?.text && !p?.quantity?.text)
              ?.map((item: any) => parseFloat(item?.amount?.text))
              .reduce((prev: any, curr: any) => prev + curr, 0)
          : 0
      );
    }
  }, [invoice?._id]);

  //this effect for setting grand total,produce total and discounts
  useEffect(() => {
    if (invoice?._id) {
      setValue(
        "totalAmount",
        totalCostsAmount
          ? (totalProduceAmount + totalCostsAmount).toLocaleString()
          : totalProduceAmount.toLocaleString()
      );
      setValue("grandTotal", grandTotal.toLocaleString());
    }
  }, [totalCostsAmount, totalProduceAmount, invoice?._id, grandTotal]);

  //this effect for getting invoice data from API
  useEffect(() => {
    if (id) {
      const getData = async () => {
        setFetchLoading(true);
        const response = await getAIInvoices(1, {
          documentID: id,
        });

        setFetchLoading(false);

        if (response) {
          setInvoice(response?.items?.[0]);
        }
      };

      const getSubProductTypes = async () => {
        setFetchLoading(true);
        try {
          const subTypesRes = await getSubTypesWithProductTypeId();

          if (subTypesRes?.data) {
            setSubProductTypes(
              subTypesRes?.data?.map((item: any) => ({
                id: item?._id,
                name: item?.name,
              }))
            );
          }
        } catch (error) {
          console.warn(error);
        } finally {
          setFetchLoading(false);
        }
      };

      getSubProductTypes();
      getData();
    }
  }, [id]);

  //get companies
  useEffect(() => {
    const getCompanies = async () => {
      setCompanyLoading(true);
      const companyRes = await getBuyerSeller();
      setCompanyLoading(false);

      if (companyRes) {
        setCompaniesSeller(companyRes?.items);
        setTempCompanies(companyRes?.items);
        setCompaniesBuyer(companyRes?.items);
      }
    };
    getCompanies();
  }, [refreshCompanyRequest]);

  //this effect for getting units
  useEffect(() => {
    const getUnitsData = async () => {
      const response = await getUnits();

      if (response) {
        setUnits(
          response?.map((item: any) => ({
            id: item?._id,
            name: item?.name,
          }))
        );
      }
    };
    getUnitsData();
  }, []);

  //this function for adding new produce and cost
  const onAddNewItem = () => {
    if (modalType === "produce") {
      if (
        !watchNewProduceName ||
        !watchNewProducePrice ||
        !watchNewQuantity ||
        !(watchNewProduceType as any)?.name
      ) {
        toast.error("All fields are required");
      } else {
        const newData = {
          subProductType: watchNewProduceType,
          name: watchNewProduceName,
          id: Math.random().toString(),
          amount: (
            parseFloat(formatNumber(watchNewProducePrice)) *
            parseFloat(formatNumber(watchNewQuantity))
          )
            .toFixed(2)
            .toString(),
          price: parseFloat(formatNumber(watchNewProducePrice))
            .toFixed(2)
            .toString(),
          quantity: watchNewQuantity,
        };

        const finalData = [...(watch("produces") as any), newData];
        setValue("newProduceName", "");
        setValue("newProducePrice", "");
        setValue("newProduceType", {});
        setValue("newQuantity", "");
        setValue("produces", finalData);
        setOpenNewItemModal(false);
        setTotalProduceAmount(
          watch("produces")
            ?.map((item) =>
              item?.price && item?.quantity
                ? parseFloat(formatNumber(item?.price)) *
                  parseFloat(formatNumber(item?.quantity))
                : parseFloat(formatNumber(item?.amount))
            )
            .reduce((prev, curr) => prev + curr, 0) || 0
        );
      }
    } else if (modalType === "cost") {
      if (!watchNewCostAmount || !watchNewCostName) {
        toast.error("All fields are required");
      } else {
        const newData = {
          costName: watchNewCostName,
          id: Math.random().toString(),
          amount: parseFloat(formatNumber(watchNewCostAmount))
            .toFixed(2)
            .toString(),
        };

        const finalData = [...(watch("costs") as any), newData];
        setValue("newCostAmount", "");
        setValue("newCostName", "");
        setValue("costs", finalData);
        setOpenNewItemModal(false);
        setTotalCostsAmount(
          watch("costs")
            ?.map((item: any) => parseFloat(formatNumber(item?.amount || 0)))
            .reduce((prev: any, curr: any) => prev + curr, 0)
        );
      }
    }
  };

  //deleting cost and produce
  const onDeleteItem = (type: string, id: string) => {
    if (type === "cost") {
      const filteredCosts = watch("costs")?.filter((item, i) => id !== item.id);
      setValue("costs", filteredCosts);
      setTotalCostsAmount(
        watch("costs")
          ?.map((item: any) => parseFloat(formatNumber(item?.amount || 0)))
          .reduce((prev: any, curr: any) => prev + curr, 0)
      );
    } else if (type === "produce") {
      const filteredProduces = watch("produces")?.filter(
        (item, i) => id !== item.id
      );

      setValue("produces", filteredProduces);
      setTotalProduceAmount(
        watch("produces")
          ?.map((item) =>
            item?.price && item?.quantity
              ? parseFloat(formatNumber(item?.price)) *
                parseFloat(formatNumber(item?.quantity))
              : parseFloat(formatNumber(item?.amount))
          )
          .reduce((prev, curr) => prev + curr, 0) || 0
      );
    }
  };

  //this function for submit invoice data
  const onSubmitInvoice = async (data: any) => {
    setLoading(true);

    const response = await getCompanyById(
      (watch("companyNameSeller") as any)?.id
    );

    setLoading(false);
    setSellerBankAccountInfo({
      bankName: response?.bankName,
      bankAccountName: response?.bankAccountName,
      ibanNumber: response?.ibanNumber,
      swiftCode: response?.swiftCode,
      accountNumber: response?.accountNumber,
    });

    if (
      response?.bankAccountName &&
      watch("accountName") &&
      watch("accountName")?.toLowerCase()?.trim() !==
        response?.bankAccountName?.toLowerCase()?.trim()
    ) {
      setOpenAccountWarningModal(true);
    } else if (
      response?.bankName &&
      watch("bankName") &&
      watch("bankName")?.toLowerCase()?.trim() !==
        response?.bankName?.toLowerCase()?.trim()
    ) {
      setOpenAccountWarningModal(true);
    } else if (
      response?.swiftCode &&
      watch("BIC") &&
      watch("BIC")?.trim() !== response?.swiftCode?.trim()
    ) {
      setOpenAccountWarningModal(true);
    } else if (
      response?.ibanNumber &&
      watch("IBAN") &&
      watch("IBAN")?.trim()?.replaceAll("-", " ") !==
        response?.ibanNumber?.trim()?.replaceAll("-", " ")
    ) {
      setOpenAccountWarningModal(true);
    } else if (
      response?.accountNumber &&
      watch("account") &&
      watch("account")?.trim() !== response?.accountNumber?.trim()
    ) {
      setOpenAccountWarningModal(true);
    } else {
      const finalData = {
        ...data,
        processedDocumentId: id,
      };

      dispatch(setAIInvoice(finalData));
      navigate("/create-new-surplus");
    }
  };

  const onContinue = (data: any) => {
    const finalData = {
      ...data,
      processedDocumentId: id,
    };

    dispatch(setAIInvoice(finalData));
    navigate("/create-new-surplus");
  };

  const renderModalBody = () => {
    return (
      <>
        {modalType === "cost" ? (
          <>
            <ControlledTextField
              textLabel="Cost Name"
              control={control}
              name="newCostName"
            />
            <ControlledTextField
              textLabel="Amount"
              control={control}
              name="newCostAmount"
            />
          </>
        ) : (
          <>
            <ControlledComboBox
              options={subProductTypes}
              className={classes.comboBox}
              textLabel="Produce Type"
              control={control}
              name="newProduceType"
            />
            <ControlledTextField
              textLabel="Produce Name"
              control={control}
              name="newProduceName"
            />
            <ControlledTextField
              textLabel="Quantity"
              control={control}
              name="newQuantity"
            />
            <ControlledTextField
              textLabel="Price"
              control={control}
              name="newProducePrice"
            />
          </>
        )}
        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={onAddNewItem}
            title="Submit"
          />
        </div>
      </>
    );
  };
  const renderAddCompanyModalBody = () => {
    return (
      <>
        <AddCompanyModalBody
          addCompanyModalType={addCompanyModalType}
          invoiceJSON={invoiceJSON}
          setRefreshCompanyRequest={setRefreshCompanyRequest}
          setOpenModal={setOpenNewCompanyModal}
        />
      </>
    );
  };
  const renderAccountWarningModalBody = () => {
    return (
      <>
        Seller's bank account details on our platform and details fetched from
        processed invoice are not matching.
        <br />
        <br />
        Bank account details on our platform:
        <div className="d-flex flex-column gap-1">
          <span>
            {" "}
            <span className="fw-bold">Bank account name : </span>
            {sellerBankAccountInfo?.bankAccountName}
          </span>
          <span>
            {" "}
            <span className="fw-bold">Bank name : </span>
            {sellerBankAccountInfo?.bankName}
          </span>
          <span>
            {" "}
            <span className="fw-bold">IBAN : </span>
            {sellerBankAccountInfo?.ibanNumber}
          </span>
          <span>
            {" "}
            <span className="fw-bold">BIC : </span>
            {sellerBankAccountInfo?.swiftCode}
          </span>
          <span>
            {" "}
            <span className="fw-bold">Account number : </span>
            {sellerBankAccountInfo?.accountNumber}
          </span>
        </div>
        <br />
        Bank account details fetched from invoice:
        <div className="d-flex flex-column gap-1">
          <span>
            {" "}
            <span className="fw-bold">Bank account name : </span>
            {invoiceJSON?.bank_account_name?.text}
          </span>
          <span>
            {" "}
            <span className="fw-bold">Bank name : </span>
            {invoiceJSON?.bank_bank_name?.text}
          </span>
          <span>
            {" "}
            <span className="fw-bold">IBAN : </span>
            {invoiceJSON?.supplier_iban?.text || invoiceJSON?.bank_iban?.text}
          </span>
          <span>
            {" "}
            <span className="fw-bold">BIC : </span>
            {invoiceJSON?.bank_bic?.text}
          </span>
          <span>
            {" "}
            <span className="fw-bold">Account number : </span>
            {invoiceJSON?.bank_account_number?.text}
          </span>
        </div>
        <br />
        <span>Do you want to continue adding this trade? </span>
        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseAccountWarningModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={handleSubmit(onContinue)}
            title="Continue"
          />
        </div>
      </>
    );
  };

  const tempCompaniesWithFiltered = tempCompanies
    .map((item) => {
      const isSameCompany = companiesSeller.find((c) => c._id === item._id);

      return isSameCompany
        ? undefined
        : {
            score: item.score,
            name: item.companyName,
            id: item.id,
          };
    })
    .filter((item) => item !== undefined);

  const tempCompaniesWithFilteredBuyer = tempCompanies
    .map((item) => {
      const isSameCompany = companiesBuyer.find((c) => c._id === item._id);

      return isSameCompany
        ? undefined
        : {
            score: item.score,
            name: item.companyName,
            id: item.id,
          };
    })
    .filter((item) => item !== undefined);

  return (
    <Sidebar isJustNavbar>
      {loading && <LoadingIndicator loading />}
      <Helmet>
        <title>New AI Invoice | FruPro Admin Portal</title>
      </Helmet>

      {invoice?._id &&
      companiesSeller &&
      isSearchFinished &&
      isBuyerSearchFinished &&
      subProductTypes.length ? (
        <div style={{ position: "relative", overflow: "hidden" }}>
          <div className="row g-3">
            <div
              className={`col-md-6`}
              style={
                window.screen.width < 450
                  ? {}
                  : { position: "fixed", top: "64px", left: 0 }
              }
            >
              <div className="mb-3">
                <i className="ri-arrow-left-line"></i>
                <span className={classes.goBackText} onClick={onGoBack}>
                  Go back
                </span>
              </div>
              {
                <div className={classes.mainBoxStyle}>
                  {fetchLoading || companyLoading ? (
                    <Skeleton count={1} style={{ height: 600 }} />
                  ) : (
                    <iframe
                      title="Invoice File"
                      style={{ width: "100%", height: "80vh" }}
                      src={`${invoice?.fileUrl}#toolbar=0&navpanes=0&scrollbar=0&view=FitH`}
                    />
                  )}
                </div>
              }
            </div>
            <div style={{ flex: 1 }} />
            <div
              className={`col-md-6`}
              style={{
                flex: 1,
              }}
            >
              {
                <div style={{ marginTop: 40 }} className={classes.mainBoxStyle}>
                  <div className="row"></div>
                  {fetchLoading || companyLoading ? (
                    <Skeleton count={1} style={{ height: 600 }} />
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <ControlledDateField
                            isShowIcons
                            watchValue={watch("invoiceDate")}
                            helperText={errors.invoiceDate?.message}
                            error={!!errors.invoiceDate}
                            textLabel="Invoice Date"
                            name="invoiceDate"
                            control={control}
                          />
                        </div>
                        <div className=" col-md-6 mt-3 ">
                          <ControlledDateField
                            isShowIcons
                            watchValue={watch("deliveryDate")}
                            helperText={errors.deliveryDate?.message}
                            error={!!errors.deliveryDate}
                            textLabel="Delivery Date"
                            name="deliveryDate"
                            control={control}
                          />
                        </div>

                        {currencies?.length ? (
                          <div className="col-md-6">
                            <ControlledComboBox
                              isShowIcons
                              watchValue={(watch("currency") as any)?.name}
                              defaultValue={
                                defaultCurrency?.id
                                  ? {
                                      name: defaultCurrency?.code,
                                      id: defaultCurrency?.id,
                                    }
                                  : null
                              }
                              className={classes.comboBox}
                              textLabel="Currency"
                              options={currencies.map((item) => ({
                                name: item.code,
                                id: item.id,
                              }))}
                              control={control}
                              name="currency"
                            />
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="fw-bold">SELLER:</span>
                        {defaultSellerCompany?.score !== 100 ||
                        !defaultSellerCompany?._id ? (
                          <AdminButtonComponent
                            onClick={() => onOpenAddCompanyModal("seller")}
                            title="Add company"
                          />
                        ) : null}
                      </div>

                      <div className=" col-md-12 mt-2">
                        <ControlledComboBox
                          isShowIcons
                          watchValue={
                            (watch("companyNameSeller") as any)?.score === 100
                              ? "yes"
                              : ""
                          }
                          notSortOptions
                          defaultValue={
                            defaultSellerCompany?._id
                              ? {
                                  score: defaultSellerCompany?.score,
                                  name: defaultSellerCompany?.companyName,
                                  id: defaultSellerCompany?._id,
                                }
                              : undefined
                          }
                          newOptions={[
                            ...companiesSeller?.map((item) => ({
                              name: item.companyName,
                              id: item._id,
                              score: item.score,
                            })),
                            ...tempCompaniesWithFiltered,
                          ]}
                          options={[
                            ...companiesSeller?.map((item) => ({
                              name: item.companyName,
                              id: item._id,
                              score: item.score,
                            })),
                            ...tempCompaniesWithFiltered,
                          ]}
                          className={classes.comboBox}
                          helperText={errors.companyNameSeller?.message}
                          error={!!errors.companyNameSeller}
                          textLabel="Company Name"
                          name="companyNameSeller"
                          control={control}
                        />
                      </div>

                      <div className=" col-md-12 mt-3">
                        <ControlledTextField
                          helperText={errors.locationSeller?.message}
                          error={!!errors.locationSeller}
                          className={classes.textField}
                          textLabel="Address"
                          name="locationSeller"
                          control={control}
                        />
                      </div>
                      <div className=" col-md-12 mt-2">
                        <ControlledTextField
                          helperText={errors.refSeller?.message}
                          error={!!errors.refSeller}
                          textLabel="Ref"
                          name="refSeller"
                          control={control}
                        />
                      </div>
                      <div className=" col-md-12 mt-2">
                        <ControlledTextField
                          helperText={errors.vatSeller?.message}
                          error={!!errors.vatSeller}
                          textLabel="VAT"
                          name="vatSeller"
                          control={control}
                        />
                      </div>
                      <div className=" col-md-12 mt-3 ">
                        <ControlledTextField
                          helperText={errors.companyAdminSeller?.message}
                          error={!!errors.companyAdminSeller}
                          textLabel="Contact Name"
                          name="companyAdminSeller"
                          control={control}
                        />
                      </div>
                      <div className=" col-md-12 mt-3 ">
                        <ControlledTextField
                          helperText={errors.invoiceEmailSeller?.message}
                          error={!!errors.invoiceEmailSeller}
                          textLabel="Email"
                          name="invoiceEmailSeller"
                          control={control}
                        />
                      </div>
                      <div className=" col-md-12 mt-3 ">
                        <ControlledTextField
                          helperText={errors.phoneNumberSeller?.message}
                          error={!!errors.phoneNumberSeller}
                          textLabel="Phone Number"
                          name="phoneNumberSeller"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="fw-bold">BUYER:</span>
                        {defaultBuyerCompany?.score !== 100 ||
                        !defaultBuyerCompany?._id ? (
                          <AdminButtonComponent
                            onClick={() => onOpenAddCompanyModal("buyer")}
                            title="Add company"
                          />
                        ) : null}
                      </div>
                      <div className=" col-md-12 mt-2">
                        <ControlledComboBox
                          isShowIcons
                          watchValue={
                            (watch("companyNameBuyer") as any)?.score === 100
                              ? "yes"
                              : ""
                          }
                          notSortOptions
                          defaultValue={
                            defaultBuyerCompany?._id
                              ? {
                                  score: defaultBuyerCompany?.score,
                                  name: defaultBuyerCompany?.companyName,
                                  id: defaultBuyerCompany?._id,
                                }
                              : undefined
                          }
                          newOptions={[
                            ...companiesBuyer?.map((item) => ({
                              name: item.companyName,
                              id: item._id,
                              score: item.score,
                            })),
                            ...tempCompaniesWithFilteredBuyer,
                          ]}
                          options={[
                            ...companiesBuyer?.map((item) => ({
                              name: item.companyName,
                              id: item._id,
                              score: item.score,
                            })),
                            ...tempCompaniesWithFilteredBuyer,
                          ]}
                          helperText={errors.companyNameBuyer?.message}
                          className={classes.comboBox}
                          error={!!errors.companyNameBuyer}
                          textLabel="Company Name"
                          name="companyNameBuyer"
                          control={control}
                        />
                      </div>
                      <div className=" col-md-12 mt-3">
                        <ControlledTextField
                          helperText={errors.locationBuyer?.message}
                          error={!!errors.locationBuyer}
                          className={classes.textField}
                          textLabel="Address"
                          name="locationBuyer"
                          control={control}
                        />
                      </div>
                      <div className=" col-md-12 mt-2">
                        <ControlledTextField
                          helperText={errors.refBuyer?.message}
                          error={!!errors.refBuyer}
                          textLabel="Ref"
                          name="refBuyer"
                          control={control}
                        />
                      </div>
                      <div className=" col-md-12 mt-2">
                        <ControlledTextField
                          helperText={errors.vatBuyer?.message}
                          error={!!errors.vatBuyer}
                          textLabel="VAT"
                          name="vatBuyer"
                          control={control}
                        />
                      </div>
                      <div className=" col-md-12 mt-3 ">
                        <ControlledTextField
                          helperText={errors.companyAdminBuyer?.message}
                          error={!!errors.companyAdminBuyer}
                          textLabel="Contact Name"
                          name="companyAdminBuyer"
                          control={control}
                        />
                      </div>
                      <div className=" col-md-12 mt-3 ">
                        <ControlledTextField
                          helperText={errors.invoiceEmailBuyer?.message}
                          error={!!errors.invoiceEmailBuyer}
                          textLabel="Email"
                          name="invoiceEmailBuyer"
                          control={control}
                        />
                      </div>
                      <div className=" col-md-12 mt-3 ">
                        <ControlledTextField
                          helperText={errors.phoneNumberBuyer?.message}
                          error={!!errors.phoneNumberBuyer}
                          textLabel="Phone Number"
                          name="phoneNumberBuyer"
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className={classes.hr} />

                  <div className="mt-3">
                    <span className="fw-bold">Produces : </span>
                    <div className="d-flex justify-content-end">
                      <AdminButtonComponent
                        className={classes.addNew}
                        onClick={() => onOpenModal("produce")}
                        title="Add produce"
                      />
                    </div>
                    {watch("produces")?.length ? (
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{minWidth:200}}   className="fw-bold">Produce</TableCell>
                              <TableCell style={{width:130}} className="fw-bold">
                                Quantity/Unit
                              </TableCell>

                              <TableCell style={{width:150}} className="fw-bold">Price</TableCell>
                              <TableCell style={{width:150}} className="fw-bold">Amount</TableCell>
                              <TableCell  style={{width:50}} className="fw-bold"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {watch("produces")?.map((row, index) => {
                              const totalAmountCompare: number =
                                convertToNumberDecimalString(
                                  (
                                    parseFloat(row.price) *
                                    parseFloat(row.quantity)
                                  ).toLocaleString()
                                );

                              return (
                                <TableRow
                                  key={row.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    style={{
                                      height: "100px",
                                      width: 250,
                                    }}
                                    component="th"
                                    scope="row"
                                  >
                                    <div
                                      style={{
                                        gap: "16px",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <ControlledComboBox
                                        isShowIcons
                                        watchValue={watch(
                                          `produces[${index}].subProductType` as any
                                        )}
                                        className={classes.comboBox}
                                        options={[
                                          ...subProductTypes.map((itm) => ({
                                            id: itm.id,
                                            name: itm.name,
                                          })),
                                        ]}
                                        error={
                                          !!errors.produces?.[index]
                                            ?.subProductType
                                        }
                                        helperText={
                                          errors.produces?.[index]
                                            ?.subProductType?.message
                                        }
                                        textLabel=""
                                        name={`produces[${index}].subProductType`}
                                        control={control}
                                      />
                                      <ControlledTextField
                                        isShowIcons
                                        watchValue={watch(
                                          `produces[${index}].name` as any
                                        )}
                                        error={!!errors.produces?.[index]?.name}
                                        helperText={
                                          errors.produces?.[index]?.name
                                            ?.message
                                        }
                                        defaultValue={row.name}
                                        textLabel=""
                                        name={`produces[${index}].name`}
                                        control={control}
                                      />
                                      <ControlledComboBox
                                        options={suppliers}
                                        className={classes.comboBox}
                                        freeSolo
                                        autoSelect
                                        placeholder="Supplier"
                                        isShowIcons
                                        watchValue={watch(
                                          `produces[${index}].supplier` as any
                                        )}
                                        error={
                                          !!errors.produces?.[index]?.supplier
                                        }
                                        helperText={
                                          errors.produces?.[index]?.supplier
                                            ?.message
                                        }
                                        textLabel=""
                                        name={`produces[${index}].supplier`}
                                        control={control}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell style={{ height: "100px" }}>
                                    {" "}
                                    <div className="d-flex flex-column gap-2">
                                      <ControlledTextField
                                        isShowIcons
                                        watchValue={watch(
                                          `produces[${index}].quantity` as any
                                        )}
                                        error={
                                          !!errors.produces?.[index]?.quantity
                                        }
                                        helperText={
                                          errors.produces?.[index]?.quantity
                                            ?.message
                                        }
                                        defaultValue={row.quantity}
                                        textLabel=""
                                        onValueChange={() =>
                                          onChangePriceNQuantity(index)
                                        }
                                        name={`produces[${index}].quantity`}
                                        control={control}
                                      />
                                      <ControlledComboBox
                                        isShowIcons
                                        watchValue={watch(
                                          `produces[${index}].unit` as any
                                        )}
                                        className={classes.comboBox}
                                        options={units}
                                        error={!!errors.produces?.[index]?.unit}
                                        helperText={
                                          errors.produces?.[index]?.unit
                                            ?.message
                                        }
                                        textLabel=""
                                        name={`produces[${index}].unit`}
                                        control={control}
                                      />
                                    </div>
                                  </TableCell>

                                  <TableCell
                                    style={{ height: "100px", width: 120 }}
                                  >
                                    {" "}
                                    <ControlledTextField
                                      isShowIcons
                                      watchValue={watch(
                                        `produces[${index}].price` as any
                                      )}
                                      error={!!errors.produces?.[index]?.price}
                                      helperText={
                                        errors.produces?.[index]?.price?.message
                                      }
                                      onValueChange={() =>
                                        onChangePriceNQuantity(index)
                                      }
                                      textLabel=""
                                      defaultValue={
                                        row?.price
                                          ? parseFloat(row.price)
                                              .toFixed(2)
                                              .toString()
                                          : ""
                                      }
                                      name={`produces[${index}].price`}
                                      control={control}
                                    />
                                  </TableCell>
                                  <TableCell style={{ height: "100px" }}>
                                    {" "}
                                    <ControlledTextField
                                      disabled
                                      isShowIcons
                                      watchValue={
                                        convertToNumberDecimalString(
                                          watch(
                                            `produces[${index}].amount` as any
                                          ) || ""
                                        ) === totalAmountCompare
                                          ? "send"
                                          : undefined
                                      }
                                      error={!!errors.produces?.[index]?.amount}
                                      helperText={
                                        errors.produces?.[index]?.amount
                                          ?.message
                                      }
                                      defaultValue={
                                        row?.price && row?.quantity
                                          ? (
                                              parseFloat(row.price) *
                                              parseFloat(row.quantity)
                                            )
                                              .toFixed(2)
                                              .toString()
                                          : ""
                                      }
                                      name={`produces[${index}].amount`}
                                      textLabel=""
                                      control={control}
                                    />
                                  </TableCell>
                                  <TableCell style={{ height: "100px" }}>
                                    {" "}
                                    <i
                                      onClick={() => {
                                        onDeleteItem("produce", row.id || "");
                                      }}
                                      className={`ri-delete-bin-line ${classes.trash} `}
                                    ></i>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div className="pb-3">
                        <span>No produce added</span>
                      </div>
                    )}

                    <hr className={classes.hr} />
                    <span className="fw-bold">Additional Costs : </span>
                    <div className="d-flex justify-content-end">
                      <AdminButtonComponent
                        className={classes.addNew}
                        onClick={() => onOpenModal("cost")}
                        title="Add cost"
                      />
                    </div>
                    {watch("costs")?.length ? (
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell className="fw-bold">
                                Cost Name
                              </TableCell>
                              <TableCell className="fw-bold"></TableCell>
                              <TableCell className="fw-bold"></TableCell>
                              <TableCell className="fw-bold">Amount</TableCell>
                              <TableCell className="fw-bold"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {watch("costs")?.map((row, index) => (
                              <TableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{ height: "100px" }}
                                  component="th"
                                  scope="row"
                                >
                                  <ControlledTextField
                                    isShowIcons
                                    watchValue={watch(
                                      `costs[${index}].costName` as any
                                    )}
                                    error={!!errors.costs?.[index]?.costName}
                                    helperText={
                                      errors.costs?.[index]?.costName?.message
                                    }
                                    defaultValue={row.costName}
                                    textLabel=""
                                    name={`costs[${index}].costName`}
                                    control={control}
                                  />
                                </TableCell>
                                <TableCell style={{ height: "100px" }}>
                                  {" "}
                                </TableCell>
                                <TableCell style={{ height: "100px" }}>
                                  {" "}
                                </TableCell>
                                <TableCell style={{ height: "100px" }}>
                                  {" "}
                                  <ControlledTextField
                                    isShowIcons
                                    watchValue={watch(
                                      `costs[${index}].amount` as any
                                    )}
                                    onValueChange={onChangeAmountForCosts}
                                    error={!!errors.costs?.[index]?.amount}
                                    helperText={
                                      errors.costs?.[index]?.amount?.message
                                    }
                                    defaultValue={parseFloat(row.amount)
                                      .toFixed(2)
                                      .toString()}
                                    name={`costs[${index}].amount`}
                                    textLabel=""
                                    control={control}
                                  />
                                </TableCell>
                                <TableCell style={{ height: "100px" }}>
                                  {" "}
                                  <i
                                    onClick={() =>
                                      onDeleteItem("cost", row.id || "")
                                    }
                                    className={`ri-delete-bin-line ${classes.trash} `}
                                  ></i>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div className="pb-3">
                        <span>No cost added</span>
                      </div>
                    )}
                    <hr className={classes.hr} />
                    <div className="mt-1 row g-1">
                      <div className=" d-flex justify-content-end align-items-center gap-1">
                        <span>Total : </span>{" "}
                        <div className="col-md-3">
                          <ControlledTextField
                            disabled
                            helperText={errors.totalAmount?.message}
                            error={!!errors.totalAmount}
                            name="totalAmount"
                            control={control}
                          />
                        </div>
                      </div>
                      {invoiceJSON?.net_amount?.text &&
                      invoiceJSON?.net_amount?.text !==
                        invoiceJSON?.totalAmount?.text ? (
                        <div className=" d-flex justify-content-end align-items-center gap-1">
                          <span>Discount : </span> -
                          <div className="col-md-3">
                            <ControlledTextField
                              helperText={errors.discount?.message}
                              error={!!errors.discount}
                              name="discount"
                              control={control}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className=" d-flex justify-content-end align-items-center gap-1">
                        <span>Grand Total : </span>{" "}
                        <div className="col-md-3">
                          <ControlledTextField
                            disabled
                            helperText={errors.grandTotal?.message}
                            error={!!errors.grandTotal}
                            name="grandTotal"
                            control={control}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className={classes.hr} />
                    <div className="mt-1 d-flex flex-row align-items-center gap-2">
                      <span>
                        {/* Payment is due 30 days from the invoice date. Payment due */}
                        Payment due date is on{" "}
                      </span>{" "}
                      <div className="col-md-4">
                        <ControlledDateField
                          helperText={errors.dueDate?.message}
                          error={!!errors.dueDate}
                          textLabel=""
                          name="dueDate"
                          control={control}
                        />
                      </div>
                    </div>
                    <hr />

                    <div className="mt-1  g-2">
                      <div className="col-md-4">
                        <ControlledTextField
                          helperText={errors.accountName?.message}
                          error={!!errors.accountName}
                          textLabel="Account Name"
                          name="accountName"
                          control={control}
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <ControlledTextField
                          helperText={errors.bankName?.message}
                          error={!!errors.bankName}
                          textLabel="Bank Name"
                          name="bankName"
                          control={control}
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <ControlledTextField
                          textLabel="IBAN"
                          helperText={errors.IBAN?.message}
                          error={!!errors.IBAN}
                          name="IBAN"
                          control={control}
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <ControlledTextField
                          textLabel="BIC"
                          helperText={errors.BIC?.message}
                          error={!!errors.BIC}
                          name="BIC"
                          control={control}
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <ControlledTextField
                          helperText={errors.account?.message}
                          error={!!errors.account}
                          textLabel="Account"
                          name="account"
                          control={control}
                        />
                      </div>
                    </div>
                    <hr className={classes.hr} />
                    <div className="mt-2 d-flex justify-content-end">
                      <AdminButtonComponent
                        className={classes.submitButton}
                        onClick={handleSubmit(onSubmitInvoice)}
                        title="Submit"
                      />
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      ) : null}
      <AdminModalComponent
        headerTitle={modalType === "cost" ? "Add new cost" : "Add new produce"}
        children={renderModalBody()}
        closeModal={onCloseModal}
        openModal={openAddNewItemModal}
      />
      <AdminModalComponent
        headerTitle={""}
        children={renderAccountWarningModalBody()}
        closeModal={onCloseModal}
        openModal={openAccountWarningModal}
      />
      <AdminModalComponent
        size="xl"
        headerTitle={"Add new company"}
        children={renderAddCompanyModalBody()}
        closeModal={onCloseAddCompanyModal}
        openModal={openNewCompanyModal}
      />
    </Sidebar>
  );
};

export default AIInvoicesContainer;
