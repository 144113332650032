import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  deleteWsData,
  setWsData,
  setWsDataVersion,
  updateWsData,
} from "../slices/wsDataSlice";
import { SocketData } from "../types/SocketData";
import useConnectWebSocket from "./useConnectWebSocket";

interface GenericData {
  _id: string;
  [key: string]: any;
}

interface EventData {
  _id: string;
  event: "CREATE" | "UPSERT" | "DELETE";
  data: GenericData;
}

interface LineUpdate {
  collectionName: SocketData;
  result: EventData[];
  date: string;
  latestVersion: number;
  eventName: "CREATE" | "UPSERT" | "DELETE";
}

interface CollectionUpdate {
  success: boolean;
  lines: LineUpdate[];
  message: string;
  responseType: string;
}

interface UpdateResponse {
  success: boolean;
  data: CollectionUpdate;
}

export default function useListenChanges() {
  const dispatch = useDispatch();
  const { socket } = useConnectWebSocket();

  const handleUpdate = useCallback(
    (response: UpdateResponse) => {
    
      if (!response.success) return;

      response.data.lines.forEach((line) => {
        const updateBatch: { id: string; data: GenericData }[] = [];
        const deleteIds: string[] = [];

        let shouldReCreate = line.result.find((itm) => itm.event === "CREATE");

        if (!!shouldReCreate) {
         
          dispatch(
            setWsData({
              key: line.collectionName as SocketData,
              value: {
                data: line.result.map((itm) => itm.data),
                version: line.latestVersion,
              },
            })
          );
          return;
        }

        line.result.forEach((item) => {
          switch (line.eventName) {
            case "CREATE":
            case "UPSERT":
              updateBatch.push({ id: item._id, data: item });
              break;
            case "DELETE":
              deleteIds.push(item._id);
              break;
            default:
              console.warn(`Unhandled event type: ${item.event}`);
              break;
          }
        });

        if (updateBatch.length > 0) {
          
          dispatch(
            updateWsData({
              key: line.collectionName as SocketData,
              items: updateBatch,
            })
          );
        }

        if (deleteIds.length > 0) {
          dispatch(
            deleteWsData({
              key: line.collectionName as SocketData,
              ids: deleteIds,
            })
          );
        }

        dispatch(
          setWsDataVersion({
            key: line.collectionName as SocketData,
            version: line.latestVersion,
          })
        );
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!socket) return;
  
    socket.on("getNewVersionData", handleUpdate);

    return () => {
      socket.off("getNewVersionData", handleUpdate);
    };
  }, [socket, handleUpdate]);

  return null;
}
