import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { downloadFile } from "../../../services/common";
import { makeStyles } from "@mui/styles";
import {
  downloadSurplusReport,
  getOnGoingPaymentStatements,
  getSurplusSellersBuyers,
  regenerateInvoice,
} from "../../../services/surplus-allocation";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ControlledComboBox from "../../../components/ControlledComboBox";
import ControlledTextField from "../../../components/ControlledTextField";
import PermissionWrapper from "../../../components/PermissionWrapper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const schema = yup.object().shape({
  seller: yup.object(),
  buyer: yup.object(),
  invoiceCode: yup.string(),
});

const useStyles = makeStyles({
  statementHeader: {
    fontSize: 24,
    color: "#000",
  },
  button: {
    background: "#E77228!important",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
});

interface BuyerSellers {
  name: string;
  id: string;
}

const ReportsContainer: React.FC = () => {
  const { control, watch, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      seller: undefined,
      buyer: undefined,
      invoiceCode: "",
    },
  });

  const classes = useStyles();
  const [allSellers, setAllSellers] = useState<BuyerSellers[]>([]);
  const [allBuyers, setAllBuyers] = useState<BuyerSellers[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  const watchSeller = watch("seller");
  const watchBuyer = watch("buyer");
  const watchInvoiceCode = watch("invoiceCode");

  const onDownloadReport = async () => {
    setLoading(true);
    const response = await downloadSurplusReport();
    setLoading(false);

    if (response) {
      downloadFile(response, "report");
    }
  };

  useEffect(() => {
    const getAllSellersBuyers = async () => {
      setFetchLoading(true);
      const response = await getSurplusSellersBuyers("invoice");
      setFetchLoading(false);
      if (response) {
        setAllBuyers(
          response.buyers.map((item: any) => ({
            name: item?.companyName,
            _id: item?.id,
          }))
        );
        setAllSellers(
          response.sellers.map((item: any) => ({
            name: item?.companyName,
            _id: item.id,
          }))
        );
      }
    };
    getAllSellersBuyers();
  }, []);

  const onDownloadStatement = async () => {
    setLoading(true);

    const downloaded = await getOnGoingPaymentStatements(
      (watchBuyer as any)?._id,
      (watchSeller as any)?._id
    );
    setLoading(false);

    if (downloaded) {
      reset({});
      window.open(downloaded.url);
    }
  };

  const onRegenerateInvoice = async () => {
    setLoading(true);
    const updated = await regenerateInvoice(watchInvoiceCode || "");
    setLoading(false);

    if (updated) {
      window.open(updated?.url);
      reset({
        invoiceCode: "",
      });
    }
  };

  return (
    <div>
      {loading && <LoadingIndicator loading />}
      <Tooltip title="See all allocation's report in an excel file">
        <AdminButtonComponent title="Report" onClick={onDownloadReport} />
      </Tooltip>

    {fetchLoading?<Skeleton count={1} style={{height:400}}/>: <>
    <div className="mt-5">
        <span className={classes.statementHeader}>Download Statement</span>

        <div className="row gap-2 d-flex align-items-center mt-1">
          <div className="col-md-3">
            <ControlledComboBox
              className={classes.comboBox}
              textLabel="Seller"
              options={allSellers}
              name={`seller`}
              control={control}
            />
          </div>
          <div className="col-md-3">
            <ControlledComboBox
              className={classes.comboBox}
              textLabel="Buyer"
              options={allBuyers}
              name={`buyer`}
              control={control}
            />
          </div>
          <div className="col-md-4 mt-3">
            <PermissionWrapper permission="ORDERS_REPORTS_DOWNLOAD_STATEMENT">
              <AdminButtonComponent
                disabled={!watchBuyer || !watchSeller}
                onClick={onDownloadStatement}
                className={classes.button}
                title="Download statement"
              />
            </PermissionWrapper>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <hr />
        <span className={classes.statementHeader}>Invoice Regeneration</span>

        <div className="row d-flex align-items-center mt-2">
          <div className="col-md-4">
            <ControlledTextField
              textLabel="Invoice Code"
              control={control}
              name="invoiceCode"
            />
          </div>
          <div className="col-md-4 mt-3">
            <Tooltip title="Regenerate invoice">
              <PermissionWrapper permission="ORDERS_REPORTS_INVOICE">
                <AdminButtonComponent
                  disabled={!watchInvoiceCode}
                  title="Regenerate"
                  onClick={onRegenerateInvoice}
                />
              </PermissionWrapper>
            </Tooltip>
          </div>
        </div>
      </div>
    </>}
    </div>
  );
};

export default ReportsContainer;
