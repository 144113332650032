import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  deleteWsData,
  setWsData,
  setWsDataVersion,
  updateWsData,
} from "../slices/wsDataSlice";
import { SocketData } from "../types/SocketData";
import useWebSocket from "./useWebSocket";

interface GenericData {
  _id: string;
  [key: string]: any;
}

interface EventData {
  _id: string;
  event: "CREATE" | "UPSERT" | "DELETE";
  data: GenericData;
}

interface CollectionUpdate {
  success: boolean;
  data: EventData[];
  collectionName: SocketData;
  versionNumber: number;
}

interface UpdateResponse {
  success: boolean;
  message: string;
  data: CollectionUpdate[];
  responseType: string;
}

export default function useSync(type: SocketData, version: number) {
  const dispatch = useDispatch();

  const updateLocalData = useCallback(
    (response: UpdateResponse) => {
      if (!response.success) return;

      response.data.forEach((update) => {
        if (!update.success) return;
        /* if (update.versionNumber === version) {
          console.log("Version is the same, no need to update");
          return;
        }
        */

        if (update.data.length === 0) {
          dispatch(
            setWsData({
              key: update.collectionName as SocketData,
              value: {
                data: [],
                version: update.versionNumber,
              },
            })
          );
          return;
        }

        const updateBatch: { id: string; data: GenericData }[] = [];
        const deleteIds: string[] = [];
        let shouldReCreate = update.data.find((itm) => itm.event === "CREATE");

        if (!!shouldReCreate) {
          dispatch(
            setWsData({
              key: update.collectionName as SocketData,
              value: {
                data: update.data.map((itm) => itm.data),
                version: update.versionNumber,
              },
            })
          );
          return;
        }

        update.data.forEach((item) => {
          switch (item.event) {
            case "CREATE":
            case "UPSERT":
              updateBatch.push({ id: item._id, data: item.data });
              break;
            case "DELETE":
              deleteIds.push(item._id);
              break;
            default:
              break;
          }
        });

        if (updateBatch.length > 0) {
          dispatch(
            updateWsData({
              key: update.collectionName as SocketData,
              items: updateBatch,
            })
          );
        }

        if (deleteIds.length > 0) {
          dispatch(
            deleteWsData({
              key: update.collectionName as SocketData,
              ids: deleteIds,
            })
          );
        }

        dispatch(
          setWsDataVersion({
            key: update.collectionName as SocketData,
            version: update.versionNumber,
          })
        );
      });
    },
    [dispatch]
  );

  const { retrieveData } = useWebSocket({
    callback: updateLocalData,
  });

  const sync = useCallback(() => {
    retrieveData(type, version);
  }, [retrieveData, type, version]);

  return { sync, updateLocalData };
}

export type { GenericData, EventData, CollectionUpdate, UpdateResponse };
