import { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ControlledTextField from "../../components/ControlledTextField";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import ControlledComboBox from "../../components/ControlledComboBox";
import { useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import { addNewCompany } from "../../services/company";
import { toast } from "react-toastify";
import {
  getCompanyTypesForms,
  getSubTypesWithProductTypeId,
  getTrimmedString,
} from "../../services/common";
import { Helmet } from "react-helmet";

export interface Specialities {
  _id: string;
  name: string;
}

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  surname: yup.string().required("Surname is required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  accountEmail: yup.string().email("Must be a valid email"),

  phoneNumber: yup.string().required("Phone number is required"),
  specialities: yup.array(),
  buyerInterests: yup.array(),
  sellerInterests: yup.array(),
  companyName: yup.string().required("Company name is required"),
  VAT: yup.string(),
  CRN: yup.string(),
  role: yup.string().required("Role is required"),
  country: yup.object().required("Country is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  postCode: yup.string().required("Post Code is required"),
  address: yup.string().required("Address is required"),
});

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  mainBox: {
    marginTop: 10,
    padding: 25,
    width: "100%",
    background: "white",
    borderRadius: "20px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  },
  headerTitle: {
    color: "#000",
    fontSize: 18,
  },
  hr: {
    opacity: 0.05,
  },
});

const AddNewCompanyContainer = () => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const classes = useStyles();

  const watchRole = watch("role");

  const navigate = useNavigate();
  const countries = useAppSelector((state) => state.auth.countries);

  const newCountries = countries.map((item) => ({
    id: item.geonameId,
    name: item.countryName,
  }));

  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [specialities, setSpecialities] = useState<Specialities[]>([]);
  const [produceTypes, setProduceTypes] = useState<Specialities[]>([]);
  const [isSpecialitiesFocus, setIsSpecialitiesFocus] = useState(false);
  const [isInterestsFocus, setIsInterestsFocus] = useState(false);

  const onFocusSpecialities = () => {
    setIsSpecialitiesFocus(true);
  };

  const onFocusInterests = () => {
    setIsInterestsFocus(true);
  };

  useEffect(() => {
    if (isSpecialitiesFocus && !specialities.length) {
      const getSpecialities = async () => {
        setLoading(true);
        const response = await getCompanyTypesForms();
        setLoading(false);
        if (response) {
          setSpecialities(
            response?.specialities?.map((item: any) => ({
              name: item?.name,
              _id: item?.id,
            }))
          );
        }
      };

      getSpecialities();
    }
  }, [isSpecialitiesFocus, specialities.length]);

  useEffect(() => {
    if (isInterestsFocus && !produceTypes.length) {
      const getProduceTypes = async () => {
        setLoading(true);
        const response = await getSubTypesWithProductTypeId();
        setLoading(false);
        if (response) {
          setProduceTypes(
            response?.data?.map((item: any) => ({
              name: item?.name,
              _id: item?._id,
            }))
          );
        }
      };

      getProduceTypes();
    }
  }, [isInterestsFocus, produceTypes.length]);

  const onAddCompany = async (data: any) => {
    const companySpecialityIds = data?.specialities.map((s: any) => s?._id);
    const buyerInterestIds = data?.buyerInterests.map((s: any) => s?._id);
    const sellerInterestIds = data?.sellerInterests.map((s: any) => s?._id);

    const dataScheme = {
      name: data?.name,
      surname: data?.surname,
      email: data?.email,
      mobileNo: `${selectedCountry}${data?.phoneNumber}`,
      companyName: data?.companyName,
      address: data?.address,
      postCode: data?.postCode,
      city: data?.city,
      state: data?.state,
      country: data?.country?.name,
      role: data?.role,
      CRN: data?.CRN,
      VAT: data?.VAT,
      accountEmail: data?.accountEmail,
      companySpecialityIds: companySpecialityIds?.length
        ? companySpecialityIds
        : undefined,
      buyerInterestIds: buyerInterestIds?.length ? buyerInterestIds : undefined,
      sellerInterestIds: sellerInterestIds?.length
        ? sellerInterestIds
        : undefined,
    };
    const finalData = getTrimmedString(dataScheme);
    setLoading(true);

    if (!selectedCountry) {
      setLoading(false);
      toast.error("Please select phone number code.");
    } else {
      const added = await addNewCompany(finalData);
      setLoading(false);

      if (added) {
        navigate("/company-list");
      }
    }
  };

  return (
    <Sidebar
      renderButton={
        <AdminButtonComponent
          title="Submit"
          onClick={handleSubmit(onAddCompany)}
        />
      }
      pageTitle="New Company"
    >
      <Helmet>
        <title>Add New Company | FruPro Admin Portal</title>
      </Helmet>
      {loading && <LoadingIndicator loading={loading} />}
      <div className={classes.mainBox}>
        <p className={classes.headerTitle}>User Information</p>
        <hr className={classes.hr} />
        <div className="row g-4">
          <div className="col-md-4">
            <ControlledTextField
              textLabel="Name"
              name="name"
              control={control}
              error={!!errors.name}
              helperText={errors?.name?.message as string}
            />
          </div>
          <div className="col-md-4">
            <ControlledTextField
              textLabel="Surname"
              name="surname"
              control={control}
              error={!!errors.surname}
              helperText={errors?.surname?.message as string}
            />
          </div>
          <div className="col-md-4">
            <ControlledTextField
              textLabel="Email"
              name="email"
              control={control}
              error={!!errors.email}
              helperText={errors?.email?.message as string}
            />
          </div>
          <div className="col-md-4">
            <ControlledComboBox
              options={["ADMIN"]}
              className={classes.comboBox}
              textLabel="Role"
              name="role"
              control={control}
              error={!!errors.role}
              helperText={errors?.role?.message as string}
            />
          </div>
          <div className="col-md-4">
            <PhoneNumberInput
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              name="phoneNumber"
              control={control}
              error={!!errors.phoneNumber}
              helperText={errors?.phoneNumber?.message as string}
            />
          </div>
          <div className="col-md-4">
            <ControlledTextField
              textLabel="Account Email"
              name="accountEmail"
              control={control}
              error={!!errors.accountEmail}
              helperText={errors?.accountEmail?.message as string}
            />
          </div>
        </div>
        <div className="mt-4">
          <p className={classes.headerTitle}>Company Information</p>
          <hr className={classes.hr} />
          <div className="row g-4">
            <div className="col-md-4">
              <ControlledTextField
                textLabel="Company Name"
                name="companyName"
                control={control}
                error={!!errors.companyName}
                helperText={errors?.companyName?.message as string}
              />
            </div>
            {watchRole === "NORMAL" ? null : (
              <>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="Company House Registration(CRN)"
                    name="CRN"
                    control={control}
                    error={!!errors.CRN}
                    helperText={errors?.CRN?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="VAT Number"
                    name="VAT"
                    control={control}
                    error={!!errors.VAT}
                    helperText={errors?.VAT?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledComboBox
                    multiple
                    className={classes.comboBox}
                    onFocus={onFocusSpecialities}
                    options={specialities}
                    textLabel="Specialities"
                    name="specialities"
                    control={control}
                    error={!!errors.specialities}
                    helperText={errors?.specialities?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledComboBox
                    onFocus={onFocusInterests}
                    multiple
                    className={classes.comboBox}
                    options={produceTypes}
                    textLabel="Buyer Interests"
                    name="buyerInterests"
                    control={control}
                    error={!!errors.buyerInterests}
                    helperText={errors?.buyerInterests?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledComboBox
                    multiple
                    onFocus={onFocusInterests}
                    className={classes.comboBox}
                    options={produceTypes}
                    textLabel="Seller Interests"
                    name="sellerInterests"
                    control={control}
                    error={!!errors.sellerInterests}
                    helperText={errors?.sellerInterests?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledComboBox
                    className={classes.comboBox}
                    options={newCountries}
                    textLabel="Country"
                    name="country"
                    control={control}
                    error={!!errors.country}
                    helperText={errors?.country?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="State"
                    name="state"
                    control={control}
                    error={!!errors.state}
                    helperText={errors?.state?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="City"
                    name="city"
                    control={control}
                    error={!!errors.city}
                    helperText={errors?.city?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="Post Code"
                    name="postCode"
                    control={control}
                    error={!!errors.postCode}
                    helperText={errors?.postCode?.message as string}
                  />
                </div>
                <div className="col-md-12">
                  <ControlledTextField
                    textLabel="Address"
                    name="address"
                    control={control}
                    error={!!errors.address}
                    helperText={errors?.address?.message as string}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default AddNewCompanyContainer;
