import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useParams } from "react-router-dom";
import { getAdminLogsDetail } from "../../services/admin-logs";
import { getDeepDifference } from "@gthrm/deep-diff";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Helmet } from "react-helmet";

const AdminLogsDetail = () => {
  const [logDetail, setLogDetail] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const { id } = useParams();

  const differenceData =
    logDetail?.oldData &&
    logDetail?.newData &&
    logDetail?.type !== "ORDER_ADDITIONAL_COST_ADD" &&
    logDetail?.type !== "FINMID_SELLER_COMPANY_CREATE" //ı put this condition because it was giving error for this types
      ? getDeepDifference(logDetail?.oldData, logDetail?.newData)
      : null;

  useEffect(() => {
    if (id) {
      const getData = async () => {
        setLoading(true);

        const response = await getAdminLogsDetail(id);

        setLoading(false);

        if (response) {
          setLogDetail(response[0]);
        }
      };
      getData();
    }
  }, [id]);

  return (
    <Sidebar pageTitle="Log Detail">
       <Helmet>
          <title>Log Detail | FruPro Admin Portal</title>
        </Helmet>
      {loading ? (
        <Skeleton count={1} style={{ height: 600 }} />
      ) : logDetail?._id ? (
        <div className="container">
          <div className="row g-3">
            <div className="col-md-12 d-flex gap-3 flex-column">
              <div>
                <span className="fw-bold">Type : </span>
                {logDetail?.type}
              </div>
              <div>
                <span className="fw-bold">Admin : </span>
                {logDetail?.admin?.[0]?.fullName}
              </div>
              <div>
                <span className="fw-bold">Date : </span>
                {moment(logDetail?.createdAt).format("DD/MM/YYYY")}
              </div>
              <div>
                <span className="fw-bold">Message : </span>
                {logDetail?.message}
              </div>
              {differenceData ? (
                <div>
                  <span className="fw-bold">Data difference :</span>
                  <br />
                  {JSON.stringify(differenceData, null, 4)}
                </div>
              ) : logDetail?.type === "FINMID_SELLER_COMPANY_CREATE" ? (
                <>
                  {" "}
                  <span className="fw-bold">Data difference :</span>
                  {JSON.stringify(
                    logDetail?.newData?.verifiedBuyer?.creditLimit?.history,
                    null,
                    4
                  )}
                </>
              ) : logDetail?.type === "ORDER_ADDITIONAL_COST_ADD" ? (
                <>
                  <span className="fw-bold">Data difference :</span>
                  {JSON.stringify(
                    logDetail?.newData?.offer?.additionalCosts,
                    null,
                    4
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </Sidebar>
  );
};

export default AdminLogsDetail;
