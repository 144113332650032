import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Sidebar } from "../../components/Sidebar";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import DualListBox from "./DualListBox";
import { Permission } from "../../permissions";
import {
  addPermissionsToAdminGroup,
  createAdminGroup,
  deleteAdminGroup,
  deleteAdminGroupPermissions,
  getAdminGroupPermissions,
  getAdminGroups,
  getAdminUsersForGroups,
  getGroupUsers,
  getPermissions as getPermissionsRequest,
  updateGroupUsers,
} from "../../services/admin-groups";
import { useConfirmDialog } from "./SelectConfirmDialog";
import { toast } from "react-toastify";
import { useSelectDialog } from "./SelectBoxDialog";
import { useInputDialog } from "./InputDialog";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { sortArray } from "../../services/common";
import { adminUsersObjectType } from "../AdminUsers/interfaces";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  mainBox: {
    background: "white",
    borderRadius: 20,
    padding: 20,
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#E77228",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#E77228",
    },
  },
  containerBox: {
    padding: 10,
    paddingTop: 40,
    paddingBottom: 40,
  },
  tabsBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonBox: {
    display: "flex",
    gap: 5,
    flexDirection: "row",
  },
});

export interface ItemType {
  name: any;
  _id: string;
}

export default function AdminGroupsContainer() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [refetchGroupPermissions, setRefetchGroupPermissions] =
    useState<number>(0);
  const [refetchGroups, setRefetchGroups] = useState<number>(0);
  const [refetchAdminUsers, setRefetchAdminUsers] = useState<number>(0);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedList, setSelectedList] = useState<ItemType>({
    name: "",
    _id: "",
  });
  const [selectedListItems, setSelectedListItems] = useState<ItemType[]>([]);
  const [selectedGroupPermissions, setSelectedGroupPermissions] = useState<
    {
      name: string;
      _id: string;
    }[]
  >([]);

  const { hasPermission } = usePermissions();

  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [groups, setGroups] = useState<ItemType[]>([]);
  const [adminUsers, setAdminUsers] = useState<adminUsersObjectType[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<string>(
    groups?.[0]?._id
  );

  const {
    ConfirmDialog: RemoveGroupConfirmDialog,
    openDialog: openRemoveGroupConfirmDialog,
  } = useConfirmDialog({
    description: "Are you sure about to remove this group?",
    title: "Remove Group",
  });

  const { openDialog: openAddGroupDialog, InputDialog: AddGroupInputDialog } =
    useInputDialog({
      title: "Create a New Group",
      description: "Please enter a group name.",
    });

  useEffect(() => {
    if (!hasPermission("ADMIN_USER_GROUP_ACCESS")) return;
    if (activeTabIndex === 0 && selectedGroupId) {
      const getGroupPermission = async () => {
        const response = await getAdminGroupPermissions([selectedGroupId]);

        if (response?.data) {
          const groupPerPermission = response?.data?.map(
            (item: any) => item.permissionId[0]
          );
          !selectedList?._id && setSelectedList && setSelectedList(groups?.[0]);

          setSelectedListItems(groupPerPermission);
          setSelectedGroupPermissions(groupPerPermission);
        } else {
          setSelectedListItems([]);
          setSelectedGroupPermissions([]);
        }
      };
      getGroupPermission();
    }
  }, [
    selectedGroupId,
    activeTabIndex,
    refetchGroupPermissions,
    hasPermission("ADMIN_USER_GROUP_ACCESS"),
  ]);

  useEffect(() => {
    if (!hasPermission("ADMIN_USER_GROUP_ACCESS")) return;
    if (activeTabIndex == 1) {
      const getAdminUsers = async () => {
        const response = await getAdminUsersForGroups();

        if (response?.items) {
          const adminUserGroups = groups.filter((gr) =>
            response?.items[0]?.groups
              .map((item: any) => item._id)
              .includes(gr._id as any)
          );

          setSelectedList &&
            setSelectedList({
              name: response?.items[0].fullName,
              _id: response?.items[0]?.id,
            });
          setSelectedListItems(adminUserGroups);

          setSelectedGroupId(response?.items[0]?.id);
          setAdminUsers(response?.items);
        }
      };
      getAdminUsers();
    }
  }, [
    activeTabIndex,
    refetchAdminUsers,
    refetchGroupPermissions,
    hasPermission("ADMIN_USER_GROUP_ACCESS"),
  ]);

  useEffect(() => {
    if (!hasPermission("ADMIN_USER_GROUP_ACCESS")) return;
    if (activeTabIndex === 0) {
      const getGroupsWithPermissions = async () => {
        try {
          const response = await getAdminGroups();

          if (!response.success) {
            throw new Error("Error fetching groups");
          }
          setSelectedGroupId(sortArray(response?.data)[0]?._id);
          setGroups(sortArray(response?.data));
        } catch (error) {
          console.warn(error);
        }
      };

      getGroupsWithPermissions();
    }
  }, [hasPermission("ADMIN_USER_GROUP_ACCESS"), activeTabIndex, refetchGroups]);

  useEffect(() => {
    if (!hasPermission("ADMIN_USER_GROUP_ACCESS")) return;
    const getPermissions = async () => {
      try {
        const response = await getPermissionsRequest();

        if (!response.success) {
          throw new Error("Error fetching permissions");
        }

        setPermissions(response.data as Permission[]);
      } catch (error) {
        console.warn(error);
      }
    };

    getPermissions();
  }, [hasPermission("ADMIN_USER_GROUP_ACCESS")]);

  const getAdmins = useCallback(async () => {
    const response = await getAdminUsersForGroups();

    if (response?.items) {
      setAdminUsers(response?.items);
    }
  }, []);

  const handleRemoveAdminGroup = useCallback(async (item: ItemType) => {
    try {
      const isAccepted = await openRemoveGroupConfirmDialog();
      if (!isAccepted) return;

      setLoading(true);
      const removeResponse = await deleteAdminGroup(item._id);

      if (!removeResponse.success) return;
      toast.success("Group removed successfully.");
      setRefetchGroups((prev: number) => prev + 1);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleRemoveAdminGroupPermissions = useCallback(
    async (removeItem: ItemType) => {
      try {
        const removeResponse = await deleteAdminGroupPermissions(
          selectedGroupId,
          [{ permissionId: removeItem._id }]
        );

        if (!removeResponse.success) return;
        const filteredPermissions = selectedGroupPermissions.filter(
          (per) => per._id !== removeItem._id
        );
        setSelectedGroupPermissions(filteredPermissions);
        setRefetchGroupPermissions((prev: number) => prev + 1);
      } catch (error) {
        console.warn(error);
      }
    },
    [selectedGroupId, selectedGroupPermissions]
  );

  const handleAddAdminGroupPermissions = useCallback(
    async (item: ItemType) => {
      try {
        const addResponse = await addPermissionsToAdminGroup(selectedGroupId, [
          { permissionId: item._id },
        ]);

        if (!addResponse.success) {
          throw new Error("Error adding permissions");
        } else {
          setSelectedGroupPermissions((prev) => [...prev, item]);
          setRefetchGroupPermissions((prev: number) => prev + 1);
        }
      } catch (error) {
        console.warn(error);
      }
    },
    [selectedGroupId]
  );

  const handleCreateAdminGroup = useCallback(async () => {
    try {
      const groupName = await openAddGroupDialog();

      if (!groupName) return;

      setLoading(true);
      const addResponse = await createAdminGroup(groupName);

      if (!addResponse.success) return;

      setRefetchGroups((prev) => prev + 1);
      toast.success("New admin group created successfully");
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  }, [openAddGroupDialog]);

  const handleAddUserToGroup = useCallback(
    async (item: ItemType) => {
      try {
        const newSelectedItems = [...selectedListItems, item];

        const addResponse = await updateGroupUsers(
          selectedList?._id,
          newSelectedItems.map((item) => item._id)
        );
        if (!addResponse.success)
          throw new Error("Error adding user to group.");

        setSelectedListItems(newSelectedItems);
      } catch (error) {
        console.warn(error);
      }
    },
    [selectedListItems, selectedGroupId]
  );

  const handleRemoveUserFromGroup = useCallback(
    async (removeItem: ItemType) => {
      const newGroupIds = selectedListItems.filter(
        (item) => item._id !== removeItem._id
      );
      try {
        const removeResponse = await updateGroupUsers(
          selectedList?._id,
          newGroupIds.map((item) => item._id)
        );
        if (!removeResponse.success) throw new Error("Error removing user");

        setSelectedListItems(newGroupIds);
      } catch (error) {
        console.warn(error);
      }
    },
    [selectedListItems, selectedGroupId]
  );

  const groupListItems: any = [];

  const userListItems: any = [];

  const renderContainers = useCallback(() => {
    switch (activeTabIndex) {
      case 0:
        return (
          <Box display={"flex"}>
            <DualListBox
              getAdmins={getAdmins}
              adminUsers={adminUsers}
              activeTabIndex={activeTabIndex}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              selectedListItems={selectedListItems}
              setSelectedListItems={setSelectedListItems}
              groups={groups}
              selectedGroupPermissions={selectedGroupPermissions}
              selectedGroupId={selectedGroupId}
              setSelectedGroupId={setSelectedGroupId}
              permissions={permissions}
              key={"group-permissions"}
              rightSectionTitle="Permissions"
              onRightSectionAdd={handleAddAdminGroupPermissions}
              onLeftSectionAdd={handleCreateAdminGroup}
              onLeftSectionRemove={handleRemoveAdminGroup}
              onRightSectionRemove={handleRemoveAdminGroupPermissions}
            />
          </Box>
        );
      case 1:
        return (
          <Box display={"flex"}>
            <DualListBox
              getAdmins={getAdmins}
              adminUsers={adminUsers}
              activeTabIndex={activeTabIndex}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              selectedListItems={selectedListItems}
              setSelectedListItems={setSelectedListItems}
              selectedGroupId={selectedGroupId}
              groups={groups}
              selectedGroupPermissions={selectedGroupPermissions}
              setSelectedGroupId={setSelectedGroupId}
              permissions={permissions}
              key={"group-users"}
              rightSectionTitle="Groups"
              onRightSectionAdd={handleAddUserToGroup}
              onRightSectionRemove={handleRemoveUserFromGroup}
            />
          </Box>
        );
      default:
        return (
          <Box display={"flex"}>
            <DualListBox
              getAdmins={getAdmins}
              activeTabIndex={activeTabIndex}
              selectedList={selectedList}
              adminUsers={adminUsers}
              setSelectedList={setSelectedList}
              selectedListItems={selectedListItems}
              setSelectedListItems={setSelectedListItems}
              selectedGroupId={selectedGroupId}
              groups={groups}
              selectedGroupPermissions={selectedGroupPermissions}
              setSelectedGroupId={setSelectedGroupId}
              permissions={permissions}
              rightSectionTitle="Permissions"
              onRightSectionAdd={handleAddAdminGroupPermissions}
              onLeftSectionAdd={handleCreateAdminGroup}
              onLeftSectionRemove={handleRemoveAdminGroup}
              onRightSectionRemove={handleRemoveAdminGroupPermissions}
            />
          </Box>
        );
    }
  }, [
    selectedGroupPermissions,
    activeTabIndex,
    groupListItems,
    handleAddAdminGroupPermissions,
    handleAddUserToGroup,
    handleCreateAdminGroup,
    handleRemoveAdminGroup,
    handleRemoveAdminGroupPermissions,
    handleRemoveUserFromGroup,
    userListItems,
  ]);

  return (
    <Sidebar pageTitle="Admin Groups">
       <Helmet>
          <title>Admin Groups | FruPro Admin Portal</title>
        </Helmet>
      <PermissionWrapper
        unauthorizedComponent={true}
        permission="ADMIN_USER_GROUP_ACCESS"
      >
        {fetchLoading ? (
          <Skeleton count={1} style={{ height: 600 }} />
        ) : (
          <Box className={classes.mainBox}>
            {loading && <LoadingIndicator loading />}
            <Box className={classes.tabsBox}>
              <Tabs
                className={classes.tabs}
                value={activeTabIndex}
                onChange={(_, i) => setActiveTabIndex(i)}
              >
                <Tab
                  onClick={() => setSelectedGroupId("")}
                  value={0}
                  label="Groups"
                />
                <Tab
                  onClick={() => setSelectedGroupId("")}
                  value={1}
                  label="Users"
                />
              </Tabs>
            </Box>
            <Box className={classes.containerBox}>{renderContainers()}</Box>
            {RemoveGroupConfirmDialog}
            {AddGroupInputDialog}
          </Box>
        )}
      </PermissionWrapper>
    </Sidebar>
  );
}
