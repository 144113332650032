import React from "react";
import { CompanyDetailsStateType } from "./interfaces";
import { SchemaType } from "./CompanyDetailsContainer";
import { makeStyles } from "@mui/styles";
import { FieldErrors } from "react-hook-form";
import ControlledTextField from "../../../components/ControlledTextField";
import ControlledComboBox from "../../../components/ControlledComboBox";
import { useAppSelector } from "../../../hooks/hooks";
import { CountriesStateType } from "../../../slices/authSlice";
const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
});

interface PropsType {
  control: any;
  countries: {
    id: number;
    name: string;
  }[];
  errors: FieldErrors<SchemaType>;
  companyData: CompanyDetailsStateType | undefined;
}

const ConfidentialityInfoContainer: React.FC<PropsType> = ({
  control,
  companyData,
  countries,
  errors,
}) => {
  const classes = useStyles();



  return (
    <div className="container">
      <div className="row g-3">
        <div className="col-4">
          <ControlledTextField
            control={control}
            name="companyCode"
            disabled
            error={!!errors.companyCode}
            helperText={errors.companyCode?.message}
            textLabel="Company Code"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            control={control}
            error={!!errors.CRN}
            helperText={errors.CRN?.message}
            name="CRN"
            textLabel="Company House Registration(CRN)"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            control={control}
            error={!!errors.VAT}
            helperText={errors.VAT?.message}
            name="VAT"
            textLabel="VAT Number"
          />
        </div>
        <hr />

        <h4>Request Information</h4>
        <div className="col-4">
          <ControlledTextField
            error={!!errors.reqBy}
            helperText={errors.reqBy?.message}
            control={control}
            name="reqBy"
            disabled
            textLabel="Request By"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors.reqEmail}
            helperText={errors.reqEmail?.message}
            control={control}
            name="reqEmail"
            disabled
            textLabel="Requester Email"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            control={control}
            error={!!errors.reqDate}
            helperText={errors.reqDate?.message}
            name="reqDate"
            disabled
            textLabel="Request Date"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            control={control}
            error={!!errors.verifyDate}
            helperText={errors.verifyDate?.message}
            name="verifyDate"
            disabled
            textLabel="Verify Date"
          />
        </div>

        <hr />

        <h4>Principal Place Of Business</h4>
        <div className="col-3">
          {companyData?.principalPlaceCountry.code ||
          companyData?.principalPlaceCountry.name ? (
            <ControlledComboBox
              className={classes.comboBox}
              options={countries}
              control={control}
              name="principalCountry"
              error={!!errors?.principalCountry}
              helperText={errors?.principalCountry?.name?.message}
              textLabel="Country"
            />
          ) : null}
        </div>
        <div className="col-3">
          <ControlledTextField
            control={control}
            name="principalState"
            error={!!errors.principalState}
            helperText={errors.principalState?.message}
            textLabel="State"
          />
        </div>
        <div className="col-3">
          <ControlledTextField
            control={control}
            name="principalCity"
            error={!!errors.principalCity}
            helperText={errors.principalCity?.message}
            textLabel="City"
          />
        </div>
        <div className="col-3">
          <ControlledTextField
            control={control}
            name="principalPostCode"
            error={!!errors.principalPostCode}
            helperText={errors.principalPostCode?.message}
            textLabel="Post Code"
          />
        </div>
        <div className="col-12">
          <ControlledTextField
            control={control}
            name="principalAddress"
            error={!!errors.principalAddress}
            helperText={errors.principalAddress?.message}
            textLabel="Address"
          />
        </div>

        <hr />

        <h4>Registered Address</h4>
        <div className="col-3">
          {companyData?.registerCountry.code ||
          companyData?.registerCountry.name ? (
            <ControlledComboBox
              className={classes.comboBox}
              options={countries}
              control={control}
              name="registerCountry"
              error={!!errors?.registerCountry}
              helperText={errors?.registerCountry?.name?.message}
              textLabel="Country"
            />
          ) : null}
        </div>
        <div className="col-3">
          <ControlledTextField
            control={control}
            name="registerState"
            error={!!errors.registerState}
            helperText={errors.registerState?.message}
            textLabel="State"
          />
        </div>
        <div className="col-3">
          <ControlledTextField
            control={control}
            name="registerCity"
            error={!!errors.registerCity}
            helperText={errors.registerCity?.message}
            textLabel="City"
          />
        </div>
        <div className="col-3">
          <ControlledTextField
            control={control}
            name="registerPostCode"
            error={!!errors.registerPostCode}
            helperText={errors.registerPostCode?.message}
            textLabel="Post Code"
          />
        </div>
        <div className="col-12">
          <ControlledTextField
            control={control}
            name="registerAddress"
            error={!!errors.registerAddress}
            helperText={errors.registerAddress?.message}
            textLabel="Address"
          />
        </div>
      </div>
    </div>
  );
};

export default ConfidentialityInfoContainer;
