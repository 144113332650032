import { useEffect } from "react";
import "./index.css";
import AppRoutes from "./AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { AdminUserInfo } from "./services/auth";
import { getCountries, getCurrencies } from "./services/common";
import { useAppDispatch, useAppSelector } from "./hooks/hooks";
import {
  setAdminInfoLoading,
  setAdminUserInfo,
  setCountries,
  setCurrencies,
} from "./slices/authSlice";
import "remixicon/fonts/remixicon.css";
import "react-multi-carousel/lib/styles.css";
import LoadingIndicator from "./components/LoadingIndicator";
import useListenChanges from "./hooks/useListenChanges";

function App() {
  const tradeAppLoading = useAppSelector((state) => state.auth.tradeAppLoading);
  let token: string | null = localStorage.getItem("accessToken");
  const dispatch = useAppDispatch();
  useListenChanges();

  useEffect(() => {
    if (token) {
      const getAdminUserData = async () => {
        try {
          const response = await AdminUserInfo();
          const { items } = await getCountries();
          const currency = await getCurrencies();

          if (currency) {
            dispatch(
              setCurrencies(currency.filter((item: any) => item.code !== "CAD"))
            );
          }
          if (response) {
            dispatch(setAdminUserInfo(response));
          }
          if (items) {
            dispatch(setCountries(items));
          }
        } catch (error) {
          console.warn(error);
        } finally {
          dispatch(setAdminInfoLoading(false));
        }
      };
      getAdminUserData();
    }
  }, [token, dispatch]);

  return (
    <>
      <ThemeProvider theme={theme}>
        {tradeAppLoading ? (
          <LoadingIndicator title="Please,wait.." loading={tradeAppLoading} />
        ) : (
          <AppRoutes />
        )}
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
      </ThemeProvider>
    </>
  );
}

export default App;
