import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { makeStyles } from "@mui/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import AllocationCompany from "./components/AllocationCompany";
import { useAppSelector } from "../../hooks/hooks";
import { toast } from "react-toastify";
import {
  AdditionalCostsType,
  BuyersSellersStateType,
  CompanyDetailType,
  Country,
  RepaymentTermsType,
  UnitsType,
} from "./components/interfaces";
import { BankAccountsDefaultTypes } from "./components/BankInfo";
import { Currency } from "./components/interfaces";
import InventoryInfo from "./components/InventoryInfo";
import {
  getBuyerSellerOnlyCanAllocates,
  getBuyerSellerWithoutCanAllocates,
  getSubTypesWithProductTypeId,
  getSupplierInventories,
  getUnits,
} from "../../services/common";
import SellerCompany from "./components/SellerCompany";
import dayjs, { Dayjs } from "dayjs";
import Produces from "./components/Produces";
import AdditionalCosts from "./components/AdditionalCosts";
import RebateDiscount from "./components/RebateDiscount";
import InvoiceFactoring from "./components/InvoiceFactoring";
import AllocationSummary from "./components/AllocationSummary";
import OrderDocuments from "./components/OrderDocuments";
import { DocumentsType } from "./components/interfaces";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
  createInventoryAndAllocation,
  createShippingDocument,
  getBankDefaultInfos,
  getPaymentTerms,
  getSellerAddresses,
} from "../../services/surplus-allocation";
import { addOrderDocuments } from "../../services/order-documents";
import { getSellerFeesWithoutPagination } from "../../services/fees";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getCostTypes } from "../../services/additional-cost-types";
import BankInfo from "./components/BankInfo";
import Notifications from "./components/Notifications";
import PaymentTerms from "./components/PaymentTerms";
import moment from "moment";

export interface PaymentTermsType {
  _id: string;
  paymentTerm: string;
  fee: number;
  show: boolean;
}

export interface CompanyFeesType {
  _id: string;
  buyer_fee: number;
  companyId: string;
  seller_fee: number;
  finmidFeesType: string;
}

const useStyles = makeStyles({
  hr: {
    opacity: 0.05,
  },
  main: {
    background: "white",
    borderRadius: 20,
    width: "100%",
    padding: 20,
    marginBottom: 20,
  },
  sectionsFlex: {
    "@media (min-width: 780px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  create: {
    border: "none",
    borderRadius: 20,
    width: "100%",
    background: "#E77228",
    color: "white",
    marginTop: 17,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    height: "45px",

    "&:disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },
  cancel: {
    borderRadius: 20,
    width: "100%",
    color: "#E77228!important",
    border: "1px solid #E77228",
    background: "transparent",
    marginTop: 17,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    height: "45px",

    "&:disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },
});

const allocationSchema = yup.object().shape({
  buyerCompany: yup.object().required("Buyer company is required"),
  sellerCompany: yup.object().required("Seller company is required"),
  creditLimitAmount: yup.string(),
  creditLimitCurrency: yup.object().required("Currency is required"),
  supplier: yup.string(),
  consignmentNumber: yup.string().required("Consignment number is required"),
  buyerReferenceNumber: yup.string(),
  sellerReferenceNumber: yup.string(),
  orderDate: yup.string().required("Order date is required"),
  invoiceDate: yup.string().required("Invoice date is required"),
  referenceNumber: yup.string(),
  supplierReference: yup.string(),
  receiveDate: yup.string().nullable().required("Receive date is required"),
  location: yup.object().nullable().required("Location is required"),
  produceType: yup.object().nullable(),
  produceName: yup.string(),
  produceCurrency: yup.string(),
  advisoryPrice: yup.string(),
  returnPrice: yup.string(),
  quantity: yup.string(),
  lineItemAmount: yup.string(),
  unit: yup.object(),
  packingSize: yup.string(),
  boxesInPallet: yup.string(),
  variety: yup.string(),
  category: yup.string().nullable(),
  brand: yup.string(),
  documentType: yup.string(),
  grower: yup.string(),
  haulier: yup.string(),
  countryOfOrigin: yup.object().nullable(),
  additionalCosts: yup.object().nullable(),
  vatRate: yup.string(),
  description: yup.string(),
  amount: yup.number(),
  applyRebateDiscount: yup.boolean(),
  rebateDiscount: yup.string(),
  rebateDiscountAmount: yup.string(),
  applyInvoiceFactoring: yup.boolean(),
  isBuyerChecked: yup.boolean(),
  isCreateInvoice: yup.boolean(),
  upfrontPaymentAmount: yup.string(),
  upfrontPaymentAmountPercentage: yup.string(),
  finalPaymentAmount: yup.string(),
  finalPaymentAmountPercentage: yup.string(),
  feePercentage: yup.string(),
  selectedBankInfo: yup.object(),
});

const CreateNewSurplusContainer = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    control,
    reset,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(allocationSchema),
    defaultValues: {
      produceCurrency: "",
      produceType: null,
      lineItemAmount: "",
      unit: undefined,
      selectedBankInfo: {},
      sellerCompany: {},
      location: undefined,
      buyerCompany: {},
      supplier: "",
      applyInvoiceFactoring: false,
      countryOfOrigin: undefined,
      orderDate: dayjs(Date.now()) as any,
      invoiceDate: dayjs(Date.now()) as any,
      receiveDate: dayjs(Date.now()) as any,
      creditLimitCurrency: {
        name: "GBP",
        id: "POUND",
      },
      documentType: "",
      isBuyerChecked: false,
      additionalCosts: null,
      vatRate: "",
      buyerReferenceNumber: "",
      amount: 0,
      feePercentage: "",
      sellerReferenceNumber: "",
      rebateDiscountAmount: "",
      category: "",
      rebateDiscount: undefined,
      applyRebateDiscount: false,
      isCreateInvoice: true,
      consignmentNumber: "",
      referenceNumber: "",
      supplierReference: "",
      upfrontPaymentAmount: "",
      finalPaymentAmount: "",
      upfrontPaymentAmountPercentage: "",
      finalPaymentAmountPercentage: "",
    },
  });

  const [allocationBuyers, setAllocationBuyers] = useState<
    {
      name: string;
      id: string;
    }[]
  >([]);
  const [allocationSellers, setAllocationSellers] = useState<
    {
      name: string;
      id: string;
    }[]
  >([]);
  const [finmidLimit, setFinmidLimit] = useState(0);
  const [tempSellers, setTempSellers] = useState<CompanyDetailType[]>([]);
  const [tempBuyers, setTempBuyers] = useState<CompanyDetailType[]>([]);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [feeOption, setFeeOption] = useState("30 Days");
  const [companyBuyerFeesData, setCompanyBuyerFeesData] = useState<
    CompanyFeesType[]
  >([]);
  const [companySellerFeesData, setCompanySellerFeesData] = useState<
    CompanyFeesType[]
  >([]);
  const [sellerFee, setSellerFee] = useState<string>("");
  const [buyerFee, setBuyerFee] = useState<string>("");
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState("30D");
  const [selectedBankDetailId, setSelectedBankDetailId] = useState("");
  const [buyerOrSellerFee, setBuyerOrSellerFee] = useState("Seller Fee");
  const [defaultCountry, setDefaultCountry] = useState({
    name: "",
    id: "",
  });
  const [subTypeLoading, setSubTypeLoading] = useState(false);
  const [bankInfosLoading, setBankInfosLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [buyersLoading, setBuyersLoading] = useState(false);

  const [bankInfos, setBankInfos] = useState<BankAccountsDefaultTypes>();
  const [suppliers, setSuppliers] = useState<string[]>([]);
  const [paymentTerms, setPaymentTerms] = useState<PaymentTermsType[]>([]);
  const [isSellerOnFinmid, setIsSellerOnFinmid] = useState(false);
  const [costsLoading, setCostsLoading] = useState(false);
  const [subTypes, setSubTypes] = useState<{ name: string; id: string }[]>([]);
  const [subTempTypes, setSubTempTypes] = useState<any[]>([]);
  const [addedProduces, setAddedProduces] = useState<any[]>([]);
  const [units, setUnits] = useState<{ name: string; id: string }[]>([]);
  const [addresses, setAddresses] = useState<{ name: string; id: string }[]>(
    []
  );
  const [addedAdditionalCosts, setAddedAdditionalCosts] = useState<
    AdditionalCostsType[]
  >([]);
  const [repaymentTerms, setRepaymentTerms] = useState<RepaymentTermsType>();
  const [selectedDocumentFile, setSelectedDocumentFile] = useState();
  const [isApplyFactoring, setIsApplyFactoring] = useState(false);
  const [isApplyPartial, setIsApplyPartial] = useState(false);
  const [documents, setDocuments] = useState<DocumentsType[]>([]);
  const [additionalCosts, setAdditionalCosts] = useState<any[]>([]);
  const [paymentTermsLoading, setPaymentTermsLoading] = useState(false);

  const [notification, setNotification] = useState({
    buyer: {
      email: true,
      notification: true,
    },
    seller: {
      email: true,
      notification: true,
    },
  });

  const [isExpandedSidebar, setIsExpandedSidebar] = useState(false);

  const aiData = useAppSelector((state) => state.invoice.aiInvoiceData);

  const watchApplyInvoiceFactoring = watch("applyInvoiceFactoring");
  const watchIsBuyerChecked = watch("isBuyerChecked");
  const isCreateInvoice = watch("isCreateInvoice");
  const watchDeliveryDate = watch("orderDate");
  const watchReceiveDate = watch("receiveDate");
  const watchBuyer = watch("buyerCompany");
  const watchSupplier = watch("supplier");
  const watchConsignmentNumber = watch("consignmentNumber");
  const watchReferenceNumber = watch("referenceNumber");
  const watchSupplierReference = watch("supplierReference");
  const watchLocation = watch("location");
  const watchCurrency = watch("creditLimitCurrency");
  const watchDiscountType = watch("rebateDiscount");
  const watchDiscountTypeValue = watch("rebateDiscountAmount");
  const watchApplyRebateDiscount = watch("applyRebateDiscount");
  const watchCategory = watch("category");
  const watchInvoiceDate = watch("invoiceDate");
  const watchBuyerRef = watch("buyerReferenceNumber");
  const watchSellerRef = watch("sellerReferenceNumber");
  const watchFeePercentage = watch("feePercentage");
  // const watchPartialOption = watch("partialOption");
  const watchUpfrontPaymentAmount = watch("upfrontPaymentAmount");
  const watchFinalPaymentAmount = watch("finalPaymentAmount");

  const totalPaymentAmount =
    parseFloat(watchUpfrontPaymentAmount as any) +
    parseFloat(watchFinalPaymentAmount as any);

  const totalProducesValue = useMemo(() => {
    return (
      addedProduces?.length &&
      addedProduces
        .map((item) => item?.finalAmount)
        .reduce((acc, item) => acc + item)
    );
  }, [addedProduces?.length]);

  const totalAdditionalValue = useMemo(() => {
    return addedAdditionalCosts.reduce(
      (acc, item) =>
        acc +
        Number(item.amount) +
        (Number(item.vatRate) / 100) * Number(item.amount),
      0
    );
  }, [addedAdditionalCosts?.length]);

  const totalEstimatedValue = useMemo(() => {
    return totalProducesValue + totalAdditionalValue;
  }, [totalProducesValue, totalAdditionalValue]);

  const rebateValue: number = useMemo(() => {
    return watchDiscountType === "Percentage"
      ? (totalEstimatedValue * parseFloat(watchDiscountTypeValue as any)) / 100
      : parseFloat(watchDiscountTypeValue as any);
  }, [watchDiscountType, totalEstimatedValue, watchDiscountTypeValue]);

  const grandTotal: number = useMemo(() => {
    return rebateValue
      ? totalEstimatedValue - rebateValue
      : totalEstimatedValue;
  }, [rebateValue, totalEstimatedValue]);

  const isCreateDisabled =
    addedProduces?.length &&
    totalProducesValue + totalAdditionalValue > finmidLimit;

  const currencies: Currency[] = useAppSelector(
    (state) => state.auth.currencies
  );
  const countries: Country[] = useAppSelector((state) => state.auth.countries);

  const watchSellerCompany = watch("sellerCompany");

  const isFeePercentageEnough = useCallback(() => {
    const paymentTermFee = paymentTerms.find(
      (item) => item.paymentTerm === selectedPaymentTerm
    );
    if (buyerOrSellerFee === "Seller Fee") {
      if (companySellerFeesData.length) {
        const specPaymentTerm = companySellerFeesData.find(
          (item) => item.finmidFeesType === selectedPaymentTerm
        );

        if (specPaymentTerm?.seller_fee) {
          if (
            (specPaymentTerm as any)?.seller_fee >
            parseFloat(watchFeePercentage || "")
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          if (
            (paymentTermFee as any)?.fee > parseFloat(watchFeePercentage || "")
          ) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        if (
          (paymentTermFee as any)?.fee > parseFloat(watchFeePercentage || "")
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      if (companyBuyerFeesData.length) {
        const specPaymentTerm = companyBuyerFeesData.find(
          (item) => item.finmidFeesType === selectedPaymentTerm
        );

        if (specPaymentTerm?.buyer_fee) {
          if (
            (specPaymentTerm as any)?.buyer_fee >
            parseFloat(watchFeePercentage || "")
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          if (
            (paymentTermFee as any)?.fee > parseFloat(watchFeePercentage || "")
          ) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        if (
          (paymentTermFee as any)?.fee > parseFloat(watchFeePercentage || "")
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
  }, [
    buyerOrSellerFee,
    selectedPaymentTerm,
    paymentTerms.length,
    watchFeePercentage,
    companySellerFeesData.length,
    companyBuyerFeesData.length,
  ]);

  const onGoBack = () => {
    navigate("/surplus-allocation");
  };

  //bank account infos

  useEffect(() => {
    if (
      (watchSellerCompany as any)?.id &&
      (watchBuyer as any)?.id &&
      (watchCurrency as any)?.id &&
      !bankInfos?.companyBankDetails
    ) {
      const getBankAccountInfos = async () => {
        setBankInfosLoading(true);
        const infos = await getBankDefaultInfos();

        if (infos?.status === 200) {
          setBankInfos(infos.data);
        }
        setBankInfosLoading(false);
      };
      getBankAccountInfos();
    }
  }, [
    watchBuyer,
    watchSellerCompany,
    watchCurrency,
    bankInfos?.companyBankDetails,
  ]);

  //seller fee and buyer fee
  useEffect(() => {
    const selectedTermPercentage: any = paymentTerms.find(
      (term) => term.paymentTerm === selectedPaymentTerm
    );
    if (parseFloat(watchFeePercentage as any) >= 100) {
      setValue("feePercentage", selectedTermPercentage?.fee);
    }
  }, [watchFeePercentage]);

  //get suppliers

  useEffect(() => {
    const getSuppliers = async () => {
      const resSuppliers = await getSupplierInventories();

      if (resSuppliers?.suppliers) {
        setSuppliers(resSuppliers?.suppliers);
      }
    };
    getSuppliers();
  }, []);

  useEffect(() => {
    if (watchApplyInvoiceFactoring && buyerOrSellerFee && paymentTerms.length) {
      const selectedTermPercentage = paymentTerms.find(
        (term) => term.paymentTerm === selectedPaymentTerm
      );
      if (buyerOrSellerFee === "Buyer Fee") {
        if (companyBuyerFeesData.length) {
          const companySpecFee = companyBuyerFeesData.find(
            (item) => item.finmidFeesType === selectedPaymentTerm
          );

          setValue(
            "feePercentage",
            companySpecFee?.buyer_fee
              ? companySpecFee?.buyer_fee.toString()
              : selectedTermPercentage?.fee.toString()
          );
        } else {
          setValue("feePercentage", selectedTermPercentage?.fee.toString());
        }
      } else {
        if (companySellerFeesData.length) {
          const companySpecFee = companySellerFeesData.find(
            (item) => item.finmidFeesType === selectedPaymentTerm
          );

          setValue(
            "feePercentage",
            // "2.5"
            companySpecFee?.seller_fee
              ? companySpecFee?.seller_fee.toString()
              : selectedTermPercentage?.fee.toString()
          );
        } else {
          setValue("feePercentage", selectedTermPercentage?.fee.toString());
          // setValue("feePercentage", "2.5");
        }
      }
    }
  }, [
    companyBuyerFeesData.length,
    companySellerFeesData.length,
    watchApplyInvoiceFactoring,
    buyerOrSellerFee,
    paymentTerms.length,
    selectedPaymentTerm,
  ]);

  useEffect(() => {
    if ((watchSellerCompany as any)?.id) {
      const getSellerFee = async () => {
        const sellerFeeRes = await getSellerFeesWithoutPagination(
          (watchSellerCompany as any)?.id
        );

        if (sellerFeeRes?.allFees) {
          setCompanySellerFeesData(sellerFeeRes?.allFees);
        } else {
          setCompanySellerFeesData([]);
        }
      };
      getSellerFee();
    }
  }, [(watchSellerCompany as any)?.id]);

  useEffect(() => {
    if ((watchBuyer as any)?.id) {
      const getBuyerFee = async () => {
        const buyerFeeRes = await getSellerFeesWithoutPagination(
          (watchBuyer as any)?.id
        );

        if (buyerFeeRes?.allFees) {
          setCompanyBuyerFeesData(buyerFeeRes?.allFees);
        } else {
          setCompanyBuyerFeesData([]);
        }
      };
      getBuyerFee();
    }
  }, [(watchBuyer as any)?.id]);

  //ends here seller buyer fee

  useEffect(() => {
    if ((watchSellerCompany as any)?.id) {
      const defaultCoOfOrigin = tempSellers.find(
        (item: any) => item?.id === (watchSellerCompany as any)?.id
      );

      const defaultCountryCodeName = countries.find((item) =>
        defaultCoOfOrigin?.principalPlaceCountry?.code
          ? item?.geonameId ===
            parseFloat(defaultCoOfOrigin?.principalPlaceCountry?.code)
          : item?.countryName === defaultCoOfOrigin?.principalPlaceCountry?.name
      );

      setDefaultCountry({
        name: defaultCountryCodeName?.countryName || "",
        id: defaultCountryCodeName?.geonameId.toString() || "",
      });
    }
  }, [(watchSellerCompany as any)?.id]);

  useEffect(() => {
    if (watchCurrency) {
      const symbol = currencies.find(
        (item) => item?.code === (watchCurrency as any)?.name
      );

      setCurrencySymbol(symbol?.symbol ? symbol?.symbol : "");
    }
  }, [watchCurrency]);

  useEffect(() => {
    const getTerms = async () => {
      setPaymentTermsLoading(true);
      const response = await getPaymentTerms();
      setPaymentTermsLoading(false);

      if (response?.data) {
        setPaymentTerms(response?.data);
      }
    };
    getTerms();
  }, []);

  //get sub produce types

  useEffect(() => {
    const getSubProductTypes = async () => {
      setSubTypeLoading(true);
      const subTypesRes = await getSubTypesWithProductTypeId();
      setSubTypeLoading(false);

      if (subTypesRes?.data) {
        setSubTempTypes(subTypesRes?.data);
        setSubTypes(
          subTypesRes?.data?.map((item: any) => ({
            id: item?._id,
            name: item?.name,
          }))
        );
      }
    };
    getSubProductTypes();
  }, []);

  //get units

  useEffect(() => {
    const getAllUnits = async () => {
      const unitsRes = await getUnits();

      if (units) {
        setUnits(
          unitsRes?.map((item: UnitsType) => ({
            name: item?.name,
            id: item?.id,
          }))
        );
      }
    };
    getAllUnits();
  }, []);

  //get cost types

  useEffect(() => {
    const getTitles = async () => {
      setCostsLoading(true);
      const response = await getCostTypes();
      setCostsLoading(false);
      if (response?.length) {
        setAdditionalCosts(response);
      }
    };
    getTitles();
  }, []);

  useEffect(() => {
    const getSellers = async () => {
      setBuyersLoading(true);

      const response = await getBuyerSellerWithoutCanAllocates();

      setBuyersLoading(false);

      if (response) {
        setTempSellers(response?.items);
        setTempBuyers(response.items);
        setAllocationBuyers(
          response?.items?.map((item: any) => ({
            name: item?.companyName,
            id: item?.id,
          }))
        );
        setAllocationSellers(
          response?.items
            ?.filter((i: any) => i?.canAllocateSurplus)
            ?.map((item: any) => ({
              name: item?.companyName,
              id: item?.id,
            }))
        );
      }
    };
    getSellers();
  }, []);

  useEffect(() => {
    if ((watchSellerCompany as any)?.id) {
      const getAddresses = async () => {
        const response = await getSellerAddresses(
          (watchSellerCompany as any)?.id
        );

        if (response) {
          setAddresses(
            response?.map((item: any) => ({
              name: item?.title,
              id: item?.id || item?._id,
            }))
          );
        }
      };
      getAddresses();
    }
  }, [(watchSellerCompany as any)?.id]);

  //this effect for if user comes from ai invoice page fill inputs with data

  useEffect(() => {
    if (
      aiData?.companyNameSeller &&
      subTempTypes?.length &&
      tempSellers?.length &&
      countries?.length &&
      currencies.length
    ) {
      const defaultCoOfOrigin = tempSellers.find(
        (item: any) => item?.id === aiData?.companyNameSeller?.id
      );

      const defaultCountryCodeName = countries.find((item) =>
        defaultCoOfOrigin?.principalPlaceCountry?.code
          ? item?.geonameId ===
            parseFloat(defaultCoOfOrigin?.principalPlaceCountry?.code)
          : item?.countryName === defaultCoOfOrigin?.principalPlaceCountry?.name
      );
      const defaultCurrency = currencies.find(
        (item) => item?.id === aiData?.currency?.id
      );

      const defaultProduces = aiData.produces.map((item) => {
        const defaultProduceType = subTempTypes.find(
          (type) => type?._id === item.subProductType.id
        );

        return {
          supplier: item?.supplier,
          currency: aiData.currency,
          countryOfOrigin: {
            name: defaultCountryCodeName?.countryName,
          },
          finalAmount:
            parseFloat(item.quantity.toString().replace(/,/g, "")) *
            parseFloat(item.price.toString().replace(/,/g, "")),
          produceType: defaultProduceType?.productType?._id,
          produceName: item.name,
          produceSubType: {
            name: item?.subProductType?.name,
            id: item?.subProductType?.id,
          },
          advisoryPrice: item.price,
          returnPrice: item.price,
          quantity: item.quantity.replace(/,/g, ""),
          unit: item.unit,
        };
      });
      const defaultCosts = aiData.costs.map((item) => {
        const defaultCostType = additionalCosts.find(
          (cost) => cost?.costName === item?.costName
        );

        return {
          costId: defaultCostType,
          amount: item?.amount,
          description: "",
          vatRate: 0,
        };
      });
      setValue("creditLimitCurrency", {
        name: defaultCurrency?.code,
        id: defaultCurrency?.id,
      });
      setValue("sellerCompany", aiData.companyNameSeller);
      setValue("buyerCompany", aiData.companyNameBuyer);
      setValue("buyerReferenceNumber", aiData.refBuyer);
      setValue("sellerReferenceNumber", aiData.refSeller);
      setValue("buyerCompany", aiData.companyNameBuyer);
      setValue("invoiceDate", dayjs(aiData.invoiceDate) as any);
      setValue("orderDate", dayjs(aiData.deliveryDate) as any);
      setAddedProduces(defaultProduces);
      setAddedAdditionalCosts(defaultCosts);
    }
  }, [
    currencies.length,
    aiData?.companyNameSeller,
    subTempTypes?.length,
    tempSellers?.length,
    countries?.length,
  ]);

  //set selected seller notification setting as in company object

  useEffect(() => {
    if ((watchSellerCompany as any)?.id) {
      const selectedSeller = tempSellers.find(
        (s) => s.id === (watchSellerCompany as any)?.id
      );
      if (selectedSeller?.id) {
        setNotification((prev) => ({
          ...prev,
          seller: {
            email: selectedSeller.notification.emailNotification,
            notification: selectedSeller.notification.appNotification,
          },
        }));
      }
    }
  }, [watchSellerCompany, tempSellers]);

  //set selected buyer notification setting as in company object

  useEffect(() => {
    if ((watchBuyer as any)?.id) {
      const selectedBuyer = tempBuyers.find(
        (s) => s.id === (watchBuyer as any)?.id
      );
      if (selectedBuyer?.id) {
        setNotification((prev) => ({
          ...prev,
          buyer: {
            email: selectedBuyer.notification.emailNotification,
            notification: selectedBuyer.notification.appNotification,
          },
        }));
      }
    }
  }, [watchBuyer, tempBuyers]);

  //reset invoiceFactoring if selected term 0




  useEffect(() => {
    if (selectedPaymentTerm === "0D") {
      setValue("applyInvoiceFactoring",false)
      setIsApplyFactoring(false)
    }
  }, [selectedPaymentTerm,setValue]);

  const onAddNewInventoryOffer = async () => {
    let newDeliveryDate = new Date(watchDeliveryDate.toString());
    let newInvoiceDate = new Date(watchInvoiceDate.toString());

    // newDeliveryDate.setDate(newDeliveryDate.getDate() + 1);

    const buyerUser = tempBuyers?.find(
      (item) => item?.id === (watchBuyer as any)?.id
    );

    const sellerUser = tempSellers?.find(
      (item) => item?.id === (watchSellerCompany as any)?.id
    );

    const finalData = {
      processedDocumentID: aiData?.processedDocumentId
        ? aiData.processedDocumentId
        : undefined,
      createInvoiceToo: isCreateInvoice,
      notification,
      override_amounts: watchApplyInvoiceFactoring
        ? {
            sellers_fee_override_percentage:
              buyerOrSellerFee === "Seller Fee"
                ? parseFloat(watchFeePercentage || "")
                : undefined,

            buyer_fee_override_percentage:
              buyerOrSellerFee === "Buyer Fee"
                ? parseFloat(watchFeePercentage || "")
                : undefined,
          }
        : undefined,
      sellerId: (watchSellerCompany as any)?.id,
      invoice: {
        company_bank_details_id: selectedBankDetailId,
        invoiceDate: moment(newInvoiceDate).toString(),
      },
      offer: {
        repayment_term: selectedPaymentTerm,
        partialPayment:
          isApplyPartial && parseFloat(watchUpfrontPaymentAmount as any)
            ? [
                {
                  amount: parseFloat(watchUpfrontPaymentAmount as any),
                },
                {
                  amount: parseFloat(watchFinalPaymentAmount as any),
                },
              ]
            : undefined,
        refs: {
          buyerReferenceNumber: watchBuyerRef,
          sellerReferenceNumber: watchSellerRef,
        },
        additionalCosts: addedAdditionalCosts?.length
          ? addedAdditionalCosts?.map((item) => ({
              description: item.description,
              amount: item?.amount,
              costId: item?.costId?.id,
              vatRate: item?.vatRate ? Number(item.vatRate) : 0,
            }))
          : undefined,
        produces: addedProduces.map((item) => ({
          category: watchCategory ? watchCategory : "Cat 1",
          devOrCol: "Delivered",
          name: item?.produceName,
          subProductTypeId: item?.produceSubType?.id,
          productTypeId: item?.produceType,
          receive_date: watchReceiveDate ? watchReceiveDate : undefined,
          quantity: item?.quantity,
          unitId: item?.unit?.id,
          currency: {
            symbol: currencySymbol,
            name: item?.currency?.name,
          },
          addressTitle: (watchLocation as any)?.name,
          countryOfOrigin: {
            name: item?.countryOfOrigin?.name || defaultCountry?.name,
          },
          packingSize: item?.packingSize,
          dealPriceIndication: item?.returnPrice ? item?.returnPrice : null,
          packetPerPallet: item?.boxesInPallet,
          priceGuide: item?.advisoryPrice,
          variety: item?.variety,
          brand: item?.brand,
          referenceNumber: watchReferenceNumber
            ? watchReferenceNumber
            : undefined,
          grower: item?.grower,
          haulier: item?.haulier,
          supplier_ref: watchSupplierReference,
          consignmentNumber: watchConsignmentNumber,
          supplier: item?.supplier,
          id: item?.id,
        })),
        deliveryDate: moment(newDeliveryDate).toString(),
        buyerId: (buyerUser as any)?.user,

        createdAt: new Date(),
        locationId: (watchLocation as any)?.id,
        currency: (watchCurrency as any)?.name,
        discount: {
          discountType:
            watchDiscountType === "Percentage" ? "PERCENTAGE" : "FIXED",
          discountTypeValue: watchApplyRebateDiscount
            ? parseFloat(watchDiscountTypeValue as any)
            : 0,
        },
        useRefactoring: watchApplyInvoiceFactoring,
      },
    };

    // if (!isFeePercentageEnough()) {
    //   toast.error(
    //     "Please check fee percentage,it cannot be less than default fee for customer."
    //   );
    // } else {
    if (addedProduces.filter((i) => !i?.supplier).length) {
      toast.error("Supplier is not added one of produces please check.");
    } else {
      if (
        watchApplyInvoiceFactoring &&
        buyerOrSellerFee == "Buyer Fee" &&
        grandTotal + parseFloat(buyerFee) > finmidLimit
      ) {
        toast.error(
          "Your buyer company limit is not enough with buyer fee amount."
        );
      } else if (!watchFeePercentage && watchApplyInvoiceFactoring) {
        toast.error("Fee Percentage field is missing please fill this field.");
      } else if (
        parseFloat(watchFeePercentage as any) <
          (buyerOrSellerFee === "Buyer Fee"
            ? parseFloat(buyerFee)
            : parseFloat(sellerFee)) &&
        watchApplyInvoiceFactoring
      ) {
        toast.error(
          "Fee percentage you entered can not be less than initial fee percentage. Please check the value and try again."
        );
      } else if (
        watchApplyRebateDiscount &&
        (!watchDiscountType || !watchDiscountTypeValue)
      ) {
        toast.error(
          "You selected apply discount option but didn't selected discount type and amount."
        );
      } else if (!watchUpfrontPaymentAmount) {
        toast.error("Please enter upfront payment amount.");
      } else if (
        parseFloat(totalPaymentAmount.toFixed(2)) !=
        parseFloat(grandTotal.toFixed(2))
      ) {
        toast.error(
          "Total amount of partial payments must match grand total of the order. Please update partial payment amounts and try to create again."
        );
      } else if (!selectedBankDetailId) {
        toast.error("Please select one bank account.");
      } else {
        setCreateLoading(true);
        const added = await createInventoryAndAllocation(finalData);
        setCreateLoading(false);

        if ((added?.order?.id || added?.order?._id) && documents?.length) {
          const requestDocuments = documents?.map(async (doc) => {
            const finalData = {
              offerId: added?.order?.id || added?.order?._id,
              visible: true,
              uploadedForUserId: sellerUser?.user,
              file: doc?.file,
              documentName: doc?.documentType,
              showMessage: false,
            };
            return await addOrderDocuments(finalData);
          });

          const successRes = await Promise.all(requestDocuments).then(
            (data) => {
              return data;
            }
          );
          const isRoute = successRes
            ?.map((item) => item.success)
            .every((item) => item === true);
          if (isRoute) {
            navigate(
              `/surplus-allocation/${added?.order?._id || added?.order?.id}`
            );
          }
        } else if (added?.order?._id || added?.order?.id) {
          navigate(
            `/surplus-allocation/${added?.order?._id || added?.order?.id}`
          );
        }
        // }
      }
    }
  };

  return (
    <Sidebar
      setIsExpandedSidebar={setIsExpandedSidebar}
      pageTitle="New Allocation"
    >
      <div className="container">
        {createLoading && <LoadingIndicator loading={createLoading} />}

        <div className="pb-3">
          <div className={`${classes.main} row`}>
            {buyersLoading ? (
              <Skeleton count={1} style={{ height: 200, borderRadius: 20 }} />
            ) : (
              <SellerCompany
                setIsSellerOnFinmid={setIsSellerOnFinmid}
                sellerCompany={watchSellerCompany as any}
                setValue={setValue}
                control={control}
                errors={errors}
                reset={reset}
                watch={watch}
                setAllocationSellers={setAllocationSellers}
                allocationSellers={allocationSellers}
              />
            )}
          </div>
          <div className={`${classes.main} row`}>
            {buyersLoading ? (
              <Skeleton count={1} style={{ height: 200, borderRadius: 20 }} />
            ) : (
              <AllocationCompany
                aiData={aiData}
                sellerCompany={watchSellerCompany}
                setIsApplyFactoring={setIsApplyFactoring}
                currencySymbol={currencySymbol}
                setValue={setValue}
                currencies={currencies}
                control={control}
                errors={errors}
                reset={reset}
                watch={watch}
                setAllocationBuyers={setAllocationBuyers}
                allocationBuyers={allocationBuyers}
                setFinmidLimit={setFinmidLimit}
                finmidLimit={finmidLimit}
                setBuyersLoading={setBuyersLoading}
              />
            )}
          </div>

          <div className="row">
            <div className={`${classes.main} mt-4`}>
              {buyersLoading ? (
                <Skeleton count={1} style={{ height: 200, borderRadius: 20 }} />
              ) : (
                <InventoryInfo
                  setValue={setValue}
                  sellerCompany={watchSellerCompany as any}
                  addresses={addresses}
                  setAddresses={setAddresses}
                  control={control}
                  errors={errors}
                  reset={reset}
                  watch={watch}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className={`${classes.main} mt-2`}>
              {buyersLoading ? (
                <Skeleton count={1} style={{ height: 200, borderRadius: 20 }} />
              ) : (
                <Produces
                  suppliers={suppliers}
                  growers={tempSellers
                    .filter((comp) =>
                      comp.types.map((type) => type?.name).includes("Grower")
                    )
                    .map((item) => item.companyName)}
                  setSubTempTypes={setSubTempTypes}
                  subTempTypes={subTempTypes}
                  isExpandedSidebar={isExpandedSidebar}
                  defaultCountry={defaultCountry}
                  currencySymbol={currencySymbol}
                  addedProduces={addedProduces}
                  setAddedProduces={setAddedProduces}
                  countries={countries}
                  setSubTypeLoading={setSubTypeLoading}
                  setSubTypes={setSubTypes}
                  subTypes={subTypes}
                  sellerCompany={watchSellerCompany as any}
                  currencies={currencies}
                  control={control}
                  errors={errors}
                  reset={reset}
                  watch={watch}
                  setValue={setValue}
                  units={units}
                  setUnits={setUnits}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className={`col-6`}>
              <div className={classes.main}>
                {buyersLoading ? (
                  <Skeleton
                    count={1}
                    style={{ height: 200, borderRadius: 20 }}
                  />
                ) : (
                  <AdditionalCosts
                    setCostsLoading={setCostsLoading}
                    additionalCosts={additionalCosts}
                    setAdditionalCosts={setAdditionalCosts}
                    currencySymbol={currencySymbol}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    reset={reset}
                    watch={watch}
                    addedAdditionalCosts={addedAdditionalCosts}
                    setAddedAdditionalCosts={setAddedAdditionalCosts}
                  />
                )}
              </div>
              <div className="mt-3">
                <div className={classes.main}>
                  {buyersLoading ? (
                    <Skeleton
                      count={1}
                      style={{ height: 200, borderRadius: 20 }}
                    />
                  ) : (
                    <OrderDocuments
                      documents={documents}
                      setDocuments={setDocuments}
                      selectedDocumentFile={selectedDocumentFile}
                      setSelectedDocumentFile={setSelectedDocumentFile}
                      setValue={setValue}
                      control={control}
                      errors={errors}
                      reset={reset}
                      watch={watch}
                    />
                  )}
                </div>
              </div>
              <div className="mt-3">
                <div className={classes.main}>
                  {buyersLoading ? (
                    <Skeleton
                      count={1}
                      style={{ height: 200, borderRadius: 20 }}
                    />
                  ) : (
                    <Notifications
                      notification={notification}
                      setNotification={setNotification}
                    />
                  )}
                </div>
              </div>
              <div className="mt-3">
                <div className={classes.main}>
                  {buyersLoading ? (
                    <Skeleton
                      count={1}
                      style={{ height: 200, borderRadius: 20 }}
                    />
                  ) : (
                    <BankInfo
                      selectedBankDetailId={selectedBankDetailId}
                      setSelectedBankDetailId={setSelectedBankDetailId}
                      setValue={setValue}
                      setBankInfos={setBankInfos}
                      bankInfos={bankInfos}
                      loading={bankInfosLoading}
                      control={control}
                      watch={watch}
                    />
                  )}
                </div>
              </div>

              <hr
                style={{
                  border: "1px solid #ddd",
                  height: "auto",
                  width: "1px",
                  marginLeft: 25,
                  opacity: 0.05,
                }}
              />
            </div>

            <div className="col-6">
              <div className={classes.main}>
                {buyersLoading ? (
                  <Skeleton
                    count={1}
                    style={{ height: 200, borderRadius: 20 }}
                  />
                ) : (
                  <RebateDiscount
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    reset={reset}
                    watch={watch}
                    totalEstimatedValue={
                      totalAdditionalValue + totalProducesValue
                    }
                  />
                )}
              </div>
              <div className={classes.main}>
                {paymentTermsLoading ? (
                  <Skeleton
                    count={1}
                    style={{ height: 200, borderRadius: 20 }}
                  />
                ) : (
                  <PaymentTerms
                    selectedPaymentTerm={selectedPaymentTerm}
                    setSelectedPaymentTerm={setSelectedPaymentTerm}
                    setPaymentTerms={setPaymentTerms}
                    paymentTerms={paymentTerms}
                    paymentTermsLoading={paymentTermsLoading}
                  />
                )}
              </div>
              <div className={classes.main}>
                {buyersLoading ? (
                  <Skeleton
                    count={1}
                    style={{ height: 200, borderRadius: 20 }}
                  />
                ) : (
                  <InvoiceFactoring
                    selectedPaymentTerm={selectedPaymentTerm}
                    setSelectedPaymentTerm={setSelectedPaymentTerm}
                    setPaymentTerms={setPaymentTerms}
                    paymentTerms={paymentTerms}
                    buyerOrSellerFee={buyerOrSellerFee}
                    setBuyerOrSellerFee={setBuyerOrSellerFee}
                    isSellerOnFinmid={isSellerOnFinmid}
                    feeOption={feeOption}
                    setFeeOption={setFeeOption}
                    isApplyPartial={isApplyPartial}
                    setIsApplyPartial={setIsApplyPartial}
                    currencySymbol={currencySymbol}
                    grandTotal={grandTotal}
                    isApplyFactoring={isApplyFactoring}
                    setIsApplyFactoring={setIsApplyFactoring}
                    finmidLimit={finmidLimit}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    reset={reset}
                    watch={watch}
                    sellerCompany={watchSellerCompany as any}
                    addedProduces={addedProduces}
                    totalEstimatedValue={
                      totalAdditionalValue + totalProducesValue
                    }
                    setRepaymentTerms={setRepaymentTerms}
                    repaymentTerms={repaymentTerms}
                    paymentTermsLoading={paymentTermsLoading}
                  />
                )}
              </div>
              <div className={classes.main}>
                {buyersLoading ? (
                  <Skeleton
                    count={1}
                    style={{ height: 200, borderRadius: 20 }}
                  />
                ) : (
                  <AllocationSummary
                    buyerOrSellerFee={buyerOrSellerFee}
                    feeOption={feeOption}
                    currencySymbol={currencySymbol}
                    rebateValue={rebateValue}
                    grandTotal={grandTotal}
                    sellerCompany={watchSellerCompany as any}
                    setValue={setValue}
                    addedProduces={addedProduces}
                    totalEstimatedValue={
                      totalAdditionalValue + totalProducesValue
                    }
                    control={control}
                    errors={errors}
                    reset={reset}
                    watch={watch}
                    repaymentTerms={repaymentTerms}
                    setRepaymentTerms={setRepaymentTerms}
                  />
                )}
              </div>
            </div>
          </div>
          <hr className={classes.hr} />
          <div className=" d-flex align-items-center justify-content-center gap-3">
            <button onClick={onGoBack} className={classes.cancel}>
              Cancel
            </button>
            <button
              disabled={
                // !watchIsBuyerChecked ||
                !watchSellerCompany ||
                !addedProduces?.length ||
                (watchApplyInvoiceFactoring && isCreateDisabled) ||
                (watchApplyInvoiceFactoring &&
                  totalProducesValue + totalAdditionalValue > finmidLimit)
              }
              onClick={handleSubmit(onAddNewInventoryOffer)}
              className={classes.create}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default CreateNewSurplusContainer;
