import { useEffect, useState } from "react";
import {
  getNotificationItemWithId,
  getNotifications,
} from "../services/notification";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { NotificationDetailTypes } from "../pages/PushNotifications/NotificationDetail";
import usePermissions from "./usePermissions";

export interface OrdersNotificationType {
  orders: Order[];
}

export interface Order {
  id: string;
  idType: string;
  offerODRCode: string;
  paymentCode: string;
}

export interface NotificationListTypes {
  _id: string;
  content: string;
  deliveryDate: string;
  data: OrdersNotificationType;
  title: string;
  message: string;
  messageType: string;
  status: string;
  createdAt: string;
  results: ResultsNotificationsType;
  users: User[];
  push_notification_id: string;
}

export interface ResultsNotificationsType {
  successCount: number;
  failureCount: number;
}

export interface User {
  _id: string;
  email: string;
  fullName: string;
  companyName: string;
  userId: string;
}

const notificationsSchema = yup.object().shape({
  keyword: yup.string(),
  message: yup.string(),
  createDateFrom: yup.string(),
  createDateTo: yup.string(),
  deliveryDateFrom: yup.string(),
  deliveryDateTo: yup.string(),
  sortType: yup.object(),
  sortName: yup.object(),
});

export const useGetNotificationList = () => {
  const { control, watch, reset, handleSubmit, setValue } = useForm({
    resolver: yupResolver(notificationsSchema),
    defaultValues: {
      keyword: "",
      message: "",
      createDateFrom: "",
      createDateTo: "",
      deliveryDateFrom: "",
      deliveryDateTo: "",
      sortName: { name: "CreateDate", id: "createdAt" },
      sortType: { name: "Descending", id: "desc" },
    },
  });


  const {hasPermission} =usePermissions()
  const [notifications, setNotifications] = useState<NotificationListTypes[]>(
    []
  );
  const [isClearFilterKey, setIsClearFilterKey] = useState<number>(0);
  const [deletedFilterKey, setDeletedFilterKey] = useState<string>("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [filterData, setFilterData] = useState({});
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  const watchSortName = watch("sortName");
  const watchSortType = watch("sortType");
  useEffect(() => {
    setValue(`${deletedFilterKey}` as any, undefined);
  }, [deletedFilterKey, isClearFilterKey]);

  useEffect(() => {
  if(!hasPermission("PUSH_NOTIFICATIONS"))return 
  const getNotificationsData = async () => {
    setFetchLoading(true);
    const response = await getNotifications({
      filters: {
        keyword: (filterData as any)?.keyword
          ? (filterData as any)?.keyword
          : undefined,
        message: (filterData as any)?.message
          ? (filterData as any)?.message
          : undefined,
        createdAt:
          (filterData as any)?.createDateFrom ||
          (filterData as any)?.createDateTo
            ? {
                from: (filterData as any)?.createDateFrom
                  ? (filterData as any)?.createDateFrom
                  : undefined,
                to: (filterData as any)?.createDateTo
                  ? (filterData as any)?.createDateTo
                  : undefined,
              }
            : undefined,
        deliveryDate:
          (filterData as any)?.deliveryDateFrom ||
          (filterData as any)?.deliveryDateTo
            ? {
                from: (filterData as any)?.deliveryDateFrom
                  ? (filterData as any)?.deliveryDateFrom
                  : undefined,
                to: (filterData as any)?.deliveryDateTo
                  ? (filterData as any)?.deliveryDateTo
                  : undefined,
              }
            : undefined,
      },
      page: queryParams.page,
      limit: 25,
      sort:
        (watchSortName as any)?.name && (watchSortType as any)?.name
          ? {
              field:
                (watchSortName as any)?.name === "CreateDate"
                  ? "createdAt"
                  : (watchSortName as any)?.name === "DeliveryDate"
                  ? "deliveryDate"
                  : "",
              sortType:
                (watchSortType as any)?.name === "Ascending"
                  ? "asc"
                  : (watchSortType as any)?.name === "Descending"
                  ? "desc"
                  : "",
            }
          : undefined,
    });
    setFetchLoading(false);

    if (response?.success) {
      setNotifications(response?.data);
      setQueryParams({
        page: response?.pagination?.page,
        total: response?.pagination?.total,
        limit: response?.pagination?.limit,
        totalPage: response?.pagination?.totalPage,
      });
    }
  };
  getNotificationsData();
  }, [watchSortName, watchSortType, queryParams.page, filterData,hasPermission("PUSH_NOTIFICATIONS")]);

  return {
    deletedFilterKey,
    setDeletedFilterKey,
    handleSubmit,
    isClearFilterKey,
    setIsClearFilterKey,
    control,
    showFilterModal,
    setShowFilterModal,
    watchSortName,
    watchSortType,
    notifications,
    fetchLoading,
    setFetchLoading,
    queryParams,
    setQueryParams,
    setNotifications,
    filterData,
    setFilterData,
  };
};

export const useGetNotificationItem = (id: string) => {
  const { hasPermission } = usePermissions();
  const [notification, setNotification] = useState<NotificationDetailTypes>();
  const [detailLoading, setDetailLoading] = useState(false);
  const [isFetch, setIsFetch] = useState(0);

  useEffect(() => {
    if (id && hasPermission("PUSH_NOTIFICATIONS")) {
      const getNotificationDetailsItem = async () => {
        setDetailLoading(true);
        const response = await getNotificationItemWithId(id);
        setDetailLoading(false);

        if (response?.success) {
          setNotification(response?.data);
        }
      };
      getNotificationDetailsItem();
    }
  }, [id, isFetch,hasPermission("PUSH_NOTIFICATIONS")]);

  return { notification, setNotification, detailLoading, setIsFetch };
};
