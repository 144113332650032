import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import authSlice from "./slices/authSlice";
import invoiceSlice from "./slices/invoiceSlice";
import sellerFeeSlice from "./slices/sellerFeeSlice";
import dashboardSlice from "./slices/dashboardSlice";
import { thunk } from "redux-thunk";
import wsDataSlice from "./slices/wsDataSlice";
import localForage from "localforage";

localForage.config({
  name: "FruProDB",
});

const sellerFeePersistConfig = {
  key: "sellerFee",
  storage,
};

const dashboardPersistConfig = {
  key: "dashboard",
  storage,
};

const authPersistConfig = {
  key: "auth",
  storage,
};

const wsDataPersistConfig = {
  key: "wsData",
  storage: localForage, //no localStorage, yes localForage
};

const persistedSellerFeeReducer = persistReducer(
  sellerFeePersistConfig,
  sellerFeeSlice
);

const persistedDashboardReducer = persistReducer(
  dashboardPersistConfig,
  dashboardSlice
);

const persistedWsDataReducer = persistReducer(wsDataPersistConfig, wsDataSlice);

const persistedAuthReducer = persistReducer(authPersistConfig, authSlice);

const rootReducer = combineReducers({
  auth: persistedAuthReducer,
  invoice: invoiceSlice,
  sellerFee: persistedSellerFeeReducer,
  dashboard: persistedDashboardReducer,
  wsData: persistedWsDataReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
