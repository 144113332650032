import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getInvoices = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      "/premade-invoices/admin/get-all-premade-invoices",
      {
        ...data,
      }
    );
    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};


export const updateInvoice = async (data: any) => {
 try {
   const response = await axiosApiInstance.post(
     "/premade-invoices/admin/update-premade-invoice",
     {
       ...data,
     }
   );
   if (response){
    toast.success("Invoice status is updated successfully.")
    return response.data
   };
 } catch (error) {
   if (axios.isAxiosError(error)) {
     toast.error(error.response?.data.message);
   }
 }
};
