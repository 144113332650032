import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import {
  exportTradabaseOrders,
  getTradabaseOrders,
  getTradabaseOrdersFilters,
} from "../../services/tradabase-orders";
import usePermissions from "../../hooks/usePermissions";
import PermissionWrapper from "../../components/PermissionWrapper";
import { TradabaseData } from "./interface";
import { makeStyles } from "@mui/styles";
import TradabaseTableItem from "./TradabaseTableItem";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AdminModalComponent from "../../components/AdminModalComponent";
import ControlledComboBox from "../../components/ControlledComboBox";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import ControlledDateField from "../../components/ControlledDateField";
import ControlledTextField from "../../components/ControlledTextField";
import { downloadFile, getBuyerSeller } from "../../services/common";
import LoadingIndicator from "../../components/LoadingIndicator";
import FilterBadge from "../../components/FilterBadge";
import TradabaseOrderDetail from "./TradabaseOrderDetail";
import moment from "moment";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import { capturePartialPayment } from "../../services/surplus-allocation";
import { Helmet } from "react-helmet";

const schema = yup.object().shape({
  buyer: yup.string().nullable(),
  seller: yup.string().nullable(),
  sortType: yup.object().nullable(),
  tradabaseReference: yup.string().nullable(),
  tradeStatus: yup.string().nullable(),
  deliveryFrom: yup.string().nullable(),
  createFrom: yup.string().nullable(),
  createTo: yup.string().nullable(),
  deliveryTo: yup.string().nullable(),
  invoiceFrom: yup.string().nullable(),
  invoiceTo: yup.string().nullable(),
  sortName: yup.object().nullable(),
  useRefactoring: yup.string().nullable(),
  partialPayment: yup.string().nullable(),
  owner: yup.string().nullable(),
});

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  selectAllCancelButton: {
    background: "red!important",
  },
  selectAllCaptureButton: {
    minWidth: "180px!important",
    background: "#69cdae!important",
  },
});

const columns = [
  { name: "Tradabase", id: "tradabase" },
  { name: "FruPro", id: "frupro" },
  { name: "", id: "action" },
];
const TradabaseOrdersContainer = () => {
  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      buyer: "",
      owner: "",
      deliveryFrom: "",
      deliveryTo: "",
      createFrom: "",
      createTo: "",
      invoiceFrom: "",
      invoiceTo: "",
      seller: "",
      sortName: { name: "CreateDate", id: "offerCreatedAt" },
      sortType: { name: "Descending", id: "-" },
    },
  });

  const watchSortName = watch("sortName");
  const watchSortType = watch("sortType");

  const classes = useStyles();
  const { hasPermission } = usePermissions();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [sellerCompanies, setSellerCompanies] = useState<string[]>([]);
  const [buyerCompanies, setBuyerCompanies] = useState<string[]>([]);
  const [tradabaseReferences, setTradabaseReferences] = useState<string[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<TradabaseData[]>([]);
  const [ownerNames, setOwnerNames] = useState<string[]>([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [captureAvailableTrades, setCaptureAvailableTrades] = useState<
    TradabaseData[]
  >([]);
  const [captureAllLoading, setCaptureAllLoading] = useState(false);
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [isFetchCompanies, setIsFetchCompanies] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [isFetch, setIsFetch] = useState(0);
  const [filterData, setFilterData] = useState({});
  const [deletedFilterKey, setDeletedFilterKey] = useState("");
  const [isClearFilterKey, setIsClearFilterKey] = useState(0);
  const [fetchCompanyLoading, setFetchCompanyLoading] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [orders, setOrders] = useState<TradabaseData[]>([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  // const captureAvailableTrades = useMemo(() => {
  //   return orders
  //     .filter((i) =>
  //       i.offerPayment
  //         .map((p) => p.status)
  //         .every(
  //           (s) => s !== "CAPTURED" && s !== "BUYER_PAID" && s !== "SELLER_PAID"
  //         )
  //     )
  //     .filter((f) => f.offer[0]?.useRefactoring);
  // }, [orders.length,queryParams.page]);

  useEffect(() => {
    setValue(`${deletedFilterKey as any}` as any, undefined);
  }, [deletedFilterKey, isClearFilterKey]);

  useEffect(() => {
    if (openFilterModal) {
      setIsFetchCompanies(true);
    } else {
      setIsFetchCompanies(false);
    }
  }, [openFilterModal]);

  useEffect(() => {
    if (!hasPermission("TRADABASE_PROCESS")) return;

    const getData = async () => {
      setFetchLoading(true);
      const finalData = {
        page: queryParams.page,
        filters: {
          buyerCompanyName: (filterData as any)?.buyer
            ? (filterData as any)?.buyer
            : undefined,
          sellerCompanyName: (filterData as any)?.seller
            ? (filterData as any)?.seller
            : undefined,
          deliveryDate:
            (filterData as any)?.deliveryFrom || (filterData as any)?.deliveryTo
              ? {
                  from: (filterData as any)?.deliveryFrom,
                  to: (filterData as any)?.deliveryTo,
                }
              : undefined,
          createDate:
            (filterData as any)?.createFrom || (filterData as any)?.createTo
              ? {
                  from: (filterData as any)?.createFrom,
                  to: (filterData as any)?.createTo,
                }
              : undefined,
          invoiceDate:
            (filterData as any)?.invoiceFrom || (filterData as any)?.invoiceTo
              ? {
                  from: (filterData as any)?.invoiceFrom,
                  to: (filterData as any)?.invoiceTo,
                }
              : undefined,
          ownerName: (filterData as any)?.owner,
          tradabaseReference: (filterData as any)?.tradabaseReference,
          tradeStatus: (filterData as any)?.tradeStatus,
          useRefactoring: (filterData as any)?.useRefactoring
            ? (filterData as any)?.useRefactoring === "YES"
              ? true
              : false
            : undefined,
          partialPayment: (filterData as any)?.partialPayment
            ? (filterData as any)?.partialPayment === "YES"
              ? true
              : false
            : undefined,
        },
        sorting:
          watchSortName && watchSortType
            ? {
                sort:
                  (watchSortType as any)?.name === "Descending"
                    ? "desc"
                    : (watchSortType as any)?.name === "Ascending"
                    ? "asc"
                    : undefined,
                sortBy: (watchSortName as any)?.id,
              }
            : undefined,
      };
      const response = await getTradabaseOrders(finalData);
      setFetchLoading(false);

      if (response?.success) {
        setOrders(response?.data);
        setCaptureAvailableTrades(
          response?.data
            ?.filter((i: any) =>
              i.offerPayment
                .map((p: any) => p.status)
                .every(
                  (s: any) =>
                    s !== "CAPTURED" &&
                    s !== "BUYER_PAID" &&
                    s !== "SELLER_PAID"
                )
            )
            .filter((f: any) => f.offer[0]?.useRefactoring)
        );
        setQueryParams({
          page: response?.pagination?.page,
          total: response?.pagination?.total,
          totalPage: response?.pagination?.totalPage,
          limit: response?.pagination?.limit,
        });
      }
    };
    getData();
  }, [
    hasPermission("TRADABASE_PROCESS"),
    queryParams.page,
    watchSortName,
    isFetch,
    watchSortType,
    filterData,
  ]);

  useEffect(() => {
    if (isFetchCompanies) {
      const getCompanies = async () => {
        setFetchCompanyLoading(true);
        const response = await getTradabaseOrdersFilters();
        setFetchCompanyLoading(false);

        if (response?.tradabaseReference) {
          setTradabaseReferences(response?.tradabaseReference);
        }

        if (response?.sellerCompanyName) {
          setSellerCompanies(response?.sellerCompanyName);
        }
        if (response?.buyerCompanyName) {
          setBuyerCompanies(response?.buyerCompanyName);
        }
        if (response?.ownerName) {
          setOwnerNames(response?.ownerName);
        }
      };
      getCompanies();
    }
  }, [isFetchCompanies]);

  const onSelectAllOrders = useCallback(
    (e) => {
      if (!e) {
        setSelectedOrders([]);
      } else {
        setSelectedOrders(
          orders
            .filter((i) =>
              i.offerPayment
                .map((p) => p.status)
                .every(
                  (s) =>
                    s !== "CAPTURED" &&
                    s !== "BUYER_PAID" &&
                    s !== "SELLER_PAID"
                )
            )
            .filter((f) => f.offer[0]?.useRefactoring)
        );
      }
    },
    [orders.length]
  );

  const onCaptureAll = useCallback(async () => {
    setCaptureAllLoading(true);

    const allCaptureReqs = selectedOrders.map(
      async (o) => await capturePartialPayment(o.offerPayment[0]?._id)
    );

    await Promise.all(allCaptureReqs).then((res) => console.log(res));
    setCaptureAllLoading(false);

    setIsFetch((prev) => prev + 1);
    setSelectedOrders([]);
  }, [selectedOrders.length]);

  const onFilter = useCallback((data: any) => {
    setFilterData({
      tradabaseReference: data?.tradabaseReference
        ? data?.tradabaseReference
        : undefined,
      buyer: data?.buyer ? data?.buyer : undefined,
      seller: data?.seller ? data?.seller : undefined,
      deliveryFrom: data?.deliveryFrom
        ? moment(data?.deliveryFrom).format("DD/MM/YYYY")
        : undefined,
      tradeStatus: data?.tradeStatus ? data?.tradeStatus : undefined,
      deliveryTo: data?.deliveryTo
        ? moment(data?.deliveryTo).format("DD/MM/YYYY")
        : undefined,
      invoiceFrom: data?.invoiceFrom
        ? moment(data?.invoiceFrom).format("DD/MM/YYYY")
        : undefined,
      invoiceTo: data?.invoiceTo
        ? moment(data?.invoiceTo).format("DD/MM/YYYY")
        : undefined,
        createFrom: data?.createFrom
        ? moment(data?.createFrom).format("DD/MM/YYYY")
        : undefined,
      createTo: data?.createTo
        ? moment(data?.createTo).format("DD/MM/YYYY")
        : undefined,
      useRefactoring: data?.useRefactoring ? data?.useRefactoring : undefined,
      partialPayment: data?.partialPayment ? data?.partialPayment : undefined,
      owner: data?.owner ? data?.owner : undefined,
    });
    setQueryParams({
      ...queryParams,
      page: 1,
    });
    setOpenFilterModal(false);
  }, []);

  const onExportExcel = useCallback(async () => {
    setExportLoading(true);
    const finalData = {
      filters: {
        buyerCompanyName: (filterData as any)?.buyer
          ? (filterData as any)?.buyer
          : undefined,
        sellerCompanyName: (filterData as any)?.seller
          ? (filterData as any)?.seller
          : undefined,
        deliveryDate:
          (filterData as any)?.deliveryFrom || (filterData as any)?.deliveryTo
            ? {
                from: (filterData as any)?.deliveryFrom,
                to: (filterData as any)?.deliveryTo,
              }
            : undefined,
        invoiceDate:
          (filterData as any)?.invoiceFrom || (filterData as any)?.invoiceTo
            ? {
                from: (filterData as any)?.invoiceFrom,
                to: (filterData as any)?.invoiceTo,
              }
            : undefined,
        ownerName: (filterData as any)?.owner,
        useRefactoring: (filterData as any)?.useRefactoring
          ? (filterData as any)?.useRefactoring === "YES"
            ? true
            : false
          : undefined,
      },
      sorting:
        watchSortName || watchSortType
          ? {
              sort:
                (watchSortType as any)?.name === "Descending"
                  ? "desc"
                  : (watchSortType as any)?.name === "Ascending"
                  ? "asc"
                  : undefined,
              sortBy: (watchSortName as any)?.id,
            }
          : undefined,
    };
    const exported = await exportTradabaseOrders(finalData);

    setExportLoading(false);

    if (exported) {
      downloadFile(exported, "Tradabase_orders");
    }
  }, [filterData, watchSortName, watchSortType]);

  const renderTableBody = () => {
    return (
      <>
        {orders.map((order, index) => {
          return (
            <TradabaseTableItem
              selectedOrders={selectedOrders}
              setDetailLoading={setDetailLoading}
              isFetch={isFetch}
              setIsFetch={setIsFetch}
              setShowDetail={setShowOrderDetail}
              setOrders={setOrders}
              orders={orders}
              order={order}
              index={index}
            />
          );
        })}
      </>
    );
  };

  const renderFilterModalBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-6">
            <ControlledComboBox
              className={classes.comboBox}
              control={control}
              textLabel="Tradabase Reference"
              name="tradabaseReference"
              options={tradabaseReferences}
            />
          </div>

          <div className="col-6">
            <ControlledComboBox
              className={classes.comboBox}
              control={control}
              textLabel="Buyer"
              name="buyer"
              options={buyerCompanies}
            />
          </div>
        </div>
        <div className="mt-2 col-12">
          <ControlledComboBox
            className={classes.comboBox}
            control={control}
            textLabel="Seller"
            name="seller"
            options={sellerCompanies}
          />
        </div>

        <div className="row">
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Delivery Date From"
              control={control}
              name="deliveryFrom"
            />
          </div>
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Delivery Date To"
              control={control}
              name="deliveryTo"
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Invoice Date From"
              control={control}
              name="invoiceFrom"
            />
          </div>
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Invoice Date To"
              control={control}
              name="invoiceTo"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Create Date From"
              control={control}
              name="createFrom"
            />
          </div>
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Create Date To"
              control={control}
              name="createTo"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <ControlledComboBox
              className={classes.comboBox}
              control={control}
              textLabel="Partial Payment"
              name="partialPayment"
              options={["YES", "NO"]}
            />
          </div>
          <div className="col-6">
            <ControlledComboBox
              className={classes.comboBox}
              control={control}
              textLabel="FruPay"
              name="useRefactoring"
              options={["YES", "NO"]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <ControlledComboBox
              className={classes.comboBox}
              control={control}
              textLabel="Trade Status"
              name="tradeStatus"
              options={["CAPTURED", "UNCAPTURED"]}
            />
          </div>
          <div className="col-6">
            <ControlledComboBox
              options={ownerNames}
              className={classes.comboBox}
              control={control}
              textLabel="Owner"
              name="owner"
            />
          </div>
        </div>

        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setOpenFilterModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={handleSubmit(onFilter)}
            title="Submit"
          />
        </div>
      </>
    );
  };
  return (
    <Sidebar pageTitle="Tradabase Orders">
      {fetchCompanyLoading && (
        <LoadingIndicator loading={fetchCompanyLoading} />
      )}
       <Helmet>
          <title>Tradabase Orders | FruPro Admin Portal</title>
        </Helmet>
      <PermissionWrapper unauthorizedComponent permission="TRADABASE_PROCESS">
        {showOrderDetail ? (
          <TradabaseOrderDetail
            isFetch={isFetch}
            setIsFetch={setIsFetch}
            setShowDetail={setShowOrderDetail}
          />
        ) : (
          <TableComponent
            showRightHeaderItems={
              <>
                {selectedOrders.length ? (
                  <div className="mr-4 d-flex flex-row gap-2">
                    <AdminButtonComponent
                      disabled={captureAllLoading || !selectedOrders.length}
                      loading={captureAllLoading}
                      className={classes.selectAllCaptureButton}
                      onClick={onCaptureAll}
                      title={`Capture all(${selectedOrders.length})`}
                    />
                    <AdminButtonComponent
                      disabled={captureAllLoading}
                      className={classes.selectAllCancelButton}
                      onClick={() => setSelectedOrders([])}
                      title="Cancel"
                    />
                  </div>
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!captureAvailableTrades.length}
                        onClick={(e: any) =>
                          onSelectAllOrders(e.target.checked)
                        }
                        style={{ color: "#E77228" }}
                      />
                    }
                    label={`Select all to capture(${captureAvailableTrades.length})`}
                  />
                )}
                {selectedOrders.length ? null : (
                  <AdminButtonComponent
                    style={{ marginRight: 10, minWidth: 100 }}
                    loading={exportLoading}
                    disabled={exportLoading}
                    title="Export "
                    onClick={onExportExcel}
                  />
                )}
              </>
            }
            filterBadge={
              <FilterBadge
                sellers={sellerCompanies}
                buyers={buyerCompanies}
                setDeletedFilterKey={setDeletedFilterKey}
                isClearFilterKey={isClearFilterKey}
                setIsClearFilterKey={setIsClearFilterKey}
                filterData={filterData}
                setFilterData={setFilterData}
              />
            }
            onOpenModal={() => setOpenFilterModal(true)}
            control={control}
            isShowSortingNames
            isShowSortingTypes
            sortOptions={[
              { name: "DeliveryDate", id: "deliveryDate" },
              { name: "InvoiceDate", id: "invoiceDate" },
              { name: "CreateDate", id: "offerCreatedAt" },
            ]}
            showFilterIcon
            loading={fetchLoading}
            TableBodyComponent={renderTableBody}
            showRightButton={false}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            columns={columns}
          />
        )}
      </PermissionWrapper>

      <AdminModalComponent
        size="lg"
        children={renderFilterModalBody()}
        headerTitle="Filter Trades"
        openModal={openFilterModal}
        closeModal={() => setOpenFilterModal(false)}
      />
    </Sidebar>
  );
};

export default TradabaseOrdersContainer;
