import React, { useState } from "react";
import { CompanyDetailsStateType } from "./interfaces";
import { Divider, Grid, Tooltip } from "@mui/material";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { useForm } from "react-hook-form";
import ControlledTextField from "../../../components/ControlledTextField";
import { increaseBuyerLimit } from "../../../services/verification-requests";
import PermissionWrapper from "../../../components/PermissionWrapper";

const useStyles = makeStyles({
  boldText: {
    fontWeight: 600,
    fontSize: 18,
  },
  PendingText: {
    color: "#E0A600",
  },
  ApprovedText: {
    color: "#69CDAE",
  },

  RejectedText: {
    color: "#ED0000",
  },
  sellerBox: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 10,
    padding: 15,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.10)",
  },

  requestHeaderText: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    fontSize: 18,
    fontWeight: 600,
    color: "#E77228",
  },
  editLimitBox: {
    cursor: "pointer",
    borderRadius: "100%",
    width: "35px",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    alignItems: "center",
    background: "white",
  },
  Input: {
    background: "#F7F7F7",
    fontSize: 20,
    borderRadius: 10,
    width: 250,
    height: 25,
    padding: 15,
  },
});

const schema = yup.object().shape({
  creditLimit: yup.string(),
});

const CreditHistory = ({
  setRefreshRequest,
  refreshRequest,
  companyData,
}: {
  setRefreshRequest: React.Dispatch<React.SetStateAction<number>>;
  refreshRequest: number;
  companyData: CompanyDetailsStateType | undefined;
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);

  const creditLimit = companyData && companyData?.verifiedBuyer.creditLimit;
  const creditHistory =
    companyData && companyData?.verifiedBuyer.creditLimit.history;

  const reverseHistory = creditHistory && [...creditHistory].reverse();

  const {
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      creditLimit: creditLimit?.total.toString(),
    },
  });

  const watchLimit = watch("creditLimit");
  const onUpdateLimit = async () => {
    setLoading(true);
    const data = {
      buyerCompanyId: companyData?.id,
      reason: "",
      creditTotalLimit: watchLimit,
    };
    const response = await increaseBuyerLimit(data);
    setLoading(false);
    if (response) {
      setRefreshRequest(() => refreshRequest + 1);
    }
  };

  return (
    <div>
      {!creditHistory?.length ? (
        <>No history found</>
      ) : (
        <>
          {loading && <LoadingIndicator loading />}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.sellerBox}>
                <span className={classes.requestHeaderText}>
                  <span className="d-flex flex-row align-items-center gap-3">
                    Total Limit : £{" "}
                    {
                      <ControlledTextField
                        control={control}
                        textLabel=""
                        name="creditLimit"
                      />
                    }{" "}
                  </span>
                  <Tooltip title="Edit company credit limit">
                    <PermissionWrapper permission="COMPANY_UPDATE">
                      <span
                        onClick={onUpdateLimit}
                        className={classes.editLimitBox}
                      >
                        <EditIcon />
                      </span>
                    </PermissionWrapper>
                  </Tooltip>
                </span>
                <span className={classes.requestHeaderText}>
                  Remaining Limit :{" "}
                  {
                    <NumericFormat
                      fixedDecimalScale={true}
                      displayType="text"
                      decimalSeparator=","
                      decimalScale={2}
                      thousandSeparator="."
                      prefix="£"
                      value={creditLimit?.remaining}
                    />
                  }{" "}
                </span>
                <span className={classes.requestHeaderText}>
                  Spent Amount :{" "}
                  {
                    <NumericFormat
                      fixedDecimalScale={true}
                      displayType="text"
                      decimalSeparator=","
                      decimalScale={2}
                      thousandSeparator="."
                      prefix="£"
                      value={creditLimit?.spent}
                    />
                  }{" "}
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid
            marginLeft={2}
            marginTop={3}
            display="flex"
            flexDirection="row"
            gap="50px"
          >
            <Grid display="flex" flexDirection="column" gap="40px">
              <Grid display="flex" gap="135px" flexDirection="row">
                <span className={classes.boldText}>Date</span>
                <span className={classes.boldText}>Description</span>
              </Grid>
              {reverseHistory?.map((item) => {
                return (
                  <Grid
                    display="flex"
                    alignItems="center"
                    gap="50px"
                    flexDirection="row"
                  >
                    <Grid>
                      {moment(item?.dateChanged).format("DD/MM/YYYY")}
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid display="flex" flexDirection="column">
                      <span
                        className={
                          item.status === "PENDING"
                            ? classes.PendingText
                            : item.status === "APPROVED"
                            ? classes.ApprovedText
                            : item.status === "REJECTED"
                            ? classes.RejectedText
                            : ""
                        }
                      >
                        {item.status}
                      </span>
                      <span>{item.description}</span>
                      {item.purchaseTime ? (
                        <span>Purchase Time : {item.purchaseTime}</span>
                      ) : null}
                      {item.reason ? <span>Reason : {item.reason}</span> : null}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Grid display="flex" flexDirection="column" gap="20px"></Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default CreditHistory;
