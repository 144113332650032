import { axiosApiInstance } from "../services/axios-instance";
import { useEffect, useState } from "react";

export interface PaymentTermsType {
  _id: string;
  paymentTerm: string;
  fee: number;
  show: boolean;
}

export interface CompanyFeesType {
  _id: string;
  buyer_fee: number;
  companyId: string;
  seller_fee: number;
  finmidFeesType: string;
}

export const usePaymentTerms = (
  feePercentage: string,
  setValue: any,
  sellerCompany: string
) => {
  const [sellerFeesLoading, setSellerFeesLoading] = useState(false);
  const [paymentTermsLoading, setPaymentTermsLoading] = useState(false);
  const [openCaptureWaitingModal, setOpenCaptureWaitingModal] = useState(false);

  const [paymentTerms, setPaymentTerms] = useState<PaymentTermsType[]>([]);
  const [companySellerFeesData, setCompanySellerFeesData] = useState<
    CompanyFeesType[]
  >([]);
  const [selectedTerm, setSelectedTerm] = useState("30D");

  //get payment terms

  useEffect(() => {
    if (openCaptureWaitingModal) {
      const getTerms = async () => {
        setPaymentTermsLoading(true);
        const response = await axiosApiInstance.get("/info/payment-terms");
        setPaymentTermsLoading(false);

        if (response?.data?.data) {
          setPaymentTerms(response?.data?.data);
        }
      };
      getTerms();
    }
  }, [openCaptureWaitingModal]);

  //put seller fee to input default or put payment terms default fee to fee percentage input
  useEffect(() => {
    if (paymentTerms.length) {
      const selectedTermPercentage: any = paymentTerms.find(
        (term) => term.paymentTerm === selectedTerm
      );

      if (parseFloat(feePercentage as any) >= 100) {
        setValue("feePercentage", selectedTermPercentage?.fee);
      }
    }
  }, [feePercentage, selectedTerm, setValue, paymentTerms]);

  useEffect(() => {
    if (
      sellerCompany &&
      openCaptureWaitingModal &&
      !companySellerFeesData.length
    ) {
      const getSellerFee = async () => {
        setSellerFeesLoading(true);
        const sellerFeeRes = await axiosApiInstance.post(
          "/fees/get-company-fees",
          {
            companyId: sellerCompany,
          }
        );
        setSellerFeesLoading(false);
        if (sellerFeeRes?.data?.allFees) {
          setCompanySellerFeesData(sellerFeeRes?.data?.allFees);
        } else {
          setCompanySellerFeesData([]);
        }
      };
      getSellerFee();
    }
  }, [openCaptureWaitingModal, sellerCompany, companySellerFeesData.length]);

  useEffect(() => {
    if (paymentTerms.length) {
      const selectedTermPercentage = paymentTerms.find(
        (term) => term.paymentTerm === selectedTerm
      );
      {
        if (companySellerFeesData.length) {
          const companySpecFee = companySellerFeesData.find(
            (item) => item.finmidFeesType === selectedTerm
          );

          setValue(
            "feePercentage",
            // "2.5"
            companySpecFee?.seller_fee
              ? companySpecFee?.seller_fee.toString()
              : selectedTermPercentage?.fee.toString()
          );
        } else {
          setValue("feePercentage", selectedTermPercentage?.fee.toString());
          // setValue("feePercentage", "2.5");
        }
      }
    }
  }, [companySellerFeesData, paymentTerms, selectedTerm, setValue]);

  return {
    sellerFeesLoading,
    paymentTermsLoading,
    paymentTerms,
    openCaptureWaitingModal,
    setOpenCaptureWaitingModal,
    companySellerFeesData,
    selectedTerm,
    setSelectedTerm,
  };
};
