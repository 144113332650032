import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OrderStateType } from "../../orderStateType";
import {
  Box,
  Typography,
  Chip,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import QRCode from "react-qr-code";
import NumberFormatComponent from "../../../../components/NumberFormatComponent";
import AdminButtonComponent from "../../../../components/AdminButtonComponent";
import {
  cancelOrder,
  createInvoice,
  createPaymentRequest,
  getPaymentTerms,
  removeFruPay,
  updateDeliveryDate,
  updateInvoiceDate,
} from "../../../../services/surplus-allocation";
import dayjs from "dayjs";
import AdminModalComponent from "../../../../components/AdminModalComponent";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { InvoicesState } from "./InvoiceInterface";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import usePermissions from "../../../../hooks/usePermissions";
import PermissionWrapper from "../../../../components/PermissionWrapper";
import { OrderStatuses } from "../../../../constants";
import { PartialState } from "./SurplusOrderDetailContainer";
import {
  CompanyFeesType,
  PaymentTermsType,
} from "../../../CreateNewSurplus/CreateNewSurplusContainer";
import ControlledTextField from "../../../../components/ControlledTextField";
import { getSellerFeesWithoutPagination } from "../../../../services/fees";

const useStyles = makeStyles({
  OrderInfoBox: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: 20,
  },
  OrderItemBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  OrderDetailText: {
    fontSize: 16,
    fontWeight: 400,
  },
  OrderDetailExpText: {
    fontSize: 16,
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    gap: 5,
    color: "#E77228",
  },
  statusAllocatedBox: {
    background: "#D9FFCC !important",
    color: "#699D56!important",
  },
  statusInvoicedBox: {
    background: "#DCDFFF!important",
    color: "#4252FF!important",
  },
  statusPaidBox: {
    background: "#CAF2FF!important",
    color: "#008DB9!important",
  },
  statusCancelBox: {
    background: "#CF142B!important",
    color: "white!important",
  },
  statusCompleteBox: {
    background: "#A3C585!important",
    color: "white!important",
  },
  statusSettledBox: {
    background: "#5F8CBA",
    color: "white",
  },
  AcceptanceBox: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  AcceptanceExpText: {
    color: "#196602",
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  cancelOrder: {
    borderRadius: "10px!important",
    width: "100%!important",
    background: "#CF142B!important",
    border: "1px solid #CF142B!important",
    color: "white!important",
  },
  acceptInvoice: {
    borderRadius: "10px!important",
    width: "100%!important",
    background: "#69cdae!important",
    border: "1px solid #69CDAE!important",
    color: "white!important",
  },
  confirmButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  qrBox: {
    borderRadius: 10,
    padding: 20,
    background: "#f8f8f8",
  },
  qrStyle: {
    width: "100%",
    height: 150,
    cursor: "pointer",
  },
});
interface PropsType {
  refresh: number;
  setValue: any;
  partialPayment: PartialState[];
  invoices: InvoicesState[];
  setInvoices: React.Dispatch<React.SetStateAction<InvoicesState[]>>;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  setOrderData: React.Dispatch<
    React.SetStateAction<OrderStateType | undefined>
  >;
  setRefreshPartial: React.Dispatch<React.SetStateAction<number>>;
  orderData: OrderStateType | undefined;
  watch: any;
  control: any;
}

const OrderInformationBox: React.FC<PropsType> = ({
  setValue,
  watch,
  control,
  setInvoices,
  setOrderData,
  refresh,
  setRefreshPartial,
  orderData,
  partialPayment,
  invoices,
  setRefresh,
}) => {
  const { hasPermission } = usePermissions();
  const classes = useStyles();
  const documentLinkSeller =
    process.env.REACT_APP_MARKETPLACE_URL === "https://staging.frupro.app"
      ? `https://staging.frupro.app/mdu/${orderData?.magicLink?.seller}`
      : `https://app.frupro.com/mdu/${orderData?.magicLink?.seller}`;
  const documentLinkBuyer =
    process.env.REACT_APP_MARKETPLACE_URL === "https://staging.frupro.app"
      ? `https://staging.frupro.app/mdu/${orderData?.magicLink?.buyer}`
      : `https://app.frupro.com/mdu/${orderData?.magicLink?.buyer}`;

  const [isRemovingPayment, setIsRemovingPayment] = useState(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [isInvoiceCreating, setIsInvoiceCreating] = useState<boolean>(false);
  const [newDeliveryDate, setNewDeliveryDate] = useState<string>("");
  const [newInvoiceDate, setNewInvoiceDate] = useState<string>("");
  const [isCreatingPayment, setIsCreatingPayment] = useState<boolean>(false);
  const [isInvoiceDateUpdating, setIsInvoiceDateUpdating] =
    useState<boolean>(false);
  const [showCancelOrderModal, setShowCancelorderModal] =
    useState<boolean>(false);
  const [showCreatePaymentModal, setShowCreatePaymentModal] =
    useState<boolean>(false);
  const [showRemovePaymentModal, setShowRemovePaymentModal] =
    useState<boolean>(false);

  //create payment for non frupay
  const [paymentTermsLoading, setPaymentTermsLoading] = useState(false);
  const [sellerFeesLoading, setSellerFeesLoading] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState<PaymentTermsType[]>([]);
  const [companySellerFeesData, setCompanySellerFeesData] = useState<
    CompanyFeesType[]
  >([]);
  const [selectedTerm, setSelectedTerm] = useState("30D");
  const [isApplyPartial, setIsApplyPartial] = useState(false);
  const watchFeePercentage = watch("feePercentage");
  const watchUpfrontPaymentAmount = watch("upfrontPaymentAmount");
  const watchFinalPaymentAmount = watch("finalPaymentAmount");

  //get payment Terms

  useEffect(() => {
    if (showCreatePaymentModal && !paymentTerms.length) {
      const getTerms = async () => {
        setPaymentTermsLoading(true);
        const response = await getPaymentTerms();
        setPaymentTermsLoading(false);

        if (response?.data) {
          setPaymentTerms(response?.data);
        }
      };
      getTerms();
    }
  }, [showCreatePaymentModal, paymentTerms]);

  //if fee percentage is greater than 100 reset value

  useEffect(() => {
    const selectedTermPercentage = paymentTerms.find(
      (term) => term.paymentTerm === selectedTerm
    );
    if (companySellerFeesData.length) {
      const companySpecFee = companySellerFeesData.find(
        (item) => item.finmidFeesType === selectedTerm
      );

      setValue(
        "feePercentage",
        // "2.5"
        companySpecFee?.seller_fee
          ? companySpecFee?.seller_fee.toString()
          : selectedTermPercentage?.fee.toString()
      );
    } else {
      setValue("feePercentage", selectedTermPercentage?.fee.toString());
      // setValue("feePercentage", "2.5");
    }
  }, [selectedTerm, paymentTerms, setValue, companySellerFeesData]);

  //put seller fee to input default or put payment terms default fee to fee percentage input
  useEffect(() => {
    if (paymentTerms.length) {
      const selectedTermPercentage: any = paymentTerms.find(
        (term) => term.paymentTerm === selectedTerm
      );

      if (parseFloat(watchFeePercentage as any) >= 100) {
        setValue("feePercentage", selectedTermPercentage?.fee);
      }
    }
  }, [watchFeePercentage, selectedTerm, setValue, paymentTerms]);

  useEffect(() => {
    if (
      orderData?.sellerCompany._id &&
      showCreatePaymentModal &&
      !companySellerFeesData.length
    ) {
      const getSellerFee = async () => {
        setSellerFeesLoading(true);
        const sellerFeeRes = await getSellerFeesWithoutPagination(
          orderData?.sellerCompany._id
        );
        setSellerFeesLoading(false);
        if (sellerFeeRes?.allFees) {
          setCompanySellerFeesData(sellerFeeRes?.allFees);
        } else {
          setCompanySellerFeesData([]);
        }
      };
      getSellerFee();
    }
  }, [orderData, showCreatePaymentModal, companySellerFeesData.length]);

  //ser value for upfront and final payment amount
  useEffect(() => {
    if (
      orderData?.grandTotalAmount &&
      watchUpfrontPaymentAmount > orderData?.grandTotalAmount
    ) {
      setValue(
        "upfrontPaymentAmount",
        (orderData?.grandTotalAmount / 2).toFixed(2)
      );
      setValue(
        "finalPaymentAmount",
        (
          orderData?.grandTotalAmount - parseFloat(watchUpfrontPaymentAmount)
        ).toFixed(2)
      );
    } else {
      if (orderData?.grandTotalAmount) {
        setValue(
          "finalPaymentAmount",
          watchUpfrontPaymentAmount
            ? (
                orderData?.grandTotalAmount -
                parseFloat(watchUpfrontPaymentAmount)
              ).toFixed(2)
            : orderData?.grandTotalAmount.toFixed(2)
        );
      }
    }
  }, [orderData?.grandTotalAmount, watchUpfrontPaymentAmount, setValue]);
  useEffect(() => {
    if (orderData?.grandTotalAmount) {
      setValue(
        "upfrontPaymentAmount",
        (orderData?.grandTotalAmount / 2).toFixed(2)
      );
    }
  }, [orderData?.grandTotalAmount, setValue]);

  useEffect(() => {
    if (watchUpfrontPaymentAmount && orderData?.grandTotalAmount) {
      setValue(
        "upfrontPaymentAmountPercentage",
        (
          (parseFloat(watchUpfrontPaymentAmount) * 100) /
          orderData?.grandTotalAmount
        ).toFixed(2)
      );
      setValue(
        "finalPaymentAmountPercentage",
        (
          100 -
          (parseFloat(watchUpfrontPaymentAmount) * 100) /
            orderData?.grandTotalAmount
        ).toFixed(2)
      );
    } else {
      setValue("upfrontPaymentAmountPercentage", "0");
      setValue("finalPaymentAmountPercentage", "100");
    }
  }, [watchUpfrontPaymentAmount, setValue, orderData?.grandTotalAmount]);

  const onOpenCancelOrderModal = () => {
    setShowCancelorderModal(true);
  };
  const onCloseCancelOrderModal = () => {
    setShowCancelorderModal(false);
  };

  const onOpenCreatePaymentModal = () => {
    setShowCreatePaymentModal(true);
  };
  const onCloseCreatePaymentModal = () => {
    setShowCreatePaymentModal(false);
  };

  const onOpenRemovePaymentModal = () => {
    setShowRemovePaymentModal(true);
  };
  const onCloseRemovePaymentModal = () => {
    setShowRemovePaymentModal(false);
  };

  const orderStatusChipColor = useMemo(() => {
    return OrderStatuses.find((i) => i.name === orderData?.status);
  }, [orderData?.status])?.color;

  const isCancelDisabled = useMemo(() => {
    return !partialPayment.map((i) => i.status).every((i) => i === "WAITING") ||
      orderData?.status === "PARTIALLY PAID" ||
      orderData?.status === "CANCELLED" ||
      orderData?.status === "PARTIALLY REPAID" ||
      orderData?.status === "COMPLETE" ||
      orderData?.status === "INVOICED" ||
      orderData?.status === "BUYER_PAID" ||
      orderData?.invoices.length
      ? orderData?.invoices
          .map((item) => item.status)
          .every((item) => item !== "DRAFT")
      : false;
  }, [orderData?.status, orderData?.invoices.length, partialPayment.length]);

  const isRemovePaymentDisabled = useMemo(() => {
    return partialPayment.map((i) => i.status).includes("CAPTURED");
  }, [partialPayment]);

  // const cannotEditProduceOrCannotDiscountOrCreatePayment =
  //   orderData?.status === "UNREACHABLE" ||
  //   orderData?.status === "PENDING" ||
  //   orderData?.status === "CANCELLED" ||
  //   orderData?.status === "PAID" ||
  //   orderData?.status === "COMPLETE";

  useEffect(() => {
    if (refresh || orderData) {
      setNewDeliveryDate(orderData?.deliveryDate?.[0]?.deliveryDate || "");
      setNewInvoiceDate(invoices?.[0]?.invoiceDate);
    }
  }, [refresh, orderData]);

  const onCancelOrder = async () => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    setIsCancelling(true);
    await cancelOrder(orderData?._id ? orderData?._id : "");
    setIsCancelling(false);

    setRefresh((prev: any) => prev + 1);
    setRefreshPartial((prev: any) => prev + 1);
    setShowCancelorderModal(false);
  };

  const onConvertToFruPay = useCallback(async () => {
    const finalData = {
      offerId: orderData?._id,
      repayment_term: selectedTerm,
      partialPayment:
        isApplyPartial && parseFloat(watchUpfrontPaymentAmount as any)
          ? [
              {
                amount: parseFloat(watchUpfrontPaymentAmount as any),
              },
              {
                amount: parseFloat(watchFinalPaymentAmount as any),
              },
            ]
          : undefined,
      override_amounts: {
        sellers_fee_override_percentage: parseFloat(watchFeePercentage),

        buyer_fee_override_percentage: undefined,
      },
    };
    setIsCreatingPayment(true);
    const response = await createPaymentRequest(finalData);
    setIsCreatingPayment(false);
    if (response) {
      setRefresh((prev: any) => prev + 1);
      setRefreshPartial((prev: any) => prev + 1);
    }
  }, [
    setRefreshPartial,
    orderData,
    isApplyPartial,
    selectedTerm,
    setRefresh,
    watchFeePercentage,
    watchFinalPaymentAmount,
    watchUpfrontPaymentAmount,
  ]);

  const onCreateInvoice = async () => {
    if (!hasPermission("INVOICE_CREATE_DRAFT_INVOICE")) return;

    setIsInvoiceCreating(true);
    const data = {
      offerId: orderData?._id,
      refs: {
        buyerReferenceNumber: orderData?.refs.buyerReferenceNumber,
        sellerReferenceNumber: orderData?.refs.sellerReferenceNumber,
      },
    };
    await createInvoice(data);
    setIsInvoiceCreating(false);
    setRefresh((prev: any) => prev + 1);
  };

  const onUpdateInvoiceDate = async (selectedDate: any) => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    if (!selectedDate) {
      toast.error("Please select invoice date");
    } else {
      setIsInvoiceDateUpdating(true);
      const data = {
        invoiceId: invoices?.[0]?.id,
        invoiceDate: selectedDate,
      };
      const updated = await updateInvoiceDate(data);
      setIsInvoiceDateUpdating(false);
      if (updated?.success) {
        setNewInvoiceDate("");
        setOrderData(undefined);
        setInvoices([]);
        setRefresh((prev: any) => prev + 1);
      }
    }
  };

  const onUpdateDeliveryDate = async (selectedDate: any) => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    if (!selectedDate) {
      toast.error("Please select delivery date");
    } else {
      setIsInvoiceDateUpdating(true);
      const data = {
        invoiceId: invoices?.[0]?.id,
        deliveryDate: selectedDate,
      };
      const updated = await updateDeliveryDate(data);
      setIsInvoiceDateUpdating(false);
      if (updated?.success) {
        setNewDeliveryDate("");
        setOrderData(undefined);
        setRefresh((prev: any) => prev + 1);
      }
    }
  };

  const onRemovePayment = useCallback(async () => {
    setIsRemovingPayment(true);
    const removed = await removeFruPay(orderData?._id || "");
    setIsRemovingPayment(false);

    if (removed?.success) {
      setRefresh((prev: any) => prev + 1);
      setRefreshPartial((prev: any) => prev + 1);
    }
  }, [orderData?._id, setRefresh, setRefreshPartial]);

  const renderCancelOrderModalBody = () => {
    return (
      <>
        <div className="container">
          <span>Are you sure you want to cancel this order?</span>
          <div className={classes.filterModalButtons}>
            <AdminButtonComponent
              disabled={isCancelling}
              className={classes.closeButton}
              onClick={onCloseCancelOrderModal}
              title="Close"
            />{" "}
            <AdminButtonComponent
              disabled={isCancelling}
              loading={isCancelling}
              className={classes.confirmButton}
              onClick={onCancelOrder}
              title="Confirm"
            />
          </div>
        </div>
      </>
    );
  };

  const renderCreatePaymentModalBody = () => {
    return (
      <>
        <div className="container">
          {paymentTermsLoading || sellerFeesLoading ? (
            <div className="flex justify-content-center pt-4 pb-4">
              <CircularProgress style={{ width: 25, height: 25 }} />
            </div>
          ) : (
            <>
              <RadioGroup
                className="mt-2"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                {paymentTerms.map((term, index) => {
                  return (
                    <div key={index}>
                      <FormControlLabel
                        defaultChecked
                        checked={term.paymentTerm === selectedTerm}
                        value={term.paymentTerm}
                        onChange={(e: any) => setSelectedTerm(e.target?.value)}
                        control={<Radio style={{ color: "#E77228" }} />}
                        label={`${term?.paymentTerm?.split("D")?.[0]} Days`}
                      />
                    </div>
                  );
                })}
              </RadioGroup>
              <div className="mt-3 row d-flex align-items-center">
                <div className="col-md-6">
                  <ControlledTextField
                    textLabel="Fee Percentage"
                    control={control}
                    name="feePercentage"
                  />
                </div>
                <div className="col-md-6 mt-3 d-flex gap-1 justify-end">
                  Platform Fee:{" "}
                  <NumberFormatComponent
                    prefix={orderData?.produces[0].currency.symbol}
                    value={
                      (orderData?.grandTotalAmount &&
                        (parseFloat(watchFeePercentage) *
                          orderData?.grandTotalAmount) /
                          100) ||
                      0
                    }
                  />
                </div>
              </div>

              <hr style={{ opacity: 0.07 }} className="mt-3 mb-3" />
              <div className="mt-2">
                <FormControlLabel
                  style={{ display: "flex", flexDirection: "row", gap: 3 }}
                  control={
                    <Checkbox
                      defaultChecked={isApplyPartial}
                      checked={isApplyPartial}
                      value={isApplyPartial}
                      // disabled={
                      //   !watch("isBuyerChecked") ||
                      //   !addedProduces?.length ||
                      //   finmidLimit < totalEstimatedValue
                      // }
                      onChange={(e) => {
                        setIsApplyPartial(e.target.checked);
                      }}
                      style={{ color: "#E77228", marginLeft: 9 }}
                    />
                  }
                  label="Apply Partial Payment"
                />
              </div>
              {isApplyPartial ? (
                <div className="container ">
                  {
                    <div className="mt-3 d-flex flex-column">
                      <div className="d-flex flex-row items-center gap-2">
                        <div className="col-6">
                          <ControlledTextField
                            textLabel="Upfront Payment Amount"
                            defaultValue={
                              orderData?.grandTotalAmount
                                ? (orderData?.grandTotalAmount / 2).toFixed(2)
                                : ""
                            }
                            control={control}
                            name="upfrontPaymentAmount"
                          />
                        </div>
                        <div className="col-3 mt-4 d-flex flex-row items-center ml-3">
                          <ControlledTextField
                            disabled
                            textLabel=""
                            control={control}
                            name="upfrontPaymentAmountPercentage"
                          />
                          %
                        </div>
                      </div>
                      {/* <NumberFormatComponent
                  value={grandTotal / 2}
                  prefix={currencySymbol}
                /> */}

                      <div className="d-flex flex-row items-center gap-2">
                        <div className="col-6 mt-2">
                          <ControlledTextField
                            disabled
                            textLabel="Final Payment Amount"
                            defaultValue={
                              orderData?.grandTotalAmount
                                ? (
                                    orderData?.grandTotalAmount -
                                    parseFloat(watchUpfrontPaymentAmount)
                                  ).toFixed(2)
                                : ""
                            }
                            control={control}
                            name="finalPaymentAmount"
                          />
                        </div>

                        <div className="col-3 mt-4 d-flex flex-row items-center ml-3">
                          <ControlledTextField
                            disabled
                            textLabel=""
                            control={control}
                            name="finalPaymentAmountPercentage"
                          />
                          %
                        </div>
                      </div>

                      {/* <NumberFormatComponent
                  value={grandTotal / 2}
                  prefix={currencySymbol}
                />{" "} */}
                    </div>
                  }
                </div>
              ) : null}
              <div className="d-flex justify-end mt-3 mb-1">
                <AdminButtonComponent
                  style={{ width: 100, height: 40 }}
                  loading={isCreatingPayment}
                  disabled={
                    isCreatingPayment ||
                    !watchFeePercentage ||
                    !watchUpfrontPaymentAmount ||
                    !paymentTerms.length
                  }
                  onClick={onConvertToFruPay}
                  title="Save"
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const renderRemovePaymentModalBody = () => {
    return (
      <>
        <div className="container">
          <span>Are you sure you want to remove payment?</span>
          <div className={classes.filterModalButtons}>
            <AdminButtonComponent
              disabled={isRemovingPayment}
              className={classes.closeButton}
              onClick={onCloseRemovePaymentModal}
              title="Close"
            />{" "}
            <AdminButtonComponent
              disabled={isRemovingPayment}
              loading={isRemovingPayment}
              className={classes.confirmButton}
              onClick={onRemovePayment}
              title="Confirm"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Typography style={{ padding: 20, fontSize: 20, fontWeight: 500 }}>
        Order Information
      </Typography>
      {isInvoiceCreating && <LoadingIndicator loading={isInvoiceCreating} />}
      {/* {isCreatingPayment && <LoadingIndicator loading={isCreatingPayment} />} */}
      {isCancelling && <LoadingIndicator loading={isCancelling} />}
      {isInvoiceDateUpdating && (
        <LoadingIndicator loading={isInvoiceDateUpdating} />
      )}
      <Box className={classes.OrderInfoBox}>
        <Box className={classes.OrderItemBox}>
          <Typography className={classes.OrderDetailText}>Seller</Typography>
          <Typography className={classes.OrderDetailExpText}>
            {orderData?.sellerCompany.companyName}
          </Typography>
        </Box>
        <hr style={{ width: "100%", opacity: 0.03 }} />
        <Box className={classes.OrderItemBox}>
          <Typography className={classes.OrderDetailText}>Buyer</Typography>
          <Typography className={classes.OrderDetailExpText}>
            {orderData?.buyerCompany.companyName}
          </Typography>
        </Box>
        <hr style={{ width: "100%", opacity: 0.03 }} />
        <Box className={classes.OrderItemBox}>
          <Typography className={classes.OrderDetailText}>Status</Typography>
          <Typography className={classes.OrderDetailExpText}>
            <Chip
              style={{
                color: "white",
                minWidth: 120,
                fontWeight: 600,
                backgroundColor: orderStatusChipColor,
              }}
              label={orderData?.status}
            />
          </Typography>
        </Box>

        <hr style={{ width: "100%", opacity: 0.03 }} />
        <Box className={classes.OrderItemBox}>
          <Typography className={classes.OrderDetailText}>Created</Typography>
          <Typography className={classes.OrderDetailExpText}>
            {moment(orderData?.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </Box>

        <hr style={{ width: "100%", opacity: 0.03 }} />
        <Box className={classes.OrderItemBox}>
          <Typography className={classes.OrderDetailText}>
            Order/Delivery Date
          </Typography>
          {/* <Typography className={classes.OrderDetailExpText}>
            {moment(
              orderData?.deliveryDate?.[orderData?.deliveryDate?.length - 1]
                ?.deliveryDate
            ).format("DD/MM/YYYY")}
          </Typography> */}
          {orderData?._id && newDeliveryDate ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                <DatePicker
                  format="DD/MM/YYYY"
                  defaultValue={dayjs(newDeliveryDate)}
                  onAccept={(e) => onUpdateDeliveryDate(e)}
                  sx={{
                    svg: { zIndex: 1000 },
                    input: { zIndex: 1000, width: 100 },
                    "& fieldset": {
                      border: "none",
                      height: 55,
                      backgroundColor: "#f7f7f7",
                      borderRadius: "10px!important",
                    },
                  }}
                />
              </PermissionWrapper>
            </LocalizationProvider>
          ) : null}
        </Box>
        {invoices?.length && newInvoiceDate ? (
          <>
            <hr style={{ width: "100%", opacity: 0.03 }} />
            <Box className={classes.OrderItemBox}>
              <Typography className={classes.OrderDetailText}>
                Invoice Date
              </Typography>
              <Typography className={classes.OrderDetailExpText}>
                {(invoices?.length && invoices?.[0]?.status === "DRAFT") ||
                invoices?.[0]?.status === "PROCESSING" ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                      <DatePicker
                        format="DD/MM/YYYY"
                        defaultValue={dayjs(newInvoiceDate)}
                        onAccept={(e) => onUpdateInvoiceDate(e)}
                        sx={{
                          svg: { zIndex: 1000 },
                          input: { zIndex: 1000, width: 100 },
                          "& fieldset": {
                            border: "none",
                            height: 55,
                            backgroundColor: "#f7f7f7",
                            borderRadius: "10px!important",
                          },
                        }}
                      />
                    </PermissionWrapper>
                  </LocalizationProvider>
                ) : (
                  moment(invoices?.[0]?.invoiceDate).format("DD/MM/YYYY")
                )}
              </Typography>
            </Box>
          </>
        ) : null}
        <hr style={{ width: "100%", opacity: 0.03 }} />
        <Box className={classes.OrderItemBox}>
          <Typography className={classes.OrderDetailText}>
            Final Amount
          </Typography>
          <Typography className={classes.OrderDetailExpText}>
            <NumberFormatComponent
              prefix={orderData?.produces?.[0]?.currency?.symbol}
              value={
                orderData?.totalAmount
                  ? orderData.totalAmount - orderData?.discount?.value
                  : 0 || ""
              }
            />
          </Typography>
        </Box>
        {!orderData?.payment_option?.amount_details?.buyer_fee_is_default &&
        orderData?.useRefactoring ? (
          <>
            <hr style={{ width: "100%", opacity: 0.03 }} />
            <Box className={classes.OrderItemBox}>
              <Typography className={classes.OrderDetailText}>
                Buyer Fee
              </Typography>
              <Typography className={classes.OrderDetailExpText}>
                <NumberFormatComponent
                  prefix={orderData?.produces?.[0]?.currency?.symbol}
                  value={
                    orderData?.payment_option?.amount_details
                      ?.buyer_fee_amount || ""
                  }
                />
              </Typography>
            </Box>
          </>
        ) : null}
        <hr style={{ width: "100%", opacity: 0.03 }} />
        <Box className={classes.OrderItemBox}>
          <Typography className={classes.OrderDetailText}>FruPay</Typography>
          <Typography className={classes.OrderDetailExpText}>
            {orderData?.useRefactoring ? "YES" : "NO"}
            {orderData?.useRefactoring ||
            orderData?.status === "CANCELLED" ||
            !orderData?.invoices.length ? null : (
              <AdminButtonComponent
                style={{ marginLeft: 10 }}
                title="Create Payment"
                onClick={onOpenCreatePaymentModal}
              />
            )}
            {orderData?.useRefactoring ? (
              <AdminButtonComponent
                style={{ marginLeft: 10 }}
                disabled={isRemovePaymentDisabled}
                title="Remove FruPay"
                onClick={onOpenRemovePaymentModal}
              />
            ) : null}
          </Typography>
        </Box>
        <hr style={{ width: "100%", opacity: 0.03 }} />
        <Box className={classes.AcceptanceBox}>
          <Typography className={classes.AcceptanceExpText}>
            {orderData?.acceptable?.buyer ? (
              <img src="/static/svg/ic_checked.svg" />
            ) : (
              <img src="/static/svg/ic_unchecked.svg" />
            )}
            Buyer's acceptance
          </Typography>
          <Typography className={classes.AcceptanceExpText}>
            {orderData?.acceptable?.seller ? (
              <img src="/static/svg/ic_checked.svg" />
            ) : (
              <img src="/static/svg/ic_unchecked.svg" />
            )}
            Seller's acceptance
          </Typography>
        </Box>
        {!orderData?.invoices.length && orderData?.status !== "CANCELLED" ? (
          <Box className="mt-3 ">
            <PermissionWrapper permission="INVOICE_CREATE_DRAFT_INVOICE">
              <AdminButtonComponent
                disabled={
                  isInvoiceCreating || orderData?.status === "CANCELLED"
                }
                className={classes.acceptInvoice}
                onClick={onCreateInvoice}
                title={isInvoiceCreating ? "Creating..." : "Create Invoice"}
              />
            </PermissionWrapper>
          </Box>
        ) : null}

        {isCancelDisabled ? null : (
          <Box className="mt-3 ">
            <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
              <AdminButtonComponent
                disabled={isCancelDisabled || isCancelling}
                className={classes.cancelOrder}
                onClick={onOpenCancelOrderModal}
                title={isCancelling ? "Cancelling..." : "Cancel order"}
              />
            </PermissionWrapper>
          </Box>
        )}

        <div className="mt-2 d-flex flex-row gap-3">
          <div className={classes.qrBox}>
            <a href={documentLinkSeller} target="_blank" rel="noreferrer">
              <Tooltip title="Add document seller">
                <QRCode
                  className={classes.qrStyle}
                  value={documentLinkSeller}
                />
              </Tooltip>
            </a>
          </div>

          <div className={classes.qrBox}>
            <a target="_blank" href={documentLinkBuyer} rel="noreferrer">
              <Tooltip title="Add document buyer">
                <QRCode className={classes.qrStyle} value={documentLinkBuyer} />
              </Tooltip>
            </a>
          </div>
        </div>

        <div className="mt-2 flex flex-col gap-2">
          <span className="flex flex-row items-center gap-2">
            <span>Seller Email Notification :</span>{" "}
            <span style={{ color: "#E77228" }}>
              {" "}
              {orderData?.notifications.seller.email ? (
                <img
                  alt="notification_seller_email"
                  src="/static/svg/ic_checked.svg"
                />
              ) : (
                <img
                  alt="notification_seller_email"
                  src="/static/svg/ic_unchecked.svg"
                />
              )}
            </span>
          </span>
          <span className="flex flex-row items-center gap-2">
            <span>Seller App Notification :</span>{" "}
            <span style={{ color: "#E77228" }}>
              {" "}
              {orderData?.notifications.seller.notification ? (
                <img
                  alt="notification_seller_notification"
                  src="/static/svg/ic_checked.svg"
                />
              ) : (
                <img
                  alt="notification_seller_notification"
                  src="/static/svg/ic_unchecked.svg"
                />
              )}
            </span>
          </span>
          <span className="flex flex-row items-center gap-2">
            <span>Buyer Email Notification :</span>{" "}
            <span style={{ color: "#E77228" }}>
              {" "}
              {orderData?.notifications.buyer.email ? (
                <img
                  alt="notification_buyer_email"
                  src="/static/svg/ic_checked.svg"
                />
              ) : (
                <img
                  alt="notification_buyer_email"
                  src="/static/svg/ic_unchecked.svg"
                />
              )}
            </span>
          </span>
          <span className="flex flex-row items-center gap-2 ">
            <span>Buyer App Notification :</span>{" "}
            <span style={{ color: "#E77228" }}>
              {" "}
              {orderData?.notifications.buyer.notification ? (
                <img
                  alt="notification_buyer_notification"
                  src="/static/svg/ic_checked.svg"
                />
              ) : (
                <img
                  alt="notification_buyer_notification"
                  src="/static/svg/ic_unchecked.svg"
                />
              )}
            </span>
          </span>
        </div>
      </Box>

      <AdminModalComponent
        children={renderCancelOrderModalBody()}
        headerTitle="Are you sure"
        openModal={showCancelOrderModal}
        closeModal={onCloseCancelOrderModal}
      />
      <AdminModalComponent
        children={renderCreatePaymentModalBody()}
        headerTitle="Create Payment"
        openModal={showCreatePaymentModal}
        closeModal={onCloseCreatePaymentModal}
      />
      <AdminModalComponent
        children={renderRemovePaymentModalBody()}
        headerTitle="Remove Payment"
        openModal={showRemovePaymentModal}
        closeModal={onCloseRemovePaymentModal}
      />
    </div>
  );
};

export default OrderInformationBox;
