import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import {
  deleteAIInvoice,
  getAIInvoices,
  processAIInvoice,
  uploadAIIInvoice,
} from "../../services/ai-invoices";
import LoadingIndicator from "../../components/LoadingIndicator";
import AdminModalComponent from "../../components/AdminModalComponent";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { Box, Chip, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { AIInvoicesState } from "./interface";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledComboBox from "../../components/ControlledComboBox";
import FilterBadge from "../../components/FilterBadge";
import usePermissions from "../../hooks/usePermissions";
import PermissionWrapper from "../../components/PermissionWrapper";
import { Helmet } from "react-helmet";

const schema = yup.object().shape({
  status: yup.array(),
});

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  deleteModalButton: {
    width: "50%",
    height: "50px",
    background: "red !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  labelImage: {
    cursor: "pointer",
  },
  uploadSvg: {
    borderRadius: "100%",
    width: 50,
    height: 50,
  },
  imageTitle: {
    marginLeft: 5,
  },
  trash: {
    cursor: "pointer",
    color: "red",
    marginLeft: 15,
    fontSize: 20,
  },
  seeFile: {
    color: "#E77228",
    "&:hover": {
      color: "#E77228",
    },
  },
  pending: {
    backgroundColor: "#E0A600!important",
    width: 150,
    color: "white!important",
  },
  reject: {
    width: 150,
    backgroundColor: "#ED0000!important",
    color: "white!important",
  },
  deleted: {
    width: 150,

    color: "white!important",
  },
  approved: {
    width: 150,
    backgroundColor: "#69CDAE!important",
    color: "white!important",
  },
  processed: {
    width: 150,
    backgroundColor: "#5F8CBA!important",
    color: "white!important",
  },
  failed: {
    width: 150,
    backgroundColor: "#ED0000!important",
    color: "white!important",
  },
  processButton: {
    textTransform: "capitalize",
    width: 150,
    borderRadius: "10px!important",
    backgroundColor: "#3F8CBA!important",
  },
  detailsButton: {
    textTransform: "capitalize",
    width: 150,
    borderRadius: "10px!important",
    backgroundColor: "#6F8CBE!important",
  },
  deleteButton: {
    width: 150,
    textTransform: "capitalize",
    borderRadius: "10px!important",
    backgroundColor: "red!important",
  },
});

const columns = [
  { name: "File", id: "file" },
  { name: "Order Code", id: "orderCode" },
  { name: "Date", id: "createdAt" },
  { name: "Status", id: "status" },
  { name: "", id: "action" },
];
const AIInvoicesListContainer = () => {
  const { hasPermission } = usePermissions();
  const { control, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const classes = useStyles();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [openUploadInvoiceModal, setOpenUploadInvoiceModal] =
    useState<boolean>(false);
  const [openProcessInvoiceModal, setOpenProcessInvoiceModal] =
    useState<boolean>(false);
  const [openDeleteInvoiceModal, setOpenDeleteInvoiceModal] =
    useState<boolean>(false);
  const [openFilterInvoiceModal, setOpenFilterInvoiceModal] =
    useState<boolean>(false);

  const [filterData, setFilterData] = useState({});
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>("");
  const [deletedFilterKey, setDeletedFilterKey] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [invoices, setInvoices] = useState<AIInvoicesState[]>([]);

  const watchStatus = watch("status");

  const onOpenProcessModal = (id: string) => {
    setOpenProcessInvoiceModal(true);
    setSelectedInvoiceId(id);
  };
  const onOpenDeleteModal = (id: string) => {
    setOpenDeleteInvoiceModal(true);
    setSelectedInvoiceId(id);
  };
  const onOpenFilterModal = () => {
    setOpenFilterInvoiceModal(true);
  };
  const onCloseFilterModal = () => {
    setOpenFilterInvoiceModal(false);
  };
  const onCloseUploadModal = () => {
    setOpenUploadInvoiceModal(false);
  };

  const onCloseProcessModal = () => {
    setOpenProcessInvoiceModal(false);
    setSelectedInvoiceId("");
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteInvoiceModal(false);
    setSelectedInvoiceId("");
  };

  const onRemoveFile = () => {
    setSelectedFiles([]);
  };

  useEffect(() => {
    setValue(`${deletedFilterKey}` as any, undefined);
  }, [deletedFilterKey]);

  useEffect(() => {
    if (!hasPermission("INVOICE_READING_VIEW")) return;

    const getData = async () => {
      setFetchLoading(true);
      const response = await getAIInvoices(queryParams.page, filterData);
      setFetchLoading(false);

      if (response) {
        setInvoices(response?.items);
        setQueryParams({
          page: response?.metaData?.page,
          total: response?.metaData?.total,
          totalPage: response?.metaData?.totalPage,
          limit: response?.metaData?.limit,
        });
      }
    };
    getData();
  }, [
    queryParams.page,
    refresh,
    filterData,
    hasPermission("INVOICE_READING_VIEW"),
  ]);

  const onUploadInvoice = async () => {
    if (!hasPermission("INVOICE_READING_VIEW")) return;

    if (!selectedFiles?.length) {
      toast.error("Please select an invoice");
    } else {
      setLoading(true);
      const response = await uploadAIIInvoice(selectedFiles);
      setLoading(false);

      if (response) {
        setOpenUploadInvoiceModal(false);
        setRefresh((prev: number) => prev + 1);
        setSelectedFiles([]);
      }
    }
  };

  

  const onFilter = async () => {
    setQueryParams({
      ...queryParams,
      page: 1,
    });
    setFilterData({
      status: watchStatus?.length ? watchStatus : undefined,
    });

    setOpenFilterInvoiceModal(false);
  };

  const onProcessDocument = async () => {
    setLoading(true);
    const response = await processAIInvoice(selectedInvoiceId);
    setLoading(false);

    if (response) {
      setRefresh((prev: number) => prev + 1);
      setOpenProcessInvoiceModal(false);
      setSelectedInvoiceId("");
    }
  };

  const onDeleteDocument = async () => {
    setLoading(true);
    const response = await deleteAIInvoice(selectedInvoiceId);
    setLoading(false);

    if (response === 200) {
      setRefresh((prev: number) => prev + 1);
      setOpenDeleteInvoiceModal(false);
      setSelectedInvoiceId("");
    }
  };

  const renderTableBody = () => {
    return (
      <>
        {invoices.map((invoice, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {invoice.status === "DELETED" ? (
                  <span>{invoice.fileName}</span>
                ) : (
                  <a
                    className={classes.seeFile}
                    target="_blank"
                    href={invoice.fileUrl}
                  >
                    {invoice.fileName}
                  </a>
                )}
              </TableCell>
              <TableCell component="th" scope="row">
                {
                  <a
                    className={classes.seeFile}
                    target="_blank"
                    href={`/surplus-allocation/${invoice.orderId}`}
                  >
                    {invoice?.orderCode}
                  </a>
                }
              </TableCell>
              <TableCell component="th" scope="row">
                {moment(invoice.createdAt).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell component="th" scope="row">
                {
                  <Chip
                    className={
                      invoice.status === "PENDING"
                        ? classes.pending
                        : invoice.status === "APPROVED"
                        ? classes.approved
                        : invoice.status === "FAILED"
                        ? classes.failed
                        : invoice.status === "REJECTED"
                        ? classes.reject
                        : invoice.status === "DELETED"
                        ? classes.deleted
                        : invoice.status === "PROCESSED"
                        ? classes.processed
                        : ""
                    }
                    label={invoice.status}
                  />
                }
              </TableCell>
              <TableCell component="th" scope="row">
                <div className="d-flex  gap-2 justify-content-end">
                  <div className="d-flex gap-2 flex-column  justify-content-end">
                    {invoice.status === "PENDING" ||
                    invoice.status === "FAILED" ? (
                      <AdminButtonComponent
                        onClick={() => onOpenProcessModal(invoice._id)}
                        className={classes.processButton}
                        title="Process"
                      />
                    ) : invoice.status === "PROCESSED" ? (
                      <a href={`/invoice/${invoice._id}`} target="_blank">
                        <AdminButtonComponent
                          onClick={() => null}
                          className={classes.detailsButton}
                          title="Details"
                        />
                      </a>
                    ) : null}
                    {invoice.status === "PENDING" ||
                    invoice.status === "PROCESSED" ||
                    invoice.status === "FAILED" ? (
                      <AdminButtonComponent
                        onClick={() => onOpenDeleteModal(invoice._id)}
                        className={classes.deleteButton}
                        title="Delete"
                      />
                    ) : null}
                  </div>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };
  const renderUploadModalBody = () => {
    return (
      <>
        <div>
          <label className={classes.labelImage}>
            {selectedFiles.length ? (
              <>
                {selectedFiles.length} {selectedFiles.length===1?"file":"files"} selected
                <i
                  onClick={onRemoveFile}
                  className={`ri-delete-bin-line ${classes.trash}`}
                ></i>
              </>
            ) : (
              <div className="d-flex flex-row align-items-center gap-2">
                <img
                  className={classes.uploadSvg}
                  src="/static/svg/upload.svg"
                />
                <span className={classes.imageTitle}>
                  {selectedFiles.length ? null : "Please select invoices to upload"}
                </span>
              </div>
            )}

            {selectedFiles.length ? null : (
              <input
              multiple
                onChange={(e: any) => setSelectedFiles(e.target.files)}
                type="file"
                accept=".pdf,.xls,.xlsx"
                hidden
              />
            )}
          </label>
        </div>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseUploadModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={onUploadInvoice}
            title="Submit"
          />
        </Box>
      </>
    );
  };
  const renderProcessModalBody = () => {
    return (
      <>
        <span>Are you sure you want to start process?</span>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseProcessModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={onProcessDocument}
            title="Submit"
          />
        </Box>
      </>
    );
  };
  const renderDeleteModalBody = () => {
    return (
      <>
        <span>Are you sure you want to delete invoice?</span>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseDeleteModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.deleteModalButton}
            onClick={onDeleteDocument}
            title="Delete"
          />
        </Box>
      </>
    );
  };

  const renderFilterModalBody = () => {
    return (
      <>
        <div className="col-12">
          <ControlledComboBox
            className={classes.comboBox}
            multiple
            control={control}
            name="status"
            options={[
              "DELETED",
              "REJECTED",
              "APPROVED",
              "PROCESSED",
              "PENDING",
              "FAILED",
            ]}
            textLabel="Status"
          />
        </div>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseFilterModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={onFilter}
            title="Submit"
          />
        </Box>
      </>
    );
  };
  return (
    <Sidebar pageTitle="Uploaded Invoice Documents">
       <Helmet>
          <title>Uploaded Invoices | FruPro Admin Portal</title>
        </Helmet>
      {loading && <LoadingIndicator loading />}
      <PermissionWrapper
        permission="INVOICE_READING_VIEW"
        unauthorizedComponent
      >
        <TableComponent
          loading={fetchLoading}
          filterBadge={
            <FilterBadge
              filterData={filterData}
              setFilterData={setFilterData}
              setDeletedFilterKey={setDeletedFilterKey}
            />
          }
          showFilterIcon
          onOpenModal={onOpenFilterModal}
          setOpenModal={setOpenUploadInvoiceModal}
          headerButtonTitle=" + Upload new invoice"
          columns={columns}
          showRightButton
          TableBodyComponent={renderTableBody}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
        />
      </PermissionWrapper>
      <AdminModalComponent
        headerTitle="Upload an invoice"
        children={renderUploadModalBody()}
        closeModal={onCloseUploadModal}
        openModal={openUploadInvoiceModal}
      />

      <AdminModalComponent
        headerTitle="Are you sure?"
        children={renderProcessModalBody()}
        closeModal={onCloseProcessModal}
        openModal={openProcessInvoiceModal}
      />

      <AdminModalComponent
        headerTitle="Are you sure?"
        children={renderDeleteModalBody()}
        closeModal={onCloseDeleteModal}
        openModal={openDeleteInvoiceModal}
      />
      <AdminModalComponent
        headerTitle="Filter Invoices"
        children={renderFilterModalBody()}
        closeModal={onCloseFilterModal}
        openModal={openFilterInvoiceModal}
      />
    </Sidebar>
  );
};

export default AIInvoicesListContainer;
