import React, { useCallback, useEffect, useState } from "react";
import {
  addCompanyImage,
  deleteCompanyImages,
  getCompanyImages,
} from "../../../services/company";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { ImagesStateType } from "./interfaces";
import TableComponent from "../../../components/TableComponent";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import moment from "moment";
import AdminModalComponent from "../../../components/AdminModalComponent";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import PermissionWrapper from "../../../components/PermissionWrapper";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import ControlledComboBox from "../../../components/ControlledComboBox";
import ControlledTextField from "../../../components/ControlledTextField";
import { toast } from "react-toastify";
import SkeletonLoading from "../../../components/SkeletonLoading";

const schema = yup.object().shape({
  content: yup.string(),
});

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  iconBox: {
    gap: 10,
    display: "flex",
    justifyContent: "flex-end",
  },
  image: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "150px!important",
    width: "150px",
    borderRadius: 10,
  },
  contentBox: {
    width: "50%",
    wordBreak: "break-all",
  },
  delete: {
    color: "red",
  },

  deleteButton: {
    width: "50%",
    height: "50px",
    background: "red !important",
    borderRadius: "20px !important",
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#e77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },

  trash: {
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: 22,
    alignItems: "center",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    background: "white",
    width: 30,
    height: 30,
    cursor: "pointer",
    color: "red",
  },
  imageTitle: {
    marginLeft: 5,
  },
  labelImage: {
    cursor: "pointer",
  },
  uploadSvg: {
    borderRadius: "100%",
    width: 50,
    height: 50,
  },
  seeFile: {
    cursor: "pointer",
    color: "#E77228",
    textDecoration: "underline",
  },
  headerDocsText: {
    fontWeight: 600,
    fontSize: 20,
  },
  uploadDocButton: {
    borderRadius: "10px!important",
  },
  imgItem: {
    position: "relative",
    backgroundSize: "cover",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    marginTop: 15,

    minHeight: 300,
    borderRadius: 10,
    background: "#f8f8f8",
  },
  uploadDoc: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
    color: "#e77228",
    border: "1px solid",
    borderRadius: 10,
    padding: 5,
    fontWeight: 600,
  },
  hr: {
    opacity: 0.05,
    marginTop: 15,
  },
  contentText: {
    marginTop: 10,
    marginLeft: 15,
  },
});

const columns = [
  { id: "image", name: "Image" },
  { id: "content", name: "Content" },
  { id: "createdAt", name: "Create Time" },
  { id: "action", name: "" },
];

const CompanyImages = ({ companyId }: { companyId: string }) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      content: "",
    },
  });

  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectedUploadFile, setSelectedUploadFile] = useState<any>();
  const [refreshRequest, setRefreshRequest] = useState<number>(0);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [images, setImages] = useState<ImagesStateType[]>([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  const onSelectFile = (e: any) => {
    setSelectedUploadFile(e);
  };

  const onOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const onCloseAddModal = () => {
    setOpenAddModal(false);
  };

  useEffect(() => {
    const getImages = async () => {
      setFetchLoading(true);
      const images = await getCompanyImages(queryParams.page, companyId);
      setFetchLoading(false);
      if (images) {
        setQueryParams({
          page: images?.pagination?.currentPage,
          total: images?.pagination?.totalImages,
          totalPage: images?.pagination?.totalPages,
          limit: 1,
        });
        setImages(images.images);
      }
    };
    getImages();
  }, [queryParams.page, refreshRequest]);

  const onOpenConfirmModal = (id: string) => {
    setOpenConfirmModal(true);
    setSelectedId(id);
  };
  const onCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const onAddImage = useCallback(
    async (data: any) => {
      if (!selectedUploadFile) {
        toast.error("Please select a file");
      } else {
        const formData = new FormData();
        formData.append("content", data?.content);
        formData.append("file", selectedUploadFile);

        setLoading(true);
        const response = await addCompanyImage(formData, companyId);
        setLoading(false);
        if (response) {
          setRefreshRequest((prev: number) => prev + 1);
          setOpenAddModal(false);
        }
      }
    },
    [companyId, selectedUploadFile]
  );

  const onDeleteImage = async () => {
    setLoading(true);
    const deleted = await deleteCompanyImages(companyId, selectedId);
    setLoading(false);

    if (deleted) {
      reset();
      setSelectedUploadFile(undefined);
      setOpenConfirmModal(false);
      setSelectedId("");
      setRefreshRequest(() => refreshRequest + 1);
    }
  };

  const renderTableBody = () => {
    return (
      <>
        {images.map((item, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Box
                  className={classes.image}
                  style={{
                    backgroundImage: `url(${item.url})`,
                  }}
                ></Box>
              </TableCell>
              <TableCell
                className={classes.contentBox}
                component="th"
                scope="row"
              >
                <span className={classes.contentBox}>
                  {" "}
                  {item.content || "N/A"}
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                {moment(item.createdAt).format("DD/MM/YYYY")}
              </TableCell>

              <TableCell className={classes.iconBox}>
                <Tooltip title="Delete image">
                  <PermissionWrapper permission="COMPANY_UPDATE">
                    <IconButton onClick={() => onOpenConfirmModal(item._id)}>
                      <i className={`ri-delete-bin-line ${classes.delete}`}></i>
                    </IconButton>
                  </PermissionWrapper>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderModalBody = () => {
    return (
      <>
        <span>Are you sure you want to delete this image?</span>

        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={onCloseConfirmModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={loading}
            className={classes.deleteButton}
            onClick={onDeleteImage}
            title="Delete"
          />
        </div>
      </>
    );
  };

  const renderAddModalBody = () => {
    return (
      <>
        <ControlledTextField
          control={control}
          textLabel="Content"
          name="content"
        />

        <label className="d-flex flex-row align-items-center mt-4 cursor-pointer">
          {selectedUploadFile ? (
            <div className="d-flex flex-row gap-3 align-items-center">
              <a
                target="_blank"
                rel="noreferrer"
                href={URL.createObjectURL(selectedUploadFile)}
                className={classes.seeFile}
              >
                See File
              </a>
              <i
                onClick={() => setSelectedUploadFile(undefined)}
                className={`ri-delete-bin-line ${classes.trash}`}
              ></i>
            </div>
          ) : (
            <img className={classes.uploadSvg} src="/static/svg/upload.svg" />
          )}

          <span className={classes.imageTitle}>
            {selectedUploadFile ? null : "Please select a file to upload"}
          </span>
          {selectedUploadFile ? null : (
            <input
              onChange={(e) => onSelectFile(e.target.files?.[0])}
              type="file"
              accept=".svg,.jpeg,.jpg,.png"
              hidden
            />
          )}
        </label>
        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={onCloseAddModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={loading}
            className={classes.saveButton}
            onClick={handleSubmit(onAddImage)}
            title="Submit"
          />
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="row">
        <div className="d-flex justify-content-between align-items-center">
          <span className={classes.headerDocsText}>Images</span>
          <PermissionWrapper permission="COMPANY_UPDATE">
            <span onClick={onOpenAddModal} className={classes.uploadDoc}>
              {" "}
              {"+ Upload Image"}
            </span>
          </PermissionWrapper>
        </div>

        <hr className={classes.hr} />

        {fetchLoading ? (
          <SkeletonLoading />
        ) : (
          images.map((image, index) => {
            return (
              <div className="col-md-4" key={index}>
                <div className={classes.imgItem}>
                  <img
                    src={image.url}
                    style={{
                      width: "100%",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      height: 250,
                    }}
                  />
                  <PermissionWrapper permission="COMPANY_UPDATE">
                    <i
                      onClick={() => onOpenConfirmModal(image._id)}
                      className={`position-absolute ri-delete-bin-line ${classes.trash} right-2 top-2`}
                    ></i>
                  </PermissionWrapper>

                  <p className={classes.contentText}>
                    <span style={{ color: "#E77228" }} className="fw-bold">
                      Content :{" "}
                    </span>
                    {image.content || "N/A"}{" "}
                  </p>
                </div>
              </div>
            );
          })
        )}
      </div>
      {/* <TableComponent
        setOpenModal={setOpenAddModal}
        headerButtonTitle=" + Add new ımage"
        loading={fetchLoading}
        showRightButton={true}
        TableBodyComponent={renderTableBody}
        columns={columns}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      /> */}
      <AdminModalComponent
        headerTitle="Are you sure"
        openModal={openConfirmModal}
        closeModal={onCloseConfirmModal}
        children={renderModalBody()}
      />

      <AdminModalComponent
        headerTitle="Add New Image"
        openModal={openAddModal}
        closeModal={onCloseAddModal}
        children={renderAddModalBody()}
      />
    </div>
  );
};

export default CompanyImages;
