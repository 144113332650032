import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import NumberFormat from "react-number-format";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import ControlledComboBox from "../../../components/ControlledComboBox";
import ControlledTextField from "../../../components/ControlledTextField";

const useStyles = makeStyles((theme) => ({
  hr: {
    opacity: 0.4,
  },
  mainText: {
    fontSize: 18,
  },
  Input: {
    borderRadius: "10px!important",
    width: "100%",
    height: 45,
    padding: 15,
  },
  checkLimitButton: {
    border: "none",
    borderRadius: 10,
    width: "100%",
    background: "#E77228",
    color: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    height: "40px",
    marginTop: 12,
    "&:disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },
  customControlledField: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  checkSuccess: {
    color: "#69CDAE!important",
  },
  checkError: {
    color: "red!important",
  },
}));
const RebateDiscount = ({
  control,
  setValue,
  watch,
  reset,
  errors,
  totalEstimatedValue,
}: {
  control: any;
  setValue: any;
  reset: any;
  watch: any;
  errors: any;
  totalEstimatedValue: number;
}) => {
  const classes = useStyles();

  const produceCurrency = watch("produceCurrency");
  const watchApplyFactoring = watch("applyRebateDiscount");
  const rebateOption = watch("rebateDiscount");
  const rebateOptionAmount = watch("rebateDiscountAmount");

  useEffect(() => {
    if (!watchApplyFactoring) {
      setValue("rebateDiscount", undefined);
      setValue("rebateDiscountAmount", undefined);
    }
  }, [watchApplyFactoring]);

  

  useEffect(() => {
    if (
      totalEstimatedValue &&
      rebateOption === "Amount" &&
      totalEstimatedValue < rebateOptionAmount
    ) {
      setValue("rebateDiscountAmount", 0);
      toast.error(
        "Your rebate amount cannot be higher than estimated total value."
      );
    } else if (
      rebateOption === "Percentage" &&
      rebateOptionAmount > 100
    ) {
      setValue("rebateDiscountAmount", 0);
      toast.error("Your rebate percantage cannot be higher than 100.");
    }
  }, [totalEstimatedValue, rebateOption, rebateOptionAmount]);

  return (
    <div>
      <span className={classes.mainText}>Rebate / Discount</span>
      <Divider className={classes.hr} />
      <FormControlLabel
        style={{
          marginBottom: 15,
          display: "flex",
          flexDirection: "row",
          gap: 3,
        }}
        control={<Checkbox style={{ color: "#E77228", marginLeft: 9 }} />}
        label="Apply Rebate/Discount"
        value={watch("applyRebateDiscount")}
        onChange={(e: any) => {
          setValue("applyRebateDiscount", e.target.checked);
        }}
      />
      {watchApplyFactoring ? (
        <div className="row d-flex  align-items-center ">
          <div className="col-6">
            <ControlledComboBox
              className={classes.customControlledField}
              control={control}
              name="rebateDiscount"
              error={!!errors?.rebateDiscount}
              helperText={errors?.rebateDiscount?.message}
              options={[
                 "Amount" ,
                 "Percentage" ,
              ]}
              textLabel={"Rebate/Discount Option"}
          
            />
          </div>
          <div className="col-6">
            <ControlledTextField
              error={!!errors?.description}
              helperText={errors?.description?.message}
              textLabel="Rebate/Discount Amount"
       
              name="rebateDiscountAmount"
              control={control}
              type="number"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RebateDiscount;
