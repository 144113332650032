export const TRADING_YEARS = [
  {
    title: "<1 year",
    value: {
      from: "0",
      to: "1",
    },
  },
  {
    title: "2-3 years",
    value: {
      from: "2",
      to: "3",
    },
  },
  {
    title: "4-5 years",
    value: {
      from: "4",
      to: "5",
    },
  },
  {
    title: "6-9 years",
    value: {
      from: "6",
      to: "9",
    },
  },
  {
    title: "10+ years",
    value: {
      from: "10",
      to: "19",
    },
  },
  {
    title: "20+ years",
    value: {
      from: "20",
      to: "29",
    },
  },
  {
    title: "30+ years",
    value: {
      from: "30",
      to: "39",
    },
  },
  {
    title: "40+ years",
    value: {
      from: "40",
      to: "49",
    },
  },
  {
    title: "50+ years",
    value: {
      from: "50",
      to: "59",
    },
  },
  {
    title: "60+ years",
    value: {
      from: "60",
      to: "69",
    },
  },
  {
    title: "70+ years",
    value: {
      from: "70",
      to: "79",
    },
  },
  {
    title: "80+ years",
    value: {
      from: "80",
      to: "89",
    },
  },
  {
    title: "90+ years",
    value: {
      from: "90",
      to: "99",
    },
  },
  {
    title: "100+ years",
    value: {
      from: "100",
      to: "999",
    },
  },
];

export const TRADING_YEARS_FOR_INPUT = [
  "<1 year",

  "2-3 years",

  "4-5 years",

  "6-9 years",

  "10+ years",

  "20+ years",

  "30+ years",

  "40+ years",

  "50+ years",

  "60+ years",

  "70+ years",

  "80+ years",

  "90+ years",

  "100+ years",
];
