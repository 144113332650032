import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getNotificationSettings = async () => {
  try {
    const response = await axiosApiInstance.get("/admin/system-management");
    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const updateNotificationSettings = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      "admin/system-management/updateOptions",
      { ...data }
    );
    if (response) {
      toast.success("Status is updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const updateRepresentetiveData = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      "admin/authfuncs/update-email-signature",
      { ...data }
    );
    if (response) {
      toast.success("Data is updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};


