import { Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { PhoneNumberInputType } from "./interfaces/ComponentInterfaces";
import { makeStyles } from "@mui/styles";
import { useAppSelector } from "../hooks/hooks";
import { CountriesStateType } from "../slices/authSlice";

const useStyles = makeStyles({
  PhoneInput: {
    background: "#F7F7F7",
    fontSize: 20,
    borderRadius: "10px !important",
    width: "100%",
    height: 50,
  
  },
  countryError: {
    background: "#F7F7F7",
    fontSize: 20,
    marginBottom: 20,
    border: "none",
    borderRadius: "20px !important",
    width: "100%",
    height: 50,
  },
  country: {
    background: "#F7F7F7",
    fontSize: 20,
    border: "none",
    borderRadius: "20px !important",
    width: "100%",
    height: 50,
  },
  label: {
    fontWeight: 500,
    position: "relative",
    marginLeft: 10,
  },

});

const PhoneNumberInput: React.FC<PhoneNumberInputType> = ({
  disabled,
  error,
  helperText,
  className,
  control,
  name,
  selectedCountry,
  setSelectedCountry,
}) => {
  const classes = useStyles();

  const countries = useAppSelector((state) => state.auth.countries);

  const onChange = (e: string) => {
    setSelectedCountry(e);
  };

  return (
    <>
      <InputLabel className={classes.label}>Phone Number</InputLabel>
      <Grid gap={1} container >
        <Grid item xs={5}>
          <Select
            sx={{
              "& fieldset": { border: "none" },
            }}
            className={error ? classes.countryError : classes.country}
            disabled={disabled}
            disableUnderline
            value={selectedCountry}
            onChange={(e: SelectChangeEvent<string>) =>
              onChange(e.target.value)
            }
              renderValue={(value:any) => (
                <>{`+${value}`}</>
              )}
          >
            {countries?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.phone}>
                  {item.countryName}(+{item.phone})
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  error={error}
                  helperText={helperText}
                  {...field}
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  InputLabelProps={{
                    style: { color: "#000000" },
                    shrink: true,
                  }}
                  InputProps={{
                    className: className ? className : classes.PhoneInput,
                    disableUnderline: true,
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PhoneNumberInput;
