import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Divider } from "@mui/material";
import ControlledComboBox from "../../../components/ControlledComboBox";
import ControlledTextField from "../../../components/ControlledTextField";
import { BuyersSellersStateType } from "./interfaces";
import { Currency } from "./interfaces";
import { getFinmidLimit } from "../../../services/surplus-allocation";
import { InvoiceSliceState } from "../../../slices/invoiceSlice";

const useStyles = makeStyles((theme) => ({
  hr: {
    opacity: 0.4,
  },
  mainText: {
    fontSize: 18,
  },
  Input: {
    borderRadius: "10px!important",
    width: "100%",
    height: 45,
    padding: 15,
  },
  checkLimitButton: {
    border: "none",
    borderRadius: 10,
    width: "100%",
    background: "#E77228",
    color: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    height: "40px",
    marginTop: 12,
    "&:disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },

  checkSuccess: {
    color: "#69CDAE",
  },
  checkError: {
    color: "red",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
}));
const AllocationCompany = ({
  aiData,
  sellerCompany,
  currencySymbol,
  setFinmidLimit,
  finmidLimit,
  control,
  setValue,
  watch,
  setIsApplyFactoring,
  errors,
  setAllocationBuyers,
  allocationBuyers,
  currencies,
  setBuyersLoading,
}: {
  aiData: InvoiceSliceState;
  control: any;
  reset: any;
  setValue: any;
  errors: any;
  watch: any;
  sellerCompany: any;
  currencySymbol: string;
  finmidLimit: number;
  setFinmidLimit: React.Dispatch<React.SetStateAction<number>>;
  setBuyersLoading: React.Dispatch<React.SetStateAction<boolean>>;
  currencies: Currency[];
  setIsApplyFactoring: React.Dispatch<React.SetStateAction<boolean>>;
  allocationBuyers: { name: string; id: string }[];
  setAllocationBuyers: React.Dispatch<
    React.SetStateAction<{ name: string; id: string }[]>
  >;
}) => {
  const classes = useStyles();

  const selectionCurrencies = currencies.map((item) => ({
    id: item.name,
    name: item.code,
  }));

  const defaultCurrency = selectionCurrencies.find(
    (curr) => curr?.name === "GBP"
  );

  const watchBuyer = watch("buyerCompany");
  const watchCurrency = watch("creditLimitCurrency");
  const watchCreditLimit = watch("creditLimitAmount");
  const [checkMessage, setCheckMessage] = useState("");
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(false);

  // const connectionBuyers = allocationBuyers?.filter((item) =>
  //   connections?.find(
  //     (conn) => conn?.user?.companyId === item?.id && conn?.status === 3
  //   )
  // );

  // const defaultCurrency = currencies.find((curr) => curr?.code === "GBP");

  useEffect(() => {
    setIsApplyFactoring(false);
    setFinmidLimit(0);
    setCheckMessage("");
    setValue("isBuyerChecked", false);
    setValue("applyInvoiceFactoring", false);
  }, [
    watchBuyer?.id,
    watchCurrency?.name,
    watchCreditLimit,
    sellerCompany?.id,
  ]);

  useEffect(() => {
    if (defaultCurrency?.id && !aiData.processedDocumentId) {
      setValue("creditLimitCurrency", defaultCurrency);
    }
  }, [defaultCurrency?.id, aiData.processedDocumentId]);

  // useEffect(() => {
  //   const getBuyerConnections = async () => {
  //     setConnectionsLoading(true);
  //     const response = await getConnections();
  //     setConnectionsLoading(false);

  //     if (response) {
  //       setConnections(response?.items);
  //     }
  //   };
  //   getBuyerConnections();
  // }, []);

  const onCheckLimit = async () => {
    setLoading(true);
    const data = {
      companyId: watchBuyer?.id,
      currency: watchCurrency?.name,
    };
    const limit = await getFinmidLimit(data);

    setLoading(false);
    if (limit) {
      setFinmidLimit(limit?.estimated_remaining_limit);
      if (limit?.estimated_remaining_limit < watchCreditLimit) {
        setCheckMessage(
          `${watchBuyer?.name} do not have sufficient funds for ${currencySymbol}${watchCreditLimit} order`
        );
        setValue("isBuyerChecked", false);
      } else {
        setCheckMessage(
          `${watchBuyer?.name} is eligible for ${currencySymbol}${watchCreditLimit} order`
        );
        setValue("isBuyerChecked", true);
      }
    } else {
      setFinmidLimit(0);
      setValue("isBuyerChecked", false);
      setCheckMessage("Something went wrong, please try again.");
    }
  };

  return (
    <div>
      <span className={classes.mainText}>Allocation Company</span>
      <Divider className={classes.hr} />
      {defaultCurrency?.id ? (
        <div className="row d-flex pt-3   align-items-center">
          <div className="col-3">
            <ControlledComboBox
              className={classes.comboBox}
              control={control}
              name="buyerCompany"
              error={!!errors?.buyerCompany}
              helperText={errors?.buyerCompany?.message}
              options={allocationBuyers}
              textLabel={"Buyer Company"}
            />
          </div>
          <div className="col-2">
            <ControlledComboBox
              defaultValue={defaultCurrency}
              className={classes.comboBox}
              control={control}
              name="creditLimitCurrency"
              error={!!errors?.creditLimitCurrency}
              helperText={errors?.creditLimitCurrency?.message}
              options={selectionCurrencies}
              textLabel={"Currency"}
            />
          </div>
          <div style={{ minWidth: "150px" }} className="col-3">
            <ControlledTextField
              error={!!errors?.creditLimitAmount}
              helperText={errors?.creditLimitAmount?.message}
              // inputProps={{ min: 0, style: { textAlign: "end" } }}
              textLabel="Credit Limit Amount"
              name="creditLimitAmount"
              control={control}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onCheckLimit();
                }
              }}
            />
          </div>

          <div className="col-1">
            <button
              onClick={onCheckLimit}
              disabled={
                !watchBuyer || !watchCreditLimit || !watchCurrency || loading
              }
              className={classes.checkLimitButton}
            >
              {loading ? "Limit is checking..." : " Check"}
            </button>
          </div>
          <div className="col-3">
            <div
              className={
                checkMessage.includes("eligible")
                  ? classes.checkSuccess
                  : classes.checkError
              }
            >
              {checkMessage}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AllocationCompany;
