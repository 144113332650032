import React, { useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { makeStyles } from "@mui/styles";
import { PasswordField } from "../../components/PasswordTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { updatePassword } from "../../services/auth";
import LoadingIndicator from "../../components/LoadingIndicator";
const useStyles = makeStyles({
  settingsCard: {
    width: "100%",
    background: "white",
    borderRadius: "20px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 20,
  },
});

export const PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;


interface schemaType {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required("Old Password is required"),
  newPassword:yup.string()
  .matches(
    PASSWORD_REGEX,
    '*Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
  )
  .required('New password is required'),
  confirmPassword: yup
  .string()
  .oneOf(
    [yup.ref('newPassword')],'*New password and Confirm password must be match')
  .matches(
    PASSWORD_REGEX,
    '*Confirm Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
  )
  .required('Confirm password is required'),
});
export const ChangePasswordContainer: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<schemaType>({
    resolver: yupResolver(validationSchema),
  });

  const onUpdatePassword = async (data: schemaType) => {
    setLoading(true);
    const response = await updatePassword(data.oldPassword, data.newPassword);
    setLoading(false);
  };

  const classes = useStyles();
  return (
    <>
      {loading && <LoadingIndicator loading />}
      <div className={classes.settingsCard}>
        <div className=" p-3">
          <div className="row g-3">
            <div className="col-md-4">
              <PasswordField
                  error={!!errors.oldPassword}
                  helperText={errors.oldPassword?.message}
                textLabel="Password"
                control={control}
                name="oldPassword"
              />
            </div>
            <div className="col-md-4">
              <PasswordField
              error={!!errors.newPassword}
              helperText={errors.newPassword?.message}
                textLabel="New Password"
                control={control}
                name="newPassword"
              />
            </div>
            <div className="col-md-4">
              <PasswordField
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                textLabel="Confirm Password"
                control={control}
                name="confirmPassword"
              />
            </div>
          </div>
        </div>

        <div className={classes.buttonBox}>
          <AdminButtonComponent
            title="Save"
            onClick={handleSubmit(onUpdatePassword)}
          />
        </div>
      </div>
    </>
  );
};
