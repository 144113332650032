import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import { makeStyles } from "@mui/styles";
import { getUpcomingDueInvoices } from "../../services/upcoming-due-invoices";
import { UpcomingDueInvoiceState } from "./interface";
import { Chip, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import moment from "moment";
import NumberFormatComponent from "../../components/NumberFormatComponent";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  companyText: {
    color: "#e77228",
    cursor: "pointer",
    
  },
});

const columns = [
  { name: "Code", id: "code" },
  { name: "Company", id: "company" },
  { name: "Date", id: "date" },
  { name: "Final Amount", id: "finalAmount" },
  { name: "Status", id: "status" },
  { name: "", id: "action" },
];
const UpcomingDueInvoicesContainer = () => {
  const classes = useStyles();
  const { hasPermission } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<UpcomingDueInvoiceState[]>([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  useEffect(() => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;
    const getData = async () => {
      setLoading(true);
      const response = await getUpcomingDueInvoices(queryParams.page);
      setLoading(false);

      if (response?.data) {
        setInvoices(response?.data);
        setQueryParams({
          page: response?.pagination?.page,
          total: response?.pagination?.total,
          totalPage: response?.pagination?.totalPage,
          limit: 20,
        });
      }
    };
    getData();
  }, [queryParams.page, hasPermission("ORDERS_ORDER_DETAILS_EDIT")]);

  const renderTableBody = () => {
    return (
      <>
        {invoices.map((invoice, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <span>
                  <span className="fw-bold">Payment Code :</span>{" "}
                  {invoice.paymentCode}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Offer Code :</span>{" "}
                  {invoice.offerCode}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Invoice Code :</span> {invoice.code}
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                <div>
                  <span className="fw-bold">Buyer :</span>{" "}
                  <a
                    className={classes.companyText}
                    target="_blank"
                    href={`/company-list/${invoice.buyerCompany._id}`}
                  >
                    {invoice.buyerCompany.companyName}
                  </a>
                </div>

                <span>
                  <span className="fw-bold">Seller :</span>{" "}
                  <a
                    className={classes.companyText}
                    target="_blank"
                    href={`/company-list/${invoice.sellerCompany._id}`}
                  >
                    {invoice.sellerCompany.companyName}
                  </a>
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                <span>
                  <span className="fw-bold">Create Date :</span>{" "}
                  {moment(invoice.createdAt).format("DD/MM/YYYY")}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Offer/Delivery Date :</span>{" "}
                  {moment(invoice.deliveryDate).format("DD/MM/YYYY")}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Invoice Date :</span>{" "}
                  {moment(invoice.invoiceDate).format("DD/MM/YYYY")}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Due Date :</span>{" "}
                  {moment(invoice.dueDate).format("DD/MM/YYYY")}
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                {invoice?.produces?.[0]?.currency.symbol}
                <NumberFormatComponent value={invoice.grandTotalAmount} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Chip label={invoice.status} />
              </TableCell>
              <TableCell component="th" scope="row">
                <a target="_blank" href={invoice.pdfPath}>
                  <Tooltip title="See details of invoice">
                    <IconButton>
                      <i className="ri-eye-line"></i>
                    </IconButton>
                  </Tooltip>
                </a>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };
  return (
    <Sidebar pageTitle="Upcoming Due Invoices">
       <Helmet>
          <title>Upcoming Due Invoices | FruPro Admin Portal</title>
        </Helmet>
      <PermissionWrapper
        unauthorizedComponent
        permission="ORDERS_ORDER_DETAILS_EDIT"
      >
        {" "}
        <TableComponent
          loading={loading}
          showRightButton={false}
          TableBodyComponent={renderTableBody}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          columns={columns}
        />
      </PermissionWrapper>
    </Sidebar>
  );
};

export default UpcomingDueInvoicesContainer;
