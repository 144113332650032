import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Title,
} from "@tremor/react";
import { DashboardState } from "../../interface";
import NumberFormatComponent from "../../../../components/NumberFormatComponent";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { isDarkReaderActive } from "../../../../services/common";

const useStyles = makeStyles({
  darkModeTextColor: {
    fontWeight: 600,
    color: "white",
  },
  seeAllCompsText: {
    cursor: "pointer",
    fontSize: 16,
    textDecoration: "underline",
  },
  darkModeHeaderTextColor: {
    fontWeight: 600,
    color: "white!important",
  },
  darkModeSeeAllTextColor: {
    cursor: "pointer",
    fontWeight: 600,
    textDecoration: "underline",
    color: "white!important",
  },
});

const OverdueInvoicesContainer = ({
  dashboardData,
  isDarkMode,
}: {
  isDarkMode: boolean;
  dashboardData?: DashboardState;
}) => {
  const classes = useStyles();
  return (
    <Card decoration="bottom" decorationColor="orange" className="mt-2 ">
      <div className="d-flex justify-content-between">
        <Title
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.darkModeHeaderTextColor
              : ""
          }
          color="orange"
        >
          Overdue Invoices
        </Title>
        <a href={`/surplus-allocation?status=DUE`} target="_blank">
          <Title
            className={
              isDarkMode || isDarkReaderActive()
                ? classes.darkModeSeeAllTextColor
                : classes.seeAllCompsText
            }
            color="orange"
          >
            See all
          </Title>
        </a>
      </div>
      <Table className="mt-2">
        <TableHead>
          <TableRow>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Seller
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Buyer
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Amount Due
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Invoice Date
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Due Date
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dashboardData?.dueInvoices.map((item, index) => (
            <TableRow key={index}>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {item.sellerCompany.companyName}
              </TableCell>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {item.buyerCompany.companyName}
              </TableCell>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {item.produces?.[0]?.currency?.symbol}{<NumberFormatComponent value={item.grandTotalAmount} />}
              </TableCell>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {moment(item.invoiceDate).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {moment(item.dueDate).format("DD/MM/YYYY")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default OverdueInvoicesContainer;
