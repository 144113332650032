import React from "react";
import { makeStyles } from "@mui/styles";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  notFoundMain: {
    display: "flex",
    width: "100%",
    height: "100vh",
    gap: 10,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  notFoundTextsBox: {
    transform: "translateY(50%)",
  },
  notFoundImage: {
    width: 700,
    height: 400,
  },
  pageNotFoundText: {
    color: "#6c757d",
    fontWeight: 600,
    fontSize: 50,
  },
  pageNotFoundDesc: {
    fontWeight: 500,
    fontSize: 25,
  },
});
const NotFoundPageContainer = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onGoHome = () => {
    navigate("/");
  };
  return (
    <div className={classes.notFoundMain}>
      <img className={classes.notFoundImage} src="/static/svg/4042.jpg" />
      <span className={classes.pageNotFoundText}>Page Not Found</span>
      <span className={classes.pageNotFoundDesc}>
        Try refining your search or use the navigation below to return the main
        page
      </span>
      <AdminButtonComponent title="Go to home page" onClick={onGoHome} />
    </div>
  );
};

export default NotFoundPageContainer;
