import axios from "axios";
import { toast } from "react-toastify";
import { axiosApiInstance } from "./axios-instance";

export const getEmails = async ({
  page,
  filters,
  order,
}: {
  page: number;
  filters?: any;
  order?: any;
}) => {
  try {
    let url = `/admin/authfuncs/email-queue?page=${page}&limit=20`;

    if (filters) {
      const encodedFilters = encodeURIComponent(JSON.stringify(filters));
      url += `&filters=${encodedFilters}`;
    }

    if (order) {
      const orderKey = order.name === "Ascending" ? "asc" : "desc";
      url += `&order=${orderKey}`;
    }

    const response = await axiosApiInstance.get(url);
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const resendEmail = async (id: string | string[]) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/authfuncs/email-queue/trigger",
      { email_queue_ids: Array.isArray(id) ? id : [id] }
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const deleteEmail = async (id: string) => {
  try {
    const response = await axiosApiInstance.put(
      "/admin/authfuncs/email-queue",
      { id }
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
