import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { Box, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Variety from "./components/Variety";
import Size from "./components/Size";
import Unit from "./components/Unit";
import {
  getSizes,
  getUnits,
  getVarieties,
} from "../../services/variety-size-unit";
import usePermissions from "../../hooks/usePermissions";
import PermissionWrapper from "../../components/PermissionWrapper";
const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#E77228",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#E77228",
    },
  },
});
const VarietySizeUnit = () => {
  const classes = useStyles();

  const { hasPermission } = usePermissions();

  const [value, setValue] = useState<string>("1");
  const [queryParamsVariety, setQueryParamsVariety] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });
  const [queryParamsSize, setQueryParamsSize] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });
  const [queryParamsUnit, setQueryParamsUnit] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });
  const [fetchLoading, setFetchLoading] = useState(false);
  const [isFetch, setIsFetch] = useState<number>(0);
  const [varieties, setVarieties] = useState<{ name: string; _id: string }[]>(
    []
  );
  const [sizes, setSizes] = useState<{ name: string; _id: string }[]>([]);
  const [units, setUnits] = useState<
    {
      subProductName: string;
      _id: string;
      subProductID: string;
      unitWeight: number;
      unitDescription: string;
    }[]
  >([]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  //variety

  useEffect(() => {
    if (value === "1" && hasPermission("TRADE_INFO")) {
      const getVarietyData = async () => {
        setFetchLoading(true);
        const response = await getVarieties(queryParamsVariety.page);
        setFetchLoading(false);

        if (response) {
          setQueryParamsVariety({
            page: response?.metaData?.page,
            total: response?.metaData?.totalItems,
            totalPage: response?.metaData?.totalPages,
            limit: 1,
          });
          setVarieties(response?.items?.reverse());
        }
      };
      getVarietyData();
    }
  }, [value, isFetch, hasPermission("TRADE_INFO"), queryParamsVariety.page]);

  //size

  useEffect(() => {
    if (value === "2" && hasPermission("TRADE_INFO")) {
      const getSizeData = async () => {
        setFetchLoading(true);
        const response = await getSizes(queryParamsSize.page);
        setFetchLoading(false);

        if (response) {
          setQueryParamsSize({
            page: response?.metaData?.page,
            total: response?.metaData?.totalItems,
            totalPage: response?.metaData?.totalPages,
            limit: 1,
          });
          setSizes(response?.items?.reverse());
        }
      };
      getSizeData();
    }
  }, [value, isFetch, hasPermission("TRADE_INFO"), queryParamsSize.page]);

  //unit

  useEffect(() => {
    if (value === "3" && hasPermission("TRADE_INFO")) {
      const getUnitData = async () => {
        setFetchLoading(true);
        const response = await getUnits(queryParamsUnit.page);
        setFetchLoading(false);

        if (response) {
          setQueryParamsUnit({
            page: response?.metaData?.page,
            total: response?.metaData?.totalItems,
            totalPage: response?.metaData?.totalPages,
            limit: 1,
          });
          setUnits(response?.items);
        }
      };
      getUnitData();
    }
  }, [value, isFetch, hasPermission("TRADE_INFO"), queryParamsUnit.page]);

  const renderTabs = () => {
    switch (value) {
      case "1":
        return (
          <>
            <PermissionWrapper unauthorizedComponent permission="TRADE_INFO">
              <Variety
                setIsFetch={setIsFetch}
                queryParams={queryParamsVariety}
                setQueryParams={setQueryParamsVariety}
                fetchLoading={fetchLoading}
                variety={varieties}
              />
            </PermissionWrapper>
          </>
        );
      case "2":
        return (
          <>
            <PermissionWrapper unauthorizedComponent permission="TRADE_INFO">
              <Size
                setIsFetch={setIsFetch}
                queryParams={queryParamsSize}
                setQueryParams={setQueryParamsSize}
                fetchLoading={fetchLoading}
                sizes={sizes}
              />
            </PermissionWrapper>
          </>
        );
      case "3":
        return (
          <>
            <PermissionWrapper unauthorizedComponent permission="TRADE_INFO">
              <Unit
                setIsFetch={setIsFetch}
                queryParams={queryParamsUnit}
                setQueryParams={setQueryParamsUnit}
                fetchLoading={fetchLoading}
                units={units}
              />
            </PermissionWrapper>
          </>
        );
    }
  };

  return (
    <Sidebar pageTitle="Variety,size,unit">
      <Box sx={{ width: "100%" }}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          <Tab value="1" label="Variety" />
          <Tab value="2" label="Size" />
          <Tab value="3" label="Unit" />
        </Tabs>
        <Box marginTop={2}>{renderTabs()}</Box>
      </Box>
    </Sidebar>
  );
};

export default VarietySizeUnit;
