import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const addNewNote = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(`admin/notes/create-note`,{...data});
    if (response) {
     toast.success("Note added")
     return response.data
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const deleteNote = async (noteId:string) => {
  try {
    const response = await axiosApiInstance.post(`/admin/notes/delete-note`,{noteId:noteId});
    if (response) {
     toast.success("Note deleted")
     return response.data
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
