import React, { useState, useEffect } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import { getBrands } from "../../services/brands";
import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoadingIndicator from "../../components/LoadingIndicator";
import SearchTextField from "../../components/SearchTextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet";
const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
});

interface paginationDataType {
  limit: number;
  total: number;
  totalPage: number;
  page: number;
}

interface brandsResponseDataType {
  brand: string;
  categories: string[];
  count: number;
  countries: {
    code: number;
    name: string;
  }[];
  subProductTypes: {
    _id: string;
    name: string;
  }[];
}

interface schemaType {
  keyword: string;
}

const searchSchema = yup.object().shape({
  keyword: yup.string(),
});

const columns = [
  { name: "Brand", id: "brand" },
  { name: "Product Type", id: "productType" },
  { name: "Product Category", id: "productCategory" },
  { name: "Country Of Origin", id: "countryOfOrigin" },
];

const BrandsContainer: React.FC = () => {
  const classes = useStyles();

  const { control, watch } = useForm({
    resolver: yupResolver(searchSchema),
    defaultValues: {
      keyword: "",
    },
  });

  const watchKeyword = watch("keyword");

  const [queryParams, setQueryParams] = useState<paginationDataType>({
    limit: 1,
    total: 1,
    totalPage: 1,
    page: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [brands, setBrands] = useState<brandsResponseDataType[]>([]);

  useEffect(() => {
    const getAllBrands = async () => {
      setLoading(true);
      const response = await getBrands(queryParams.page);
      setTimeout(async () => {
        setLoading(false);

        if (response) {
          setBrands(response.items);
          setQueryParams({
            page: response.metaData.page,
            limit: response.metaData.limit,
            total: response.metaData.total,
            totalPage: response.metaData.totalPage,
          });
        }
      }, 1000);
    };
    getAllBrands();
  }, [queryParams.page]);

  const renderBrandsTableBody = () => {
    return (
      <>
        {brands.map((brand, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {brand.brand}
              </TableCell>
              <TableCell component="th" scope="row">
                {brand.subProductTypes.map((type) => type.name).join(",")}
              </TableCell>
              <TableCell>
                {brand.categories.map((category) => category).join(",")}
              </TableCell>
              <TableCell>
                {brand.countries.map((country) => country.name).join(",")}
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  //search render
  const onSearch = async () => {
    setLoading(true);
    const response = await getBrands(queryParams.page, watchKeyword);
    setTimeout(async () => {
      setLoading(false);

      if (response) {
        setBrands(response.items);
        setQueryParams({
          page: response.metaData.page,
          limit: response.metaData.limit,
          total: response.metaData.total,
          totalPage: response.metaData.totalPage,
        });
      }
    }, 1000);
  };

  const renderSearchField = () => {
    return (
      <SearchTextField
        onSearchClick={onSearch}
        control={control}
        name="keyword"
      />
    );
  };
  return (
    <>
      <Helmet>
        <title>Brands | FruPro Admin Portal</title>
      </Helmet>
      <Sidebar pageTitle="Brands">
        {loading && <LoadingIndicator loading />}
        <TableComponent
          RenderSearchTextField={renderSearchField()}
          TableBodyComponent={renderBrandsTableBody}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          columns={columns}
          showRightButton={false}
        />
      </Sidebar>
    </>
  );
};

export default BrandsContainer;
