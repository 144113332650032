import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface InvoiceSliceState {
 processedDocumentId:string
  account: string;
  BIC: string;
  IBAN: string;
  bankName: string;
  accountName: string;
  dueDate: string;
  grandTotal: string;
  totalAmount: string;
  phoneNumberSeller: string;
  invoiceEmailSeller: string;
  companyAdminSeller: string;
  costs: any[];
  produces: Produce[];
  currency: Currency;
  companyNameSeller: CompanyNameSeller;
  phoneNumberBuyer: string;
  invoiceEmailBuyer: string;
  companyAdminBuyer: string;
  companyNameBuyer: CompanyNameBuyer;
  locationBuyer: string;
  locationSeller: string;
  vatSeller: string;
  vatBuyer: string;
  refSeller: string;
  refBuyer: string;
  deliveryDate: string;
  invoiceDate: string;
}

export interface Produce {
  supplier:string
  unit: Unit;
  name: string;
  id: string;
  quantity: string;
  price: string;
  amount: string;
  subProductType: SubProductType;
}

export interface Unit {
  id: string;
  name: string;
}

export interface SubProductType {
  id: string;
  name: string;
}

export interface Currency {
  name: string;
  id: string;
}

export interface CompanyNameSeller {
  name: string;
  id: string;
}

export interface CompanyNameBuyer {
  name: string;
  id: string;
}

const initialState = {
  aiInvoiceData: <InvoiceSliceState>{},
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    setAIInvoice: (state, action: PayloadAction<InvoiceSliceState>) => {
      state.aiInvoiceData = action.payload;
    },
  },
});

export default invoiceSlice.reducer;

export const { setAIInvoice } = invoiceSlice.actions;
