import React from "react";
import { Modal } from "react-bootstrap";
import { ModalComponentTypes } from "./interfaces/ComponentInterfaces";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  modalStyleInventory: {
    marginTop: 80,
    borderRadius: "30px",
    border: "1px solid #E77228",
  },
  modalStyle: {
    marginTop: 80,
    borderRadius: "30px",
    border: "1px solid #E77228",
  },
});
const AdminModalComponent: React.FC<ModalComponentTypes> = ({
  openModal,
  isInventoryPageModal,
  closeModal,
  headerTitle,
  children,
  size,
}) => {
  const classes = useStyles();
  return (
    //@ts-ignore
    <Modal
      style={{
        marginLeft: location.pathname === "/surplus-allocation" ? "60px" : 0,
      }}
      scrollable
      show={openModal}
      contentClassName={
        isInventoryPageModal ? classes.modalStyleInventory : classes.modalStyle
      }
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={closeModal}
    >
      <Modal.Header>
        {
          //@ts-ignore
          <Modal.Title id="contained-modal-title-vcenter">
            {headerTitle}
          </Modal.Title>
        }
      </Modal.Header>
      {
        //@ts-ignore
        <Modal.Body>{children}</Modal.Body>
      }
    </Modal>
  );
};

export default AdminModalComponent;
