import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";
import {
  AttachmentStateType,
  DocumentStateType,
  SchemaType,
  VideosStateType,
} from "../pages/CompanyList/CompanyDetails/CompanyDetailsContainer";
import { TRADING_YEARS } from "../pages/CompanyList/CompanyDetails/constants";

export const getAllCompanies = async (page: number, keyword: string) => {
  try {
    const response = await axiosApiInstance.get(
      `/companies?includes=user&page=${page}&limit=10&keyword=${
        keyword ? keyword : ""
      }`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const getCompanyById = async (id: string) => {
  try {
    const response = await axiosApiInstance.get(`/companies/${id}`);

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addNewCompany = async (data: any) => {
  try {
    const response = await axiosApiInstance.post("/auth/add-user-company", {
      ...data,
    });

    if (response) {
      toast.success("Company is created successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const updateCompanyInfo = async (
  data: SchemaType,
  id: string | undefined,
  attachment: AttachmentStateType[],
  videos: VideosStateType[],
  documents: DocumentStateType[],
  confirmAllocation: boolean,
  isSelfBilling: boolean,
  selectedCountry: string,
  canAllocateSurplus: boolean,
  displayCertificates: boolean,
  displayGrowerInfoOnInvoice: boolean,
  displaySupplierInfoOnInvoice: boolean
) => {
  try {
    const {
      linkedin,
      twitter,
      invoiceEmail,
      companyForm,
      invoiceFullName,
      invoicePhoneNumber,
      instagram,
      tradingYears,
      companyWebsite,
      facebook,
      status,
      nameOfAcc,
      CRN,
      accNumber,
      nameOfBank,
      VAT,
      IBAN,
      principalAddress,
      companyTradingType,
      principalCity,
      principalCountry,
      principalPostCode,
      principalState,
      companyCode,
      registerAddress,
      registerCity,
      arrReportDue,
      registerCountry,
      registerPostCode,
      BIC,
      registerState,
      description,
      companyType,
      recPeriod,
      companyName,
      sellerInterests,
      buyerInterests,
      specialities,
      verifyDate,
      reqBy,
      reqDate,
      reqEmail,
      ...rest
    } = data;

    const selectedTradingYear = TRADING_YEARS.find(
      (item) => item.title === tradingYears
    );

    const newData = {
      socialMedia: {
        linkedin: linkedin,
        facebook: facebook,
        twitter: twitter,
        instagram: instagram,

        companyWebsite: companyWebsite,
      },

      tradingYears: {
        from: selectedTradingYear?.value.from || 0,
        to: selectedTradingYear?.value.to || 0,
        display: selectedTradingYear?.title || undefined,
      },
      invoiceContactInfo: {
        
       seller:{
        fullName: invoiceFullName,
        email: invoiceEmail,
        phoneNumber:
          invoicePhoneNumber !== "" && selectedCountry
            ? `+${selectedCountry}${invoicePhoneNumber}`
            : "",
       },
       buyer:{
        fullName: invoiceFullName,
        email: invoiceEmail,
        phoneNumber:
          invoicePhoneNumber !== "" && selectedCountry
            ? `+${selectedCountry}${invoicePhoneNumber}`
            : "",
       }
      },
      displayCertificates,
      displayGrowerInfoOnInvoice,
      displaySupplierInfoOnInvoice,
      canAllocateSurplus: canAllocateSurplus,
      preconfirmAllocations: confirmAllocation,
      selfBillingPermission: isSelfBilling,
      accountNumber: accNumber,
      companyForm: companyForm ? companyForm : undefined,
      arrivalDueDay: arrReportDue,
      arrivalDueHour: recPeriod,
      VATNumber: VAT,
      companyHouseRegistration: CRN,
      ibanNumber: IBAN,
      swiftCode: BIC,
      description: description ? description : undefined,
      tradingType: companyTradingType ? companyTradingType : undefined,
      principalPlaceAddress: principalAddress,
      principalPlaceCountryCode: principalCountry.id.toString(),
      principalPlaceCountryName: principalCountry.name.toString(),
      principalPlaceStateName: principalState,
      principalPlaceStateCode: principalState,
      videos:
        videos.map((item) => ({
          videoUrl: item.videoUrl,
        })) || [],
      documents:
        documents.map((item) => ({
          name: item.name,
          size: item.size,
          path: item.path,
        })) || [],
      principalPlaceCityName: principalCity,
      principalPlaceCityCode: principalCity,
      principalPlacePostCode: principalPostCode,
      registerCountryCode: registerCountry.id.toString(),
      registerCountryName: registerCountry.name.toString(),
      registerStateName: registerState,
      registerStateCode: registerState,
      registerCityName: registerCity,
      registerCityCode: registerCity,
      registerPostCode: registerPostCode,
      registerAddress: registerAddress,
      status: status === "Full Trading Account" ? "VERIFIED" : "APPROVED",
      bankAccountName: nameOfAcc,
      bankName: nameOfBank,
      attachment: attachment.map((att) => ({
        name: att.name,
        size: att.size,
        path: att.path,
      })),
      sellerInterestIds: sellerInterests.map((item) => item?._id),
      buyerInterestIds: buyerInterests.map((item) => item?._id),
      specialityIds: specialities.map((item) => item?._id),
      typeIds: companyType.map((item) => item?._id),
      ...rest,
    };

    const response = await axiosApiInstance.put(
      `companies/admin-update/${id}`,
      { ...newData }
    );

    if (response) {
      toast.success("Company Information is updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getAllEmployees = async (
  companyId: string,
  page: number,
  keyword: string
) => {
  try {
    const response = await axiosApiInstance.get(
      `/companies/${companyId}/employees?page=${page}&limit=10&keyword=${
        keyword ? keyword : ""
      }`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const updateCompanyLocation = async (
  companyId: string,
  lat: string,
  long: string
) => {
  try {
    const response = await axiosApiInstance.put(
      `/companies/${companyId}/locations`,
      {
        lat: lat,
        long: long,
      }
    );

    if (response) {
      toast.success("Location is updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getCompanyImages = async (page: number, id: string) => {
  try {
    const response = await axiosApiInstance.post(
      `company-images/admin/get-company-images`,
      {
        companyId: id,
        page: page,
        limit: 20,
      }
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addCompanyImage = async (data: any, companyId: string) => {
  try {
    const response = await axiosApiInstance.post(
      `/company-images/admin/image-upload/${companyId}`,
      data,
      {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }
    );
    if (response) {
      toast.success("Image added successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const deleteCompanyImages = async (
  companyId: string,
  imageId: string
) => {
  try {
    const response = await axiosApiInstance.post(
      `company-images/admin/remove-company-image`,
      {
        companyId,
        imageId,
      }
    );

    if (response) {
      toast.success("Image is deleted successfully");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getCompanyLimits = async (page: number, companyIds: string[]) => {
  try {
    const response = await axiosApiInstance.post("/companies/company-limits", {
      page: page,
      companyIds,
    });
    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const uploadDocument = async (data: any, companyId: string) => {
  try {
    const response = await axiosApiInstance.post(
      `/companies/admin-update/${companyId}/documents/add`,
      { ...data }
    );
    if (response) {
      toast.success("Document uploaded successfully");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const deleteDocument = async (docId: string, companyId: string) => {
  try {
    const response = await axiosApiInstance.post(
      `/companies/admin-update/${companyId}/documents/remove`,
      { documentId: docId }
    );
    if (response) {
      toast.success("Document deleted successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addCompanyAddress = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      "/address/company-addresses/create",
      { ...data }
    );
    if (response) {
      toast.success("Address added successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const editCompanyAddress = async (data: any) => {
  try {
    const response = await axiosApiInstance.put(
      "/address/company-addresses/update",
      { ...data }
    );
    if (response) {
      toast.success("Address edited successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getCompanyAddress = async (companyId: string) => {
  try {
    const response = await axiosApiInstance.get(
      `/address/company-addresses/${companyId}`
    );
    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addCompanyCertificate = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/company-certifications/admin/upload-certification-document`,
      data,
      {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }
    );
    if (response) {
      toast.success("Certificate added successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const updateCompanyLogoCover = async (data: any) => {
  try {
    const { companyId, ...rest } = data;
    const response = await axiosApiInstance.put(
      `/companies/admin-update/${data?.companyId}/image-change`,
      { ...rest }
    );
    if (response) {
      toast.success("Update success");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addCompanyVideos = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(`/admin/add-company-videos`, {
      ...data,
    });
    if (response) {
      toast.success("Video added successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const removeCompanyVideos = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/admin/remove-company-videos`,
      { ...data }
    );
    if (response) {
      toast.success("Video deleted successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getCompanyEmailPreferences = async (page: number, id: string) => {
  try {
    const response = await axiosApiInstance.post(
      `/admin/get-company-email-subscription-preferences`,
      {
        page: page,
        companyId: id,
        limit: 50,
      }
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addCompanyEmailPreferences = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/admin/add-company-email-subscription-preference`,
      {
        ...data,
      }
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const removeCompanyEmailPreferences = async (id: string) => {
  try {
    const response = await axiosApiInstance.post(
      `admin/remove-company-email-subscription-preference`,
      {
        emailSubscriptionPreferenceId: id,
      }
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const exportCompanies = async (keyword: string) => {
  try {
    const response = await axiosApiInstance.get(
      `/companies?includes=user&page=0&getImageUrl=false&keyword=${keyword}&export=true`
    );
    if (response) {
      toast.success("Companies exported");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const updateCompanyNotifications = async ({
  companyId,
  appNotification,
  emailNotification,
}: {
  companyId: string;
  appNotification: boolean;
  emailNotification: boolean;
}) => {
  try {
    const response = await axiosApiInstance.put(
      `/companies/admin-update/${companyId}/partial-update`,
      {
        notification: {
          appNotification,
          emailNotification,
        },
      }
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
