import React, { useState, useEffect } from "react";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Sidebar } from "../../components/Sidebar";
import { makeStyles } from "@mui/styles";
import TableComponent from "../../components/TableComponent";
import RegistrationDetailsModal from "./RegistrationDetailsModal";
import { ComRegStateType, CompanyRegDetailsStateType } from "./interfaces";
import { getCompanyRequests } from "../../services/company-registration";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import SearchTextField from "../../components/SearchTextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AdminModalComponent from "../../components/AdminModalComponent";
import ApproveReqTexts from "./ApproveReqTexts";
import ControlledComboBox from "../../components/ControlledComboBox";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import {
  approveRequest,
  verifyRequest,
  rejectRequest,
  getCompanyRequestById,
} from "../../services/company-registration";
import ControlledTextField from "../../components/ControlledTextField";
import { Helmet } from "react-helmet";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";
const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  statusInput: {
    fontSize: 20,
    borderRadius: 10,
    border: "none !important",
    height: 50,

    "& .MuiFormHelperText-root.Mui-error": {
      color: "red !important",
    },
    "& .MuiInputBase-root": {
      background: "#f8f8f8",
      minHeight: 50,
      borderColor: "black !important",
      border: "none !important",
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  textsBox: {
    marginTop: 20,
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});

interface QueryParamsType {
  page: number;
  limit: number;
  total: number;
  totalPage: number;
}
interface SchemaType {
  keyword: string;
  companyStatus: string;
  rejectionReason: string;
}

const columns = [
  { name: "Request No", id: "reqNo" },
  { name: "Company Name", id: "companyName" },
  { name: "Company Type", id: "companyType" },
  { name: "Registration House Number", id: "regHouseNum" },
  { name: "Vat Number", id: "vatNumber" },
  { name: "", id: "action" },
];

const schema = yup.object().shape({
  keyword: yup.string(),
  companyStatus: yup.string(),
  rejectionReason: yup.string(),
});

const CompanyRegistrationContainer: React.FC = () => {
  const classes = useStyles();

  const { control, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyStatus: "View Only",
      rejectionReason: "",
    },
  });

  const {hasPermission}=usePermissions()

  const keyword = watch("keyword");
  const companyStatus = watch("companyStatus");
  const rejectionReason = watch("rejectionReason");

  const [queryParams, setQueryParams] = useState<QueryParamsType>({
    limit: 1,
    total: 1,
    totalPage: 1,
    page: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [selectedRequestId, setSelectedRequestId] = useState<string>("");
  const [selectedCompanyDetails, setSelectedCompanyDetails] =
    useState<CompanyRegDetailsStateType>();
  const [refreshToken, setRefreshToken] = useState<number>(0);
  const [requests, setRequests] = useState<ComRegStateType[]>([]);
  const [openApproveRequestModal, setOpenApproveRequestModal] =
    useState<boolean>(false);
  const [openRejectRequestModal, setOpenRejectRequestModal] =
    useState<boolean>(false);

  const [openRegistrationDetailsModal, setOpenRegistrationDetailsModal] =
    useState<boolean>(false);

  useEffect(() => {
    if(!hasPermission("COMPANY_VIEW_DETAILS"))return
    const getRequests = async () => {
      setFetchLoading(true);
      const response = await getCompanyRequests(queryParams.page, "");
      setFetchLoading(false);

      if (response) {
        setRequests(response.items);
        setQueryParams({
          page: response.metaData.page,
          limit: response.metaData.limit,
          total: response.metaData.total,
          totalPage: response.metaData.totalPage,
        });
      }
    };
    getRequests();
  }, [queryParams.page, refreshToken,hasPermission("COMPANY_VIEW_DETAILS")]);

  useEffect(() => {
   if(selectedRequestId){
    const getDetails = async () => {
      setLoading(true);
      const response = await getCompanyRequestById(selectedRequestId);

      setLoading(false);
      if (response) {
        setSelectedCompanyDetails(response);
      }
    };
    getDetails();
   }
  }, [selectedRequestId]);

  const renderTableBody = () => {
    return (
      <>
      <Helmet>
          <title>Company Registration | FruPro Admin Portal</title>
      </Helmet>
        {requests.map((item, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item?.code}
              </TableCell>
              <TableCell component="th" scope="row">
                {item?.company?.companyName||"N/A"}
              </TableCell>
              <TableCell component="th" scope="row">
                {item?.company?.companyType || "N/A"}
              </TableCell>
              <TableCell component="th" scope="row">
                {item?.company?.companyHouseRegistration || "N/A"}
              </TableCell>
              <TableCell component="th" scope="row">
                {item?.company?.VATNumber || "N/A"}
              </TableCell>
              <TableCell component="th" scope="row">
                {item?.status === "PENDING" ? (
                  <>
                    <Tooltip title="See details">
                      <IconButton
                        onClick={() => {
                          setSelectedRequestId(item.id);
                          setOpenRegistrationDetailsModal(true);
                        }}
                      >
                        <i className="ri-eye-line"></i>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Approve">
                      <IconButton
                        onClick={() => {
                          setOpenApproveRequestModal(true);
                          setSelectedCompanyId(item.company._id);
                        }}
                      >
                        <i className="ri-checkbox-circle-line"></i>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reject">
                      <IconButton
                        onClick={() => {
                          setOpenRejectRequestModal(true);
                          setSelectedCompanyId(item.company._id);
                        }}
                      >
                        <i className="ri-close-circle-line"></i>
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title="See details">
                    <IconButton
                      onClick={() => {
                        setSelectedRequestId(item.id);
                        setOpenRegistrationDetailsModal(true);
                      }}
                    >
                      <i className="ri-eye-line"></i>
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const onSearch = async () => {
    
    setLoading(true);
    const response = await getCompanyRequests(queryParams.page, (keyword as any));
    setLoading(false);

    if (response) {
      setRequests(response.items);
      setQueryParams({
        page: response.metaData.page,
        limit: response.metaData.limit,
        total: response.metaData.total,
        totalPage: response.metaData.totalPage,
      });
    }
  };

  const renderSearchTextField = () => {
    return (
      <>
        {" "}
        <SearchTextField
          onSearchClick={onSearch}
          control={control}
          name="keyword"
        />
      </>
    );
  };

  //approve or verify

  const onApproveRequest = async (id: string) => {
    setLoading(true);

    await approveRequest(id);
    setLoading(false);
    setOpenApproveRequestModal(false);
    setRefreshToken(() => refreshToken + 1);
  };

  const onVerifyRequest = async (id: string) => {
    setLoading(true);

    await verifyRequest(id);
    setLoading(false);
    setOpenApproveRequestModal(false);
    setRefreshToken(() => refreshToken + 1);
  };

  const renderApproveRequestModalBody = () => {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ControlledComboBox
                className={classes.statusInput}
                control={control}
                textLabel="Company Status"
                name="companyStatus"
                options={["Full Trading", "View Only"]}
              />
            </div>

            <div className={classes.textsBox}>
              <ApproveReqTexts companyStatus={companyStatus||""} />
            </div>

            <div className={classes.newUserModalButtons}>
              <AdminButtonComponent
                className={classes.closeButton}
                onClick={() => setOpenApproveRequestModal(false)}
                title="Cancel"
              />{" "}
              <AdminButtonComponent
                className={classes.saveButton}
                onClick={() =>
                  companyStatus === "View Only"
                    ? onApproveRequest(selectedCompanyId)
                    : onVerifyRequest(selectedCompanyId)
                }
                title="Submit"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  //reject request

  const onRejectRequest = async () => {
    setLoading(true);
    await rejectRequest(selectedCompanyId, (rejectionReason as any));
    setLoading(false);
    setOpenRejectRequestModal(false);
    setRefreshToken(() => refreshToken + 1);
  };

  const renderRejectRequestModalBody = () => {
    return (
      <>
        <ControlledTextField
          control={control}
          name="reason"
          textLabel="Rejection Reason"
        />

        <div className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setOpenRejectRequestModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={() => onRejectRequest()}
            title="Submit"
          />
        </div>
      </>
    );
  };

  const renderRegistrationDetailsModalBody = () => {
    return (
      <RegistrationDetailsModal
        selectedCompanyDetails={selectedCompanyDetails}
      />
    );
  };

  return (
    <Sidebar pageTitle="Company Requests">
      {loading && <LoadingIndicator loading />}
    <PermissionWrapper unauthorizedComponent permission="COMPANY_VIEW_DETAILS">
    <TableComponent
    loading={fetchLoading}
        RenderSearchTextField={renderSearchTextField()}
        TableBodyComponent={renderTableBody}
        columns={columns}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        showRightButton={false}
      />
    </PermissionWrapper>
      <AdminModalComponent
        size="lg"
        headerTitle="Approve Request"
        children={renderApproveRequestModalBody()}
        openModal={openApproveRequestModal}
        closeModal={() => setOpenApproveRequestModal(false)}
      />
      <AdminModalComponent
        headerTitle="Reject Request"
        children={renderRejectRequestModalBody()}
        openModal={openRejectRequestModal}
        closeModal={() => setOpenRejectRequestModal(false)}
      />
      <AdminModalComponent
        size="lg"
        headerTitle=""
        children={renderRegistrationDetailsModalBody()}
        openModal={openRegistrationDetailsModal}
        closeModal={() => setOpenRegistrationDetailsModal(false)}
      />
    </Sidebar>
  );
};

export default CompanyRegistrationContainer;
