import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

interface addDonateEmailBodyType {
  address: string;
  long: string;
  lat: string;
  name: string;
}

export const getDonateCenter = async (page: number, keyword?: string) => {
  try {
    const response = await axiosApiInstance.get(
      `/donate-emails?page=${page}&keyword=${keyword ? keyword : ""}&limit=10`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const addDonateCenter = async (data: addDonateEmailBodyType) => {
  try {
    const response = await axiosApiInstance.post(`/donate-emails`, {
      ...data,
    });
    if (response) {
      toast.success("Donate center is created successfully");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const editDonateCenter = async (
  data: addDonateEmailBodyType,
  donateCenterId: string
) => {
  try {
    const response = await axiosApiInstance.put(
      `/donate-emails/${donateCenterId}`,
      {
        ...data,
      }
    );
    if (response) {
      toast.success("Donate center is updated successfully");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getDonateEmails = async (
  page: number,
  donateId?: string,

  keyword?: string
) => {
  try {
    const response = await axiosApiInstance.get(
      `/donate-emails/${donateId}/emails?page=${page}&limit=10&keyword=${
        keyword ? keyword : ""
      }`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addDonateEmails = async (email: string, donateId?: string) => {
  try {
    const emails = [`${email}`];

    const response = await axiosApiInstance.post(
      `/donate-emails/${donateId}/emails`,
      {
        emails,
      }
    );
    if (response) {
      toast.success("Donate Email is created successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const deleteDonateEmail = async (email: string, donateId?: string) => {
  try {
    const response = await axiosApiInstance.delete(
      `/donate-emails/${donateId}/emails`,
      {
        data: {
          email: email,
        },
      }
    );
    if (response) {
      toast.success("Donate Email is deleted successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
