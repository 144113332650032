import { useCallback, useEffect, useMemo, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import {
  getUserDetails,
  updateUserAvatar,
  updateUserDetails,
} from "../../services/user";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import { makeStyles } from "@mui/styles";
import { UserDetailState } from "./interface";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../components/ControlledTextField";
import ControlledComboBox from "../../components/ControlledComboBox";
import { useAppSelector } from "../../hooks/hooks";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { updateUserCover, uploadFile } from "../../services/common";
import { Helmet } from "react-helmet";
import PermissionWrapper from "../../components/PermissionWrapper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { sendResetPasswordEmail } from "../../services/auth";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup.string(),
  phoneNumber: yup.string(),
  about: yup.string(),
  jobTitle: yup.string(),
  twitter: yup.string().url("Must be a valid URL"),
  facebook: yup.string().url("Must be a valid URL"),
  instagram: yup.string().url("Must be a valid URL"),
  linkedin: yup.string().url("Must be a valid URL"),
  city: yup.string().required("City is required"),
  country: yup.object().required("Country is required"),
});

const useStyles = makeStyles({
  mainBox: {
    marginTop: 10,
    padding: 25,
    width: "100%",
    background: "white",
    borderRadius: "20px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  },
  inputBox: {
    marginTop: 100,
  },
  partHeader: {
    color: "#69CDAE",
    fontWeight: 600,
    fontSize: 18,
  },
  cover: {
    position: "relative",
    width: "100%",
    borderRadius: 20,
    height: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "flex-end",
  },
  photo: {
    border: "0.1px solid gray",
    cursor: "pointer",
    position: "absolute",
    bottom: -70,
    left: 20,
    display: "flex",
    justifyContent: "center",
    width: "150px",
    borderRadius: "100%",
    height: "75%",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  editTextBox: {
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.4)",
    color: "white",
    width: "60%",
    height: "30%",
    borderRadius: "100%",
    position: "absolute",
  },
  iconPen: {
    cursor: "pointer",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },

  resetPassButton: {
    minWidth: "275px!important",
    background: "transparent!important",
    color:"#E77228!important"
  },
  updateButton: {
    minWidth: "150px!important",
  },
});

const UserDetailsContainer = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const classes = useStyles();
  const { slug } = useParams();

  const countries = useAppSelector((state) => state.auth.countries);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [resetPassLoading, setRessPasswordLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [userData, setUserData] = useState<UserDetailState>();

  const defaultyCountryCode = useMemo(() => {
    return countries.find((i) => userData?.phoneNumber.includes(`+${i.phone}`));
  }, [countries, userData?.phoneNumber]);

  const newCountries = countries.map((item: any) => ({
    name: item.countryName,
    id: item.geonameId,
  }));
  const defaultCountry = newCountries.find((item: any) =>
    userData?.userLocation?.country.includes(item.name)
  );

  useEffect(() => {
    if (slug) {
      const getDetails = async () => {
        setFetchLoading(true);
        const response = await getUserDetails(slug);
        setFetchLoading(false);
        if (response) {
          setUserData(response);
        }
      };
      getDetails();
    }
  }, [slug, refresh]);

  useEffect(() => {
    if (userData?.userSlug && countries.length) {
      setSelectedCountry(defaultyCountryCode?.phone || "");
      reset({
        firstName: userData.firstName,
        lastName: userData.lastName,
        phoneNumber: userData.phoneNumber.split(
          `+${defaultyCountryCode?.phone}`
        )[1],
        email: userData.email,
        jobTitle: userData.jobTitle,
        about: userData.about,
        twitter: userData.socialMedia?.twitter,
        instagram: userData.socialMedia?.instagram,
        facebook: userData.socialMedia?.facebook,
        linkedin: userData.socialMedia?.linkedin,
        city: userData.userLocation?.city,
        country: defaultCountry,
      });
    }
  }, [userData?.userSlug, countries.length, refresh]);

  const onUpdateUser = async (data: any) => {
    setUpdateLoading(true);
    const finalData = {
      userId: userData?.id,
      email: data?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
      phoneNumber: `+${selectedCountry}${data?.phoneNumber}`,
      jobTitle: data?.jobTitle,
      about: data?.about,
      socialMedia: {
        twitter: data?.twitter,
        facebook: data?.facebook,
        linkedin: data?.linkedin,
        instagram: data?.instagram,
      },
      userLocation: {
        country: data?.country?.name,
        city: data?.city,
      },
    };

    await updateUserDetails(finalData);
    setUpdateLoading(false);
  };

  const onUpdateErrorUser = useCallback(() => {
    if (errors) {
      toast.error("There are some required fields missing,please check.");
    }
  },[errors]);

  const onUpdateAvatar = async (file: any) => {
    if (file) {
      setLoading(true);
      const data = await uploadFile(file, "company");

      const finalData = {
        photo: {
          ...data,
        },
        userId: userData?.id,
      };
      const uploadData = await updateUserAvatar(finalData);
      setLoading(false);
      if (uploadData) {
        setRefresh(() => refresh + 1);
      }
    }
  };

  const onUpdateCover = async (file: any) => {
    setLoading(true);
    let coverPhoto = await uploadFile(file, "user/coverPhotos");
    const finalData = {
      userId: userData?.id,
      coverPhoto: {
        ...coverPhoto,
      },
    };
    let result = await updateUserCover(finalData);
    setLoading(false);

    if (result) {
      setRefresh(() => refresh + 1);
    }
  };

  const onSendResetPasswordEmail = useCallback(async () => {
    const finalData = {
      email: userData?.email,
      type: "reset-password-email",
    };
    setRessPasswordLoading(true);
    await sendResetPasswordEmail(finalData);
    setRessPasswordLoading(false);
  }, [userData?.email]);
  return (
    <>
      <Helmet>
        <title>User Detail | FruPro Admin Portal</title>
      </Helmet>
      <Sidebar
        renderButton={
          <div className="d-flex flex-row gap-2">
            <PermissionWrapper permission="USER_EDIT_DETAILS_UPDATE">
              <AdminButtonComponent
                disabled={resetPassLoading || updateLoading||fetchLoading}
                loading={resetPassLoading}
                className={classes.resetPassButton}
                title="Send Reset Password Email"
                onClick={onSendResetPasswordEmail}
              />
            </PermissionWrapper>
            <PermissionWrapper permission="USER_EDIT_DETAILS_UPDATE">
              <AdminButtonComponent
                className={classes.updateButton}
                loading={updateLoading}
                disabled={resetPassLoading || updateLoading||fetchLoading}
                title="Update"
                onClick={handleSubmit(onUpdateUser,onUpdateErrorUser)}
              />
            </PermissionWrapper>
          </div>
        }
        pageTitle="User Detail"
      >
        {loading && <LoadingIndicator loading={loading} />}
        {fetchLoading ? (
          <div className="w-full">
            <Skeleton count={1} style={{ height: 600, width: "100%" }} />
          </div>
        ) : userData?.userSlug ? (
          <div className={classes.mainBox}>
            <div
              className={classes.cover}
              style={{
                backgroundImage: `url(${
                  userData?.coverPhoto?.url ||
                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                })`,
              }}
            >
              <div>
                <label>
                  <PermissionWrapper permission="USER_EDIT_DETAILS_UPDATE">
                    <input
                      onChange={(e: any) => onUpdateCover(e.target.files[0])}
                      type="file"
                      hidden
                      accept=".jpg,.jpeg,.png,.svg"
                    />
                  </PermissionWrapper>
                  <img
                    className={classes.iconPen}
                    src="/static/svg/icon_pen.svg"
                  />
                </label>
              </div>
              <label>
                <PermissionWrapper permission="USER_EDIT_DETAILS_UPDATE">
                  <input
                    onChange={(e: any) => onUpdateAvatar(e.target.files[0])}
                    type="file"
                    accept=".jpg,.jpeg,.png,.svg"
                    hidden
                  />
                </PermissionWrapper>
                <div
                  className={classes.photo}
                  style={{
                    backgroundImage: `url(${
                      userData?.photo?.url ||
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    })`,
                  }}
                >
                  <div className={classes.editTextBox}>Edit</div>
                </div>
              </label>
            </div>

            <div className={classes.inputBox}>
              <span className={classes.partHeader}>Personal Information</span>
              <div className="row mt-2 g-3">
                <div className="col-md-3">
                  <ControlledTextField
                    name="firstName"
                    control={control}
                    textLabel="First Name"
                  />
                </div>
                <div className="col-md-3">
                  <ControlledTextField
                    name="lastName"
                    control={control}
                    textLabel="Last Name"
                  />
                </div>
                <div className="col-md-3">
                  <ControlledTextField
                    name="email"
                    control={control}
                    textLabel="Email"
                  />
                </div>

                <div className="col-md-3">
                  <ControlledTextField
                    name="jobTitle"
                    control={control}
                    textLabel="Job Title"
                  />
                </div>
                <div className="col-md-4">
                  <PhoneNumberInput
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    name="phoneNumber"
                    control={control}
                  />
                </div>
                <div className="col-md-8">
                  <ControlledTextField
                    name="about"
                    control={control}
                    textLabel="About"
                  />
                </div>
              </div>
            </div>
            <hr style={{ opacity: 0.07 }} className="mt-5" />
            <div>
              <span className={classes.partHeader}>Social Media</span>
              <div className="row mt-2 g-4">
                <div className="col-md-3">
                  <ControlledTextField
                    error={!!errors?.twitter}
                    helperText={errors?.twitter?.message as string}
                    name="twitter"
                    control={control}
                    textLabel="Twitter"
                  />
                </div>
                <div className="col-md-3">
                  <ControlledTextField
                    error={!!errors?.facebook}
                    helperText={errors?.facebook?.message as string}
                    name="facebook"
                    control={control}
                    textLabel="Facebook"
                  />
                </div>
                <div className="col-md-3">
                  <ControlledTextField
                    error={!!errors?.instagram}
                    helperText={errors?.instagram?.message as string}
                    name="instagram"
                    control={control}
                    textLabel="Instagram"
                  />
                </div>
                <div className="col-md-3">
                  <ControlledTextField
                    error={!!errors?.linkedin}
                    helperText={errors?.linkedin?.message as string}
                    name="linkedin"
                    control={control}
                    textLabel="Linkedin"
                  />
                </div>
              </div>
            </div>
            <hr style={{ opacity: 0.07 }} className="mt-5" />
            <div>
              <span className={classes.partHeader}>Location</span>
              <div className="row mt-2 g-3">
                {newCountries.length ? (
                  <div className="col-md-3">
                    <ControlledComboBox
                      className={classes.comboBox}
                      defaultValue={defaultCountry}
                      name="country"
                      error={!!errors?.country}
                      helperText={errors?.country?.message as string}
                      options={newCountries}
                      control={control}
                      textLabel="Country"
                    />
                  </div>
                ) : null}
                <div className="col-md-3">
                  <ControlledTextField
                    error={!!errors?.city}
                    helperText={errors?.city?.message as string}
                    name="city"
                    control={control}
                    textLabel="City"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Sidebar>
    </>
  );
};

export default UserDetailsContainer;
