import React, { useMemo } from "react";
import { Card, DonutChart, Icon, Metric, Text } from "@tremor/react";
import { makeStyles } from "@mui/styles";
import { ShoppingBagIcon } from "@heroicons/react/outline";
import { DashboardState } from "../../interface";
import NumberFormatComponent from "../../../../components/NumberFormatComponent";
import { isDarkReaderActive } from "../../../../services/common";

const useStyles = makeStyles({
  cardInfoStyle: {
    fontSize: 16,
  },
  cardHeaderStyle: {
    fontSize: 20,
  },

  cardInfoDarkStyle: {
    fontWeight: 600,
    color: "white",
    fontSize: 16,
  },

  fillTremorContentEmphasisCustom: {
    "& text.fill-tremor-content-emphasis": {
      fill: "white!important",
      fontWeight: 600,
    },
  },
});

const TradesCard = ({
  isDarkMode,
  dashboardData,
  pound,
}: {
  isDarkMode: boolean;
  dashboardData?: DashboardState;
  pound?: string;
}) => {
  const classes = useStyles();

  const closedTrades: number | undefined = useMemo(() => {
    return dashboardData?.tradeStatus.closedTrades
      .map((trade) => trade.data && trade.data[0].count)
      .reduce((prev, curr) => prev + curr, 0);
  }, [dashboardData?.tradeStatus.closedTrades.length]);
  const waitingPriceTrades: number | undefined = useMemo(() => {
    return dashboardData?.tradeStatus.returnPriceWaiting
      .map((trade) => trade.data && trade.data[0].count)
      .reduce((prev, curr) => prev + curr, 0);
  }, [dashboardData?.tradeStatus.closedTrades.length]);
  const draft: number | undefined = useMemo(() => {
    return dashboardData?.tradeStatus.draftInvoices
      .map((trade) => trade.data && trade.data[0].count)
      .reduce((prev, curr) => prev + curr, 0);
  }, [dashboardData?.tradeStatus.draftInvoices.length]);

  const formattedData = [
    {
      name: "Completed",
      count: closedTrades,
    },
    {
      name: "Waiting return price",
      count: waitingPriceTrades,
    },
    {
      name: "Draft Invoices",
      count: draft,
    },
  ];

  const valueFormatter = (number: number) =>
    `${pound} ${new Intl.NumberFormat("us").format(number).toString()}`;
  return (
    <Card
      style={{ minHeight: "100%" }}
      decoration="bottom"
      decorationColor="cyan"
    >
      <div className="d-flex flex-row justify-content-between">
        <Metric color="cyan" className={classes.cardHeaderStyle}>
          Trade Status
        </Metric>
        <Icon icon={ShoppingBagIcon} color="cyan" variant="solid" size="sm" />
      </div>
      <DonutChart
        className={`${
          isDarkMode || isDarkReaderActive()
            ? classes.fillTremorContentEmphasisCustom
            : ""
        } mt-4`}
        showAnimation
        data={formattedData}
        category="count"
        index="name"
        colors={["cyan", "blue", "sky"]}
      />

      <div className="d-flex flex-column gap-3 mt-3">
        <Text
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.cardInfoDarkStyle
              : classes.cardInfoStyle
          }
        >
              <a
            target="_blank"
            href={`/surplus-allocation?tradeStatus=COMPLETED`}
            style={{ textDecoration: "underline" }}
            className="fw-bold cursor-pointer"
          >
            Completed :
          </a>{" "} {closedTrades || 0}
        </Text>
        <Text
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.cardInfoDarkStyle
              : classes.cardInfoStyle
          }
        >
               <a
            target="_blank"
            href={`/surplus-allocation?tradeStatus=WAITING_RETURN_PRICE`}
            style={{ textDecoration: "underline" }}
            className="fw-bold cursor-pointer"
          >
            Waiting for return price :
          </a>{" "}{" "}
          {waitingPriceTrades || 0}
        </Text>
        <Text
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.cardInfoDarkStyle
              : classes.cardInfoStyle
          }
        >
               <a
            target="_blank"
            href={`/surplus-allocation?status=DRAFT`}
            style={{ textDecoration: "underline" }}
            className="fw-bold cursor-pointer"
          >
            Draft invoices :
          </a>{" "} {draft || 0}
        </Text>
      </div>
    </Card>
  );
};

export default TradesCard;
