import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getRequests = async (page: number) => {
  try {
    const response = await axiosApiInstance.post(
      "company-certifications/admin/company-certification-requests",
      {
        page: page,
        limit: 20,
      }
    );
    if(response)return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const updatePendingRequest = async (data:any) => {
  try {
    const response = await axiosApiInstance.post(
      '/company-certifications/admin/company-certification-requests/update',
      data
    );
    if (response) {
      toast.success('Request is updated successfully.');
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
}


export const getCertificationTypes = async (page:number) => {
  try {
    const response = await axiosApiInstance.post(
      '/company-certifications/admin/get-certification-types',
      {
        page: page,
        limit: 100,
      }
    );
    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addCertificationTypes = async (data:any) => {
  try {
    const response = await axiosApiInstance.post(
      '/company-certifications/admin/add-certification-type',
      data,
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }
    );
    if (response) {
      toast.success('Certification type added successfully.');
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const deleteCertificationTypes = async (id:string) => {
  try {
    const response = await axiosApiInstance.post(
      '/company-certifications/admin/delete-certification-type',
      {
        certificationTypeId: id,
      }
    );
    if (response) {
      toast.success('Certification type deleted successfully.');
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};


export const updateCertificationTypesImage = async (data:any) => {
  try {
    const response = await axiosApiInstance.post(
      '/company-certifications/admin/update-certification-type',
      data,
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }
    );
    if (response) {
      toast.success('Certification type image updated successfully.');
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const updateCertificationTypesInfo = async (data:any) => {
  try {
    const response = await axiosApiInstance.post(
      '/company-certifications/admin/update-certification-type-infos',
      data
    );
    if (response) {
      toast.success('Certification type title  updated successfully.');
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const deleteCertificate = async (data:any) => {
  try {
    const response = await axiosApiInstance.post(
      '/company-certifications/admin/remove-company-certificate',
      data
    );
    if (response) {
      toast.success('Certification deleted successfully.');
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};