import React from 'react'
import Skeleton from 'react-loading-skeleton';

const SkeletonLoading = () => {
  return (
   <div className="mt-2 row">
   {Array.from({ length: 6 }).map((item, i) => {
     return (
       <div key={i} className="col-4">
         <Skeleton count={1} height={350} />
       </div>
     );
   })}
 </div>
  )
}

export default SkeletonLoading