import React from "react";
import { Controller } from "react-hook-form";
import { DateInputTypes } from "./interfaces/ComponentInterfaces";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormLabel } from "@mui/material";
const ControlledDateField: React.FC<DateInputTypes> = ({
  name,
  isShowIcons,
  watchValue,
  textLabel,
  control,
  disabled,
  defaultValue,
  helperText,
  width,
}) => {
  return (
    <div className="d-flex flex-column">
      <FormLabel
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
          flexDirection: "row",
        }}
      >
        {textLabel?textLabel:<br/>}{" "}
        {isShowIcons ? (
          watchValue ? (
            <img src="/static/svg/ic_checked.svg" />
          ) : (
            <img
              style={{ width: 17, height: 17 }}
              src="/static/svg/ic_warning.png"
            />
          )
        ) : null}{" "}
      </FormLabel>
      <Controller
        name={name}
        defaultValue={defaultValue || ""}
        control={control}
        render={({ field }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
              format="DD/MM/YYYY"
                {...field}
                defaultValue={defaultValue}
                disabled={disabled}
                sx={{
                
                  svg: { zIndex: 1000 },
                  input: { zIndex: 1000, width: width ? width : "100%" },
                  "& fieldset": {
                    border: "none",
                    height: 55,
                    backgroundColor: "#f7f7f7",
                    borderRadius: "10px!important",
                  },
                }}
              />
              <span style={{ color: "#E77228", fontSize: "0.75rem" }}>
                {helperText}
              </span>
            </LocalizationProvider>
          );
        }}
      />
    </div>
  );
};

export default ControlledDateField;
