import React, { ReactElement } from "react";
import usePermissions from "../hooks/usePermissions";
import { toast } from "react-toastify";
import Unauthorized from "./Unauthorized";
import { useAppSelector } from "../hooks/hooks";
import { PermissionNameType } from "../permissions";

export default function PermissionWrapper({
  permission,
  unauthorizedComponent,
  children,
}: {
  permission: PermissionNameType;
  unauthorizedComponent?: boolean | React.ReactNode;
  children: React.ReactNode;
}) {
  const isAdminInfoLoading = useAppSelector((state) => state.auth.adminLoading);
  const { hasPermission } = usePermissions();

  if (isAdminInfoLoading) {
    return <></>;
  }

  if (hasPermission(permission)) {
    return <>{children}</>;
  } else if (unauthorizedComponent) {
    return typeof unauthorizedComponent === "boolean" ? (
      <Unauthorized />
    ) : (
      <>{unauthorizedComponent}</>
    );
  } else {
    return (
      <>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            const newStyle = {
              ...child.props.style,
              cursor: "not-allowed",
              opacity: 0.5,
            };
            const newProps = {
              style: newStyle,
              onClick: (e: React.MouseEvent) => {
                e.preventDefault();
                toast.error("You are not allowed to perform this action.");
                e.stopPropagation();
              },
            };
            return React.cloneElement(child as ReactElement, newProps);
          }
          return child;
        })}
      </>
    );
  }
}
