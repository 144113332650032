import React, { useMemo } from "react";
import { Card, DonutChart, Icon, Metric, Text } from "@tremor/react";
import { makeStyles } from "@mui/styles";
import { DocumentIcon } from "@heroicons/react/outline";
import { DashboardState } from "../../interface";
import NumberFormatComponent from "../../../../components/NumberFormatComponent";
import { Link, useNavigate } from "react-router-dom";
import { isDarkReaderActive } from "../../../../services/common";

const useStyles = makeStyles({
  cardInfoStyle: {
    fontSize: 16,
  },
  cardInfoDarkStyle: {
    fontWeight: 600,
    color: "white",
    fontSize: 16,
  },
  cardHeaderStyle: {
    fontSize: 20,
  },
  fillTremorContentEmphasisCustom: {
    "& text.fill-tremor-content-emphasis": {
      fill: "white!important",
      fontWeight: 600,
    },
  },
});

const InvoicesCard = ({
  isDarkMode,
  dashboardData,
  pound,
}: {
  isDarkMode: boolean;
  pound?: string;
  dashboardData?: DashboardState;
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const issuedInvoices: number = useMemo(() => {
    return dashboardData?.invoiceStatus
      .map((item) => item.data.find((data) => data.status === "ISSUED"))
      .filter((f) => f)
      .map((issued) => issued?.count || 0)
      .reduce((prev: any, curr: any) => prev + curr, 0);
  }, [dashboardData?.invoiceStatus.length]);
  const paidInvoices: number = useMemo(() => {
    return dashboardData?.invoiceStatus
      .map((item) => item.data.find((data) => data.status === "PAID"))
      .filter((f) => f)
      .map((issued) => issued?.count || 0)
      .reduce((prev: any, curr: any) => prev + curr, 0);
  }, [dashboardData?.invoiceStatus.length]);

  const draftInvoices: number = useMemo(() => {
    return dashboardData?.invoiceStatus
      .map((item) => item.data.find((data) => data.status === "DRAFT"))
      .filter((f) => f)
      .map((issued) => issued?.count || 0)
      .reduce((prev: any, curr: any) => prev + curr, 0);
  }, [dashboardData?.invoiceStatus.length]);

  const dueInvoices: number = useMemo(() => {
    return dashboardData?.invoiceStatus
      .map((item) => item.data.find((data) => data.status === "DUE"))
      .filter((f) => f)
      .map((issued) => issued?.count || 0)
      .reduce((prev: any, curr: any) => prev + curr, 0);
  }, [dashboardData?.invoiceStatus.length]);

  const formattedData = [
    {
      name: "Issued",
      count: issuedInvoices,
    },
    {
      name: "Paid",
      count: paidInvoices,
    },
    {
      name: "To be captured",
      count: draftInvoices,
    },
    {
      name: "Overdue/Unpaid",
      count: dueInvoices,
    },
  ];

  return (
    <Card
      style={{ minHeight: "100%" }}
      decoration="bottom"
      decorationColor="orange"
    >
      <div className="d-flex flex-row justify-content-between">
        <Metric color="orange" className={classes.cardHeaderStyle}>
          Invoice Status
        </Metric>
        <Icon icon={DocumentIcon} color="orange" variant="solid" size="sm" />
      </div>
      <DonutChart
      
      className={`${
        isDarkMode || isDarkReaderActive()
          ? classes.fillTremorContentEmphasisCustom
          : ""
      } mt-4`}
        showAnimation
        data={formattedData}
        category="count"
        index="name"
        colors={["orange", "amber", "yellow", "red"]}
      />
      <div className="d-flex flex-column gap-3 mt-3">
        <Text
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.cardInfoDarkStyle
              : classes.cardInfoStyle
          }
        >
          <a
            target="_blank"
            href={`/surplus-allocation?status=ISSUED`}
            style={{ textDecoration: "underline" }}
            className="fw-bold cursor-pointer"
          >
            Issued :
          </a>{" "}
          {issuedInvoices}
        </Text>
        <Text
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.cardInfoDarkStyle
              : classes.cardInfoStyle
          }
        >
          <a
            target="_blank"
            href={`/surplus-allocation?status=PAID`}
            style={{ textDecoration: "underline" }}
            className="fw-bold cursor-pointer"
          >
            Paid :
          </a>{" "}
          {paidInvoices}
        </Text>
        <Text
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.cardInfoDarkStyle
              : classes.cardInfoStyle
          }
        >
          <a
            target="_blank"
            href={`/surplus-allocation?status=DRAFT`}
            style={{ textDecoration: "underline" }}
            className="fw-bold cursor-pointer"
          >
            To be captured :
          </a>{" "}
          {draftInvoices}
        </Text>
        <Text
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.cardInfoDarkStyle
              : classes.cardInfoStyle
          }
        >
          <a
            target="_blank"
            href={`/surplus-allocation?status=DUE`}
            style={{ textDecoration: "underline" }}
            className="fw-bold cursor-pointer"
          >
            Overdue/Unpaid :
          </a>{" "}
          {dueInvoices}
        </Text>
      </div>
    </Card>
  );
};

export default InvoicesCard;
