import React, { useCallback, useEffect, useState } from "react";
import { Certifications, CompanyDetailsStateType } from "./interfaces";
import TableComponent from "../../../components/TableComponent";
import { Chip, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import AdminModalComponent from "../../../components/AdminModalComponent";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import {
  deleteCertificate,
  getCertificationTypes,
  updatePendingRequest,
} from "../../../services/company-certificates";
import PermissionWrapper from "../../../components/PermissionWrapper";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import ControlledComboBox from "../../../components/ControlledComboBox";
import { addCompanyCertificate } from "../../../services/company";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { toast } from "react-toastify";

export interface CertificationType {
  _id: string;
  title: string;
  imagePath: string;
  createdAt: string;
  updatedAt: string;
}

const schema = yup.object().shape({
  certificateType: yup.object(),
});

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  PendingChip: {
    position: "absolute",
    left: 0,
    top: 5,
    margin: "5px!important",
    background: "#E0A600!important",
    color: "white!important",
  },
  ApprovedChip: {
    position: "absolute",
    left: 0,
    top: 5,
    margin: "5px!important",
    background: "#69CDAE!important",
    color: "white!important",
  },
  approve: {
    background: "#69CDAE!important",
    borderRadius: "10px!important",
  },
  reject: {
    background: "#ED0000!important",
    borderRadius: "10px!important",
  },
  RejectedChip: {
    position: "absolute",
    left: 0,
    top: 5,
    margin: "5px!important",
    background: "#ED0000!important",
    color: "white!important",
  },
  seeFile: {
    cursor: "pointer",
    color: "#E77228",
    textDecoration: "underline",
  },
  image: {
    width: 75,
    height: 75,
    borderRadius: "100%",
  },
  trash: {
    color: "red",
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#e77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  ModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  imageTitle: {
    marginLeft: 5,
  },
  labelImage: {
    cursor: "pointer",
  },
  uploadSvg: {
    borderRadius: "100%",
    width: 50,
    height: 50,
  },
  headerDocsText: {
    fontWeight: 600,
    fontSize: 20,
  },

  uploadDoc: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
    color: "#e77228",
    border: "1px solid",
    borderRadius: 10,
    padding: 5,
    fontWeight: 600,
  },
  certItem: {
    position: "relative",
    backgroundSize: "cover",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    marginTop: 15,

    minHeight: "100%",
    borderRadius: 10,
    background: "#f8f8f8",
  },
  trashCertDelete: {
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: 22,
    alignItems: "center",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    background: "white",
    width: 30,
    height: 30,
    cursor: "pointer",
    color: "red",
  },
  contentText: {
    marginTop: 10,
    marginLeft: 15,
  },
});

const columns = [
  { name: "File", id: "file" },
  { name: "Document Type", id: "documentType" },
  { name: "Document Type Title", id: "title" },
  { name: "Create Time", id: "createdAt" },
  { name: "Status", id: "status" },
  { name: "", id: "action" },
];

const CertificatedDocuments = ({
  refresh,
  setRefresh,
  companyData,
}: {
  refresh: number;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  companyData: CompanyDetailsStateType | undefined;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      certificateType: {},
    },
  });
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [addLoading, setAddLoading] = useState(false);
  const [selectedUploadFile, setSelectedUploadFile] = useState();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [certificateTypes, setCertificateTypes] = useState<CertificationType[]>(
    []
  );
  const [openApproveRejectConfirmModal, setOpenApproveRejectConfirmModal] =
    useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [isFetchCertificateTypes, setIsFetchCertificateTypes] = useState(false);
  const [certificateTypeLoading, setCertificateTypeLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<Certifications>();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  useEffect(() => {
    if (isFetchCertificateTypes && !certificateTypes.length) {
      const getCerTypes = async () => {
        setCertificateTypeLoading(true);

        const response = await getCertificationTypes(1);
        setCertificateTypeLoading(false);

        if (response?.success) {
          setCertificateTypes(response?.certificationTypes);
        }
      };
      getCerTypes();
    }
  }, [isFetchCertificateTypes, certificateTypes.length]);

  const onSelectFile = (e: any) => {
    setSelectedUploadFile(e);
  };

  const onOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const onCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const onOpenModal = (id: string) => {
    setOpenModal(true);
    setSelected(id);
  };
  const onOpenApproveOrRejectConfirmModal = (row: any, type: string) => {
    setOpenApproveRejectConfirmModal(true);
    setSelectedRow(row);
    setModalType(type);
  };

  const onCloseApproveOrRejectConfirmModal = () => {
    setOpenApproveRejectConfirmModal(false);
    setSelectedRow(undefined);
    setModalType("");
  };

 

  const onApproveOrRejectDoc = useCallback(async () => {
    if (modalType === "APPROVE") {
      const data = {
        status: "APPROVED",
        companyId: companyData?.id,
        certificationRequestId: selectedRow?._id,
        certificationTypeId: selectedRow?.certificationType._id,
      };
      setLoading(true);
      const response = await updatePendingRequest(data);
      setLoading(false);

      if (response) {
        setRefresh((prev: number) => prev + 1);
        setOpenApproveRejectConfirmModal(false);
        setModalType("");
        setSelectedRow(undefined);
      }
    } else {
      const data = {
        status: "REJECTED",
        companyId: companyData?.id,
        certificationRequestId: selectedRow?._id,
        certificationTypeId: selectedRow?.certificationType._id,
      };
      setLoading(true);
      const response = await updatePendingRequest(data);
      setLoading(false);
      if (response) {
        setRefresh((prev: number) => prev + 1);
        setOpenApproveRejectConfirmModal(false);
        setModalType("");
        setSelectedRow(undefined);
      }
    }
  }, [modalType, selectedRow, companyData]);

  // const renderTableBody = () => {
  //   return (
  //     <>
  //       {companyData?.certifiedDocuments.map((document, index) => {
  //         return (
  //           <TableRow
  //             className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
  //             key={index}
  //             sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
  //           >
  //             <TableCell component="th" scope="row">
  //               <a
  //                 href={document.url}
  //                 target="_blank"
  //                 className={classes.seeFile}
  //                 rel="noreferrer"
  //               >
  //                 {" "}
  //                 See file
  //               </a>
  //             </TableCell>
  //             <TableCell component="th" scope="row">
  //               <img
  //                 className={classes.image}
  //                 src={document.certificationType.imagePath}
  //               />
  //             </TableCell>
  //             <TableCell component="th" scope="row">
  //               {document.title}
  //             </TableCell>
  //             <TableCell component="th" scope="row">
  //               {moment(document.createdAt).format("DD/MM/YYYY")}
  //             </TableCell>
  //             <TableCell component="th" scope="row">
  //               {document.status === "PENDING" ? (
  //                 <Chip
  //                   className={classes.PendingChip}
  //                   label={document.status}
  //                 />
  //               ) : document.status === "APPROVED" ? (
  //                 <Chip
  //                   className={classes.ApprovedChip}
  //                   label={document.status}
  //                 />
  //               ) : document.status === "REJECTED" ? (
  //                 <Chip
  //                   className={classes.RejectedChip}
  //                   label={document.status}
  //                 />
  //               ) : null}
  //             </TableCell>
  //             <TableCell component="th" scope="row">
  //               {document?.status === "PENDING" ? (
  //                 <div className="d-flex flex-row gap-2 justify-content-end">
  //                   <AdminButtonComponent
  //                     className={classes.approve}
  //                     title="Approve"
  //                     onClick={() =>
  //                       onOpenApproveOrRejectConfirmModal(document, "APPROVE")
  //                     }
  //                   />
  //                   <AdminButtonComponent
  //                     className={classes.reject}
  //                     title="Reject"
  //                     onClick={() =>
  //                       onOpenApproveOrRejectConfirmModal(document, "REJECT")
  //                     }
  //                   />
  //                 </div>
  //               ) : (
  //                 <div className="d-flex justify-content-end">
  //                   <Tooltip title="Delete document">
  //                     <PermissionWrapper permission="COMPANY_UPDATE">
  //                       <IconButton onClick={() => onOpenModal(document._id)}>
  //                         <i
  //                           className={`ri-delete-bin-line ${classes.trash}`}
  //                         ></i>
  //                       </IconButton>
  //                     </PermissionWrapper>
  //                   </Tooltip>
  //                 </div>
  //               )}
  //             </TableCell>
  //           </TableRow>
  //         );
  //       })}
  //     </>
  //   );
  // };

  const onDeleteDocument = async () => {
    setLoading(true);
    const data = {
      certificationRequestId: selected,
      companyId: companyData?.id ? companyData?.id : "",
    };
    const deleted = await deleteCertificate(data);
    setLoading(false);

    if (deleted) {
      setOpenModal(false);
      setSelected("");
      setRefresh(() => refresh + 1);
    }
  };

  const onAddCertificate = useCallback(
    async (data: any) => {
      if (!data?.certificateType?.id || !selectedUploadFile) {
        toast.error("Please select type and upload a file.");
      } else {
        setAddLoading(true);
        const formData = new FormData();
        formData.append("userId", companyData?.user || "");
        formData.append("companyId", companyData?.id || "");
        formData.append("certificationTypeId", data?.certificateType?.id);
        formData.append("file", selectedUploadFile as any);
        const response = await addCompanyCertificate(formData);
        setAddLoading(false);

        if (response) {
          reset();
          setSelectedUploadFile(undefined);
          setOpenAddModal(false);
          setRefresh((prev: number) => prev + 1);
        }
      }
    },
    [selectedUploadFile, companyData?.user, companyData?.id]
  );

  const renderModalBody = () => {
    return (
      <>
        <span>Are you sure you want to delete this document?</span>
        <div className={classes.ModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            disabled={loading}
            loading={loading}
            className={classes.saveButton}
            onClick={onDeleteDocument}
            title="Delete"
          />
        </div>
      </>
    );
  };
  const renderApproveRejectConfirmModalBody = () => {
    return (
      <>
        <span>
          {" "}
          {modalType === "APPROVE"
            ? "Are you sure you want to approve document?"
            : modalType === "REJECT"
            ? "Are you sure you want to reject document?"
            : ""}
        </span>
        <div className={classes.ModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={onCloseApproveOrRejectConfirmModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            disabled={loading}
            loading={loading}
            className={classes.saveButton}
            onClick={onApproveOrRejectDoc}
            title="Confirm"
          />
        </div>
      </>
    );
  };

  const renderAddModalBody = () => {
    return (
      <>
        <ControlledComboBox
          error={!!errors.certificateType}
          helperText={errors.certificateType?.message}
          onFocus={() => setIsFetchCertificateTypes(true)}
          className={classes.comboBox}
          textLabel="Certificate Type"
          options={certificateTypes.map((item) => ({
            name: item.title,
            id: item._id,
          }))}
          name="certificateType"
          control={control}
        />

        <label className="d-flex flex-row align-items-center mt-4 cursor-pointer">
          {selectedUploadFile ? (
            <div className="d-flex flex-row gap-3 align-items-center">
              <a
                target="_blank"
                rel="noreferrer"
                href={URL.createObjectURL(selectedUploadFile)}
                className={classes.seeFile}
              >
                See File
              </a>
              <i
                onClick={() => setSelectedUploadFile(undefined)}
                className={`ri-delete-bin-line ${classes.trash}`}
              ></i>
            </div>
          ) : (
            <img className={classes.uploadSvg} src="/static/svg/upload.svg" />
          )}

          <span className={classes.imageTitle}>
            {selectedUploadFile ? null : "Please select a file to upload"}
          </span>
          {selectedUploadFile ? null : (
            <input
              onChange={(e) => onSelectFile(e.target.files?.[0])}
              type="file"
              accept=".xlsx,.pdf,.doc,.docx,.svg,.jpeg,.jpg"
              hidden
            />
          )}
        </label>
        <div className={classes.ModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseAddModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            disabled={addLoading}
            loading={addLoading}
            className={classes.saveButton}
            onClick={handleSubmit(onAddCertificate)}
            title="Submit"
          />
        </div>
      </>
    );
  };

  return (
    <div>
      {certificateTypeLoading && (
        <LoadingIndicator loading={certificateTypeLoading} />
      )}
      <div className="row g-3">
        <div className="d-flex justify-content-between align-items-center">
          <span className={classes.headerDocsText}>Certifications</span>
          <PermissionWrapper permission="COMPANY_UPDATE">
            <span onClick={onOpenAddModal} className={classes.uploadDoc}>
              {" "}
              {"+ Upload Certificate"}
            </span>
          </PermissionWrapper>
        </div>

        {companyData?.certifiedDocuments.map((c, index) => {
          return (
            <div className="col-md-3">
              <div className={classes.certItem}>
                <img
                  src={c.certificationType.imagePath}
                  style={{
                    width: "100%",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    height: 250,
                  }}
                />
                {c.status !== "PENDING" ? (
                  <PermissionWrapper permission="COMPANY_UPDATE">
                    <i
                      onClick={() => onOpenModal(c._id)}
                      className={`position-absolute ri-delete-bin-line ${classes.trashCertDelete} right-2 top-2`}
                    ></i>
                  </PermissionWrapper>
                ) : null}
                {c.status === "PENDING" ? (
                  <Chip className={classes.PendingChip} label={c.status} />
                ) : c.status === "APPROVED" ? (
                  <Chip className={classes.ApprovedChip} label={c.status} />
                ) : c.status === "REJECTED" ? (
                  <Chip className={classes.RejectedChip} label={c.status} />
                ) : null}
                <p className={classes.contentText}>
                  <span style={{ color: "#E77228" }} className="fw-bold">
                    Title :{" "}
                  </span>
                  {c.certificationType.title || "N/A"}{" "}
                </p>
                {c.status === "PENDING" ? (
                  <div className="ml-3 mt-2">
                    {" "}
                    <div className="d-flex flex-row gap-2 justify-content-center">
                      <PermissionWrapper permission="COMPANY_UPDATE">
                        <AdminButtonComponent
                          className={classes.approve}
                          title="Approve"
                          onClick={() =>
                            onOpenApproveOrRejectConfirmModal(
                              c,
                              "APPROVE"
                            )
                          }
                        />
                      </PermissionWrapper>
                      <PermissionWrapper permission="COMPANY_UPDATE">
                        {" "}
                        <AdminButtonComponent
                          className={classes.reject}
                          title="Reject"
                          onClick={() =>
                            onOpenApproveOrRejectConfirmModal(
                              c,
                              "REJECT"
                            )
                          }
                        />
                      </PermissionWrapper>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      {/* <TableComponent
        rightButtonPermission="COMPANY_UPDATE"
        setOpenModal={setOpenAddModal}
        headerButtonTitle=" + Add new certificate"
        showRightButton={true}
        TableBodyComponent={renderTableBody}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        columns={columns}
      /> */}
      <AdminModalComponent
        headerTitle="Are you sure"
        children={renderModalBody()}
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
      />
      <AdminModalComponent
        headerTitle="Are you sure"
        children={renderApproveRejectConfirmModalBody()}
        openModal={openApproveRejectConfirmModal}
        closeModal={onCloseApproveOrRejectConfirmModal}
      />
      <AdminModalComponent
        headerTitle="Add new certificate"
        children={renderAddModalBody()}
        openModal={openAddModal}
        closeModal={onCloseAddModal}
      />
    </div>
  );
};

export default CertificatedDocuments;
