import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Box,
  Typography,
  Grid,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { Permission } from "../../permissions";
import { adminUsersObjectType } from "../AdminUsers/interfaces";
import { sortArray } from "../../services/common";

const useStyles = makeStyles({
  list: {
    minHeight: "40vh",
    maxHeight: "60vh",
    overflowY: "auto",
    margin: 0,
    padding: 0,
    marginBottom: 16,
    listStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: 16,
    },
  },
  listItem: {
    background: "#ffead8",
    borderRadius: 10,
    padding: 5,
  },
});

export interface ItemType {
  name: any;
  _id: string;
}

export interface RightSectionRemove<T, K> {
  data: K;
  items: ItemType[];
}

export interface RightSectionAdd<K> {
  data: K;
}

interface DualListBoxProps<T, K> {
  adminUsers: adminUsersObjectType[];
  selectedGroupId: string;
  activeTabIndex: number;
  selectedList?: ItemType;
  setSelectedList?: React.Dispatch<React.SetStateAction<ItemType>>;
  selectedListItems: ItemType[];
  setSelectedListItems: React.Dispatch<React.SetStateAction<ItemType[]>>;
  groups: ItemType[];
  selectedGroupPermissions: { name: string; _id: string }[];
  permissions: Permission[];
  setSelectedGroupId: React.Dispatch<React.SetStateAction<string>>;
  rightSectionTitle?: string;
  onRightSectionAdd?: (item: ItemType) => void;
  onRightSectionRemove?: (item: ItemType) => void;
  onLeftSectionAdd?: () => void;
  getAdmins?: () => void;
  onLeftSectionRemove?: (item: ItemType) => void;
}

const DualListBox = <T extends unknown, K extends unknown>({
  selectedGroupId,
  getAdmins,
  adminUsers,
  selectedList,
  activeTabIndex,
  setSelectedList,
  selectedListItems,
  setSelectedListItems,
  selectedGroupPermissions,
  groups,
  permissions,
  rightSectionTitle,
  setSelectedGroupId,
  onRightSectionAdd,
  onRightSectionRemove,
  onLeftSectionAdd,
  onLeftSectionRemove,
}: DualListBoxProps<T, K>) => {
  const classes = useStyles();

  const tempAdminUsers = useMemo(() => {
    return adminUsers
      .map((user) => ({
        name: user.fullName,
        _id: user.id,
      }))
      .filter((item) => item.name);
  }, [adminUsers]);

  const handleListItemClick = useCallback(
    (list: ItemType) => {
      if (activeTabIndex === 0) {
        setSelectedGroupId((list as any)?._id);
        setSelectedList && setSelectedList(list);
        setSelectedListItems(selectedGroupPermissions);
      } else {
        getAdmins && getAdmins();
        const selectedAdminUser = adminUsers.find(
          (item) => item.id === list._id
        );

        const adminUserGroups = groups.filter((gr) =>
          selectedAdminUser?.groups.map((g) => g._id).includes(gr._id as any)
        );
        setSelectedGroupId((list as any)?._id);
        setSelectedList && setSelectedList(list);
        setSelectedListItems(adminUserGroups);
      }
    },
    [selectedGroupPermissions, activeTabIndex, adminUsers, groups]
  );

  const handleListItemToggle = useCallback(
    (item: Permission) => {
      const isInPermission = selectedListItems.find(
        (li) => li._id === item._id
      );

      if (isInPermission?._id) {
        onRightSectionRemove && onRightSectionRemove(item);
      } else {
        onRightSectionAdd && onRightSectionAdd(item);
      }
    },
    [selectedListItems]
  );

  return (
    <Grid container spacing={2}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        justifyContent="space-between"
        style={{ backgroundColor: "#fbfbfb", borderRadius: 16 }}
      >
        {/* Groups List */}
        <List className={classes.list}>
          {(activeTabIndex === 0 ? groups : sortArray(tempAdminUsers)).map(
            (listItem: ItemType, index: number) => {
              return (
                <ListItem
                  className={
                    selectedGroupId === listItem._id ? classes.listItem : ""
                  }
                  key={index}
                  onClick={() => handleListItemClick(listItem as any)}
                >
                  <ListItemText>
                    <Typography
                      style={{
                        cursor: "pointer",
                        color:
                          selectedGroupId === listItem._id
                            ? "#E77228"
                            : "black",
                        fontWeight:
                          selectedGroupId === listItem._id ? 600 : 400,
                      }}
                    >
                      {listItem.name}
                    </Typography>
                  </ListItemText>
                </ListItem>
              );
            }
          )}
        </List>
        {!onLeftSectionAdd && !onLeftSectionRemove && activeTabIndex === 0 ? (
          <></>
        ) : (
          <Box
            display={"flex"}
            gap={4}
            justifySelf={"flex-end"}
            alignSelf={"flex-end"}
            p={2}
          >
            {onLeftSectionRemove && (
              <AdminButtonComponent
                onClick={() =>
                  selectedList && onLeftSectionRemove(selectedList)
                }
                title="Remove"
                type="error"
                disabled={!selectedList}
              />
            )}
            {onLeftSectionAdd && (
              <AdminButtonComponent
                onClick={() => onLeftSectionAdd()}
                title="Add"
              />
            )}
          </Box>
        )}
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        justifyContent="space-between"
      >
        {/* {activeTabIndex === 0 ? (
          <Typography ml={2} fontWeight={600} mb={2}>
            {selectedListItems.length === 0
              ? ""
              : rightSectionTitle
              ? `${selectedList?.name}'s ${rightSectionTitle}`
              : selectedList?.name}
          </Typography>
        ) : null} */}
        {/* Permissions List */}
        <List className={classes.list}>
          {(activeTabIndex === 0 ? permissions : groups).map(
            (i: ItemType, index) => {
              const isChecked = selectedListItems
                .map((item) => item._id)
                .includes(i._id as any);
              return (
                <ListItem key={index}>
                  <ListItemText>
                    <Typography>{i.name}</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={() => handleListItemToggle(i)}
                      defaultChecked={isChecked}
                      checked={isChecked}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            }
          )}
        </List>

      </Box>
    </Grid>
  );
};

export default DualListBox;
