import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import { RequestsStateType } from "./interface";
import { getVerificationRequests } from "../../services/verification-requests";
import { Chip, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import VerificationRequestDetail from "./VerificationRequestDetail";
import LoadingIndicator from "../../components/LoadingIndicator";
import AdminModalComponent from "../../components/AdminModalComponent";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import ControlledTextField from "../../components/ControlledTextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledComboBox from "../../components/ControlledComboBox";
import FilterBadge from "../../components/FilterBadge";
import { Helmet } from "react-helmet";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: 444,

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  companyText: {
    color: "#E77228",
    cursor: "pointer",
    textDecoration: "underline",
  },
  PendingChip: {
    background: "#E0A600!important",
    color: "white!important",
  },
  ApprovedChip: {
    background: "#69CDAE!important",
    color: "white!important",
  },
  RejectedChip: {
    background: "#ED0000!important",
    color: "white!important",
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
});

interface QueryParamsType {
  page: number;
  limit: number;
  total: number;
  totalPage: number;
}
interface FilterDataType {
  buyerStatus: string;
  sellerStatus: string;
  companyName: string;
}

const schema = yup.object().shape({
  buyerStatus: yup.string().nullable(),
  sellerStatus: yup.string().nullable(),
  companyName: yup.string().nullable(),
});

const columns = [
  { name: "Company Name", id: "companyName" },
  { name: "Seller Status", id: "sellerStatus" },
  { name: "Buyer Status", id: "buyerStatus" },
];

const VerificationRequestContainer = () => {
  const {
    control,
    watch,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const classes = useStyles();
  const [queryParams, setQueryParams] = useState<QueryParamsType>({
    limit: 1,
    total: 1,
    totalPage: 1,
    page: 1,
  });

  const {hasPermission}=usePermissions()

  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [renderedContainer, setRenderedContainer] = useState("table");
  const [refreshRequest, setRefreshRequest] = useState<number>(0);
  const [deletedFilterKey, setDeletedFilterKey] = useState<string>("");
  const [filterData, setFilterData] = useState<FilterDataType>({
    buyerStatus: "",
    sellerStatus: "",
    companyName: "",
  });
  const [selectedDetail, setSelectedDetail] = useState<RequestsStateType>();
  const [requests, setRequests] = useState<RequestsStateType[]>([]);
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);

  useEffect(() => {
    setValue((`${deletedFilterKey}` as any), undefined);
  }, [deletedFilterKey]);

  useEffect(() => {
    if(!hasPermission("COMPANY_VIEW_DETAILS"))return
    const getRequests = async () => {
      setFetchLoading(true);
      const response = await getVerificationRequests(queryParams, filterData);
      setFetchLoading(false);

      if (response) {
        setRequests(response.data);
        setQueryParams({
          page: response?.pagination?.page,
          total: response?.pagination?.total,
          totalPage: response?.pagination?.totalPage,
          limit: response?.pagination?.limit,
        });
      }
    };
    getRequests();
  }, [
    queryParams.page,
    refreshRequest,
    filterData.buyerStatus,
    filterData.sellerStatus,
    filterData.companyName,
    hasPermission("COMPANY_VIEW_DETAILS")
  ]);

  const onSeeDetail = (row: RequestsStateType) => {
    setSelectedDetail(row);
    setRenderedContainer("detail");
  };

  const renderTableBody = () => {
    return (
      <>
      <Helmet>
          <title> Company Verification | FruPro Admin Portal</title>
      </Helmet>
        {requests.map((item, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <span
                  onClick={() => onSeeDetail(item)}
                  className={classes.companyText}
                >
                  {item.companyName}
                </span>
              </TableCell>

              <TableCell component="th" scope="row">
                {item.verifiedSeller.status === "PENDING" ? (
                  <Chip
                    className={classes.PendingChip}
                    label={item.verifiedSeller.status}
                  />
                ) : item.verifiedSeller.status === "APPROVED" ? (
                  <Chip
                    className={classes.ApprovedChip}
                    label={item.verifiedSeller.status}
                  />
                ) : item.verifiedSeller.status === "REJECTED" ? (
                  <Chip
                    className={classes.RejectedChip}
                    label={item.verifiedSeller.status}
                  />
                ) : (
                  <Chip label={item.verifiedSeller.status} />
                )}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.verifiedBuyer.status === "PENDING" ? (
                  <Chip
                    className={classes.PendingChip}
                    label={item.verifiedBuyer.status}
                  />
                ) : item.verifiedBuyer.status === "APPROVED" ? (
                  <Chip
                    className={classes.ApprovedChip}
                    label={item.verifiedBuyer.status}
                  />
                ) : item.verifiedBuyer.status === "REJECTED" ? (
                  <Chip
                    className={classes.RejectedChip}
                    label={item.verifiedBuyer.status}
                  />
                ) : (
                  <Chip label={item.verifiedBuyer.status} />
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const onOpenFilterModal = () => {
    setOpenFilterModal(true);
  };

  const onCloseFilterModal = () => {
    setOpenFilterModal(false);
  };

  const onFilter = (data: any) => {
    setQueryParams({
      ...queryParams,
      page:1
    })
    setOpenFilterModal(false);
    setFilterData({
      buyerStatus: data?.buyerStatus ? data?.buyerStatus : undefined,
      sellerStatus: data?.sellerStatus ? data?.sellerStatus : undefined,
      companyName: data?.companyName ? data?.companyName : undefined,
    });
  };

  const renderModalBody = () => {
    return (
      <>
        <div className="container ">
          <div className="row g-3">
            <div className="col-12">
              <ControlledTextField
                textLabel="Company Name"
                control={control}
                name="companyName"
              />
            </div>

            <div className="col-12">
              <ControlledComboBox
                className={classes.comboBox}
                options={["PENDING", "APPROVED", "REJECTED"]}
                textLabel="Seller Status"
                control={control}
                name="sellerStatus"
              />
            </div>
            <div className="col-12">
              <ControlledComboBox
                className={classes.comboBox}
                options={["PENDING", "APPROVED", "REJECTED"]}
                textLabel="Buyer Status"
                control={control}
                name="buyerStatus"
              />
            </div>
          </div>
          <div className={classes.filterModalButtons}>
            <AdminButtonComponent
              disabled={loading}
              className={classes.closeButton}
              onClick={() => setOpenFilterModal(false)}
              title="Cancel"
            />{" "}
            <AdminButtonComponent
              loading={loading}
              disabled={false}
              className={classes.saveButton}
              onClick={handleSubmit(onFilter)}
              title="Filter"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <Sidebar pageTitle="Verification Requests">
      {loading && <LoadingIndicator loading />}
     <PermissionWrapper permission="COMPANY_VIEW_DETAILS" unauthorizedComponent>
     {renderedContainer === "table" ? (
        <TableComponent
        loading={fetchLoading}
          filterBadge={
            <FilterBadge
              filterData={filterData}
              setDeletedFilterKey={setDeletedFilterKey}
              setFilterData={setFilterData}
            />
          }
          onOpenModal={onOpenFilterModal}
          showFilterIcon
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          TableBodyComponent={renderTableBody}
          showRightButton={false}
          columns={columns}
        />
      ) : (
        <VerificationRequestDetail
          refreshRequest={refreshRequest}
          setRefreshRequest={setRefreshRequest}
          loading={loading}
          setLoading={setLoading}
          selectedDetail={selectedDetail}
          setRenderedContainer={setRenderedContainer}
        />
      )}
     </PermissionWrapper>

      <AdminModalComponent
        children={renderModalBody()}
        openModal={openFilterModal}
        closeModal={onCloseFilterModal}
        headerTitle={"Filter requests"}
      />
    </Sidebar>
  );
};

export default VerificationRequestContainer;
