import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { makeStyles } from "@mui/styles";
import TableComponent from "../../components/TableComponent";
import { getInvoices, updateInvoice } from "../../services/uploaded-invoices";
import LoadingIndicator from "../../components/LoadingIndicator";
import { UploadedInvoicesStateType } from "./interface";
import { Box, Chip, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import AdminModalComponent from "../../components/AdminModalComponent";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../components/ControlledTextField";
import ControlledComboBox from "../../components/ControlledComboBox";
import FilterBadge from "../../components/FilterBadge";
import { Helmet } from "react-helmet";

const columns = [
  { name: "Company Name", id: "" },
  { name: "Invoice", id: "" },
  { name: "Status", id: "" },
  { name: "Create Date", id: "" },
  { name: "", id: "" },
];

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: 465,

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  PendingChip: {
    width: 120,
    background: "#E0A600!important",
    color: "white!important",
  },
  ApprovedChip: {
    width: 120,
    background: "#69CDAE!important",
    color: "white!important",
  },
  RejectedChip: {
    width: 120,
    background: "#ED0000!important",
    color: "white!important",
  },
  approve: {
    textTransform: "capitalize",
    width: 200,
    background: "#5F8CBA!important",
    borderRadius: "20px!important",
  },
  reject: {
    textTransform: "capitalize",
    width: 200,
    background: "red!important",
    borderRadius: "20px!important",
  },
  seeInvoice: {
    color: "#E77228",
    "&:hover": {
      color: "#E77228",
    },
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});

const schema = yup.object().shape({
  companyName: yup.string(),
  documentStatus: yup.string(),
});

const UploadedInvoicesContainer = () => {
  const { control, watch, reset, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const watchCompanyName = watch("companyName");

  const watchStatus = watch("documentStatus");

  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [deletedFilterKey, setDeletedFilterKey] = useState<string>("");
  const [isClearFilterKey, setIsClearFilterKey] = useState(0);
  const [filterData, setFilterData] = useState({
    companyName: undefined,
    documentStatus: undefined,
  });
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [uploadedInvoices, setUploadedInvoices] = useState<
    UploadedInvoicesStateType[]
  >([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    limit: 20,
    totalPage: 1,
  });

  useEffect(() => {
    setValue((`${deletedFilterKey}` as any), undefined);
  }, [deletedFilterKey]);

  useEffect(() => {
    const getPremadeInvoices = async () => {
      const data = {
        ...filterData,
        page: queryParams.page,
        limit: queryParams.limit,
      };
      setLoading(true);
      const response = await getInvoices(data);
      setLoading(false);

      if (response) {
        setUploadedInvoices(response?.data);
        setQueryParams({
          page: response?.pagination?.page,
          total: response?.pagination?.totalDocuments,
          totalPage: response?.pagination?.totalPage,
          limit: 20,
        });
      }
    };
    getPremadeInvoices();
  }, [queryParams.page, refetch, filterData]);

  const onUpdateInvoiceStatus = async (type: string, invoiceId: string) => {
    if (type === "processed") {
      setLoading(true);
      const processedData = {
        premadeInvoiceId: invoiceId,
        documentStatus: "PROCESSED",
      };
      const processed = await updateInvoice(processedData);
      setLoading(false);

      if (processed) {
        setRefetch((prev: any) => prev + 1);
      }
    } else {
      setLoading(true);
      const processedData = {
        premadeInvoiceId: invoiceId,
        documentStatus: "DECLINED",
      };
      const processed = await updateInvoice(processedData);
      setLoading(false);

      if (processed) {
        setRefetch((prev: any) => prev + 1);
      }
    }
  };

  const onFilter = () => {
    setQueryParams({
      ...queryParams,
      page:1
    })
    setFilterData({
      documentStatus: watchStatus ? (watchStatus as any) : undefined,
      companyName: watchCompanyName ? (watchCompanyName as any) : undefined,
    });
    setOpenFilterModal(false);
  };

  const renderTableBody = () => {
    return (
      <>
        {uploadedInvoices?.map((invoice, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell style={{ width: 200 }} component="th" scope="row">
                {invoice?.company[0]?.companyName}
              </TableCell>
              <TableCell style={{ width: 200 }} component="th" scope="row">
                <a
                  className={classes.seeInvoice}
                  target="_blank"
                  href={invoice.url}
                >
                  See invoice
                </a>
              </TableCell>
              <TableCell style={{ width: 200 }} component="th" scope="row">
                <Chip
                  className={
                    invoice.data.status === "PENDING"
                      ? classes.PendingChip
                      : invoice.data.status === "PROCESSED"
                      ? classes.ApprovedChip
                      : invoice.data.status === "DECLINED"
                      ? classes.RejectedChip
                      : ""
                  }
                  label={invoice.data.status}
                />
              </TableCell>
              <TableCell style={{ width: 200 }} component="th" scope="row">
                {moment(invoice.createdAt).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell component="th" scope="row">
                {invoice.data.status === "PENDING" ? (
                  <div className="row d-flex gap-2">
                    {" "}
                    <div className="col-12 d-flex justify-content-end">
                      <AdminButtonComponent
                        className={classes.approve}
                        onClick={() =>
                          onUpdateInvoiceStatus("processed", invoice._id)
                        }
                        title="Mark as processed"
                      />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                      <AdminButtonComponent
                        className={classes.reject}
                        onClick={() =>
                          onUpdateInvoiceStatus("declined", invoice._id)
                        }
                        title="Mark as declined"
                      />
                    </div>
                  </div>
                ) : null}
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderFilterModal = () => {
    return (
      <>
        <ControlledTextField
          control={control}
          name="companyName"
          textLabel="Company Name"
        />
        <ControlledComboBox
          className={classes.comboBox}
          options={["PENDING", "PROCESSED", "DECLINED"]}
          control={control}
          name="documentStatus"
          textLabel="Status"
        />

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setOpenFilterModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={onFilter}
            title="Submit"
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Uploaded Invoices | FruPro Admin Portal</title>
      </Helmet>
      <Sidebar pageTitle="Premade Invoices">
        {loading && <LoadingIndicator loading />}
        <TableComponent
          filterBadge={
            <FilterBadge
              isClearFilterKey={isClearFilterKey}
              setIsClearFilterKey={setIsClearFilterKey}
              filterData={filterData}
              setFilterData={setFilterData}
              setDeletedFilterKey={setDeletedFilterKey}
            />
          }
          onOpenModal={() => setOpenFilterModal(true)}
          showFilterIcon
          TableBodyComponent={renderTableBody}
          showRightButton={false}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          columns={columns}
        />
        <AdminModalComponent
          headerTitle="Filter Invoices"
          children={renderFilterModal()}
          openModal={openFilterModal}
          closeModal={() => setOpenFilterModal(false)}
        />
      </Sidebar>
    </>
  );
};

export default UploadedInvoicesContainer;
