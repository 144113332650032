import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { OrderStateType } from "../../orderStateType";
import {
  BankAccountsDefaultTypes,
  DefaultBankDetail,
} from "../../../CreateNewSurplus/components/BankInfo";
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { updateOrderBankInfo } from "../../../../services/surplus-allocation";

const useStyles = makeStyles({
  headerTitle: {
    fontSize: 20,
  },

  hr: {
    marginTop: 10,
    opacity: 0.07,
  },
});
const BankAccounts = ({
  invoiceId,
  defaultBankInfo,
  selectedBankDetailId,
  setSelectedBankDetailId,
  loading,
  bankInfos,
  setRefresh,
  orderData,
  setOrderData,
}: {
  invoiceId: string;
  defaultBankInfo: {
    accountName: string;
    bankName: string;
    iban: string;
    swift: string;
    sortCode: string;
    accountNo: string;
    _id: string;
    bankDetailDocumentId: string;
  };
  selectedBankDetailId: string;
  setSelectedBankDetailId: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  bankInfos?: BankAccountsDefaultTypes;
  orderData?: OrderStateType;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  setOrderData: React.Dispatch<
    React.SetStateAction<OrderStateType | undefined>
  >;
}) => {
  const classes = useStyles();
  const [loadingAction, setLoadingAction] = useState(false);

  const filteredDefaultBankInfosByCurrency: DefaultBankDetail[] | undefined =
    useMemo(() => {
      return bankInfos?.defaultBankDetails?.filter(
        (i) =>
          i.bankDetails.currency ===
          (orderData?.produces[0].currency.name === "POUND"
            ? "GBP"
            : orderData?.produces[0].currency.name)
      );
    }, [bankInfos?.defaultBankDetails, orderData?.produces]);

  //set Default bank info in invoice object

 
  useEffect(() => {
    if (orderData?._id) {
      
      const defaultBankId = filteredDefaultBankInfosByCurrency?.find(
        (i) =>
          i?.company_bank_details_id === defaultBankInfo?.bankDetailDocumentId
      );
      setSelectedBankDetailId(defaultBankId?.company_bank_details_id || "");
    }
  }, [
    orderData?._id,
    defaultBankInfo?.iban,
    filteredDefaultBankInfosByCurrency,
    setSelectedBankDetailId,
  ]);

  const onUpdateBankInfo = useCallback(
    async (bankId: string) => {
      let tempBankId = selectedBankDetailId;

      setSelectedBankDetailId(bankId);
      setLoadingAction(true);

      const data = {
        invoiceId: invoiceId,
        bankDetailsId: bankId,
      };
      const updated = await updateOrderBankInfo(data);
      setLoadingAction(false);

      if (updated?.success) {
        setRefresh((prev) => prev + 1);
      } else {
        setSelectedBankDetailId(tempBankId);
      }
    },

    [setSelectedBankDetailId, invoiceId, setRefresh, selectedBankDetailId]
  );
  return (
    <div className="p-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span className={classes.headerTitle}>Bank Accounts</span>
      </div>
      <hr className={classes.hr} />

      <div className="mt-3">
        {loading ? (
          <CircularProgress className="mt-2" size={20} />
        ) : (
          <div className="d-flex  flex-row gap-2 align-items-center">
            {orderData?._id ? (
              <div className="container ">
                {!filteredDefaultBankInfosByCurrency?.length ? (
                  <span>No account found</span>
                ) : (
                  <RadioGroup
                    className="mt-2"
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    {filteredDefaultBankInfosByCurrency?.map((item, index) => {
                      return (
                        <div className="mt-2" key={index}>
                          <FormControlLabel
                            disabled={loadingAction}
                            defaultChecked
                            checked={
                              item.company_bank_details_id ===
                              selectedBankDetailId
                            }
                            value={item.company_bank_details_id}
                            onChange={(e: any) =>
                              onUpdateBankInfo(item.company_bank_details_id)
                            }
                            control={
                              loadingAction &&
                              selectedBankDetailId ===
                                item.company_bank_details_id ? (
                                <CircularProgress
                                  className="mr-3 ml-3"
                                  size={18}
                                />
                              ) : (
                                <Radio style={{ color: "#E77228" }} />
                              )
                            }
                            label={item.bankDetails.title}
                          />
                        </div>
                      );
                    })}
                  </RadioGroup>
                )}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default BankAccounts;
