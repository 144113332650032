import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getUninvoicedSales = async (
  page: number,
  filterData: any,
  sort: any
) => {
  try {
    const response = await axiosApiInstance.post(
      `/tradabase/not-invoiced-sales-details`,
      {
        page: page,
        filters: filterData,
        sorting: sort,
      }
    );

    if(response)return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message || error?.message);
    }
  }
};


export const getFilterDatasUnInvoicedSales=async()=>{

 try {
  const response=await axiosApiInstance.get("/tradabase/not-invoiced-sales-details-filters")

  if(response)return response.data
 } catch (error) {
  if (axios.isAxiosError(error)) {
   toast.error(error.response?.data?.message || error?.message);
 }
 }
}