import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getTradabaseOrders = async (data: any) => {
  try {
    const response = await axiosApiInstance.post("/tradabase/get-list", {
      page: data?.page,
      filters: data?.filters,
      sorting: data?.sorting,
      limit: 20,
    });

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message || error?.message);
    }
  }
};

export const getTradabaseOrdersDetail = async (id:any) => {
  try {
    const response = await axiosApiInstance.post("/tradabase/get-list", {
   filters:{
    ids:[id],
   },
     page:1,
     limit:10
    });

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message || error?.message);
    }
  }
};

export const captureTradabaseOrder = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/update-surplus-invoice",
      {
        ...data,
      }
    );

    if (response) {
      toast.success("Captured successfully.")
      return response.data
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message || error?.message);
    }
  }
};

export const getTradabaseOrdersFilters = async () => {
  try {
    const response = await axiosApiInstance.get("/tradabase/get-list-filters");

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message || error?.message);
    }
  }
};



export const exportTradabaseOrders = async (filterData:any) => {
  try {
    const response = await axiosApiInstance.post("/tradabase/get-list-export-to-excel",{...filterData},{
      responseType: "arraybuffer",
    });

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message || error?.message);
    }
  }
};



export const applyPartialTradabaseOrders = async (data:any) => {
  try {
    const response = await axiosApiInstance.post("/finmid/admin/re-create-payment",{...data});

    if (response){
      toast.success("Partial payment applied successfully")
      return response.data
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message || error?.message);
    }
  }
};
