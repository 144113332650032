import React, { useMemo } from "react";
import TableComponent from "../../../components/TableComponent";
import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PermissionWrapper from "../../../components/PermissionWrapper";
import useSyncedData from "../../../hooks/useSyncedData";
import SearchTextField from "../../../components/SearchTextField";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";

export interface ProductType {
  _id: string;
  description: string;
  name: string;
  images?: ImagesEntity[] | null;
  subProductType?: SubProductTypeEntity[] | null;
  __v: number;
  createdAt: string;
  updatedAt: string;
}
export interface ImagesEntity {
  _id: string;
  name: string;
  path: string;
  size: number;
  isCover: boolean;
  thumbPath: string;
}
export interface SubProductTypeEntity {
  description?: string | null;
  _id: string;
  name: string;
  images?: ImagesEntity1[] | null;
}
export interface ImagesEntity1 {
  name: string;
  path: string;
  size: number | string;
  isCover: boolean;
  thumbPath: string;
  url?: string;
}

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});

const validationSchema = yup.object().shape({
  name: yup.string(),
  nameEdit: yup.string(),
  descriptionEdit: yup.string(),
  keyword: yup.string(),
});

const SubProductType = () => {
  const navigate = useNavigate();
  const { control, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      nameEdit: "",
      descriptionEdit: "",
      keyword: "",
    },
  });

  const watchKeyword = watch("keyword");
  const [debouncedKeyword, status] = useDebounce(watchKeyword, 500);
  const isPerformingSearch = status.isPending();

  const data = useSyncedData("product_types") as ProductType[];

  const subProductTypes = useMemo(() => {
    const flatArray = data
      ? data.flatMap((pType) =>
          (pType.subProductType || []).map((spType) => ({
            ...spType,
            productTypeId: pType._id,
          }))
        )
      : [];

    return debouncedKeyword
      ? flatArray.filter((itm) =>
          itm.name.toLowerCase().includes(debouncedKeyword.toLowerCase())
        )
      : flatArray;
  }, [data, debouncedKeyword]);

  const classes = useStyles();

  const onClickEditItem = (item: SubProductTypeEntity) => {
    navigate(`/subproduct-types/${item._id}`);
  };

  const renderTypeTableBody = () => {
    return (
      <>
        {subProductTypes.length === 0 && (
          <h1 className="text-center text-slate-400 py-8 ml-32">
            No subproduct types found.
          </h1>
        )}
        {subProductTypes.map((v, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {v?.name}
              </TableCell>
              <TableCell component="th" scope="row">
                <div className="d-flex justify-content-end mr-2">
                  <PermissionWrapper permission="TRADE_ACTION">
                    <i
                      onClick={() => onClickEditItem(v!)}
                      style={{ fontSize: 24 }}
                      className="ri-edit-line cursor-pointer"
                    ></i>
                  </PermissionWrapper>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderSearchTextField = () => {
    return <SearchTextField control={control} name="keyword" />;
  };

  return (
    <div>
      <TableComponent
        rightButtonPermission="TRADE_ACTION"
        headerButtonTitle="Add new Subproduct Type"
        isRightButtonFunction={true}
        rightButtonFunction={() => navigate("/subproduct-types/create")}
        loading={!subProductTypes || isPerformingSearch}
        showRightButton={true}
        TableBodyComponent={renderTypeTableBody}
        RenderSearchTextField={renderSearchTextField()}
        showFilterIcon={false}
        columns={[
          { name: "Name", id: "name" },
          { name: "", id: "action" },
        ]}
      />
    </div>
  );
};

export default SubProductType;
