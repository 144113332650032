import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useSyncedData from "../../hooks/useSyncedData";
import { ImagesEntity1, ProductType } from "./components/SubProductType";
import {
  addSubproductType,
  addSubproductTypeImages,
  editSubproductType,
  getImageUrl,
} from "../../services/variety-size-unit";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../components/ControlledTextField";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import ControlledComboBox from "../../components/ControlledComboBox";
import { uploadFile } from "../../services/common";
import { toast } from "react-toastify";
import CropModal from "./components/CropModal";

const validationSchema = yup.object().shape({
  name: yup.string().required("Subproduct type name is required."),
  description: yup.string(),
  productTypeId: yup
    .object()
    .shape({
      _id: yup.string(),
      name: yup.string(),
    })
    .optional(),
});

const SubProductTypeDetailContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isCreating] = useState(id === "create");
  const data = useSyncedData("product_types") as ProductType[];

  const { control, setValue, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      description: "",
      productTypeId: undefined,
    },
  });

  const [isProcessing, setProcessing] = useState(false);

  const watchProductType = watch("productTypeId");
  const watchName = watch("name");
  const watchDescription = watch("description");

  const productTypes = useMemo(() => {
    if (!isCreating) return null;

    return data.map((itm) => ({ name: itm.name, id: itm._id }));
  }, [isCreating, data]);

  const subProductType = useMemo(() => {
    if (isCreating) return null;

    const flatArray = data
      ? data.flatMap((pType) =>
          (pType.subProductType || []).map((spType) => ({
            ...spType,
            productTypeId: pType._id,
          }))
        )
      : [];

    return flatArray.find((itm) => itm._id === id);
  }, [data, id, isCreating]);

  const [image, setImage] = useState<ImagesEntity1>();
  const [uploadImage, setUploadImage] = useState<File>();
  const [imageLoading, setImageLoading] = useState(false);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageSrc(URL.createObjectURL(file));
      setCropModalOpen(true); // Open the crop modal
    }
  }

  const handleCropComplete = (croppedImage: File) => {
    setUploadImage(croppedImage);
    setImageSrc(null);
  };

  const onSubmit = useCallback(async () => {
    const isCreate = id === "create";
    if (isCreate && !watchProductType?._id) {
      return toast.error("Product type is required.");
    }
    if (!watchName) {
      return toast.error("Subproduct type name is required.");
    }
    const shouldUploadPhoto = !!uploadImage;

    try {
      setProcessing(true);
      let subProductTypeId = id;
      if (isCreate) {
        const createResponse = await addSubproductType(
          watchProductType!._id!,
          watchName!,
          watchDescription
        );

        subProductTypeId = createResponse.subProductType.find(
          (itm: any) => itm.name === watchName
        )._id;
      } else {
        await editSubproductType(
          subProductType?.productTypeId!,
          subProductType?._id!,
          watchName!,
          watchDescription ?? ""
        );
      }

      if (shouldUploadPhoto) {
        const signedUrl = await uploadFile(uploadImage, "subProduceTypes");
        await addSubproductTypeImages(subProductTypeId!, {
          ...signedUrl,
          isCover: true,
        });
      }
      navigate("/subproduct-types");
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
    }
  }, [
    id,
    navigate,
    subProductType?._id,
    subProductType?.productTypeId,
    uploadImage,
    watchDescription,
    watchName,
    watchProductType,
  ]);

  useEffect(() => {
    if (!subProductType) return;

    const getImages = async () => {
      try {
        setImageLoading(true);
        const response = await getImageUrl(subProductType.images?.[0]!);
        setImage(response);
      } catch (error) {
        console.error(error);
      } finally {
        setImageLoading(false);
      }
    };

    getImages();
    setValue("name", subProductType.name);
    setValue("description", subProductType.description ?? "");
  }, [subProductType, setValue]);

  return (
    <Sidebar
      pageTitle={isCreating ? "Create Subproduct Type" : "Edit Subproduct Type"}
    >
      <Box sx={{ width: "100%" }}>
        <Box marginTop={2}>
          <div className="flex flex-1 flex-col bg-white p-8 gap-4 rounded-md">
            {isCreating && (
              <ControlledComboBox
                options={[
                  ...productTypes!.map((itm) => ({
                    name: itm.name,
                    _id: itm.id,
                  })),
                ]}
                textLabel="Product Type"
                name="productTypeId"
                control={control}
                required
              />
            )}
            <ControlledTextField
              name="name"
              control={control}
              textLabel="Name"
              required
            />
            <ControlledTextField
              name="description"
              control={control}
              textLabel="Description"
            />

            <div className="gap-1 flex flex-col">
              <h1>Image</h1>
              {imageLoading ? (
                <div className="w-48 h-48 animate-pulse bg-slate-200" />
              ) : image?.url || uploadImage ? (
                <div className="flex gap-4">
                  <img
                    alt="product type"
                    src={
                      (uploadImage && URL.createObjectURL(uploadImage)) ??
                      image?.url ??
                      ""
                    }
                    className="w-48 h-48"
                  />
                  <div className="flex flex-col w-48 h-48 items-center justify-center gap-1 relative bg-slate-50">
                    <input
                      className="absolute top-0 opacity-0 left-0 w-48 h-48"
                      type={"file"}
                      onChange={onSelectFile}
                      accept="image/*"
                    />
                    <p className="text-center font-bold">
                      Click here to change image.
                    </p>
                    <p className="text-center font-thin text-xs">
                      Image must be at least 400x400 pixels
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col w-48 h-48 items-center justify-center gap-1 relative bg-slate-50">
                  <input
                    className="absolute top-0 opacity-0 left-0 w-48 h-48"
                    type={"file"}
                    accept="image/*"
                    onChange={onSelectFile}
                  />
                  <p className="text-center font-bold">
                    Click here to upload an image.
                  </p>
                  <p className="text-center font-thin text-xs">
                    Image must be at least 400x400 pixels
                  </p>
                </div>
              )}
            </div>

            <div className="flex self-end">
              <AdminButtonComponent
                onClick={() => {
                  onSubmit();
                }}
                title={isCreating ? "Save" : "Edit"}
                loading={isProcessing}
                disabled={isProcessing}
              />
            </div>
          </div>
        </Box>
      </Box>
      {imageSrc && (
        <CropModal
          isOpen={cropModalOpen}
          imageSrc={imageSrc}
          onClose={() => setCropModalOpen(false)}
          onCropComplete={handleCropComplete}
        />
      )}
    </Sidebar>
  );
};

export default SubProductTypeDetailContainer;
