import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getAdminLogs = async (page: number,filterData:any) => {
  try {
    const response = await axiosApiInstance.post("/admin/admin-logs", {
      ...filterData,
      page: page,
      limit: 10,
    });

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};


export const getAdminLogsDetail = async (id:string) => {
  try {
    const response = await axiosApiInstance.post("/admin/admin-log-detail", {
      adminLogId:id
    });

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};
