import { Permission, PermissionNameType } from "../permissions";
import { useAppSelector } from "./hooks";

export default function usePermissions() {
  const permissionsFromRedux = useAppSelector(
    (state) => state.auth.adminUserInfo.permissions
  );

  const permissions: Permission[] = permissionsFromRedux.map(
    (perm: PermissionNameType, idx) => {
      return { name: perm, _id: idx.toString() };
    }
  );
  
  const hasPermission = (permissionName: PermissionNameType) => {
    return !!permissions.find((perm) => perm.name === permissionName);
  };

 
  return { hasPermission };
}
