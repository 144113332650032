import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

interface updateCurrencyDataBodyType {
  code: string;
  createdAt: string;
  id: string;
  name: string;
  status: number;
  symbol: string;
  updatedAt: string;
}

export const getCurrencies = async () => {
  try {
    const response = await axiosApiInstance.get("/currencies");

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const updateCurrencyStatus = async (
  currencyId: string,
  data: updateCurrencyDataBodyType
) => {
  try {
    const response = await axiosApiInstance.put(
      `/currencies/${currencyId}/toggle-status`,
      {
        ...data,
      }
    );

    if (response) {
      toast.success("Currency status is changed succesfully.");
      return response.data;
    }
  } catch (error) {}
};
