import React, { ReactNode } from "react";
import TableComponent from "../../components/TableComponent";
import { adminUsersObjectType, paginationDataType } from "./interfaces";
import { TableRow, TableCell, IconButton, Tooltip } from "@mui/material";
import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PermissionWrapper from "../../components/PermissionWrapper";

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {},
  editGroup: {
    cursor: "pointer!important",
    color: "white!important",
    background: "#E77228!important",
  },
  hr: {
    opacity: 0.07,
  },
});
interface AdminUsersTableTypes {
  onOpenEditGroupModal: (group: adminUsersObjectType) => void;
  setOpenUnBlockConfirmationModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setOpenBlockReasonModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDeleteConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setAdminUserId: React.Dispatch<React.SetStateAction<string>>;
  queryParams: paginationDataType;
  setOpenAddNewAdminModal: React.Dispatch<React.SetStateAction<boolean>>;
  setQueryParams: React.Dispatch<React.SetStateAction<paginationDataType>>;
  adminUsersData: adminUsersObjectType[];
  fetchLoading:boolean
  renderSearchTextField?: ReactNode;
}

const AdminUsersTable: React.FC<AdminUsersTableTypes> = ({
  fetchLoading,
  queryParams,
  onOpenEditGroupModal,
  setOpenDeleteConfirmationModal,
  setOpenAddNewAdminModal,
  setOpenBlockReasonModal,
  setOpenUnBlockConfirmationModal,
  setAdminUserId,
  setQueryParams,
  adminUsersData,
  renderSearchTextField,
}) => {
  const classes = useStyles();

  const adminColumns = [
    { id: "email", name: "Email" },
    { id: "fullName", name: "FullName" },
    { id: "phoneNumber", name: "Phone Number" },
    { id: "group", name: "Groups" },
    { id: "status", name: "Status" },
    { id: "block", name: "" },
    { id: "delete", name: "" },
  ];

  const renderAdminTableBody = () => {
    return (
      <>
        {adminUsersData?.map((user, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {user.email}
              </TableCell>

              <TableCell component="th" scope="row">
                {user.fullName || "N/A"}
              </TableCell>

              <TableCell component="th" scope="row">
                {user.phoneNumber || "N/A"}
              </TableCell>
              <TableCell component="th" scope="row">
                <span>
                  <span className="fw-bold">Group Names :</span>{" "}
                  {user.groups.map((item) => item.name).join(",")}
                </span>
                <hr className={classes.hr} />

                <PermissionWrapper permission="ADMIN_USER_GROUP_ACCESS">
                  <Chip
                    onClick={() => onOpenEditGroupModal(user)}
                    className={classes.editGroup}
                    label="Edit admin's group"
                  />
                </PermissionWrapper>
              </TableCell>
              <TableCell component="th" scope="row">
                {user.status === "ACTIVATED" ? (
                  <Chip color="success" label={user.status || "N/A"} />
                ) : user.status === "BLOCKED" ? (
                  <Chip color="error" label={user.status || "N/A"} />
                ) : (
                  <Chip label={user.status || "N/A"} />
                )}
              </TableCell>

              <TableCell component="th" scope="row">
                {user.status === "ACTIVATED" ? (
                  <Tooltip title="Block">
                    <PermissionWrapper permission="ADMIN_USERS_BLOCK">
                      <IconButton
                        onClick={() => {
                          setOpenBlockReasonModal(true);
                          setAdminUserId(user.id);
                        }}
                      >
                        {" "}
                        <i className="ri-lock-line"></i>
                      </IconButton>
                    </PermissionWrapper>
                  </Tooltip>
                ) : user.status === "BLOCKED" ? (
                  <Tooltip title="Unblock">
                    <IconButton
                      onClick={() => {
                        setOpenUnBlockConfirmationModal(true);
                        setAdminUserId(user.id);
                      }}
                    >
                      {" "}
                      <i className="ri-lock-unlock-line"></i>
                    </IconButton>
                  </Tooltip>
                ) : null}
              </TableCell>

              <TableCell component="th" scope="row">
                <Tooltip title="Delete">
                  <PermissionWrapper permission="ADMIN_USERS_BLOCK">
                    <IconButton
                      onClick={() => {
                        setOpenDeleteConfirmationModal(true);
                        setAdminUserId(user.id);
                      }}
                    >
                      {" "}
                      <i
                        style={{ color: "rgb(246, 19, 61)" }}
                        className="ri-delete-bin-line"
                      ></i>
                    </IconButton>
                  </PermissionWrapper>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <TableComponent
    loading={fetchLoading}
      showRightButton={true}
      setOpenModal={setOpenAddNewAdminModal}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      headerButtonTitle="+ Add New Admin User"
      rightButtonPermission="ADMIN_USERS_ADD_NEW"
      RenderSearchTextField={renderSearchTextField}
      TableBodyComponent={renderAdminTableBody}
      showFilterIcon={false}
      columns={adminColumns}
    />
  );
};

export default AdminUsersTable;
