import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Sidebar } from "../../../components/Sidebar";
import { makeStyles } from "@mui/styles";
import { Box, Tab, Tabs } from "@mui/material";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import {
  CompanyDetailsStateType,
  ProductTypesStateType,
  CompanyTypesType,
  SubProductTypeStateType,
} from "./interfaces";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  getCompanyTypesForms,
  getProductTypes,
  uploadFile,
} from "../../../services/common";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import GeneralInfoContainer from "./GeneralInfoContainer";
import ConfidentialityInfoContainer from "./ConfidentialityInfoContainer";
import {
  getCompanyById,
  updateCompanyInfo,
  updateCompanyLogoCover,
} from "../../../services/company";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { useAppSelector } from "../../../hooks/hooks";
import AdminModalComponent from "../../../components/AdminModalComponent";
import OtpInput from "../../../components/AdminOtpInput";
import CreditHistory from "./CreditHistory";
import CompanyImages from "./CompanyImages";
import CertificatedDocuments from "./CertificatedDocuments";
import PermissionWrapper from "../../../components/PermissionWrapper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Documents from "./Documents";
import Addresses from "./Addresses";
import Videos from "./Videos";
import Preferences from "./Preferences";
const useStyles = makeStyles({
  mainBox: {
    marginTop: 10,
    background: "white",
    borderRadius: 20,
    padding: 20,
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#E77228",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#E77228",
    },
  },
  containerBox: {
    padding: 10,
  },
  tabsBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonBox: {
    display: "flex",
    gap: 5,
    flexDirection: "row",
  },
});

export interface SchemaType {
  companyName: string;
  companyType: any[];
  description: string;
  arrReportDue: number;
  recPeriod: number;
  companyForm: string;
  status: string;
  accNumber: string;
  nameOfBank: string;
  tradingYears: string;
  invoicePhoneNumber: string;
  invoiceEmail: string;
  invoiceFullName: string;
  specialities: any[];
  linkedin: string;
  facebook: string;
  instagram: string;
  twitter: string;
  companyWebsite: string;
  IBAN: string;
  companyTradingType: string;
  nameOfAcc: string;
  VAT: string;
  CRN: string;
  reqBy: string;
  reqDate: string;
  reqEmail: string;
  verifyDate: string;
  buyerInterests: any[];
  sellerInterests: any[];
  principalCountry: {
    id: number;
    name: string;
  };
  principalCity: string;
  principalState: string;
  principalPostCode: string;
  principalAddress: string;
  registerCountry: {
    id: number;
    name: string;
  };
  registerCity: string;
  registerState: string;
  registerPostCode: string;
  registerAddress: string;
  companyCode: string;
  BIC: string;
  paymentTerm: number;
  sortCode: string;
}

export interface AttachmentStateType {
  _id: string;
  name: string;
  path: string;
  size: number;
}

export interface VideosStateType {
  videoUrl: string;
  embedType: string;
}

export interface DocumentStateType {
  _id: string;
  name: string;
  path: string;
  size: number;
  url: string;
}

const schema = yup.object().shape({
  companyName: yup.string(),
  tradingYears: yup.string().nullable(),
  sortCode: yup.string().nullable(),
  companyType: yup.array(),
  status: yup.string(),
  description: yup.string(),
  accNumber: yup.string().nullable(),
  recPeriod: yup.number(),
  companyForm: yup.string().nullable(),
  specialities: yup.array(),
  nameOfBank: yup.string().nullable(),
  principalCountry: yup.object(),
  principalAddress: yup.string(),
  principalState: yup.string(),
  principalPostCode: yup.string(),
  principalCity: yup.string(),
  registerCountry: yup.object(),
  registerAddress: yup.string(),
  registerState: yup.string(),
  buyerInterests: yup.array(),
  registerPostCode: yup.string(),
  registerCity: yup.string(),
  IBAN: yup.string().nullable(),
  linkedin: yup.string().url(),
  twitter: yup.string().url(),
  instagram: yup.string().url(),
  facebook: yup.string().url(),
  VAT: yup.string(),
  reqBy: yup.string(),
  reqDate: yup.string(),
  sellerInterests: yup.array(),
  reqEmail: yup.string(),
  verifyDate: yup.string(),
  CRN: yup.string().nullable(),
  companyCode: yup.string(),
  companyWebsite: yup.string().url(),
  paymentTerm: yup.number(),
  BIC: yup.string().nullable(),
  arrReportDue: yup.number(),
  companyTradingType: yup.string().nullable(),
  nameOfAcc: yup.string().nullable(),
  invoicePhoneNumber: yup.string(),
  invoiceEmail: yup.string().email(),
  invoiceFullName: yup.string(),
});

const CompanyDetailsContainer: React.FC = () => {
  const classes = useStyles();
  const countries = useAppSelector((state) => state.auth.countries);
  const user = useAppSelector((state) => state.auth.adminUserInfo);

  const newCountries = countries.map((item) => ({
    id: item.geonameId,
    name: item.countryName,
  }));

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyForm: "",
      tradingYears: "",
      companyTradingType: "",
      sellerInterests: [],
    },
  });

  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [openTwoFaModal, setOpenTwoFaModal] = useState<boolean>(false);
  const [productTypes, setProductTypes] = useState<ProductTypesStateType[]>([]);
  const [subProductTypes, setSubProductTypes] = useState<
    SubProductTypeStateType[]
  >([]);
  const [companyTypes, setCompanyTypes] = useState<CompanyTypesType[]>([]);
  const [companyForms, setCompanyForms] = useState<string[]>([]);
  const [value, setValue] = useState<number>(1);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [videos, setVideos] = useState<VideosStateType[]>([]);
  const [attachments, setAttachments] = useState<AttachmentStateType[]>([]);
  const [documents, setDocuments] = useState<DocumentStateType[]>([]);
  const [selectedLogo, setSelectedLogo] = useState();
  const [selectedCover, setSelectedCover] = useState();
  const [companyData, setCompanyData] = useState<CompanyDetailsStateType>();
  const [canAllocateSurplus, setCanAllocateSurplus] = useState<boolean>(false);
  const [confirmAllocation, setConfirmAllocation] = useState<boolean>(false);
  const [isSelfBilling, setIsSelfBilling] = useState<boolean>(false);
  const [displayCertificates, setDisplayCertificates] =
    useState<boolean>(false);
  const [displayGrowerInfoOnInvoice, setDisplayGrowerInfoOnInvoice] =
    useState<boolean>(false);
  const [displaySupplierInfoOnInvoice, setDisplaySupplierInfoOnInvoice] =
    useState<boolean>(false);
  const [refreshRequest, setRefreshRequest] = useState<number>(0);

  const foundPhoneCode = useMemo(() => {
    // Get the seller's phone number
    const phoneNumber = companyData?.invoiceContactInfo?.seller?.phoneNumber;

    // Ensure the phoneNumber exists
    if (!phoneNumber) return undefined;

    // Find the country based on the phoneNumber
    return countries.find((c) => {
      const countryCode = c.phone.includes("+") ? c.phone : `+${c.phone}`;
      return phoneNumber.startsWith(countryCode);
    });
  }, [companyData?.invoiceContactInfo?.seller?.phoneNumber, countries]);

  const selectedPhoneNumberCountry: string | undefined =
    companyData?.principalPlaceCountry?.code &&
    countries.find(
      (c) => c.geonameId === parseInt(companyData?.principalPlaceCountry?.code)
    )?.phone;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onSelectCover = (e: any) => {
    setSelectedCover(e);
  };
  const onSelectLogo = (e: any) => {
    setSelectedLogo(e);
  };

  useEffect(() => {
    const getCompanyTypeForm = async () => {
      const response = await getCompanyTypesForms();
      if (response) {
        setCompanyTypes(
          response?.types?.map((item: any) => ({
            _id: item.id,
            name: item?.name,
            code: item.code,
          }))
        );
        setProductTypes(
          response?.specialities?.map((item: any) => ({
            _id: item.id,
            name: item?.name,
            code: item.code,
          }))
        );
        setCompanyForms(response.company_form);
      }
    };
    getCompanyTypeForm();
  }, []);

  useEffect(() => {
    const getProduceTypes = async () => {
      const response = await getProductTypes();

      if (response) {
        const newArr = subProductTypes.concat(
          ...response.map((item: any) => item.subProductType)
        );
        setSubProductTypes(newArr);
      }
    };
    getProduceTypes();
  }, []);

  useEffect(() => {
    const getDetails = async () => {
      setFetchLoading(true);
      const response = await getCompanyById(id ? id : "");
      if (response) {
        setCompanyData(response);
      }
      setFetchLoading(false);
    };
    getDetails();
  }, [id, refreshRequest]);

  useEffect(() => {
    if (companyData?.id) {
      const defaultCountry = newCountries?.find((item) =>
        companyData?.principalPlaceCountry.code
          ? item.id === parseInt(companyData?.principalPlaceCountry.code)
          : item.name === companyData?.principalPlaceCountry?.name?.trim()
      );

      const defaultRegisterCountry = newCountries.find((item) =>
        companyData?.principalPlaceCountry.code
          ? item.id === parseInt(companyData?.registerCountry.code)
          : item.name === companyData?.registerCountry?.name?.trim()
      );

      setVideos(companyData.videos || []);
      setSelectedCountry(foundPhoneCode?.phone || "");
      setDisplayCertificates(companyData?.displayCertificates);
      setDisplayGrowerInfoOnInvoice(companyData?.displayGrowerInfoOnInvoice);
      setDisplaySupplierInfoOnInvoice(
        companyData?.displaySupplierInfoOnInvoice
      );
      setAttachments(companyData.attachment || []);
      setConfirmAllocation(companyData.preconfirmAllocations);
      setDocuments(companyData.documents || []);
      setCanAllocateSurplus(companyData.canAllocateSurplus);
      setIsSelfBilling(companyData.selfBillingPermission);
      reset({
        VAT: companyData.VATNumber,
        buyerInterests: companyData?.buyerInterests,
        sellerInterests: companyData?.sellerInterests,
        invoiceEmail: companyData.invoiceContactInfo?.buyer?.email,
        invoiceFullName: companyData.invoiceContactInfo?.buyer?.fullName,
        invoicePhoneNumber:
          companyData.invoiceContactInfo?.buyer.phoneNumber?.split(
            foundPhoneCode?.phone||""
          )[1],
        principalCity: companyData.principalPlaceCity?.name,
        principalCountry: defaultCountry,
        tradingYears: companyData?.tradingYears?.display || "",
        principalState: companyData?.principalPlaceState?.name,
        principalPostCode: companyData?.principalPlacePostCode,
        principalAddress: companyData?.principalPlaceAddress,
        registerState: companyData.registerState?.name,
        registerCity: companyData.registerCity?.name,
        registerCountry: defaultRegisterCountry,
        registerPostCode: companyData?.registerPostCode,
        registerAddress: companyData?.registerAddress,
        CRN: companyData?.companyHouseRegistration,
        companyCode: companyData?.code,
        BIC: companyData?.swiftCode,
        paymentTerm: companyData?.paymentTerm,
        arrReportDue: companyData?.arrivalDueDay,
        IBAN: companyData?.ibanNumber,
        sortCode: companyData?.sortCode,
        linkedin: companyData?.socialMedia?.linkedin,
        facebook: companyData.socialMedia?.facebook,
        twitter: companyData.socialMedia?.twitter,
        instagram: companyData.socialMedia?.instagram,
        companyWebsite: companyData.socialMedia?.companyWebsite,
        recPeriod: companyData?.arrivalDueHour,
        nameOfAcc: companyData?.bankAccountName,
        status:
          companyData.status === "VERIFIED"
            ? "Full Trading Account"
            : "View Only",
        nameOfBank: companyData.bankName,
        description: companyData.description,
        companyForm: companyData.companyForm,
        companyName: companyData.companyName,
        companyType: companyData.types,
        accNumber: companyData.accountNumber,
        specialities: companyData.specialities,
        companyTradingType: companyData.tradingType,
      });
    }
  }, [companyData?.id, newCountries.length]);

  useEffect(() => {
    document.getElementById("verifyOTPCompany")?.focus();
  }, [openTwoFaModal]);

  // useEffect(() => {
  //   if (otp.length === 6) {
  //     const onVerifyTwoFa = async () => {
  //       setLoading(true);
  //       const response = await VerifyTwoFa(otp, user.email);
  //       setLoading(false);

  //       if (response === 200) {
  //         setOtp("");
  //         setOpenTwoFaModal(false);
  //         handleSubmit(onUpdateCompanyInfo)();
  //       }
  //     };
  //     onVerifyTwoFa();
  //   }
  // }, [otp]);

  const onUpdateCompanyInfo = async (data: any) => {
    setLoading(true);
    const res = await updateCompanyInfo(
      data,
      id,
      attachments,
      videos,
      documents,
      confirmAllocation,
      isSelfBilling,
      selectedCountry,
      canAllocateSurplus,
      displayCertificates,
      displayGrowerInfoOnInvoice,
      displaySupplierInfoOnInvoice
    );
    setCompanyData(res);
    setLoading(false);
  };

  const onUpdateCoverLogo = useCallback(
    async (type: string, data: any) => {
      if (type === "cover") {
        setLoading(true);
        const file = await uploadFile(data, "company");

        const finalData = {
          coverImage: file,
          companyId: companyData?.id || "",
        };
        await updateCompanyLogoCover(finalData);
        setLoading(false);
        setRefreshRequest((prev) => prev + 1);
      } else {
        setLoading(true);
        const file = await uploadFile(data, "company");
        const finalData = {
          logo: file,
          companyId: companyData?.id || "",
        };
        await updateCompanyLogoCover(finalData);
        setLoading(false);
        setRefreshRequest((prev) => prev + 1);
      }
    },
    [companyData?.id]
  );

  const renderContainers = () => {
    switch (value) {
      case 1:
        return (
          <>
            <GeneralInfoContainer
              onUpdateCoverLogo={onUpdateCoverLogo}
              displaySupplierInfoOnInvoice={displaySupplierInfoOnInvoice}
              setDisplaySupplierInfoOnInvoice={setDisplaySupplierInfoOnInvoice}
              displayGrowerInfoOnInvoice={displayGrowerInfoOnInvoice}
              displayCertificates={displayCertificates}
              setDisplayCertificates={setDisplayCertificates}
              setDisplayGrowerInfoOnInvoice={setDisplayGrowerInfoOnInvoice}
              isSelfBilling={isSelfBilling}
              setIsSelfBilling={setIsSelfBilling}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              videos={videos}
              setVideos={setVideos}
              documents={documents}
              setDocuments={setDocuments}
              subProductTypes={subProductTypes}
              errors={errors}
              setAttachments={setAttachments}
              attachments={attachments}
              setCanAllocateSurplus={setCanAllocateSurplus}
              canAllocateSurplus={canAllocateSurplus}
              confirmAllocation={confirmAllocation}
              setConfirmAllocation={setConfirmAllocation}
              companyForms={companyForms}
              companyTypes={companyTypes}
              companyData={companyData}
              productTypes={productTypes}
              control={control}
            />
          </>
        );
      case 2:
        return (
          <>
            <ConfidentialityInfoContainer
              countries={newCountries}
              companyData={companyData}
              control={control}
              errors={errors}
            />
          </>
        );
      // case 3:
      //   return (
      //     <>
      //       <SubscriptionInfoContainer
      //         companyData={companyData}
      //         control={control}
      //         errors={errors}
      //       />
      //     </>
      //   );

      case 4:
        return (
          <>
            <CreditHistory
              setRefreshRequest={setRefreshRequest}
              refreshRequest={refreshRequest}
              companyData={companyData}
            />
          </>
        );
      case 5:
        return (
          <>
            <CompanyImages companyId={companyData?.id || ""} />
          </>
        );
      case 6:
        return (
          <>
            <Videos
              setRefreshRequest={setRefreshRequest}
              videos={companyData?.videos || []}
            />
          </>
        );
      case 7:
        return (
          <>
            <CertificatedDocuments
              refresh={refreshRequest}
              setRefresh={setRefreshRequest}
              companyData={companyData}
            />
          </>
        );

      case 8:
        return (
          <>
            <Documents
              loading={loading}
              setLoading={setLoading}
              refresh={refreshRequest}
              setRefresh={setRefreshRequest}
              companyData={companyData}
            />
          </>
        );
      case 9:
        return (
          <>
            <Addresses
              countries={countries}
              loading={loading}
              setLoading={setLoading}
              refresh={refreshRequest}
              setRefresh={setRefreshRequest}
              companyData={companyData}
            />
          </>
        );
      case 10:
        return (
          <>
            <Preferences
              //@ts-ignore
              notification={companyData?.notification}
              companyId={companyData?.id || ""}
            />
          </>
        );
    }
  };

  const renderTwoFaModalBody = () => {
    return (
      <>
        <div>
          <OtpInput
            id="verifyOTPCompany"
            differentBackground={true}
            numInputs={6}
            value={otp}
            onChange={setOtp}
          />
        </div>
      </>
    );
  };

  return (
    <Sidebar
      renderButton={
        value !== 4 &&
        value !== 5 &&
        value !== 6 &&
        value !== 7 &&
        value !== 8 &&
        value !== 9 &&
        value !== 10 ? (
          <Box>
            <PermissionWrapper permission="COMPANY_UPDATE">
              <AdminButtonComponent
                title="Update"
                onClick={() => handleSubmit(onUpdateCompanyInfo)()}
              />
            </PermissionWrapper>
          </Box>
        ) : null
      }
      pageTitle="Company Details"
    >
      <Box className={classes.mainBox}>
        {loading && <LoadingIndicator loading />}
        {fetchLoading ? (
          <Skeleton count={1} style={{ height: 600 }} />
        ) : (
          <>
            <Box className={classes.tabsBox}>
              <Tabs
                className={classes.tabs}
                value={value}
                onChange={handleChange}
              >
                <Tab value={1} label="General" />
                <Tab value={2} label="Confidentiality" />
                {/* <Tab value={3} label="Subscription Details" /> */}
                <Tab value={4} label="Credit History" />
                <Tab value={5} label="Images" />
                <Tab value={6} label="Videos" />
                <Tab value={7} label="Certifications" />
                <Tab value={8} label="Documents" />
                <Tab value={9} label="Addresses" />
                <Tab value={10} label="Preferences" />
              </Tabs>
            </Box>
            <Box className={classes.containerBox}>{renderContainers()}</Box>
          </>
        )}
        <AdminModalComponent
          headerTitle="Verify Your Code"
          openModal={openTwoFaModal}
          children={renderTwoFaModalBody()}
          closeModal={() => setOpenTwoFaModal(false)}
        />
      </Box>
    </Sidebar>
  );
};

export default CompanyDetailsContainer;
