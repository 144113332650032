import React, { useEffect } from "react";
import { CompanyRegDetailsStateType } from "./interfaces";
import ControlledTextField from "../../components/ControlledTextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Chip } from "@mui/material";

const useStyles = makeStyles({
  inputBox: {
    display: "flex",
    gap: 10,
    padding: 10,
    alignItems: "center",
  },
  requestChip: {
    background: "#E77228 !important",
    color: "white !important",
  },
  errorChip: {
    background: "red!important",
    color: "white !important",
  },
  approvedChip: {
    background: "#69CDAE !important",
    color: "white !important",
  },
  infoChip: {
    background: "#007bff !important",
    color: "white !important",
  },
});

interface PropsType {
  selectedCompanyDetails: CompanyRegDetailsStateType | undefined;
}

interface SchemaType {
  comHouseReg: string;
  vatNum: string;
  requestBy: string;
  requestDate: string;
  requestEmail: string;
  country: string;
  state: string;
  city: string;
  postCode: string;
  address: string;
  nameAcc: string;
  sortCode: string;
  accNumber: string;
  bankName: string;
  iban: string;
  bic: string;
  paymentTerm: number;
  finalRec: number;
  arrReport: number;
  rAddress: string;
  rState: string;
  rCity: string;
  rPostCode: string;
  rCountry: string;
}

const schema = yup.object().shape({
  comHouseReg: yup.string(),
  vatNum: yup.string(),
  requestBy: yup.string(),
  address: yup.string(),
  state: yup.string(),
  city: yup.string(),
  postCode: yup.string(),
  country: yup.string(),
  rAddress: yup.string(),
  rState: yup.string(),
  rCity: yup.string(),
  rPostCode: yup.string(),
  rCountry: yup.string(),
  requestEmail: yup.string(),
  requestDate: yup.string(),
  nameAcc: yup.string(),
  accNumber: yup.string(),
  sortCode: yup.string(),
  bankName: yup.string(),
  iban: yup.string(),
  bic: yup.string(),
  paymentTerm: yup.number(),
  arrReport: yup.number(),
  finalRec: yup.number(),
});

const RegistrationDetailsModal: React.FC<PropsType> = ({
  selectedCompanyDetails,
}) => {
  const classes = useStyles();
  const { control, reset } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      comHouseReg: selectedCompanyDetails?.company?.companyHouseRegistration,
      vatNum: selectedCompanyDetails?.company?.VATNumber,
      requestBy: selectedCompanyDetails?.user?.fullName,
      requestEmail: selectedCompanyDetails?.user?.email,
      requestDate: moment(selectedCompanyDetails?.createdAt).format(
        "DD/MM/YYYY"
      ),
      country: selectedCompanyDetails?.company?.principalPlaceCountry.name,
      state: selectedCompanyDetails?.company?.principalPlaceState?.name,
      city: selectedCompanyDetails?.company?.principalPlaceCity?.name,
      address: selectedCompanyDetails?.company?.principalPlaceAddress,
      postCode: selectedCompanyDetails?.company?.principalPlacePostCode,
      rCountry: selectedCompanyDetails?.company?.registerCountry?.name,
      rState: selectedCompanyDetails?.company?.registerState?.name,
      rCity: selectedCompanyDetails?.company?.registerCountry?.name,
      rAddress: selectedCompanyDetails?.company?.registerAddress,
      rPostCode: selectedCompanyDetails?.company?.principalPlacePostCode,
      nameAcc: selectedCompanyDetails?.company?.bankAccountName,
      accNumber: selectedCompanyDetails?.company?.accountNumber,
      sortCode: selectedCompanyDetails?.company?.sortCode,
      bankName: selectedCompanyDetails?.company?.bankName,
      iban: selectedCompanyDetails?.company?.ibanNumber,
      bic: selectedCompanyDetails?.company?.swiftCode,
      paymentTerm: selectedCompanyDetails?.company?.paymentTerm,
      finalRec: 0,
      arrReport: selectedCompanyDetails?.company?.arrivalDueDay,
    });
  }, [selectedCompanyDetails]);
  return (
    <div className="container">
      <div className="row">
        <div>
          <h4>Company Registration Details</h4>

          <div className={classes.inputBox}>
            <div className="col-5">
              <ControlledTextField
                disabled
                textLabel="Company House Registration"
                control={control}
                name="comHouseReg"
              />
            </div>
            <div className="col-5">
              <ControlledTextField
                disabled
                textLabel="VAT Number"
                control={control}
                name="vatNum"
              />
            </div>
            <div className="col-2">
              {selectedCompanyDetails?.status === "REJECT" ? (
                <Chip
                  className={classes.errorChip}
                  label={selectedCompanyDetails?.status}
                />
              ) : selectedCompanyDetails?.status === "PENDING" ? (
                <Chip
                  className={classes.infoChip}
                  label={selectedCompanyDetails?.status}
                />
              ) : (
                <Chip
                  className={classes.approvedChip}
                  label={selectedCompanyDetails?.status}
                />
              )}
            </div>
          </div>
        </div>
        <hr />
        <div>
          <h4>Request Information</h4>
          <div className={classes.inputBox}>
            <div className="col-3">
              <ControlledTextField
                disabled
                textLabel="Request By"
                control={control}
                name="requestBy"
              />
            </div>
            <div className="col-3">
              <ControlledTextField
                disabled
                textLabel="Request Email"
                control={control}
                name="requestEmail"
              />
            </div>
            <div className="col-3">
              <ControlledTextField
                disabled
                textLabel="Request Date"
                control={control}
                name="requestDate"
              />
            </div>
            <div className="col-3">
              <Chip
                className={classes.requestChip}
                label={selectedCompanyDetails?.requestType}
              />
            </div>
          </div>
        </div>
        <hr />
        <div>
          <h4>Principal Place Of Business</h4>
          <div className={classes.inputBox}>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Country"
                control={control}
                name="country"
              />
            </div>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="State"
                control={control}
                name="state"
              />
            </div>

            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="City"
                control={control}
                name="city"
              />
            </div>
          </div>
          <div className={classes.inputBox}>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Post Code"
                control={control}
                name="postCode"
              />
            </div>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Address"
                control={control}
                name="address"
              />
            </div>
          </div>
        </div>
        <hr />
        <div>
          <h4>Banking Account</h4>
          <div className={classes.inputBox}>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Name Of Account"
                control={control}
                name="nameAcc"
              />
            </div>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Sort Code"
                control={control}
                name="sortCode"
              />
            </div>

            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Account Number"
                control={control}
                name="accNumber"
              />
            </div>
          </div>
          <div className={classes.inputBox}>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Bank Name"
                control={control}
                name="bankName"
              />
            </div>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="IBAN Number"
                control={control}
                name="iban"
              />
            </div>

            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="BIC(Swift Code)"
                control={control}
                name="bic"
              />
            </div>
          </div>
          <div className={classes.inputBox}>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Payment Terms"
                control={control}
                name="paymentTerm"
              />
            </div>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Arrival Report Due"
                control={control}
                name="arrReport"
              />
            </div>

            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Final Reconciliation Period"
                control={control}
                name="finalRec"
              />
            </div>
          </div>
        </div>
        <hr />
        <div>
          <h4>Registered Address</h4>

          <div className={classes.inputBox}>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Country"
                control={control}
                name="rCountry"
              />
            </div>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="State"
                control={control}
                name="rState"
              />
            </div>

            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="City"
                control={control}
                name="rCity"
              />
            </div>
          </div>
          <div className={classes.inputBox}>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Post Code"
                control={control}
                name="rPostCode"
              />
            </div>
            <div className="col-4">
              <ControlledTextField
                disabled
                textLabel="Address"
                control={control}
                name="rAddress"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationDetailsModal;
