import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../components/ControlledTextField";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sendNewPassword, verifyNewPassword } from "../../services/auth";
import LoadingIndicator from "../../components/LoadingIndicator";
import { PasswordField } from "../../components/PasswordTextField";

export const PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;

const schema = yup.object().shape({
  firstName: yup.string().required("This field is required"),
  lastName: yup.string().required("This field is required"),
  password: yup
    .string()
    .matches(
      PASSWORD_REGEX,
      "*Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    )
    .required("This is required"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), undefined],
      "*Password and Confirm Password must be match"
    )
    .matches(
      PASSWORD_REGEX,
      "*Confirm Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    )
    .required("*This is required"),
});

const useStyles = makeStyles({
  backTo: {
    color: "#E77228",
  },
  submit: {
    width: "100%",
  },
  button: {
    width: 600,
  },
  header: {
    color: "#E77228",
    textAlign: "center",
    fontSize: 26,
  },
  background: {
    backgroundImage: "url(./static/svg/orange_main_blur.svg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    backgroundPosition: "center",
    height: "100% !important",
    width: "100%",
  },
  passwordCard: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  card: {
    height: "auto",
    padding: "40px 40px 24px 40px",
    background: "rgba(249, 249, 249, 0.9)",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    backdropFilter: "blur(15.5px)",
    borderRadius: "20px",
  },
});

const CreatePasswordContainer = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const classes = useStyles();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [showForm, setShowForm] = useState(false);

  const activeCode = searchParams.get("activeCode"); // "text"


  useEffect(() => {
    if (activeCode) {
      const verifyCode = async () => {
        setLoading(true);
        const response = await verifyNewPassword(activeCode);
        setLoading(false);

        if (response === 204) {
          setShowForm(true);
        }
      };
      verifyCode();
    }
  }, [activeCode]);

  const onVerify = async (formData: any) => {
    const data = {
      password: formData?.password,
      activeCode,
      firstName: formData?.firstName,
      lastName: formData?.lastName,
    };

    setLoading(true);
    const response = await sendNewPassword(data);
    setLoading(false);

    if (response === 204) {
      navigate("/login");
    }
  };
  return (
    <>
      <Helmet>
        <title>Create Password | FruPro Admin Portal</title>
      </Helmet>
      {loading && <LoadingIndicator loading />}
      <div className={classes.background}>
        <div className={classes.passwordCard}>
          <div className={classes.card}>
            <p className={classes.header}>Create Password</p>
            <span>
              Valid Password must be at least 8 characters including number,
              uppercase letter, lowercase letter and special special characters
            </span>

            {showForm ? (
              <div className="row g-5">
                <ControlledTextField
                  textLabel="First Name"
                  helperText={errors.firstName?.message}
                  error={!!errors.firstName}
                  name="firstName"
                  control={control}
                />

                <ControlledTextField
                  textLabel="Last Name"
                  helperText={errors.lastName?.message}
                  error={!!errors.lastName}
                  name="lastName"
                  control={control}
                />

                <PasswordField
                isPasswordTextField
                  textLabel="Password"
                  helperText={errors.password?.message}
                  error={!!errors.password}
                  name="password"
                  control={control}
                />

                <PasswordField
                        
                  textLabel="Confirm Password"
                  helperText={errors.confirmPassword?.message}
                  error={!!errors.confirmPassword}
                  name="confirmPassword"
                  control={control}
                />
              </div>
            ) : null}
            {showForm ? (
              <>
                <div className="mt-5">
                  <AdminButtonComponent
                    className={classes.submit}
                    title="Submit"
                    onClick={handleSubmit(onVerify)}
                  />
                </div>
                <div style={{ textAlign: "center" }} className="mt-3">
                  <a href="/login" className={`${classes.backTo}`}>
                    Back to sign in
                  </a>
                </div>
              </>
            ) : (
              <div className="mt-3">
                <a href="/login">
                  <AdminButtonComponent
                    onClick={() => null}
                    className={classes.submit}
                    title="Back to sign in"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePasswordContainer;
