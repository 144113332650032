import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SocketData } from "../types/SocketData";

type WsData = {
  version: number;
  data: any[];
};

type WsDataStateType = {
  [key in SocketData]?: WsData;
};

const initialState: WsDataStateType = {};

const wsDataSlice = createSlice({
  name: "wsData",
  initialState,
  reducers: {
    setWsDataVersion: <T extends keyof WsDataStateType>(
      state: WsDataStateType,
      action: PayloadAction<{ key: T; version: number }>
    ) => {
      const { key, version } = action.payload;
      state[key] = {
        ...state[key],
        version,
      };
    },
    insertWsData: <T extends keyof WsDataStateType>(
      state: WsDataStateType,
      action: PayloadAction<{ key: T; data: any }>
    ) => {
      const { key, data: payloadData } = action.payload;
      state[key] = {
        ...state[key],
        data: [...(state[key]?.data ?? []), payloadData],
      };
    },
    updateWsData: <T extends keyof WsDataStateType>(
      state: WsDataStateType,
      action: PayloadAction<{ key: T; items: { id: string; data: any }[] }>
    ) => {
      const { key, items } = action.payload;

      if (!state[key]) {
        state[key] = { version: 0, data: [] };
      }

      items.forEach(({ id, data: payloadData }) => {
        const index = state[key]?.data.findIndex((itm) => itm._id === id) ?? -1;
        if (index !== -1) {
          state[key]!.data[index] = payloadData;
        } else {
          state[key]!.data.push(payloadData);
        }
      });
    },
    deleteWsData: <T extends keyof WsDataStateType>(
      state: WsDataStateType,
      action: PayloadAction<{ key: T; ids: string[] }>
    ) => {
      const { key, ids } = action.payload;
      state[key]!.data = state[key]!.data.filter(
        (itm) => !ids.includes(itm._id)
      );
    },
    setWsData: <T extends keyof WsDataStateType>(
      state: WsDataStateType,
      action: PayloadAction<{ key: T; value: WsData }>
    ) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetWsData: () => initialState,
  },
});

export default wsDataSlice.reducer;

export const {
  setWsData,
  resetWsData,
  deleteWsData,
  insertWsData,
  setWsDataVersion,
  updateWsData,
} = wsDataSlice.actions;
