import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface ActionMenuItem {
  label: string;
  onClick: (...params: any) => void;
  destructive?: boolean;
}

interface ActionMenuProps {
  items: ActionMenuItem[];
  label?: string | React.ReactNode;
}

export default function ActionMenu({
  items,
  label = "Actions",
}: ActionMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onMenuItemClick = React.useCallback((item: ActionMenuItem) => {
    setAnchorEl(null);
    item.onClick();
  }, []);

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={`action-${item.label}-${index}`}
            sx={{
              "&:hover": {
                backgroundColor: item.destructive ? "red" : "#e8e8e8",
                color: item.destructive ? "white" : "#000",
              },
            }}
            onClick={() => onMenuItemClick(item)}
          >
            {item.label}
          </MenuItem>
        ))}
        {items.length === 0 && (
          <MenuItem disabled>No actions available</MenuItem>
        )}
      </Menu>
    </div>
  );
}
