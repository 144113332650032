import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const sendPushNotification = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      "admin/send-push-notification",
      { ...data }
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getNotifications = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/push-notification-summary",
      { ...data }
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const getNotificationItemWithId = async (id:string) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/push-notification-details",
      { push_notification_id:id }
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};


export const sendNotificationAgain = async (id:string) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/push-notification-re-send",
      { push_notification_receipent_id:id }
    );

    if (response) {
      toast.success("Notification sent successfully.")
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
