import {
  Box,
  CircularProgress,
  Collapse,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { TradesForeType } from "./ForecastedTrades";
import NumberFormatComponent from "../../components/NumberFormatComponent";
import { useAppSelector } from "../../hooks/hooks";
import ControlledTextField from "../../components/ControlledTextField";
import { updateForecastedPrices } from "../../services/forecasted-trades";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Table } from "react-bootstrap";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { addNewNote } from "../../services/notes";
import AdminModalComponent from "../../components/AdminModalComponent";

const schema = yup.object().shape({
  noteText: yup.string(),
  SalesRevenue: yup.string(),
  SalesCost: yup.string(),
  LotCharges: yup.string(),
  TransferCost: yup.string(),
  ServiceCost: yup.string(),
  ProductCost: yup.string(),
});

const useStyles = makeStyles({
  editPen: {
    cursor: "pointer",
    fontSize: 20,
  },
  textField: {
    background: "#F8F8F8",
    fontSize: 20,
    borderRadius: 10,
    border: "none !important",
    width: "100px",
    height: 30,
    padding: 15,
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red !important",
    },
    "& .MuiInputBase-root": {
      minHeight: 30,
      width: "auto",
      borderColor: "black !important",
      border: "0.1px solid black !important",
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  textFieldGreen: {
    background: "#F8F8F8",
    fontSize: 20,
    borderRadius: 10,
    border: "none !important",
    width: "100px",
    height: 30,
    padding: 15,
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red !important",
    },
    "& .MuiInputBase-root": {
      minHeight: 30,
      width: "auto",

      border: "1px solid #69cdae!important",
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  textFieldRed: {
    background: "#F8F8F8",
    fontSize: 20,
    borderRadius: 10,
    border: "none !important",
    width: "100px",
    height: 30,
    padding: 15,
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red !important",
    },
    "& .MuiInputBase-root": {
      minHeight: 30,
      width: "auto",

      border: "1px solid red!important",
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  greenColor: {
    color: "#69CDAE",
  },
  redColor: {
    color: "red",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  noteItem: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    minHeight: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    background: "#f8f8f8",
    borderRadius: 10,
    padding: 20,
    width: "100%",
  },
  seeAllNotes: {
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 600,
    color: "#E77228",
    "&:hover": {
      opacity: 0.7,
    },
  },
});
const TableComponentForecasted = ({
  trades,
  setTrades,
  setRefresh,
  index,
  trade,
}: {
  trades: TradesForeType[];
  setTrades: React.Dispatch<React.SetStateAction<TradesForeType[]>>;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  trade: TradesForeType;
}) => {
  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      noteText: "",
      SalesRevenue: "",
      SalesCost: "",
      LotCharges: "",
      TransferCost: "",
      ServiceCost: "",
      ProductCost: "",
    },
  });

  const watchNoteText = watch("noteText");
  const classes = useStyles();
  const currencies = useAppSelector((state) => state.auth.currencies);
  const itemCurrency = currencies.find(
    (item) => item.code === trade.CurrencyID
  );

  const [openSeeNotesModal, setOpenSeeNotesModal] = useState(false);
  const [editPriceLoading, setEditPriceLoading] = useState("");
  const [showBackgroundColorful, setShowBackgroundColorful] = useState("");
  const [pressedItem, setPressedItem] = useState("");
  const [addNoteLoading, setAddNoteLoading] = useState(false);

  const onAddNote = useCallback(async () => {
    setAddNoteLoading(true);

    const finalData = {
      note: watchNoteText,
      noteType: "FORECASTED_TRADE",
      relatedId: trade._id,
    };

    const added = await addNewNote(finalData);

    setAddNoteLoading(false);
    if (added?.success) {
      const newTrades = [...trades];
      const currIndexTrade = newTrades[index];
      currIndexTrade.notes.push(added?.data);
      setTrades(newTrades);
    }
  }, [index, trade?._id, trades.length, watchNoteText]);

  const onEditPrices = useCallback(
    async (loadType: string, successType: string, errorType: string) => {
      setPressedItem(loadType);
      setEditPriceLoading(loadType);
      const finalData = {
        forecastedTradeId: trade._id,
        forecastedTrade: {
          SalesRevenue: {
            admin: parseFloat(watch(`SalesRevenue[${index}]` as any) || ""),
          },
          SalesCost: {
            admin: parseFloat(watch(`SalesCost[${index}]` as any) || ""),
          },
          LotCharges: {
            admin: parseFloat(watch(`LotCharges[${index}]` as any) || ""),
          },
          TransferCost: {
            admin: parseFloat(watch(`TransferCost[${index}]` as any) || ""),
          },
          // ServiceCost: {
          //   admin: watch(`costInput.ServiceCost[${index}]`),
          // },
          ProductCost: {
            admin: parseFloat(watch(`ProductCost[${index}]` as any) || ""),
          },
        },
      };
      const updated = await updateForecastedPrices(finalData);
      setTimeout(() => {
        setEditPriceLoading("");
        if (updated?.success) {
          setShowBackgroundColorful(successType);

          setTimeout(() => {
            const newTrades = [...trades];

            newTrades[index] = updated?.data;
            setTrades(newTrades);
            setShowBackgroundColorful("");

            setPressedItem("");
          }, 1000);
        } else {
          setShowBackgroundColorful(errorType);
          setTimeout(() => {
            setShowBackgroundColorful("");
            setPressedItem("");
          }, 1000);
        }
      }, 1000);
    },
    [trade._id, trades[index], index]
  );

  const renderSeeNotesModalBody = () => {
    return (
      <>
        <div className="d-flex flex-column gap-2">
          {trade.notes.map((n, index) => {
            const adminUserNote = trade.admins.find((a) => a._id === n.adminId);
            return (
              <div key={index} className={classes.noteItem}>
                <span>
                  <span className="fw-bold">Note :</span> {n.note}
                </span>

                <span>
                  <span className="fw-bold">Admin :</span>{" "}
                  {adminUserNote?.fullName}
                </span>
              </div>
            );
          })}
        </div>

        <div className="d-flex justify-content-end mt-4">
          <AdminButtonComponent
            className={classes.closeButton}
            title="Close"
            onClick={() => setOpenSeeNotesModal(false)}
          />
        </div>
      </>
    );
  };
  return (
    <>
      <TableRow
        style={{ position: "relative" }}
        className={classes.colorRow}
        key={index}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {trade.ConsignmentNumber}
        </TableCell>
        <TableCell style={{ minWidth: 150 }} component="th" scope="row">
          {trade.SupplierName}
        </TableCell>
        <TableCell style={{ minWidth: 150 }} component="th" scope="row">
          <div> {moment(trade.ArrivalDate).format("DD/MM/YYYY")}</div>
        </TableCell>
        <TableCell style={{ minWidth: 250 }} component="th" scope="row">
          <div className="d-flex justify-content-between align-items-center">
            <span>TB : </span>
            <div className="d-flex justify-content-end">
              {itemCurrency?.symbol}
              {<NumberFormatComponent value={trade.SalesRevenue.tradabase} />}
            </div>
          </div>
          <div className="d-flex mt-3 justify-content-between align-items-center">
            <span>Admin : </span>
            <div className="d-flex justify-content-end align-items-center gap-2">
              <ControlledTextField
                onKeyPress={(e:any) =>
                  e?.key === "Enter" &&
                  onEditPrices(
                    "salesRevenue",
                    "salesRevenueGreen",
                    "salesRevenueRed"
                  )
                }
                defaultValue={trade.SalesRevenue.admin.toString()}
                alignRight
                className={
                  showBackgroundColorful === "salesRevenueGreen"
                    ? classes.textFieldGreen
                    : showBackgroundColorful === "salesRevenueRed"
                    ? classes.textFieldRed
                    : classes.textField
                }
                control={control}
                name={`SalesRevenue[${index}]`}
              />
              {editPriceLoading === "salesRevenue" ? (
                <CircularProgress size={20} />
              ) : showBackgroundColorful === "salesRevenueGreen" ? (
                <img
                  width={20}
                  height={20}
                  src="/static/svg/Check_round_fill_green_thick.svg"
                />
              ) : showBackgroundColorful === "salesRevenueRed" ? (
                <img
                  width={20}
                  height={20}
                  src="/static/svg/Close_round_fill_red.svg"
                />
              ) : (
                <i
                  onClick={() =>
                    onEditPrices(
                      "salesRevenue",
                      "salesRevenueGreen",
                      "salesRevenueRed"
                    )
                  }
                  className={`ri-pencil-line  ${classes.editPen}`}
                ></i>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell style={{ minWidth: 250 }} component="th" scope="row">
          <div className="d-flex justify-content-between align-items-center">
            <span>TB :</span>
            <div className="d-flex justify-content-end">
              {" "}
              {itemCurrency?.symbol}
              {<NumberFormatComponent value={trade.ProductCost.tradabase} />}
            </div>
          </div>
          <div className="d-flex mt-3 justify-content-between align-items-center">
            <span>Admin : </span>
            <div className="d-flex justify-content-end align-items-center gap-2">
              <ControlledTextField
                onKeyPress={(e) =>
                  e?.key === "Enter" &&
                  onEditPrices(
                    "productCost",
                    "productCostGreen",
                    "productCostRed"
                  )
                }
                defaultValue={trade.ProductCost.admin.toString()}
                alignRight
                className={
                  showBackgroundColorful === "productCostGreen"
                    ? classes.textFieldGreen
                    : showBackgroundColorful === "productCostRed"
                    ? classes.textFieldRed
                    : classes.textField
                }
                control={control}
                name={`ProductCost[${index}]`}
              />
              {editPriceLoading === "productCost" ? (
                <CircularProgress size={20} />
              ) : showBackgroundColorful === "productCostGreen" ? (
                <img
                  width={20}
                  height={20}
                  src="/static/svg/Check_round_fill_green_thick.svg"
                />
              ) : showBackgroundColorful === "productCostRed" ? (
                <img
                  width={20}
                  height={20}
                  src="/static/svg/Close_round_fill_red.svg"
                />
              ) : (
                <i
                  onClick={() =>
                    onEditPrices(
                      "productCost",
                      "productCostGreen",
                      "productCostRed"
                    )
                  }
                  className={`ri-pencil-line  ${classes.editPen}`}
                ></i>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell style={{ minWidth: 250 }} component="th" scope="row">
          <div className="d-flex justify-content-between align-items-center">
            <span>TB : </span>
            <div className="d-flex justify-content-end">
              {" "}
              {itemCurrency?.symbol}
              {<NumberFormatComponent value={trade.SalesCost.tradabase} />}
            </div>
          </div>
          <div className="d-flex mt-3 justify-content-between align-items-center">
            <span>Admin : </span>
            <div className="d-flex justify-content-end align-items-center gap-2">
              <ControlledTextField
                onKeyPress={(e: any) =>
                  e?.key === "Enter" &&
                  onEditPrices("salesCost", "salesCostGreen", "salesCostRed")
                }
                defaultValue={trade.SalesCost.admin.toString()}
                alignRight
                className={
                  showBackgroundColorful === "salesCostGreen"
                    ? classes.textFieldGreen
                    : showBackgroundColorful === "salesCostRed"
                    ? classes.textFieldRed
                    : classes.textField
                }
                control={control}
                name={`SalesCost[${index}]`}
              />
              {editPriceLoading === "salesCost" ? (
                <CircularProgress size={20} />
              ) : showBackgroundColorful === "salesCostGreen" ? (
                <img
                  width={20}
                  height={20}
                  src="/static/svg/Check_round_fill_green_thick.svg"
                />
              ) : showBackgroundColorful === "salesCostRed" ? (
                <img
                  width={20}
                  height={20}
                  src="/static/svg/Close_round_fill_red.svg"
                />
              ) : (
                <i
                  onClick={() =>
                    onEditPrices("salesCost", "salesCostGreen", "salesCostRed")
                  }
                  className={`ri-pencil-line  ${classes.editPen}`}
                ></i>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell style={{ minWidth: 250 }} component="th" scope="row">
          <div className="d-flex justify-content-between align-items-center">
            <span>TB : </span>
            <div className="d-flex justify-content-end">
              {" "}
              {itemCurrency?.symbol}
              {<NumberFormatComponent value={trade.LotCharges.tradabase} />}
            </div>
          </div>
          <div className="d-flex mt-3 justify-content-between align-items-center">
            <span>Admin : </span>
            <div className="d-flex justify-content-end align-items-center gap-2">
              <ControlledTextField
                onKeyPress={(e: any) =>
                  e?.key === "Enter" &&
                  onEditPrices("lotCharges", "lotChargesGreen", "lotChargesRed")
                }
                defaultValue={trade.LotCharges.admin.toString()}
                alignRight
                className={
                  showBackgroundColorful === "lotChargesGreen"
                    ? classes.textFieldGreen
                    : showBackgroundColorful === "lotChargesRed"
                    ? classes.textFieldRed
                    : classes.textField
                }
                control={control}
                name={`LotCharges[${index}]`}
              />
              {editPriceLoading === "lotCharges" ? (
                <CircularProgress size={20} />
              ) : showBackgroundColorful === "lotChargesGreen" ? (
                <img
                  width={20}
                  height={20}
                  src="/static/svg/Check_round_fill_green_thick.svg"
                />
              ) : showBackgroundColorful === "lotChargesRed" ? (
                <img
                  width={20}
                  height={20}
                  src="/static/svg/Close_round_fill_red.svg"
                />
              ) : (
                <i
                  onClick={() =>
                    onEditPrices(
                      "lotCharges",
                      "lotChargesGreen",
                      "lotChargesRed"
                    )
                  }
                  className={`ri-pencil-line  ${classes.editPen}`}
                ></i>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell style={{ minWidth: 250 }} component="th" scope="row">
          <div className="d-flex justify-content-between align-items-center">
            <span>TB :</span>
            <div className="d-flex justify-content-end">
              {" "}
              {itemCurrency?.symbol}
              {<NumberFormatComponent value={trade.TransferCost.tradabase} />}
            </div>
          </div>
          <div className="d-flex mt-3 justify-content-between align-items-center">
            <span>Admin : </span>
            <div className="d-flex justify-content-end align-items-center gap-2">
              <ControlledTextField
                onKeyPress={(e) =>
                  e?.key === "Enter" &&
                  onEditPrices(
                    "transferCost",
                    "transferCostGreen",
                    "transferCostRed"
                  )
                }
                defaultValue={trade.TransferCost.admin.toString()}
                alignRight
                className={
                  showBackgroundColorful === "transferCostGreen"
                    ? classes.textFieldGreen
                    : showBackgroundColorful === "transferCostRed"
                    ? classes.textFieldRed
                    : classes.textField
                }
                control={control}
                name={`TransferCost[${index}]`}
              />
              {editPriceLoading === "transferCost" ? (
                <CircularProgress size={20} />
              ) : showBackgroundColorful === "transferCostGreen" ? (
                <img
                  width={20}
                  height={20}
                  src="/static/svg/Check_round_fill_green_thick.svg"
                />
              ) : showBackgroundColorful === "transferCostRed" ? (
                <img
                  width={20}
                  height={20}
                  src="/static/svg/Close_round_fill_red.svg"
                />
              ) : (
                <i
                  onClick={() =>
                    onEditPrices(
                      "transferCost",
                      "transferCostGreen",
                      "transferCostRed"
                    )
                  }
                  className={`ri-pencil-line  ${classes.editPen}`}
                ></i>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell style={{ minWidth: 150 }} component="th" scope="row">
          <div className="d-flex justify-content-end">
            <span
              className={
                trade.GrossMargin === 0
                  ? ""
                  : trade.GrossMargin.toString().includes("-")
                  ? classes.redColor
                  : classes.greenColor
              }
            >
              {" "}
              {trade.GrossMargin.toString().includes("-") ? (
                `-${itemCurrency?.symbol}${trade.GrossMargin.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                ).replace("-", "")}`
              ) : (
                <NumberFormatComponent value={trade.GrossMargin} />
              )}
            </span>
          </div>
        </TableCell>
        <TableCell width={10} component="th" scope="row">
          <div className="d-flex justify-content-end">
            <span
              className={
                trade.GrossMarginPercentage === 0
                  ? ""
                  : trade.GrossMarginPercentage.toString().includes("-")
                  ? classes.redColor
                  : classes.greenColor
              }
            >
              {<NumberFormatComponent value={trade?.GrossMarginPercentage} />}%
            </span>
          </div>
        </TableCell>
        <TableCell style={{ minWidth: 150 }} component="th" scope="row">
          <div className="d-flex justify-content-end">
            <span
              className={
                trade.Margin === 0
                  ? ""
                  : trade.Margin.toString().includes("-")
                  ? classes.redColor
                  : classes.greenColor
              }
            >
              {" "}
              {trade.Margin.toString().includes("-") ? (
                `-${itemCurrency?.symbol}${trade.Margin.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                ).replace("-", "")}`
              ) : (
                <NumberFormatComponent value={trade.Margin} />
              )}
            </span>
          </div>
        </TableCell>
        <TableCell width={10} component="th" scope="row">
          <div className="d-flex justify-content-end">
            <span
              className={
                trade.MarginPercentage === 0
                  ? ""
                  : trade.MarginPercentage.toString().includes("-")
                  ? classes.redColor
                  : classes.greenColor
              }
            >
              {" "}
              {<NumberFormatComponent value={trade.MarginPercentage} />}%
            </span>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <p style={{ fontSize: 20 }} className="mt-2 fw-bold">
              Notes
            </p>

            <Box sx={{ margin: 1 }}>
              <div className="row d-flex align-items-center">
                <div className="col-md-6">
                  <ControlledTextField name="noteText" control={control} />
                </div>{" "}
                <div className="col-md-3">
                  <AdminButtonComponent
                    style={{ minWidth: 150 }}
                    disabled={addNoteLoading || !watchNoteText}
                    loading={addNoteLoading}
                    onClick={onAddNote}
                    title="Add note"
                  />
                </div>
              </div>
              {trade.notes?.[trade?.notes?.length - 1]?.note ? (
                <div className="col-md-12">
                  <div className={`mb-3 mt-3 `}>
                    <span className="fw-bold"> Last note : </span>{" "}
                    <span>
                      {trade?.notes?.[trade?.notes?.length - 1]?.note}
                    </span>
                  </div>
                </div>
              ) : null}

              {trade.notes?.length > 1 ? (
                <span
                  onClick={() => setOpenSeeNotesModal(true)}
                  className={classes.seeAllNotes}
                >
                  See all notes ({trade?.notes?.length})
                </span>
              ) : null}
            </Box>
          </Collapse>
        </TableCell>

        <AdminModalComponent
          children={renderSeeNotesModalBody()}
          headerTitle="All notes"
          openModal={openSeeNotesModal}
          closeModal={() => setOpenSeeNotesModal(false)}
        />
      </TableRow>
    </>
  );
};

export default TableComponentForecasted;
