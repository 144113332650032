import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import LoginCard from "./LoginCard";
import { LOGIN_FORM, TWO_FA } from "./contants";
import VerifyContainer from "./VerifyTwoFa/VerifyContainer";
import { UserInfoStateType } from "../../slices/authSlice";
import { Helmet } from "react-helmet";
const useStyles = makeStyles({
  button: {
    width: 600,
  },
  background: {
    backgroundImage: "url(./static/svg/orange_main_blur.svg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    backgroundPosition: "center",
    height: "100% !important",
    width: "100%",
  },
  loginCard: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});
const Login: React.FC = () => {
  const classes = useStyles();

  const [renderedContainer, setRenderedContainer] =
    useState<string>(LOGIN_FORM);
  const [accessToken, setAccessToken] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [userData, setUserData] = useState<UserInfoStateType>({
    createdAt: "",
    deleted: false,
    email: "",
    firstName: "",
    lastName: "",
    id: "",
    fullName: "",
    phoneNumber: "",
    photo: "",
    role: "",
    secret: "",
    status: "",
    updatedAt: "",
    _2fa: false,
    permissions: [],
  });

  const renderLoginContainer = () => {
    switch (renderedContainer) {
      case LOGIN_FORM:
        return (
          <LoginCard
            setEmail={setEmail}
            setUserData={setUserData}
            setAccessToken={setAccessToken}
            setRenderedContainer={setRenderedContainer}
          />
        );
      case TWO_FA:
        return (
          <VerifyContainer
            userData={userData}
            email={email}
            accessToken={accessToken}
            setRenderedContainer={setRenderedContainer}
          />
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | FruPro Admin Portal</title>
      </Helmet>
      <div className={classes.background}>
        <div className={classes.loginCard}>{renderLoginContainer()}</div>
      </div>
    </>
  );
};
export default Login;
