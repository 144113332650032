import React, { useEffect, useState } from "react";
import ControlledComboBox from "../../../components/ControlledComboBox";
import { Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { checkBuyerSeller } from "../../../services/verification-requests";

const useStyles = makeStyles(() => ({
  hr: {
    opacity: 0.4,
  },
  mainText: {
    fontSize: 18,
  },
  Input: {
    borderRadius: "10px!important",
    width: "100%",
    height: 45,
    padding: 15,
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  checkLimitButton: {
    border: "none",
    borderRadius: 10,
    width: "100%",
    background: "#E77228",
    color: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    height: "40px",
    marginTop: 12,
    "&:disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },
  success: {
    color: "#69CDAE",
  },
  error: {
    color: "red",
  },
}));

const SellerCompany = ({
  setIsSellerOnFinmid,
  errors,
  watch,
  reset,
  control,
  setValue,
  allocationSellers,
  sellerCompany,
}: {
  errors: any;
  watch: any;
  reset: any;
  control: any;
  setValue: any;
  sellerCompany?: { name: string; id: string };
  allocationSellers: { name: string; id: string }[];
  setIsSellerOnFinmid: React.Dispatch<React.SetStateAction<boolean>>;
  setAllocationSellers: React.Dispatch<
    React.SetStateAction<{ name: string; id: string }[]>
  >;
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const onCheckSeller = async () => {
    setLoading(true);
    const data = {
      companyId: sellerCompany?.id,
      companyType: "sellers",
    };
    const checked = await checkBuyerSeller(data);
    setLoading(false);

    if (checked?.data) {
      setMessage("Seller is exists on Finmid");
      setIsSellerOnFinmid(true);
    } else if (checked) {
      setIsSellerOnFinmid(false);
      setMessage(
        `${checked?.message} You cannot choose "Apply invoice factoring" option`
      );
    } else {
      setIsSellerOnFinmid(false);
      setMessage("Something went wrong,try again");
    }
  };

  useEffect(() => {
    if ((sellerCompany as any)?.id) {
      onCheckSeller();
    }
  }, [(sellerCompany as any)?.id]);

  useEffect(() => {
    if (!sellerCompany?.id) {
      setIsSellerOnFinmid(false);
      setMessage("");
    }
  }, [sellerCompany?.id]);

  return (
    <div>
      <span className={classes.mainText}>Seller Company</span>
      <Divider className={classes.hr} />
      <div className="row d-flex pt-3   align-items-center">
        <div className="col-3">
          <ControlledComboBox
            defaultValue={null}
            className={classes.comboBox}
            control={control}
            name="sellerCompany"
            error={!!errors?.sellerCompany}
            helperText={errors?.sellerCompany?.message}
            options={allocationSellers}
            textLabel={"Seller Company"}
          />
        </div>
        <div className="col-4 mt-3">
          {loading ? (
            <span>Checking...</span>
          ) : (
            <span
              className={
                message === "Seller is exists on Finmid"
                  ? classes.success
                  : classes.error
              }
            >
              {message}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SellerCompany;
