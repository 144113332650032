import axios from "axios";

export const axiosApiInstance = axios.create();

let token: string | null = localStorage.getItem("accessToken");

axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = `${process.env.REACT_APP_API_ROOT}/api`;
    config.headers["Authorization"] = `Bearer ${token} `;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      window.location.href = "/login";

      localStorage.removeItem("accessToken");
    }
    return Promise.reject(error);
  }
);
