import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";


export const getForecastedTrades = async (data:any) => {
 try {
   const response = await axiosApiInstance.post(
     `/tradabase/forecasted-trades`,
     {
      page:data?.page,
      sorting:data?.sorting,
      filters:data?.filterData
     }
   );
   if (response) return response.data;
 } catch (error) {
   if (axios.isAxiosError(error)) {
     toast.error(error.response?.data.message);
   }
 }
};


export const getForecastedTradesFilters = async () => {
 try {
   const response = await axiosApiInstance.get(
     `/tradabase/forecasted-trades-filters`
   );
   if (response) return response.data;
 } catch (error) {
   if (axios.isAxiosError(error)) {
     toast.error(error.response?.data.message);
   }
 }
};


export const updateForecastedPrices = async (data:any) => {
 try {
   const response = await axiosApiInstance.post(
     `/tradabase/update-forecasted-trade`,{...data}
   );
   if (response) return response.data;
 } catch (error) {
   if (axios.isAxiosError(error)) {
     
   }
 }
};
