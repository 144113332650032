import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { ChangePasswordContainer } from "./ChangePasswordContainer";
import NotificationSettings from "./NotificationSettings";
import RepresentetiveContainer from "./RepresentetiveContainer";
import { getNotificationSettings } from "../../services/settings";
import { SettingsState } from "./interface";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Helmet } from "react-helmet";
import usePermissions from "../../hooks/usePermissions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#E77228",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#E77228",
    },
  },
});
const SettingsContainer = () => {
  const classes = useStyles();

  const [value, setValue] = useState("password");
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [settings, setSettings] = useState<SettingsState[]>([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    limit: 1,
    totalPage: 1,
  });

  const {hasPermission}=usePermissions()

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getSettings = async () => {
      if(!hasPermission("NOTIFICATION_SETTINGS"))return
      setFetchLoading(true);
      const response = await getNotificationSettings();
      setFetchLoading(false);

      if (response) {
        setSettings(response);
      }
    };
    getSettings();
  }, [refresh,hasPermission("NOTIFICATION_SETTINGS")]);

  const renderTabs = () => {
    return (
      <>
        {fetchLoading?<Skeleton count={1} style={{height:300}}/>: value === "password" ? (
          <ChangePasswordContainer />
        ) : value === "representetive" ? (
          <RepresentetiveContainer
            managementId={settings[0]?.id}
            setRefresh={setRefresh}
            refresh={refresh}
            setLoading={setLoading}
            emailData={settings[0]?.emailCloudFunction}
          />
        ) : (
          <NotificationSettings
            setQueryParams={setQueryParams}
            queryParams={queryParams}
            setLoading={setLoading}
            loading={loading}
            settings={settings}
          />
        )}
      </>
    );
  };
  return (
    <>
      <Helmet>
        <title>Settings | FruPro Admin Portal </title>
      </Helmet>
      <Sidebar pageTitle="Settings">
        {loading && <LoadingIndicator loading />}
        <Box sx={{ width: "100%" }}>
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab value="password" label="Change Password" />
            <Tab value="notification" label="Notification Settings" />
            <Tab value="representetive" label="Platform Representative" />
          </Tabs>
          <Box marginTop={2}>{renderTabs()}</Box>
        </Box>
      </Sidebar>
    </>
  );
};

export default SettingsContainer;
