import {
  Badge,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Title,
} from "@tremor/react";
import { DashboardState } from "../../interface";
import moment from "moment";
import NumberFormatComponent from "../../../../components/NumberFormatComponent";
import { makeStyles } from "@mui/styles";
import { isDarkReaderActive } from "../../../../services/common";

const useStyles = makeStyles({
  darkModeTextColor: {
    fontWeight: 600,
    color: "white!important",
  },
  seeAllTradesText: {
    cursor: "pointer",
    fontSize: 16,
    textDecoration: "underline",
  },
  darkModeHeaderTextColor: {
    fontWeight: 600,
    color: "white!important",
  },
  darkModeSeeAllTextColor: {
    cursor: "pointer",
    fontWeight: 600,
    textDecoration: "underline",
    color: "white!important",
  },
  seeAllCompsText: {
    cursor: "pointer",
    fontSize: 16,
    textDecoration: "underline",
  },
});

const TradesTable = ({
  dashboardData,
  isDarkMode,
}: {
  isDarkMode: boolean;
  dashboardData?: DashboardState;
}) => {
  const classes = useStyles();

  return (
    <Card decoration="bottom" decorationColor="cyan" className="mt-2 ">
      <div className="d-flex justify-content-between">
        <Title
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.darkModeHeaderTextColor
              : ""
          }
          color="cyan"
        >
          Trades
        </Title>
        <a href={"/surplus-allocation"} target="_blank">
          <Title
            className={
              isDarkMode || isDarkReaderActive()
                ? classes.darkModeSeeAllTextColor
                : classes.seeAllCompsText
            }
            color="cyan"
          >
            See all
          </Title>
        </a>
      </div>
      <Table className="mt-2">
        <TableHead>
          <TableRow>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Payment Code
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              FruPay
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Date
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Customer
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Amount
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Trading Agent
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dashboardData?.lastTrades.map((item, index) => (
            <TableRow key={index}>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {item.offerCode}
              </TableCell>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                <Badge>{item.frupay ? "YES" : "NO"}</Badge>
              </TableCell>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {moment(item.invoiceDate).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                Seller: {item.sellerCompany}
                <br />
                Buyer : {item.buyerCompany}
              </TableCell>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {<NumberFormatComponent value={item.amount} />}
              </TableCell>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {item.tradeAgent.fullName}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default TradesTable;
