import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { CircularProgress, Backdrop } from "@mui/material";

interface LoadingIndicatorType {
  loading: boolean;
  title?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: "999999999 !important",
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
    loadingProgress: {
      color: "#E77228 !important",
    },
  })
);
const LoadingIndicator: React.FC<LoadingIndicatorType> = ({
  loading,
  title,
}) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <div className="d-flex flex-column gap-2 align-items-center">
        <CircularProgress className={classes.loadingProgress} />
        <span>{title}</span>
      </div>
    </Backdrop>
  );
};

export default LoadingIndicator;
