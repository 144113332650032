import React, { useState, useEffect, useCallback } from "react";
import { Sidebar } from "../../components/Sidebar";
import { makeStyles } from "@mui/styles";
import {
  exportCompanies,
  getAllCompanies,
  getAllEmployees,
  updateCompanyLocation,
} from "../../services/company";
import { RootObject } from "./companyListStateType";
import { RootEmployeeObject } from "./employeeListStateType";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TableComponent from "../../components/TableComponent";
import LoadingIndicator from "../../components/LoadingIndicator";
import SearchTextField from "../../components/SearchTextField";
import {
  Checkbox,
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import AdminModalComponent from "../../components/AdminModalComponent";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { Box } from "@mui/system";
import ControlledTextField from "../../components/ControlledTextField";
import AdminOtpInput from "../../components/AdminOtpInput";
import { useAppSelector } from "../../hooks/hooks";
import { impersonateUser } from "../../services/user";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import ControlledComboBox from "../../components/ControlledComboBox";
import { addToXero, getContactGroups } from "../../services/xero";
import { toast } from "react-toastify";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";
import { exportToExcelAnArray } from "../../services/common";

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  checkbox: {
    color: "#E77228 !important",
  },
  impersonate: {
    background: "#E77228!important",
  },
  PendingChip: {
    width: "150px!important",
    background: "#E0A600!important",
    color: "white!important",
  },
  ApprovedChip: {
    width: "150px!important",
    background: "#69CDAE!important",
    color: "white!important",
  },
  RejectedChip: {
    width: "150px!important",
    background: "#ED0000!important",
    color: "white!important",
  },
  AddAsSellerChip: {
    width: "150px!important",
    background: "#E77228!important",
    color: "white!important",
  },
  AddAsBuyerChip: {
    width: "150px!important",
    border: "1px solid #E77228!important",
    background: "none!important",
    color: "#E77228!important",
  },
  boldText: {
    fontWeight: 600,
  },
  frupayCellWidth: {
    width: 250,
  },
  CellWidth: {
    width: 150,
  },
  companyAdminText: {
    color: "#E77228!important",
    textDecoration: "underline",
    cursor: "pointer",
  },
  employeeField: {
    color: "#E77228!important",
    textDecoration: "underline",
    cursor: "pointer",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 160,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  addXeroButton: {
    color: "white!important",
    width: 150,
    borderRadius: "20px!important",
    marginTop: "10px!important",
    background: "#E77228!important",
  },
  cover: {
    width: 40,
    height: 40,
    borderRadius: "100%",
  },

  exportButton: {
    background: "#69cdae!important",
  },
});
interface selectedCompanyStateType {
  lat: string;
  long: string;
  id: string;
}

interface QueryParamsType {
  page: number;
  limit: number;
  total: number;
  totalPage: number;
}

interface SchemaType {
  lat: string;
  long: string;
  keyword: string;
  xeroContactGroup: any;
  xeroCurrency: any;
  employeeSearchKeyword: string;
}

interface contactGroupsType {
  name: string;
  _id: string;
}

const schema = yup.object().shape({
  lat: yup.string().required("Latitude is required"),
  long: yup.string().required("Longtitude is required"),
  keyword: yup.string(),
  employeeSearchKeyword: yup.string(),
  xeroContactGroup: yup.array(),
  xeroCurrency: yup.object(),
});

const columns = [
  { name: "", id: "companyPhoto" },
  { name: "Company Name", id: "companyName" },
  { name: "VAT", id: "vatNumber" },
  { name: "CRN", id: "crnNumber" },
  { name: "Status", id: "status" },
  { name: "Company Admin", id: "companyAdmin" },
  { name: "Xero", id: "xero" },
  { name: "FruPay Status", id: "frupay" },
  { name: "", id: "actions" },
];

const employeeColumns = [
  { name: "Full Name", id: "fullName" },
  { name: "Email", id: "email" },
  { name: "Status", id: "status" },
  { name: "Is company admin", id: "action" },
  { name: "", id: "action" },
];

const CompanyListContainer: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const currencies = useAppSelector((state) =>
    state.auth.currencies?.map((item) => ({
      name: item?.code,
      id: item?.id,
    }))
  );

  const { hasPermission } = usePermissions();
  const {
    watch,
    control,
    reset,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      xeroContactGroup: [],
      xeroCurrency: undefined,
    },
  });

  const watchKeyword = watch("keyword");
  const watchEmployeeKeyword = watch("employeeSearchKeyword");
  const watchXeroContactGroup = watch("xeroContactGroup");
  const watchXeroCurrency = watch("xeroCurrency");

  const [queryParams, setQueryParams] = useState<QueryParamsType>({
    page: 1,
    limit: 0,
    total: 0,
    totalPage: 0,
  });
  const [queryParamsEmployee, setQueryParamsEmployee] =
    useState<QueryParamsType>({
      page: 0,
      limit: 0,
      total: 0,
      totalPage: 0,
    });
  const [companyList, setCompanyList] = useState<RootObject[]>([]);
  const [employeeList, setEmployeeList] = useState<RootEmployeeObject[]>([]);
  const [xeroContactGroups, setXeroContactGroups] = useState<
    contactGroupsType[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [groupsLoading, setGroupsLoading] = useState<boolean>(false);

  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [open2faModal, setOpen2faModal] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [selectedCompanyLocationData, setSelectedCompanyLocationData] =
    useState<selectedCompanyStateType>({
      lat: "",
      long: "",
      id: "",
    });
  const [openUpdateLocationModal, setOpenUpdateLocationModal] =
    useState<boolean>(false);
  const [openAddToXeroModal, setOpenAddToXeroModal] = useState<boolean>(false);
  const [openEmployeesModal, setOpenEmployeesModal] = useState<boolean>(false);
  const [refreshToken, setRefreshToken] = useState<number>(0);

  const defaultXeroCurrency = currencies.find((item) => item.name === "GBP");
  // const defaultXeroGroup = xeroContactGroups.find(
  //   (item) => item.name === "TradaBaseSuppliers"
  // );

  //company list

  useEffect(() => {
    if (!hasPermission("COMPANY_LISTING_VIEW")) return;

    const getCompanies = async () => {
      setFetchLoading(true);
      const companies = await getAllCompanies(
        queryParams.page,
        watchKeyword || ""
      );

      setTimeout(() => {
        setFetchLoading(false);

        if (companies) {
          setCompanyList(companies.items);
          setQueryParams({
            page: companies?.metaData?.page,
            limit: companies?.metaData?.limit,
            total: companies?.metaData?.total,
            totalPage: companies?.metaData?.totalPage,
          });
        }
      }, 1000);
    };
    getCompanies();
  }, [queryParams.page, refreshToken, hasPermission("COMPANY_LISTING_VIEW")]);

  //employee List

  useEffect(() => {
    setEmployeeList([]);
  }, [openEmployeesModal]);

  useEffect(() => {
    if (selectedCompanyLocationData.id) {
      getEmployees(selectedCompanyLocationData.id);
    }
  }, [queryParamsEmployee.page, selectedCompanyLocationData.id]);

  const getEmployees = async (id: string) => {
    setLoading(true);
    const employees = await getAllEmployees(
      id,
      queryParamsEmployee.page,
      watchEmployeeKeyword as any
    );

    setTimeout(() => {
      setLoading(false);

      if (employees) {
        setEmployeeList(employees.items);
        setQueryParamsEmployee({
          page: employees?.metaData?.page,
          limit: employees?.metaData?.limit,
          total: employees?.metaData?.total,
          totalPage: employees?.metaData?.totalPage,
        });
      }
    }, 1000);
  };

  // const onOpen2faModal = (id: string) => {
  //   setOpenEmployeesModal(false);
  //   setOpen2faModal(true);
  //   setSelectedEmployeeId(id);
  // };
  const onClose2faModal = () => {
    setOpen2faModal(false);
  };

  // const onVerifyImpersonate = async () => {
  //   setLoading(true);
  //   const response = await VerifyTwoFa(otp, user.email);
  //   setTimeout(() => {
  //     setLoading(false);

  //     if (response === 200) {
  //       setOpen2faModal(false);
  //       setOtp("");
  //       onImpersonateUser(selectedEmployeeId);
  //     }
  //   }, 1000);
  // };

  useEffect(() => {
    document.getElementById("verifyOTP")?.focus();
  }, []);

  // useEffect(() => {
  //   if (otp.length === 6) {
  //     onVerifyImpersonate();
  //   }
  // }, [otp]);

  const onImpersonateUser = async (userId: string) => {
    setLoading(true);
    const response = await impersonateUser(userId);

    setTimeout(() => {
      setLoading(false);
      if (response) {
        window.open(
          `${process.env.REACT_APP_MARKETPLACE_URL}/impersonation?signature=${response.signature}`,
          "_blank"
        );
      }
    }, 1000);
  };

  const onNavigateToAddBuyerOrSellerPage = (type: string, id: string) => {
    if (type === "seller") {
      navigate(`/new-seller/${id}`);
    } else {
      navigate(`/new-buyer/${id}`);
    }
  };

  //table body

  const renderTableBody = () => {
    return (
      <>
        <Helmet>
          <title>Company List | FruPro Admin Portal</title>
        </Helmet>
        {companyList.map((company, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <img className={classes.cover} src={company?.logo?.url} />
              </TableCell>
              <TableCell>{company.companyName}</TableCell>

              <TableCell>{company.VATNumber}</TableCell>
              <TableCell>{company.companyHouseRegistration}</TableCell>
              <TableCell>
                <Chip label={company.status} />
              </TableCell>

              <TableCell>
                <span
                  onClick={() => window.open(`/users/${company.user.userSlug}`)}
                  className={classes.companyAdminText}
                >
                  {company.user.fullName}
                </span>
              </TableCell>
              <TableCell className={classes.frupayCellWidth}>
                <div className="d-flex flex-column">
                  {company.user.xeroId ? (
                    <div style={{ marginLeft: 5 }}>
                      {" "}
                      <span className={classes.boldText}>Id:</span>{" "}
                      {company.user.xeroId}
                    </div>
                  ) : null}
                  <PermissionWrapper permission="COMPANY_ADD_TO_XERO">
                    <Chip
                      className={classes.addXeroButton}
                      disabled={!!company.user.xeroFruProContact}
                      onClick={() => onOpenAddXeroModal(company.id)}
                      label={
                        company.user.xeroFruProContact
                          ? "EXISTS ON XERO"
                          : "ADD TO XERO"
                      }
                    />
                  </PermissionWrapper>
                </div>
              </TableCell>
              <TableCell className={classes.frupayCellWidth}>
                <p>
                  <span className={classes.boldText}>Seller : </span>
                  {company.verifiedSeller.status === "NONE" ? (
                    <PermissionWrapper permission="COMPANY_ADD_AS_A_SELLER">
                      <Chip
                        onClick={() =>
                          onNavigateToAddBuyerOrSellerPage("seller", company.id)
                        }
                        className={classes.AddAsSellerChip}
                        clickable
                        label={"ADD AS SELLER"}
                      />
                    </PermissionWrapper>
                  ) : company.verifiedSeller.status === "APPROVED" ? (
                    <Chip
                      className={classes.ApprovedChip}
                      label={company.verifiedSeller.status}
                    />
                  ) : company.verifiedSeller.status === "REJECTED" ? (
                    <Chip
                      className={classes.RejectedChip}
                      label={company.verifiedSeller.status}
                    />
                  ) : company.verifiedSeller.status === "PENDING" ? (
                    <Chip
                      className={classes.PendingChip}
                      label={company.verifiedSeller.status}
                    />
                  ) : null}
                </p>
                <p className="mt-2">
                  <span className={classes.boldText}>Buyer : </span>
                  {company.verifiedBuyer.status === "NONE" ? (
                    <PermissionWrapper permission="COMPANY_ADD_AS_A_BUYER">
                      <Chip
                        onClick={() =>
                          onNavigateToAddBuyerOrSellerPage("buyer", company.id)
                        }
                        className={classes.AddAsBuyerChip}
                        clickable
                        label={"ADD AS BUYER"}
                      />
                    </PermissionWrapper>
                  ) : company.verifiedBuyer.status === "APPROVED" ? (
                    <Chip
                      className={classes.ApprovedChip}
                      label={company.verifiedBuyer.status}
                    />
                  ) : company.verifiedBuyer.status === "REJECTED" ? (
                    <Chip
                      className={classes.RejectedChip}
                      label={company.verifiedBuyer.status}
                    />
                  ) : company.verifiedBuyer.status === "PENDING" ? (
                    <Chip
                      className={classes.PendingChip}
                      label={company.verifiedBuyer.status}
                    />
                  ) : null}
                </p>
              </TableCell>
              <TableCell>
                <Tooltip title="Employees">
                  <IconButton
                    onClick={() => {
                      setOpenEmployeesModal(true);
                      setSelectedCompanyLocationData({
                        lat: "",
                        long: "",
                        id: company.id,
                      });
                      getEmployees(company.id);
                    }}
                  >
                    <i className="ri-user-2-line "></i>
                  </IconButton>
                </Tooltip>
                <PermissionWrapper permission="COMPANY_VIEW_DETAILS">
                  <Tooltip title="Details">
                    <a
                      target="_blank"
                      href={`/company-list/${company.id}`}
                      rel="noreferrer"
                    >
                      <IconButton>
                        <i className="ri-eye-line"></i>
                      </IconButton>
                    </a>
                  </Tooltip>
                </PermissionWrapper>
                {/* <Tooltip title="Location">
                  <IconButton
                    onClick={() => {
                      setOpenUpdateLocationModal(true);
                      setSelectedCompanyLocationData({
                        lat: company.location?.lat,
                        long: company.location?.long,
                        id: company.id,
                      });
                      setValue("lat", company.location?.lat),
                        setValue("long", company.location?.long);
                    }}
                  >
                    <i className="ri-map-pin-line"></i>
                  </IconButton>
                </Tooltip> */}
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  //search field

  const onSearch = async () => {
    setLoading(true);
    const companies = await getAllCompanies(
      queryParams.page,
      watchKeyword as any
    );

    setTimeout(() => {
      setLoading(false);

      if (companies) {
        setCompanyList(companies.items);
        setQueryParams({
          page: companies?.metaData?.page,
          limit: companies?.metaData?.limit,
          total: companies?.metaData?.total,
          totalPage: companies?.metaData?.totalPage,
        });
      }
    }, 1000);
  };

  const RenderSearchTextField = () => {
    return (
      <>
        <SearchTextField
          onKeyPress={onSearch}
          onSearchClick={onSearch}
          control={control}
          name="keyword"
        />
      </>
    );
  };

  //update location

  const onUpdate = async (data: any) => {
    setLoading(true);
    await updateCompanyLocation(
      selectedCompanyLocationData.id,
      data.lat,
      data.long
    );
    setLoading(false);
    setOpenUpdateLocationModal(false);
    setRefreshToken(() => refreshToken + 1);
  };

  const onCloseModal = () => {
    setOpenUpdateLocationModal(false);
  };

  const renderUpdateLocationModalBody = () => {
    return (
      <>
        <div className="container">
          <div className="row g-3">
            <ControlledTextField
              error={!!errors.lat}
              helperText={errors.lat?.message}
              control={control}
              name="lat"
              textLabel="Latitude"
            />
            <ControlledTextField
              error={!!errors.long}
              helperText={errors.long?.message}
              control={control}
              name="long"
              textLabel="Longtitude"
            />
          </div>
        </div>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setOpenUpdateLocationModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            disabled={loading}
            className={classes.saveButton}
            onClick={handleSubmit(onUpdate)}
            title="Update"
          />
        </Box>
      </>
    );
  };

  //employees

  const RenderEmployeeSearchTextField = () => {
    return (
      <>
        <SearchTextField
          onSearchClick={() => getEmployees(selectedCompanyLocationData.id)}
          control={control}
          name="employeeSearchKeyword"
        />
      </>
    );
  };

  const onCloseEmployeesModal = () => {
    setOpenEmployeesModal(false);
  };

  const render2faModalBody = () => {
    return (
      <>
        <div>
          <AdminOtpInput
            id="verifyOTP"
            differentBackground={true}
            numInputs={6}
            value={otp}
            onChange={setOtp}
          />
        </div>
      </>
    );
  };

  const employeesTableBody = () => {
    return (
      <>
        {employeeList.map((employee, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <span
                  onClick={() => window.open(`/users/${employee.userSlug}`)}
                  className={classes.employeeField}
                >
                  {" "}
                  {employee.fullName}
                </span>
              </TableCell>
              <TableCell>{employee.email}</TableCell>
              <TableCell>
                <Chip label={employee.status} />
              </TableCell>
              <TableCell>
                <Checkbox
                  className={classes.checkbox}
                  checked={true}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </TableCell>
              <TableCell>
                <PermissionWrapper permission="COMPANY_ADMINUSER_IMPERSONATE">
                  <AdminButtonComponent
                    className={classes.impersonate}
                    title="Impersonate"
                    onClick={() => onImpersonateUser(employee.id)}
                  />
                </PermissionWrapper>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderEmployeesModalBody = () => {
    return (
      <>
        <TableComponent
          RenderSearchTextField={RenderEmployeeSearchTextField()}
          TableBodyComponent={employeesTableBody}
          columns={employeeColumns}
          queryParams={queryParamsEmployee}
          setQueryParams={setQueryParamsEmployee}
          showRightButton={false}
        />
      </>
    );
  };

  //xero

  // useEffect(() => {
  //   if (xeroContactGroups.length && openAddToXeroModal&&process.env.REACT_APP_API_ROOT==="https://frupro-prod-dc2fe6k.nw.gateway.dev") {
  //     setValue("xeroContactGroup", [defaultXeroGroup]);
  //   }
  // }, [xeroContactGroups.length, openAddToXeroModal]);

  useEffect(() => {
    if (defaultXeroCurrency?.id) {
      setValue("xeroCurrency", defaultXeroCurrency);
    }
  }, [defaultXeroCurrency?.id, openAddToXeroModal]);

  useEffect(() => {
    if (openAddToXeroModal) {
      const getGroups = async () => {
        setGroupsLoading(true);
        const resGroups = await getContactGroups();
        setGroupsLoading(false);

        if (resGroups?.data) {
          setXeroContactGroups(
            resGroups?.data?.map((item: any) => ({
              name: item?.Name,
              _id: item?.ContactGroupID,
            }))
          );
        }
      };
      getGroups();
    }
  }, [openAddToXeroModal]);

  const onOpenAddXeroModal = (id: string) => {
    setOpenAddToXeroModal(true);
    setSelectedCompanyId(id);
  };
  const onCloseAddXeroModal = () => {
    setOpenAddToXeroModal(false);
    setSelectedCompanyId("");
    reset({
      xeroContactGroup: [],
      xeroCurrency: undefined,
    });
  };

  const onAddToXero = async () => {
    if (!watchXeroContactGroup?.length) {
      toast.error("Please select at least 1 xero contact group");
    } else if (!(watchXeroCurrency as any)?.id) {
      toast.error("Please select currency");
    } else {
      setLoading(true);
      const finalData = {
        companyId: selectedCompanyId,
        contactGroups: watchXeroContactGroup?.map((item: any) => item?._id),
        currency: (watchXeroCurrency as any)?.name,
      };
      const added = await addToXero(finalData);
      setLoading(false);

      if (added) {
        onSearch();

        setSelectedCompanyId("");
        setOpenAddToXeroModal(false);
        setValue("xeroContactGroup", []);
        setValue("xeroCurrency", {});
      }
    }
  };

  const onExportCompanies = useCallback(async () => {
    setExportLoading(true);
    const exportRes = await exportCompanies(watchKeyword || "");
    setExportLoading(false);

    if (exportRes?.excelBuffer) {
      exportToExcelAnArray(exportRes?.excelBuffer?.data,"companies_list.xlsx");
    }
  }, [watchKeyword]);

  const renderAddToXeroModalBody = () => {
    return (
      <>
        <div className="container">
          <div className="row g-2">
            <ControlledComboBox
              className={classes.comboBox}
              textLabel="Contact Groups"
              multiple
              options={xeroContactGroups}
              control={control}
              name="xeroContactGroup"
            />
            {
              <ControlledComboBox
                className={classes.comboBox}
                textLabel="Currency"
                options={currencies}
                control={control}
                name="xeroCurrency"
              />
            }
          </div>
        </div>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseAddXeroModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={onAddToXero}
            title="Submit"
          />
        </Box>
      </>
    );
  };

  const renderCompanyExportButton = () => {
    return (
      <>
        <AdminButtonComponent
        style={{minWidth:150}}
          loading={exportLoading}
          className={classes.exportButton}
          title={exportLoading ? "Exporting.." : "Export Companies"}
          onClick={onExportCompanies}
        />
      </>
    );
  };

  return (
    <>
      <Sidebar closeSidebar={openEmployeesModal} pageTitle="Company List">
        {loading && <LoadingIndicator loading />}
        {groupsLoading && (
          <LoadingIndicator title="Please wait.." loading={groupsLoading} />
        )}
        <PermissionWrapper
          permission="COMPANY_LISTING_VIEW"
          unauthorizedComponent
        >
          <TableComponent
            showRightHeaderItems={renderCompanyExportButton()}
            loading={fetchLoading}
            rightButtonFunction={() => navigate("/new-company")}
            isRightButtonFunction={true}
            headerButtonTitle="+ Add new company"
            RenderSearchTextField={RenderSearchTextField()}
            TableBodyComponent={renderTableBody}
            columns={columns}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            showRightButton={true}
            rightButtonPermission={"COMPANY_ADD_NEW"}
          />
        </PermissionWrapper>
      </Sidebar>
      <AdminModalComponent
        children={renderUpdateLocationModalBody()}
        headerTitle="Update Location"
        openModal={openUpdateLocationModal}
        closeModal={onCloseModal}
      />
      <AdminModalComponent
        children={render2faModalBody()}
        headerTitle="Verify"
        openModal={open2faModal}
        closeModal={onClose2faModal}
      />
      <AdminModalComponent
        size="xl"
        children={renderEmployeesModalBody()}
        headerTitle="Employees"
        openModal={openEmployeesModal}
        closeModal={onCloseEmployeesModal}
      />
      <AdminModalComponent
        children={renderAddToXeroModalBody()}
        headerTitle="Add to xero"
        openModal={openAddToXeroModal}
        closeModal={onCloseAddXeroModal}
      />
    </>
  );
};

export default CompanyListContainer;
