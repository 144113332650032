import React, { useState } from "react";
import ControlledTextField from "../../components/ControlledTextField";
import { makeStyles } from "@mui/styles";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResetPassword } from "../../services/auth";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles({
  card: {
    width: "550px",
    height: "auto",
    padding: "40px 40px 24px 40px",
    background: "rgba(249, 249, 249, 0.9)",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    backdropFilter: "blur(15.5px)",
    borderRadius: "20px",
  },
  headerText: {
    color: "#E77228",
    fontWeight: 500,
  },
  InputBox: {
    display: "flex",
    gap: 30,
    flexDirection: "column",
    marginTop: 30,
  },
  ButtonBox: {
    marginTop: 30,
  },
  LoginButton: {
    width: "100%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  ForgotBox: {
    marginTop: 30,
  },
  forgotPassword: {
    color: "#69CDAE",
    cursor: "pointer",
    textDecoration: "underline",
  },
  copyRight: {
    display: "flex",
    justifyContent: "center",
  },
  copyRightBox: {
    marginTop: 30,
  },
  fruProTextColor: {
    color: "#E77228",
    marginLeft: 5,
  },
  yearText: {
    marginLeft: 5,
  },
  headerBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: 20,
  },
  logo: {
    margin: "0 auto",
  },
});

interface EmailSchemaType {
  email: string;
}

const emailSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
});
const SendEmailCard: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<EmailSchemaType>({ resolver: yupResolver(emailSchema) });

  const onSend = async (data: EmailSchemaType) => {
    setLoading(true);
    setTimeout(async () => {
      const response = await ResetPassword(data);
      setLoading(false);

      navigate("/login");
    }, 1000);
  };
  return (
    <div className={classes.card}>
      <div className={classes.headerBox}>
        <img className={classes.logo} src="/static/svg/logo.png" />
        <h3 className={classes.headerText}>Forgot Password</h3>
        <p>
          Lost your password? Please enter your email address. You will receive
          a link to create a new password via email.
        </p>
      </div>

      <div className={classes.InputBox}>
        <ControlledTextField
          error={!errors.email}
          helperText={errors.email?.message}
          placeholder="example@gmail.com"
          textLabel="Email"
          control={control}
          name="email"
        />
      </div>

      <div className={classes.ButtonBox}>
        <AdminButtonComponent
          loading={loading}
          disabled={loading}
          onClick={handleSubmit(onSend)}
          className={classes.LoginButton}
          title="Send"
        />
      </div>
      <div className={classes.ForgotBox}>
        <a href="/login">
          {" "}
          <p className={classes.forgotPassword}>Back to sign in</p>
        </a>
      </div>
    </div>
  );
};

export default SendEmailCard;
