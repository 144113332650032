import React, { useState, useEffect } from "react";
import { Sidebar } from "../../components/Sidebar";
import { makeStyles } from "@mui/styles";
import TableComponent from "../../components/TableComponent";
import { useParams } from "react-router-dom";
import {
  getDonateEmails,
  addDonateEmails,
  deleteDonateEmail,
} from "../../services/donateEmails";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchTextField from "../../components/SearchTextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingIndicator from "../../components/LoadingIndicator";
import AdminModalComponent from "../../components/AdminModalComponent";
import ControlledTextField from "../../components/ControlledTextField";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  addTextField: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  addButtonField: {
    marginTop: 20,
  },
  emailBox: {
    background: "#69CDAE",
    borderRadius: 20,
    maxWidth: "200px",
    display: "flex",
    alignItems: "center",
    wordBreak: "break-word",
    justifyContent: "center",
    minHeight: "45px",
    color: "white",
  },
});

interface QueryParamsType {
  page: number;
  limit: number;
  total: number;
  totalPage: number;
}

type ParamsType = {
  id: string;
};
interface DonateEmailsResponseType {
  email: string;
}

interface donateEmailsFormTypes {
  keyword: string;
  email: string;
}

const donateEmailsSchema = yup.object().shape({
  keyword: yup.string(),
  email: yup.string().email().required("Email is required"),
});

const DonateEmailsContainer: React.FC = () => {
  const classes = useStyles();

  const { id } = useParams<ParamsType>();

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(donateEmailsSchema),
  });

  const watchKeyword = watch("keyword");

  const [queryParams, setQueryParams] = useState<QueryParamsType>({
    page: 1,
    limit: 1,
    total: 1,
    totalPage: 1,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [openAddNewEmailModal, setOpenNewEmailModal] = useState<boolean>(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState<boolean>(false);
  const [refreshToken, setRefreshToken] = useState<number>(0);
  const [donateEmails, setDonateEmails] = useState<DonateEmailsResponseType[]>(
    []
  );

  const [selectedEmails, setSelectedEmails] = useState<
    DonateEmailsResponseType[]
  >([]);
  const [selectedRowEmail, setSelectedRowEmail] = useState<string>("");

  const columns = [
    { name: "Email", id: "email" },
    { name: "Action", id: "delete" },
  ];

  useEffect(() => {
    const getAllCenterEmails = async () => {
      setLoading(true);
      const response = await getDonateEmails(queryParams.page, id);

      setTimeout(() => {
        setLoading(false);
        if (response) {
          setDonateEmails(response.items);
          setQueryParams({
            page: response.metaData.page,
            limit: response.metaData.limit,
            total: response.metaData.total,
            totalPage: response.metaData.totalPage,
          });
        }
      }, 1000);
    };
    getAllCenterEmails();
  }, [queryParams.page, refreshToken]);

  const renderDonateEmailsTableBody = () => {
    return (
      <>
        {donateEmails.map((email, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {email.email}
              </TableCell>

              <TableCell>
                <IconButton
                  onClick={() => {
                    setOpenDeleteConfirmationModal(true);
                    setSelectedRowEmail(email.email);
                  }}
                >
                  <Tooltip title="Delete Email">
                    <i className="ri-delete-bin-line"></i>
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const onSearch = async () => {
    setLoading(true);
    const response = await getDonateEmails(queryParams.page, id, watchKeyword);

    setTimeout(() => {
      setLoading(false);
      if (response) {
        setDonateEmails(response.items);
        setQueryParams({
          page: response.metaData.page,
          limit: response.metaData.limit,
          total: response.metaData.total,
          totalPage: response.metaData.totalPage,
        });
      }
    }, 1000);
  };

  const renderSearchField = () => {
    return (
      <>
        <SearchTextField
          onSearchClick={onSearch}
          control={control}
          name="keyword"
        />
      </>
    );
  };

  //add new email modal

  const onAddEmail = async (data: any) => {
    setLoading(true);
    const response = await addDonateEmails(data?.email, id);
    setLoading(false);
    setOpenNewEmailModal(false);

    setRefreshToken(() => refreshToken + 1);
  };

  const onDeleteEmail = async () => {
    setLoading(true);
    const response = await deleteDonateEmail(selectedRowEmail, id);
    setLoading(false);
    setRefreshToken(() => refreshToken + 1);
    setOpenDeleteConfirmationModal(false);
  };

  const onCloseModal = () => {
    setOpenNewEmailModal(false);
  };

  const renderAddNewEmailModalBody = () => {
    return (
      <>
        <ControlledTextField
          error={!!errors.email}
          helperText={errors.email?.message}
          control={control}
          textLabel="Email"
          name="email"
        />

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenNewEmailModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={loading}
            className={classes.saveButton}
            onClick={handleSubmit(onAddEmail)}
            title="Save"
          />
        </Box>
      </>
    );
  };

  //delete confirmation

  const onCloseDeleteConfirmationModal = () => {
    setOpenDeleteConfirmationModal(false);
  };

  const renderDeleteConfirmationModalBody = () => {
    return (
      <>
        <p>Are you sure you want to delete this email?</p>

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenDeleteConfirmationModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={loading}
            className={classes.saveButton}
            onClick={onDeleteEmail}
            title="Delete"
          />
        </Box>
      </>
    );
  };
  return (
    <>
    <Helmet>
      <title>Donate Emails | FruPro Admin Portal</title>
    </Helmet>
      <Sidebar pageTitle="Donate Emails">
        {loading && <LoadingIndicator loading />}
        <TableComponent
          setOpenModal={setOpenNewEmailModal}
          RenderSearchTextField={renderSearchField()}
          headerButtonTitle="+Add new email"
          showRightButton={true}
          columns={columns}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          TableBodyComponent={renderDonateEmailsTableBody}
        />

        <AdminModalComponent
          headerTitle="Add new email"
          children={renderAddNewEmailModalBody()}
          openModal={openAddNewEmailModal}
          closeModal={onCloseModal}
        />
        <AdminModalComponent
          headerTitle="Are you sure?"
          children={renderDeleteConfirmationModalBody()}
          openModal={openDeleteConfirmationModal}
          closeModal={onCloseDeleteConfirmationModal}
        />
      </Sidebar>
    </>
  );
};

export default DonateEmailsContainer;
