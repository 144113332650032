import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getAnonymousCompanies = async (page: number) => {
  try {
    const response = await axiosApiInstance.get(
      `/company-info/anonymous-info?page=${page}&limit=${20}`
    );
    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const updateAnonymousCompany = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/company-info/anonymous-info-update`,
      {
        ...data,
      }
    );
    if (response) {
      toast.success("Data is updated successfully");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
