import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getInventoryItems = async (filters = {}) => {
  try {
    const response = await axiosApiInstance.post("inventory/admin-inventory", {
      ...filters,
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getInvoiceItems = async (filters = {}, page: number) => {
  try {
    const response = await axiosApiInstance.post(
      `admin-surplus-invoices?page=${page}`,
      {
        ...filters,
      }
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const OrderOrInvoiceList = async (
  type: string,
  filterData: any,
  page: number,
  order?: string,
  limit?: number
) => {
  try {
    const response = await axiosApiInstance.post(
      `/admin-surplus-list?page=${page}&limit=${limit ?? 50}`,
      {
        ...filterData,
        type: type,
        order: order,
      }
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getOrderItems = async (filters = {}) => {
  try {
    const response = await axiosApiInstance.post(`admin-surplus-offers`, {
      ...filters,
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getOrderDetail = async (id: string) => {
  try {
    const response = await axiosApiInstance.get(`offers-admin/${id}`);

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const downloadSurplusReport = async () => {
  try {
    const response = await axiosApiInstance.get(`surplus/export-admin-report`, {
      responseType: "arraybuffer",
    });

    if (response) {
      toast.success("Report is downloaded successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getSurplusSellersBuyers = async (type: string) => {
  try {
    const response = await axiosApiInstance.post("/admin-surplus-info", {
      type: type,
    });
    if (response) return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getSummaryAndDetailedReports = async (
  expandReport: boolean,
  type: string,
  filterValues = {}
) => {
  try {
    const response = await axiosApiInstance.post(
      "/file/admin-surplus-list",
      {
        expandReport: expandReport,
        type: type,
        ...filterValues,
      },
      {
        responseType: "arraybuffer",
      }
    );
    if (response) {
      toast.success("Report is downloaded successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const downloadInvoices = async (
  invoiceIds: string[],
  filterValues = {}
) => {
  try {
    const response = await axiosApiInstance.post(
      "/file/admin-surplus-list/bulk-invoice",
      {
        expandReport: true,
        type: "invoice",
        invoiceIds: invoiceIds ? invoiceIds : undefined,
        ...filterValues,
      },
      {
        responseType: "arraybuffer",
      }
    );
    if (response) {
      toast.success("Invoices downloaded  successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getOnGoingPaymentStatements = async (
  buyerCompanyId: string,
  sellerCompanyId: string
) => {
  try {
    const response = await axiosApiInstance.post(
      "admin/ongoing-payment-statements",
      {
        buyerCompanyId: buyerCompanyId,
        sellerCompanyId: sellerCompanyId,
      }
    );
    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const regenerateInvoice = async (invoiceCode: string) => {
  try {
    const response = await axiosApiInstance.post("/updateInvoice", {
      invoiceCode: invoiceCode,
    });

    if (response) {
      toast.success("Invoice regenerated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getFinmidLimit = async (data: any) => {
  try {
    const { ...rest } = data;
    const response = await axiosApiInstance.post("/finmid/get-company-limit", {
      ...rest,
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const createInventoryAndAllocation = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/surplus-offers/v2/create",
      {
        ...data,
      }
    );
    if (response) {
      const message = `Offer created successfully.`;
      toast.success(message);
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const createShippingDocument = async (
  offerId: string,
  file: any,
  documentName: string,
  showMessage: boolean
) => {
  try {
    const formData = new FormData();
    formData.append("documentName", documentName);
    formData.append("file", file);
    const response = await axiosApiInstance.post(
      `/documents/upload-offer-document/${offerId}`,
      formData,
      {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }
    );

    if (response) {
      showMessage && toast.success("Document is created successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const updateProduceItemInOffer = async (data: any, offerId: string) => {
  try {
    const response = await axiosApiInstance.post(
      `/admin/offer-produces/update/${offerId}`,
      { ...data }
    );
    if (response) {
      toast.success("Produces updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getImpersonateCount = async (id: string) => {
  try {
    const response = await axiosApiInstance.post(
      `/surplus-invoice-request-amount-admin-level`,
      {
        sellerUser: {
          id: id,
        },
      }
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getFinmidPrices = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(`/finmid/get-payment-prices`, {
      ...data,
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getSellerAddresses = async (id: string) => {
  try {
    const response = await axiosApiInstance.get(
      `/address/company-addresses/${id}`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getPartialPayment = async (id: string) => {
  try {
    const response = await axiosApiInstance.get(`/get-partial-payments/${id}`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const updatePartialPayment = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/admin/partial-payments/edit-partial-price`,
      { ...data }
    );
    if (response) {
      toast.success("Amount updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const capturePartialPayment = async (data:any) => {
  try {
    const response = await axiosApiInstance.post(
      `/admin/partial-payments/capture-partial-price`,
      data
    );
    if (response) {
      toast.success("Payment captured successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const cancelOrder = async (offerId: string) => {
  try {
    const response = await axiosApiInstance.put(
      `/admin/offers/${offerId}/cancel`
    );
    if (response) {
      toast.success("Order is cancelled successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const acceptInvoice = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/admin/update-surplus-invoice`,
      { ...data }
    );
    if (response) {
      toast.success("Invoice captured successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const rejectInvoice = async (offerId: string, invoiceId: string) => {
  try {
    const response = await axiosApiInstance.put(
      `admin/offers/${offerId}/invoices/${invoiceId}/revise`
    );
    if (response) {
      toast.success("Invoice rejected successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const updateOfferDiscount = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(`/admin/discount-management`, {
      ...data,
    });
    if (response) {
      toast.success("Offer discount updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const createInvoice = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/admin/surplus-offers/invoice/create`,
      {
        ...data,
      }
    );
    if (response) {
      toast.success("Invoice created successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getOfferInvoices = async (offerId: string) => {
  try {
    const response = await axiosApiInstance.get(
      `/admin/offers/${offerId}/invoices?isSurplusOrder=true`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const forwardInvoice = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(`/admin/invoices/email`, {
      ...data,
    });
    if (response) {
      toast.success("Invoice forwarded successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const updateInvoiceDate = async (data: any) => {
  try {
    const response = await axiosApiInstance.put(
      `/invoices/admin/update-invoice-date`,
      {
        ...data,
      }
    );
    if (response) {
      toast.success("Invoice date updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const updateDeliveryDate = async (data: any) => {
  try {
    const response = await axiosApiInstance.put(
      `/invoices/admin/update-delivery-date`,
      {
        ...data,
      }
    );
    if (response) {
      toast.success("Delivery date updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const addAddCost = async (data: any, offerId: string) => {
  try {
    const response = await axiosApiInstance.post(
      `/admin/offers/${offerId}/additional-cost`,
      data
    );
    if (response) {
      toast.success("Additional cost added successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};
export const removeAddCost = async (offerId: string, costId: string) => {
  try {
    const response = await axiosApiInstance.delete(
      `/admin/offers/${offerId}/additional-cost/${costId}`
    );
    if (response) {
      toast.success("Additional cost removed successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const generateCreditNote = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/credit-notes/admin/create-credit-note`,
      {
        ...data,
      }
    );
    if (response) {
      toast.success("Credit Note is generated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const deleteCreditNote = async (id: string, creditNoteId: string) => {
  try {
    const response = await axiosApiInstance.post(
      `/credit-notes/admin/remove-credit-note`,
      {
        invoiceId: id,
        creditNoteId: creditNoteId,
      }
    );
    if (response) {
      toast.success("Credit Note is deleted successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const createPaymentRequest = async (finalData: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/finmid/create-payment-for-existing-offer`,
      finalData
    );
    if (response) {
      toast.success("Payment request created successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getPaymentTerms = async () => {
  try {
    const response = await axiosApiInstance.get(`/info/payment-terms`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const markAsPaid = async (offerId: string, invoiceId: string) => {
  try {
    const response = await axiosApiInstance.put(
      `/offers/${offerId}/invoices/${invoiceId}/complete`
    );
    if (response) {
      toast.success("Marked as paid successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const editRefs = async (data: any) => {
  try {
    const { type, ...rest } = data;
    const response = await axiosApiInstance.post(`/admin/buyer-seller-ref`, {
      ...rest,
    });
    if (response) {
      toast.success(
        data?.type === "buyer"
          ? "Buyer ref updated successfully."
          : "Seller ref updated successfully."
      );
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getBankDefaultInfos = async () => {
  try {
    const response = await axiosApiInstance.get(`/banks`, {});
    if (response) {
      return { status: response.status, data: response.data };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const removeFruPay = async (id: string) => {
  try {
    const response = await axiosApiInstance.post(
      `/finmid/remove-order-frupay`,
      {
        orderId: id,
      }
    );
    if (response) {
      toast.success("FruPay Removed successfully");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const updateOrderBankInfo = async (data: any) => {
  try {
    const response = await axiosApiInstance.put(
      `/invoices/admin/update-bank-details`,
      {
        ...data,
      }
    );
    if (response) {
      toast.success("Bank info updated successfully");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};
