import React, { useEffect, useState } from "react";
import {
  getRequests,
  updatePendingRequest,
} from "../../services/company-certificates";
import LoadingIndicator from "../../components/LoadingIndicator";
import { CertifiedDocument, RequestsStateType } from "./requestInterface";
import TableComponent from "../../components/TableComponent";
import { makeStyles } from "@mui/styles";
import { Box, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import AdminModalComponent from "../../components/AdminModalComponent";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../components/ControlledTextField";
import ControlledComboBox from "../../components/ControlledComboBox";
import { Types } from "./CertificationRequestsContainer";

const schema = yup.object().shape({
  type: yup.object(),
  reason: yup.string(),
});

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  image: {
    width: 75,
    height: 75,
    borderRadius: "100%",
  },
  approve: {
    "&:hover": {
      borderRadius: "20px!important",
      background: "#69CDAE!important",
    },
    borderRadius: "20px!important",
    background: "#69CDAE!important",
  },
  reject: {
    "&:hover": {
      borderRadius: "20px!important",
      background: "red!important",
    },
    borderRadius: "20px!important",
    background: "red!important",
  },
  seeFile: {
    color: "#E77228",
    "&:hover": {
      color: "#E77228",
    },
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: 460,

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
});

const columns = [
  { name: "Company Name", id: "companyName" },
  { name: "File", id: "file" },
  { name: "Certification Type", id: "certificateType" },
  { name: "Certification Title", id: "certificationTitle" },
  { name: "Create Time", id: "createdAt" },
  { name: "", id: "actions" },
];

const CertificationRequestTable = ({
  certificationTypes,
}: {
  certificationTypes: Types[];
}) => {
  const { control, watch, reset, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const watchReason = watch("reason");
  const watchCertificateType = watch("type");

  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  const [pendingRequestsCompanyData, setPendingRequestsCompanyData] = useState<
    RequestsStateType[]
  >([]);

 

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("approve");
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [selectedRequest, setSelectedRequest] = useState<CertifiedDocument>();
  const [refresh, setRefresh] = useState<number>(0);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  let requestsArr: CertifiedDocument[] = [];

  pendingRequestsCompanyData?.map((request: any) => {
    return request?.certifiedDocuments?.map((item: any) => {
      return requestsArr.push(item);
    });
  });

  useEffect(() => {
    const getPendingRequests = async () => {
      setFetchLoading(true);
      const requests = await getRequests(queryParams.page);
      setFetchLoading(false);

      if (requests) {
        setPendingRequestsCompanyData(requests?.certificationRequests);
        setQueryParams({
          page: requests?.pagination?.page,
          total: requests?.pagination?.total,
          totalPage: requests?.pagination?.totalPages,
          limit: 1,
        });
      }
    };
    getPendingRequests();
  }, [queryParams.page, refresh]);

  

  useEffect(() => {
    if (selectedRequest?._id) {
      setValue("type",{
        name: selectedRequest?.certificationType?.title,
        id: selectedRequest?.certificationType?._id,
        url: selectedRequest?.certificationType?.imagePath,
      });
    }
  }, [selectedRequest?._id]);

  const onPressButtons = (
    type: string,
    row: CertifiedDocument,
    companyId?: string
  ) => {
    setSelectedRequest(row);
    setModalType(type);
    setOpenModal(true);
    setSelectedCompanyId(companyId ? companyId : "");
  };

  const onApproveOrReject = async () => {
    setLoading(true);

    if (modalType === "approve") {
      const data = {
        status: "APPROVED",
        companyId: selectedCompanyId,
        certificationRequestId: selectedRequest?._id,
        certificationTypeId: (watchCertificateType as any)?.id,
      };
      const response = await updatePendingRequest(data);
      setLoading(false);

      if (response) {
        setRefresh(() => refresh + 1);
        setSelectedCompanyId("");
        setOpenModal(false);
        setSelectedRequest(undefined);
      }
    } else {
      const data = {
        status: "REJECTED",
        companyId: selectedCompanyId,
        certificationRequestId: selectedRequest?._id,
        reason: watchReason,
      };
      const response = await updatePendingRequest(data);
      setLoading(false);

      if (response) {
        setRefresh(() => refresh + 1);
        setSelectedCompanyId("");
        setOpenModal(false);
        setSelectedRequest(undefined);
      }
    }
  };

  const renderTableBody = () => {
    return (
      <>
        {requestsArr?.map((request, index) => {
          const requestCompany = pendingRequestsCompanyData?.find((item) =>
            item?.certifiedDocuments
              ?.map((document) => document?._id)
              .includes(request?._id)
          );

          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{requestCompany?.companyName}</TableCell>
              <TableCell>
                <a
                  target="_blank"
                  href={request.url}
                  className={classes.seeFile}
                >
                  See file
                </a>
              </TableCell>
              <TableCell>
                <img
                  className={classes.image}
                  src={request.certificationType.imagePath}
                />
              </TableCell>
              <TableCell>{request.certificationType.title}</TableCell>
              <TableCell>
                {moment(request.createdAt).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell>
                <div className="d-flex gap-2 justify-content-end">
                  <AdminButtonComponent
                    onClick={() => {
                      onPressButtons("approve", request, requestCompany?._id);
                    }}
                    className={classes.approve}
                    title="Approve"
                  />
                  <AdminButtonComponent
                    onClick={() =>
                      onPressButtons("reject", request, requestCompany?._id)
                    }
                    className={classes.reject}
                    title="Reject"
                  />
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderModalBody = () => {
    return (
      <>
        {modalType === "approve" ? (
          <>
            <ControlledComboBox
              defaultValue={{
                name: selectedRequest?.title,
                id: selectedRequest?._id,
                url: selectedRequest?.url,
              }}
              className={classes.comboBox}
              options={certificationTypes?.map((item) => ({
                name: item.title,
                id: item._id,
                url: item.imagePath,
              }))}
              textLabel="Certification Type"
              control={control}
              name="type"
            />

            <Box className={classes.newUserModalButtons}>
              <AdminButtonComponent
                className={classes.closeButton}
                onClick={() => setOpenModal(false)}
                title="Cancel"
              />{" "}
              <AdminButtonComponent
                disabled={
                  modalType === "approve" ? !watchCertificateType : false
                }
                className={classes.saveButton}
                onClick={() => onApproveOrReject()}
                title="Submit"
              />
            </Box>
          </>
        ) : (
          <>
            <ControlledTextField
              textLabel="Reason"
              control={control}
              name="reason"
            />
            <Box className={classes.newUserModalButtons}>
              <AdminButtonComponent
                className={classes.closeButton}
                onClick={() => setOpenModal(false)}
                title="Cancel"
              />{" "}
              <AdminButtonComponent
                className={classes.saveButton}
                onClick={() => onApproveOrReject()}
                title="Submit"
              />
            </Box>
          </>
        )}
      </>
    );
  };

  return (
    <div>
      {loading && <LoadingIndicator loading />}
      <TableComponent
      loading={fetchLoading}
        TableBodyComponent={renderTableBody}
        showRightButton={false}
        columns={columns}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
      <AdminModalComponent
        children={renderModalBody()}
        headerTitle={
          modalType === "approve" ? "Approve request" : "Reject request"
        }
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
    </div>
  );
};

export default CertificationRequestTable;
