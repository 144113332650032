import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getBankAccounts = async ( filterData: any) => {
  try {
    const response = await axiosApiInstance.post(`/bank-details/get-details`, {
      ...filterData,
    });
    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addBankAccount = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/bank-details/add-detail-for-company`,
      {
        ...data,
      }
    );
    if (response) {
      toast.success("Account is added successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const updateBankDetails = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      "/bank-details/update-details",
      {
        ...data,
      }
    );

    if (response) {
      toast.success("Bank account details is updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const deleteBankDetails = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      "/bank-details/remove-detail",
      {
        ...data,
      }
    );

    if (response) {
      toast.success("Bank account details is deleted successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getBankSelections = async () => {
  try {
    const response = await axiosApiInstance.post(
      "/bank-details/company-bank-selections"
    );
    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getBuyersSellers = async (type: string) => {
  try {
    const response = await axiosApiInstance.post(
      "/bank-details/get-verified-companies",
      {
        companyType: type,
      }
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
