import { Box, IconButton, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  filterBoxFlex: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
  },
  filterBoxItem: {
    padding: 10,
    marginTop: 5,
    marginLeft: 5,
    border: "1px solid #69CDAE",
    borderRadius: "30px!important",
    width: "auto",
    height: "auto",
  },
  filterDeleteIcon: {
    width: 13,
    height: 13,
  },
  boldText: {
    fontWeight: "600!important",
  },
});

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 0 },
    items: window.location.pathname === "/forecasted-trades" ? 8 : 5,
  },
};

const FilterBadge = ({
  searchParams,
  isClearFilterKey,
  filterData,
  setSearchParams,
  setIsClearFilterKey,
  setFilterData,
  buyers,
  sellers,
  setDeletedFilterKey,
}: {
  searchParams?: any;
  setSearchParams?: React.Dispatch<React.SetStateAction<any>>;
  filterData: any;
  buyers?: any;
  sellers?: any;
  isClearFilterKey?: number;
  setIsClearFilterKey?: React.Dispatch<React.SetStateAction<number>>;
  setFilterData: any;
  setDeletedFilterKey: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const cleanFilterField = useCallback(
    (fieldName: any) => {
      searchParams && navigate("/surplus-allocation");

      setIsClearFilterKey &&
        setIsClearFilterKey(() => (isClearFilterKey as any) + 1);
      setDeletedFilterKey(fieldName);
      delete filterData[fieldName];
      setFilterData({ ...filterData });
    },
    [
      filterData,
      setFilterData,
      searchParams,
      isClearFilterKey,
      setDeletedFilterKey,
      setIsClearFilterKey,
      navigate,
    ]
  );

  return (
    <div>
      <Carousel
        arrows
        slidesToSlide={1}
        autoPlay={false}
        responsive={responsive}
      >
        {Object.keys(filterData).map((filterKeys) => {
          const buyerNames =
            filterKeys === "buyer" &&
            Array.isArray(filterData[filterKeys]) &&
            filterData[filterKeys]?.map((item: any) => {
              return buyers.find((buyer: any) => buyer?._id === item);
            });

          const buyerName =
            filterKeys === "buyerCompanyId" &&
            buyers.find((buyer: any) => buyer?.id === filterData[filterKeys]);
          const sellerName =
            filterKeys === "sellerCompanyId" ||
            (filterKeys === "sellerId" &&
              sellers.find(
                (seller: any) => seller?.id === filterData[filterKeys]
              ));

          const sellerNames =
            filterKeys === "seller" &&
            Array.isArray(filterData[filterKeys]) &&
            filterData[filterKeys]?.map((item: any) => {
              return sellers.find((seller: any) => seller?._id === item);
            });

          const companyNames =
            filterKeys === "companyName" &&
            Array.isArray(filterData[filterKeys]) &&
            filterData[filterKeys]?.map((item: any) => {
              return sellers.find((seller: any) => seller?._id === item);
            });

          return typeof filterData[filterKeys] === "boolean" ? (
            <Box className={classes.filterBoxItem}>
              <Box className={classes.filterBoxFlex}>
                <IconButton onClick={() => cleanFilterField(filterKeys)}>
                  <img
                    className={classes.filterDeleteIcon}
                    src="/static/svg/ic_close_dialog.svg"
                  />
                </IconButton>
                <Box>
                  <Typography className={classes.boldText}>
                    {filterKeys === "sellerCompanyId" ||
                    filterKeys === "sellerId"
                      ? "seller"
                      : filterKeys === "buyerCompanyId"
                      ? "buyer"
                      : filterKeys === "invoiceDateMonth"
                      ? "month"
                      : filterKeys === "invoiceDateYear"
                      ? "year"
                      : filterKeys}
                  </Typography>
                  <Typography>
                    {filterKeys === "sellerEmailNotification"
                      ? filterData[filterKeys]
                        ? "YES"
                        : "NO"
                      : filterKeys === "buyerEmailNotification"
                      ? filterData[filterKeys]
                        ? "YES"
                        : "NO"
                      : undefined}
                  </Typography>
                  <Typography>
                    {filterKeys === "frupay"
                      ? filterData[filterKeys]
                        ? "YES"
                        : "NO"
                      :undefined}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : filterData[filterKeys] ? (
            Array.isArray(filterData[filterKeys]) ? (
              <Box key={filterKeys} className={classes.filterBoxItem}>
                <Box className={classes.filterBoxFlex}>
                  <IconButton onClick={() => cleanFilterField(filterKeys)}>
                    <img
                      className={classes.filterDeleteIcon}
                      src="/static/svg/ic_close_dialog.svg"
                    />
                  </IconButton>
                  <Box>
                    <Typography className={classes.boldText}>
                      {filterKeys}
                    </Typography>
                    <Box>
                      <Typography style={{ wordBreak: "break-all" }}>
                        {filterKeys === "buyer"
                          ? buyerNames.map((item: any) => item?.name).join(",")
                          : filterKeys === "seller"
                          ? sellerNames.map((item: any) => item?.name).join(",")
                          : filterKeys === "companyName"
                          ? companyNames
                              .map((item: any) => item?.name)
                              .join(",")
                          : filterData[filterKeys]
                              .map((item: any) => item)
                              .join(",")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className={classes.filterBoxItem}>
                <Box className={classes.filterBoxFlex}>
                  <IconButton onClick={() => cleanFilterField(filterKeys)}>
                    <img
                      className={classes.filterDeleteIcon}
                      src="/static/svg/ic_close_dialog.svg"
                    />
                  </IconButton>
                  <Box>
                    <Typography className={classes.boldText}>
                      {filterKeys === "sellerCompanyId" ||
                      filterKeys === "sellerId"
                        ? "seller"
                        : filterKeys === "buyerCompanyId"
                        ? "buyer"
                        : filterKeys === "invoiceDateMonth"
                        ? "month"
                        : filterKeys === "invoiceDateYear"
                        ? "year"
                        : filterKeys}
                    </Typography>
                    <Box>
                      <Typography style={{ wordBreak: "break-all" }}>
                        {filterKeys === "buyerCompanyId"
                          ? buyerName?.name
                          : filterKeys === "sellerCompanyId" ||
                            filterKeys === "sellerId"
                          ? sellerName?.name
                          : filterData[filterKeys]}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          ) : null;
        })}
      </Carousel>
    </div>
  );
};

export default FilterBadge;
