import { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { CompanyDetailsStateType } from "../CompanyList/CompanyDetails/interfaces";
import { getCompanyById } from "../../services/company";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import { makeStyles } from "@mui/styles";
import CountryList from "../../country.json";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ControlledTextField from "../../components/ControlledTextField";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import {
  addNewBuyer,
  checkBuyerSeller,
} from "../../services/verification-requests";
import ControlledComboBox from "../../components/ControlledComboBox";
import AdminModalComponent from "../../components/AdminModalComponent";
import { useAppSelector } from "../../hooks/hooks";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface country {
  id: string;
  name: string;
}

export interface CheckIsExistsState {
  id: string;
  status: string;
  legal_name: string;
  legal_address: LegalAddress;
  business_registration_number: string;
  tax_number: string;
  email: string;
  phone: any;
}

export interface LegalAddress {
  address_line_1: string;
  postal_code: string;
  city: string;
  country: string;
  address_line_2: any;
  state: any;
}

const countries: country[] = CountryList.map((item: any) => ({
  id: item.code,
  name: item.countryName,
}));

const useStyles = makeStyles({
  mainBox: {
    marginTop: 10,
    padding: 20,
    width: "100%",
    background: "white",
    borderRadius: "20px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  },
  headerTitle: {
    color: "#000",
    fontSize: 18,
  },
  hr: {
    opacity: 0.05,
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
});

const schema = yup.object().shape({
  BRN: yup.string().required("Business registration number is required"),
  taxNumber: yup.string().required("Tax number is required"),
  phoneNumber: yup.string(),
  companyName: yup.string().required("Company name required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  fullAddress: yup.string().required("Address is required"),
  postalCode: yup.string().required("Postal code is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  country: yup.object().required("Country is required"),
});

const AddFruPayBuyerContainer = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const countriesWithPhone = useAppSelector((state) => state.auth.countries);
  const [loading, setLoading] = useState<boolean>(false);
  const [openIsExistsModal, setOpenIsExistsModal] = useState<boolean>(false);
  const [checkLoading, setCheckLoading] = useState<boolean>(false);
  const [companyLoading, setCompanyLoading] = useState<boolean>(false);
  const [isInFinmidData, setIsInFinmidData] = useState<CheckIsExistsState>();
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [company, setCompany] = useState<CompanyDetailsStateType>();

  const defaultCountryInput = countries.find(
    (item) => item.name === company?.principalPlaceCountry.name
  );

  const defaultPhoneCode = countriesWithPhone.find((item) =>
    company?.userDetails.phoneNumber.includes(`+${item.phone}`)
  )?.phone;

  useEffect(() => {
    if (
      isInFinmidData?.status === "PENDING" ||
      isInFinmidData?.status === "ACTIVE" ||
      isInFinmidData?.status === "INACTIVE" ||
      isInFinmidData?.status === "REJECTED" ||
      isInFinmidData?.status === "SUSPENDED"
    ) {
      setOpenIsExistsModal(true);
    }
  }, [isInFinmidData?.status]);

  //check for buyer exists on Finmid

  useEffect(() => {
    const checkExists = async () => {
      setCheckLoading(true);
      const data = {
        companyId: id,
        companyType: "buyers",
      };
      const checked = await checkBuyerSeller(data);
      setCheckLoading(false);

      if (checked) {
        setIsInFinmidData(checked?.data);
      }
    };
    checkExists();
  }, []);

  useEffect(() => {
    if (id) {
      const getCompanyData = async () => {
        setCompanyLoading(true);
        const response = await getCompanyById(id);

        if (response) {
          setCompany(response);
        }
        setCompanyLoading(false);
      };
      getCompanyData();
    }
  }, [id]);

  useEffect(() => {
    if (company?.id) {
      setSelectedCountry(defaultPhoneCode || "");
      reset({
        country: defaultCountryInput,
        companyName: company?.companyName,
        state: company.principalPlaceState?.name,
        city: company.principalPlaceCity?.name,
        BRN: company?.VATNumber,
        taxNumber: company?.companyHouseRegistration,
        postalCode: company?.principalPlacePostCode,
        fullAddress: company?.principalPlaceAddress,
        email: company.userDetails?.email,
        phoneNumber: company.userDetails?.phoneNumber.split(
          defaultPhoneCode || ""
        )[1],
      });
    }
  }, [company?.id]);

  const onAddBuyer = async (data: any) => {
    setLoading(true);

    const finalData = {
      companyId: id,
      legal_name: company?.companyName,
      email: data?.email,
      phone:
        selectedCountry && data?.phoneNumber
          ? `+${selectedCountry}${data?.phoneNumber}`
          : undefined,
      legal_address: {
        address_line_1: data?.fullAddress,
        postal_code: data?.postalCode,
        city: data?.city,
        country: data?.country?.id,
      },
      business_registration_number: data?.BRN,
      tax_number: data?.taxNumber,
    };
    const added = await addNewBuyer(finalData);
    setLoading(false);

    if (added) {
      navigate("/company-list");
    }
  };

  const onCloseExistsModal = () => {
    setOpenIsExistsModal(false);
    navigate("/company-list");
  };

  const renderModalBody = () => {
    return (
      <>
        <span className="">Company is already exists as buyer on FinMid.</span>
        <div className="d-flex justify-content-end  mt-3">
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={onCloseExistsModal}
            title="Close"
          />{" "}
        </div>
      </>
    );
  };

  return (
    <>
      {" "}
      <Sidebar
        renderButton={
          isInFinmidData?.status === "PENDING" ||
          isInFinmidData?.status === "ACTIVE" ||
          isInFinmidData?.status === "INACTIVE" ||
          isInFinmidData?.status === "REJECTED" ||
          isInFinmidData?.status === "SUSPENDED" ? null : (
            <div className="">
              <AdminButtonComponent
                title="Submit"
                onClick={handleSubmit(onAddBuyer)}
              />
            </div>
          )
        }
        pageTitle="New Buyer"
      >
        {checkLoading || companyLoading ? (
          <Skeleton count={1} style={{ height: 600 }} />
        ) : isInFinmidData?.status === "PENDING" ||
          isInFinmidData?.status === "ACTIVE" ||
          isInFinmidData?.status === "INACTIVE" ||
          isInFinmidData?.status === "REJECTED" ||
          isInFinmidData?.status === "SUSPENDED" ? null : (
          <div className={classes.mainBox}>
            <div>
              {loading && <LoadingIndicator loading={loading} />}
              <p className={classes.headerTitle}>Buyer Information</p>
              <hr className={classes.hr} />
              <div className="row pb-2 g-4">
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="Company Name"
                    control={control}
                    name="companyName"
                    error={!!errors?.companyName}
                    helperText={errors?.companyName?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="Email"
                    control={control}
                    name="email"
                    error={!!errors?.email}
                    helperText={errors?.email?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <PhoneNumberInput
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    control={control}
                    name="phoneNumber"
                    error={!!errors?.phoneNumber}
                    helperText={errors?.phoneNumber?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="Business Registration Number(BRN)"
                    control={control}
                    name="BRN"
                    error={!!errors?.BRN}
                    helperText={errors?.BRN?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="Tax Number"
                    control={control}
                    name="taxNumber"
                    error={!!errors?.taxNumber}
                    helperText={errors?.taxNumber?.message as string}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <p className={classes.headerTitle}>Legal Address</p>
              <hr className={classes.hr} />
              <div className="row pb-2 g-4">
                <div className="col-md-4">
                  {company?.id && (
                    <ControlledComboBox
                      className={classes.comboBox}
                      defaultValue={defaultCountryInput}
                      options={countries}
                      textLabel="Country"
                      control={control}
                      name="country"
                      error={!!errors?.country}
                      helperText={errors?.country?.message as string}
                    />
                  )}
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="State"
                    control={control}
                    name="state"
                    error={!!errors?.state}
                    helperText={errors?.state?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="City"
                    control={control}
                    name="city"
                    error={!!errors?.city}
                    helperText={errors?.city?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="Postal Code"
                    control={control}
                    name="postalCode"
                    error={!!errors?.postalCode}
                    helperText={errors?.postalCode?.message as string}
                  />
                </div>
                <div className="col-md-12">
                  <ControlledTextField
                    textLabel="Address"
                    control={control}
                    name="fullAddress"
                    error={!!errors?.fullAddress}
                    helperText={errors?.fullAddress?.message as string}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Sidebar>
      <AdminModalComponent
        children={renderModalBody()}
        openModal={openIsExistsModal}
        closeModal={() => setOpenIsExistsModal(false)}
      />
    </>
  );
};

export default AddFruPayBuyerContainer;
