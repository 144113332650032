import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Title,
} from "@tremor/react";
import { DashboardState } from "../../interface";
import NumberFormatComponent from "../../../../components/NumberFormatComponent";
import moment from "moment";
import { Badge, BadgeDelta } from "@tremor/react";
import { makeStyles } from "@mui/styles";
import { isDarkReaderActive } from "../../../../services/common";
import { useAppSelector } from "../../../../hooks/hooks";

const useStyles = makeStyles({
  darkModeTextColor: {
    fontWeight: 600,
    color: "white!important",
  },
  seeAllLimitsText: {
    cursor: "pointer",
    fontSize: 16,
    textDecoration: "underline",
  },
  darkModeHeaderTextColor: {
    fontWeight: 600,
    color: "white!important",
  },
  darkModeSeeAllTextColor: {
    cursor: "pointer",
    fontWeight: 600,
    textDecoration: "underline",
    color: "white!important",
  },
  seeAllCompsText: {
    cursor: "pointer",
    fontSize: 16,
    textDecoration: "underline",
  },
});

const ForecastedTradesTable = ({
  isDarkMode,
  dashboardData,
}: {
  isDarkMode: boolean;
  dashboardData?: DashboardState;
}) => {
  const classes = useStyles();

  const currencies = useAppSelector((state) => state.auth.currencies);

  return (
    <Card decoration="bottom" decorationColor="blue" className="mt-2 ">
      <div className="d-flex justify-content-between">
        <Title
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.darkModeHeaderTextColor
              : ""
          }
          color="blue"
        >
          Forecasted Trades
        </Title>
        <a href={"/forecasted-trades"} target="_blank">
          <Title
            className={
              isDarkMode || isDarkReaderActive()
                ? classes.darkModeSeeAllTextColor
                : classes.seeAllCompsText
            }
            color="blue"
          >
            See all
          </Title>
        </a>
      </div>
      <Table className="mt-2">
        <TableHead>
          <TableRow>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Consignment
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Supplier
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Arrival Date
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Sales Price
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Costs
            </TableHeaderCell>

            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Gross Margin
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Gross %
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Net Margin
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Net %
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dashboardData?.forecastedTrades.map((item, index) => {
            const itemCurrency = currencies.find(
              (c) => c.code === item.CurrencyID
            );
            return (
              <TableRow key={index}>
                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  {item?.ConsignmentNumber}
                </TableCell>
                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  {item?.SupplierName}
                </TableCell>
                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  {moment(item?.ArrivalDate).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  {itemCurrency?.symbol}
                  {
                    <NumberFormatComponent
                      value={item?.SalesRevenue.tradabase}
                    />
                  }
                </TableCell>
                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  {itemCurrency?.symbol}
                  {<NumberFormatComponent value={item.TotalCost} />}
                </TableCell>

                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  <BadgeDelta
                    style={{ minWidth: 120 }}
                    deltaType={
                      item.GrossMargin.toString().includes("-")
                        ? "moderateDecrease"
                        : "moderateIncrease"
                    }
                    isIncreasePositive={true}
                  >
                    <NumberFormatComponent value={item?.GrossMargin} />
                  </BadgeDelta>
                </TableCell>

                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  <span
                    style={{
                      color: item.GrossMarginPercentage.toString().includes("-")
                        ? "red"
                        : "",
                    }}
                  >
                    {" "}
                    {item?.GrossMarginPercentage}
                  </span>
                </TableCell>
                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  <BadgeDelta
                    style={{ minWidth: 120 }}
                    deltaType={
                      item.Margin.toString().includes("-")
                        ? "moderateDecrease"
                        : "moderateIncrease"
                    }
                    isIncreasePositive={true}
                  >
                    <NumberFormatComponent value={item?.Margin} />
                  </BadgeDelta>
                </TableCell>
                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  <span
                    style={{
                      color: item.MarginPercentage.toString().includes("-")
                        ? "red"
                        : "",
                    }}
                  >
                    {" "}
                    {item?.MarginPercentage}
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};

export default ForecastedTradesTable;
