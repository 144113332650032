import { useCallback, useEffect, useMemo, useState } from "react";
import {
  addCompanyEmailPreferences,
  getCompanyEmailPreferences,
  removeCompanyEmailPreferences,
  updateCompanyNotifications,
} from "../../../services/company";
import { Checkbox, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  preferenceItem: {
    width: 300,
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    marginTop: 15,
    padding: 5,
    borderRadius: 10,
    background: "#f8f8f8",
  },
});

export interface EmailPrefsItem {
  _id: string;
  companyName: string;
  preferences: Preference[];
}

export interface Preference {
  _id: string;
  emailType: string;
  createdAt: string;
}

const Preferences = ({
  companyId,
  notification,
}: {
  companyId: string;
  notification: any;
}) => {
  const classes = useStyles();
  const [remittanceLoading, setRemittanceLoading] = useState(false);
  const [statementLoading, setStatementLoading] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [orderPreferences, setOrderPreferences] = useState<{
    emailNotification: boolean;
    appNotification: boolean;
  }>({
    emailNotification: notification.emailNotification ?? false,
    appNotification: notification.appNotification ?? false,
  });
  const [loading, setLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(0);
  const [prefsData, setPrefsData] = useState<EmailPrefsItem>();

  let remittancePreference: any =
    prefsData?._id &&
    prefsData.preferences?.find((p) => p?.emailType === "REMITTANCE");

  let statementPreference: any =
    prefsData?._id &&
    prefsData.preferences?.find((p) => p?.emailType === "STATEMENT");

  useEffect(() => {
    const getPreferenceData = async () => {
      setLoading(true);
      const response = await getCompanyEmailPreferences(1, companyId);
      setLoading(false);

      if (response) {
        setPrefsData(response?.data?.[0]);
      }
    };
    getPreferenceData();
  }, [isRefresh, companyId]);

  const onOfferNotificationStatusChange = useCallback(
    async (
      type: "APP_NOTIFICATION" | "EMAIL_NOTIFICATION",
      status: boolean
    ) => {
      const notificationType =
        type === "APP_NOTIFICATION" ? "appNotification" : "emailNotification";

      try {
        setNotificationLoading(true);
        const response = await updateCompanyNotifications({
          companyId: companyId,
          ...orderPreferences,
          [notificationType]: status,
        });

        if (response.success) {
          setOrderPreferences((prev) => {
            return {
              ...prev,
              [notificationType]: status,
            };
          });

          toast.success("Notification preferences updated successfully");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setNotificationLoading(false);
      }
    },
    [companyId, orderPreferences]
  );

  const onChangeStatus = useCallback(
    async (status: string, item?: Preference) => {
      if (status === "REMITTANCE") {
        if (!!item?.emailType) {
          setRemittanceLoading(true);
          const response = await removeCompanyEmailPreferences(item._id);
          setRemittanceLoading(false);

          if (response?.success) {
            let newPrefsData: any = { ...prefsData };

            const filteredPrefs = newPrefsData.preferences?.filter(
              (p: any) => p.emailType !== "REMITTANCE"
            );

            newPrefsData.preferences = filteredPrefs;
            setPrefsData(newPrefsData);
          }
        } else {
          setRemittanceLoading(true);
          const response = await addCompanyEmailPreferences({
            companyId: companyId,
            emailSubscriptionPreferenceType: "REMITTANCE",
          });
          setRemittanceLoading(false);

          if (response?.success) {
            let newDoc = response?.newPreferenceDoc;

            const newPrefsData: any = { ...prefsData };
            newPrefsData?.preferences.push(newDoc);

            setPrefsData(newPrefsData);
          }
        }
      } else {
        if (!!item?.emailType) {
          setStatementLoading(true);
          const response = await removeCompanyEmailPreferences(item._id);
          setStatementLoading(false);
          if (response?.success) {
            let newPrefsData: any = { ...prefsData };

            const filteredPrefs = newPrefsData.preferences?.filter(
              (p: any) => p.emailType !== "STATEMENT"
            );

            newPrefsData.preferences = filteredPrefs;
            setPrefsData(newPrefsData);
          }
        } else {
          setStatementLoading(true);
          const response = await addCompanyEmailPreferences({
            companyId: companyId,
            emailSubscriptionPreferenceType: "STATEMENT",
          });
          setStatementLoading(false);
          if (response?.success) {
            let newDoc = response?.newPreferenceDoc;

            const newPrefsData: any = { ...prefsData };
            newPrefsData?.preferences.push(newDoc);

            setPrefsData(newPrefsData);
          }
        }
      }
    },
    [companyId, prefsData, prefsData?.preferences.length]
  );

  return (
    <div>
      <div className={classes.preferenceItem}>
        {" "}
        <div
          style={{ minHeight: "40px" }}
          className="mt-2 d-flex flex-row align-items-center justify-content-between"
        >
          <div style={{ width: 100 }}>
            <span>Remittance : </span>
          </div>
          {loading || remittanceLoading ? (
            <CircularProgress size={20} />
          ) : (
            prefsData?._id && (
              <Checkbox
                onChange={() =>
                  onChangeStatus("REMITTANCE", remittancePreference)
                }
                checked={!!remittancePreference?.emailType}
                value={!!remittancePreference?.emailType}
                defaultChecked={!!remittancePreference?.emailType}
              />
            )
          )}
        </div>
      </div>
      <div className={classes.preferenceItem}>
        {" "}
        <div
          style={{ minHeight: "40px" }}
          className="mt-2 d-flex flex-row align-items-center justify-content-between"
        >
          <div style={{ width: 100 }}>
            <span>Statement : </span>
          </div>
          {loading || statementLoading ? (
            <CircularProgress size={20} />
          ) : (
            prefsData?._id && (
              <Checkbox
                onChange={() =>
                  onChangeStatus("STATEMENT", statementPreference)
                }
                checked={!!statementPreference?.emailType}
                value={!!statementPreference?.emailType}
                defaultChecked={!!statementPreference?.emailType}
              />
            )
          )}
        </div>
      </div>
      <div className={classes.preferenceItem}>
        {" "}
        <div
          style={{ minHeight: "40px" }}
          className="mt-2 d-flex flex-row align-items-center justify-content-between"
        >
          <div style={{ width: 130 }}>
            <span>App Notification : </span>
          </div>
          {loading || notificationLoading ? (
            <CircularProgress size={20} />
          ) : (
            orderPreferences && (
              <Checkbox
                onChange={(e) =>
                  onOfferNotificationStatusChange(
                    "APP_NOTIFICATION",
                    e.target.checked
                  )
                }
                defaultChecked={orderPreferences?.appNotification}
              />
            )
          )}
        </div>
        <div
          style={{ minHeight: "40px" }}
          className="mt-2 d-flex flex-row align-items-center justify-content-between"
        >
          <div style={{ width: 140 }}>
            <span>Email Notification : </span>
          </div>
          {loading || notificationLoading ? (
            <CircularProgress size={20} />
          ) : (
            orderPreferences && (
              <Checkbox
                onChange={(e) =>
                  onOfferNotificationStatusChange(
                    "EMAIL_NOTIFICATION",
                    e.target.checked
                  )
                }
                defaultChecked={orderPreferences?.emailNotification}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Preferences;
