import React, { useCallback, useEffect, useState } from "react";
import io from "socket.io-client";
import AdminButtonComponent from "../components/AdminButtonComponent";
import { toast } from "react-toastify";

const Socket = () => {
  const token = localStorage.getItem("accessToken");
  const [socket, setSocket] = useState<any>(null);
  const [textInput, setTextInput] = useState("");
  const [connectedUser, setConnectedUser] = useState("");
  const [connections, setConnections] = useState<any[]>([]);
  const [addedText, setAddedtexts] = useState<string[]>([]);

  useEffect(() => {
    if (token) {
      const newSocket = io(
        "https://socket-service-dot-frupro-staging.nw.r.appspot.com/",
        {
          extraHeaders: {
            Authorization: `Bearer ${token} `,
          },
        }
      );

      setSocket(newSocket);
    }
  }, [token]);

  useEffect(() => {
    if (socket) {
      socket.on("broadcast", (message:any) => {
        setAddedtexts((prev) => [...prev, message?.message]);
      });
      socket.on("connection", (message: any) => {
        if (!message?.success) {
          toast.error("Error on connection to socket");
        } else {
          setConnectedUser(message?.user?.fullName);
        }
      });
      socket.on("getConnections", (connRes: any) => {
        if (connRes?.success) {
          
          setConnections(connRes?.connectedUsers);
        }
      });
    }
  }, [socket]);

  const onSendMessage = useCallback(async () => {
    if (socket) {
      socket.emit("broadcast", textInput);
    }
  }, [textInput, socket]);
  return (
    <div className="p-2">
      <span>
        <span className="fw-bold">Connected User : </span>
        {connectedUser}
      </span>
      <div className="d-flex flex-row gap-2">
        <input
          style={{ border: "1px solid black" }}
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
        />
        <AdminButtonComponent onClick={onSendMessage} title="send" />
      </div>
      <div className="d-flex flex-column gap-2">
        {addedText.map((item) => {
          return <span>{item}</span>;
        })}
      </div>
      <hr style={{ opacity: 0.08 }} className="mt-4" />
      <div className="d-flex mt-2 flex-column gap-2">
        <span className="fw-bold">Connected users : </span>
        {connections?.map((item: any) => {
          return <span>{item?.user?.fullName}</span>;
        })}
      </div>
    </div>
  );
};

export default Socket;
