import React, { useCallback, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import {
  ResultsNotificationsType,
  useGetNotificationItem,
} from "../../hooks/useGetNotifications";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { sendNotificationAgain } from "../../services/notification";
import PermissionWrapper from "../../components/PermissionWrapper";
import { Helmet } from "react-helmet";

export interface NotificationDetailTypes {
  _id: string;
  content: string;
  deliveryDate: string;
  title: string;
  message: string;
  messageType: string;
  status: string;
  data: Data;
  createdAt: string;
  results: Result[];
  push_notification_id: string;
}

export interface Data {
  orders: Order[];
}

export interface Order {
  id: string;
  idType: string;
  offerODRCode: string;
  paymentCode: string;
}

export interface Result {
  _id: string;
  pushNotificationId: string;
  userId: string;
  token: string;
  device: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  push_notification_receipent_id: string;
  result: string;
  user: User;
}

export interface User {
  _id: string;
  email: string;
  fullName: string;
  companyName: string;
}

const useStyles = makeStyles({
  mainRow: {
    padding: 10,
    marginTop: 20,
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    borderRadius: "5px!important",
    background: "white!important",
    width: "100%",
    minHeight: "100%",
  },
  boldText: {
    fontWeight: 600,
  },
  successText: {
    color: "#097969",
  },
  failedText: {
    color: "red",
  },
});

const NotificationDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [sendLoading, setSendLoading] = useState(false);

  const { notification, setNotification, detailLoading, setIsFetch } =
    useGetNotificationItem(id || "");

  const onSendAgain = useCallback(async (id: string) => {
    setSendLoading(true);
    const sent = await sendNotificationAgain(id);
    setSendLoading(false);

    if (sent?.success) {
      setIsFetch((prev) => prev + 1);
    }
  }, []);

  return (
    <Sidebar pageTitle="Notification Detail">
       <Helmet>
          <title>Push Notifications | FruPro Admin Portal</title>
        </Helmet>
     <PermissionWrapper unauthorizedComponent permission="PUSH_NOTIFICATIONS">
     {detailLoading ? (
        <Skeleton
          style={{ borderRadius: 5 }}
          count={1}
          height={600}
          width="100%"
        />
      ) : (
        <>
          <div className="container">
            <div className="row">
              <div className={classes.mainRow}>
                <div className="d-flex flex-column gap-3">
                  {notification?.createdAt ? (
                    <span>
                      <span className={classes.boldText}> Create Date : </span>
                      {moment(notification?.createdAt).format("DD/MM/YYYY")}
                    </span>
                  ) : null}
                  {notification?.deliveryDate ? (
                    <span>
                      <span className={classes.boldText}> Delivery Date :</span>{" "}
                      {moment(notification?.deliveryDate).format("DD/MM/YYYY")}
                    </span>
                  ) : null}
                  {notification?.message ? (
                    <span>
                      <span className={classes.boldText}>
                        Notification Message :
                      </span>{" "}
                      <br />
                      {notification?.message}
                    </span>
                  ) : null}

                  {notification?.content ? (
                    <span>
                      <span className={classes.boldText}>
                        Notification Content :
                      </span>{" "}
                      <br />
                      {notification?.content}
                    </span>
                  ) : null}
                  {notification?.messageType ? (
                    <span>
                      <span className={classes.boldText}>
                        Notification Type :{" "}
                      </span>
                      <br />
                      {notification?.messageType}
                    </span>
                  ) : null}

                  {notification?.data?.orders?.length ? (
                    <span>
                      <span className={classes.boldText}>
                        {" "}
                        List of orders sent :
                      </span>{" "}
                      <br />
                      <div className="d-flex flex-column gap-2">
                        {notification?.data?.orders?.map((i, index) => {
                          return <span key={index}>{i.offerODRCode}</span>;
                        })}
                      </div>
                    </span>
                  ) : null}

                  {notification?.results.length ? (
                    <TableContainer>
                      <Table sx={{ maxWidth: 1000 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: 600 }}>
                              Company
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }}>
                              User
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }}>
                              Status
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {notification?.results?.map(
                            (row: Result, index: number) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.user.companyName}
                                </TableCell>
                                <TableCell>{row.user.fullName}</TableCell>
                                <TableCell>
                                  <span
                                    className={
                                      row.result === "SUCCESS"
                                        ? classes.successText
                                        : row.result === "FAILED"
                                        ? classes.failedText
                                        : ""
                                    }
                                  >
                                    {row.result}
                                  </span>
                                </TableCell>
                                <TableCell
                                  style={{ maxWidth: 70 }}
                                  component="th"
                                  scope="row"
                                >
                                  {row.result === "FAILED" ? (
                                    <AdminButtonComponent
                                      style={{ minWidth: 120 }}
                                      disabled={sendLoading}
                                      loading={sendLoading}
                                      onClick={() => onSendAgain(row._id)}
                                      title="send again"
                                    />
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
     </PermissionWrapper>
    </Sidebar>
  );
};

export default NotificationDetail;
