import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";
import * as streamSaver from "streamsaver";
import { expireDateVariables } from "../pages/CreditNotes/constants";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import * as FileSaver from "file-saver";
import moment from "moment";

export const goToTradeApp = async () => {
  try {
    const response = await axiosApiInstance.get(
      "/impersonation/admin-web-impersonate"
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getOfferInvoiceInfoFromCodes = async (data: any) => {
  try {
    const response = await axiosApiInstance.post("/get-info-from-codes", {
      ...data,
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getSupplierInventories = async (params = null) => {
  try {
    const response = await axiosApiInstance.get(
      "/inventory/registered-suppliers"
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getCompanyUsers = async (filterText: string, page: number) => {
  try {
    const response = await axiosApiInstance.post("/companies/users", {
      keyword: filterText ? filterText : undefined,
      page: page,
      limit: 50,
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getProductTypes = async (params = null) => {
  try {
    const response = await axiosApiInstance.get("/product-types", {
      params: params ? params : "",
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getSubTypesWithProductTypeId = async (withImage = false) => {
  try {
    const response = await axiosApiInstance.get(
      `/sub-product-types/with-product-type?getImageUrl=${withImage}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getCompanyTypesForms = async () => {
  try {
    const response = await axiosApiInstance.get("/info/company");

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getUnits = async () => {
  try {
    let result = await axiosApiInstance.get(`/units`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getCountries = async () => {
  try {
    const response = await axiosApiInstance.get("/countries");

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const downloadFile = (
  arraybuffer: any,
  fileName = "",
  isZip?: boolean
) => {
  if (typeof window === undefined) return;

  let blobType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const blob = new Blob([arraybuffer], {
    type: blobType,
  });

  try {
    const fileStream = streamSaver.createWriteStream(
      `${fileName}${isZip ? ".zip" : ".xlsx"}`,
      {
        size: blob.size,
      }
    );

    const readableStream = blob.stream();

    if (window.WritableStream && readableStream.pipeTo) {
      return readableStream.pipeTo(fileStream).then(() => {
        console.log("done writing");
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const getSignedUrl = async (data: any, type: any) => {
  return axiosApiInstance
    .post("sign-url", {
      fileName: data.name,
      contentType: data.type,
      type: type,
    })
    .then(({ data, status }) => {
      if (status === 200) return data;
      else return {};
    });
};
export const uploadFile = async (data: any, type: any) => {
  try {
    let { signedUrl, path, errors } = await getSignedUrl(data, type);

    if (errors) throw errors[0];
    if (!signedUrl) return;

    const file = new File([data], data.name);
    let result = await axiosApiInstance.put(signedUrl, file, {
      headers: {
        "Content-Type": data.type || "image/png",
      },
    });
    if (result?.status === 200)
      return {
        name: data.name,
        size: data.size,
        path,
      };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const updateUserCover = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(`/users/admin/update-cover`, {
      ...data,
    });
    if (response) {
      toast.success("Cover photo is updated successfully.");
      return response.data;
    }
    return null;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const getBuyerSeller = async () => {
  try {
    const response = await axiosApiInstance.get(
      `/companies?limit=9999&&getImageUrl=${false}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const getBuyerSellerOnlyCanAllocates = async () => {
  try {
    const response = await axiosApiInstance.get(
      `/companies?limit=9999&getImageUrl=${false}&onlyCanAllocate=${true}&status=VERIFIED`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getBuyerSellerWithoutCanAllocates = async () => {
  try {
    const response = await axiosApiInstance.get(
      `/companies?limit=9999&getImageUrl=${false}&status=VERIFIED`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getCurrencies = async () => {
  try {
    const response = await axiosApiInstance.get(`/currencies`);

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export function calculateFutureDate(timePeriod: string) {
  const hours = (expireDateVariables as any)[timePeriod];
  if (typeof hours !== "undefined") {
    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + hours * 3600 * 1000); // Convert hours to milliseconds
    return futureDate;
  } else {
    return null; // Return null for unknown time periods
  }
}

export function getTrimmedString(data: object) {
  const newData = Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      if (typeof value === "string") {
        return [key, value.trim()];
      } else {
        return [key, value];
      }
    })
  );
  return newData;
}

export function convertToDayJsFormat(dateString: string) {
  const count: any = dateString.split("").filter((letter) => letter === ".");

  // Split the date string into day, month, and year parts
  const [day, month, year] = dateString?.includes("/")
    ? dateString?.split("/")
    : dateString?.split(".");

  // Create a Day.js object using the parsed values
  const dayjsDate =
    dateString?.includes("/") ||
    (dateString?.includes(".") && count?.length > 1)
      ? dayjs(
          `${
            year.length === 2
              ? `20${year}`
              : year.includes(".")
              ? year.split(".")[0]
              : year
          }-${month}-${day}`
        )
      : dayjs(dateString, { format: "DD MMMM YYYY" });

  return dayjsDate;
}

export function formatNumber(input: string): string {
  if (!input) {
    return "";
  }
  // Remove thousands separator
  // If the input contains both commas and periods, the one that appears first is treated as the thousands separator
  if (input.includes(",") && input.includes(".")) {
    if (input.indexOf(",") < input.indexOf(".")) {
      input = input.replace(/,/g, ""); // Comma is thousands separator
    } else {
      input = input.replace(/\./g, ""); // Period is thousands separator
    }
  } else {
    // Remove either comma or period if they are likely to be thousands separator
    input = input.replace(/(\.|,)(?=(?:[0-9]{3})+(?:$|\s))/g, "");
  }

  // Replace decimal separator with a comma

  return input;
}

export const getYearsBetween = (startYear: any, endYear: any) => {
  // Validate input
  if (startYear > endYear) {
    return "Start year should be less than or equal to end year.";
  }

  // Create an array to store the years
  let yearsArray = [];

  // Loop through the range of years and push them into the array
  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year);
  }

  return yearsArray;
};

export const formattedStringWithoutCharacterNParanthesis = (string: string) => {
  const stringWithoutCharacters = string?.replace(/\([^)]*\)/g, "");

  // const stringWithoutParanthesis = stringWithoutCharacters?.replace(
  //   /[^a-zA-Z0-9 ]/g,
  //   ""
  // );

  const lowerCaseString = stringWithoutCharacters.toLowerCase();

  const words = ["limited", "ltd", "LIMITED", "LTD"];

  const response = words.reduce((acc, word) => {
    return acc.replace(new RegExp(word, "g"), "");
  }, lowerCaseString);

  return response;
};

export function convertProduceName(input: string): string {
  if (!input) {
    console.warn("Input is empty");
    return "";
  }
  // Remove numbers and their related parts at the beginning of the string
  input = input.replace(/^\d+\w*\s*/, "");

  // Remove content within parentheses (including the parentheses)
  input = input.replace(/\([^)]*\)/g, "");

  // Remove any part of the string that follows a number towards the end
  input = input.replace(/\s*\d.*$/, "");

  // Trim any leading or trailing whitespace
  return input.trim();
}

export const CleanString = (s: string) => {
  return s.replace(/[^a-zA-Z ]/g, "");
};

export const stringSimilarity = (
  str1: string,
  str2: string,
  gramSize: number = 2
) => {
  function getNGrams(s: string, len: number) {
    s = " ".repeat(len - 1) + s.toLowerCase() + " ".repeat(len - 1);
    let v = new Array(s.length - len + 1);
    for (let i = 0; i < v.length; i++) {
      v[i] = s.slice(i, i + len);
    }
    return v;
  }

  if (!str1?.length || !str2?.length) {
    return 0.0;
  }

  let s1 = str1.length < str2.length ? str1 : str2;
  let s2 = str1.length < str2.length ? str2 : str1;

  let pairs1 = getNGrams(s1, gramSize);
  let pairs2 = getNGrams(s2, gramSize);
  let set = new Set<string>(pairs1);

  let total = pairs2.length;
  let hits = 0;
  for (let item of pairs2) {
    if (set.delete(item)) {
      hits++;
    }
  }
  return hits / total;
};

export interface SearchResult {
  companyName: String;
  result: Number;
}

// export const Main = async () => {
// 	console.clear();
// 	console.log("STARTED================================================================\n");

// 	let results: Array<SearchResult> = [];

// 	for (const companyName of companies) {
// 		let result: SearchResult = {
// 			companyName: companyName,
// 			score: Number((Number(stringSimilarity(CleanString(searchString), CleanString(companyName), 2)) * 100).toFixed(2)),
// 		};

// 		results.push(result);
// 	}

// 	results.sort((a, b) => {
// 		const result = Number(a.result) - Number(b.result);
// 		return result * -1;
// 	});

// 	console.log("Search String: " + searchString);
// 	for (let i = 0; i < results.length; i++) {
// 		console.log(results[i]);

// 		if (i > 5) {
// 			break;
// 		}
// 	}

// 	console.log("\nFINISHED===============================================================");
// };

export const sortArray = (options: any) => {
  options.sort(function (a: any, b: any) {
    if (a.name?.toUpperCase() < b.name?.toUpperCase()) {
      return -1;
    }
    if (a.name?.toUpperCase() > b.name?.toUpperCase()) {
      return 1;
    }
    return 0;
  });

  return options;
};

export const isDarkReaderActive = () => {
  const darkReaderStyles = Array.from(document.querySelectorAll("style")).find(
    (style) => {
      return style.innerText.includes("darkreader");
    }
  );

  return !!darkReaderStyles;
};

export const convertToNumberDecimalString = (str: string) => {
  return parseFloat(str?.replace(",", ""));
};

export const exportToExcelAnArray = (arrayBuffer: any, fileName?: string) => {
  const data = new Uint8Array(arrayBuffer);

  // Create a workbook from the Uint8Array data
  const workbook = XLSX.read(data, { type: "array" });

  // Convert the workbook to a binary string
  const excelData = XLSX.write(workbook, { type: "binary" });

  // Convert the binary string to a Blob
  const blob = new Blob([s2ab(excelData)], {
    type: "application/octet-stream",
  });

  // Save the Blob as an Excel file using FileSaver.js
  FileSaver.saveAs(blob, fileName ? fileName : "file.xlsx");
};

function s2ab(s: any) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export const convertDateObjectToString = (_date: any): string | null => {
  if (
    !(
      _date instanceof Date ||
      typeof _date === "string" ||
      (typeof _date === "object" && _date !== null)
    )
  ) {
    return null;
  }

  const date =
    typeof _date === "object" && _date !== null
      ? Object.values(_date).find((value) => value instanceof Date)
      : _date;

  const formattedDate = moment.utc(date).format("DD/MM/YYYY");

  if (formattedDate === "Invalid date") {
    console.error("Could not convert date, resulting in Invalid date");
    return null;
  }

  return formattedDate;
};
