import {
  CircularProgress,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { getPaymentTerms } from "../../../services/surplus-allocation";
import { PaymentTermsType } from "../CreateNewSurplusContainer";

const useStyles = makeStyles((theme) => ({
  hr: {
    opacity: 0.4,
  },
  mainText: {
    fontSize: 18,
  },
  Input: {
    borderRadius: "10px!important",
    width: "100%",
    height: 45,
    padding: 15,
  },
  customControlledField: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
}));

const PaymentTerms = ({
  selectedPaymentTerm,
  setSelectedPaymentTerm,
  setPaymentTerms,
  paymentTerms,
  paymentTermsLoading,
}: {
  selectedPaymentTerm: string;
  setSelectedPaymentTerm: React.Dispatch<React.SetStateAction<string>>;
  setPaymentTerms: React.Dispatch<React.SetStateAction<PaymentTermsType[]>>;
  paymentTerms: PaymentTermsType[];
  paymentTermsLoading: boolean;
}) => {
  const classes = useStyles();

  return (
    <div className="mt-1">
      <span className={classes.mainText}>Payment Terms</span>
      <Divider className={classes.hr} />
      {paymentTermsLoading ? (
        <div className="pt-3 d-flex align-items-center justify-content-center">
          <CircularProgress size={30} />
        </div>
      ) : (
        <div className="container ">
          <RadioGroup
            className="mt-2"
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            {paymentTerms.map((term, index) => {
              return (
                <div key={index}>
                  <FormControlLabel
                    defaultChecked
                    checked={term.paymentTerm === selectedPaymentTerm}
                    value={term.paymentTerm}
                    onChange={(e: any) =>
                      setSelectedPaymentTerm(e.target?.value)
                    }
                    control={<Radio style={{ color: "#E77228" }} />}
                    label={`${term?.paymentTerm?.split("D")?.[0]} Days`}
                  />
                </div>
              );
            })}
          </RadioGroup>
        </div>
      )}
    </div>
  );
};

export default PaymentTerms;
