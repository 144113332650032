import React, { useCallback } from "react";
import ControlledComboBox from "../../components/ControlledComboBox";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { OrderDocumentsDownloadState } from "./interface";
import { makeDocumentRequest } from "../../services/reports";

const useStyles = makeStyles({
  opacity: { margin: "20px 0 20px 0", opacity: 0.06 },
  docReqText: {
    fontWeight: 800,
    fontSize: 18,
  },
  requestButton: {
    borderRadius: "10px!important",
    height: 45,
    width: "100%",
    background: "white!important",
    color: "#E77228!important",
    border: "1px solid #E77228!important",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
});

const documentNames = [
  "Shipping Document",
  "Picker Notes",
  "Credit Notes",
  "QC(Quality Control) report",
  "Invoice",
  "Purchase Order",
  "Sales Report",
  "Certificate of Origin",
  "Pesticides Record",
  "LOI (Letter of Indemnity)",
  "Packing List",
  "Logistics Booking Confirmation",
  "Logistics Forward Cargo Receipt",
  "Logistics BOL",
  "Logistics Airway Bill",
  "Others",
];

const RequestDocuments = ({
  setValue,
  control,
  onSearch,
  setRefetch,
  loading,
  downloadData,
  watch,
  setLoading,
}: {
  watch: any;
  loading:boolean
  setValue: any;
  onSearch: () => void;
  control: any;
  setRefetch: React.Dispatch<React.SetStateAction<number>>;
  downloadData?: OrderDocumentsDownloadState;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const classes = useStyles();
  const watchDocName = watch("documentName");
  const watchReqFrom = watch("requestFrom");

  const onRequestDoc = useCallback(async () => {
    if (!watchDocName || !watchReqFrom) {
      toast.error("Please fill fields for make a request.");
    } else {
      setLoading(true);
      const finalData = {
        requestedDocumentName: watchDocName,
        orderId: downloadData?._id,
        requestedFrom:
          watchReqFrom === "Seller"
            ? "seller"
            : watchReqFrom === "Buyer"
            ? "buyer"
            : undefined,
      };
      const response = await makeDocumentRequest(finalData);
      setLoading(false);
      if (response) {
        onSearch();
        setValue("documentName", "");
        setValue("requestFrom", "");
      }
    }
  }, [watchDocName, watchReqFrom]);

  return (
    <div>
      <div>
        <span className={classes.docReqText}>Requested Documents</span>

        <div className="d-flex mt-2 flex-column gap-2">
          {downloadData?.order_document_requests.map((req, index) => {
            return (
              <div key={index}>
                <span>
                  {index + 1}. {req.requestedDocumentName}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <hr className={classes.opacity} />
      <span className={classes.docReqText}>Request Documents</span>
      <div className="row mt-2 d-flex align-items-center">
        <div className="col-md-5">
          <ControlledComboBox
          autoSelect
            className={classes.comboBox}
            freeSolo
            textLabel="Document Name"
            options={documentNames}
            control={control}
            name="documentName"
          />
        </div>
        <div className="col-md-4">
          <ControlledComboBox
          defaultValue={"Seller"}
            className={classes.comboBox}
            textLabel="Request From"
            options={["Buyer", "Seller"]}
            control={control}
            name="requestFrom"
          />
        </div>
        <div className="col-md-3 mt-3">
          <AdminButtonComponent
          disabled={loading}
            className={classes.requestButton}
            title={loading?"Please wait..":"Request"}
            onClick={onRequestDoc}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestDocuments;
