import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getVerificationRequests = async (
  queryParams: any,
  filterData: any
) => {
  try {
    const response = await axiosApiInstance.get(
      `credit-requests/get/requests?limit=20&page=${queryParams?.page}${
        filterData?.companyName ? `&companyName=${filterData?.companyName}` : ""
      }${
        filterData?.buyerStatus ? `&buyerStatus=${filterData?.buyerStatus}` : ""
      }${
        filterData?.sellerStatus
          ? `&sellerStatus=${filterData?.sellerStatus}`
          : ""
      }`
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const approveRejectSeller = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/credit-requests/update/seller-request`,
      {
        ...data,
      }
    );

    if (response) {
      toast.success("Request is updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const approveRejectBuyer = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/credit-requests/update/buyer-request`,
      {
        ...data,
      }
    );

    if (response) {
      toast.success("Request is updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const approveRejectLimit = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/credit-requests/update/buyer-increase-request`,
      {
        ...data,
      }
    );

    if (response) {
      toast.success("Request is updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const increaseBuyerLimit = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/credit-requests/update/buyer-total-credit`,
      {
        ...data,
      }
    );

    if (response) {
      toast.success("Limit is updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addNewBuyer = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/finmid-platform/buyer-create`,
      {
        ...data,
      }
    );

    if (response) {
      toast.success("Buyer is created successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error.response?.data?.errors?.[0]?.message ||
          error.response?.data?.message
      );
    }
  }
};

export const addNewSeller = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/finmid-platform/seller-create`,
      {
        ...data,
      }
    );

    if (response) {
      toast.success("Seller is created successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error.response?.data?.errors?.[0]?.message ||
          error.response?.data?.message
      );
    }
  }
};

export const checkBuyerSeller = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/finmid-platform/get-company-detail`,
      {
        ...data,
      }
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error.response?.data?.errors?.[0]?.message ||
          error.response?.data?.message
      );
    }
  }
};
