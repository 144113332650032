import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";
import React from "react";

export const getServiceFeeInvoices = async (data: any) => {
  try {
    const response = await axiosApiInstance.post("/admin/get-service-files", {
      ...data,
    });

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.message || error.response?.data.message);
    }
  }
};

export const createServiceFeeInvoice = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/create-service-fee-file",
      {
        ...data,
      }
    );

    if (response) {
      toast.success("Service fee invoice created successfully.", {
        autoClose: 5000,
      });
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const deleteServiceFeeInvoice = async (id: string) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/delete-service-fee-file",
      {
        file_storage_id: id,
      }
    );

    if (response) {
      toast.success("Service fee invoice deleted successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};
