import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Title,
} from "@tremor/react";
import { DashboardState } from "../../interface";
import NumberFormatComponent from "../../../../components/NumberFormatComponent";
import { makeStyles } from "@mui/styles";
import { isDarkReaderActive } from "../../../../services/common";

const useStyles = makeStyles({
  darkModeTextColor: {
    fontWeight: 600,
    color: "white!important",
  },
  seeAllLimitsText: {
    cursor: "pointer",
    fontSize: 16,
    textDecoration: "underline",
  },
  darkModeHeaderTextColor: {
    fontWeight: 600,
    color: "white!important",
  },
  darkModeSeeAllTextColor: {
    cursor: "pointer",
    fontWeight: 600,
    textDecoration: "underline",
    color: "white!important",
  },
  seeAllCompsText: {
    cursor: "pointer",
    fontSize: 16,
    textDecoration: "underline",
  },
});

const CompanyLimitsTable = ({
  isDarkMode,
  dashboardData,
}: {
  isDarkMode: boolean;
  dashboardData?: DashboardState;
}) => {
  const classes = useStyles();
  return (
    <Card decoration="bottom" decorationColor="lime" className="mt-2">
      <div className="d-flex justify-content-between">
        <Title
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.darkModeHeaderTextColor
              : ""
          }
          color="lime"
        >
          Company Limits
        </Title>
        <a href={"/company-limits"} target="_blank">
          <Title
            className={
              isDarkMode || isDarkReaderActive()
                ? classes.darkModeSeeAllTextColor
                : classes.seeAllCompsText
            }
            color="lime"
          >
            See all
          </Title>
        </a>
      </div>
      <Table className="mt-2">
        <TableHead>
          <TableRow>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Company Name
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Used Limit
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Remaining Limit
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Total Credit Limit
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Trading Agent
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dashboardData?.companyLimits.map((item, index) => (
            <TableRow key={index}>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {item.companyName}
              </TableCell>

              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {
                  <NumberFormatComponent
                    value={item.verifiedBuyer.creditLimit.spent}
                  />
                }
              </TableCell>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {" "}
                {
                  <NumberFormatComponent
                    value={item.verifiedBuyer.creditLimit.remaining}
                  />
                }
              </TableCell>
              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {
                  <NumberFormatComponent
                    value={item.verifiedBuyer.creditLimit.total}
                  />
                }
              </TableCell>

              <TableCell
                className={
                  isDarkMode || isDarkReaderActive()
                    ? classes.darkModeTextColor
                    : ""
                }
              >
                {item?.managers?.admin?.[0]?.fullName || ""}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default CompanyLimitsTable;
