import React, { useEffect, useMemo } from "react";
import { Sidebar } from "../../components/Sidebar";
import { getDashboards } from "../../services/dashboards";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet";
import PaymentsTable from "./components/Tables/PaymentsTable";
import TradesTable from "./components/Tables/TradesTable";
import OnboardedCompaniesTable from "./components/Tables/OnboardedCompaniesTable";
import CompanyLimitsTable from "./components/Tables/CompanyLimitsTable";
import SalesCard from "./components/Cards/SalesCard";
import InvoicesCard from "./components/Cards/InvoicesCard";
import TradesCard from "./components/Cards/TradesCard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { DashboardState } from "./interface";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import usePermissions from "../../hooks/usePermissions";
import PermissionWrapper from "../../components/PermissionWrapper";
import NonInvoicedSalesTable from "./components/Tables/NonInvoicedSalesTable";
import OverdueInvoicesContainer from "./components/Tables/OverdueInvoicesContainer";
import ForecastedTradesTable from "./components/Tables/ForecastedTradesTable";
import { setDashboardData } from "../../slices/dashboardSlice";
import useSyncedData from "../../hooks/useSyncedData";
import {
  deleteWsData,
  resetWsData,
  setWsDataVersion,
} from "../../slices/wsDataSlice";

const useStyles = makeStyles({
  card: {
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    height: "200vh",
    display: "flex",
    padding: 20,
    borderRadius: 20,
    background: "white",
    flexDirection: "column",
    width: "100%",
    gap: 20,
  },
  iframe: {
    height: "100vh",
  },
  cardInfoStyle: {
    fontSize: 16,
  },

  cardHeaderStyle: {
    fontSize: 20,
  },
  recentActivities: {
    fontSize: 20,
    fontWeight: 600,
  },
});

const Main: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { hasPermission } = usePermissions();
  const dashboardData = useAppSelector(
    (state) => state.dashboard.dashboardData
  );

  const poundCurrency = useAppSelector((state) =>
    state.auth.currencies.find((item) => item.code === "GBP")
  )?.symbol;

  const hasDashboardPermission = useMemo(() => {
    return hasPermission("ADMIN_DASHBOARD_VIEW");
  }, [hasPermission]);

  useEffect(() => {
    if (!hasDashboardPermission) return;
    const getAllDashboards = async () => {
      const response = await getDashboards();

      const dashboardRes = response?.find(
        (res: any) => res.group === "admin-dashboard-data"
      )?.data;
      dispatch(setDashboardData(dashboardRes as DashboardState));
    };

    getAllDashboards();
  }, [dispatch, hasDashboardPermission]);

  return (
    <Sidebar pageTitle="Dashboard">
      <PermissionWrapper
        unauthorizedComponent={true}
        permission="ADMIN_DASHBOARD_VIEW"
      >
        <div style={{ overflow: "hidden" }}>
          <Helmet>
            <title>Dashboard | FruPro Admin Portal</title>
          </Helmet>
          <div className="p-1 mt-2">
            <div className="row g-3">
              <div className="col-md-4">
                {!dashboardData ? (
                  <Skeleton
                    style={{ height: 400, borderRadius: 10 }}
                    count={1}
                  />
                ) : (
                  <SalesCard
                    isDarkMode={false}
                    pound={poundCurrency}
                    dashboardData={dashboardData}
                  />
                )}
              </div>
              <div className="col-md-4">
                {!dashboardData ? (
                  <Skeleton
                    style={{ height: 400, borderRadius: 10 }}
                    count={1}
                  />
                ) : (
                  <InvoicesCard
                    isDarkMode={false}
                    pound={poundCurrency}
                    dashboardData={dashboardData}
                  />
                )}
              </div>
              <div className="col-md-4">
                {!dashboardData ? (
                  <Skeleton
                    style={{ height: 400, borderRadius: 10 }}
                    count={1}
                  />
                ) : (
                  <TradesCard
                    isDarkMode={false}
                    pound={poundCurrency}
                    dashboardData={dashboardData}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="mt-3 p-1">
            <span className={classes.recentActivities}>Recent Activities</span>
            {!dashboardData ? (
              <Skeleton
                style={{ height: "300px", borderRadius: 10 }}
                count={1}
              />
            ) : (
              <PaymentsTable isDarkMode={false} dashboardData={dashboardData} />
            )}
            {!dashboardData ? (
              <Skeleton
                style={{ height: "300px", borderRadius: 10 }}
                count={1}
              />
            ) : (
              <TradesTable isDarkMode={false} dashboardData={dashboardData} />
            )}
            {!dashboardData ? (
              <Skeleton
                style={{ height: "300px", borderRadius: 10 }}
                count={1}
              />
            ) : (
              <OnboardedCompaniesTable
                isDarkMode={false}
                dashboardData={dashboardData}
              />
            )}
            {!dashboardData ? (
              <Skeleton
                style={{ height: "300px", borderRadius: 10 }}
                count={1}
              />
            ) : (
              <CompanyLimitsTable
                isDarkMode={false}
                dashboardData={dashboardData}
              />
            )}
            {!dashboardData ? (
              <Skeleton
                style={{ height: "300px", borderRadius: 10 }}
                count={1}
              />
            ) : (
              <NonInvoicedSalesTable
                isDarkMode={false}
                dashboardData={dashboardData}
              />
            )}
            {!dashboardData ? (
              <Skeleton
                style={{ height: "300px", borderRadius: 10 }}
                count={1}
              />
            ) : (
              <OverdueInvoicesContainer
                isDarkMode={false}
                dashboardData={dashboardData}
              />
            )}

            {!dashboardData ? (
              <Skeleton
                style={{ height: "300px", borderRadius: 10 }}
                count={1}
              />
            ) : (
              <ForecastedTradesTable
                isDarkMode={false}
                dashboardData={dashboardData}
              />
            )}
          </div>
        </div>
      </PermissionWrapper>

      {/* <div className={classes.card}>
        {charts.length
          ? charts[0].charts.map((item) => {
              return <iframe className={classes.iframe} src={item}></iframe>;
            })
          : null}
      </div> */}
    </Sidebar>
  );
};
export default Main;
