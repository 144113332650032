import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import { getSellerFees } from "../../services/fees";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow } from "@mui/material";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Helmet } from "react-helmet";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";
const columns = [
  { id: "seller", name: "Seller" },
  { id: "fee", name: "Fee" },
];

interface paginationDataType {
  page: number;
  limit: number;
  totalPage: number;
  total: number;
}

interface sellerFeeStateType {
  companyName: string;
  fee: number;
  _id: string;
}

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
});

const SellerFeeContainer: React.FC = () => {
  const classes = useStyles();

  const {hasPermission}=usePermissions()

  const [queryParams, setQueryParams] = useState<paginationDataType>({
    page: 1,
    limit: 1,
    totalPage: 1,
    total: 1,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [sellerFees, setSellerFees] = useState<sellerFeeStateType[]>([]);

  useEffect(() => {
    if(!hasPermission("COMPANY_VIEW_DETAILS"))return
    const getUsersSellerFees = async () => {
      setFetchLoading(true);
      setTimeout(async () => {
        const response = await getSellerFees(queryParams.page);
        setFetchLoading(false);
        if (response) {
          setSellerFees(response.items);
          setQueryParams({
            page: response.page,
            totalPage: response.total,
            total: 1,
            limit: 1,
          });
        }
      }, 1000);
    };
    getUsersSellerFees();
  }, [queryParams.page,hasPermission("COMPANY_VIEW_DETAILS")]);

  const renderSellerFeesTableBody = () => {
    return (
      <>
        <Helmet>
          <title>Seller Fees | FruPro Admin Portal</title>
        </Helmet>
        {sellerFees.map((fee, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {fee.companyName}
              </TableCell>
              <TableCell component="th" scope="row">
                %{fee.fee}
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <Sidebar pageTitle="Seller Fees">
      {loading && <LoadingIndicator loading />}
      <PermissionWrapper
        unauthorizedComponent={true}
        permission="COMPANY_VIEW_DETAILS"
      >
        <TableComponent
        loading={fetchLoading}
          showRightButton={false}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          TableBodyComponent={renderSellerFeesTableBody}
          showFilterIcon={false}
          columns={columns}
        />{" "}
      </PermissionWrapper>
    </Sidebar>
  );
};

export default SellerFeeContainer;
