import { useCallback, useEffect } from "react";
import { SocketData } from "../types/SocketData";
import useConnectWebSocket from "./useConnectWebSocket";

const NEW_DATA_EVENT_NAME = "getNewVersionData";

export default function useWebSocket({
  callback,
}: {
  callback: (data: any) => void;
}) {
  const { socket, id } = useConnectWebSocket();

  const retrieveData = useCallback(
    (type: SocketData, version: number) => {
      if (!id || !socket) return;

      socket.emit(NEW_DATA_EVENT_NAME, {
        requestedData: [
          {
            object_name: type,
            version: version,
          },
        ],
        clientId: id,
      });
    },
    [socket, id]
  );

  useEffect(() => {
    if (!socket || !id) return;

    socket.on(id, (data: any) => {
      callback(data);
    });

    return () => {
      socket.off(id);
    };
  }, [id, socket, callback]);

  return {
    retrieveData,
  };
}
