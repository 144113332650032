import {
  CircularProgress,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import ControlledComboBox from "../../../components/ControlledComboBox";
import InfoIcon from "@mui/icons-material/Info";

export interface BankAccountsDefaultTypes {
  defaultBankDetails: DefaultBankDetail[];
  companyBankDetails: CompanyBankDetail[];
}

export interface DefaultBankDetail {
  companyId: string;
  bankDetails: BankDetails;
  company_bank_details_id: string;
}

export interface BankDetails {
  title: string;
  currency: string;
  bankName: string;
  accountName: string;
  accountNo: string;
  iban: string;
  sortCode: string;
  swift: string;
  _id?: string;
}

export interface CompanyBankDetail {
  _id: string;
  sellerId: string;
  bankDetails: BankDetail[];
  buyerId?: string;
  updatedAt: string;
  deleted: boolean;
  createdAt?: string;
  __v?: number;
}

export interface BankDetail {
  type: string;
  bankName: string;
  accountName: string;
  companyBankDetailId?: string;
  iban: string;
  swift: string;
  accountNo: string;
  sortCode: string;
  paymentNote: string;
  _id: string;
  currency: string;
}

const useStyles = makeStyles({
  hr: {
    opacity: 0.4,
  },
  mainText: {
    fontSize: 18,
  },
  customControlledField: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "200px",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      width: "100%",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
});
const BankInfo = ({
  selectedBankDetailId,
  setSelectedBankDetailId,
  setValue,
  bankInfos,
  control,
  watch,
  loading,
  setBankInfos,
}: {
  setBankInfos: any;
  selectedBankDetailId: string;
  setSelectedBankDetailId: any;
  setValue: any;
  bankInfos?: BankAccountsDefaultTypes;
  control: any;
  watch: any;
  loading: boolean;
}) => {
  const classes = useStyles();

  const sellerCompanyId = watch("sellerCompany")?.id;
  const buyerCompanyId = watch("buyerCompany")?.id;
  const orderCurrency = watch("creditLimitCurrency")?.name;

  const filteredDefaultBankInfosByCurrency: DefaultBankDetail[] | undefined =
    useMemo(() => {
      return bankInfos?.defaultBankDetails?.filter(
        (i) => i.bankDetails.currency === orderCurrency
      );
    }, [bankInfos?.defaultBankDetails, orderCurrency]);

  const companyAllBankAccounts: BankDetails[] = useMemo(() => {
    let tempAllAccounts: any = [];

    bankInfos?.companyBankDetails?.map((i) =>
      tempAllAccounts.push(...i.bankDetails)
    );

    return tempAllAccounts;
  }, [bankInfos?.companyBankDetails]);

  useEffect(() => {
    if (sellerCompanyId && buyerCompanyId && orderCurrency) {
      const sellerBuyerDefaultBankAccountDetails =
        bankInfos?.companyBankDetails?.find(
          (item) =>
            item.buyerId === buyerCompanyId &&
            item.sellerId === sellerCompanyId
        );

      const foundDefaultBankDetail = filteredDefaultBankInfosByCurrency?.find(
        (i) =>
          sellerBuyerDefaultBankAccountDetails?.bankDetails.find(
            (item) => i.company_bank_details_id === item.companyBankDetailId
          )
      );

      if (foundDefaultBankDetail?.company_bank_details_id) {
        setSelectedBankDetailId(foundDefaultBankDetail.company_bank_details_id);
      } else {
        setSelectedBankDetailId("");
      }
    } else {
      setSelectedBankDetailId("");
      setBankInfos([]);
    }
  }, [
    bankInfos?.companyBankDetails,
    setBankInfos,
    setSelectedBankDetailId,
    sellerCompanyId,
    buyerCompanyId,
    orderCurrency,
    filteredDefaultBankInfosByCurrency,
    companyAllBankAccounts,
  ]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <span className={classes.mainText}>Bank Accounts</span>
        <Tooltip
          arrow
          title={
            <>
              <span>
                You have to choose buyer,seller and currency to see accounts and
                accounts will be filtered by selected currency.
              </span>
            </>
          }
          placement="top"
        >
          <InfoIcon color="primary" />
        </Tooltip>
      </div>
      <Divider className={classes.hr} />

      <div className="mt-3">
        {loading ? (
          <CircularProgress className="mt-2" size={20} />
        ) : (
          <div className="d-flex  flex-row gap-2 align-items-center">
            {sellerCompanyId && buyerCompanyId && orderCurrency ? (
              <div className="container ">
                {!filteredDefaultBankInfosByCurrency?.length ? (
                  <span>No account found</span>
                ) : (
                  <RadioGroup
                    className="mt-2"
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    {filteredDefaultBankInfosByCurrency?.map((item, index) => {
                      return (
                        <div key={index}>
                          <FormControlLabel
                            defaultChecked
                            checked={
                              item.company_bank_details_id ===
                              selectedBankDetailId
                            }
                            value={item.company_bank_details_id}
                            onChange={(e: any) =>
                              setSelectedBankDetailId(e.target?.value)
                            }
                            control={<Radio style={{ color: "#E77228" }} />}
                            label={item.bankDetails.title}
                          />
                        </div>
                      );
                    })}
                  </RadioGroup>
                )}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default BankInfo;
