import { useCallback, useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import { getCompanyLimits } from "../../services/company";
import { Chip, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NumberFormatComponent from "../../components/NumberFormatComponent";
import AdminModalComponent from "../../components/AdminModalComponent";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import ControlledComboBox from "../../components/ControlledComboBox";
import { getBuyerSeller } from "../../services/common";
import LoadingIndicator from "../../components/LoadingIndicator";
import FilterBadge from "../../components/FilterBadge";
import { Helmet } from "react-helmet";

const schema = yup.object().shape({
  companyName: yup.array(),
});

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  approvedChip: {
    background: "#69CDAE!important",
    color: "white!important",
  },
  rejectedChip: {
    color: "white!important",
    background: "red!important",
  },
  companyName: {
    color: "#E77228",
    textDecoration: "underline",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});

export interface Limits {
  _id: string;
  companyName: string;
  verifiedBuyer: VerifiedBuyer;
}

export interface VerifiedBuyer {
  creditLimit: CreditLimit;
  status: string;
}

export interface CreditLimit {
  total: number;
  remaining: number;
  spent: number;
}

const columns = [
  {
    name: "Company Name",
    id: "companyName",
  },
  {
    name: "Total Limit",
    id: "totalLimit",
  },
  {
    name: "Spent Limit",
    id: "spentLimit",
  },
  {
    name: "Remaining Limit",
    id: "remainingLimit",
  },
  {
    name: "Status",
    id: "status",
  },
];

const CompanyLimitsContainer = () => {
  const { control, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyName: [],
    },
  });

  const watchCompanyIds = watch("companyName");
  const classes = useStyles();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [companyListLoading, setCompanyListLoading] = useState<boolean>(false);
  const [isClearFilterKey, setIsClearFilterKey] = useState<number>(0);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [isFetchCompanyList, setIsFetchCompanyList] = useState(false);
  const [deletedFilterKey, setDeletedFilterKey] = useState("");
  const [companies, setCompanies] = useState<Limits[]>([]);
  const [companyList, setCompanyList] = useState<
    { name: string; _id: string }[]
  >([]);
  const [filterData, setFilterData] = useState<{ companyName?: string[] }>({
    companyName: undefined,
  });

  const onOpenModal = () => {
    setOpenFilterModal(true);
  };
  const onCloseModal = () => {
    setOpenFilterModal(false);
  };

  const onFilterLimits = useCallback(() => {
    setOpenFilterModal(false);
    setQueryParams({
      ...queryParams,
      page: 1,
    });
    setFilterData({
      companyName: watchCompanyIds?.map((i: any) => i?._id) as string[],
    });
  }, [queryParams, watchCompanyIds]);

  useEffect(() => {
    if (isClearFilterKey && deletedFilterKey) {
      setValue(`${deletedFilterKey}` as any, undefined);
    }
  }, [deletedFilterKey, isClearFilterKey]);

  useEffect(() => {
    const getLimits = async () => {
      setLoading(true);
      const response = await getCompanyLimits(
        queryParams.page,
        filterData.companyName || []
      );
      setLoading(false);
      setCompanies(response?.companies);
      setQueryParams({
        page: response?.pagination?.page,
        totalPage: response?.pagination?.totalPages,
        total: response?.pagination?.totalDocs,
        limit: 1,
      });
    };

    getLimits();
    return () => {
      setCompanies([]);
    };
  }, [queryParams.page, filterData]);

  useEffect(() => {
    if (isFetchCompanyList && !companyList.length) {
      const getCompanyList = async () => {
        setCompanyListLoading(true);
        const response = await getBuyerSeller();
        setCompanyListLoading(false);

        if (response) {
          setCompanyList(
            response?.items?.map((i: any) => ({
              name: i.companyName,
              _id: i?.id,
            }))
          );
        }
      };
      getCompanyList();
      setIsFetchCompanyList(false);
    }
  }, [isFetchCompanyList, companyList.length]);

  const renderTableBody = () => {
    return (
      <>
        {companies.map((c, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <a
                  className={classes.companyName}
                  target="_blank"
                  href={`/company-list/${c._id}`}
                >
                  {c.companyName}
                </a>
              </TableCell>
              <TableCell component="th" scope="row">
                <NumberFormatComponent
                  value={c.verifiedBuyer.creditLimit.total}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <NumberFormatComponent
                  value={c.verifiedBuyer.creditLimit.spent}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <NumberFormatComponent
                  value={c.verifiedBuyer.creditLimit.remaining}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Chip
                  className={
                    c.verifiedBuyer.status === "APPROVED"
                      ? classes.approvedChip
                      : c.verifiedBuyer.status === "REJECTED"
                      ? classes.rejectedChip
                      : ""
                  }
                  label={c.verifiedBuyer.status}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderFilterModal = () => {
    return (
      <>
        <div>
          <ControlledComboBox
            onFocus={() => setIsFetchCompanyList(true)}
            multiple
            className={classes.comboBox}
            options={companyList}
            control={control}
            name="companyName"
            textLabel="Company Name"
          />
        </div>
        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenFilterModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={onFilterLimits}
            title="Submit"
          />
        </div>
      </>
    );
  };
  return (
    <>
      <Sidebar pageTitle="Company Limits">
      <Helmet>
          <title>Company Limits | FruPro Admin Portal</title>
        </Helmet>
        {companyListLoading && (
          <LoadingIndicator loading={companyListLoading} />
        )}
        <TableComponent
          filterBadge={
            <FilterBadge
              isClearFilterKey={isClearFilterKey}
              setIsClearFilterKey={setIsClearFilterKey}
              sellers={companyList}
              filterData={filterData}
              setFilterData={setFilterData}
              setDeletedFilterKey={setDeletedFilterKey}
            />
          }
          onOpenModal={onOpenModal}
          showFilterIcon
          loading={loading}
          showRightButton={false}
          TableBodyComponent={renderTableBody}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          columns={columns}
        />
      </Sidebar>
      <AdminModalComponent
        children={renderFilterModal()}
        headerTitle="Filter Limits"
        closeModal={onCloseModal}
        openModal={openFilterModal}
      />
    </>
  );
};

export default CompanyLimitsContainer;
