import axios from "axios";
import { axiosApiInstance } from "./axios-instance";
import { toast } from "react-toastify";

export const getUpcomingDueInvoices = async (page: number) => {
  try {
    const response = await axiosApiInstance.post("/upcoming-due-invoices", {
      page: page,
      limit: 20,
      getUrls: true,
    });

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message || error?.message);
    }
  }
};
