import React, { useCallback, useState } from "react";
import PermissionWrapper from "../../../components/PermissionWrapper";
import { makeStyles } from "@mui/styles";
import AdminModalComponent from "../../../components/AdminModalComponent";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import {
  addCompanyVideos,
  removeCompanyVideos,
} from "../../../services/company";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import ControlledTextField from "../../../components/ControlledTextField";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  videoUrl: yup.string().url("Must be a valid url"),
});

const useStyles = makeStyles({
  headerDocsText: {
    fontWeight: 600,
    fontSize: 20,
  },
  trash: {
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: 22,
    alignItems: "center",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    background: "white",
    width: 30,
    height: 30,
    cursor: "pointer",
    color: "red",
  },
  uploadDoc: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
    color: "#e77228",
    border: "1px solid",
    borderRadius: 10,
    padding: 5,
    fontWeight: 600,
  },
  hr: {
    opacity: 0.05,
    marginTop: 15,
  },
  videoItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    position: "relative",

    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    marginTop: 15,

    minHeight: 300,
    borderRadius: 10,
    background: "#f8f8f8",
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#e77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  deleteButton: {
    width: "50%",
    height: "50px",
    background: "red !important",
    borderRadius: "20px !important",
  },
  playIconImg: {
    width: 50,
    height: 50,
    cursor: "pointer",
  },
});
const Videos = ({
  videos,
  setRefreshRequest,
}: {
  videos: any[];
  setRefreshRequest: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { control, reset, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { videoUrl: "" },
  });

  const classes = useStyles();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [actionsLoading, setActionsLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState("");

  const { id } = useParams();

  const onOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const onCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const onOpenConfirmModal = (url: string) => {
    setSelectedUrl(url);
    setOpenConfirmModal(true);
  };
  const onCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const onAddVideo = useCallback(
    async (data: any) => {
      if (!data?.videoUrl) {
        toast.error("Please enter an valid url");
      } else {
        setActionsLoading(true);

        const finalData = {
          companyId: id,
          videos: [
            ...videos.map((v) => ({ videoUrl: v?.videoUrl })),
            {
              videoUrl: data?.videoUrl,
            },
          ],
        };
        const response = await addCompanyVideos(finalData);
        setActionsLoading(false);

        if (response) {
          reset();
          setOpenAddModal(false);
          setRefreshRequest((prev: number) => prev + 1);
        }
      }
    },
    [id, videos.length]
  );

  const onDeleteVideo = useCallback(async () => {
    setActionsLoading(true);
    const finalData = {
      companyId: id,
      videoUrl: selectedUrl,
    };
    const response = await removeCompanyVideos(finalData);
    setActionsLoading(false);
    if (response) {
      setSelectedUrl("");
      setRefreshRequest((prev: number) => prev + 1);
      setOpenConfirmModal(false);
    }
  }, [selectedUrl, id]);

  const renderConfirmModalBody = () => {
    return (
      <>
        <span>Are you sure you want to delete this video?</span>

        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            disabled={actionsLoading}
            className={classes.closeButton}
            onClick={onCloseConfirmModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={actionsLoading}
            disabled={actionsLoading}
            className={classes.deleteButton}
            onClick={onDeleteVideo}
            title="Delete"
          />
        </div>
      </>
    );
  };
  const renderAddModalBody = () => {
    return (
      <>
        <ControlledTextField
          name="videoUrl"
          control={control}
          textLabel="Video Url"
        />

        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            disabled={actionsLoading}
            className={classes.closeButton}
            onClick={onCloseAddModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={actionsLoading}
            disabled={actionsLoading}
            className={classes.saveButton}
            onClick={handleSubmit(onAddVideo)}
            title="Submit"
          />
        </div>
      </>
    );
  };
  return (
    <div className="row">
      <div className="d-flex justify-content-between align-items-center">
        <span className={classes.headerDocsText}>Videos</span>
        <PermissionWrapper permission="COMPANY_UPDATE">
          <span onClick={onOpenAddModal} className={classes.uploadDoc}>
            {" "}
            {"+ Upload Video"}
          </span>
        </PermissionWrapper>
      </div>
      <hr className={classes.hr} />

      {videos.map((v, index) => {
        return (
          <div key={index} className="col-md-4">
            <div
              className={`${classes.videoItem} `}
              style={{
                backgroundImage: `url(https://i1.ytimg.com/vi/${
                  v?.videoUrl.split("/embed/")[1]
                }/hq720.jpg)`,
              }}
            >
              <PermissionWrapper permission="COMPANY_UPDATE">
                <i
                  onClick={() => onOpenConfirmModal(v?.videoUrl)}
                  className={`position-absolute ri-delete-bin-line ${classes.trash} right-2 top-2`}
                ></i>
              </PermissionWrapper>{" "}
              <img
              className={classes.playIconImg}
                onClick={() => window.open(v?.videoUrl)}
                src="/static/svg/play.png"
              />
            </div>
          </div>
        );
      })}
      <AdminModalComponent
        children={renderConfirmModalBody()}
        headerTitle="Are you sure"
        openModal={openConfirmModal}
        closeModal={onCloseConfirmModal}
      />
      <AdminModalComponent
        children={renderAddModalBody()}
        headerTitle="Add new video"
        openModal={openAddModal}
        closeModal={onCloseAddModal}
      />
    </div>
  );
};

export default Videos;
