import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import ControlledTextField from "../../components/ControlledTextField";
import { PasswordField } from "../../components/PasswordTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { TWO_FA } from "./contants";
import { AdminLogin } from "../../services/auth";
import { UserInfoStateType } from "../../slices/authSlice";
const useStyles = makeStyles({
  card: {
    width:400,
    height: "auto",
    padding: "40px 40px 24px 40px",
    background: "rgba(249, 249, 249, 0.9)",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    backdropFilter: "blur(15.5px)",
    borderRadius: "20px",
  },
  headerText: {
    textAlign:"center",
    fontSize:20,
    color: "#E77228",
    fontWeight: 500,
  },
  InputBox: {
    display: "flex",
    gap: 30,
    flexDirection: "column",
    marginTop: 30,
  },
  ButtonBox: {
    marginTop: 30,
  },
  LoginButton: {
    width: "100%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  ForgotBox: {
    marginTop: 30,
  },
  forgotPassword: {
    color: "#69CDAE",
    cursor: "pointer",
    textDecoration: "underline",
  },
  copyRight: {
    display: "flex",
    justifyContent: "center",
  },
  copyRightBox: {
    marginTop: 30,
  },
  fruProTextColor: {
    color: "#E77228",
    marginLeft: 5,
  },
  yearText: {
    marginLeft: 5,
  },
  headerBox: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  logo: {
    margin: "0 auto",
  },
});

export interface LoginCardType {
  setUserData: React.Dispatch<React.SetStateAction<UserInfoStateType>>;
  setRenderedContainer: React.Dispatch<React.SetStateAction<string>>;
  setAccessToken: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

export interface LoginSchemaType {
  email: string;
  password: string;
}

const loginSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/^(?=.*[a-z]).*$/, {
      message: "Password must be at least one lowercase",
    })
    .matches(/^(?=.*[A-Z]).*$/, {
      message: "Password must be at least one uppercase",
    })
    .matches(/^(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).*$/, {
      message: "Password must be at least one special character",
    }),
});

const LoginCard: React.FC<LoginCardType> = ({
  setRenderedContainer,
  setAccessToken,
  setUserData,
  setEmail,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginSchemaType>({
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const onSubmit = async (data: LoginSchemaType) => {
    setLoading(true);

    setTimeout(async () => {
      const response = await AdminLogin(data);
      setLoading(false);
      if (response) {
        setAccessToken(response.accessToken.token);
        setEmail(response.user.email);
        setUserData(response.user);
        setRenderedContainer(TWO_FA);
      }
    }, 1000);
  };
  return (
    <div className={classes.card}>
      <div className={classes.headerBox}>
        <img className={classes.logo} src="/static/svg/logo.png" />
        <h3 className={classes.headerText}>Welcome to admin portal</h3>
      </div>

      <div className={classes.InputBox}>
        <ControlledTextField
          error={!errors.email}
          helperText={errors.email?.message}
          placeholder="example@gmail.com"
          textLabel="Email"
          control={control}
          name="email"
        />
        <PasswordField
          error={!errors.password}
          helperText={errors.password?.message}
          placeholder="Password"
          textLabel="Password"
          control={control}
          name="password"
        />
      </div>

      <div className={classes.ButtonBox}>
        <AdminButtonComponent
          onKeyPress={handleSubmit(onSubmit)}
          loading={loading}
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          className={classes.LoginButton}
          title="Login"
        />
      </div>
      {/* <div className={classes.ForgotBox}>
        <a href="/forgot-password">
          {" "}
          <p className={classes.forgotPassword}>Forgot Password?</p>
        </a>
      </div> */}
      <div className={classes.copyRightBox}>
        <p className={classes.copyRight}>
          {"Copyright © "}
          {"  "}
          <a
            className={classes.fruProTextColor}
            target="_blank"
            href="https://app.frupro.com"
          >
            FruPro
          </a>
          {"   "}
          <span className={classes.yearText}>{new Date().getFullYear()}</span>.
        </p>
      </div>
    </div>
  );
};

export default LoginCard;
