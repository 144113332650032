import React, { useState, useEffect } from "react";
import { Sidebar } from "../../components/Sidebar";
import { getProductTypes } from "../../services/common";
import TableComponent from "../../components/TableComponent";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
});

interface subProductTypesInterface {
  description: string;
  name: string;
  url: string;
  _id: string;
  images: {
    isCover: boolean;
    name: string;
    path: string;
    size: number;
    thumbPath: string;
    _id: string;
  }[];
}

interface productTypesInterface {
  createdAt: string;
  id: string;
  images: {
    isCover: boolean;
    name: string;
    path: string;
    size: number;
    thumbPath: string;
    thumbUrl: string;
    url: string;
    _id: string;
  }[];
  subProductType: {
    description: string;
    name: string;
    url: string;
    _id: string;
    images: {
      isCover: boolean;
      name: string;
      path: string;
      size: number;
      thumbPath: string;
      _id: string;
    }[];
  }[];
  name: string;
  description: string;
}

interface QueryParamsType {
  page: number;
  limit: number;
  total: number;
  totalPage: number;
}

const columns = [
  { id: "produceType", name: "Produce Type" },
  { id: "action", name: "" },
];

const ProduceTypesContainer: React.FC = () => {
  const [productTypes, setProductTypes] = useState<productTypesInterface[]>([]);
  const [subProductTypes, setSubProductTypes] = useState<
    subProductTypesInterface[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [queryParams, setQueryParams] = useState<QueryParamsType>({
    page: 1,
    limit: 1,

    total: 1,
    totalPage: 1,
  });

  useEffect(() => {
    const getAllProductTypes = async () => {
      setLoading(true);
      const response = await getProductTypes();

      setTimeout(() => {
        setLoading(false);
        if (response) {
          setProductTypes(response);
        }
      }, 1000);
    };
    getAllProductTypes();
  }, []);

  useEffect(() => {
    if (productTypes.length) {
      productTypes.map((item) =>
        item.subProductType.map((type) => {
          subProductTypes.push(type);
          setSubProductTypes(subProductTypes);
        })
      );
    }
  }, [productTypes]);



  const renderTableBodyComponent = () => {
    return (
      <>
        {subProductTypes.map((type) => {
          return (
            <TableRow
              // className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              // key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {type.name}
              </TableCell>

              <TableCell>
                <IconButton>
                  <Tooltip title="See Details">
                    <i className="ri-eye-line"></i>
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };
  return (
    <>
    <Helmet>
      <title>Produce Types | FruPro Admin Portal</title>
    </Helmet>
    <Sidebar pageTitle="Produce Types">
      {loading&&<LoadingIndicator loading/>}
      <TableComponent
        showRightButton={false}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        TableBodyComponent={renderTableBodyComponent}
        columns={columns}
        />
    </Sidebar>
        </>
  );
};

export default ProduceTypesContainer;
