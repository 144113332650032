import React, { useEffect } from "react";
import { InventoryStateType } from "../interfaces";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../../components/ControlledTextField";
import moment from "moment";

interface PropsType {
  selectedInventory: InventoryStateType | undefined;
}

interface SchemaType {
  name: string;
  referenceNumber: string;
  consignmentNumber: string;
  description: string;
  productType: string;
  subProductType: string;
  category: string;
  supplier: string;
  supplierRef: string;
  unit: string;
  quantity: number;
  packingSize: string;
  boxesInPallet: string;
  advisoryPrice: number;
  brand: string;
  currency: string;
  variety: string;
  grower: string;
  haulier: string;
  sold_quantity: number;
  remaining_quantity: number;
  listing_date: string;
  address: string;
  comment: string;
}

const schema = yup.object().shape({
  name: yup.string(),
  referenceNumber: yup.string(),
  consignmentNumber: yup.string(),
  description: yup.string(),
  productType: yup.string(),
  subProductType: yup.string(),
  category: yup.string(),
  supplier: yup.string(),
  supplierRef: yup.string(),
  unit: yup.string(),
  quantity: yup.number(),
  packingSize: yup.string(),
  boxesInPallet: yup.string(),
  advisoryPrice: yup.number(),
  brand: yup.string(),
  currency: yup.string(),
  variety: yup.string(),
  grower: yup.string(),
  haulier: yup.string(),
  sold_quantity: yup.number(),
  remaining_quantity: yup.number(),
  listing_date: yup.string(),
  address: yup.string(),
  comment: yup.string(),
});

const InventoryDetailsModal: React.FC<PropsType> = ({ selectedInventory }) => {
  let remaining_qty: number | undefined =
    selectedInventory &&
    selectedInventory?.quantity - selectedInventory?.sold_quantity;
  const { control, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("name", selectedInventory?.produce?.name || "");
    setValue("referenceNumber", selectedInventory?.referenceNumber || "");
    setValue("consignmentNumber", selectedInventory?.consignmentNumber || "");
    setValue("description", selectedInventory?.produce?.description || "");
    setValue("productType", selectedInventory?.produce?.productType.name || "");
    setValue(
      "subProductType",
      selectedInventory?.produce?.subProductType.name || ""
    );
    setValue("category", selectedInventory?.produce?.category || "");
    setValue("supplier", selectedInventory?.supplier || "");
    setValue("supplierRef", selectedInventory?.supplier_ref || "");
    setValue("unit", selectedInventory?.produce?.unit.name || "");
    setValue("quantity", selectedInventory?.produce?.quantity || 0);
    setValue("packingSize", selectedInventory?.produce?.packingSize || "");
    setValue(
      "boxesInPallet",
      selectedInventory?.produce?.packetPerPallet || ""
    );
    setValue("advisoryPrice", selectedInventory?.produce?.priceGuide || 0);
    setValue("brand", selectedInventory?.produce?.name || "");
    setValue("currency", selectedInventory?.produce?.currency.name || "");
    setValue("variety", selectedInventory?.produce?.variety || "");
    setValue("grower", selectedInventory?.produce?.grower || "");
    setValue("haulier", selectedInventory?.produce?.haulier || "");
    setValue("sold_quantity", selectedInventory?.sold_quantity || 0);
    setValue("remaining_quantity", remaining_qty || 0);
    setValue(
      "listing_date",
      moment(selectedInventory?.createdAt).format("DD/MM/YYYY") || ""
    );
    setValue("address", selectedInventory?.produce?.location.address || "");
    setValue("comment", selectedInventory?.comment || "");
  }, [selectedInventory]);
  return (
    <div className="container">
      <div className="row g-3">
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Produce"
            control={control}
            name="name"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Reference Number"
            control={control}
            name="referenceNumber"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Consignment Number"
            control={control}
            name="consignmentNumber"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Description"
            control={control}
            name="description"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Product Type"
            control={control}
            name="productType"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Sub Product Type"
            control={control}
            name="subProductType"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Category"
            control={control}
            name="category"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Supplier"
            control={control}
            name="supplier"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Supplier Reference"
            control={control}
            name="supplierRef"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Unit"
            control={control}
            name="unit"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Quantity"
            control={control}
            name="quantity"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Packing Size"
            control={control}
            name="packingSize"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Boxes in pallet"
            control={control}
            name="boxesInPallet"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Advisory Price"
            control={control}
            name="advisoryPrice"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Brand"
            control={control}
            name="brand"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Currency"
            control={control}
            name="currency"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Variety"
            control={control}
            name="variety"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Grower"
            control={control}
            name="grower"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Haulier"
            control={control}
            name="haulier"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Sold Quantity"
            control={control}
            name="sold_quantity"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Remaining Quantity"
            control={control}
            name="remaining_quantity"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Listing Date"
            control={control}
            name="listing_date"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Address"
            control={control}
            name="address"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            disabled={true}
            textLabel="Comment"
            control={control}
            name="comment"
          />
        </div>
      </div>
    </div>
  );
};

export default InventoryDetailsModal;
