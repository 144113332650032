import React, { useEffect, useState } from "react";
import TableComponent from "../../../components/TableComponent";
import { makeStyles } from "@mui/styles";
import { InventoryStateType } from "../interfaces";
import { Box, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import moment from "moment";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AdminModalComponent from "../../../components/AdminModalComponent";
import ControlledTextField from "../../../components/ControlledTextField";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import ControlledComboBox from "../../../components/ControlledComboBox";
import { getInventoryItems } from "../../../services/surplus-allocation";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ControlledDateField from "../../../components/ControlledDateField";
import InventoryDetailsModal from "./InventoryDetailsModal";

const category = ["Cat 1 ", "Cat 2"];
const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});

interface InventoryTablePropsType {
  setInventoryLoading: React.Dispatch<React.SetStateAction<boolean>>;
  inventoryLoading:boolean
  selectedInventoryItem?: InventoryStateType;
  setSelectedInventoryItem: React.Dispatch<
    React.SetStateAction<InventoryStateType | undefined>
  >;
  setIsShowInventoryDetails: React.Dispatch<React.SetStateAction<boolean>>;
  isShowInventoryDetails: boolean;
  inventoryFilterData: InventoryStateType[];
  setInventoryData: React.Dispatch<React.SetStateAction<InventoryStateType[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  inventoryData: InventoryStateType[];
}

interface QueryParamsType {
  page: number;
  limit: number;
  total: number;
  totalPage: number;
}

const schema = yup.object().shape({
  supplier: yup.string().nullable(),
  categories: yup.array(),
  subProductTypeIds: yup.array().nullable(),
  location: yup
    .object()
    .shape({
      _id: yup.string(),
      name: yup.string(),
    })
    .nullable(),
  dateFrom: yup.date().nullable(),
  dateTo: yup.date().nullable(),
  brand: yup.string(),
  variety: yup.string(),
  referenceNumber: yup.string().nullable(),
  consignmentNumber: yup.string().nullable(),
});

const columns = [
  { name: "Seller", id: "seller" },
  { name: "Product", id: "product" },
  { name: "Supplier", id: "supplier" },
  { name: "Received QTY/unit", id: "QTYUnit" },
  { name: "Sold QTY", id: "soldQTY" },
  { name: "Remaining", id: "Remaining" },
  { name: "Receive Date", id: "receiveDate" },
  { name: "Location", id: "location" },
  { name: "", id: "action" },
];

const InventoryTableContainer: React.FC<InventoryTablePropsType> = ({
  selectedInventoryItem,
  setInventoryLoading,
  inventoryLoading,
  setSelectedInventoryItem,
  inventoryData,
  loading,
  setIsShowInventoryDetails,
  isShowInventoryDetails,
  inventoryFilterData,
  setInventoryData,
  setLoading,
}) => {
  const classes = useStyles();

  const [queryParams, setQueryParams] = useState<QueryParamsType>({
    page: 1,
    limit: 1,
    total: 1,
    totalPage: 1,
  });
  const [openInventoryFilterModal, setOpenInventoryFilterModal] =
    useState<boolean>(false);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      supplier: "",
      subProductTypeIds: [],
      categories: [],
      dateFrom: null,
      dateTo: null,

      variety: "",
      referenceNumber: "",
      consignmentNumber: "",
    },
  });

  const allSuppliers = inventoryFilterData
    ?.map((item) => item?.supplier)
    .filter(
      (value, index, self) => index === self.findIndex((t) => t === value)
    );
  const allRefNumbers = inventoryFilterData
    ?.map((item) => item?.referenceNumber)
    .filter(
      (value, index, self) => index === self.findIndex((t) => t === value)
    );
  const allConsNumbers = inventoryFilterData
    ?.map((item) => item?.consignmentNumber)
    .filter(
      (value, index, self) => index === self.findIndex((t) => t === value)
    );
  const allLocations = inventoryFilterData
    ?.map((item) => ({
      name: item?.produce?.location?.address,
      _id: item?.produce?.locationId,
    }))
    .filter(
      (value, index, self) =>
        index === self.findIndex((t) => t?._id === value?._id)
    );
  const allProduces = inventoryFilterData
    ?.map((item) => ({
      _id: item?.produce?.subProductType?._id,
      name: item?.produce?.subProductType?.name,
    }))
    .filter(
      (value, index, self) =>
        index === self.findIndex((t) => t?._id === value?._id)
    );

  const onFilter = async (data: any) => {
    setLoading(true);
    const response = await getInventoryItems({
      subProductTypeIds: data?.subProductTypeIds?.length
        ? data?.subProductTypeIds?.map((item: any) => item?._id)
        : undefined,
      brand: data?.brand ? data?.brand : undefined,
      variety: data?.variety ? data?.variety : undefined,
      supplier: data?.supplier ? data?.supplier : undefined,
      categories: data?.categories?.length ? data?.categories : undefined,
      location: data?.location?._id ? data?.location?._id : undefined,
      dateFrom: data?.dateFrom ? data?.dateFrom : undefined,
      dateTo: data?.dateTo ? data?.dateTo : undefined,
      referenceNumber: data?.referenceNumber
        ? data?.referenceNumber
        : undefined,
      consignmentNumber: data?.consignmentNumber
        ? data?.consignmentNumber
        : undefined,
    });
    setLoading(false);
    setOpenInventoryFilterModal(false);
    setInventoryData(response);
  };

  const renderTableBody = () => {
    return (
      <>
        {inventoryData.map((item, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item.supplier}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.produce.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.supplier}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.quantity}/{item.produce.unit.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.sold_quantity}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.quantity - item.sold_quantity}
              </TableCell>
              <TableCell component="th" scope="row">
                {moment(item.receive_date).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.produce.location.address}
              </TableCell>
              <TableCell>
                <Tooltip title="Details">
                  <IconButton
                    onClick={() => {
                      setIsShowInventoryDetails(true);
                      setSelectedInventoryItem(item);
                    }}
                  >
                    <i className="ri-eye-line"></i>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderFilterModalBody = () => {
    return (
      <>
        <div className="container">
          <div className="row g-3">
            <div className="col-6">
              <ControlledComboBox
                textLabel="Produce"
                control={control}
                multiple={true}
                name="subProductTypeIds"
                options={allProduces}
              />
            </div>
            <div className="col-6">
              <ControlledComboBox
                control={control}
                name="supplier"
                options={allSuppliers}
                textLabel="Supplier"
              />
            </div>
            <div className="col-6">
              <ControlledComboBox
                control={control}
                multiple={true}
                name="categories"
                options={category}
                textLabel="Category"
              />
            </div>
            <div className="col-3">
              <ControlledDateField
                textLabel="Date From"
                control={control}
                name="dateFrom"
              />
            </div>
            <div className="col-3">
              <ControlledDateField
                textLabel="Date To"
                control={control}
                name="dateTo"
              />
            </div>
            <div className="col-6">
              <ControlledTextField
                control={control}
                name="brand"
                textLabel="Brand"
              />
            </div>
            <div className="col-6">
              <ControlledComboBox
                control={control}
                options={allLocations}
                name="location"
                textLabel="Location"
              />
            </div>
            <div className="col-6">
              <ControlledTextField
                control={control}
                name="variety"
                textLabel="Variety"
              />
            </div>
            <div className="col-6">
              <ControlledComboBox
                control={control}
                options={allRefNumbers}
                name="referenceNumber"
                textLabel="Reference Number"
              />
            </div>
            <div className="col-6">
              <ControlledComboBox
                control={control}
                options={allConsNumbers}
                name="consignmentNumber"
                textLabel="Consignment Number"
              />
            </div>
          </div>
          <Box className={classes.newUserModalButtons}>
            <AdminButtonComponent
              className={classes.closeButton}
              onClick={() => setOpenInventoryFilterModal(false)}
              title="Cancel"
            />{" "}
            <AdminButtonComponent
              className={classes.saveButton}
              onClick={handleSubmit(onFilter)}
              title="Save"
            />
          </Box>
        </div>
      </>
    );
  };

  return (
    <>
      {loading && <LoadingIndicator loading />}
      <TableComponent
      loading={inventoryLoading}
        onOpenModal={() => setOpenInventoryFilterModal(true)}
        showFilterIcon={true}
        showRightButton={false}
        TableBodyComponent={renderTableBody}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        columns={columns}
      />
      <AdminModalComponent
        size="lg"
        headerTitle="Filter Inventory"
        children={renderFilterModalBody()}
        closeModal={() => setOpenInventoryFilterModal(false)}
        openModal={openInventoryFilterModal}
      />
    </>
  );
};

export default InventoryTableContainer;
