import React from "react";
import { TextField, Autocomplete, Checkbox, FormLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Controller } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ComboBoxTypes } from "./interfaces/ComponentInterfaces";

const useStyles = makeStyles((theme?: any) => ({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: 350,

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  checkIcon: {
    color: "#E77228 !important",
  },
  InputField: {
    display: "flex",
    flexDirection: "column",
  },
  labelText: {
    color: "black",
    fontSize: "16px !important",
  },
  labelTextOther: {
    width: 120,
    color: "black",
    fontSize: "16px !important",
  },
  image: {
    width: 30,
    height: 30,
    marginLeft: 5,
    borderRadius: "100%",
  },
}));

const ControlledComboBox: React.FC<ComboBoxTypes> = ({
  options,
  onFocus,
  freeSolo,
  autoSelect,
  isShowIcons,
  placeholder,
  notSortOptions,
  newOptions,
  control,
  disabled,
  className,
  name,
  id,
  watchValue,
  defaultValue,
  defaultValues,
  multiple,
  textLabel,
  limitTags,
  helperText,
  error,
  required,
  onBlur,
}) => {
  const classes = useStyles();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const sortedOptions = !notSortOptions
    ? options?.sort(function (a: any, b: any) {
        if (
          a?.name
            ? a.name?.toString().toUpperCase() <
              b.name?.toString().toUpperCase()
            : a?.toString().toUpperCase() < b?.toString().toUpperCase()
        ) {
          return -1;
        }
        if (
          a?.name
            ? a.name?.toString().toUpperCase() >
              b.name?.toString().toUpperCase()
            : a?.toString().toUpperCase() > b?.toString().toUpperCase()
        ) {
          return 1;
        }
        return 0;
      })
    : newOptions || [];

  return (
    <>
      <div className={classes.InputField}>
        <FormLabel
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            flexDirection: "row",
          }}
          className={
            textLabel === "Produce Type"
              ? classes.labelTextOther
              : classes.labelText
          }
          component="label"
        >
          {textLabel}
          {required && <h1 className="text-red-500">*</h1>}
          {isShowIcons ? (
            watchValue ? (
              <img src="/static/svg/ic_checked.svg" />
            ) : (
              <img
                style={{ width: 17, height: 17 }}
                src="/static/svg/ic_warning.png"
              />
            )
          ) : null}
        </FormLabel>
        <Controller
          disabled={disabled}
          name={name}
          control={control}
          defaultValue={multiple && []}
          render={({ field: { onChange, ...rest } }) => {
            return multiple ? (
              <Autocomplete
                onFocus={onFocus}
                freeSolo={freeSolo}
                disabled={disabled}
                defaultValue={defaultValues}
                size="small"
                isOptionEqualToValue={(option, value) =>
                  option.id
                    ? option.id === value._id
                    : option?._id
                    ? option._id === value._id
                    : option === value
                }
                onChange={(e, data) => {
                  onChange(data);
                }}
                {...rest}
                onBlur={(e) => {
                  rest.onBlur && rest.onBlur();
                  onBlur && onBlur();
                }}
                limitTags={limitTags}
                multiple={multiple}
                className={className ? className : classes.comboBox}
                id={id}
                options={sortedOptions}
                disableCloseOnSelect
                sx={{
                  "& fieldset": { border: "none" },
                }}
                getOptionLabel={(option) =>
                  option?.title
                    ? option?.title
                    : option.name
                    ? option.name
                    : option
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option?._id || option}>
                    <Checkbox
                      key={option?._id || option}
                      className={classes.checkIcon}
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option?.title
                      ? option?.title
                      : option.name
                      ? option.name
                      : option}
                    {option?.url ? (
                      <img
                        style={{ width: 30, height: 30 }}
                        src={option?.url}
                      />
                    ) : null}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    placeholder={placeholder}
                    helperText={helperText}
                    error={error}
                    {...params}
                  />
                )}
              />
            ) : (
              <Autocomplete
                onFocus={onFocus}
                autoSelect={autoSelect}
                freeSolo={freeSolo}
                disabled={disabled}
                defaultValue={defaultValue}
                size="small"
                onChange={(e, data) => {
                  onChange(data);
                }}
                {...rest}
                onBlur={(e) => {
                  rest.onBlur && rest.onBlur();
                  onBlur && onBlur();
                }}
                limitTags={limitTags}
                multiple={multiple}
                className={className ? className : classes.comboBox}
                id={id}
                options={sortedOptions}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                getOptionLabel={(option) =>
                  option.name
                    ? option.name
                    : typeof option === "object"
                    ? ""
                    : option
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option?.id || option}>
                    {option.name ? option.name : option}
                    {option?.url ? (
                      <img className={classes.image} src={option?.url} />
                    ) : null}
                    {option?.score
                      ? ` (${(option?.score).toFixed(2)}%) `
                      : null}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    placeholder={placeholder}
                    helperText={helperText}
                    error={error}
                    {...params}
                  />
                )}
              />
            );
          }}
        />
      </div>
    </>
  );
};

export default ControlledComboBox;
