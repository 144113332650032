import { useCallback, useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@mui/styles";
import ControlledTextField from "../../components/ControlledTextField";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { getDocumentsWithSearchKey } from "../../services/reports";
import { toast } from "react-toastify";
import LoadingIndicator from "../../components/LoadingIndicator";
import { OrderDocumentsDownloadState } from "./interface";
import PermissionWrapper from "../../components/PermissionWrapper";
import RequestDocuments from "./RequestDocuments";
import { Helmet } from "react-helmet";

const schema = yup.object().shape({
  keyword: yup.string(),
  documentName: yup.string(),
  requestFrom: yup.string(),
});

const useStyles = makeStyles({
  mainBox: {
    width: "100%",
    borderRadius: 20,
    background: "white",
    padding: 10,
  },
  opacity: { margin: "20px 0 20px 0", opacity: 0.06 },
  downloadButton: {
    marginTop: "3px!important",
    height: 45,
    width: "100%",
    borderRadius: "10px!important",
    background: "#5F8CBA!important",
  },
  downloadText: {
    color: "#E77228",
    cursor: "pointer",
    textDecoration: "underline",
  },
  documentItem: {
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    background: "#F8F8F8",
    borderRadius: 10,
    padding: 20,
    width: "100%",
  },
});

const DownloadDocumentsContainer = () => {
  const classes = useStyles();
  const { control, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      documentName: "",
      requestFrom: "",
    },
  });
  const [downloadData, setDownloadData] =
    useState<OrderDocumentsDownloadState>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState(0);
  const watchKeyword = watch("keyword");

  const onSearch = async () => {
    if (!watchKeyword) {
      toast.error("Please type a keyword for search");
    } else {
      setLoading(true);
      const response = await getDocumentsWithSearchKey(watchKeyword || "");
      setLoading(false);

      if (response?.success) {
        setDownloadData(response?.data);
      } else {
        setDownloadData(undefined);
      }
    }
  };

  const concatDocuments = useCallback(() => {
    let allDocs: { name: string; url: string }[] = [];

    const invoices: { name: string; url: string }[] = downloadData?.invoices
      .length
      ? downloadData?.invoices.map((invoice) => ({
          name: "Frupro Invoice",
          url: invoice.url,
        }))
      : [];

    const docs = downloadData?.orderDocuments?.length
      ? downloadData?.orderDocuments.map((doc) => ({
          name: doc.documentName,
          url: doc.url,
        }))
      : [];

    const confirmUrl = downloadData?.offerConfirmationUrl
      ? [
          {
            name: "Order Confirmation File",
            url: downloadData?.offerConfirmationUrl,
          },
        ]
      : [];

    const tradabaseFiles = downloadData?.tradabaseFiles?.length
      ? downloadData?.tradabaseFiles.map((t) => ({
          name: "Tradabase File",
          url: t.url,
        }))
      : [];

    const aiFiles = downloadData?.documentaiFiles?.length
      ? downloadData?.documentaiFiles.map((t) => ({
          name: "Invoice",
          url: t.fileUrl,
        }))
      : [];

    const mergedDocs = allDocs.concat(
      invoices,
      docs,
      confirmUrl,
      tradabaseFiles,
      aiFiles
    );

    return [...mergedDocs];
  }, [
    downloadData?.invoices.length,
    downloadData?.documentaiFiles.length,
    downloadData?.tradabaseFiles.length,
    downloadData?.orderDocuments.length,
    downloadData?.offerConfirmationUrl.length,
  ]);

  return (
    <Sidebar pageTitle="Download Documents">
       <Helmet>
          <title>Download Documents | FruPro Admin Portal</title>
        </Helmet>
      <PermissionWrapper
        unauthorizedComponent={true}
        permission="DOWNLOAD_INVOICE_DOCUMENTS"
      >
        <div>
          <div style={{ overflow: "hidden" }} className={classes.mainBox}>
            <div className="row gap-2 d-flex align-items-center">
              <div className="col-md-4">
                <ControlledTextField
                  placeholder="Enter code,search and download documents"
                  textLabel="Code (Order,Payment,Invoice)"
                  control={control}
                  name="keyword"
                />
              </div>
              <div className="col-md-2 mt-3">
                <AdminButtonComponent
                  disabled={(watchKeyword as string)?.length < 6 || loading}
                  className={classes.downloadButton}
                  onClick={onSearch}
                  title={loading ? "Searching.." : "Search"}
                />
              </div>
            </div>
            <hr className={classes.opacity} />
            {/* {downloadData?.offerConfirmationUrl ? (
              <div className="col-md-6">
                <div className={classes.documentItem}>
                  <span>
                    <span className="fw-bold">Order Confirmation File</span>{" "}
                    <br />
                    1.{" "}
                    <a
                      href={downloadData?.offerConfirmationUrl}
                      target="_blank"
                      className={classes.downloadText}
                    >
                      Click here to download
                    </a>{" "}
                  </span>
                </div>
              </div>
            ) : null} */}
            {concatDocuments().length ? (
              <div className="col-md-6 mt-2">
                <div className={classes.documentItem}>
                  <span>
                    <span className="fw-bold">Order Documents</span>{" "}
                    <div className="d-flex flex-column gap-2 mt-2">
                      {concatDocuments().map((doc, i) => {
                        return (
                          <div key={i}>
                            {i + 1}. <span> {doc.name} :</span>{" "}
                            <a
                              href={doc.url}
                              target="_blank"
                              className={classes.downloadText}
                            >
                              Click here to download
                            </a>
                          </div>
                        );
                      })}
                    </div>{" "}
                  </span>
                </div>
              </div>
            ) : null}
            {/* {downloadData?.invoices.length ? (
              <div className="col-md-6 mt-2">
                <div className={classes.documentItem}>
                  <span>
                    <span className="fw-bold">Invoices</span>{" "}
                    <div className="d-flex flex-column gap-2 mt-2">
                      {downloadData?.invoices.map((doc, index) => {
                        return (
                          <div key={index}>
                            <span>{index + 1}.</span>{" "}
                            <a
                              href={doc.url}
                              target="_blank"
                              className={classes.downloadText}
                            >
                              Click here to download
                            </a>
                          </div>
                        );
                      })}
                    </div>{" "}
                  </span>
                </div>
              </div>
            ) : null} */}

            {downloadData?._id ? (
              <>
                <hr className={classes.opacity} />

                <RequestDocuments
                  loading={loading}
                  onSearch={onSearch}
                  setValue={setValue}
                  setRefetch={setRefetch}
                  downloadData={downloadData}
                  setLoading={setLoading}
                  watch={watch}
                  control={control}
                />
              </>
            ) : null}
          </div>
        </div>
      </PermissionWrapper>
    </Sidebar>
  );
};

export default DownloadDocumentsContainer;
