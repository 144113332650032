import React, { useEffect, useState } from "react";
import TableComponent from "../../../components/TableComponent";
import { makeStyles } from "@mui/styles";
import AdminModalComponent from "../../../components/AdminModalComponent";
import {
  downloadInvoices,
  getSummaryAndDetailedReports,
  getSurplusSellersBuyers,
} from "../../../services/surplus-allocation";
import { Buyer, InvoiceStateType } from "../interfaces";

import {
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import { Box } from "@mui/system";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../../components/ControlledTextField";
import ControlledDateField from "../../../components/ControlledDateField";
import FilterBadge from "../../../components/FilterBadge";
import ControlledComboBox from "../../../components/ControlledComboBox";
import { INVOICE_STATUS } from "../constants";
import { downloadFile } from "../../../services/common";
import LoadingIndicator from "../../../components/LoadingIndicator";
import NumberFormatComponent from "../../../components/NumberFormatComponent";
import { useNavigate } from "react-router-dom";
import { InvoiceStatuses } from "../../../constants";

const useStyles = makeStyles({
  boldText: {
    fontWeight: 600,
  },
  clickableText: {
    cursor: "pointer",
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  statusPaid: {
    background: "rgb(244, 195, 39)",
    color: "white",
  },
  statusIssued: {
    background: "rgb(4, 176, 237) !important",
    color: "white !important",
  },
  statusDraft: {
    background: "rgb(231, 114, 40) !important",
    color: "white !important",
  },
  statusRevised: {
    background: "rgb(231, 114, 40) !important",
    color: "white!important",
  },
  statusDue: {
    background: "rgb(255, 4, 51)!important",
    color: "white!important",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  detail: {
    borderRadius: "20px!important",
    background: "#69CDAE!important",
  },
  summary: {
    borderRadius: "20px!important",
    background: "#E77228!important",
  },
  checkbox: {
    color: "#E77228!important",
  },
  downloadInvoice: {
    borderRadius: "20px!important",
    background: "#5F8CBA!important",
  },
  codesCell: {
    width: 375,
  },
  orangeCompanyText: {
    color: "#000",
    textDecoration: "none",
    "&:hover": {
      color: "#E77228",
      textDecoration: "none",
    },
  },
});
interface QueryParamsType {
  page: number;
  limit: number;
  total: number;
  totalPage: number;
}

interface PropsType {
  searchParams: any;
  invoiceStatus?: string;
  setSearchParams?: React.Dispatch<React.SetStateAction<any>>;
  invoiceLoading: boolean;
  setInvoiceFilterData: React.Dispatch<React.SetStateAction<any>>;
  invoiceFilterData: any;
  invoiceQueryParams: QueryParamsType;
  setInvoiceQueryParams: React.Dispatch<React.SetStateAction<QueryParamsType>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setInvoiceData: React.Dispatch<React.SetStateAction<InvoiceStateType[]>>;
  invoiceData: InvoiceStateType[];
}

interface BuyersSellersState {
  name: string;
  id: string;
}

interface SelectedBuyer {
  photo: any;
  _id: string;
  email: string;
  phoneNumber: string;
  fullName: string;
  companyName: string;
}

const columns = [
  { name: "Code", id: "code" },
  { name: "Company", id: "company" },
  { name: "Date", id: "date" },
  { name: "Final Amount", id: "finalAmount" },
  { name: "Status", id: "status" },
  { name: "", id: "action" },
  { name: "", id: "action2" },
];

// const schema = yup.object().shape({
//   consignmentNo: yup.string().nullable(),
//   refNo: yup.string().nullable(),
//   category: yup.string().nullable(),
//   supplier: yup.string().nullable(),
//   produceName: yup.string().nullable(),
//   variety: yup.string().nullable(),
//   brand: yup.string().nullable(),
//   buyer: yup.array(),
//   companyName: yup.string(),
//   seller: yup.array(),
//   status: yup.array(),
//   offerFrom: yup.string().nullable(),
//   offerTo: yup.string().nullable(),
//   createFrom: yup.string().nullable(),
//   createTo: yup.string().nullable(),
//   paymentCode: yup.string().nullable(),
//   offerODRCode: yup.string().nullable(),
//   invoiceCode: yup.string().nullable(),
// });

const schema = yup.object().shape({
  // consignmentNo: yup.string().nullable(),
  code: yup.string().nullable(),
  supplier: yup.string().nullable(),
  // produceName: yup.string().nullable(),
  buyer: yup.array(),
  companyName: yup.string(),
  seller: yup.array(),
  status: yup.array(),
  offerFrom: yup.string().nullable(),
  offerTo: yup.string().nullable(),
  createFrom: yup.string().nullable(),
  createTo: yup.string().nullable(),
  invoiceDateFrom: yup.string().nullable(),
  invoiceDateTo: yup.string().nullable(),
  // offerODRCode: yup.string().nullable(),
  // invoiceCode: yup.string().nullable(),
});

const InvoiceTableContainer: React.FC<PropsType> = ({
  invoiceData,
  invoiceStatus,
  searchParams,
  setSearchParams,
  invoiceLoading,
  setInvoiceFilterData,
  invoiceFilterData,
  invoiceQueryParams,
  setInvoiceQueryParams,
  setInvoiceData,
  setLoading,
}) => {
  const classes = useStyles();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const [openInvoiceFilterModal, setOpenInvoiceFilterModal] =
    useState<boolean>(false);

  const [selectedBuyerData, setSelectedBuyerData] = useState<SelectedBuyer>();
  const [openBuyerInfoModal, setOpenBuyerInfoModal] = useState<boolean>(false);
  const [allBuyers, setAllBuyers] = useState<BuyersSellersState[]>([]);
  const [allSellers, setAllSellers] = useState<BuyersSellersState[]>([]);
  const [deletedFilterKey, setDeletedFilterKey] = useState<string>("");
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceStateType[]>(
    []
  );
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);

  useEffect(() => {
    setIsCheckedAll(false);
    setSelectedInvoices([]);
  }, [invoiceData, invoiceFilterData]);

  useEffect(() => {
    setValue(`${deletedFilterKey}` as any, undefined);
  }, [deletedFilterKey]);

  useEffect(() => {
    if (invoiceStatus) {
      setValue("status", [invoiceStatus]);
    }
  }, [invoiceStatus]);

  useEffect(() => {
    const getAllSellersBuyers = async () => {
      const response = await getSurplusSellersBuyers("invoice");
      if (response) {
        setAllBuyers(
          response.buyers.map((item: any) => ({
            name: item?.companyName,
            _id: item?.id,
          }))
        );
        setAllSellers(
          response.sellers.map((item: any) => ({
            name: item?.companyName,
            _id: item.id,
          }))
        );
      }
    };
    getAllSellersBuyers();
  }, []);

  const handleChecked = (e: any) => {
    setIsCheckedAll(e.target.checked);
  };

  const onSelectedAllInvoices = () => {
    if (isCheckedAll) {
      setSelectedInvoices([]);
    } else {
      setSelectedInvoices(invoiceData);
    }
  };

  const onSelectInvoices = (invoice: InvoiceStateType) => {
    const isInvoicesInArray: InvoiceStateType | undefined =
      selectedInvoices.find((item) => item._id === invoice._id);

    if (isInvoicesInArray) {
      const newInvoices: InvoiceStateType[] = selectedInvoices.filter(
        (item) => item._id !== invoice._id
      );
      setSelectedInvoices(newInvoices);
    } else {
      setSelectedInvoices([...selectedInvoices, invoice]);
    }
  };

  const onDownloadInvoices = async () => {
    setLoading(true);

    const invoiceIds = selectedInvoices.map((item) => item._id);
    const response = await downloadInvoices(invoiceIds, invoiceFilterData);
    setLoading(false);

    if (response) {
      setIsCheckedAll(false);
      setSelectedInvoices([]);
      downloadFile(response, "Invoices", true);
    }
  };

  const onFilterInvoice = async (data: any) => {
    setLoading(true);
    setInvoiceFilterData({
      code: data?.code ? data?.code : undefined,
      // consignmentNo: data?.consignmentNo ? [data?.consignmentNo] : undefined,
      buyer: data?.buyer?.length
        ? data?.buyer.map((item: any) => item._id)
        : undefined,
      status: data?.status?.length ? data?.status : undefined,
      seller: data?.seller?.length
        ? data?.seller.map((item: any) => item._id)
        : undefined,
      supplier: data?.supplier ? [data?.supplier] : undefined,
      produceName: data?.produceName ? data?.produceName : undefined,
      offerTo: data?.offerTo
        ? moment(data?.offerTo).format("YYYY-DD-MM")
        : undefined,
      offerFrom: data?.offerFrom
        ? moment(data?.offerFrom).format("YYYY-DD-MM")
        : undefined,
      createTo: data?.createTo
        ? moment(data?.createTo).format("YYYY-DD-MM")
        : undefined,
      createFrom: data?.createFrom
        ? moment(data?.createFrom).format("YYYY-DD-MM")
        : undefined,
      invoiceDateFrom: data?.invoiceDateFrom
        ? moment(data?.invoiceDateFrom).format("YYYY-DD-MM")
        : undefined,
      invoiceDateTo: data?.invoiceDateTo
        ? moment(data?.invoiceDateTo).format("YYYY-DD-MM")
        : undefined,
      // offerODRCode: data?.offerODRCode ? data?.offerODRCode : undefined,
      // invoiceCode: data?.invoiceCode ? data?.invoiceCode : undefined,
    });
    setLoading(false);

    setOpenInvoiceFilterModal(false);
  };

  const onDownloadReports = async (expand: boolean) => {
    if (expand) {
      setLoading(true);
      const response = await getSummaryAndDetailedReports(
        expand,
        "invoice",
        invoiceFilterData
      );
      setLoading(false);
      if (response) {
        downloadFile(response, "detailedReport");
      }
    } else {
      setLoading(true);
      const response = await getSummaryAndDetailedReports(
        expand,
        "invoice",
        invoiceFilterData
      );
      setLoading(false);
      if (response) {
        downloadFile(response, "summaryReport");
      }
    }
  };

  const onOpenBuyerInfoModal = (userData: Buyer, companyName: string) => {
    setOpenBuyerInfoModal(true);
    setSelectedBuyerData({
      ...userData,
      companyName: companyName,
    });
  };

  const renderTableBody = () => {
    return (
      <>
        {invoiceData.map((item, index) => {
          const invoiceChipColor = InvoiceStatuses.find(
            (i) => i.name === item.status
          );
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                className={classes.codesCell}
                component="th"
                scope="row"
              >
                <a
                  href={`${process.env.REACT_APP_FINMID_URL}/invoices/${item.invoice_offer[0].offerCode}`}
                  style={{ textDecoration: "underline" }}
                  target="_blank"
                >
                  <span className={classes.boldText}>Payment code : </span>
                  {item.invoice_offer[0].offerCode}
                </a>
                <br />
                <span>
                  <span className={classes.boldText}>Offer code : </span>
                  {item.offerCode}
                </span>
                <br />
                <span>
                  <span className={classes.boldText}>Invoice code : </span>
                  {item.code}
                </span>
              </TableCell>
              <TableCell
                className={classes.codesCell}
                component="th"
                scope="row"
              >
                <span
                // onClick={() =>
                //   onOpenBuyerInfoModal(
                //     item.buyer,
                //     item.buyerCompany.companyName
                //   )
                // }
                // className={classes.clickableText}
                >
                  <span className={classes.boldText}>Buyer : </span>{" "}
                  <a
                    target="_blank"
                    href={`/company-list/${item.buyerCompany._id}`}
                    className={classes.orangeCompanyText}
                  >
                    {item.buyerCompany.companyName}
                  </a>
                </span>
                <br />
                <span
                // onClick={() =>
                //   onOpenBuyerInfoModal(
                //     item.buyer,
                //     item.buyerCompany.companyName
                //   )
                // }
                // className={classes.clickableText}
                >
                  <span className={classes.boldText}>Seller : </span>
                  <a
                    target="_blank"
                    href={`/company-list/${item.sellerCompany._id}`}
                    className={classes.orangeCompanyText}
                  >
                    {" "}
                    {item.sellerCompany.companyName}
                  </a>
                </span>
              </TableCell>
              <TableCell
                className={classes.codesCell}
                component="th"
                scope="row"
              >
                <span>
                  <span className={classes.boldText}>Created Date : </span>
                  {moment(item.createdAt).format("DD/MM/YYYY")}
                </span>
                <br />
                <span>
                  <span className={classes.boldText}>
                    Offer/Delivery Date :{" "}
                  </span>
                  {moment(item.deliveryDate).format("DD/MM/YYYY")}
                </span>
                <br />
                <span>
                  <span className={classes.boldText}>Invoice Date : </span>
                  {moment(item.invoiceDate).format("DD/MM/YYYY")}
                </span>
                <br />
                <span>
                  <span className={classes.boldText}>Due Date : </span>
                  {moment(item.dueDate).format("DD/MM/YYYY")}
                </span>
              </TableCell>

              <TableCell component="th" scope="row">
                <NumberFormatComponent
                  prefix={item.produces[0].currency.symbol}
                  value={item.grandTotalAmount}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Chip
                  style={{
                    backgroundColor: invoiceChipColor?.color,
                    color: "white",
                    fontWeight: 600,
                    minWidth: 120,
                  }}
                  label={item.status}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <a target="_blank" href={item.pdfPath}>
                  <Tooltip title="See details of invoice">
                    <IconButton>
                      <i className="ri-eye-line"></i>
                    </IconButton>
                  </Tooltip>
                </a>
              </TableCell>
              <TableCell component="th" scope="row">
                <Checkbox
                  checked={selectedInvoices
                    .map((item) => item._id)
                    .includes(item._id)}
                  onClick={() => onSelectInvoices(item)}
                  className={classes.checkbox}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderInvoiceFilterModalBody = () => {
    return (
      <>
        <div className="container">
          <div className="row g-3">
            {/* <div className="col-6">
              <ControlledTextField
                control={control}
                name="produceName"
                textLabel="Produce Name"
              />
            </div> */}
            <Tooltip title="You can filter by Reference Number,Consignment Number,Payment Code,Order Code,Invoice Code and Produce Name">
              <div className="col-6">
                <ControlledTextField
                  control={control}
                  name="code"
                  textLabel="Code"
                />
              </div>
            </Tooltip>
            {/* <div className="col-6">
              <ControlledTextField
                control={control}
                name="offerODRCode"
                textLabel="Offer Code"
              />
            </div>

            <div className="col-6">
              <ControlledTextField
                control={control}
                name="consignmentNo"
                textLabel="Consignment Number"
              />
            </div>
            <div className="col-6">
              <ControlledTextField
                control={control}
                name="refNo"
                textLabel="Reference Number"
              />
            </div> */}

            <div className="col-6">
              <ControlledComboBox
                control={control}
                multiple
                options={allBuyers}
                name="buyer"
                textLabel="Buyer"
              />
            </div>
            <div className="col-6">
              <ControlledComboBox
                multiple
                control={control}
                options={allSellers}
                name="seller"
                textLabel="Seller"
              />
            </div>
            <div className="col-6">
              <ControlledTextField
                control={control}
                name="supplier"
                textLabel="Supplier"
              />
            </div>

            <div className="col-6">
              <ControlledComboBox
                control={control}
                multiple
                options={InvoiceStatuses.map((i) => i.name)}
                name="status"
                textLabel="Status"
              />
            </div>

            <div className="col-3">
              <ControlledDateField
                textLabel="Create Date(From - To)"
                control={control}
                name="createFrom"
              />
            </div>
            <div className="col-3">
              <ControlledDateField
                textLabel=""
                control={control}
                name="createTo"
              />
            </div>
            <div className="col-3">
              <ControlledDateField
                textLabel="Offer Date(From - To)"
                control={control}
                name="offerFrom"
              />
            </div>
            <div className="col-3">
              <ControlledDateField
                textLabel=""
                control={control}
                name="offerTo"
              />
            </div>

            <div className="col-3">
              <ControlledDateField
                textLabel="Invoice Date(From - To)"
                control={control}
                name="invoiceDateFrom"
              />
            </div>
            <div className="col-3">
              <ControlledDateField
                textLabel=""
                control={control}
                name="invoiceDateTo"
              />
            </div>
            {/* <div className="col-6">
              <InputLabel style={{ fontWeight: 500 }}>Allocated QTY</InputLabel>
              <div style={{ marginTop: 10 }}>
                <Controller
                  name="quantity"
                  control={control}
                  render={({ field }) => {
                    return (
                      <PrettoSlider
                        {...field}
                        color="primary"
                        getAriaLabel={() => "Temperature range"}
                        valueLabelDisplay="auto"
                        onChange={(event: Event, value: number | number[]) => {
                          setValue(
                            "quantityFrom",
                            Array.isArray(value) ? value[0] : value
                          );
                          setValue(
                            "quantityTo",
                            Array.isArray(value) ? value[1] : value
                          );
                        }}
                        min={0}
                        max={1000}
                        value={[quantityFrom || 0, quantityTo || 1000]}
                      />
                    );
                  }}
                />
              </div>
            </div> */}
          </div>
        </div>

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setOpenInvoiceFilterModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={handleSubmit(onFilterInvoice)}
            title="Save"
          />
        </Box>
      </>
    );
  };

  const renderDownloadReportsAndInvoices = () => {
    return (
      <div className="d-flex gap-3" style={{ marginRight: 10 }}>
        <AdminButtonComponent
          className={classes.detail}
          onClick={() => onDownloadReports(false)}
          title="Summary Report"
        />
        <AdminButtonComponent
          className={classes.summary}
          onClick={() => onDownloadReports(true)}
          title="Detailed Report"
        />
        <AdminButtonComponent
          disabled={!selectedInvoices.length}
          className={classes.downloadInvoice}
          onClick={onDownloadInvoices}
          title="Download Invoices"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckedAll}
              value={isCheckedAll}
              onChange={handleChecked}
              onClick={onSelectedAllInvoices}
              className={classes.checkbox}
            />
          }
          label="Select all invoices"
        />
      </div>
    );
  };

  const renderBuyerInfoModalBody = () => {
    return (
      <>
        <div className="d-flex flex-column gap-3">
          <div>
            <span className={classes.boldText}>Company Name : </span>
            {selectedBuyerData?.companyName}
          </div>
          <div>
            <span className={classes.boldText}>Full Name : </span>
            {selectedBuyerData?.fullName}
          </div>
          <div>
            <span className={classes.boldText}>Phone Number : </span>
            {selectedBuyerData?.phoneNumber}
          </div>
          <div>
            <span className={classes.boldText}>Email : </span>
            {selectedBuyerData?.email}
          </div>
        </div>

        <Box
          className={`d-flex justify-content-end ${classes.newUserModalButtons}`}
        >
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setOpenBuyerInfoModal(false)}
            title="Cancel"
          />{" "}
        </Box>
      </>
    );
  };
  return (
    <>
      <TableComponent
        loading={invoiceLoading}
        showRightHeaderItems={renderDownloadReportsAndInvoices()}
        filterBadge={
          <FilterBadge
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            buyers={allBuyers}
            sellers={allSellers}
            filterData={invoiceFilterData}
            setFilterData={setInvoiceFilterData}
            setDeletedFilterKey={setDeletedFilterKey}
          />
        }
        onOpenModal={() => setOpenInvoiceFilterModal(true)}
        showFilterIcon={!invoiceStatus && true}
        showRightButton={false}
        TableBodyComponent={renderTableBody}
        queryParams={invoiceQueryParams}
        setQueryParams={setInvoiceQueryParams}
        columns={columns}
      />
      <AdminModalComponent
        size="lg"
        headerTitle="Filter Invoice"
        openModal={openInvoiceFilterModal}
        closeModal={() => setOpenInvoiceFilterModal(false)}
        children={renderInvoiceFilterModalBody()}
      />
      <AdminModalComponent
        size="sm"
        headerTitle="Buyer Information"
        openModal={openBuyerInfoModal}
        closeModal={() => setOpenBuyerInfoModal(false)}
        children={renderBuyerInfoModalBody()}
      />
    </>
  );
};

export default InvoiceTableContainer;
