import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Tooltip,
  RadioGroup,
  Radio,
  CircularProgress,
} from "@mui/material";
// import { getFinmidPrices } from "@services/allocation-surplus";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import NumberFormatComponent from "../../../components/NumberFormatComponent";
import {
  getFinmidPrices,
  getPaymentTerms,
} from "../../../services/surplus-allocation";
import { RepaymentTermsType } from "./interfaces";
import ControlledTextField from "../../../components/ControlledTextField";
import { PaymentTermsType } from "../CreateNewSurplusContainer";

const useStyles = makeStyles((theme) => ({
  hr: {
    opacity: 0.4,
  },
  mainText: {
    fontSize: 18,
  },
  Input: {
    borderRadius: "10px!important",
    width: "100%",
    height: 45,
    padding: 15,
  },
  customControlledField: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
}));
const InvoiceFactoring = ({
  selectedPaymentTerm,
  setSelectedPaymentTerm,
  setPaymentTerms,
  paymentTerms,
  buyerOrSellerFee,
  setBuyerOrSellerFee,
  isSellerOnFinmid,
  feeOption,
  setFeeOption,
  isApplyPartial,
  setIsApplyPartial,
  grandTotal,
  currencySymbol,
  isApplyFactoring,
  setIsApplyFactoring,
  finmidLimit,
  control,
  addedProduces,
  setValue,
  watch,
  reset,
  errors,
  sellerCompany,
  totalEstimatedValue,
  setRepaymentTerms,
  repaymentTerms,
  paymentTermsLoading,
}: {
  selectedPaymentTerm: string;
  setSelectedPaymentTerm: React.Dispatch<React.SetStateAction<string>>;
  setPaymentTerms: React.Dispatch<React.SetStateAction<PaymentTermsType[]>>;
  paymentTerms: PaymentTermsType[];
  isSellerOnFinmid: boolean;
  buyerOrSellerFee: string;
  setBuyerOrSellerFee: React.Dispatch<React.SetStateAction<string>>;
  feeOption: string;
  setFeeOption: React.Dispatch<React.SetStateAction<string>>;
  isApplyPartial: boolean;
  setIsApplyPartial: React.Dispatch<React.SetStateAction<boolean>>;
  grandTotal: number;
  currencySymbol: string;
  isApplyFactoring: boolean;
  setIsApplyFactoring: React.Dispatch<React.SetStateAction<boolean>>;
  finmidLimit: any;
  control: any;
  addedProduces: any[];
  watch: any;
  reset: any;
  errors: any;
  setValue: any;
  totalEstimatedValue: number;
  sellerCompany?: { name: string; id: string };
  repaymentTerms?: RepaymentTermsType;
  setRepaymentTerms: React.Dispatch<
    React.SetStateAction<RepaymentTermsType | undefined>
  >;
  paymentTermsLoading: boolean;
}) => {
  const classes = useStyles();
  const buyer = watch("buyerCompany");
  const currencies = watch("creditLimitCurrency");
  const watchApplyInvoiceFactoring = watch("applyInvoiceFactoring");
  const watchUpfrontPaymentAmount = watch("upfrontPaymentAmount");
  const watchFinalPaymentAmount = watch("finalPaymentAmount");
  const watchFeePercentage = watch("feePercentage");

  const produceCurrency = watch("produceCurrency");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getFinmidPrice = async () => {
      const finalData = {
        seller_ids: [sellerCompany?.id],
        total_amount: grandTotal?.toFixed(2).toString(),
        buyer_id: buyer?.id,
        currency: currencies?.name,
      };
      setLoading(true);
      const res = await getFinmidPrices(finalData);
      setLoading(false);
      if (res?.repayment_methods?.length) {
        setRepaymentTerms(res?.repayment_methods[0]);
      } else if (res?.decline_reason) {
        toast.error(res?.decline_reason);
      }
    };

    if (watchApplyInvoiceFactoring) {
      getFinmidPrice();
    }
  }, [watchApplyInvoiceFactoring]);

  useEffect(() => {
    if (!watchApplyInvoiceFactoring) {
      setRepaymentTerms(undefined);
      setIsApplyPartial(false);
      setFeeOption("");
      setValue("partialOption", "");
    }
  }, [watchApplyInvoiceFactoring]);

  useEffect(() => {
    if (watchUpfrontPaymentAmount > grandTotal) {
      setValue("upfrontPaymentAmount", (grandTotal / 2).toFixed(2));
      setValue(
        "finalPaymentAmount",
        (grandTotal - parseFloat(watchUpfrontPaymentAmount)).toFixed(2)
      );
    } else {
      setValue(
        "finalPaymentAmount",
        watchUpfrontPaymentAmount
          ? (grandTotal - parseFloat(watchUpfrontPaymentAmount)).toFixed(2)
          : grandTotal.toFixed(2)
      );
    }
  }, [grandTotal, watchUpfrontPaymentAmount, setValue]);
  useEffect(() => {
    setValue("upfrontPaymentAmount", (grandTotal / 2).toFixed(2));
  }, [grandTotal, setValue]);

  useEffect(() => {
    if (watchUpfrontPaymentAmount) {
      setValue(
        "upfrontPaymentAmountPercentage",
        ((parseFloat(watchUpfrontPaymentAmount) * 100) / grandTotal).toFixed(2)
      );
      setValue(
        "finalPaymentAmountPercentage",
        (
          100 -
          (parseFloat(watchUpfrontPaymentAmount) * 100) / grandTotal
        ).toFixed(2)
      );
    } else {
      setValue("upfrontPaymentAmountPercentage", "0");
      setValue("finalPaymentAmountPercentage", "100");
    }
  }, [watchUpfrontPaymentAmount, setValue, grandTotal]);

  return (
    <div className="mt-1">
      <span className={classes.mainText}>Invoice Factoring</span>
      <Divider className={classes.hr} />
      {loading && <LoadingIndicator loading={loading} />}
      <div className="d-flex flex-row align-items-center">
        <FormControlLabel
          style={{ display: "flex", flexDirection: "row", gap: 3 }}
          control={
            <Checkbox
              defaultChecked={isApplyFactoring}
              checked={isApplyFactoring}
              value={isApplyFactoring}
              disabled={
                selectedPaymentTerm==="0D"||
                !watch("isBuyerChecked") ||
                !addedProduces?.length ||
                finmidLimit < totalEstimatedValue ||
                !isSellerOnFinmid
              }
              onChange={(e) => {
                setValue("applyInvoiceFactoring", e.target.checked);
                setIsApplyFactoring(e.target.checked);
              }}
              style={{ color: "#E77228", marginLeft: 9 }}
            />
          }
          label="Apply Invoice Factoring"
        />
        {!watch("isBuyerChecked") ||
        !addedProduces?.length ||
        finmidLimit < totalEstimatedValue ? (
          <Tooltip
            arrow
            title={
              <>
                <span>
                  Please check if company has sufficient limits to add this
                  order.
                </span>
              </>
            }
            placement="top"
          >
            <InfoIcon color="primary" />
          </Tooltip>
        ) : null}
      </div>
      {watchApplyInvoiceFactoring ? (
        <>
          {paymentTermsLoading ? (
            <div className="pt-3 d-flex align-items-center justify-content-center">
              <CircularProgress size={30} />
            </div>
          ) : (
            <div className="container ">
              {/* <RadioGroup
                className="mt-2"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <div>
                  <FormControlLabel
                    checked={buyerOrSellerFee === "Buyer Fee"}
                    value="Buyer Fee"
                    onChange={(e: any) => setBuyerOrSellerFee(e.target.value)}
                    control={<Radio style={{ color: "#E77228" }} />}
                    label="Buyer Pays Platform Fee"
                  />
                </div>
                <div>
                  <FormControlLabel
                    defaultChecked
                    checked={buyerOrSellerFee === "Seller Fee"}
                    value="Seller Fee"
                    onChange={(e: any) => setBuyerOrSellerFee(e.target.value)}
                    control={<Radio style={{ color: "#E77228" }} />}
                    label="Seller Pays Platform Fee"
                  />
                </div>
              </RadioGroup> */}

              <div className="mt-1 row d-flex align-items-center">
                <div className="col-md-6">
                  <ControlledTextField
                    textLabel="Fee Percentage"
                    control={control}
                    name="feePercentage"
                  />
                </div>
                <div className="col-md-6">
                  Platform Fee:{" "}
                  <NumberFormatComponent
                    prefix={currencySymbol}
                    value={
                      (parseFloat(watchFeePercentage) * grandTotal) / 100 || 0
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : null}

      {watchApplyInvoiceFactoring ? (
        <div className="mt-2">
          <Divider className={classes.hr} />
          <FormControlLabel
            style={{ display: "flex", flexDirection: "row", gap: 3 }}
            control={
              <Checkbox
                defaultChecked={isApplyPartial}
                checked={isApplyPartial}
                value={isApplyPartial}
                // disabled={
                //   !watch("isBuyerChecked") ||
                //   !addedProduces?.length ||
                //   finmidLimit < totalEstimatedValue
                // }
                onChange={(e) => {
                  setIsApplyPartial(e.target.checked);
                }}
                style={{ color: "#E77228", marginLeft: 9 }}
              />
            }
            label="Apply Partial Payment"
          />
        </div>
      ) : null}
      {isApplyPartial ? (
        <div className="container ">
          {/* <div className="col-5">
            <ControlledComboBox
              className={classes.customControlledField}
              control={control}
              name="partialOption"
              error={!!errors?.partialOption}
              helperText={errors?.partialOption?.message}
              options={["%50-%50"]}
              textLabel={"Partial Payment Option"}
            />
          </div> */}
          {
            <div className="mt-3 d-flex flex-column">
              <div className="d-flex flex-row items-center gap-3">
                <div className="col-6">
                  <ControlledTextField
                    textLabel="Upfront Payment Amount"
                    defaultValue={(grandTotal / 2).toFixed(2)}
                    control={control}
                    name="upfrontPaymentAmount"
                  />
                </div>
                <div className="col-3 mt-4 d-flex flex-row items-center gap-2">
                  <ControlledTextField
                    disabled
                    textLabel=""
                    defaultValue={(grandTotal / 2).toFixed(2)}
                    control={control}
                    name="upfrontPaymentAmountPercentage"
                  />
                  %
                </div>
              </div>
              {/* <NumberFormatComponent
                  value={grandTotal / 2}
                  prefix={currencySymbol}
                /> */}

              <div className="d-flex flex-row  gap-3 items-center">
                <div className="col-6 mt-2">
                  <ControlledTextField
                    disabled
                    textLabel="Final Payment Amount"
                    defaultValue={(
                      grandTotal - parseFloat(watchUpfrontPaymentAmount)
                    ).toFixed(2)}
                    control={control}
                    name="finalPaymentAmount"
                  />
                </div>
                <div className="col-3 mt-4 d-flex flex-row gap-2 items-center">
                  <ControlledTextField
                    disabled
                    textLabel=""
                    defaultValue={(grandTotal / 2).toFixed(2)}
                    control={control}
                    name="finalPaymentAmountPercentage"
                  />
                  %
                </div>
              </div>
              {/* <NumberFormatComponent
                  value={grandTotal / 2}
                  prefix={currencySymbol}
                />{" "} */}
            </div>
          }
        </div>
      ) : null}
    </div>
  );
};

export default InvoiceFactoring;
