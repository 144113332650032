import * as yup from "yup";

export const aiInvoiceSchema = yup.object().shape({
  invoiceDate: yup.string().required("Invoice date is required"),
  deliveryDate: yup.string().required("Delivery date is required"),
  refBuyer: yup.string(),
  refSeller: yup.string(),
  vatBuyer: yup.string(),
  vatSeller: yup.string(),
  locationSeller: yup.string(),
  locationBuyer: yup.string(),
  companyNameBuyer: yup.object().required("Buyer company name is required"),
  companyAdminBuyer: yup.string(),
  invoiceEmailBuyer: yup.string(),
  phoneNumberBuyer: yup.string(),
  companyNameSeller: yup.object().required("Seller company name is required"),
  currency: yup.object().required("Currency is required"),
  produces: yup.array().of(
    yup.object().shape({
      supplier:yup.string(),
      unit: yup.object().required("Unit is required"),
      id: yup.string(),
      name: yup.string().required("Name is required"),
      price: yup.string().required("Price is required"),
      quantity: yup.string().required("Quantity is required"),
      amount: yup.string().required("Amount is required"),
      subProductType: yup.object().required("Sub product type is required"),
    })
  ),
  costs: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      costName: yup.string().required("Cost name is required"),

      amount: yup.string().required("Amount is required"),
    })
  ),
  companyAdminSeller: yup
    .string()
    ,
  invoiceEmailSeller: yup.string(),
  phoneNumberSeller: yup.string(),
  totalAmount: yup.string(),
  discount: yup.string(),
  grandTotal: yup.string(),
  dueDate: yup.string().nullable(),
  accountName: yup.string(),
  bankName: yup.string(),
  IBAN: yup.string(),
  BIC: yup.string(),
  account: yup.string(),
  newCostName: yup.string(),
  newCostAmount: yup.string(),
  newProduceName: yup.string(),
  newProduceType: yup.object(),
  newQuantity: yup.string(),
  newProducePrice: yup.string(),
});
