import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableComponentTypes } from "./interfaces/ComponentInterfaces";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";
import AdminButtonComponent from "./AdminButtonComponent";
import LoadingIndicator from "./LoadingIndicator";
import PermissionWrapper from "./PermissionWrapper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ControlledComboBox from "./ControlledComboBox";
const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "200px",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  mainRoot: {
    width: "100%",
    backgroundColor: "#ffff",
    borderRadius: "20px !important",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    "& MuiTableContainer": {
      borderRadius: "20px!important",
    },
  },

  tableContainer: {
    borderRadius: "20px",
  },

  table: {
    minWidth: "100%",
  },
  orangeLine: {
    width: "100%",
    height: 5,
    backgroundColor: "#E77228",
  },
  paperStyle: {
    width: "100%",
    marginBottom: 10,
    boxShadow: "none",
    borderRadius: "20px",
  },
  filterButton: {
    background: "#E77228",
    borderRadius: 35,
    width: 65,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      background: "#69CDAE",
    },
  },
  filterBox: {
    display: "flex",
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #F2F2F2",
    padding: "16px 20px 12px",
  },
  colsCell: {
    fontWeight: "600 !important",
    fontSize: "16px !important",
  },
  filterSearchBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonBox: {
    marginRight: 20,
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    padding: 20,
  },
  circular: {
    color: "#E77228!important",
  },
  skeleton: {
    height: "80px!important",
  },
});

const TableComponent: React.FC<TableComponentTypes> = ({
  TableBodyComponent,
  columns,
  loading,
  control,
  sortOptions,
  renderOneFilterItem,
  isShowSortingNames,
  isShowSortingTypes,
  rightButtonFunction,
  isRightButtonFunction,
  showRightHeaderItems,
  filterBadge,
  showRightButton,
  RenderSearchTextField,
  headerButtonTitle,
  queryParams,
  setQueryParams,
  showFilterIcon,
  setOpenModal,
  onOpenModal,
  rightButtonPermission,
  centerColumnHeaders = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.mainRoot}>
      <Box className={classes.headerBox}>
        <Box className={classes.filterSearchBox}>
          <Box>
            {" "}
            {showFilterIcon ? (
              <Box onClick={onOpenModal} className={classes.filterBox}>
                <IconButton style={{ padding: 0 }}>
                  <Box className={classes.filterButton}>
                    <img src="/static/svg/Filter_alt_light.svg" />
                  </Box>
                </IconButton>
              </Box>
            ) : (
              <Box style={{ height: 70 }} padding={"16px 10px 12px"}></Box>
            )}
          </Box>
          {renderOneFilterItem}
          {RenderSearchTextField}

          <div className="d-flex flex-row gap-2 align-items-center">
            {isShowSortingNames || isShowSortingTypes ? (
              <span className="fw-bold">Sort : </span>
            ) : null}
            {isShowSortingNames ? (
              <ControlledComboBox
                className={classes.comboBox}
                options={sortOptions}
                textLabel=""
                control={control}
                name="sortName"
              />
            ) : null}
            {isShowSortingTypes ? (
              <ControlledComboBox
                className={classes.comboBox}
                options={[
                  { name: "Descending", id: "-" },
                  { name: "Ascending", id: "" },
                ]}
                textLabel=""
                control={control}
                name="sortType"
              />
            ) : null}
          </div>
        </Box>

        <div className="d-flex flex-row align-items-center gap-2">
          {showRightHeaderItems ? <>{showRightHeaderItems}</> : null}
          {showRightButton ? (
            rightButtonPermission ? (
              <Box className={classes.buttonBox}>
                <PermissionWrapper permission={rightButtonPermission}>
                  <AdminButtonComponent
                    title={headerButtonTitle}
                    onClick={() =>
                      isRightButtonFunction && rightButtonFunction
                        ? rightButtonFunction()
                        : setOpenModal && setOpenModal(true)
                    }
                  />
                </PermissionWrapper>
              </Box>
            ) : (
              <Box className={classes.buttonBox}>
                <AdminButtonComponent
                  title={headerButtonTitle}
                  onClick={() =>
                    isRightButtonFunction && rightButtonFunction
                      ? rightButtonFunction()
                      : setOpenModal && setOpenModal(true)
                  }
                />
              </Box>
            )
          ) : null}
        </div>
      </Box>

      <Box className={classes.orangeLine}></Box>
      {filterBadge}
      {loading ? (
        <Skeleton count={10} className={classes.skeleton} />
      ) : (
        <>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {columns.map((col) => {
                    return (
                      <TableCell
                        className={classes.colsCell}
                        key={col.id}
                        sx={{
                          textAlign: centerColumnHeaders ? "center" : "left",
                        }}
                      >
                        <p> {col.name}</p>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableBodyComponent />
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.pagination}>
            <Pagination
              onChange={(event: React.ChangeEvent<unknown>, page) =>
                setQueryParams &&
                setQueryParams({
                  page: page,
                  limit: queryParams?.limit || 1,
                  total: queryParams?.total || 1,
                  totalPage: queryParams?.totalPage || 1,
                })
              }
              count={queryParams?.totalPage}
              page={queryParams?.page}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TableComponent;
