import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Controller } from "react-hook-form";

// import { getMyAddress } from "@services/address-management";
import moment from "moment";
import ControlledComboBox from "../../../components/ControlledComboBox";
import ControlledDateField from "../../../components/ControlledDateField";
import ControlledTextField from "../../../components/ControlledTextField";

const useStyles = makeStyles((theme) => ({
  hr: {
    opacity: 0.4,
  },
  mainText: {
    fontSize: 18,
  },
  Input: {
    borderRadius: "10px!important",
    width: "100%",
    height: 45,
    padding: 15,
  },

  checkLimitButton: {
    border: "none",
    borderRadius: 10,
    width: "100%",
    background: "#E77228",
    color: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    height: "40px",
    marginTop: 12,
    "&:disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },

  OrderDateInput: {
    borderRadius: 10,
    height: 45,
    padding: 15,
  },
  OrderDateHalfInput: {
    width: "100%",
    borderRadius: 10,
    height: 45,
    padding: 15,
  },
  showMore: {
    fontSize: 14,
    cursor: "pointer",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
}));

const InventoryInfo = ({
  sellerCompany,
  control,
  watch,
  reset,
  errors,
  setValue,
  addresses,
  setAddresses,
}: {
  setAddresses: any;
  sellerCompany?: { name: string; id: string };
  control: any;
  addresses: { name: string; id: string }[];
  watch: any;
  errors: any;
  setValue: any;
  reset: any;
}) => {
  const classes = useStyles();

  const [isShowMore, setIsShowMore] = useState(false);

  useEffect(() => {
    if (sellerCompany?.id) {
      setValue("location", addresses[0]);
      // setValue(
      //   "supplier",
      //   sellerCompany?.name?.toUpperCase().replaceAll(" ", "-")
      // );
      setValue(
        "consignmentNumber",
        `${sellerCompany?.name} ${moment(new Date()).format("YYYYMMDD-hhmm")}`
          ?.toUpperCase()
          .replaceAll(" ", "-")
      );
    }
  }, [sellerCompany?.id, addresses?.length]);

  // useEffect(() => {
  //   const getAllAddresses = async () => {
  //     const response = await getMyAddress();
  //     if (response) {
  //       setAddresses(response);
  //     }
  //   };
  //   getAllAddresses();
  // }, []);

  const onShowMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <span className={classes.mainText}>Inventory Info</span>
        <span onClick={onShowMoreLess} className={classes.showMore}>
          {isShowMore ? "Show less" : "Show more"}
        </span>
      </div>
      <Divider className={classes.hr} />

      <div className="row mt-2 g-3">
  
        <div className="col-3">
          <ControlledTextField
            textLabel="Consignment Number"
            error={!!errors?.consignmentNumber}
            helperText={errors?.consignmentNumber?.message}
            name="consignmentNumber"
            control={control}
          />
        </div>
        <div className="col-4 d-flex flex-row gap-2 align-items-center">
          <div>
            <ControlledDateField
            error={!!errors?.orderDate}
            helperText={errors?.orderDate?.message}
              defaultValue={new Date().toString()}
              textLabel="Order/Delivery Date"
              name="orderDate"
              control={control}
            />
          </div>
          <div>
            <ControlledDateField
                       error={!!errors?.invoiceDate}
                       helperText={errors?.invoiceDate?.message}
              defaultValue={new Date().toString()}
              textLabel="Invoice Date"
              name="invoiceDate"
              control={control}
            />
          </div>
        </div>
        <div className="col-5 d-flex flex-row gap-2 ">
          <ControlledTextField
            textLabel="Seller Ref No"
            name="sellerReferenceNumber"
            control={control}
          />
          <ControlledTextField
            textLabel="Buyer Ref No"
            name="buyerReferenceNumber"
            control={control}
          />
        </div>

        {isShowMore ? (
          <>
            <div className="col-3">
              <ControlledTextField
                textLabel="Reference Number"
                name="referenceNumber"
                control={control}
              />
            </div>
            <div className="col-3">
              <ControlledTextField
                textLabel="Supplier Reference"
                name="supplierReference"
                control={control}
              />
            </div>
            <div className="col-3">
              <ControlledDateField
                defaultValue={new Date().toString()}
                name="receiveDate"
                textLabel="Receive Date"
                control={control}
              />
            </div>
            <div className="col-3">
              <ControlledComboBox
                
                className={classes.comboBox}
                control={control}
                name="location"
                options={addresses}
                error={!!errors?.location}
                helperText={errors?.location?.message}
                textLabel={"Location"}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default InventoryInfo;
