import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import PermissionWrapper from "../../../../components/PermissionWrapper";
import AdminButtonComponent from "../../../../components/AdminButtonComponent";
import { makeStyles } from "@mui/styles";
import { OrderStateType } from "../../orderStateType";
import AdminModalComponent from "../../../../components/AdminModalComponent";
import ControlledTextField from "../../../../components/ControlledTextField";
import ControlledTextArea from "../../../../components/ControlledTextArea";
import { toast } from "react-toastify";
import moment from "moment";
import { addNewNote, deleteNote } from "../../../../services/notes";
import { useAppSelector } from "../../../../hooks/hooks";

const schema = yup.object().shape({
  noteText: yup.string(),
});

const useStyles = makeStyles({
  tableBox: {
    borderRadius: "20px",
    padding: 15,
    border: "1px solid #F6F6F6",
  },
  tableHeaderText: {
    color: "#737373",

    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.24px",
  },
  table: {
    borderBottom: "none!important",
  },
  tableColumnsText: {
    color: "#3D3D3D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600!important",
    lineHeight: "normal",
    letterSpacing: "0.24px",
  },
  tableRowsText: {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "0.24px",
  },
  tableRowsHrefText: {
    color: "#E77228",
    "&:hover": {
      color: "#E77228!important",
    },
    cursor: "pointer",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "0.24px",
  },
  deleteIcon: {
    cursor: "pointer",
  },
  trash: {
    cursor: "pointer",
    fontSize: 20,
    color: "red",
  },
  headerTitle: {
    fontSize: 20,
  },
  addNew: {
    background: "#E77228!important",
    borderRadius: "10px!important",
  },
  hr: {
    marginTop: 10,
    opacity: 0.07,
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  deleteButton: {
    width: "50%",
    height: "50px",
    background: "red!important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});
const Notes = ({
  setRefresh,
  orderData,
  setOrderData,
}: {
  orderData?: OrderStateType;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  setOrderData: React.Dispatch<
    React.SetStateAction<OrderStateType | undefined>
  >;
}) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      noteText: "",
    },
  });
  const classes = useStyles();

  const user = useAppSelector((state) => state.auth.adminUserInfo);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");
  const [selectedNoteText, setSelectedNoteText] = useState("");
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const [openSeeAllNoteModal, setOpenSeeAllNoteModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onOpenAddNoteModal = () => {
    setOpenNoteModal(true);
  };
  const onOpenDeleteConfirmModal = (noteId: string) => {
    setSelectedNote(noteId);
    setOpenDeleteConfirmModal(true);
  };

  const onOpenSeeAllNoteModal = (text: string) => {
    setOpenSeeAllNoteModal(true);
    setSelectedNoteText(text);
  };
  const onCloseAddNoteModal = () => {
    setOpenNoteModal(false);
  };
  const onCloseDeleteConfirmModal = () => {
    setOpenDeleteConfirmModal(false);
  };

  const onCloseSeeAllNoteModal = () => {
    setOpenSeeAllNoteModal(false);
    setSelectedNoteText("")
  };

  const onAddNote = useCallback(
    async (data: any) => {
      if (!data?.noteText) {
        toast.error("Please type a note");
      } else {
        const finalData = {
          note: data?.noteText,
          noteType: "ORDER",
          relatedId: orderData?._id,
        };
        setAddLoading(true);

        const added = await addNewNote(finalData);
        setAddLoading(false);

        if (added?.success) {
          reset();
          setOpenNoteModal(false);
          setRefresh((prev: number) => prev + 1);
        }
      }
    },
    [orderData?._id]
  );
  const onDeleteNote = useCallback(async () => {
    setDeleteLoading(true);

    const deleted = await deleteNote(selectedNote);
    setDeleteLoading(false);

    if (deleted?.success) {
      setOpenDeleteConfirmModal(false);
      setSelectedNote("");
      setRefresh((prev: number) => prev + 1);
    }
  }, [orderData, selectedNote, setOrderData]);

  const renderAddNoteModalBody = () => {
    return (
      <>
        {" "}
        <ControlledTextArea
          minRows={5}
          multiline
          textLabel="Note"
          control={control}
          name="noteText"
        />
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={addLoading}
            className={classes.closeButton}
            onClick={onCloseAddNoteModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={addLoading}
            disabled={addLoading}
            className={classes.saveButton}
            onClick={handleSubmit(onAddNote)}
            title="Submit"
          />
        </Box>
      </>
    );
  };
  const renderDeleteNoteModalBody = () => {
    return (
      <>
        {" "}
        <span>Are you sure delete this note?</span>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={deleteLoading}
            className={classes.closeButton}
            onClick={onCloseDeleteConfirmModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={deleteLoading}
            disabled={deleteLoading}
            className={classes.deleteButton}
            onClick={onDeleteNote}
            title="Delete"
          />
        </Box>
      </>
    );
  };

  const renderSeeAllNoteModalBody = () => {
    return (
      <>
        {" "}
        <span>{selectedNoteText}</span>
        <Box className="d-flex justify-content-end mt-3">
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseSeeAllNoteModal}
            title="Close"
          />{" "}
        </Box>
      </>
    );
  };
  return (
    <div className="p-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span className={classes.headerTitle}>Notes</span>
        <div className="d-flex flex-row gap-2">
          <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
            <AdminButtonComponent
              onClick={onOpenAddNoteModal}
              className={classes.addNew}
              title=" + Add new note"
            />
          </PermissionWrapper>
        </div>
      </div>
      <hr className={classes.hr} />
      {orderData?.notes?.length ? (
        <div className="d-flex flex-column gap-2">
          <TableContainer className={classes.table}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.table}>
                    <Typography className={classes.tableColumnsText}>
                      Admin
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.table}>
                    <Typography className={classes.tableColumnsText}>
                      Note
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.table}>
                    <Typography className={classes.tableColumnsText}>
                      Create Date
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.table}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderData?.notes?.map((doc, index) => {
                  const adminUser = orderData?.admins?.find(
                    (i: any) => i?._id === doc?.adminId
                  );

                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={classes.table}
                        component="th"
                        scope="row"
                      >
                        <Typography className={classes.tableRowsText}>
                          {" "}
                          {adminUser?.fullName}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.table}>
                        <span className="cursor-pointer text-decoration-underline"
                          onClick={() => {
                            onOpenSeeAllNoteModal(doc.note);
                          }}
                        >
                          { doc.note.length>48? doc.note.slice(0, 48).concat("..."):doc.note}
                        </span>
                      </TableCell>
                      <TableCell className={classes.table}>
                        {moment(doc.createdAt).format("DD/MM/YYYY")}
                      </TableCell>

                      <TableCell className={classes.table}>
                        {user.id === doc.adminId ? (
                          <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                            <IconButton onClick={() => null}>
                              <i
                                onClick={() =>
                                  onOpenDeleteConfirmModal(doc._id)
                                }
                                className={`ri-delete-bin-line ${classes.trash} `}
                              ></i>
                            </IconButton>
                          </PermissionWrapper>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : null}
      <AdminModalComponent
        children={renderAddNoteModalBody()}
        headerTitle="New note"
        openModal={openNoteModal}
        closeModal={onCloseAddNoteModal}
      />
      <AdminModalComponent
        children={renderDeleteNoteModalBody()}
        headerTitle="Are you sure"
        openModal={openDeleteConfirmModal}
        closeModal={onCloseDeleteConfirmModal}
      />{" "}
      <AdminModalComponent
        children={renderSeeAllNoteModalBody()}
        headerTitle="Note"
        openModal={openSeeAllNoteModal}
        closeModal={onCloseSeeAllNoteModal}
      />
    </div>
  );
};

export default Notes;
