import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { Controller } from "react-hook-form";
// import { getMyAddress } from "@services/address-management";
import moment from "moment";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Divider } from "@mui/material";
import { Country, Currency, UnitsType } from "./interfaces";
import ControlledComboBox from "../../../components/ControlledComboBox";
import ControlledTextField from "../../../components/ControlledTextField";
import NumberFormatComponent from "../../../components/NumberFormatComponent";
import {
  getSubTypesWithProductTypeId,
  getUnits,
} from "../../../services/common";

const useStyles = makeStyles((theme) => ({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  hr: {
    opacity: 0.4,
  },
  hrOther: {
    marginTop: 10,
    opacity: 0.2,
  },
  mainText: {
    fontSize: 18,
  },
  Input: {
    borderRadius: "10px!important",
    width: "100%",
    height: 45,
    padding: 15,
  },
  checkLimitButton: {
    border: "none",
    borderRadius: 10,
    width: "100%",
    background: "#E77228",
    color: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    height: "40px",
    marginTop: 30,
    "&:disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },
  customControlledField: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  OrderDateInput: {
    borderRadius: 10,
    height: 45,
    padding: 15,
  },
  showMore: {
    fontSize: 14,
    cursor: "pointer",
  },
  // checkLimitButton: {
  //   border: "none",
  //   borderRadius: 10,
  //   width: "100%",
  //   background: "#E77228",
  //   color: "white",
  //   marginTop: 17,
  //   alignItems: "center",
  //   display: "flex",
  //   justifyContent: "center",
  //   cursor: "pointer",
  //   height: "40px",

  //   "&:disabled": {
  //     opacity: 0.5,
  //     cursor: "default",
  //   },
  // },
  totalAmount: {
    fontSize: 13,
  },
  trash: {
    cursor: "pointer",
  },
  fontFamily: {
    fontFamily: "Montserrat,sans-serif!important",
  },
  delete: {
    cursor: "pointer",
    fontSize: 20,
    color: "red",
  },
  textField: {
    background: "#F8F8F8",
    fontSize: 20,
    borderRadius: 10,
    border: "none !important",
    width: "100%",
    height: 50,
    padding: 15,
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red !important",
    },
    "& .MuiInputBase-root": {
      background: "#f8f8f8",
      minHeight: 50,
      borderColor: "black !important",
      border: "none !important",
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  prices: {},
}));

const Produces = ({
  suppliers,
  growers,
  isExpandedSidebar,
  setSubTempTypes,
  subTempTypes,
  defaultCountry,
  currencySymbol,
  units,
  setUnits,
  addedProduces,
  setAddedProduces,
  countries,
  sellerCompany,
  setValue,
  control,
  watch,
  reset,
  errors,
  currencies,
  subTypes,
  setSubTypes,
  setSubTypeLoading,
}: {
  suppliers: string[];
  growers: string[];
  isExpandedSidebar: boolean;
  defaultCountry: { name: string; id: string };
  units: { name: string; id: string }[];
  setAddedProduces: React.Dispatch<React.SetStateAction<any[]>>;
  setUnits: React.Dispatch<
    React.SetStateAction<{ name: string; id: string }[]>
  >;
  setSubTempTypes: React.Dispatch<React.SetStateAction<any[]>>;
  subTempTypes: any[];
  currencySymbol: string;
  setSubTypeLoading: React.Dispatch<React.SetStateAction<boolean>>;
  subTypes: { name: string; id: string }[];
  sellerCompany?: { name: string; id: string };
  setSubTypes: React.Dispatch<
    React.SetStateAction<{ name: string; id: string }[]>
  >;
  watch: any;
  reset: any;
  control: any;
  setValue: any;
  errors: any;
  countries: Country[];
  addedProduces: any[];
  currencies: Currency[];
}) => {
  const classes = useStyles();

  const defaultUnit = units.find((item) => item?.name === "Boxes");

  const [isShowMore, setIsShowMore] = useState(false);
  const [productType, setProductType] = useState("");

  const watchProduceType = watch("produceType");
  const watchProduceName = watch("produceName");
  const watchAdvisoryPrice = watch("advisoryPrice");
  const watchReturnPrice = watch("returnPrice");
  const quantity = watch("quantity");
  const watchUnit = watch("unit");
  const creditLimitCurrency = watch("creditLimitCurrency");
  const creditLimit = watch("creditLimit");
  const watchVariety = watch("variety");
  const watchBoxesInPallet = watch("boxesInPallet");
  const watchGrower = watch("grower");
  const watchHaulier = watch("haulier");
  const watchPackingSize = watch("packingSize");
  const watchCountryOfOrigin = watch("countryOfOrigin");
  const watchBrand = watch("brand");
  const watchBuyerCompany = watch("buyerCompany");
  const watchSupplier = watch("supplier");
  const watchConsignmentNumber = watch("consignmentNumber");
  const watchOrderDate = watch("orderDate");
  const watchInvoiceDate = watch("invoiceDate");
  const watchReferenceNumber = watch("referenceNumber");
  const watchSupplierReference = watch("supplierReference");
  const watchReceiveDate = watch("receiveDate");
  const watchLocation = watch("location");
  const watchProduceCurrency = watch("produceCurrency");

  const totalProducesValue =
    addedProduces?.length &&
    addedProduces
      ?.map((item: any) => item?.finalAmount)
      ?.reduce((prev: any, curr: any) => prev + curr);

  const lineItemAmount =
    (parseFloat(watchReturnPrice)
      ? watchReturnPrice * quantity
      : watchAdvisoryPrice * quantity) || 0;

  useEffect(() => {
    if (lineItemAmount) {
      setValue(
        "lineItemAmount",
        lineItemAmount.toFixed(2).toString() || (0).toString()
      );
    } else {
      setValue("lineItemAmount", (0).toString());
    }
  }, [lineItemAmount]);

  useEffect(() => {
    if (sellerCompany?.id) {
      setValue(
        "supplier",
        sellerCompany?.name?.toUpperCase().replaceAll(" ", "-")
      );
    }
  }, [sellerCompany?.id]);

  useEffect(() => {
    if (defaultCountry.id) {
      setValue("countryOfOrigin", defaultCountry);
    }
  }, [defaultCountry?.id]);

  useEffect(() => {
    if ((watchProduceType as any)?.id) {
      const subTypeProductType = subTempTypes?.find(
        (item: any) => item._id === (watchProduceType as any)?.id
      );

      if (subTypeProductType?._id) {
        setProductType(subTypeProductType?.productType?._id);
      }
    }
  }, [(watchProduceType as any)?.id]);

  useEffect(() => {
    if (defaultUnit?.id) {
      setValue("unit", defaultUnit);
    }
  }, [defaultUnit?.id]);



  useEffect(() => {
    if (watchProduceType) {
      setValue("produceName", watchProduceType?.name);
    }
  }, [watchProduceType]);

  useEffect(() => {
    if (creditLimitCurrency?.id) {
      setValue("produceCurrency", creditLimitCurrency?.name);
    }
  }, [creditLimitCurrency?.id]);

  const onShowMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  const onAddProduce = () => {
    const isSameCurrency = addedProduces?.filter(
      (p) => p?.currency?.name !== creditLimitCurrency?.name
    );

    if (!watchSupplier) {
      toast.error("Please check supplier field,not allow to be empty.");
    } else {
      if (isSameCurrency?.length) {
        toast.error("All produces has to be same currency.");
      } else {
        if (
          !watchProduceName ||
          !watchProduceType ||
          !creditLimitCurrency ||
          !watchAdvisoryPrice ||
          !quantity ||
          !watchUnit?.id
        ) {
          toast.error("Please fill all produce fields");
        } else {
          const data = {
            produceName: watchProduceName,
            produceSubType: watchProduceType,
            produceType: productType,
            supplier: watchSupplier,
            currency: creditLimitCurrency,
            advisoryPrice: parseFloat(watchAdvisoryPrice),
            returnPrice: parseFloat(watchReturnPrice)?parseFloat(watchReturnPrice):null,
            finalAmount: watchReturnPrice
              ? parseFloat(watchReturnPrice) * parseFloat(quantity)
              : parseFloat(watchAdvisoryPrice) * parseFloat(quantity),
            quantity: parseFloat(quantity),
            unit: watchUnit,
            haulier: watchHaulier,
            grower: watchGrower,
            variety: watchVariety,
            packingSize: watchPackingSize,
            boxesInPallet: watchBoxesInPallet,
            brand: watchBrand,
            countryOfOrigin: watchCountryOfOrigin|| defaultCountry,
          };
          setAddedProduces([...addedProduces, data]);
          setValue("produceName", "");
          setValue("produceType", null);
          setValue("haulier", "");
          setValue("supplier", "");
          setValue("produceCurrency", creditLimitCurrency?.name);
          setValue("packingSize", "");
          setValue("boxesInPallet", "");
          setValue("lineItemAmount", "");
          setValue("quantity", "");
          setValue("category", "Cat 1");
          setValue("advisoryPrice", "");
          setValue("returnPrice", "");
          setValue("grower", "");
          setValue("countryOfOrigin", defaultCountry);
          setValue("variety", "");
          setValue("brand", "");
          setValue("unit", watchUnit);
        }
      }
    }
  };

  const onDeleteProduce = (index: number) => {
    const newAddedProduces = addedProduces?.filter((p, i) => i !== index);
    setAddedProduces(newAddedProduces);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <span className={classes.mainText}>Produces</span>
        <span onClick={onShowMoreLess} className={classes.showMore}>
          {isShowMore ? "Show less" : "Show more"}
        </span>
      </div>
      <Divider className={classes.hr} />

      <div className="row mt-2 g-3">
        {/* <div className="col-2">
          <ControlledComboBox
            className={classes.customControlledField}
            control={control}
            name="produceType"
            options={subTypes}
            textLabel={"Produce Type"}
          />
        </div>
        <div className="col-2">
          <ControlledTextField
            textLabel="Produce Name"
            name="produceName"
            control={control}
          />
        </div>
        {creditLimitCurrency?.id && (
          <div className="col-1">
            <ControlledTextField
              className={classes.customControlledField}
              control={control}
              name="produceCurrency"
              disabled
              textLabel={"Currency"}
            />
          </div>
        )}
        <div className={`col-1 ${classes.prices}`}>
          <ControlledTextField
            textLabel="Advisory Price"
            // inputProps={{ min: 0, style: { textAlign: "end" } }}

            name="advisoryPrice"
            control={control}
          />
        </div>
        <div style={{ marginLeft: 20 }} className={`col-1 ${classes.prices}`}>
          <ControlledTextField
            textLabel="Return Price"
            // inputProps={{ min: 0, style: { textAlign: "end" } }}

            name="returnPrice"
            control={control}
          />
        </div>
        <div style={{ marginLeft: 10 }} className="col-1">
          <ControlledTextField
            textLabel="Quantity"
            name="quantity"
            control={control}
          />
        </div>
        {units.length ? (
          <div className="col-1">
            <ControlledComboBox
              className={classes.customControlledField}
              control={control}
              defaultValue={defaultUnit}
              name="unit"
              options={units}
              textLabel={"Unit"}
            />
          </div>
        ) : null}
        <div
          style={{ marginLeft: 5 }}
          className="col-1 d-flex align-items-center "
        >
          <span className={classes.totalAmount}>
            {" "}
            Total amount :{" "}
            <NumberFormatComponent
              prefix={currencySymbol}
              // decimalScale={2}
              // fixedDecimalScale
              // displayType="text"
              // thousandSeparator={true}
              value={lineItemAmount}
            />{" "}
          </span>
        </div>
        <div className="col-1">
          <button onClick={onAddProduce} className={classes.checkLimitButton}>
            Add
          </button>
        </div> */}
        <TableContainer>
          <Table sx={{ minWidth: 1000 }} aria-label="simple table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={{ width: 220 }}>
                  <ControlledComboBox
                    freeSolo
                    autoSelect
                    options={suppliers}
                    error={!!errors?.supplier}
                    helperText={errors?.supplier?.message}
                    className={classes.comboBox}
                    textLabel="Supplier"
                    name="supplier"
                    control={control}
                  />
                </TableCell>
                <TableCell>
                  <ControlledComboBox
                    className={classes.customControlledField}
                    control={control}
                    name="produceType"
                    options={subTypes}
                    textLabel={"Produce Type"}
                  />
                </TableCell>
                <TableCell>
                  <ControlledTextField
                    className={classes.textField}
                    textLabel="Produce Name"
                    name="produceName"
                    control={control}
                  />
                </TableCell>

                <TableCell style={{ width: 150 }}>
                  {" "}
                  <ControlledTextField
                    onBlur={() =>
                      !watchReturnPrice
                        ? setValue("returnPrice", watchAdvisoryPrice)
                        : null
                    }
                    textLabel="Advi P."
                    // inputProps={{ min: 0, style: { textAlign: "end" } }}

                    name="advisoryPrice"
                    control={control}
                  />
                </TableCell>
                <TableCell style={{ width: 150 }}>
                  {" "}
                  <ControlledTextField
                    textLabel="Ret P." // inputProps={{ min: 0, style: { textAlign: "end" } }}
                    name="returnPrice"
                    control={control}
                  />
                </TableCell>
                <TableCell style={{ width: 100 }}>
                  {" "}
                  <ControlledTextField
                    textLabel="Quantity"
                    name="quantity"
                    control={control}
                  />
                </TableCell>
                <TableCell>
                  {units.length ? (
                    <ControlledComboBox
                      className={classes.customControlledField}
                      control={control}
                      defaultValue={defaultUnit}
                      name="unit"
                      options={units}
                      textLabel={"Unit"}
                    />
                  ) : null}
                </TableCell>
                <TableCell style={{ width: 150 }}>
                  {" "}
                  <ControlledTextField
                    disabled
                    textLabel="Total"
                    name="lineItemAmount"
                    control={control}
                  />
                  {/* <NumberFormatComponent
                      prefix={currencySymbol}
                      // decimalScale={2}
                      // fixedDecimalScale
                      // displayType="text"
                      // thousandSeparator={true}
                      value={lineItemAmount}
                    />{" "} */}
                </TableCell>
                <TableCell style={{ width: 100 }}>
                  <button
                    onClick={onAddProduce}
                    className={classes.checkLimitButton}
                  >
                    Add
                  </button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {isShowMore ? (
          <>
            {/* <div className="col-3">
              <ControlledTextField
                textLabel="Supplier"
                error={!!errors?.supplier}
                helperText={errors?.supplier?.message}
                name="supplier"
                control={control}
              />
            </div> */}
            <div className="col-2">
              <ControlledComboBox
                className={classes.customControlledField}
                control={control}
                name="countryOfOrigin"
                options={countries.map((item) => ({
                  name: item.countryName,
                  id: item.geonameId,
                }))}
                textLabel={"Country Of Origin"}
              />
            </div>
            <div className="col-1">
              <ControlledTextField
                textLabel="Variety"
                name="variety"
                control={control}
              />
            </div>
            <div className="col-1">
              <ControlledTextField
                textLabel="Brand"
                name="brand"
                control={control}
              />
            </div>
            <div className="col-2">
              <ControlledComboBox
                className={classes.customControlledField}
                control={control}
                name="category"
                error={!!errors?.category}
                helperText={errors?.category?.message}
                options={["Cat 1", "Cat 2"]}
                textLabel={"Category"}
              />
            </div>
            <div className="col-3">
              <ControlledComboBox
                className={classes.comboBox}
                autoSelect
                freeSolo
                options={growers}
                textLabel="Grower"
                name="grower"
                control={control}
              />
            </div>
            <div className="col-2">
              <ControlledTextField
                textLabel="Haulier"
                name="haulier"
                control={control}
              />
            </div>

            <div className="col-2">
              <ControlledTextField
                textLabel="Pack Size"
                name="packingSize"
                control={control}
              />
            </div>
            <div className="col-2">
              <ControlledTextField
                textLabel="Box In Pallet"
                name="boxesInPallet"
                control={control}
              />
            </div>
          </>
        ) : null}
      </div>
      {addedProduces?.length ? <hr className={classes.hrOther} /> : null}
      <div className="mt-3 ">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {addedProduces.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ width: 180 }} component="th" scope="row">
                    {row?.supplier}
                  </TableCell>
                  <TableCell style={{ width: 180 }} component="th" scope="row">
                    {row?.produceSubType?.name}
                  </TableCell>

                  <TableCell style={{ width: 180 }}>
                    {row?.produceName}
                  </TableCell>

                  <TableCell>
                    {" "}
                    <NumberFormatComponent
                      prefix={currencySymbol}
                      // decimalScale={2}
                      // fixedDecimalScale
                      // displayType="text"
                      // thousandSeparator={true}
                      value={row?.advisoryPrice}
                    />
                  </TableCell>
                  {isExpandedSidebar ? <TableCell></TableCell> : null}

                  <TableCell>
                    {" "}
                    <NumberFormatComponent
                      prefix={currencySymbol}
                      // decimalScale={2}
                      // fixedDecimalScale
                      // displayType="text"
                      // thousandSeparator={true}
                      value={row?.returnPrice}
                    />
                  </TableCell>
                  {isExpandedSidebar ? <TableCell></TableCell> : null}

                  {!isExpandedSidebar ? <TableCell></TableCell> : null}

                  <TableCell>{row?.quantity}</TableCell>
                  {!isExpandedSidebar ? <TableCell></TableCell> : null}
                  {isExpandedSidebar ? <TableCell></TableCell> : null}

                  <TableCell>{row?.unit?.name}</TableCell>
                  {isExpandedSidebar ? <TableCell></TableCell> : null}

                  <TableCell>
                    <NumberFormatComponent
                      prefix={currencySymbol}
                      // decimalScale={2}
                      // fixedDecimalScale
                      // displayType="text"
                      // thousandSeparator={true}
                      value={row?.finalAmount}
                    />
                  </TableCell>
                  {isExpandedSidebar ? <TableCell></TableCell> : null}
                  {isExpandedSidebar ? <TableCell></TableCell> : null}
                  {isExpandedSidebar ? <TableCell></TableCell> : null}

                  <TableCell>
                    <i
                      onClick={() => onDeleteProduce(index)}
                      className={`ri-delete-bin-line ${classes.delete}`}
                    ></i>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {addedProduces?.length ? (
          <>
            <Divider className={classes.hr} />
            <span
              style={{
                marginRight: isExpandedSidebar ? 165 : 125,
                fontSize: 14,
                fontWeight: 600,
              }}
              className="d-flex justify-content-end mt-2"
            >
              <NumberFormatComponent
                prefix={currencySymbol}
                value={totalProducesValue}
              />
            </span>
          </>
        ) : null}
        <hr className={classes.hrOther} />
      </div>
    </div>
  );
};

export default Produces;
