import React, { useCallback, useEffect, useMemo, useState } from "react";
import TableComponent from "../../../components/TableComponent";
import { Box, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AdminModalComponent from "../../../components/AdminModalComponent";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import ControlledTextField from "../../../components/ControlledTextField";
import * as yup from "yup";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import {
  addSize,
  addUnit,
  addVariety,
  editSize,
  editUnit,
  editVariety,
} from "../../../services/variety-size-unit";
import ControlledComboBox from "../../../components/ControlledComboBox";
import { getSubTypesWithProductTypeId } from "../../../services/common";
import PermissionWrapper from "../../../components/PermissionWrapper";

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});

const validationSchema = yup.object().shape({
  subProductType: yup.object(),
  unitWeight: yup.string(),
  subProductTypeEdit: yup.object(),
  unitWeightEdit: yup.string(),
  name: yup.string(),
  nameEdit: yup.string(),
});

const Unit = ({
  setIsFetch,
  units,
  fetchLoading,
  setQueryParams,
  queryParams,
}: {
  setIsFetch: React.Dispatch<React.SetStateAction<number>>;
  units: {
    subProductName: string;
    subProductID: string;
    unitWeight: number;
    unitDescription: string;
    _id: string;
  }[];
  fetchLoading: boolean;
  queryParams: any;
  setQueryParams: any;
}) => {
  const { control, setValue, watch, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      subProductType: {},
      unitWeight: "",
      name: "",
      unitWeightEdit: "",
      subProductTypeEdit: {},
      nameEdit: "",
    },
  });


  // const sortedUnits=useMemo(()=>{return units.sort(function (a: any, b: any) {
  //   if (a?.subProductName?.toUpperCase() < b.subProductName?.toUpperCase()) {
  //     return -1;
  //   }
  //   if (a.subProductName?.toUpperCase() > b.subProductName?.toUpperCase()) {
  //     return 1;
  //   }
  //   return 0;
  // });},[units])

  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [subTypes, setSubTypes] = useState<{ name: string; _id: string }[]>([]);
  const [selectedItem, setSelectedItem] = useState<{
    subProductName: string;
    subProductID: string;
    unitWeight: number;
    unitDescription: string;
    _id: string;
  }>();

  const nameUnit = watch("name");
  const subProductUnit = watch("subProductType");
  const unitWeight = watch("unitWeight");
  const nameUnitEdit = watch("nameEdit");
  const subProductUnitEdit = watch("subProductTypeEdit");
  const unitWeightEdit = watch("unitWeightEdit");


  const onCloseAddModal = () => {
    setOpenAddModal(false);
  };
  const onCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const onClickEditItem = (item: {
    subProductName: string;
    subProductID: string;
    unitWeight: number;
    unitDescription: string;
    _id: string;
  }) => {
    setOpenEditModal(true);
    setSelectedItem(item);
  };

  const onAddNewUnit = useCallback(async () => {
    if (!nameUnit) {
      toast.error("Name field  required");
    } else if (!(subProductUnit as { name: string; _id: string })?._id) {
      toast.error("Sub product type required");
    } else if (!unitWeight) {
      toast.error("Unit weight required");
    } else {
      const finalData = {
        data: {
          name: nameUnit,
          subProductTypeId: (subProductUnit as { name: string; _id: string })
            ?._id,
          unitWeight: parseFloat(unitWeight),
        },
      };
      setLoading(true);
      const added = await addUnit(finalData);

      setLoading(false);
      if (added) {
        reset();
        setIsFetch((prev) => prev + 1);
        setOpenAddModal(false);
      }
    }
  }, [
    nameUnit,
    setIsFetch,
    setOpenAddModal,
    reset,
    subProductUnit,
    unitWeight,
  ]);

  const onEditUnit = useCallback(async () => {
    if (!nameUnitEdit) {
      toast.error("Name field  required");
    } else if (!(subProductUnitEdit as { name: string; _id: string })?._id) {
      toast.error("Sub product type required");
    } else if (!unitWeightEdit) {
      toast.error("Unit weight required");
    } else {
      const finalData = {
        data: {
          name: nameUnitEdit,
          subProductTypeId: (
            subProductUnitEdit as { name: string; _id: string }
          )?._id,
          id: selectedItem?._id,
          unitWeight: parseFloat(unitWeightEdit || ""),
        },
      };
      setLoading(true);
      const edited = await editUnit(finalData);
      setLoading(false);

      if (edited) {
        setOpenEditModal(false);
        reset();
        setIsFetch((prev) => prev + 1);
      }
    }
  }, [nameUnitEdit, subProductUnitEdit,unitWeightEdit,selectedItem?._id, reset, setIsFetch]);

  useEffect(() => {
    if (selectedItem?._id) {
      setValue("nameEdit", selectedItem.unitDescription);
      setValue("subProductTypeEdit", {
        name: selectedItem.subProductName,
        _id: selectedItem.subProductID,
      });
      setValue("unitWeightEdit", selectedItem.unitWeight.toString());
    }
  }, [
    selectedItem?.subProductName,
    selectedItem?._id,
    selectedItem?.unitDescription,
    selectedItem?.subProductID,
    setValue,
    selectedItem?.unitWeight,
  ]);

  useEffect(() => {
    if ((openAddModal || openEditModal) && !subTypes.length) {
      const getSubTypes = async () => {
        const response = await getSubTypesWithProductTypeId();

        if (response?.data) {
          setSubTypes(
            response?.data?.map((i: any) => ({
              name: i?.name,
              _id: i?._id || i?.id,
            }))
          );
        }
      };
      getSubTypes();
    }
  }, [openAddModal, openEditModal, subTypes.length]);

  const renderUnitTableBody = () => {
    return (
      <>
        {units.map((u, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {u.subProductName}
              </TableCell>
              <TableCell component="th" scope="row">
                {u.unitWeight}
              </TableCell>
              <TableCell component="th" scope="row">
                {u.unitDescription}
              </TableCell>
              <TableCell component="th" scope="row">
                <div className="d-flex justify-content-end mr-2">
                  <PermissionWrapper permission="TRADE_ACTION">
                    <i
                      onClick={() => onClickEditItem(u)}
                      style={{ fontSize: 24 }}
                      className="ri-edit-line cursor-pointer"
                    ></i>
                  </PermissionWrapper>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderAddModalBody = () => {
    return (
      <>
        <ControlledTextField name="name" control={control} textLabel="Name" />
        <ControlledComboBox
          className={classes.comboBox}
          options={subTypes}
          name="subProductType"
          control={control}
          textLabel="Sub product Type"
        />
        <ControlledTextField
          name="unitWeight"
          control={control}
          textLabel="Unit weight"
        />

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenAddModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={loading}
            className={classes.saveButton}
            onClick={onAddNewUnit}
            title="Submit"
          />
        </Box>
      </>
    );
  };

  const renderEditModalBody = () => {
    return (
      <>
        <ControlledTextField
          name="nameEdit"
          control={control}
          textLabel="Name"
        />
        <ControlledComboBox
          className={classes.comboBox}
          options={subTypes}
          name="subProductTypeEdit"
          control={control}
          textLabel="Sub product Type"
        />
        <ControlledTextField
          name="unitWeightEdit"
          control={control}
          textLabel="Unit weight"
        />

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenEditModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={loading}
            className={classes.saveButton}
            onClick={onEditUnit}
            title="Edit"
          />
        </Box>
      </>
    );
  };

  return (
    <div>
      <TableComponent
        rightButtonPermission="TRADE_ACTION"
        setOpenModal={setOpenAddModal}
        headerButtonTitle="Add new Unit"
        loading={fetchLoading}
        showRightButton={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        TableBodyComponent={renderUnitTableBody}
        showFilterIcon={false}
        columns={[
          { name: "Subproduct type", id: "subType" },
          { name: "Unit weight", id: "weight" },
          { name: "Description", id: "description" },
          { name: "", id: "action" },
        ]}
      />{" "}
      <AdminModalComponent
        children={renderAddModalBody()}
        openModal={openAddModal}
        closeModal={onCloseAddModal}
        headerTitle="New Unit"
      />
      <AdminModalComponent
        children={renderEditModalBody()}
        openModal={openEditModal}
        closeModal={onCloseEditModal}
        headerTitle="Edit Unit"
      />
    </div>
  );
};

export default Unit;
