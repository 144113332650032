import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

interface blockUsersBodyType {
  blockReason: string;
  userIds: string[];
}
interface addUserBodyType {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export const getUsers = async (page: number, keyword: string) => {
  try {
    const response = await axiosApiInstance.get(
      `/users?includes=company&page=${page}&keyword=${keyword ? keyword : ""}&order=-createdAt`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const blockUsers = async (data: blockUsersBodyType) => {
  try {
    const response = await axiosApiInstance.post(`/block-users`, {
      ...data,
    });

    if (response) {
      toast.success("User is blocked successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const unblockUsers = async (userIds: string[]) => {
  try {
    const response = await axiosApiInstance.put(`/block-users`, {
      userIds,
    });

    if (response) {
      toast.success("User block is removed successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const impersonateUser = async (userId: string) => {
  try {
    const response = await axiosApiInstance.post(`/impersonation/${userId}`);

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addNewUser = async (data: addUserBodyType) => {
  try {
    const response = await axiosApiInstance.post("auth/add-user", { ...data });
    if (response) {
      toast.success("User is created successfully.");
      return response.status;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const editUser = async (data: any) => {
  try {
    const response = await axiosApiInstance.put("users/update-user-firebase", {
      ...data,
    });
    if (response) {
      toast.success("User data updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.response?.data.error);
    }
  }
};

export const getUserDetails = async (slug: string) => {
  try {
    const response = await axiosApiInstance.get(`/users/slug/${slug}`);
    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.response?.data.error);
    }
  }
};

export const updateUserDetails = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/users/update-info-admin-level`,
      {
        ...data,
      }
    );
    if (response) {
      toast.success("User information updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.response?.data.error);
    }
  }
};

export const updateUserAvatar = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(`/users/admin/update-avatar`, {
      ...data,
    });
    if (response) {
      toast.success("User avatar is  updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.response?.data.error);
    }
  }
};
