import React from "react";
import { TagsInput } from "react-tag-input-component";
const TagInput = ({
  selected,
  setSelected,
  name,
  label,
  placeholder,
}: {
  selected: string[];
  label:string,
  name?: string;
  placeholder?: string;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <div>
     <span style={{color:"rgba(0, 0, 0, 0.6)"}}>{label}</span>
      <TagsInput
      
        value={selected}
        onChange={setSelected}
        name={name}
        placeHolder={placeholder}
      />
      {/* <em>press enter to add new one</em> */}
    </div>
  );
};

export default TagInput;
