import React, { useMemo } from "react";
import { Card, Icon, Metric, Text, DonutChart } from "@tremor/react";
import { makeStyles } from "@mui/styles";
import { CashIcon } from "@heroicons/react/outline";
import { DashboardState } from "../../interface";
import NumberFormatComponent from "../../../../components/NumberFormatComponent";
import { isDarkReaderActive } from "../../../../services/common";

const useStyles = makeStyles({
  cardInfoStyle: {
    fontSize: 16,
  },
  cardInfoDarkStyle: {
    fontWeight: 600,
    color: "white",
    fontSize: 16,
  },
  cardHeaderStyle: {
    fontSize: 20,
  },
  fillTremorContentEmphasisCustom: {
    "& text.fill-tremor-content-emphasis": {
      fill: "white!important",
      fontWeight: 600,
    },
  },
});

const SalesCard = ({
  isDarkMode,
  dashboardData,
  pound,
}: {
  isDarkMode: boolean;
  dashboardData?: DashboardState;
  pound?: string;
}) => {
  const classes = useStyles();

  // const salesCompletedPlatform = useMemo(() => {
  //   return dashboardData?.salesCompleted.platformTotal.convertedTotalAmount;
  // }, [dashboardData?.salesCompleted.platformTotal.convertedTotalAmount]);
  const salesCompletedUser = useMemo(() => {
    return dashboardData?.salesCompleted.userToUser
      .map((item) => item.convertedTotalAmount)
      .reduce((prev, curr) => prev + curr, 0);
  }, [dashboardData?.salesCompleted.userToUser.length]);
  const salesCompletedFrupay = useMemo(() => {
    return dashboardData?.salesCompleted.frupay
      .map((item) => item.convertedTotalAmount)
      .reduce((prev, curr) => prev + curr, 0);
  }, [dashboardData?.salesCompleted?.frupay.length]);
  const salesCompletedTradabase = useMemo(() => {
    return dashboardData?.salesCompleted.frupay
      .filter((fr) => fr.tradeSource === "Tradabase")
      .map((item) => item.convertedTotalAmount)
      .reduce((prev, curr) => prev + curr, 0);
  }, [dashboardData?.salesCompleted.frupay.length]);

  const formattedData = [
    {
      name: "FruPay",
      sales: salesCompletedFrupay || 0,
    },
    {
      name: "Tradabase",
      sales: salesCompletedTradabase || 0,
    },
    {
      name: "User-user",
      sales: salesCompletedUser || 0,
    },
    // {
    //   name: "Platform",
    //   sales: salesCompletedPlatform || 0,
    // },
  ];

  const valueFormatter = (number: number) =>
    `${pound} ${new Intl.NumberFormat("us").format(number).toString()}`;

  return (
    <Card
      style={{ minHeight: "100%" }}
      decoration="bottom"
      decorationColor="teal"
    >
      <div className="d-flex flex-row justify-content-between gap-3">
        <Metric color="teal" className={classes.cardHeaderStyle}>
          Sales Completed
        </Metric>
        <Icon icon={CashIcon} color="teal" variant="solid" size="sm" />
      </div>
      <DonutChart
        className={`${
          isDarkMode || isDarkReaderActive()
            ? classes.fillTremorContentEmphasisCustom
            : ""
        } mt-4`}
        valueFormatter={valueFormatter}
        showAnimation
        data={formattedData}
        category="sales"
        index="name"
        colors={["teal", "emerald", "green", "lime"]}
      />

      <div className="d-flex flex-column gap-3 mt-3">
        {/* <Text className={classes.cardInfoStyle}>
          <span className="fw-bold">Platform :</span>{" "}
          {salesCompletedPlatform && pound}
          {<NumberFormatComponent value={salesCompletedPlatform || 0} />}
        </Text> */}
        <Text
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.cardInfoDarkStyle
              : classes.cardInfoStyle
          }
        >
          <span className="fw-bold">Tradabase :</span>{" "}
          {salesCompletedTradabase && pound}
          {<NumberFormatComponent value={salesCompletedTradabase || 0} />}
        </Text>
        <Text
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.cardInfoDarkStyle
              : classes.cardInfoStyle
          }
        >
          <span className="fw-bold">User-user :</span>{" "}
          {salesCompletedUser && pound}
          {<NumberFormatComponent value={salesCompletedUser || 0} />}
        </Text>
        <Text
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.cardInfoDarkStyle
              : classes.cardInfoStyle
          }
        >
          <span className="fw-bold">FruPay :</span>{" "}
          {salesCompletedFrupay && pound}
          {<NumberFormatComponent value={salesCompletedFrupay || 0} />}
        </Text>
      </div>
    </Card>
  );
};

export default SalesCard;
