import React, { useEffect, useState } from "react";
import TableComponent from "../../../components/TableComponent";
import { makeStyles, withStyles } from "@mui/styles";
import {
  getOrderItems,
  getSummaryAndDetailedReports,
  getSurplusSellersBuyers,
} from "../../../services/surplus-allocation";
import { OrderStateType } from "../orderStateType";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  InputLabel,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import AdminModalComponent from "../../../components/AdminModalComponent";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import ControlledTextField from "../../../components/ControlledTextField";
import ControlledComboBox from "../../../components/ControlledComboBox";
import ControlledDateField from "../../../components/ControlledDateField";
import { Slider } from "@mui/material";
import { ALLOCATION_OFFER_STATUS } from "../constants";
import FilterBadge from "../../../components/FilterBadge";
import { downloadFile } from "../../../services/common";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../../components/LoadingIndicator";
import NumberFormatComponent from "../../../components/NumberFormatComponent";
import PermissionWrapper from "../../../components/PermissionWrapper";
import { OrderStatuses } from "../../../constants";

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  statusAllocatedBox: {
    background: "#D9FFCC!important",
    color: "#699D56!important",
  },
  statusInvoicedBox: {
    background: "#DCDFFF!important",
    color: "#4252FF!important",
  },
  statusPaidBox: {
    background: "#CAF2FF!important",
    color: "#008DB9!important",
  },
  statusCancelBox: {
    background: "#CF142B!important",
    color: "white!important",
  },
  statusCompleteBox: {
    background: "#A3C585!important",
    color: "white!important",
  },
  statusSettledBox: {
    background: "#5F8CBA!important",
    color: "white!important",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  detail: {
    borderRadius: "20px!important",
    background: "#69CDAE!important",
  },
  summary: {
    borderRadius: "20px!important",
    background: "#E77228!important",
  },
  newAllocation: {
    borderRadius: "20px!important",
    background: "#5F8CBA!important",
  },
  boldText: {
    fontWeight: 600,
  },
  companyNamesText: {
    color: "#000",
    textDecoration: "none",
    "&:hover": {
      color: "#E77228",
      textDecoration: "none",
    },
  },
});

interface PropsType {
  orderStatus: string;
  sortControl: any;
  ordersLoading: boolean;
  orderQueryParams: QueryParamsType;
  setOrderQueryParams: React.Dispatch<React.SetStateAction<QueryParamsType>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  orderFilterData: any;
  orderData: OrderStateType[];
  setOrderFilterData: React.Dispatch<React.SetStateAction<any>>;
  setOrderData: React.Dispatch<React.SetStateAction<OrderStateType[]>>;
}

interface BuyersSellersState {
  name: string;
  id: string;
}

interface QueryParamsType {
  page: number;
  limit: number;
  total: number;
  totalPage: number;
}

const columns = [
  { name: "Company", id: "company" },
  { name: "FruPay", id: "frupay" },
  { name: "Notifications", id: "notifications" },
  { name: "Date", id: "date" },
  { name: "Code", id: "code" },
  { name: "Total", id: "total" },
  { name: "Status", id: "status" },
  { name: "", id: "action" },
];

const schema = yup.object().shape({
  code: yup.string().nullable(),
  // refNo: yup.string().nullable(),
  supplier: yup.string().nullable(),
  tradeStatus: yup.string(),
  // produceName: yup.string().nullable(),
  buyer: yup.array(),
  companyName: yup.string(),
  seller: yup.array(),
  status: yup.array(),
  offerFrom: yup.string().nullable(),
  offerTo: yup.string().nullable(),
  createFrom: yup.string().nullable(),
  createTo: yup.string().nullable(),
  invoiceDateFrom: yup.string().nullable(),
  invoiceDateTo: yup.string().nullable(),
  sellerEmailNotification: yup.string().nullable(),
  buyerEmailNotification: yup.string().nullable(),
  frupay: yup.string().nullable(),

  // paymentCode: yup.string().nullable(),
  // offerODRCode: yup.string().nullable(),
  // invoiceCode: yup.string().nullable(),
});

const OrderTableContainer: React.FC<PropsType> = ({
  setLoading,
  orderStatus,
  sortControl,
  setOrderData,
  orderQueryParams,
  setOrderFilterData,
  setOrderQueryParams,
  orderData,
  orderFilterData,
  ordersLoading,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [allBuyers, setAllBuyers] = useState<BuyersSellersState[]>([]);
  const [allSellers, setAllSellers] = useState<BuyersSellersState[]>([]);
  const [deletedFilterKey, setDeletedFilterKey] = useState<any>();
  const [isClearFilterKey,setIsClearFilterKey]=useState(-1)

  useEffect(() => {
    const getAllSellersBuyers = async () => {
      const response = await getSurplusSellersBuyers("offer");
      if (response) {
        setAllBuyers(
          response.buyers.map((item: any) => ({
            name: item?.companyName,
            _id: item?.id,
          }))
        );
        setAllSellers(
          response.sellers.map((item: any) => ({
            name: item?.companyName,
            _id: item.id,
          }))
        );
      }
    };
    getAllSellersBuyers();
  }, []);

  const category = ["Cat 1 ", "Cat 2"];

  const [openOrderFilterModal, setOpenOrderFilterModal] =
    useState<boolean>(false);

  useEffect(() => {
    setValue(`${deletedFilterKey}` as any, undefined);
  }, [deletedFilterKey, setValue,isClearFilterKey]);

  // useEffect(() => {
  //   if (orderStatus) {
  //     setValue("status", [orderStatus]);
  //   }
  // }, [orderStatus]);

  const onDownloadReports = async (expand: boolean) => {
    if (expand) {
      setLoading(true);
      const response = await getSummaryAndDetailedReports(
        expand,
        "offer",
        orderFilterData
      );
      setLoading(false);
      if (response) {
        downloadFile(response, "detailedReport");
      }
    } else {
      setLoading(true);
      const response = await getSummaryAndDetailedReports(
        expand,
        "offer",
        orderFilterData
      );
      setLoading(false);
      if (response) {
        downloadFile(response, "summaryReport");
      }
    }
  };

  const onFilterOrders = async (data: any) => {
    
    setLoading(true);
    setOrderQueryParams({
      ...orderQueryParams,
      page: 1,
    });
    setOrderFilterData({
      frupay:
      data?.frupay === "ALL"
        ? undefined
        : data?.frupay === "YES"
        ? true
        : data?.frupay === "NO"
        ? false
        : undefined,
      code: data?.code ? data?.code : undefined,
      // consignmentNo: data?.consignmentNo ? [data?.consignmentNo] : undefined,
      buyer: data?.buyer?.length
        ? data?.buyer.map((item: any) => item._id)
        : undefined,
      status: data?.status?.length ? data?.status : undefined,
      seller: data?.seller?.length
        ? data?.seller?.map((item: any) => item._id || item?.id)
        : undefined,
      supplier: data?.supplier ? [data?.supplier] : undefined,
      produceName: data?.produceName ? data?.produceName : undefined,
      offerTo: data?.offerTo
        ? moment(data?.offerTo).format("YYYY-MM-DD")
        : undefined,
      offerFrom: data?.offerFrom
        ? moment(data?.offerFrom).format("YYYY-MM-DD")
        : undefined,
      createTo: data?.createTo
        ? moment(data?.createTo).format("YYYY-MM-DD")
        : undefined,
      createFrom: data?.createFrom
        ? moment(data?.createFrom).format("YYYY-MM-DD")
        : undefined,
      invoiceDateFrom: data?.invoiceDateFrom
        ? moment(data?.invoiceDateFrom).format("YYYY-MM-DD")
        : undefined,
      invoiceDateTo: data?.invoiceDateTo
        ? moment(data?.invoiceDateTo).format("YYYY-MM-DD")
        : undefined,
      buyerEmailNotification:
        data?.buyerEmailNotification === "ALL"
          ? undefined
          : data?.buyerEmailNotification === "YES"
          ? true
          : data?.buyerEmailNotification === "NO"
          ? false
          : undefined,
      sellerEmailNotification:
        data?.sellerEmailNotification === "ALL"
          ? undefined
          : data?.sellerEmailNotification === "YES"
          ? true
          : data?.sellerEmailNotification === "NO"
          ? false
          : undefined,

      // offerODRCode: data?.offerODRCode ? data?.offerODRCode : undefined,
      // invoiceCode: data?.invoiceCode ? data?.invoiceCode : undefined,
      // paymentCode: data?.paymentCode ? data?.paymentCode : undefined,
    });
    setLoading(false);

    setOpenOrderFilterModal(false);
  };

  const renderTableBody = () => {
    return (
      <>
        {orderData.map((item, index) => {
          const statusChipColor = OrderStatuses.find(
            (i) => i.name === item.status
          );
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <span>
                  {" "}
                  <span className={classes.boldText}>Seller :</span>
                  {""}{" "}
                  <a
                    className={classes.companyNamesText}
                    href={`/company-list/${item.sellerCompany._id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.sellerCompany.companyName}
                  </a>
                </span>
                <br />
                <span>
                  {" "}
                  <span className={classes.boldText}>Buyer :</span>
                  {""}{" "}
                  <a
                    className={classes.companyNamesText}
                    href={`/company-list/${item.buyerCompany._id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.buyerCompany.companyName}
                  </a>
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                {item.useRefactoring ? "YES" : "NO"}
              </TableCell>
              <TableCell width={150} component="th" scope="row">
                {
                  <span className="flex flex-row items-center">
                    <span className="font-bold">Seller Email :</span>
                    {"  "}
                    <span className="ml-1">
                      {" "}
                      {item?.notifications?.seller?.email ? (
                        <img src="/static/svg/ic_checked.svg" />
                      ) : (
                        <img src="/static/svg/ic_unchecked.svg" />
                      )}
                    </span>
                  </span>
                }
                {
                  <span className="flex flex-row items-center mt-2">
                    <span className="font-bold">Buyer Email :</span>{" "}
                    <span className="ml-1">
                      {" "}
                      {item?.notifications?.buyer?.email ? (
                        <img src="/static/svg/ic_checked.svg" />
                      ) : (
                        <img src="/static/svg/ic_unchecked.svg" />
                      )}
                    </span>
                  </span>
                }
              </TableCell>
              <TableCell component="th" scope="row">
                <span>
                  <span className={classes.boldText}>Invoice Date : </span>{" "}
                  {moment(item?.invoice_data?.invoiceDate).format("DD/MM/YYYY")}{" "}
                </span>
                <br />
                <span>
                  <span>
                    <span className={classes.boldText}>
                      Offer/Delivery Date :{" "}
                    </span>{" "}
                    {moment(item.deliveryDate?.[0]?.deliveryDate).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                  <br />
                  <span className={classes.boldText}>Create Date :</span>{" "}
                  {moment(item.createdAt).format("DD/MM/YYYY")}
                </span>
              </TableCell>
              <TableCell>
                <a
                  href={`${process.env.REACT_APP_FINMID_URL}/invoices/${item.offerCode}`}
                  style={{ textDecoration: "underline" }}
                  target="_blank"
                >
                  <span className={classes.boldText}>Payment Code : </span>{" "}
                  {item.offerCode}
                </a>
                <br />
                <span>
                  <span className={classes.boldText}>Offer Code :</span>{" "}
                  {item.code}
                </span>
                <br />

                <span>
                  <span className={classes.boldText}>Invoice Code : </span>{" "}
                  {item.invoices[0]?.code}{" "}
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                <NumberFormatComponent
                  prefix={item.produces[0]?.currency?.symbol}
                  value={item.grandTotalAmount}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                {
                  <Chip
                    style={{
                      backgroundColor: statusChipColor?.color,
                      color: "white",
                      minWidth: 120,
                      fontWeight: 600,
                    }}
                    label={item?.status}
                  />
                }
              </TableCell>
              <TableCell component="th" scope="row">
                <PermissionWrapper permission="ORDERS_ORDER_LIST_ORDER_DETAILS">
                  <a
                    target="_blank"
                    href={`/surplus-allocation/${item._id}`}
                    rel="noreferrer"
                  >
                    <Tooltip title="See details of order">
                      <IconButton>
                        <i className="ri-eye-line"></i>
                      </IconButton>
                    </Tooltip>
                  </a>
                </PermissionWrapper>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderOrderFilterModalBody = () => {
    return (
      <>
        <div className="container">
          <div className="row g-3">
            {/* <div className="col-6">
              <ControlledTextField
                control={control}
                name="produceName"
                textLabel="Produce Name"
              />
            </div> */}
            <Tooltip title="You can filter by Reference Number,Consignment Number,Payment Code,Order Code,Invoice Code and Produce Name">
              <div className="col-6">
                <ControlledTextField
                  control={control}
                  name="code"
                  textLabel="Code"
                />
              </div>
            </Tooltip>
            {/* <div className="col-6">
              <ControlledTextField
                control={control}
                name="offerODRCode"
                textLabel="Order Number"
              />
            </div>
            <div className="col-6">
              <ControlledTextField
                control={control}
                name="invoiceCode"
                textLabel="Invoice Number"
              />
            </div>
            <div className="col-6">
              <ControlledTextField
                control={control}
                name="consignmentNo"
                textLabel="Consignment Number"
              />
            </div>
            <div className="col-6">
              <ControlledTextField
                control={control}
                name="refNo"
                textLabel="Reference Number"
              />
            </div> */}

            <div className="col-6">
              <ControlledComboBox
                control={control}
                multiple
                options={allBuyers}
                name="buyer"
                textLabel="Buyer"
              />
            </div>
            <div className="col-6">
              <ControlledComboBox
                multiple
                control={control}
                options={allSellers}
                name="seller"
                textLabel="Seller"
              />
            </div>
            <div className="col-6">
              <ControlledTextField
                control={control}
                name="supplier"
                textLabel="Supplier"
              />
            </div>

            <div className="col-6">
              <ControlledComboBox
                control={control}
                multiple
                options={OrderStatuses.map((i) => i.name)}
                name="status"
                textLabel="Status"
              />
            </div>
            <div className="col-3">
              <ControlledDateField
                textLabel="Create Date(From - To)"
                control={control}
                name="createFrom"
              />
            </div>
            <div className="col-3">
              <ControlledDateField
                textLabel=""
                control={control}
                name="createTo"
              />
            </div>
            <div className="col-3">
              <ControlledDateField
                textLabel="Offer Date(From - To)"
                control={control}
                name="offerFrom"
              />
            </div>
            <div className="col-3">
              <ControlledDateField
                textLabel=""
                control={control}
                name="offerTo"
              />
            </div>

            <div className="col-3">
              <ControlledDateField
                textLabel="Invoice Date(From - To)"
                control={control}
                name="invoiceDateFrom"
              />
            </div>
            <div className="col-3">
              <ControlledDateField
                textLabel=""
                control={control}
                name="invoiceDateTo"
              />
            </div>

            <div className="col-6">
              <ControlledComboBox
                control={control}
                options={["ALL", "YES", "NO"]}
                name="sellerEmailNotification"
                textLabel="Seller Email Notification"
              />
            </div>
            <div className="col-6">
              <ControlledComboBox
                control={control}
                options={["ALL", "YES", "NO"]}
                name="buyerEmailNotification"
                textLabel="Buyer Email Notification"
              />
            </div>
            <div className="col-6">
              <ControlledComboBox
                control={control}
                options={["YES", "NO"]}
                name="frupay"
                textLabel="FruPay"
              />
            </div>
          </div>
        </div>

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setOpenOrderFilterModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={handleSubmit(onFilterOrders)}
            title="Save"
          />
        </Box>
      </>
    );
  };

  const renderDownloadReports = () => {
    return (
      <div className="d-flex gap-3" style={{ marginRight: 10 }}>
        <AdminButtonComponent
          className={classes.detail}
          onClick={() => onDownloadReports(false)}
          title="Summary Report"
        />
        <AdminButtonComponent
          className={classes.summary}
          onClick={() => onDownloadReports(true)}
          title="Detailed Report"
        />
        {/* <AdminButtonComponent
          className={classes.newAllocation}
          onClick={() => navigate("/create-new-surplus")}
          title="+ New Allocation"
        /> */}
      </div>
    );
  };

  return (
    <>
      <>
        <TableComponent
          showFilterIcon={!orderStatus && true}
          sortOptions={[
            { name: "Create Date", id: "createdAt" },
            { name: "Offer/Delivery Date", id: "deliveryDate" },
            { name: "Invoice Date", id: "invoiceDate" },
            {
              name: "Buyer Company Name",
              id: "buyerCompany.companyName",
            },
            {
              name: "Seller Company Name",
              id: "sellerCompany.companyName",
            },
          ]}
          control={sortControl}
          isShowSortingNames
          isShowSortingTypes
          loading={ordersLoading}
          showRightHeaderItems={renderDownloadReports()}
          filterBadge={
            <FilterBadge
            isClearFilterKey={isClearFilterKey}
            setIsClearFilterKey={setIsClearFilterKey}
              searchParams={orderStatus}
              buyers={allBuyers}
              sellers={allSellers}
              filterData={orderFilterData}
              setFilterData={setOrderFilterData}
              setDeletedFilterKey={setDeletedFilterKey}
            />
          }
          onOpenModal={() => setOpenOrderFilterModal(true)}
          showRightButton={false}
          TableBodyComponent={renderTableBody}
          queryParams={orderQueryParams}
          setQueryParams={setOrderQueryParams}
          columns={columns}
          
        />
        <AdminModalComponent
          size="lg"
          headerTitle="Filter Order"
          children={renderOrderFilterModalBody()}
          openModal={openOrderFilterModal}
          closeModal={() => setOpenOrderFilterModal(false)}
        />
      </>
    </>
  );
};

export default OrderTableContainer;
