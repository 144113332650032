import React, { useCallback, useEffect, useState } from "react";
import TableComponent from "../../../components/TableComponent";
import { Box, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AdminModalComponent from "../../../components/AdminModalComponent";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import ControlledTextField from "../../../components/ControlledTextField";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import {
  addSize, 
  editSize,

} from "../../../services/variety-size-unit";
import PermissionWrapper from "../../../components/PermissionWrapper";

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});

const validationSchema = yup.object().shape({
  name: yup.string(),
  nameEdit: yup.string(),
});

const Size = ({
  setIsFetch,
  sizes,
  fetchLoading,
  setQueryParams,
  queryParams,
}: {
  setIsFetch: React.Dispatch<React.SetStateAction<number>>;
  sizes: { name: string; _id: string }[];
  fetchLoading: boolean;
  queryParams: any;
  setQueryParams: any;
}) => {
  const { control, setValue, watch, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      nameEdit: "",
    },
  });
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<{
    name: string;
    _id: string;
  }>();

  const nameSize = watch("name");
  const nameEditSize = watch("nameEdit");

  const onCloseAddModal = () => {
    setOpenAddModal(false);
  };
  const onCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const onClickEditItem = (item: { name: string; _id: string }) => {
    setOpenEditModal(true);
    setSelectedItem(item);
  };

  const onAddNewSize = useCallback(async () => {
    if (!nameSize) {
      toast.error("Name field is required");
    } else {
      setLoading(true);
      const added = await addSize(nameSize || "");

      setLoading(false);
      if (added) {
        reset();
        setIsFetch((prev) => prev + 1);
        setOpenAddModal(false);
      }
    }
  }, [nameSize, setIsFetch, setOpenAddModal, reset]);

  const onEditSize = useCallback(async () => {
    if (!nameEditSize) {
      toast.error("Name field is required");
    } else {
      setLoading(true);
      const edited = await editSize(
        nameEditSize || "",
        selectedItem?._id || ""
      );
      setLoading(false);

      if (edited) {
        setOpenEditModal(false);
        reset();
        setIsFetch((prev) => prev + 1);
      }
    }
  }, [nameEditSize, selectedItem?._id, reset, setIsFetch]);

  useEffect(() => {
    if (selectedItem?._id) {
      setValue("nameEdit", selectedItem.name);
    }
  }, [selectedItem?._id, selectedItem?.name, setValue]);

  const renderVarietyTableBody = () => {
    return (
      <>
        {sizes.map((s, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {s.name}
              </TableCell>
              <TableCell component="th" scope="row">
             
               <div className="d-flex justify-content-end mr-2">
               <PermissionWrapper permission="TRADE_ACTION">
                  <i
                    onClick={() => onClickEditItem(s)}
                    style={{ fontSize: 24 }}
                    className="ri-edit-line cursor-pointer"
                  ></i>
                    </PermissionWrapper>
                </div>
             
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderAddModalBody = () => {
    return (
      <>
        <ControlledTextField name="name" control={control} textLabel="Name" />

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenAddModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={loading}
            className={classes.saveButton}
            onClick={onAddNewSize}
            title="Submit"
          />
        </Box>
      </>
    );
  };

  const renderEditModalBody = () => {
    return (
      <>
        <ControlledTextField
          name="nameEdit"
          control={control}
          textLabel="Name"
        />

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenEditModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={loading}
            className={classes.saveButton}
            onClick={onEditSize}
            title="Edit"
          />
        </Box>
      </>
    );
  };

  return (
    <div>
      <TableComponent
      rightButtonPermission="TRADE_ACTION"
        setOpenModal={setOpenAddModal}
        headerButtonTitle="Add new Size"
        loading={fetchLoading}
        showRightButton={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        TableBodyComponent={renderVarietyTableBody}
        showFilterIcon={false}
        columns={[
          { name: "Name", id: "name" },
          { name: "", id: "action" },
        ]}
      />{" "}
      <AdminModalComponent
        children={renderAddModalBody()}
        openModal={openAddModal}
        closeModal={onCloseAddModal}
        headerTitle="New Size"
      />
      <AdminModalComponent
        children={renderEditModalBody()}
        openModal={openEditModal}
        closeModal={onCloseEditModal}
        headerTitle="Edit Size"
      />
    </div>
  );
};

export default Size;
