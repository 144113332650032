import React from "react";
import { ControlledTextFieldTypes } from "./interfaces/ComponentInterfaces";
import { Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { FormLabel, TextField } from "@mui/material";
import { IconButton, InputAdornment } from "@mui/material";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
const useStyles = makeStyles({
  textField: {
    background: "#F8F8F8",
    fontSize: 20,
    borderRadius: 10,
    border: "none !important",
    width: "auto",
    height: 50,
    padding: 15,
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red !important",
    },
    "& .MuiInputBase-root": {
      background: "#f8f8f8",
      minHeight: 50,
      borderColor: "black !important",
      border: "none !important",
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  InputField: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  labelText: {
    width: "100%",
    color: "black",
    fontSize: "16px !important",
  },
  labelOther: {
    width: 120,
    color: "black",
    fontSize: "16px !important",
  },
});

const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    color: "black",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: "#E77228 !important",
    },
  },
}));

const ControlledTextField: React.FC<ControlledTextFieldTypes> = ({
  control,
  onFocus,
  alignRight,
  onClick,
  isShowIcons,
  watchValue,
  name,
  maxCharacter,
  disabled,
  onBlur,
  label,
  onValueChange,
  placeholder,
  showPassword,
  textLabel,
  onKeyPress,
  minRows,
  onShowOrClosePassField,
  multiline,
  error,
  className,
  defaultValue,
  helperText,
  isPasswordTextField,
  required = false,
  type,
  onKeyDown,
}) => {
  const classes = useStyles();

  const helperTextStyle = helperTextStyles();
  return (
    <div className={classes.InputField}>
      <FormLabel
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
          flexDirection: "row",
        }}
        className={
          textLabel === "Produce Type" ||
          textLabel === "Advisory Price" ||
          textLabel === "Produce Name" ||
          textLabel === "Return Price"
            ? classes.labelOther
            : classes.labelText
        }
        component="label"
      >
        {textLabel}
        {required && <h1 className="text-red-500">*</h1>}
        {isShowIcons ? (
          watchValue ? (
            <img src="/static/svg/ic_checked.svg" />
          ) : (
            <img
              style={{ width: 17, height: 17 }}
              src="/static/svg/ic_warning.png"
            />
          )
        ) : null}
      </FormLabel>
      <Controller
        defaultValue={defaultValue || ""}
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <TextField
              onFocus={onFocus}
              inputProps={{
                maxLength: maxCharacter,
                style: { textAlign: alignRight ? "right" : "left" },
              }}
              onBlur={onBlur}
              minRows={minRows}
              value={value}
              onChange={(e) => {
                onChange(e);
                onValueChange && onValueChange();
              }}
              onClick={onClick}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              FormHelperTextProps={{ classes: helperTextStyle }}
              placeholder={placeholder}
              type={
                type
                  ? type
                  : isPasswordTextField
                  ? showPassword
                    ? "text"
                    : "password"
                  : "text"
              }
              InputLabelProps={{
                style: { color: "#000000" },
                shrink: true,
              }}
              variant="standard"
              InputProps={{
                classes: {
                  root: classes.textField,
                },
                className: classes.textField,
                disableUnderline: true,
                endAdornment: isPasswordTextField ? (
                  <>
                    <InputAdornment position="end">
                      <IconButton onClick={onShowOrClosePassField} edge="end">
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </>
                ) : null,
              }}
              className={className ? className : classes.textField}
              multiline={multiline}
              disabled={disabled}
              label={label}
              error={error}
              helperText={helperText}
            />
          );
        }}
      />
    </div>
  );
};

export default ControlledTextField;
