import React, { useCallback, useMemo, useState } from "react";
import { Checkbox, CircularProgress, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EmailPrefs, Preferences } from "./CompanyEmailPreferencesContainer";
import {
  addCompanyEmailPreferences,
  removeCompanyEmailPreferences,
} from "../../services/company";

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  spinnerStyle: {
    width: "25px!important",
    height: "25px!important",
  },
});

const PreferenceItem = ({
  index,
  setRefresh,
  i,
  setLoading,
  loading,
  setPreferences,
  preferences,
}: {
  index: number;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPreferences: React.Dispatch<React.SetStateAction<EmailPrefs[]>>;
  preferences: EmailPrefs[];
  loading: boolean;
  i: EmailPrefs;
}) => {
  const classes = useStyles();

  const [actionRemittanceLoading, setActionRemittanceLoading] = useState(false);
  const [actionStatetementLoading, setActionStatementLoading] = useState(false);

  const remittancePreference: any =
    preferences.length &&
    useMemo(() => {
      return i.preferences.find((item) => item.emailType === "REMITTANCE");
    }, [i.preferences.length, preferences.length]);

  const statementPreference: any =
    preferences.length &&
    useMemo(() => {
      return i.preferences.find((item) => item.emailType === "STATEMENT");
    }, [i.preferences.length, preferences.length]);

  const onEditRemittanceStatus = useCallback(
    async (item: EmailPrefs, index: number) => {
      if (!!remittancePreference?.emailType) {
        setActionRemittanceLoading(true);
        const removed = await removeCompanyEmailPreferences(
          remittancePreference?._id
        );
        setActionRemittanceLoading(false);
        if (removed?.success) {
          // setRefresh((prev) => prev + 1);
          let newPrefsData: any = [...preferences];

          const filteredPrefs = preferences[index].preferences?.filter(
            (p: any) => p.emailType !== "REMITTANCE"
          );

          newPrefsData[index].preferences = filteredPrefs;
          setPreferences(newPrefsData);
        }
      } else {
        setActionRemittanceLoading(true);
        const added = await addCompanyEmailPreferences({
          companyId: item?._id,
          emailSubscriptionPreferenceType: "REMITTANCE",
        });

        setActionRemittanceLoading(false);
        if (added?.success) {
          // setRefresh((prev) => prev + 1);
          let newDoc = added?.newPreferenceDoc;

          const newPrefsData: any = [...preferences];
          newPrefsData[index]?.preferences.push(newDoc);

          setPreferences(newPrefsData);
        }
      }
    },
    [remittancePreference?.emailType, remittancePreference?._id]
  );

  const onEditStatementStatus = useCallback(
    async (item: EmailPrefs, index: number) => {
      if (!!statementPreference?.emailType) {
        setActionStatementLoading(true);
        const removed = await removeCompanyEmailPreferences(
          statementPreference?._id
        );
        setActionStatementLoading(false);
        if (removed?.success) {
          // setRefresh((prev) => prev + 1);
          let newPrefsData: any = [...preferences];

          const filteredPrefs = preferences[index].preferences?.filter(
            (p: any) => p.emailType !== "STATEMENT"
          );

          newPrefsData[index].preferences = filteredPrefs;
          setPreferences(newPrefsData);
        }
      } else {
        setActionStatementLoading(true);
        const added = await addCompanyEmailPreferences({
          companyId: item?._id,
          emailSubscriptionPreferenceType: "STATEMENT",
        });

        setActionStatementLoading(false);
        if (added?.success) {
          // setRefresh((prev) => prev + 1);
          let newDoc = added?.newPreferenceDoc;

          const newPrefsData: any = [...preferences];
          newPrefsData[index]?.preferences.push(newDoc);

          setPreferences(newPrefsData);
        }
      }
    },
    [
      statementPreference?.emailType,
      statementPreference?._id,
      preferences.length,
    ]
  );
  return (
    <TableRow
      className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
      key={index}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {i.companyName}
      </TableCell>
      <TableCell component="th" scope="row">
        {actionRemittanceLoading ? (
          <CircularProgress className={classes.spinnerStyle} />
        ) : (
          <Checkbox
            onChange={() => onEditRemittanceStatus(i, index)}
            value={!!remittancePreference?.emailType}
            defaultChecked={!!remittancePreference?.emailType}
          />
        )}
      </TableCell>
      <TableCell component="th" scope="row">
        {actionStatetementLoading ? (
          <CircularProgress className={classes.spinnerStyle} />
        ) : (
          <Checkbox
            onChange={() => onEditStatementStatus(i, index)}
            value={!!statementPreference?.emailType}
            defaultChecked={!!statementPreference?.emailType}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default PreferenceItem;
