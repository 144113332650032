import React from "react";

const PersistLoading = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center flex-col bg-slate-50">
      <div className="flex flex-col items-center">
        <img
          alt="frupro logo"
          src="/static/svg/Open_Logo.svg"
          className="flex w-52 h-52 animate-pulse"
        />
        <h1 className="text-xl font-bold text-slate-400 animate-pulse text-center">Loading...</h1>
      </div>
    </div>
  );
};

export default PersistLoading;
