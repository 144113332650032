import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import {
  addCostTypes,
  deleteCostTypes,
  editCostTypes,
  getCostTypes,
} from "../../services/additional-cost-types";
import LoadingIndicator from "../../components/LoadingIndicator";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import AdminModalComponent from "../../components/AdminModalComponent";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import ControlledTextField from "../../components/ControlledTextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  description: yup.string(),
  costName: yup.string(),
  vatRate: yup.string(),
});

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  iconBox: {
    gap: 10,
    display: "flex",
    justifyContent: "flex-end",
  },
  edit: {
    fontSize: 26,
    color: "#E77228",
  },
  delete: {
    fontSize: 26,
    color: "red",
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  deleteButton: {
    width: "50%",
    height: "50px",
    background: "red !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
});

const columns = [
  { name: "Cost Name", id: "costName" },
  { name: "Description", id: "description" },
  { name: "VAT Rate", id: "vatRate" },
  { name: "Created Time", id: "createdAt" },
  { name: "", id: "action" },
];

export interface CostTypesType {
  description: string;
  deleted: boolean;
  _id: string;
  costName: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  vatRate: number;
}

const AdditionalCostsContainer = () => {
  const classes = useStyles();

  const { control, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const { hasPermission } = usePermissions();

  const [openAddCostTypeModal, setOpenAddCostTypeModal] =
    useState<boolean>(false);
  const [openEditCostTypeModal, setOpenEditCostTypeModal] =
    useState<boolean>(false);
  const [openDeleteCostTypeModal, setOpenDeleteCostTypeModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [selectedCostType, setSelectedCostType] = useState<CostTypesType>();
  const [refreshRequest, setRefreshRequest] = useState<number>(0);
  const [selectedCostTitle, setSelectedCostTitle] = useState<string>("");
  const [costTypes, setCostTypes] = useState<CostTypesType[]>([]);

  const watchCostName = watch("costName");
  const watchDescription = watch("description");
  const watchVatRate = watch("vatRate");

  useEffect(() => {
    if (selectedCostType?._id) {
      reset({
        costName: selectedCostType.costName,
        description: selectedCostType.description,
        vatRate: selectedCostType.vatRate.toString(),
      });
    } else {
      reset({
        costName: "",
        description: "",
        vatRate: "",
      });
    }
  }, [selectedCostType?._id]);

  useEffect(() => {
    if (openAddCostTypeModal) {
      reset({
        costName: "",
        description: "",
      });
      setSelectedCostType(undefined);
    }
  }, [openAddCostTypeModal]);

  useEffect(() => {
    if (!hasPermission("ADDITIONAL_COST_TYPES_VIEW_UPDATE")) return;
    const getTypes = async () => {
      setFetchLoading(true);
      const items = await getCostTypes();
      setFetchLoading(false);

      if (items) {
        setCostTypes(items);
      }
    };
    getTypes();
  }, [refreshRequest, hasPermission("ADDITIONAL_COST_TYPES_VIEW_UPDATE")]);

  const onCloseAddEditModal = () => {
    setOpenAddCostTypeModal(false);
  };
  const onCloseEditModal = () => {
    setOpenEditCostTypeModal(false);
  };
  const onOpenEditModal = (selected: CostTypesType) => {
    setOpenAddCostTypeModal(false);
    setOpenEditCostTypeModal(true);
    setSelectedCostType(selected);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteCostTypeModal(false);
  };
  const onOpenDeleteModal = (id: string) => {
    setOpenDeleteCostTypeModal(true);
    setSelectedCostTitle(id);
  };

  const validateVatRate = (value?: string): boolean => {
    if (!value) {
      toast.error("Vat Rate is required");
      return false;
    }

    if (isNaN(Number(value))) {
      toast.error("Vat Rate must be a number");
      return false;
    }

    if (Number(value) < 0 || Number(value) > 100) {
      toast.error("Vat Rate must be between 0 and 100");
      return false;
    }

    return true;
  };

  const onAddNewCost = async () => {
    setLoading(true);

    if (!validateVatRate(watchVatRate)) {
      setLoading(false);
      return;
    }

    const data = {
      costName: watchCostName,
      description: watchDescription,
      vatRate: Number(watchVatRate),
    };

    const added = await addCostTypes(data);
    setLoading(false);

    if (added) {
      setOpenAddCostTypeModal(false);
      setRefreshRequest(() => refreshRequest + 1);
      reset({
        costName: "",
        description: "",
        vatRate: "",
      });
    }
  };
  const onEditCost = async () => {
    setLoading(true);

    if (!validateVatRate(watchVatRate)) {
      setLoading(false);
      return;
    }

    const data = {
      costName: watchCostName,
      description: watchDescription,
      vatRate: Number(watchVatRate),
    };

    const edited = await editCostTypes(selectedCostType?._id!, data);
    setLoading(false);

    if (edited) {
      setSelectedCostType(undefined);
      setOpenEditCostTypeModal(false);
      setRefreshRequest(() => refreshRequest + 1);
      reset({
        costName: "",
        description: "",
      });
    }
  };

  const onDeleteCostType = async () => {
    setLoading(true);
    const deleted = await deleteCostTypes(selectedCostTitle);
    setLoading(false);
    if (deleted) {
      setSelectedCostTitle("");
      setOpenDeleteCostTypeModal(false);
      setRefreshRequest(() => refreshRequest + 1);
    }
  };

  const renderTableBody = () => {
    return (
      <>
        {costTypes.map((item, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item.costName}
              </TableCell>

              <TableCell component="th" scope="row">
                {item.description}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.vatRate}%
              </TableCell>
              <TableCell component="th" scope="row">
                {moment(item.createdAt).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell className={classes.iconBox}>
                <Tooltip title="Edit cost type">
                  <IconButton onClick={() => onOpenEditModal(item)}>
                    <i className={`ri-pencil-line   ${classes.edit}`}></i>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete cost type">
                  <IconButton onClick={() => onOpenDeleteModal(item._id)}>
                    <i className={`ri-delete-bin-line ${classes.delete}`}></i>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderModalBody = () => {
    return (
      <>
        <div className="container">
          <div className="row g-3">
            <div className="col-12">
              <ControlledTextField
                textLabel="Cost Name"
                control={control}
                name="costName"
              />
            </div>
            <div className="col-12">
              <ControlledTextField
                textLabel="Description"
                control={control}
                name="description"
              />
            </div>
            <div className="col-12">
              <ControlledTextField
                textLabel="VAT Rate"
                control={control}
                name="vatRate"
                type={"number"}
                onBlur={(e) => {
                  const value = parseFloat(e.target.value);
                  if (isNaN(value)) {
                    toast.error("VAT Rate must be a number");
                    setValue("vatRate", "");
                    return;
                  }
                  if (value < 0) {
                    toast.error("VAT Rate must be greater than 0");
                    setValue("vatRate", "");
                    return;
                  }

                  if (value > 100) {
                    toast.error("VAT Rate must be less than 100");
                    setValue("vatRate", "");
                    return;
                  }

                  e.preventDefault();
                }}
              />
            </div>
          </div>

          <div className={classes.filterModalButtons}>
            <AdminButtonComponent
              disabled={loading}
              className={classes.closeButton}
              onClick={() => {
                setOpenAddCostTypeModal(false);
                setOpenEditCostTypeModal(false);
              }}
              title="Cancel"
            />{" "}
            <AdminButtonComponent
              loading={loading}
              disabled={!watchCostName}
              className={classes.saveButton}
              onClick={selectedCostType?._id ? onEditCost : onAddNewCost}
              title="Submit"
            />
          </div>
        </div>
      </>
    );
  };

  const renderDeleteConfirmModalBody = () => {
    return (
      <>
        <div className="container">
          <span>Are you sure you want to delete this cost type?</span>
          <div className={classes.filterModalButtons}>
            <AdminButtonComponent
              disabled={loading}
              className={classes.closeButton}
              onClick={() => setOpenDeleteCostTypeModal(false)}
              title="Cancel"
            />{" "}
            <AdminButtonComponent
              loading={loading}
              className={classes.deleteButton}
              onClick={onDeleteCostType}
              title="Delete"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Additional Costs | FruPro Admin Portal</title>
      </Helmet>
      <Sidebar pageTitle="Additional Cost Types">
        {loading && <LoadingIndicator loading />}
        <PermissionWrapper
          unauthorizedComponent
          permission="ADDITIONAL_COST_TYPES_VIEW_UPDATE"
        >
          <TableComponent
            loading={fetchLoading}
            setOpenModal={setOpenAddCostTypeModal}
            showRightButton
            headerButtonTitle=" + Add new cost type"
            columns={columns}
            TableBodyComponent={renderTableBody}
          />
        </PermissionWrapper>

        <AdminModalComponent
          children={renderModalBody()}
          headerTitle="Add New Cost Type"
          openModal={openAddCostTypeModal}
          closeModal={onCloseAddEditModal}
        />

        <AdminModalComponent
          children={renderModalBody()}
          headerTitle="Edit Cost Type"
          openModal={openEditCostTypeModal}
          closeModal={onCloseEditModal}
        />
        <AdminModalComponent
          children={renderDeleteConfirmModalBody()}
          headerTitle="Are you sure"
          openModal={openDeleteCostTypeModal}
          closeModal={onCloseDeleteModal}
        />
      </Sidebar>
    </>
  );
};

export default AdditionalCostsContainer;
