import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";

const useStyles = makeStyles({
  mainText: {
    fontSize: 18,
  },
  hr: {
    opacity: 0.4,
  },
});
const Notifications = ({
  notification,
  setNotification,
}: {
  notification: {
    buyer: { email: boolean; notification: boolean };
    seller: { email: boolean; notification: boolean };
  };
  setNotification: React.Dispatch<
    React.SetStateAction<{
      buyer: { email: boolean; notification: boolean };
      seller: { email: boolean; notification: boolean };
    }>
  >;
}) => {
  const classes = useStyles();

  const onChangeNotification = useCallback(
    (key: string, e) => {
      if (key === "buyerApp") {
        setNotification((prevState) => ({
          ...prevState,
          buyer: {
            ...prevState.buyer,
            notification: e,
          },
        }));
      } else if (key === "buyerEmail") {
        setNotification((prevState) => ({
          ...prevState,
          buyer: {
            ...prevState.buyer,
            email: e,
          },
        }));
      } else if (key === "sellerApp") {
        setNotification((prevState) => ({
          ...prevState,
          seller: {
            ...prevState.seller,
            notification: e,
          },
        }));
      } else if (key === "sellerEmail") {
        setNotification((prevState) => ({
          ...prevState,
          seller: {
            ...prevState.seller,
            email: e,
          },
        }));
      }
    },
    [setNotification]
  );

  return (
    <div>
      <span className={classes.mainText}>Notifications</span>

      <Divider className={classes.hr} />

      <div className="mt-3 d-flex flex-column">
        <FormControlLabel
          style={{ display: "flex", flexDirection: "row", gap: 3 }}
          control={
            <Checkbox
              defaultChecked={notification.buyer.notification}
              value={notification.buyer.notification}
              checked={notification.buyer.notification}
              onChange={(e) => {
                onChangeNotification("buyerApp", e.target.checked);
              }}
              style={{ color: "#E77228", marginLeft: 9 }}
            />
          }
          label="Buyer App Notification"
        />
        <FormControlLabel
          style={{ display: "flex", flexDirection: "row", gap: 3 }}
          control={
            <Checkbox
              defaultChecked={notification.buyer.email}
              value={notification.buyer.email}
              checked={notification.buyer.email}
              onChange={(e) => {
                onChangeNotification("buyerEmail", e.target.checked);
              }}
              style={{ color: "#E77228", marginLeft: 9 }}
            />
          }
          label="Buyer Email Notification"
        />
        <FormControlLabel
          style={{ display: "flex", flexDirection: "row", gap: 3 }}
          control={
            <Checkbox
              defaultChecked={notification.seller.notification}
              value={notification.seller.notification}
              checked={notification.seller.notification}
              onChange={(e) => {
                onChangeNotification("sellerApp", e.target.checked);
              }}
              style={{ color: "#E77228", marginLeft: 9 }}
            />
          }
          label="Seller App Notification"
        />
        <FormControlLabel
          style={{ display: "flex", flexDirection: "row", gap: 3 }}
          control={
            <Checkbox
              defaultChecked={notification.seller.email}
              value={notification.seller.email}
              checked={notification.seller.email}
              onChange={(e) => {
                onChangeNotification("sellerEmail", e.target.checked);
              }}
              style={{ color: "#E77228", marginLeft: 9 }}
            />
          }
          label="Seller Email Notification"
        />
      </div>
    </div>
  );
};

export default Notifications;
