import React, { useEffect, useState } from "react";
import { EmailCloudFunction } from "./interface";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ControlledTextField from "../../components/ControlledTextField";
import { makeStyles } from "@mui/styles";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { useAppSelector } from "../../hooks/hooks";
import { updateRepresentetiveData } from "../../services/settings";
import PermissionWrapper from "../../components/PermissionWrapper";

const useStyles = makeStyles({
  card: {
    width: "100%",
    background: "white",
    borderRadius: "20px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  },
  PhoneInput: {
    background: "#F7F7F7",
    fontSize: 20,
    borderRadius: "10px !important",
    width: "100%",
    height: 50,
    padding: 15,
  },
});

interface SchemaType {
  email: string;
  phoneNumber: string;
  nameSurname: string;
}

const schema = yup.object().shape({
  phoneNumber: yup.string().required("Phone number is required"),
  email: yup
    .string()
    .email("Please write a valid email")
    .required("Email is required"),
  nameSurname: yup.string().required("Name and surname is required"),
});
const RepresentetiveContainer = ({
  emailData,
  managementId,
  setRefresh,
  refresh,
  setLoading,
}: {
  refresh: number;
  managementId: string;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  emailData: EmailCloudFunction;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const classes = useStyles();

  const countries = useAppSelector((state) => state.auth.countries);

  const defaultCountry = countries.find((item) =>
    emailData?.phoneNumber?.includes(item.phone)
  );

  const [selectedCountry, setSelectedCountry] = useState<string>(
    defaultCountry?.phone || ""
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SchemaType>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (emailData) {
      reset({
        nameSurname: emailData.fullName,
        email: emailData.email,
        phoneNumber: emailData?.phoneNumber.split(`+${selectedCountry}`)[1],
      });
    }
  }, [emailData]);

  const onUpdateData = async (data: any) => {
    setLoading(true);

    const finalData = {
      systemManagementId: managementId,
      email: data?.email,
      fullName: data?.nameSurname,
      phoneNumber: `+${selectedCountry}${data?.phoneNumber}`,
    };
    const updated = await updateRepresentetiveData(finalData);
    setLoading(false);

    if (updated) {
      setRefresh(() => refresh + 1);
    }
  };

  return (
  
    <PermissionWrapper unauthorizedComponent permission="NOTIFICATION_SETTINGS">
        <div className={classes.card}>
        <div className="p-4">
          <div className="row g-3">
            <div className="col-md-4">
              <ControlledTextField
                error={!!errors.nameSurname}
                helperText={errors.nameSurname?.message}
                textLabel="Name Surname"
                control={control}
                name="nameSurname"
              />
            </div>
            <div className="col-md-4">
              <ControlledTextField
                error={!!errors.email}
                helperText={errors.email?.message}
                textLabel="Email"
                control={control}
                name="email"
              />
            </div>
            <div className="col-md-4">
              <PhoneNumberInput
                className={classes.PhoneInput}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
                name="phoneNumber"
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end p-3">
          <AdminButtonComponent
            disabled={!selectedCountry}
            onClick={handleSubmit(onUpdateData)}
            title="Save"
          />{" "}
        </div>
      </div>
    </PermissionWrapper>
  
  );
};

export default RepresentetiveContainer;
