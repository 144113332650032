import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dialogIndex: {
    zIndex: "99999999999!important",
    "& .MuiPaper-root": {
      width: 500,
      borderRadius: 20,
      border: "1px solid #E77228",
    },
  },
});

interface ConfirmDialogProps {
  title: string;
  description: string;
  setResult?: (value: boolean) => void;
}

// Define the type for the ref object
export interface ConfirmDialogRef {
  openDialog: () => void;
}

const ConfirmDialog = memo(
  forwardRef<ConfirmDialogRef, ConfirmDialogProps>(
    ({ title, description, setResult }, ref) => {
      const classes = useStyles();
      const [open, setOpen] = useState(false);

      const handleCancel = useCallback(() => {
        setOpen(false);
        setResult?.(false);
      }, [setResult]);

      const handleAccept = useCallback(() => {
        setOpen(false);
        setResult?.(true);
      }, [setResult]);

      const openDialog = useCallback(() => {
        setOpen(true);
      }, []);

      useImperativeHandle(ref, () => ({
        openDialog,
      }));

      return (
        <Dialog
          className={classes.dialogIndex}
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} variant="text">
              Cancel
            </Button>
            <Button onClick={handleAccept} variant="contained" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  )
);

export default ConfirmDialog;

export const useConfirmDialog = (props: ConfirmDialogProps) => {
  const ref = React.useRef<ConfirmDialogRef>(null);
  const [resolveResult, setResolveResult] =
    useState<(value: boolean) => void>();

  const openDialog = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      setResolveResult(() => resolve); // Store the resolver function
      ref.current?.openDialog();
    });
  }, []);

  const ConfirmDialogComponent = useMemo(() => {
    return (
      <ConfirmDialog
        {...props}
        ref={ref}
        setResult={(value) => {
          resolveResult?.(value); // Resolve the promise with the value
        }}
      />
    );
  }, [props, resolveResult]);

  return {
    ConfirmDialog: ConfirmDialogComponent,
    openDialog,
  };
};
