import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { insertAdminUserInfo } from "../slices/authSlice";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL!;

const token = localStorage.getItem("accessToken");
const socket = token
  ? io(SOCKET_URL, {
      transports: ["websocket"],
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
      auth: {
        token: `Bearer ${token}`,
      },
    })
  : undefined;

export default function useConnectWebSocket() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRefresh] = useState(0);
  const dispatch = useDispatch();
  const socketId = useRef("");

  const onConnect = useCallback(
    (data) => {
      if (data.success) {
        dispatch(insertAdminUserInfo(data.user));
      }
    },
    [dispatch]
  );

  const handleSocketIdRegistration = useCallback(() => {
    if (!socket) return;
    socketId.current = socket.id ?? "";
    setRefresh((prev) => prev + 1);
  }, []);

  // can't get the socket id without listening connect event
  useEffect(() => {
    if (!socket) return;

    socket.on("connect", handleSocketIdRegistration);

    return () => {
      socket.off("connect", handleSocketIdRegistration);
    };
  }, [handleSocketIdRegistration]);

  useEffect(() => {
    if (!socket) return;

    socket.on("connection", onConnect);

    return () => {
      socket.off("connection", onConnect);
    };
  }, [onConnect]);

  return {
    socket,
    id: socketId.current,
  };
}
