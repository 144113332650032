import { useEffect } from "react";
import { SocketData } from "../types/SocketData";
import { useAppSelector } from "./hooks";
import useSync from "./useSync";

export default function useSyncedData(type: SocketData) {
  const version = useAppSelector((state) => state.wsData[type]?.version);
  const { sync } = useSync(type, version ?? 0);

  const data = useAppSelector((state) => state.wsData[type]?.data);

  useEffect(() => {
    sync();
  }, [sync]);

  return data;
}
