import React, { useCallback, useEffect, useState } from "react";
import TableComponent from "../../components/TableComponent";
import { Sidebar } from "../../components/Sidebar";
import {
  getFilterDatasUnInvoicedSales,
  getUninvoicedSales,
} from "../../services/uninvoiced-sales";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";
import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import NumberFormatComponent from "../../components/NumberFormatComponent";
import { useAppSelector } from "../../hooks/hooks";
import AdminModalComponent from "../../components/AdminModalComponent";
import ControlledComboBox from "../../components/ControlledComboBox";
import ControlledDateField from "../../components/ControlledDateField";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import FilterBadge from "../../components/FilterBadge";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Helmet } from "react-helmet";

const schema = yup.object().shape({
  sortType: yup.object(),
  sortName: yup.object().nullable(),
  Customer: yup.string().nullable(),
  SalesOrderNumber: yup.string().nullable(),
  BackToBack: yup.string().nullable(),
  Currency: yup.string().nullable(),
  LotNumber: yup.string().nullable(),
  ConsignmentNumber: yup.string().nullable(),
  SubProductType: yup.string().nullable(),
  ProductType: yup.string().nullable(),
  ProductDescription: yup.string().nullable(),
  TradingFrom: yup.string().nullable(),
  TradingTo: yup.string().nullable(),
});

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});

const columns = [
  { name: "Customer", id: "customer" },
  { name: "Codes", id: "codes" },
  { name: "Exchange Rate", id: "exchangeRate" },
  { name: "Back To Back", id: "backToBack" },
  { name: "Product", id: "product" },
  { name: "Date", id: "date" },
];

export interface NonInvoicedSales {
  _id: string;
  Customer: string;
  SalesOrderNumber: string;
  BackToBack: string;
  Currency: string;
  ExchangeRate: string;
  TradingDate: string;
  LotNumber: string;
  ConsignmentNumber: string;
  ProductType: string;
  SubProductType: string;
  ProductDescription: string;
  Product: string;
  AllocatedQty: string;
  CurrentPrice: string;
  latestUpdate: string;
  __v: number;
  createdAt: string;
  updatedAt: string;
}
export interface FilterSelectValues {
  _id: any;
  Customer: string[];
  Currency: string[];
  LotNumber: string[];
  SalesOrderNumber: string[];
  ConsignmentNumber: string[];
  ProductType: string[];
  SubProductType: string[];
  ProductDescription: string[];
}

const UninvoicedSalesTradabaseContainer = () => {
  const currencies = useAppSelector((state) => state.auth.currencies);
  const { control, watch, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      sortName: {
        name: "Trading Date",
        id: "TradingDate",
      },
      sortType: {
        name: "Descending",
        id: "-",
      },
      Customer: "",
      SalesOrderNumber: "",
      BackToBack: "",
      Currency: "",
      LotNumber: "",
      ConsignmentNumber: "",
      SubProductType: "",
      ProductType: "",
      ProductDescription: "",
      TradingFrom: "",
      TradingTo: "",
    },
  });

  const classes = useStyles();
  const { hasPermission } = usePermissions();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  const watchTradingFrom = watch("TradingFrom");
  const watchTradingTo = watch("TradingTo");
  const watchSortType = watch("sortType");
  const watchSortName = watch("sortName");
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [deletedFilterKey, setDeletedFilterKey] = useState<string>("");
  const [isFetchFilters, setIsFetchFilters] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [isClearFilterKey, setIsClearFilterKey] = useState<number>(0);
  const [salesFilterValues, setSalesFilterValues] =
    useState<FilterSelectValues>();
  const [filterData, setFilterData] = useState<any>({});
  const [sales, setSales] = useState<NonInvoicedSales[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(`${deletedFilterKey}` as any, undefined);
  }, [deletedFilterKey, isClearFilterKey]);

  useEffect(() => {
    if (hasPermission("UNINVOICED_SALES_TRADABASE")) {
      const getSalesData = async () => {
        const { TradingTo, TradingFrom, ...rest } = filterData;
        setLoading(true);
        const response = await getUninvoicedSales(
          queryParams.page,
          {
            ...rest,
            TradingDate: {
              from: (filterData as any)?.TradingFrom
                ? (filterData as any)?.TradingFrom
                : undefined,
              to: (filterData as any)?.TradingTo
                ? (filterData as any)?.TradingTo
                : undefined,
            },
          },
          {
            sortBy: (watchSortName as any)?.id,
            sort:
              (watchSortType as any)?.name === "Descending"
                ? "desc"
                : (watchSortType as any)?.name === "Ascending"
                ? "asc"
                : undefined,
          }
        );
        setLoading(false);

        if (response) {
          setSales(response?.data);
          setQueryParams({
            page: response?.pagination?.page,
            total: response?.pagination?.total,
            totalPage: response?.pagination?.totalPage,
            limit: 1,
          });
        }
      };
      getSalesData();
    }
  }, [
    hasPermission("UNINVOICED_SALES_TRADABASE"),
    filterData,
    watchSortName,
    watchSortType,
    queryParams.page,
  ]);

  useEffect(() => {
    if (isFetchFilters && !salesFilterValues?.Currency.length) {
      const getFilterDatas = async () => {
        setFetchLoading(true);
        const response = await getFilterDatasUnInvoicedSales();
        setFetchLoading(false);
        if (response) {
          setSalesFilterValues(response?.data);
        }
      };
      getFilterDatas();
    }
  }, [isFetchFilters, salesFilterValues?.Currency.length]);

  const onFilter = useCallback(
    (data: any) => {
      setIsFetchFilters(false);
      setQueryParams({
        ...queryParams,
        page: 1,
      });
      setOpenFilterModal(false);
      setFilterData({
        Customer: data?.Customer ? data?.Customer : undefined,
        SalesOrderNumber: data?.SalesOrderNumber
          ? data?.SalesOrderNumber
          : undefined,
        BackToBack: data?.BackToBack ? data?.BackToBack : undefined,
        Currency: data?.Currency ? data?.Currency : undefined,
        LotNumber: data?.LotNumber ? data?.LotNumber : undefined,
        ConsignmentNumber: data?.ConsignmentNumber
          ? data?.ConsignmentNumber
          : undefined,
        SubProductType: data?.SubProductType ? data?.SubProductType : undefined,
        ProductType: data?.ProductType ? data?.ProductType : undefined,
        ProductDescription: data?.ProductDescription
          ? data?.ProductDescription
          : undefined,
        TradingFrom: data?.TradingFrom ? data?.TradingFrom : undefined,
        TradingTo: data?.TradingTo ? data?.TradingTo : undefined,
      });
    },
    [queryParams]
  );

  const renderTableBody = () => {
    return (
      <>

        {sales.map((item, index) => {
          const itemCurrency = currencies.find(
            (curr) => curr.code === item.Currency
          );
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item.Customer}
              </TableCell>
              <TableCell component="th" scope="row">
                <span>
                  <span className="fw-bold">Order No : </span>{" "}
                  {item.SalesOrderNumber}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Consignment No :</span>{" "}
                  {item.ConsignmentNumber}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Lot No : </span> {item.LotNumber}
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                {item.ExchangeRate}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.BackToBack}
              </TableCell>
              <TableCell component="th" scope="row">
                <span>
                  <span className="fw-bold"> Name : </span> {item.Product}
                </span>
                <br />
                <span>
                  <span className="fw-bold"> Product Type : </span>{" "}
                  {item.ProductType}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Sub Product Type : </span>{" "}
                  {item.SubProductType}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Description : </span>{" "}
                  {item.ProductDescription}
                </span>
                <br />
                <span>
                  <span className="fw-bold">QTY : </span> {item.AllocatedQty}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Price : </span>{" "}
                  {itemCurrency?.symbol}
                  <NumberFormatComponent value={item.CurrentPrice} />
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                <span>
                  <span className="fw-bold"> Trading Date : </span>{" "}
                  {moment(item.TradingDate).format("DD/MM/YYYY")}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Create Date : </span>{" "}
                  {moment(item.createdAt).format("DD/MM/YYYY")}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Latest Update : </span>{" "}
                  {moment(item.latestUpdate).format("DD/MM/YYYY")}
                </span>
                <br />
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderModalBody = () => {
    return (
      <>
        <div className="row g-3">
          <div className="col-6">
            <ControlledComboBox
              textLabel="Customer"
              options={salesFilterValues?.Customer || []}
              control={control}
              name="Customer"
            />
          </div>
          <div className="col-6">
            <ControlledComboBox
              textLabel="Sales Order Number"
              options={salesFilterValues?.SalesOrderNumber || []}
              control={control}
              name="SalesOrderNumber"
            />
          </div>
          <div className="col-6">
            <ControlledComboBox
              textLabel="Back To Back"
              options={["YES", "NO"]}
              control={control}
              name="BackToBack"
            />
          </div>
          <div className="col-6">
            <ControlledComboBox
              textLabel="Currency"
              options={salesFilterValues?.Currency || []}
              control={control}
              name="Currency"
            />
          </div>
          <div className="col-3">
            <ControlledDateField
              textLabel="Trading Date(From - To)"
              control={control}
              name="TradingFrom"
            />
          </div>
          <div className="col-3">
            <ControlledDateField
              textLabel=""
              control={control}
              name="TradingTo"
            />
          </div>{" "}
          <div className="col-6">
            <ControlledComboBox
              textLabel="Lot Number"
              options={salesFilterValues?.LotNumber || []}
              control={control}
              name="LotNumber"
            />
          </div>
          <div className="col-6">
            <ControlledComboBox
              textLabel="Consignment Number"
              options={salesFilterValues?.ConsignmentNumber || []}
              control={control}
              name="ConsignmentNumber"
            />
          </div>
          <div className="col-6">
            <ControlledComboBox
              textLabel="Product Type"
              options={salesFilterValues?.ProductType || []}
              control={control}
              name="ProductType"
            />
          </div>
          <div className="col-6">
            <ControlledComboBox
              textLabel="Sub Product Type"
              options={salesFilterValues?.SubProductType || []}
              control={control}
              name="SubProductType"
            />
          </div>
          <div className="col-6">
            <ControlledComboBox
              textLabel="Product Description"
              options={salesFilterValues?.ProductDescription || []}
              control={control}
              name="ProductDescription"
            />
          </div>
          <div className={classes.newUserModalButtons}>
            <AdminButtonComponent
              className={classes.closeButton}
              onClick={() => setOpenFilterModal(false)}
              title="Cancel"
            />{" "}
            <AdminButtonComponent
              className={classes.saveButton}
              onClick={handleSubmit(onFilter)}
              title="Save"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <Sidebar pageTitle="Uninvoiced Sales Tradabase">
       <Helmet>
          <title>UnInvoiced Sales | FruPro Admin Portal</title>
        </Helmet>
      <PermissionWrapper
        unauthorizedComponent
        permission="UNINVOICED_SALES_TRADABASE"
      >
        {fetchLoading && <LoadingIndicator loading={fetchLoading} />}
        <TableComponent
          filterBadge={
            <FilterBadge
              filterData={filterData}
              setFilterData={setFilterData}
              setDeletedFilterKey={setDeletedFilterKey}
              isClearFilterKey={isClearFilterKey}
              setIsClearFilterKey={setIsClearFilterKey}
            />
          }
          onOpenModal={() => {
            setOpenFilterModal(true);
            setIsFetchFilters(true);
          }}
          sortOptions={[
            { name: "Lot Number", id: "LotNumber" },
            { name: "Customer", id: "Customer" },
            { name: "Sales Order Number", id: "SalesOrderNumber" },
            {
              name: "Back To Back",
              id: "BackToBack",
            },
            {
              name: "Currency",
              id: "Currency",
            },
            {
              name: "Trading Date",
              id: "TradingDate",
            },
            {
              name: "Consignment Number",
              id: "ConsignmentNumber",
            },
            {
              name: "Product Type",
              id: "ProductType",
            },
            {
              name: "Sub Product Type",
              id: "SubProductType",
            },
            {
              name: "Product Description",
              id: "ProductDescription",
            },
          ]}
          control={control}
          showFilterIcon
          isShowSortingNames
          isShowSortingTypes
          loading={loading}
          TableBodyComponent={renderTableBody}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          showRightButton={false}
          columns={columns}
        />
      </PermissionWrapper>
      <AdminModalComponent
        size="lg"
        headerTitle="Filter Sales"
        children={renderModalBody()}
        openModal={openFilterModal}
        closeModal={() => {
          setOpenFilterModal(false);
          setIsFetchFilters(false);
        }}
      />
    </Sidebar>
  );
};

export default UninvoicedSalesTradabaseContainer;
