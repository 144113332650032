import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { NumericFormat } from "react-number-format";
import { RequestsStateType } from "./interface";
import { Button, Divider, Grid } from "@mui/material";
import moment from "moment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AdminModalComponent from "../../components/AdminModalComponent";
import ControlledTextField from "../../components/ControlledTextField";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import {
  approveRejectBuyer,
  approveRejectLimit,
  approveRejectSeller,
} from "../../services/verification-requests";

const useStyles = makeStyles({
  goBackText: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  requestHeaderText: {
    fontSize: 18,
    fontWeight: 600,
    color: "#E77228",
  },
  detailContainer: {
    borderRadius: 20,
    width: "100%",
    padding: 20,
    marginTop: 10,
    background: "white",
  },
  limitBox: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 10,
    padding: 15,
    marginTop: 15,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.10)",
  },

  PendingText: {
    color: "#E0A600",
  },
  ApprovedText: {
    color: "#69CDAE",
  },
  ApprovedButton: {
    width: "auto",
    textTransform: "capitalize",
    borderRadius: "10px!important",
    height: 40,
    background: "#69CDAE!important",
    color: "white!important",
    "&:hover": {
      borderRadius: "10px!important",
      background: "#69CDAE!important",
      color: "white!important",
    },
  },
  RejectedText: {
    color: "#ED0000",
  },
  RejectedButton: {
    width: "auto",
    textTransform: "capitalize",
    borderRadius: "10px!important",
    height: 40,
    background: "#ED0000!important",
    color: "white!important",
    "&:hover": {
      borderRadius: "10px!important",
      background: "#ED0000!important",
      color: "white!important",
    },
  },
  dividerStyle: {
    opacity: 0.2,
    marginTop: 40,
  },
  boldText: {
    fontWeight: 600,
    fontSize: 18,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
});

const schema = yup.object().shape({
  reason: yup.string(),
  creditTotalLimit: yup.string(),
});

const VerificationRequestDetail = ({
  setRenderedContainer,
  refreshRequest,
  setRefreshRequest,
  selectedDetail,
  loading,
  setLoading,
}: {
  selectedDetail: RequestsStateType | undefined;
  loading: boolean;
  refreshRequest: number;
  setRefreshRequest: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRenderedContainer: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    control,
    watch,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const classes = useStyles();

  const reverseHistory =
    selectedDetail &&
    [...selectedDetail?.verifiedBuyer.creditLimit.history].reverse();
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [selectedHistoryId, setSelectedHistoryId] = useState<string>("");
  const [openApproveRejectModal, setOpenApproveRejectModal] =
    useState<boolean>(false);
  const [modalRenderType, setModalRenderType] =
    useState<string>("approveSeller");

  const watchReason = watch("reason");
  const watchCreditLimit = watch("creditTotalLimit");

  useEffect(() => {
    if (!openApproveRejectModal) {
      reset({
        reason: "",
        creditTotalLimit: "",
      });
    }
  }, [openApproveRejectModal]);

  const onGoBack = () => {
    setRenderedContainer("table");
  };

  const onOpenModal = (type: string, companyId: string, historyId: string) => {
    setSelectedCompanyId(companyId);
    setSelectedHistoryId(historyId);
    setOpenApproveRejectModal(true);
    setModalRenderType(type);
  };

  const onCloseModal = () => {
    setOpenApproveRejectModal(false);
    setSelectedHistoryId("");
    setSelectedCompanyId("");
  };

  const onApproveReject = async () => {
    if (modalRenderType === "approveSeller") {
      setLoading(true);
      const approveSellerData = {
        historyRequestId: selectedHistoryId,
        requestResult: "APPROVED",
        companyId: selectedCompanyId,
        reason: watchReason,
      };
      const approvedSeller = await approveRejectSeller(approveSellerData);
      setLoading(false);

      if (approvedSeller) {
        reset({
          reason: "",
          creditTotalLimit: "",
        });
        setRenderedContainer("table")
        setRefreshRequest(() => refreshRequest + 1);
        setOpenApproveRejectModal(false);
      }
    } else if (modalRenderType === "rejectSeller") {
      setLoading(true);
      const rejectSellerData = {
        historyRequestId: selectedHistoryId,
        requestResult: "REJECTED",
        companyId: selectedCompanyId,
        reason: watchReason,
      };
      const rejectedSeller = await approveRejectSeller(rejectSellerData);
      setLoading(false);

      if (rejectedSeller) {
        reset({
          reason: "",
          creditTotalLimit: "",
        });
        setRenderedContainer("table")
        setRefreshRequest(() => refreshRequest + 1);
        setOpenApproveRejectModal(false);
      }
    } else if (modalRenderType === "approveBuyer") {
      setLoading(true);
      const approveBuyerData = {
        historyRequestId: selectedHistoryId,
        requestResult: "APPROVED",
        companyId: selectedCompanyId,
        creditTotalLimit: watchCreditLimit,
        reason: watchReason,
      };
      const approvedBuyer = await approveRejectBuyer(approveBuyerData);
      setLoading(false);

      if (approvedBuyer) {
        reset({
          reason: "",
          creditTotalLimit: "",
        });
        setRenderedContainer("table")
        setRefreshRequest(() => refreshRequest + 1);
        setOpenApproveRejectModal(false);
      }
    } else if (modalRenderType === "rejectBuyer") {
      setLoading(true);
      const rejectBuyerData = {
        creditTotalLimit:1,
        historyRequestId: selectedHistoryId,
        requestResult: "REJECTED",
        companyId: selectedCompanyId,
        reason: watchReason,
      };
      const rejectedBuyer = await approveRejectBuyer(rejectBuyerData);
      setLoading(false);

      if (rejectedBuyer) {
        reset({
          reason: "",
          creditTotalLimit: "",
        });
        setRenderedContainer("table")
        setRefreshRequest(() => refreshRequest + 1);
        setOpenApproveRejectModal(false);
      }
    } else if (modalRenderType === "approveLimit") {
      setLoading(true);
      const approveLimitData = {
        historyRequestId: selectedHistoryId,
        requestResult: "APPROVED",
        companyId: selectedCompanyId,
        creditTotalLimit: watchCreditLimit,
        reason: watchReason,
      };
      const approvedLimit = await approveRejectLimit(approveLimitData);
      setLoading(false);

      if (approvedLimit) {
        reset({
          reason: "",
          creditTotalLimit: "",
        });
        setRenderedContainer("table")
        setRefreshRequest(() => refreshRequest + 1);
        setOpenApproveRejectModal(false);
      }
    } else if (modalRenderType === "rejectLimit") {
      setLoading(true);
      const rejectLimitData = {
        creditTotalLimit:1,
        historyRequestId: selectedHistoryId,
        requestResult: "REJECTED",
        companyId: selectedCompanyId,
        reason: watchReason,
      };
      const rejectedLimit = await approveRejectLimit(rejectLimitData);
      setLoading(false);

      if (rejectedLimit) {
        reset({
          reason: "",
          creditTotalLimit: "",
        });
        setRenderedContainer("table")
        setRefreshRequest(() => refreshRequest + 1);
        setOpenApproveRejectModal(false);
      }
    }
  };

  const renderHeaderTitle = () => {
    switch (modalRenderType) {
      case "approveSeller":
        return "Approve Seller request";
      case "approveBuyer":
        return "Approve Buyer request";
      case "approveLimit":
        return "Approve limit increase request";
      case "rejectSeller":
        return "Reject Seller request";
      case "rejectBuyer":
        return "Reject buyer request";
      case "rejectLimit":
        return "Reject limit increase request";
    }
  };

  const renderModalBody = () => {
    return (
      <>
        <div className="container ">
          <div className="row g-3">
            {modalRenderType === "approveBuyer" ||
            modalRenderType === "approveLimit" ? (
              <div className="col-12">
                <ControlledTextField
                  textLabel="Total Credit Limit"
                  control={control}
                  name="totalCreditLimit"
                />
              </div>
            ) : null}

            <div className="col-12">
              <ControlledTextField
                textLabel="Reason"
                control={control}
                name="reason"
              />
            </div>
          </div>
          <div className={classes.newUserModalButtons}>
            <AdminButtonComponent
              disabled={loading}
              className={classes.closeButton}
              onClick={() => setOpenApproveRejectModal(false)}
              title="Cancel"
            />{" "}
            <AdminButtonComponent
              loading={loading}
              disabled={
                modalRenderType === "approveBuyer" ||
                modalRenderType === "approveLimit"
                  ? !watchCreditLimit
                  : false
              }
              className={classes.saveButton}
              onClick={onApproveReject}
              title="Save"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className={classes.detailContainer}>
        {" "}
        <div>
          <i className="ri-arrow-left-line"></i>
          <span className={classes.goBackText} onClick={onGoBack}>
            Go back
          </span>
        </div>
        <div className={classes.limitBox}>
          <span className={classes.requestHeaderText}>
            Total Limit :{" "}
            {
              <NumericFormat
                fixedDecimalScale={true}
                displayType="text"
                decimalSeparator=","
                decimalScale={2}
                thousandSeparator="."
                prefix="£"
                value={selectedDetail?.verifiedBuyer?.creditLimit?.total}
              />
            }{" "}
          </span>
          <span className={classes.requestHeaderText}>
            Remaining Limit :{" "}
            {
              <NumericFormat
                fixedDecimalScale={true}
                displayType="text"
                decimalSeparator=","
                decimalScale={2}
                thousandSeparator="."
                prefix="£"
                value={selectedDetail?.verifiedBuyer?.creditLimit?.remaining}
              />
            }{" "}
          </span>
          <span className={classes.requestHeaderText}>
            Spent Amount :{" "}
            {
              <NumericFormat
                fixedDecimalScale={true}
                displayType="text"
                decimalSeparator=","
                decimalScale={2}
                thousandSeparator="."
                prefix="£"
                value={selectedDetail?.verifiedBuyer?.creditLimit?.spent}
              />
            }{" "}
          </span>
        </div>
        <hr className={classes.dividerStyle} />
        <Grid container>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
          >
            <Grid
              paddingLeft="15px"
              display="flex"
              width="100%"
              gap="55px"
              flexDirection="column"
            >
              <Grid display="flex" gap="140px" flexDirection="row">
                <span className={classes.boldText}>Date</span>
                <span className={classes.boldText}>Description</span>
              </Grid>
              {reverseHistory?.map((item) => {
                return (
                  <Grid
                    display="flex"
                    gap="50px"
                    alignItems="center"
                    flexDirection="row"
                  >
                    <Grid>
                      <span>
                        {moment(item?.dateChanged).format("DD/MM/YYYY")}
                      </span>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid
                      display="flex"
                      width="100%"
                      alignItems="center"
                      gap="20px"
                      justifyContent="space-between"
                      flexDirection="row"
                    >
                      <Grid display="flex" gap="5px" flexDirection="column">
                        <span
                          className={
                            item.status === "PENDING"
                              ? classes.PendingText
                              : item.status === "APPROVED"
                              ? classes.ApprovedText
                              : item.status === "REJECTED"
                              ? classes.RejectedText
                              : ""
                          }
                        >
                          {item.status}
                        </span>
                        <span>{item.description}</span>
                        {item.time ? (
                          <span>Purchase Time : {item.time}</span>
                        ) : null}
                        {item.reason ? (
                          <span>Reason : {item.reason}</span>
                        ) : null}
                      </Grid>

                      {item.status === "PENDING" ? (
                        <Grid>
                          {item.historyType === "VerifyAsASeller" ? (
                            <Grid
                              display="flex"
                              justifyContent="flex-end"
                              gap="10px"
                            >
                              <Button
                                onClick={() =>
                                  onOpenModal(
                                    "approveSeller",
                                    selectedDetail?._id
                                      ? selectedDetail?._id
                                      : "",
                                    item._id
                                  )
                                }
                                className={classes.ApprovedButton}
                                variant="contained"
                              >
                                Approve as seller
                              </Button>
                              <Button
                                onClick={() =>
                                  onOpenModal(
                                    "rejectSeller",
                                    selectedDetail?._id
                                      ? selectedDetail?._id
                                      : "",
                                    item._id
                                  )
                                }
                                className={classes.RejectedButton}
                                variant="contained"
                              >
                                Reject as seller
                              </Button>
                            </Grid>
                          ) : item.historyType === "VerifyAsABuyer" ? (
                            <Grid
                              display="flex"
                              justifyContent="flex-end"
                              gap="10px"
                            >
                              <Button
                                onClick={() =>
                                  onOpenModal(
                                    "approveBuyer",
                                    selectedDetail?._id
                                      ? selectedDetail?._id
                                      : "",
                                    item._id
                                  )
                                }
                                className={classes.ApprovedButton}
                                variant="contained"
                              >
                                Approve as buyer
                              </Button>
                              <Button
                                onClick={() =>
                                  onOpenModal(
                                    "rejectBuyer",
                                    selectedDetail?._id
                                      ? selectedDetail?._id
                                      : "",
                                    item._id
                                  )
                                }
                                className={classes.RejectedButton}
                                variant="contained"
                              >
                                Reject as buyer
                              </Button>
                            </Grid>
                          ) : item.historyType ===
                            "IncreaseBuyerCreditLimit" ? (
                            <Grid display="flex" gap="10px">
                              <Button
                                onClick={() =>
                                  onOpenModal(
                                    "approveLimit",
                                    selectedDetail?._id
                                      ? selectedDetail?._id
                                      : "",
                                    item._id
                                  )
                                }
                                className={classes.ApprovedButton}
                                variant="contained"
                              >
                                Approve limit increase
                              </Button>
                              <Button
                                onClick={() =>
                                  onOpenModal(
                                    "rejectLimit",
                                    selectedDetail?._id
                                      ? selectedDetail?._id
                                      : "",
                                    item._id
                                  )
                                }
                                className={classes.RejectedButton}
                                variant="contained"
                              >
                                Reject limit increase
                              </Button>
                            </Grid>
                          ) : null}
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <AdminModalComponent
        children={renderModalBody()}
        openModal={openApproveRejectModal}
        closeModal={onCloseModal}
        headerTitle={renderHeaderTitle()}
      />
      
    </div>
  );
};

export default VerificationRequestDetail;
