import React, { useEffect, useState } from "react";
import TableComponent from "../../components/TableComponent";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "../../services/settings";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Switch, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PermissionWrapper from "../../components/PermissionWrapper";

const columns = [
  { name: "Name", id: "name" },
  { name: "Status", id: "status" },
];

const useStyles = makeStyles({
  switch: {
    "&.MuiSwitch-root .MuiSwitch-switchBase": {
      color: "white",
    },

    "&.MuiSwitch-root .Mui-checked": {
      color: "#E77228",
    },
    "&.MuiSwitch-root  .MuiSwitch-track": {
      background: "#E77228 !important",
    },
  },
});

interface QueryParams {
  page: number;
  limit: number;
  totalPage: number;
  total: number;
}

const NotificationSettings = ({
  settings,
  loading,
  setLoading,
  queryParams,
  setQueryParams,
}: {
  settings: any;
  queryParams: QueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<QueryParams>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const classes = useStyles();

  const onChangeStatus = async (e: any, key: any) => {
    const newSettings = Object.entries(settings[0]).filter(
      ([key]) => key !== "id" && key !== "_id"
    );

    const updatedData: any = {};

    for (const key in newSettings) {
      const functionName = newSettings[key][0];
      const functionConfig = newSettings[key][1];
      updatedData[functionName] = functionConfig;
    }

    Object.assign(updatedData[key], {
      serverOn: e,
    });

    const finalUpdatedData = {
      emailCloudFunction: {
        serverOn: updatedData?.emailCloudFunction?.serverOn,
      },
      notificationCloudFunction: {
        serverOn: updatedData?.notificationCloudFunction?.serverOn,
      },

      automatedSalePost: {
        serverOn: updatedData?.automatedSalePost?.serverOn,
      },

      automatedCompanyPost: {
        serverOn: updatedData?.automatedCompanyPost?.serverOn,
      },
    };
    const finalData = {
      id: (settings?.[0] as any)?.id,
      updatedData: finalUpdatedData,
    };
    setLoading(true);
    await updateNotificationSettings(finalData);
    setLoading(false);
  };

  const renderTableBody = () => {
    return (
      <>
        {settings?.length &&
          Object.entries(settings[0])?.map(([key, value], index) => {
            return key !== "_id" && key !== "id" ? (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {key.split("Cloud")[0].toUpperCase()}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Switch
                    className={classes.switch}
                    onChange={(e: any) => onChangeStatus(e.target.checked, key)}
                    checked={Boolean((value as any)?.serverOn)}
                  />
                </TableCell>
              </TableRow>
            ) : null;
          })}
      </>
    );
  };

  return (
    <>
      {loading && <LoadingIndicator loading />}
      <PermissionWrapper
        permission="NOTIFICATION_SETTINGS"
        unauthorizedComponent={true}
      >
        <TableComponent
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          TableBodyComponent={renderTableBody}
          columns={columns}
          showRightButton={false}
        />
      </PermissionWrapper>
    </>
  );
};

export default NotificationSettings;
