import { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { getCurrencies, updateCurrencyStatus } from "../../services/currency";
import TableComponent from "../../components/TableComponent";
import { TableRow, TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Switch } from "@mui/material";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Helmet } from "react-helmet";
const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },

  switch: {
    "&.MuiSwitch-root .MuiSwitch-switchBase": {
      color: "white",
    },

    "&.MuiSwitch-root .Mui-checked": {
      color: "#E77228",
    },
    "&.MuiSwitch-root  .MuiSwitch-track": {
      background: "#E77228 !important",
    },
  },
});

interface currenciesStateType {
  name: string;
  symbol: string;
  createdAt: string;
  id: string;
  status: number;
  updatedAt: string;
  code: string;
}

interface ParamsType {
  page: number;
  total: number;
  totalPage: number;
  limit: number;
}

const columns = [
  { id: "currencyName", name: "Currency Name" },
  { id: "symbol", name: "Symbol" },
  { id: "status", name: "Status" },
];
const CurrenciesContainer = () => {
  const classes = useStyles();
  const [queryParams, setQueryParams] = useState<ParamsType>({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });
  const [currencies, setCurrencies] = useState<currenciesStateType[]>([]);
  const [refreshToken, setRefreshToken] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getAllCurrencies = async () => {
      setLoading(true);
      setTimeout(async () => {
        const response = await getCurrencies();
        setLoading(false);
        if (response) {
          setCurrencies(response);
        }
      }, 1000);
    };
    getAllCurrencies();
  }, [refreshToken]);

  const onSwitchChange = async (
    currencyId: string,
    data: currenciesStateType
  ) => {
    const response = await updateCurrencyStatus(currencyId, data);
    setRefreshToken(() => refreshToken + 1);
  };

  const renderCurrenciesTableBody = () => {
    return (
      <>
        {currencies.map((currency, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {currency.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {currency.symbol}
              </TableCell>
              <TableCell>
                <Switch
                  sx={{
                    className: classes.switch,
                  }}
                  className={classes.switch}
                  onChange={() => onSwitchChange(currency.id, currency)}
                  checked={currency.status === 1}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };
  return (
    <>
    <Helmet>
      <title>Currencies | FruPro Admin Portal</title>
    </Helmet>
    <Sidebar pageTitle="Currencies">
      {loading && <LoadingIndicator loading />}
      <TableComponent
        TableBodyComponent={renderCurrenciesTableBody}
        showRightButton={false}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        columns={columns}
        />
    </Sidebar>
        </>
  );
};

export default CurrenciesContainer;
