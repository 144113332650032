import { Checkbox, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TradabaseData } from "./interface";
import NumberFormatComponent from "../../components/NumberFormatComponent";
import { getTradabaseOrdersDetail } from "../../services/tradabase-orders";
import { capturePartialPayment } from "../../services/surplus-allocation";
import AdminModalComponent from "../../components/AdminModalComponent";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { convertDateObjectToString } from "../../services/common";

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  seeDetail: {
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      color: "#e77228",
    },
  },

  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  deleteButton: {
    width: "50%",
    height: "50px",
    background: "red !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  linkText: {
    color: "#E77228",
    textDecoration: "underline",
    "&:hover": {
      color: "#E77228",
      opacity: 0.7,
    },
  },
});
const TradabaseTableItem = ({
  orders,
  selectedOrders,
  isFetch,
  setIsFetch,
  setDetailLoading,
  setSelectedOrder,
  setOrders,
  setShowDetail,
  order,
  index,
}: {
  selectedOrders: TradabaseData[];
  setDetailLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isFetch: number;
  setIsFetch: React.Dispatch<React.SetStateAction<number>>;
  setSelectedOrder?: React.Dispatch<
    React.SetStateAction<TradabaseData | undefined>
  >;
  setShowDetail?: React.Dispatch<React.SetStateAction<boolean>>;
  orders: TradabaseData[];
  setOrders: any;
  order: TradabaseData;
  index: number;
}) => {
  const classes = useStyles();

  const [openCaptureItemConfirmModal, setOpenCaptureItemConfirmModal] =
    useState(false);
  const [captureLoading, setCaptureLoading] = useState(false);

  const isDefaultPartialCheckedMemoized = useMemo(() => {
    return order?.offerPayment
      .map((i) => i.status)
      .some(
        (i) => i === "CAPTURED" || i === "BUYER_PAID" || i === "SELLER_PAID"
      );
  }, [order.offerPayment.length]);

  const checkedForIsInSelectedOrders = useMemo(() => {
    return selectedOrders.length
      ? selectedOrders.map((ord) => ord._id).includes(order._id)
      : false;
  }, [selectedOrders.length, order._id]);
  const [isDefaultPartialChecked, setIsDefaultPartialChecked] =
    useState<boolean>(isDefaultPartialCheckedMemoized);

  useEffect(() => {
    if (isFetch) {
      setSelectedOrder && setSelectedOrder(orders[index]);
    }
  }, [isFetch, orders.length, index]);

  const onOpenConfirmCapture = () => {
    setOpenCaptureItemConfirmModal(true);
  };

  const onSeeDetail = async (order: TradabaseData) => {
    setShowDetail && setShowDetail(true);
    setDetailLoading(true);
    const response = await getTradabaseOrdersDetail(order._id);
    setDetailLoading(false);
    if (response?.success) {
      setSelectedOrder && setSelectedOrder(response?.data[0]);
    }
  };

  const onCapturePayment = useCallback(async () => {
    // const finalData = {
    //   invoiceId: order.invoice[0]?._id,
    //   type: "ISSUED",
    //   refs: order.offer?.[0]?.refs,
    //   tradabase_webhook_dataId: order._id,
    // };
    setCaptureLoading(true);
    const response = await capturePartialPayment(order.offerPayment[0]?._id);

    setCaptureLoading(false);
    if (response?.success) {
      setOpenCaptureItemConfirmModal(false);
      setIsDefaultPartialChecked(true);
    }
  }, [order, orders.length, index, order.offerPayment.length, index]);

  const renderConfirmModalBody = () => {
    return (
      <>
        Are you sure you want to capture?
        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            disabled={captureLoading}
            className={classes.closeButton}
            onClick={() => setOpenCaptureItemConfirmModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={captureLoading}
            className={classes.saveButton}
            onClick={onCapturePayment}
            title="Submit"
          />
        </div>
      </>
    );
  };

  return (
    <>
      <TableRow
        className={classes.colorRow}
        key={index}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          style={{ maxWidth: 140, verticalAlign: "top" }}
          component="th"
          scope="row"
        >
          <div className="d-flex  flex-column gap-3">
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Buyer : </span>
              <span> {order.webhookData.buyerCompany.companyName}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Seller : </span>
              <span> {order.webhookData.sellerCompany.companyName}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Owner : </span>
              <span> {order.webhookData.owners[0].fullname}</span>
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Delivery Date : </span>
              <span>
                {convertDateObjectToString(order.webhookData.deliveryDate)}
              </span>
            </div>

            {/* <div className="d-flex align-items-center justify-content-between">
            <span className="fw-bold">Reference : </span>
            <span>{order.webhookData.tradabaseReference}</span>
          </div> */}
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Due Date : </span>
              <span>
                {convertDateObjectToString(order.webhookData.dueDate)}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Total Amount : </span>
              <span>
                {order.webhookData.produces[0].currency.symbol}
                {
                  <NumberFormatComponent
                    value={order.webhookData.totalAmount}
                  />
                }
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">FRUPAY : </span>
              <span>
                {order.webhookData.userRefactoring === "true" ? "YES" : "NO"}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Tradabase Ref. : </span>
              <span> {order.webhookData.tradabaseReference}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Webhook Data Id : </span>
              <span> {order._id}</span>
            </div>

            <div
              style={{ height: 20 }}
              className="d-flex align-items-center justify-content-between"
            >
              <span></span>
            </div>

            <div
              style={{ height: 20 }}
              className="d-flex align-items-center justify-content-between"
            >
              <span></span>
            </div>
            <div
              style={{ height: 18 }}
              className="d-flex align-items-center justify-content-between"
            >
              <span></span>
            </div>
          </div>
        </TableCell>
        <TableCell style={{ maxWidth: 140 }} component="th" scope="row">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Buyer : </span>
              <span> {order.offer[0]?.buyerCompany?.companyName}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Seller : </span>
              <span> {order.offer[0]?.sellerCompany?.companyName}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Create Date : </span>
              <span>
                {convertDateObjectToString(order.offer?.[0]?.createdAt)}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Delivery Date : </span>
              <span>
                {convertDateObjectToString(order.invoice?.[0]?.deliveryDate)}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Invoice Date : </span>
              <span>
                {convertDateObjectToString(order.invoice?.[0]?.invoiceDate)}
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Due Date : </span>
              <span>
                {convertDateObjectToString(order.invoice?.[0]?.dueDate)}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Total Amount : </span>
              <span>
                {order.webhookData.produces[0].currency.symbol}
                {
                  <NumberFormatComponent
                    value={order.offer?.[0]?.totalAmount}
                  />
                }
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Offer Code : </span>
              <a
                className={classes.linkText}
                target="_blank"
                rel="noreferrer"
                href={`/surplus-allocation/${order.offer?.[0]?._id}`}
              >
                 {order.invoice[0]?.offerCode}
              </a>
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Invoice Code : </span>
              <span> {order.invoice[0]?.code}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Payment Code: </span>
              <a
                className={classes.linkText}
                target="_blank"
                rel="noreferrer"
                href={`/surplus-allocation/${order.offer?.[0]?._id}`}
              >
                 {order.invoice[0]?.paymentCode}
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">FRUPAY : </span>
              <span>{order.offer?.[0]?.useRefactoring ? "YES" : "NO"}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">Partial payment : </span>
              <span>{order.offerPayment?.length > 1 ? "YES" : "NO"}</span>
            </div>
            {order.offerPayment[0]?.amount ? (
              <div className="d-flex align-items-center justify-content-between">
                <span className="fw-bold">First Partial Payment Amount : </span>
                <span>
                  {" "}
                  {order.webhookData.produces[0]?.currency?.symbol}
                  {
                    <NumberFormatComponent
                      value={order.offerPayment[0]?.amount}
                    />
                  }
                </span>
              </div>
            ) : null}
            {order.offerPayment[1]?.amount ? (
              <div className="d-flex align-items-center justify-content-between">
                <span className="fw-bold">
                  Second Partial Payment Amount :{" "}
                </span>
                <span>
                  <span>
                    {" "}
                    {order.webhookData.produces[0]?.currency?.symbol}
                    {
                      <NumberFormatComponent
                        value={order.offerPayment[1]?.amount}
                      />
                    }
                  </span>
                </span>
              </div>
            ) : null}
            {/* <div className="d-flex align-items-center justify-content-between">
            <span className="fw-bold">Payment Request : </span>
            <span>
              {order.webhookData.produces?.[0]?.currency?.symbol}
              {<NumberFormatComponent value={totalPaymentRequestAmount} />}
            </span>
          </div> */}
          </div>
        </TableCell>
        <TableCell style={{ maxWidth: 150 }} component="th" scope="row">
          {
            <>
              <div className="d-flex  justify-content-end">
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold">
                    {" "}
                    {isDefaultPartialChecked ? "Captured : " : "Capture : "}
                  </span>{" "}
                  {isDefaultPartialChecked ? (
                    <img
                      width={25}
                      height={25}
                      src="/static/svg/Check_round_fill_green_thick.svg"
                    />
                  ) : (
                    <Checkbox
                      onClick={onOpenConfirmCapture}
                      disabled={
                        !order.offer[0]?.useRefactoring ||
                        !!order.errorMessage ||
                        !!selectedOrders.length ||
                        isDefaultPartialChecked ||
                        !order.offerPayment[0]?._id
                      }
                      value={
                        isDefaultPartialChecked || checkedForIsInSelectedOrders
                      }
                      defaultChecked={isDefaultPartialChecked}
                      checked={
                        isDefaultPartialChecked || checkedForIsInSelectedOrders
                      }
                    />
                  )}
                </div>
              </div>
            </>
          }
          <hr style={{ opacity: 0.07 }} className="mt-2 pb-2" />
          <div className="d-flex mr-2  justify-content-end">
            <span
              onClick={() => {
                window.open(`/tradabase-orders/${order._id}`);
              }}
              className={classes.seeDetail}
            >
              See details
            </span>
            <br />
          </div>
          {order.errorMessage ? (
            <div
              style={{ color: "#800000" }}
              className="mt-2 fw-bold mb-2 d-flex justify-content-end"
            >
              Error : {order.errorMessage}
            </div>
          ) : null}
        </TableCell>

        <AdminModalComponent
          children={renderConfirmModalBody()}
          openModal={openCaptureItemConfirmModal}
          closeModal={() => setOpenCaptureItemConfirmModal(false)}
          headerTitle="Are you sure"
        />
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={true}>
            {order.errorMessage ? (
              <div className="mt-2 mb-2">
                <span className="fw-bold">Error Message :</span>{" "}
                {order.errorMessage}
              </div>
            ) : null}
          </Collapse>
        </TableCell>
      </TableRow> */}
    </>
  );
};

export default TradabaseTableItem;
