import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getDashboards = async (path?: string) => {
  try {
    const response = await axiosApiInstance.get(
      `/admin-dashboard?path=admin-dashboard`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.message);
    }
  }
};
