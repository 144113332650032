import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { CompanyDetailsStateType } from "./interfaces";
import AdminModalComponent from "../../../components/AdminModalComponent";
import {
  addCompanyAddress,
  editCompanyAddress,
  getCompanyAddress,
} from "../../../services/company";
import Skeleton from "react-loading-skeleton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import ControlledTextField from "../../../components/ControlledTextField";
import ControlledComboBox from "../../../components/ControlledComboBox";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import { CountriesStateType } from "../../../slices/authSlice";
import PermissionWrapper from "../../../components/PermissionWrapper";
import SkeletonLoading from "../../../components/SkeletonLoading";
import { Checkbox, FormControlLabel } from "@mui/material";

const addAddressSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  address: yup.string().required("Address is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  postCode: yup.string().required("Post Code is required"),
  country: yup.object().required("Country is required"),
  isDefault: yup.boolean(),
});

export interface AddressesState {
  country: Country;
  state: State;
  default: boolean;
  city: City;
  deleted: boolean;
  _id: string;
  userId: string;
  companyId: string;
  title: string;
  postCode: string;
  address: string;
  createdAt: string;
  updatedAt: string;
  id: string;
}

export interface Country {
  code: string;
  name: string;
}

export interface State {
  code: any;
  name: string;
}

export interface City {
  code: string;
  name: string;
}

const useStyles = makeStyles({
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  ModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  headerDocsText: {
    fontWeight: 600,
    fontSize: 20,
  },
  uploadDocButton: {
    borderRadius: "10px!important",
  },
  addressItem: {
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px!important",
    backgroundColor: "#f8f8f8!important",
    borderRadius: "5px!important",
    background: "white!important",
    width: "100%",
    minHeight: 300,
  },

  hr: {
    opacity: 0.05,
    marginTop: 5,
  },
  trash: {
    marginRight: 15,
    fontSize: 22,
    cursor: "pointer",
    color: "red",
  },
  edit: {
    marginRight: 15,
    fontSize: 22,
    cursor: "pointer",
    color: "#E0A600",
  },
  urlText: {
    fontWeight: 600,
    color: "#e77228",
  },
  uploadDoc: {
    marginRight: "20px!important",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
    color: "#e77228",
    border: "1px solid",
    borderRadius: 10,
    padding: 5,
    fontWeight: 600,
  },
  addressCardHeader: {
    fontSize: 18,
    fontWeight: 600,
    color: "#5F8CBA",
  },
});

const Addresses = ({
  countries,
  refresh,
  loading,
  setLoading,
  setRefresh,
  companyData,
}: {
  loading: boolean;
  countries: CountriesStateType[];
  refresh: number;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  companyData: CompanyDetailsStateType | undefined;
}) => {
  const {
    reset: addReset,
    control: addControl,
    watch: addWatch,
    setValue: setAddValue,
    handleSubmit: addHandleSubmit,
    formState: { errors: addErrors },
    register: addRegister,
  } = useForm({
    resolver: yupResolver(addAddressSchema),
    defaultValues: {
      state: "",
      city: "",
      country: {},
      address: "",
      title: "",
      postCode: "",
    },
  });

  const {
    control: editControl,
    watch: editWatch,
    handleSubmit: editHandleSubmit,
    setValue: setEditValue,
    formState: { errors: editErrors },
    register: editRegister,
  } = useForm({
    resolver: yupResolver(addAddressSchema),
    defaultValues: {
      state: "",
      city: "",
      country: {},
      isDefault: false,
      address: "",
      title: "",
      postCode: "",
    },
  });
  const classes = useStyles();

  const [isFetch, setIsFetch] = useState<number>(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressesState>();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [addresses, setAddresses] = useState<AddressesState[]>([]);

  useEffect(() => {
    if (selectedAddress?._id) {
      setEditValue("title", selectedAddress.title);
      setEditValue("address", selectedAddress.address);
      setEditValue("city", selectedAddress.city.name);
      setEditValue("state", selectedAddress.state.name);
      setEditValue("postCode", selectedAddress.postCode);
      setEditValue("isDefault", selectedAddress.default);
      setEditValue("country", {
        name: selectedAddress.country.name,
        id: selectedAddress.country.code,
      });
    }
  }, [selectedAddress?._id]);

  useEffect(() => {
    if (companyData?.id) {
      const getAddressesData = async () => {
        setAddressLoading(true);
        const response = await getCompanyAddress(companyData?.id || "");
        setAddressLoading(false);
        if (response?.length) {
          setAddresses(response);
        }
      };
      getAddressesData();
    }
  }, [companyData?.id, isFetch]);

  const onOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const onCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const onOpenEditModal = (address: AddressesState) => {
    setSelectedAddress(address);
    setOpenEditModal(true);
  };

  const onCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const onAddAddress = useCallback(async (data: any) => {
    const finalData = {
      default:data?.isDefault,
      userId: companyData?.user,
      companyId: companyData?.id,
      title: data?.title,
      address: data?.address,
      state: {
        name: data?.state,
      },
      city: {
        name: data?.city,
      },
      postCode: data?.postCode,
      country: {
        code: data?.country?.id?.toString(),
        name: data?.country?.name,
      },
    };
    setAddressLoading(true);
    const response = await addCompanyAddress(finalData);
    setAddressLoading(false);
    if (response) {
      addReset();
      setIsFetch((prev: number) => prev + 1);
      setOpenAddModal(false);
    }
  }, [companyData?.id,companyData?.user,addReset]);

  const onEditAddress = useCallback(
    async (data: any) => {
      const finalData = {
        default:data?.isDefault,
        updateExists: false,
        addressId: selectedAddress?._id,
        userId: companyData?.user,
        companyId: companyData?.id,
        title: data?.title,
        address: data?.address,
        state: {
          name: data?.state,
        },
        city: {
          name: data?.city,
        },
        postCode: data?.postCode,
        country: {
          code: data?.country?.id?.toString(),
          name: data?.country?.name,
        },
      };

      setAddressLoading(true);
      const response = await editCompanyAddress(finalData);
      setAddressLoading(false);

      if (response?.success) {
        setSelectedAddress(undefined);
        setOpenEditModal(false);
        setIsFetch((prev: number) => prev + 1);
      }
    },
    [selectedAddress?._id]
  );

  const renderAddModalBody = () => {
    return (
      <div className="row g-3">
        <ControlledTextField
          error={!!addErrors.title}
          helperText={addErrors.title?.message}
          textLabel="Title"
          control={addControl}
          name="title"
        />

        <ControlledTextField
          error={!!addErrors.address}
          helperText={addErrors.address?.message}
          textLabel="Address"
          control={addControl}
          name="address"
        />
        <div className="col-6">
          <ControlledComboBox
            error={!!addErrors.country}
            helperText={addErrors.country?.message}
            className={classes.comboBox}
            textLabel="Country"
            options={countries.map((c) => ({
              name: c.countryName,
              id: c.geonameId,
            }))}
            control={addControl}
            name="country"
          />
        </div>

        <div className="col-6">
          <ControlledTextField
            error={!!addErrors.state}
            helperText={addErrors.state?.message}
            textLabel="State"
            control={addControl}
            name="state"
          />
        </div>

        <div className="col-6">
          <ControlledTextField
            error={!!addErrors.city}
            helperText={addErrors?.city?.message}
            textLabel="City"
            control={addControl}
            name="city"
          />
        </div>
        <div className="col-6">
          <ControlledTextField
            error={!!addErrors.postCode}
            helperText={addErrors.postCode?.message}
            textLabel="Post Code"
            control={addControl}
            name="postCode"
          />
        </div>
        <div className="col-6">
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setAddValue("isDefault", e.target.checked)}
                checked={addWatch("isDefault")}
                value={addWatch("isDefault")}
              />
            }
            label="Mark as default address"
          />
        </div>
        <div className={classes.ModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseAddModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            disabled={addressLoading}
            loading={addressLoading}
            className={classes.saveButton}
            onClick={addHandleSubmit(onAddAddress)}
            title="Submit"
          />
        </div>
      </div>
    );
  };

  const renderEditModalBody = () => {
    return (
      <div className="row g-3">
        <ControlledTextField
          error={!!editErrors.title}
          helperText={editErrors.title?.message}
          textLabel="Title"
          control={editControl}
          name="title"
        />

        <ControlledTextField
          error={!!editErrors.address}
          helperText={editErrors.address?.message}
          textLabel="Address"
          control={editControl}
          name="address"
        />
        <div className="col-6">
          <ControlledComboBox
            error={!!editErrors.country}
            helperText={editErrors.country?.message}
            className={classes.comboBox}
            textLabel="Country"
            options={countries.map((c) => ({
              name: c.countryName,
              id: c.geonameId,
            }))}
            control={editControl}
            name="country"
          />
        </div>

        <div className="col-6">
          <ControlledTextField
            error={!!editErrors.state}
            helperText={editErrors.state?.message}
            textLabel="State"
            control={editControl}
            name="state"
          />
        </div>

        <div className="col-6">
          <ControlledTextField
            error={!!editErrors.city}
            helperText={editErrors?.city?.message}
            textLabel="City"
            control={editControl}
            name="city"
          />
        </div>
        <div className="col-6">
          <ControlledTextField
            error={!!editErrors.postCode}
            helperText={editErrors.postCode?.message}
            textLabel="Post Code"
            control={editControl}
            name="postCode"
          />
        </div>
        <div className="col-6">
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setEditValue("isDefault", e.target.checked)}
                checked={editWatch("isDefault")}
                value={editWatch("isDefault")}
              />
            }
            label="Mark as default address"
          />
        </div>
        <div className={classes.ModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseEditModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            disabled={addressLoading}
            loading={addressLoading}
            className={classes.saveButton}
            onClick={editHandleSubmit(onEditAddress)}
            title="Submit"
          />
        </div>
      </div>
    );
  };
  return (
    <div className="mt-2 ">
      <div className="row">
        <div className="d-flex justify-content-between align-items-center">
          <span className={classes.headerDocsText}>Addresses</span>

          <PermissionWrapper permission="COMPANY_UPDATE">
            <span onClick={onOpenAddModal} className={classes.uploadDoc}>
              {" "}
              + Add New Address
            </span>
          </PermissionWrapper>
        </div>
        <hr className={`mt-3 ${classes.hr}`} />
        {addressLoading ? (
          <SkeletonLoading />
        ) : (
          <div className="mt-3 row g-3">
            {addresses.map((address, index) => {
              return (
                <div key={index} className="col-md-4">
                  <div className={`${classes.addressItem}  p-2`}>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className={classes.addressCardHeader}>
                        {address.title}
                      </span>
                      <PermissionWrapper permission="COMPANY_UPDATE">
                        <i
                          onClick={() => onOpenEditModal(address)}
                          className={`ri-edit-line ${classes.edit}`}
                        ></i>
                      </PermissionWrapper>
                    </div>
                    <hr className={classes.hr} />

                    <div className="d-flex flex-column gap-3 mt-2">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <img src="/static/svg/Pin_light.svg" />
                        <span>
                          <span className="fw-bold">Address : </span>
                          {address.address}
                        </span>
                      </div>
                      <div className="d-flex flex-row align-items-center gap-2">
                        {" "}
                        <img src="/static/svg/Compass_light.svg" />
                        <span>
                          <span className="fw-bold">Country : </span>
                          {address.country.name}
                        </span>
                      </div>
                      <div className="d-flex flex-row align-items-center gap-2">
                        {" "}
                        <img src="/static/svg/Folder_line_light.svg" />
                        <span>
                          <span className="fw-bold">State : </span>
                          {address.state.name}
                        </span>
                      </div>
                      <div className="d-flex flex-row align-items-center gap-2">
                        <img src="/static/svg/Pin_alt_light2.svg" />{" "}
                        <span>
                          <span className="fw-bold">City : </span>
                          {address.city.name}
                        </span>
                      </div>

                      <div className="d-flex flex-row align-items-center gap-2">
                        {" "}
                        <img src="/static/svg/info_gray.svg" />
                        <span>
                          <span className="fw-bold">Post Code : </span>
                          {address.postCode}
                        </span>
                      </div>
                      <div className="d-flex justify-end">
                        {" "}
                        <span className="font-bold">
                          {address.default ? "Default Address" : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <AdminModalComponent
        size="lg"
        headerTitle="Add new address"
        openModal={openAddModal}
        closeModal={onCloseAddModal}
        children={renderAddModalBody()}
      />
      <AdminModalComponent
        size="lg"
        headerTitle="Edit address"
        openModal={openEditModal}
        closeModal={onCloseEditModal}
        children={renderEditModalBody()}
      />
    </div>
  );
};

export default Addresses;
