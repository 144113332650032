import React, { useState, useCallback, useEffect } from "react";
import ReactCrop, {
  Crop,
  centerCrop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";

interface CropModalProps {
  isOpen: boolean;
  imageSrc: string;
  onClose: () => void;
  onCropComplete: (croppedImage: File) => void;
}

const CropModal: React.FC<CropModalProps> = ({
  isOpen,
  imageSrc,
  onClose,
  onCropComplete,
}) => {
  const [crop, setCrop] = useState<Crop | undefined>(undefined);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
  const [croppedImage, setCroppedImage] = useState<File | null>(null);

  const MIN_WIDTH = 400;
  const MIN_HEIGHT = 400;

  const resetState = useCallback(() => {
    setCrop(undefined);
    setCompletedCrop(null);
    setImageRef(null);
    setCroppedImage(null);
  }, []);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [onClose, resetState]);

  const onLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const img = e.currentTarget as HTMLImageElement;
      setImageRef(img);

      if (img.naturalWidth < MIN_WIDTH || img.naturalHeight < MIN_HEIGHT) {
        toast.error(`Image must be at least ${MIN_WIDTH}x${MIN_HEIGHT} pixels`);
        handleClose();
        return;
      }

      const { width, height } = img;
      const initialCrop = centerCrop(
        makeAspectCrop(
          {
            unit: "%",
            width: 90,
          },
          1,
          width,
          height
        ),
        width,
        height
      );
      setCrop(initialCrop);
    },
    [handleClose]
  );

  useEffect(() => {
    if (!completedCrop || !imageRef) {
      return;
    }

    const createCroppedImage = async () => {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.naturalWidth / imageRef.width;
      const scaleY = imageRef.naturalHeight / imageRef.height;
      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        return;
      }

      ctx.drawImage(
        imageRef,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width,
        completedCrop.height
      );

      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        const file = new File([blob], "croppedImage.jpeg", {
          type: "image/jpeg",
        });
        setCroppedImage(file);
      }, "image/jpeg");
    };

    createCroppedImage();
  }, [completedCrop, imageRef]);

  const handleSave = () => {
    if (croppedImage) {
      onCropComplete(croppedImage);
    }
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Crop Image</DialogTitle>
      <DialogContent>
        <Box>
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            onComplete={(newCrop) => setCompletedCrop(newCrop)}
            aspect={1}
          >
            <img
              src={imageSrc}
              onLoad={onLoad}
              alt="Crop target"
              style={{ maxHeight: "500px", width: "auto" }}
            />
          </ReactCrop>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={!completedCrop || !croppedImage}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CropModal;
