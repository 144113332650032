import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import { getCompanyEmailPreferences } from "../../services/company";
import { makeStyles } from "@mui/styles";
import usePermissions from "../../hooks/usePermissions";
import PreferenceItem from "./PreferenceItem";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledComboBox from "../../components/ControlledComboBox";
import { getBuyerSeller } from "../../services/common";
import LoadingIndicator from "../../components/LoadingIndicator";
import PermissionWrapper from "../../components/PermissionWrapper";
import { Helmet } from "react-helmet";

const schema = yup.object().shape({
  company: yup.object(),
});

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
});

export interface EmailPrefs {
  _id: string;
  companyName: string;
  preferences: Preferences[];
}

export interface Preferences {
  _id: string;
  emailType: string;
  createdAt: string;
}

const columns = [
  { name: "Company Name", id: "compant" },
  { name: "Remittance", id: "action" },
  { name: "Statement", id: "action2" },
];

const CompanyEmailPreferencesContainer = () => {
  const { control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const watchCompany = watch("company");
  const classes = useStyles();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  const [preferences, setPreferences] = useState<EmailPrefs[]>([]);
  const [loading, setLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [isFetchCompanies, setIsFetchCompanies] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [refresh, setRefresh] = useState<number>(0);

  const { hasPermission } = usePermissions();

  useEffect(() => {
    if (isFetchCompanies && !companies.length) {
      const getCompanies = async () => {
        setCompanyLoading(true);
        const response = await getBuyerSeller();
        setCompanyLoading(false);

        if (response) {
          setCompanies(
            response?.items?.map((i: any) => ({
              name: i?.companyName,
              id: i?.id || i?._id,
            }))
          );
        }
      };
      getCompanies();

      return () => {
        setCompanyLoading(false);
      };
    }
  }, [isFetchCompanies, companies.length]);
  useEffect(() => {
    const getEmailPreferences = async () => {
      if (!hasPermission("COMPANY_UPDATE")) return;

      setLoading(true);
      const response = await getCompanyEmailPreferences(
        (watchCompany as any)?.id?1: queryParams.page,
        (watchCompany as any)?.id
      );
      setLoading(false);

      if (response?.data) {
        setPreferences(response.data);
        setQueryParams({
          page: response?.pagination?.page,
          total: response?.pagination?.total,
          totalPage: response?.pagination?.totalPage,
          limit: 1,
        });
      }
    };
    getEmailPreferences();
  }, [
    !(watchCompany as any)?.id&& queryParams.page,
    hasPermission("COMPANY_UPDATE"),
    refresh,
    (watchCompany as any)?.id,
  ]);
 

  const renderTableBody = () => {
    return (
      <>
        {preferences.map((i, index) => {
          return (
            <PreferenceItem
              setRefresh={setRefresh}
              setPreferences={setPreferences}
              setLoading={setLoading}
              loading={loading}
              preferences={preferences}
              key={index}
              index={index}
              i={i}
            />
          );
        })}
      </>
    );
  };

  const renderFilterItem = () => {
    return (
      <div className="d-flex flex-row gap-2 align-items-center">
        <span>Company Name : </span>
        <ControlledComboBox
          onFocus={() => setIsFetchCompanies(true)}
          
          textLabel=""
          control={control}
          options={companies}
          name="company"
        />
      </div>
    );
  };
  return (
    <Sidebar pageTitle="Email Preferences">
      {companyLoading && <LoadingIndicator loading={companyLoading} />}
      <Helmet>
          <title>Email Preferences | FruPro Admin Portal</title>
        </Helmet>
    <PermissionWrapper unauthorizedComponent permission="COMPANY_UPDATE">
    <TableComponent
        loading={loading}
        renderOneFilterItem={renderFilterItem()}
        TableBodyComponent={renderTableBody}
        RenderSearchTextField={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        showRightButton={false}
        columns={columns}
      />
    </PermissionWrapper>
    </Sidebar>
  );
};

export default CompanyEmailPreferencesContainer;
