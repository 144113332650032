import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import AdminModalComponent from "../../components/AdminModalComponent";
import { makeStyles } from "@mui/styles";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import ControlledComboBox from "../../components/ControlledComboBox";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getBuyerSeller, getYearsBetween } from "../../services/common";
import LoadingIndicator from "../../components/LoadingIndicator";
import { toast } from "react-toastify";
import {
  createServiceFeeInvoice,
  deleteServiceFeeInvoice,
  getServiceFeeInvoices,
} from "../../services/service-fee-invoices";
import { useAppSelector } from "../../hooks/hooks";
import { FeeInvoicesState } from "./interface";
import { TableCell, TableRow } from "@mui/material";
import moment from "moment";
import FilterBadge from "../../components/FilterBadge";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  companyName: yup.object(),
  month: yup.object(),
  year: yup.string(),
  companyNameFilter: yup.object(),
  monthFilter: yup.object(),
  yearFilter: yup.string(),
  currency: yup.object(),
});

const useStyles = makeStyles({
  seeInvoice: {
    color: "#E77228",
    "&:hover": {
      color: "#E77228",
    },
  },
  trash: {
    cursor: "pointer",
    fontSize: 20,
    color: "red",
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  deleteButton: {
    width: "50%",
    height: "50px",
    background: "red !important",
    borderRadius: "20px !important",
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
});

interface QueryParams {
  page: number;
  total: number;
  totalPage: number;
  limit: number;
}
interface Companies {
  name: string;
  id: string;
}

const columns = [
  { name: "Company Name", id: "companyName" },
  { name: "File", id: "invoice" },
  { name: "Month/Year", id: "monthYear" },
  { name: "Create Time", id: "createdAt" },
  { name: "Currency", id: "currency" },
  { name: "", id: "action" },
];

const months = [
  { name: "January", id: 1 },
  { name: "February", id: 2 },
  { name: "March", id: 3 },
  { name: "April", id: 4 },
  { name: "May", id: 5 },
  { name: "June", id: 6 },
  { name: "July", id: 7 },
  { name: "August", id: 8 },
  { name: "September", id: 9 },
  { name: "October", id: 10 },
  { name: "November", id: 11 },
  { name: "December", id: 12 },
];

const ServiceFeeInvoicesContainer = () => {
  const {
    control,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const classes = useStyles();
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<Companies[]>([]);
  const [refresh, setRefresh] = useState<number>(0);
  const [filterData, setFilterData] = useState({});
  const [deletedFilterKey, setDeletedFilterKey] = useState<string>("");
  const [feeInvoices, setFeeInvoices] = useState<FeeInvoicesState[]>([]);
  const [openNewServiceFeeInvoiceModal, setOpenNewServiceFeeInvoiceModal] =
    useState<boolean>(false);
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [selectedServiceFeeInvoiceId, setSelectedServiceFeeInvoiceId] =
    useState<string>("");

  const [
    openDeleteServiceFeeInvoiceModal,
    setOpenDeleteServiceFeeInvoiceModal,
  ] = useState<boolean>(false);

  const currency = useAppSelector((state) => state.auth.currencies);
  const currentYear: number = new Date().getFullYear();
  const watchCompanyName = watch("companyName");
  const watchYear = watch("year");
  const watchMonth = watch("month");
  const watchCurrency = watch("currency");
  const watchCompanyNameFilter = watch("companyNameFilter");
  const watchYearFilter = watch("yearFilter");
  const watchMonthFilter = watch("monthFilter");

  const onCloseNewServiceFeeInvoiceModal = () => {
    setOpenNewServiceFeeInvoiceModal(false);
    setSelectedServiceFeeInvoiceId("");
  };

  const onOpenDeleteServiceFeeInvoiceModal = (id: string) => {
    setOpenDeleteServiceFeeInvoiceModal(true);
    setSelectedServiceFeeInvoiceId(id);
  };
  const onCloseDeleteServiceFeeInvoiceModal = () => {
    setOpenDeleteServiceFeeInvoiceModal(false);
  };
  const onCloseFilterModal = () => {
    setOpenFilterModal(false);
  };
  const onOpenFilterModal = () => {
    setOpenFilterModal(true);
  };

  useEffect(() => {
    setValue(
      `${
        deletedFilterKey === "companyName"
          ? "companyNameFilter"
          : deletedFilterKey === "invoiceDateMonth"
          ? "monthFilter"
          : deletedFilterKey === "invoiceDateYear"
          ? "yearFilter"
          : ""
      }` as any,
      undefined
    );
  }, [deletedFilterKey]);

  useEffect(() => {
    if (!hasPermission("SERVICE_FEE_INVOICES_LISTING_VIEW")) return;

    if (openNewServiceFeeInvoiceModal || openFilterModal) {
      const getCompaniesData = async () => {
        setLoading(true);

        const response = await getBuyerSeller();

        if (response) {
          setCompanies(
            response.items?.map((item: any) => ({
              name: item?.companyName,
              id: item?._id,
            }))
          );
        }
        setLoading(false);
      };
      getCompaniesData();
    }
  }, [
    openNewServiceFeeInvoiceModal,
    openFilterModal,
    hasPermission("SERVICE_FEE_INVOICES_LISTING_VIEW"),
  ]);

  useEffect(() => {
    if (!hasPermission("SERVICE_FEE_INVOICES_LISTING_VIEW")) return;

    const getFeeData = async () => {
      setFetchLoading(true);
      const response = await getServiceFeeInvoices({
        page: queryParams.page,
        limit: 10,
        ...filterData,
      });
      setFetchLoading(false);
      if (response) {
        setFeeInvoices(response?.data);
        setQueryParams({
          page: response?.paginate?.page,
          limit: 1,
          total: response?.paginate?.total,
          totalPage: response?.paginate?.totalPage,
        });
      }
    };
    getFeeData();
  }, [
    refresh,
    queryParams.page,
    filterData,
    hasPermission("SERVICE_FEE_INVOICES_LISTING_VIEW"),
  ]);

  const onAddNewInvoice = async () => {
    if (!hasPermission("SERVICE_FEE_INVOICES_LISTING_VIEW")) return;

    if (!(watchCompanyName as any)?.id) {
      toast.error("Company name is required");
    } else if (!(watchMonth as any)?.id) {
      toast.error("Month is required");
    } else if (!watchYear) {
      toast.error("Year is required");
    } else if (!(watchCurrency as any)?.id) {
      toast.error("Currency is required");
    } else {
      setLoading(true);

      const finalCurrency = currency.find(
        (item) => item.code === (watch("currency") as any)?.name
      );
      const data = {
        invoiceDateYear: watchYear?.toString(),
        currencySymbol: finalCurrency?.symbol,
        invoiceDateMonth: (watchMonth as any)?.id,
        companyId: (watchCompanyName as any)?.id,
      };
      const response = await createServiceFeeInvoice(data);
      setLoading(false);

      if (response) {
        reset({
          currency: undefined,
          companyName: undefined,
          month: undefined,
          year: "",
        });
        setOpenNewServiceFeeInvoiceModal(false);
        setRefresh((prev: number) => prev + 1);
      }
    }
  };

  const onDeleteServiceFeeInvoice = async () => {
    setLoading(true);

    const response = await deleteServiceFeeInvoice(selectedServiceFeeInvoiceId);
    setLoading(false);
    if (response) {
      setOpenDeleteServiceFeeInvoiceModal(false);
      setSelectedServiceFeeInvoiceId("");
      setRefresh((prev: number) => prev + 1);
    }
  };

  const onFilter = () => {
    setQueryParams({
      ...queryParams,
      page: 1,
    });
    setOpenFilterModal(false);
    setFilterData({
      companyName: (watchCompanyNameFilter as any)?.id
        ? (watchCompanyNameFilter as any)?.name
        : undefined,
      invoiceDateYear: watchYearFilter
        ? watchYearFilter?.toString()
        : undefined,
      invoiceDateMonth: (watchMonthFilter as any)?.id
        ? (watchMonthFilter as any)?.id
        : undefined,
    });
  };

  const renderTableBody = () => {
    return (
      <>
        {feeInvoices.map((fee, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {fee.company.companyName}
              </TableCell>

              <TableCell component="th" scope="row">
                <PermissionWrapper permission="SERVICE_FEE_INVOICES_VIEW">
                  <a
                    className={classes.seeInvoice}
                    href={fee.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {fee.fileName}
                  </a>
                </PermissionWrapper>
              </TableCell>
              <TableCell component="th" scope="row">
                {fee.data.invoiceDateMonth && fee.data.invoiceDateYear
                  ? `${fee.data.invoiceDateMonth}/${fee.data.invoiceDateYear}`
                  : fee.data.invoiceDateYear
                  ? fee.data.invoiceDateYear
                  : "-"}
              </TableCell>
              <TableCell component="th" scope="row">
                {moment(fee.createdAt).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell component="th" scope="row">
                {fee.data.currencySymbol}
              </TableCell>
              <TableCell component="th" scope="row">
                <PermissionWrapper permission="SERVICE_FEE_INVOICES_DELETE">
                  <i
                    onClick={() => onOpenDeleteServiceFeeInvoiceModal(fee._id)}
                    className={`ri-delete-bin-line ${classes.trash} col-md-1`}
                  ></i>
                </PermissionWrapper>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };
  const renderAddNewServiceFeeInvoiceModalBody = () => {
    return (
      <>
        <div className="row g-2">
          <ControlledComboBox
            className={classes.comboBox}
            textLabel="Company Name"
            options={companies}
            control={control}
            name="companyName"
          />
          <ControlledComboBox
            notSortOptions={true}
            className={classes.comboBox}
            textLabel="Month"
            newOptions={months}
            options={months}
            control={control}
            name="month"
          />
          <ControlledComboBox
            className={classes.comboBox}
            textLabel="Year"
            options={getYearsBetween(2023, currentYear + 1)}
            control={control}
            name="year"
          />
          <ControlledComboBox
            className={classes.comboBox}
            textLabel="Currency"
            options={currency
              .map((item) => ({
                name: item.code,
                id: item.id,
              }))
              .filter((item) => item.name !== "CAD")}
            control={control}
            name="currency"
          />
        </div>
        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseNewServiceFeeInvoiceModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={onAddNewInvoice}
            title="Submit"
          />
        </div>
      </>
    );
  };
  const renderDeleteServiceFeeInvoiceModalBody = () => {
    return (
      <>
        <span>
          Are you sure you want to delete this service fee invoice file?
        </span>
        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseDeleteServiceFeeInvoiceModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.deleteButton}
            onClick={onDeleteServiceFeeInvoice}
            title="Delete"
          />
        </div>
      </>
    );
  };
  const renderFilterModalBody = () => {
    return (
      <>
        <div className="row g-2">
          <ControlledComboBox
            className={classes.comboBox}
            textLabel="Company Name"
            options={companies}
            control={control}
            name="companyNameFilter"
          />
          <ControlledComboBox
            notSortOptions={true}
            className={classes.comboBox}
            textLabel="Month"
            newOptions={months}
            options={months}
            control={control}
            name="monthFilter"
          />
          <ControlledComboBox
            className={classes.comboBox}
            textLabel="Year"
            options={getYearsBetween(2023, currentYear + 1)}
            control={control}
            name="yearFilter"
          />
        </div>
        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseFilterModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={onFilter}
            title="Submit"
          />
        </div>
      </>
    );
  };
  return (
    <Sidebar pageTitle="Service Fee Invoices">
      {loading && <LoadingIndicator title="" loading />}
      <Helmet>
        <title>Service Fee Invoices | FruPro Admin Portal</title>
      </Helmet>
      <PermissionWrapper
        permission="SERVICE_FEE_INVOICES_LISTING_VIEW"
        unauthorizedComponent
      >
        <TableComponent
          loading={fetchLoading}
          filterBadge={
            <FilterBadge
              filterData={filterData}
              setFilterData={setFilterData}
              setDeletedFilterKey={setDeletedFilterKey}
            />
          }
          showFilterIcon
          onOpenModal={onOpenFilterModal}
          headerButtonTitle="+Add new Service Fee invoice"
          rightButtonPermission="SERVICE_FEE_INVOICES_ADD"
          TableBodyComponent={renderTableBody}
          showRightButton
          columns={columns}
          isRightButtonFunction
          rightButtonFunction={() => navigate("/service-fee-invoices/create")}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      </PermissionWrapper>
      <AdminModalComponent
        children={renderAddNewServiceFeeInvoiceModalBody()}
        headerTitle="New Service Fee Invoice"
        openModal={openNewServiceFeeInvoiceModal}
        closeModal={onCloseNewServiceFeeInvoiceModal}
      />
      <AdminModalComponent
        children={renderDeleteServiceFeeInvoiceModalBody()}
        headerTitle="Are you sure"
        openModal={openDeleteServiceFeeInvoiceModal}
        closeModal={onCloseDeleteServiceFeeInvoiceModal}
      />
      <AdminModalComponent
        children={renderFilterModalBody()}
        headerTitle="Filter Service Fee Invoice"
        openModal={openFilterModal}
        closeModal={onCloseDeleteServiceFeeInvoiceModal}
      />
    </Sidebar>
  );
};

export default ServiceFeeInvoicesContainer;
