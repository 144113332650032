import React from "react";
import { makeStyles } from "@mui/styles";
import PermissionWrapper from "../../components/PermissionWrapper";

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  trash: {
    marginRight: 15,
    fontSize: 22,
    cursor: "pointer",
    color: "red",
  },
  accountCard: {
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    borderRadius: "5px!important",
    background: "white!important",
    width: "100%",
    minHeight: "100%",
  },
  hr: {
    opacity: 0.08,
  },
  accountNameText: {
    color: "#5F8CBA",
  },
});
const AccountCardItem = ({
  account,
  onOpenDeleteConfirmModal,
}: {
  onOpenDeleteConfirmModal: (
    accountSellerNBuyerId: string,
    accountId: string
  ) => void;
  account: any;
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.accountCard} p-3`}>
      <div className="d-flex justify-content-between align-items-center">
        <span className={`fw-bold ${classes.accountNameText}`}>
          {account.accountName}
        </span>
        {!account?.seller ? null : (
          <PermissionWrapper permission="BANK_ACCOUNTS_DELETE">
            <i
              onClick={() =>
                onOpenDeleteConfirmModal(account?.bankInfoId, account._id)
              }
              className={`ri-delete-bin-line ${classes.trash}`}
            ></i>
          </PermissionWrapper>
        )}
      </div>{" "}
      <hr className={classes.hr} />
      <div className="d-flex flex-column gap-2">
        <span>
          <span className="fw-bold">Seller : </span> {account?.seller||"-"}
        </span>
        <span>
          <span className="fw-bold">Buyer : </span> {account?.buyer||"-"}
        </span>
        <span>
          <span className="fw-bold">Bank Name : </span> {account.bankName||"-"}
        </span>
        <span>
          <span className="fw-bold">Sort Code : </span> {account.sortCode||""}
        </span>
        <span>
          <span className="fw-bold">Account Number: </span> {account.accountNo||"-"}
        </span>
        <span>
          <span className="fw-bold">IBAN : </span> {account.iban||"-"}
        </span>
        <span>
          <span className="fw-bold">BIC : </span> {account.swift||"-"}
        </span>
        <span>
          <span className="fw-bold">Currency : </span> {account.currency||"-"}
        </span>
        {/* <span>
       <span className="fw-bold">Type : </span> {account.type}
     </span> */}
      </div>
    </div>
  );
};

export default AccountCardItem;
