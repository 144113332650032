import React, { useEffect, useMemo } from "react";
import { makeStyles } from "@mui/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import { Divider, TableHead } from "@mui/material";
import ControlledComboBox from "../../../components/ControlledComboBox";
import ControlledTextField from "../../../components/ControlledTextField";
import NumberFormatComponent from "../../../components/NumberFormatComponent";
import { AdditionalCostsType } from "./interfaces";

const useStyles = makeStyles((theme) => ({
  fontFamily: {
    fontFamily: "Montserrat,sans-serif!important",
  },
  hr: {
    opacity: 0.4,
  },
  mainText: {
    fontSize: 18,
  },
  Input: {
    borderRadius: "10px!important",
    width: "100%",
    height: 45,
    padding: 15,
  },
  checkLimitButton: {
    border: "none",
    borderRadius: 10,
    width: "100%",
    background: "#E77228",
    color: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    height: "40px",
    marginTop: 12,
    "&:disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },
  trash: {
    cursor: "pointer",
  },
  customControlledField: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },

  delete: {
    cursor: "pointer",
    fontSize: 20,
    color: "red",
  },
}));
const AdditionalCosts = ({
  setCostsLoading,
  currencySymbol,
  control,
  setValue,
  additionalCosts,
  watch,
  reset,
  errors,
  addedAdditionalCosts,
  setAddedAdditionalCosts,
}: {
  control: any;
  setValue: any;
  watch: any;
  currencySymbol: string;
  reset: any;
  errors: any;
  addedAdditionalCosts: AdditionalCostsType[];
  additionalCosts: any[];
  setAdditionalCosts: React.Dispatch<React.SetStateAction<any[]>>;
  setCostsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAddedAdditionalCosts: React.Dispatch<
    React.SetStateAction<AdditionalCostsType[]>
  >;
}) => {
  const classes = useStyles();
  const additionalCostOptions = useMemo(() => {
    return additionalCosts.map((item) => ({
      id: item._id,
      name: item.costName,
    }));
  }, [additionalCosts]);

  const additionalCost = watch("additionalCosts");
  const description = watch("description");
  const amount = watch("amount");
  const vatRate = watch("vatRate");
  const produceCurrency = watch("produceCurrency");

  // useEffect(() => {
  //   if (amount < 0) {
  //     setValue("amount", 0);
  //   }
  // }, [amount]);

  const handleClick = () => {
    const newAdditionalCost = {
      costId: additionalCost,
      description,
      amount,
      vatRate,
    };
    setAddedAdditionalCosts((prev) => [...prev, newAdditionalCost]);

    setValue("additionalCosts", null);
    setValue("description", "");
    setValue("vatRate", "");
    setValue("amount", 1);
  };

  const deleteAdditionalCost = (index: number) => {
    const newAdditionalCosts = addedAdditionalCosts.filter(
      (item, i) => i !== index
    );
    setAddedAdditionalCosts(newAdditionalCosts);
  };

  return (
    <div>
      <span className={classes.mainText}>Additional Costs</span>
      <Divider className={classes.hr} />
      <div className="row d-flex pt-3  align-items-center">
        <div className="col-4">
          <ControlledComboBox
            className={classes.customControlledField}
            control={control}
            name="additionalCosts"
            error={!!errors?.additionalCosts}
            helperText={errors?.additionalCosts?.message}
            options={additionalCostOptions}
            textLabel={"Additional Costs"}
            onBlur={() => {
              const cost = additionalCosts.find(
                (item) => item._id === additionalCost?.id
              );
              if (cost) {
                setValue("vatRate", cost?.vatRate);
              }
            }}
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.vatRate}
            helperText={errors?.vatRate?.message}
            textLabel="VAT Rate"
            name="vatRate"
            control={control}
            onBlur={(e) => {
              const value = parseFloat(e.target.value);
              if (isNaN(value)) {
                toast.error("VAT Rate must be a number");
                setValue("vatRate", "");
                return;
              }
              if (value < 0) {
                toast.error("VAT Rate must be greater than 0");
                setValue("vatRate", "");
                return;
              }

              if (value > 100) {
                toast.error("VAT Rate must be less than 100");
                setValue("vatRate", "");
                return;
              }

              e.preventDefault();
            }}
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.amount}
            helperText={errors?.amount?.message}
            textLabel="Amount"
            name="amount"
            control={control}
          />
        </div>
        <div className="col-10 mt-2">
          <ControlledTextField
            error={!!errors?.description}
            helperText={errors?.description?.message}
            textLabel="Description"
            name="description"
            control={control}
          />
        </div>

        <div className="col-2 mt-3">
          <button
            onClick={() => {
              handleClick();
            }}
            disabled={!additionalCost?.id || !amount}
            className={classes.checkLimitButton}
          >
            Add
          </button>
        </div>
      </div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 200 }}>Name</TableCell>
              <TableCell style={{ width: 75 }}>Description</TableCell>
              <TableCell align="right" style={{ width: 150 }}>
                VAT Rate
              </TableCell>
              <TableCell style={{ width: 100 }}>Amount</TableCell>
              <TableCell style={{ width: 100 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addedAdditionalCosts.map((row, index) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={{ width: 200 }} component="th" scope="row">
                  {row?.costId?.name}
                </TableCell>
                <TableCell
                  style={{
                    maxWidth: 75,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textAlign: "center",
                  }}
                >
                  {row?.description || "-"}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: 150 }}
                  component="th"
                  scope="row"
                >
                  <NumberFormatComponent value={row?.vatRate} />% (
                  <NumberFormatComponent
                    style={{
                      color: "#69cdae",
                    }}
                    prefix={currencySymbol}
                    value={row?.amount * (row?.vatRate / 100)}
                  />
                  )
                </TableCell>
                <TableCell style={{ width: 100 }}>
                  {" "}
                  <NumberFormatComponent
                    prefix={currencySymbol}
                    // decimalScale={2}
                    // fixedDecimalScale
                    // displayType="text"
                    // thousandSeparator={true}
                    value={row?.amount}
                  />
                </TableCell>
                <TableCell className="d-flex justify-content-end">
                  <i
                    onClick={() => deleteAdditionalCost(index)}
                    className={`ri-delete-bin-line ${classes.delete}`}
                  ></i>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdditionalCosts;
