

import { ThemeProvider, createTheme } from '@mui/material/styles'

declare module "@mui/material/styles" {

  interface Theme {
    palette: {
      primary: {
        main: string;
      };
      default: { main: string; text: string };
      initial: { main: string };
      error: { main: string };
      success: { main: string };
      secondary: { main: string };
    };
  }
}

const theme = createTheme({
  typography: {
    fontFamily: `"Mulish", sans-serif`,
  },

  palette: {
    primary: {
      main: "#E77228",
    },
    secondary: {
      main: "#BEBEBE",
    },
  },
});

export { theme };
