import React, { Fragment, useMemo } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./hooks/PrivateRoute";
import { pageRoutes } from "./PageRoutes";
import NotFoundPageContainer from "./pages/NotFoundPage/NotFoundPageContainer";
const AppRoutes: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {pageRoutes.map((item:any, index) => {
            return item.isPublic ? (
              <Fragment key={index}>
                <Route path={item.path} element={<item.container />} />
              </Fragment>
            ) : (
              <Fragment key={index}>
                <Route
                  path={item.path}
                  element={
                    <PrivateRoute>
                      <item.container />
                    </PrivateRoute>
                  }
                />
              </Fragment>
            );
          })}
          <Route path="*" element={<NotFoundPageContainer />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRoutes;
