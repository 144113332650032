import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getAdminUsersForGroups = async () => {
  try {
    const response = await axiosApiInstance.get(`/admin/admin-users?page=${0}`);

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.errors[0]?.message ||
          error.response?.data.message ||
          error.message
      );
    }
  }
};

export const getPermissions = async () => {
  try {
    const response = await axiosApiInstance.get(
      "/admin/groups/permissions",
      {}
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.errors[0]?.message ||
          error.response?.data.message ||
          error.message
      );
    }
  }
};

export const getAdminGroups = async () => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/groups/admin-user-groups",
      {}
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.errors[0]?.message ||
          error.response?.data.message ||
          error.message
      );
    }
  }
};

export const getAdminGroupPermissions = async (adminGroupIds: string[]) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/groups/get-admin-group-permission",
      {
        adminGroupIds,
      }
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.errors[0]?.message ||
          error.response?.data.message ||
          error.message
      );
    }
  }
};

export const deleteAdminGroupPermissions = async (
  adminGroupId: string,
  permissionIds: { permissionId: string }[]
) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/groups/delete-permissions-from-admin-group",
      {
        adminGroupId,
        permissionIds,
      }
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.errors[0]?.message ||
          error.response?.data.message ||
          error.message
      );
    }
  }
};

export const deleteAdminGroup = async (adminGroupId: string) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/groups/delete-admin-group",
      {
        adminGroupId,
      }
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.errors[0]?.message ||
          error.response?.data.message ||
          error.message
      );
    }
  }
};

export const createAdminGroup = async (adminGroupName: string) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/groups/create-admin-group",
      {
        adminGroupName,
      }
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.errors[0]?.message ||
          error.response?.data.message ||
          error.message
      );
    }
  }
};

export const addPermissionsToAdminGroup = async (
  adminGroupId: string,
  permissionIds: any[]
) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/groups/add-permissions-to-admin-group",
      {
        adminGroupId,
        permissionIds,
      }
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.errors[0]?.message ||
          error.response?.data.message ||
          error.message
      );
    }
  
  }
};

export const getGroupUsers = async (adminGroupId: string) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/groups/admin-user-group/users",
      {
        adminGroupId,
      }
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.errors[0]?.message ||
          error.response?.data.message ||
          error.message
      );
    }
  }
};

export const updateGroupUsers = async (
  adminId: string,
  adminGroupIds: string[]
) => {
  try {
    const response = await axiosApiInstance.post(
      "/admin/groups/update-admin-group",
      {
        adminId,
        adminGroupIds,
      }
    );

    if (response) return response.data;
  } catch (error) {
   
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.errors[0]?.message ||
          error.response?.data.message ||
          error.message
      );
    }
  }
};
