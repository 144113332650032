import React, { useState } from "react";
import { PartialState } from "./SurplusOrderDetailContainer";
import NumberFormatComponent from "../../../../components/NumberFormatComponent";
import { makeStyles } from "@mui/styles";
import {
  Chip,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import AdminButtonComponent from "../../../../components/AdminButtonComponent";
import ControlledTextField from "../../../../components/ControlledTextField";
import {
  capturePartialPayment,
  updatePartialPayment,
} from "../../../../services/surplus-allocation";
import AdminModalComponent from "../../../../components/AdminModalComponent";
import usePermissions from "../../../../hooks/usePermissions";
import PermissionWrapper from "../../../../components/PermissionWrapper";
import { PaymentStatuses } from "../../../../constants";
import { usePaymentTerms } from "../../../../hooks/paymentTerms";
import { OrderStateType } from "../../orderStateType";
import { toast } from "react-toastify";
import moment from "moment";

const useStyles = makeStyles({
  header: {
    fontSize: 20,
  },
  hr: {
    opacity: 0.05,
  },
  cancelled: {
    background: "#FFAF9D!important",
    color: "white!important",
  },
  captured: {
    background: "#69CDAE!important",
    color: "white!important",
  },
  waiting: {
    background: "#FFDE82!important",
    color: "white!important",
  },
  waitingTerms: {
    background: "#FEFE82!important",
    color: "white!important",
  },
  capture: {
    minWidth: "120px!important",
    minHeight: "40px!important",
    borderRadius: "10px!important",
    background: "#5F8CBA!important",
  },
  edit: {
    borderRadius: "10px!important",
    background: "#E77228!important",
  },
  amount: {
    fontSize: 17,
  },
  textField: {
    background: "#F8F8F8",
    fontSize: 20,
    borderRadius: 10,
    border: "none !important",
    width: "100px",
    height: 50,
    padding: 15,
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red !important",
    },
    "& .MuiInputBase-root": {
      background: "#f8f8f8",
      minHeight: 50,
      borderColor: "black !important",
      border: "none !important",
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  confirmButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
});

const PartialPayment = ({
  data,
  orderStatus,
  setRefresh,
  control,
  setValue,
  setRefreshPartial,
  watch,
  currencySymbol,
  partialPayment,
}: {
  data?: OrderStateType;
  orderStatus?: string;
  control: any;
  setValue: any;
  watch: any;
  currencySymbol?: string;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  partialPayment: PartialState[];
  setRefreshPartial: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { hasPermission } = usePermissions();
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [openCaptureModal, setOpenCaptureModal] = useState<boolean>(false);
  const [selectedCaptureId, setSelectedCaptureId] = useState<string>("");

  const {
    sellerFeesLoading,
    paymentTermsLoading,
    paymentTerms,
    openCaptureWaitingModal,
    setOpenCaptureWaitingModal,
    companySellerFeesData,
    selectedTerm,
    setSelectedTerm,
  } = usePaymentTerms(
    watch("feePercentage"),
    setValue,
    data?.sellerCompany?._id || ""
  );

  const onOpenCaptureConfirmModal = (id: string, status: string) => {
    status === "WAITING_PAYMENT_TERMS"
      ? setOpenCaptureWaitingModal(true)
      : setOpenCaptureModal(true);
    setSelectedCaptureId(id);
  };

  const onCloseCaptureConfirmModal = () => {
    setOpenCaptureModal(false);
    setSelectedCaptureId("");
  };

  // const onUpdatePaymentAmount = async (index: number, id: string) => {
  //   if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

  //   const currentIndexAmount = watch(`partialPaymentAmount[${index}]`);

  //   const data = {
  //     partialPaymentId: id,
  //     amount: parseFloat(currentIndexAmount),
  //   };
  //   setIsEditing(true);
  //   const updated = await updatePartialPayment(data);
  //   setIsEditing(false);
  //   if (updated) {
  //     setRefresh((prev: any) => prev + 1);
  //   }
  // };

  const onCapturePayment = async () => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    if (!watch("feePercentage") && openCaptureWaitingModal)
      return toast.error("Please enter fee percentage");

    const finalData = {
      partialPaymentId: selectedCaptureId,
      paymentTermDays: openCaptureWaitingModal
        ? parseFloat(selectedTerm)
        : undefined,

      override_amounts: {
        sellers_fee_override_percentage: openCaptureWaitingModal
          ? parseFloat(watch("feePercentage"))
          : undefined,
      },
    };

    setIsEditing(true);
    const captured = await capturePartialPayment(finalData);
    setIsEditing(false);

    if (captured) {
      setRefresh((prev: any) => prev + 1);
      setRefreshPartial((prev: any) => prev + 1);
      setOpenCaptureModal(false);
      setSelectedCaptureId("");
    }
  };

  const renderCapturePaymentModalBody = () => {
    return (
      <>
        <div className="container">
          <span>Are you sure you want to capture this payment?</span>
          <div className={classes.filterModalButtons}>
            <AdminButtonComponent
              disabled={isEditing}
              className={classes.closeButton}
              onClick={onCloseCaptureConfirmModal}
              title="Close"
            />{" "}
            <AdminButtonComponent
              loading={isEditing}
              className={classes.confirmButton}
              onClick={onCapturePayment}
              title="Confirm"
            />
          </div>
        </div>
      </>
    );
  };

  const renderCaptureLineItemModalBody = () => {
    return (
      <>
        {sellerFeesLoading && paymentTermsLoading ? (
          <div className="flex justify-center items-center mt-5">
            <CircularProgress size={30} />
          </div>
        ) : (
          <>
            <RadioGroup className="mt-2 ml-3">
              {paymentTerms.map((item: any, index: number) => {
                return (
                  <div key={index} className="flex items-center mt-2 space-x-2">
                    <FormControlLabel
                      control={<Radio style={{ color: "#E77228" }} />}
                      label={item?.paymentTerm}
                      id={item._id}
                      checked={item.paymentTerm === selectedTerm}
                      value={item.paymentTerm}
                      onClick={(e: any) => setSelectedTerm(e.target.value)}
                    />
                    {/* <Label htmlFor={item.paymentTerm}>
                        {item.paymentTerm}
                      </Label> */}
                  </div>
                );
              })}
            </RadioGroup>

            <hr className="mt-5" />
            <div className="flex gap-5 flex-row items-center">
              <div className="col-4">
                <ControlledTextField
                  // error={!feePercentage}
                  className="  h-[35px]"
                  control={control}
                  label="Fee Percentage"
                  name="feePercentage"
                />
              </div>
              <div className="mt-5">
                <span className="">
                  Platform Fee :{" "}
                  <NumberFormatComponent
                    prefix={data?.produces[0].currency.symbol}
                    value={
                      ((data?.grandTotalAmount || 0) *
                        parseFloat(watch("feePercentage") || "0")) /
                      100
                    }
                  />
                </span>
              </div>
            </div>

            <div className="mt-5 flex justify-end">
              <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                <AdminButtonComponent
                  className={classes.capture}
                  disabled={isEditing || orderStatus === "CANCELLED"}
                  onClick={onCapturePayment}
                  title={
                    isEditing ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "Capture"
                    )
                  }
                />
              </PermissionWrapper>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div className="p-3">
      <span className={classes.header}>Payment Details</span>
      <hr className={classes.hr} />
      <div className="d-flex flex-column gap-3 mt-3">
        {partialPayment.map((payment, index) => {
          const today = moment(new Date()).format("DD/MM/YYYY");
          const dueDate = moment(payment?.dueDate).format("DD/MM/YYYY");
          const statusChipColor = PaymentStatuses.find(
            (i) => i.name === payment.status
          )?.color;
          return (
            <div
              className="d-flex flex-row align-items-center justify-content-between"
              key={payment._id}
            >
              <div className="flex flex-row align-items-center gap-3">
                <div className="d-flex flex-row gap-2 align-items-center">
                  <NumberFormatComponent
                    prefix={currencySymbol}
                    value={payment.amount}
                  />
                </div>
                {dueDate ? (
                  <div
                    className={`flex  text-sm font-bold ${
                      today > dueDate ? "text-red-500" : "text-yellow-500"
                    } flex-row gap-2`}
                  >
                    <span>Due Date</span>:<span>{dueDate}</span>
                  </div>
                ) : null}
              </div>
              <div className="d-flex flex-row gap-2 align-items-center">
                <Chip
                  style={{
                    color: "white",
                    minWidth: 120,
                    fontWeight: 600,
                    backgroundColor: statusChipColor,
                  }}
                  label={payment.status}
                />
                {payment.status === "WAITING" ||
                payment.status === "WAITING_PAYMENT_TERMS" ? (
                  <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                    <AdminButtonComponent
                      className={classes.capture}
                      disabled={isEditing || orderStatus === "CANCELLED"}
                      onClick={() =>
                        onOpenCaptureConfirmModal(
                          payment._id || payment.id,
                          payment.status
                        )
                      }
                      title={
                        isEditing ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                          />
                        ) : (
                          "Capture"
                        )
                      }
                    />
                  </PermissionWrapper>
                ) : null}
                {/* {payment.status === "WAITING" ? (
                  <AdminButtonComponent
                    disabled={isEditing}
                    className={classes.edit}
                    onClick={() => onUpdatePaymentAmount(index, payment._id)}
                    title={"Edit"}
                  />
                ) : null} */}
              </div>
            </div>
          );
        })}
      </div>
      <AdminModalComponent
        children={renderCapturePaymentModalBody()}
        headerTitle="Are you sure"
        openModal={openCaptureModal}
        closeModal={onCloseCaptureConfirmModal}
      />
      <AdminModalComponent
        size="lg"
        children={renderCaptureLineItemModalBody()}
        headerTitle="Select payment term and enter fee percentage"
        openModal={openCaptureWaitingModal}
        closeModal={() => setOpenCaptureWaitingModal(false)}
      />
    </div>
  );
};

export default PartialPayment;
