import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";

type PrivateRouteType = {
  children: ReactElement;
};

const PrivateRoute: React.FC<PrivateRouteType> = ({ children }) => {
  let token: string | null = localStorage.getItem("accessToken");

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default PrivateRoute;
