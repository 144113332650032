import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import AdminOTPInput from "../../../components/AdminOtpInput";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import { LOGIN_FORM } from "../contants";
import { useAppDispatch } from "../../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { setAdminUserInfo } from "../../../slices/authSlice";
import {
  getTwoFaToken,
  VerifyTwoFa,
  FirstTimeVerifyTwoFa,
} from "../../../services/auth";
import { UserInfoStateType } from "../../../slices/authSlice";
export interface VerifyCardType {
  setRenderedContainer: React.Dispatch<React.SetStateAction<string>>;
  accessToken: string;
  userData: UserInfoStateType;
  email: string;
}
const useStyles = makeStyles({
  card: {
    height: "auto",
    padding: "40px 40px 24px 40px",
    background: "rgba(249, 249, 249, 0.9)",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    backdropFilter: "blur(15.5px)",
    borderRadius: "20px",
  },
  headerText: {
    textAlign: "center",
    fontSize: "20px!important",
    color: "#E77228",
    fontWeight: 500,
  },
  expText: {
    fontSize: 16,
    fontWeight: 400,
  },
  VerifyButton: {
    width: "100%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  goBack: {
    textDecoration: "underline",
    color: "#69CDAE",
    cursor: "pointer",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  qrContainer: {
    display: "flex",
    marginTop: 20,
    justifyContent: "center",
  },
});
const VerifyContainer: React.FC<VerifyCardType> = ({
  setRenderedContainer,
  userData,
  accessToken,
  email,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isTwoFaVerified = localStorage.getItem("isTwoFaVerified");

  const [otp, setOtp] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<string>("");

  useEffect(() => {
    document.getElementById("verifyOtp")?.focus();
  }, []);

  useEffect(() => {
    if (otp.length === 6) {
      onVerify();
    }
  }, [otp]);

  useEffect(() => {
    if (isTwoFaVerified === "false") {
      const getQrCode = async () => {
        const response = await getTwoFaToken(email);
        if (response) {
          setQrCode(response.qr);
        }
      };
      getQrCode();
    }
  }, []);

  const onVerify = async () => {
    if (isTwoFaVerified === "true") {
      setLoading(true);
      setTimeout(async () => {
        const response = await VerifyTwoFa(otp, email);

        if (response === 200) {
          setLoading(false);
          dispatch(setAdminUserInfo(userData));
          localStorage.setItem("accessToken", accessToken);
          window.location.href = "/";
        }
      }, 1000);
    } else {
      setLoading(true);
      setTimeout(async () => {
        const response = await FirstTimeVerifyTwoFa(otp, email);
        setLoading(false);
        if (response===200) {
          localStorage.setItem("accessToken", accessToken);
          window.location.href = "/";
        }
      }, 1000);
    }
  };

  return (
    <div className={classes.card}>
      <h3 className={classes.headerText}>Verify your authentication</h3>

      <div className={classes.qrContainer}>
        {qrCode ? <img src={qrCode} /> : null}
      </div>

      <div>
        <AdminOTPInput
          id="verifyOtp"
          numInputs={6}
          value={otp}
          onChange={setOtp}
        />

        <p className={classes.expText}>
          Enter your 6 digit code in your Authenticator app.
        </p>
      </div>

      <div className={classes.buttonContainer}>
        <AdminButtonComponent
          loading={loading}
          disabled={loading}
          className={classes.VerifyButton}
          onClick={onVerify}
          title="Verify"
        />
        <p
          onClick={() => setRenderedContainer(LOGIN_FORM)}
          className={classes.goBack}
        >
          Go back
        </p>
      </div>
    </div>
  );
};

export default VerifyContainer;
