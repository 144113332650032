import React, { useEffect, useState } from "react";
import { Types } from "./CertificationRequestsContainer";
import TableComponent from "../../components/TableComponent";
import { QueryParams } from "./CertificationRequestsContainer";
import { Box, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import AdminModalComponent from "../../components/AdminModalComponent";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../components/ControlledTextField";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
  addCertificationTypes,
  deleteCertificationTypes,
  updateCertificationTypesImage,
  updateCertificationTypesInfo,
} from "../../services/company-certificates";
const columns = [
  { name: "Certification", id: "certificate" },
  { name: "Title", id: "title" },
  { name: "Create Time", id: "createdAt" },
  { name: "", id: "actions" },
];

const schema = yup.object().shape({
  title: yup.string(),
});

const useStyles = makeStyles({
  labelImage: {
    cursor: "pointer",
  },
  uploadSvg: {
    borderRadius: "100%",
    width: 50,
    height: 50,
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  image: {
    width: 75,
    height: 75,
    borderRadius: "100%",
  },
  approve: {
    "&:hover": {
      borderRadius: "20px!important",
      background: "#69CDAE!important",
    },
    borderRadius: "20px!important",
    background: "#69CDAE!important",
  },
  reject: {
    "&:hover": {
      borderRadius: "20px!important",
      background: "red!important",
    },
    borderRadius: "20px!important",
    background: "red!important",
  },
  seeFile: {
    color: "#E77228",
    "&:hover": {
      color: "#E77228",
    },
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  deleteButton: {
    width: "50%",
    height: "50px",
    background: "red !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: 460,

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  edit: {
    fontSize: 26,
    color: "#E77228",
  },
  delete: {
    fontSize: 26,
    color: "red",
  },
  imageTitle: {
    marginLeft: 5,
  },
  
});

const CertificationTypesTable = ({
  queryParams,
  setQueryParams,
  certificationTypes,
  setRefresh,
  refresh,
}: {
  certificationTypes: Types[];
  refresh: number;
  queryParams: QueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<QueryParams>>;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { control, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const watchTitle = watch("title");
  const classes = useStyles();

  const [openAddEditModal, setOpenAddEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedType, setSelectedType] = useState<Types>();
  const [selectedDeleteId, setSelectedDeleteId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedType?._id) {
      setValue("title", selectedType.title);
    }
  }, [selectedType?._id]);

  useEffect(() => {
    if (!openAddEditModal) {
      setValue("title", "");
      setSelectedType(undefined);
    }
  }, [openAddEditModal]);

  const onOpenEditModal = (type: Types) => {
    setOpenAddEditModal(true);
    setSelectedType(type);
  };
  const onOpenDeleteModal = (id: string) => {
    setOpenDeleteModal(true);
    setSelectedDeleteId(id);
  };
  const onCloseAddModal = () => {
    setOpenAddEditModal(false);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const onSelectFile = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const onAddNewType = async () => {
    setLoading(true);
    const data = new FormData();
    data.append("title", (watchTitle as any));
    data.append("file", selectedFile ? selectedFile : "");

    const added = await addCertificationTypes(data);
    setLoading(false);

    if (added) {
      setSelectedFile(undefined);
      setValue("title", "");
      setOpenAddEditModal(false);
      setRefresh(() => refresh + 1);
    }
  };

  const onEditType = async () => {
    setLoading(true);
    const data = {
      title: watchTitle,
      certificationTypeId: selectedType?._id,
    };
    const edited = await updateCertificationTypesInfo(data);
    setLoading(false);

    if (edited) {
      setOpenAddEditModal(false);
      setRefresh(() => refresh + 1);
    }
  };
  const onEditImage = async (e: any) => {
    setLoading(true);
    const data = new FormData();

    data.append(
      "certificationTypeId",
      selectedType?._id ? selectedType?._id : ""
    );
    data.append("file", e.target.files[0]);
    const updatedImage = await updateCertificationTypesImage(data);
    setLoading(false);
    if (updatedImage) {
      setSelectedFile(undefined);
      setRefresh(() => refresh + 1);
      setSelectedType(updatedImage?.updatedDoc);
    }
  };

  const onDeleteType = async () => {
    setLoading(true);
    const deleted = await deleteCertificationTypes(selectedDeleteId);
    setLoading(false);

    if (deleted) {
      setRefresh(() => refresh + 1);
      setOpenDeleteModal(false);
    }
  };

  const renderTableBody = () => {
    return (
      <>
        {certificationTypes?.map((type, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <img className={classes.image} src={type.imagePath} />
              </TableCell>
              <TableCell>{type.title}</TableCell>

              <TableCell>
                {moment(type.createdAt).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell>
                {" "}
                <div className="d-flex justify-content-end">
                  <Tooltip title="Edit certification type">
                    <IconButton onClick={() => onOpenEditModal(type)}>
                      <i className={`ri-pencil-line   ${classes.edit}`}></i>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete certification type">
                    <IconButton onClick={() => onOpenDeleteModal(type._id)}>
                      <i className={`ri-delete-bin-line ${classes.delete}`}></i>
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderModalBody = () => {
    return (
      <>
        <div className=" pb-3">
          {selectedType?._id ? (
            <label className={classes.labelImage}>
              <img className={classes.uploadSvg} src={selectedType.imagePath} />

              <span className={classes.imageTitle}>
                {selectedFile
                  ? "File selected"
                  : "Please select new file and update image"}
              </span>
              <input
                onChange={(e) => onEditImage(e)}
                type="file"
                accept=".jpg,.jpeg,.svg,.png"
                hidden
              />
            </label>
          ) : (
            <label className={classes.labelImage}>
              {selectedFile ? (
                <img
                  className={classes.uploadSvg}
                  src={URL.createObjectURL(selectedFile)}
                />
              ) : (
              <div className="d-flex flex-row align-items-center gap-2">
                  <img
                  className={classes.uploadSvg}
                  src="/static/svg/upload.svg"
                />
                <span className={classes.imageTitle}>
                {selectedFile
                  ? "File selected"
                  : "Please select a file to upload"}
              </span>
              </div>
              )}
            
              <input
                onChange={(e) => onSelectFile(e)}
                type="file"
                accept=".jpg,.jpeg,.svg,.png"
                hidden
              />
            </label>
          )}
        </div>
        <ControlledTextField name="title" control={control} textLabel="Title" />

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setOpenAddEditModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            disabled={
              selectedType?._id ? !watchTitle : !selectedFile || !watchTitle
            }
            className={classes.saveButton}
            onClick={selectedType?._id ? onEditType : onAddNewType}
            title="Submit"
          />
        </Box>
      </>
    );
  };

  const renderDeleteBody = () => {
    return (
      <>
        <span>Are you sure you want to delete this certification type?</span>

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setOpenDeleteModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            disabled={!selectedDeleteId}
            className={classes.deleteButton}
            onClick={onDeleteType}
            title="Delete"
          />
        </Box>
      </>
    );
  };

  return (
    <div>
      {loading && <LoadingIndicator loading />}
      <TableComponent
        setOpenModal={setOpenAddEditModal}
        headerButtonTitle=" + Add new certification type"
        TableBodyComponent={renderTableBody}
        setQueryParams={setQueryParams}
        columns={columns}
        showRightButton={true}
        queryParams={queryParams}
      />
      <AdminModalComponent
        children={renderModalBody()}
        headerTitle="Add new certification type"
        openModal={openAddEditModal}
        closeModal={onCloseAddModal}
      />
      <AdminModalComponent
        children={renderDeleteBody()}
        headerTitle="Are you sure?"
        openModal={openDeleteModal}
        closeModal={onCloseDeleteModal}
      />
    </div>
  );
};

export default CertificationTypesTable;
