import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const addToXero = async (data: any) => {
  try {
    const response = await axiosApiInstance.post("/xero/create-contact", {
      ...data,
    });

    if (response) {
      toast.success("Company added to Xero successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message);
    }
  }
};

export const getContactGroups = async () => {
  try {
    const response = await axiosApiInstance.get("/xero/contact-groups");

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.message || error.response?.data?.message);
    }
  }
};
