import React from "react";
import { Button } from "@mui/material";
import { ButtonComponentTypes } from "./interfaces/ComponentInterfaces";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
const useStyles = makeStyles({
  defaultButton: {
    background: "#E77228 !important",
  },
  grayButton: {
    background: "#BEBEBE !important",
  },
  loadingColor: {
    color: "white !important",
  },
});
const AdminButtonComponent: React.FC<ButtonComponentTypes> = ({
  title,
  disabled,
  className,
  onKeyPress,
  loading,
  onClick,
  style,
  type,
  variant = "contained",
}) => {
  const classes = useStyles();
  const renderButtonComponent = () => {
    switch (type) {
      case "default":
        return (
          <Button
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                onKeyPress && onKeyPress(e);
              }
            }}
            disabled={disabled}
            onClick={onClick}
            style={style}
            className={className ? className : classes.defaultButton}
            variant={variant}
          >
            {loading ? (
              <CircularProgress size={20} className={classes.loadingColor} />
            ) : (
              title
            )}
          </Button>
        );
      case "gray":
        return (
          <Button
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onKeyPress && onKeyPress(e);
              }
            }}
            disabled={disabled}
            onClick={onClick}
            style={style}
            className={className ? className : classes.grayButton}
            variant={variant}
          >
            {loading ? (
              <CircularProgress size={20} className={classes.loadingColor} />
            ) : (
              title
            )}
          </Button>
        );
      case "success":
        return (
          <Button
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onKeyPress && onKeyPress(e);
              }
            }}
            disabled={disabled}
            onClick={onClick}
            color="success"
            style={style}
            className={className}
            variant={variant}
          >
            {loading ? (
              <CircularProgress size={20} className={classes.loadingColor} />
            ) : (
              title
            )}
          </Button>
        );
      case "error":
        return (
          <Button
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onKeyPress && onKeyPress(e);
              }
            }}
            disabled={disabled}
            onClick={onClick}
            color="error"
            style={style}
            className={className}
            variant={variant}
          >
            {loading ? (
              <CircularProgress size={20} className={classes.loadingColor} />
            ) : (
              title
            )}
          </Button>
        );
      default:
        return (
          <Button
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onKeyPress && onKeyPress(e);
              }
            }}
            disabled={disabled}
            onClick={onClick}
            style={style}
            className={className ? className : classes.defaultButton}
            variant={variant}
          >
            {loading ? (
              <CircularProgress size={20} className={classes.loadingColor} />
            ) : (
              title
            )}
          </Button>
        );
    }
  };

  return <>{renderButtonComponent()}</>;
};

export default AdminButtonComponent;
