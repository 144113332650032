import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { makeStyles } from "@mui/styles";
import ControlledTextField from "../../components/ControlledTextField";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../hooks/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppDispatch } from "../../hooks/hooks";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { updateAdminProfileInfo } from "../../services/auth";
import LoadingIndicator from "../../components/LoadingIndicator";
import { insertAdminUserInfo, setAdminUserInfo } from "../../slices/authSlice";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  profileCard: {
    width: "100%",
    background: "white",
    borderRadius: "20px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  },
  saveButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 20,
  },
});

const profileSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  phoneNumber: yup.string(),
  createdAt: yup.string(),
  status: yup.string(),
  email: yup.string(),
});

const AdminProfileContainer: React.FC = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const adminUserInfo = useAppSelector((state) => state.auth.adminUserInfo);

  const [loading, setLoading] = useState<boolean>(false);

  const { control, reset, handleSubmit } = useForm({
    resolver: yupResolver(profileSchema),
  });

  useEffect(() => {
    reset({
      firstName: adminUserInfo.firstName,
      lastName: adminUserInfo.lastName,
      email: adminUserInfo.email,
      phoneNumber: adminUserInfo.phoneNumber,
      status: adminUserInfo.status,
      createdAt: adminUserInfo.createdAt,
    });
  }, [adminUserInfo]);

  const onSave = async (data: any) => {
    setLoading(true);
    setTimeout(async () => {
      const updated = await updateAdminProfileInfo({
        firstName: data?.firstName,
        lastName: data?.lastName,
        phoneNumber: data?.phoneNumber,
      });

      if (updated) {
        dispatch(insertAdminUserInfo(updated));
      }
      setLoading(false);
    }, 1000);
  };
  return (
    <>
      <Helmet>
        <title>Admin Profile | FruPro Admin Portal</title>
      </Helmet>
      <Sidebar pageTitle="My Profile">
        {loading && <LoadingIndicator loading />}
        <div className={classes.profileCard}>
          <div className=" p-4 ">
            <div className="row g-3">
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="First Name"
                  control={control}
                  name="firstName"
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="Last Name"
                  control={control}
                  name="lastName"
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="Email"
                  disabled={true}
                  control={control}
                  name="email"
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="Phone Number"
                  control={control}
                  name="phoneNumber"
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  disabled={true}
                  textLabel="Status"
                  control={control}
                  name="status"
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  disabled={true}
                  textLabel="Created Time"
                  control={control}
                  name="createdAt"
                />
              </div>
            </div>
          </div>

          <div className={classes.saveButton}>
            <AdminButtonComponent
              title="Save Details"
              onClick={handleSubmit(onSave)}
            />
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default AdminProfileContainer;
