import React, { useCallback, useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import {
  addNewAdminUser,
  blockAdminUser,
  deleteAdminUser,
  editAdminsGroup,
  getAdminGroupsWithoutPagination,
  getAdminUsers,
  unBlockAdminUser,
} from "../../services/adminUser";
import AdminUsersTable from "./AdminUsersTable";
import { adminUsersObjectType, paginationDataType } from "./interfaces";
import ControlledTextField from "../../components/ControlledTextField";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { makeStyles } from "@mui/styles";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import SearchTextField from "../../components/SearchTextField";
import AdminModalComponent from "../../components/AdminModalComponent";
import { Helmet } from "react-helmet";
import LoadingIndicator from "../../components/LoadingIndicator";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";
import ControlledComboBox from "../../components/ControlledComboBox";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  searchButton: {
    borderRadius: "100% !important",
    width: "50px !important",
    cursor: "pointer",
    height: "50px !important",
    background: "#E77228 !important",
    display: "flex",
    justifyContent: "center",
    color: "white",
    fontSize: 18,
    alignItems: "center",
  },
  searchField: {
    display: "flex",
    gap: 10,
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  confirmationText: {
    textAlign: "center",
  },
});

const adminUsersSearchSchema = yup.object().shape({
  keyword: yup.string(),
  email: yup.string(),
  reason: yup.string(),
  groups: yup.array(),
});
const AdminUsersContainer: React.FC = () => {
  const classes = useStyles();
  const { hasPermission } = usePermissions();
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(adminUsersSearchSchema),
  });

  //watch
  const watchKeyword = watch("keyword");
  const watchEmail = watch("email");
  const watchReason = watch("reason");
  const watchGroups = watch("groups");

  //states
  const [adminUsersData, setAdminUsersData] = useState<adminUsersObjectType[]>(
    []
  );
  const [selectedAdminData, setSelectedAdminData] =
    useState<adminUsersObjectType>();
  const [refreshToken, setRefreshToken] = useState<number>(0);
  const [openAddNewAdminModal, setOpenAddNewAdminModal] =
    useState<boolean>(false);
  const [openEditGroupModal, setOpenEditGroupModal] = useState<boolean>(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState<boolean>(false);

  const [openBlockReasonModal, setOpenBlockReasonModal] =
    useState<boolean>(false);
  const [openUnBlockConfirmationModal, setOpenUnBlockConfirmationModal] =
    useState<boolean>(false);

  const [queryParams, setQueryParams] = useState<paginationDataType>({
    page: 1,
    totalPage: 1,
    total: 1,
    limit: 1,
  });
  const [adminUserId, setAdminUserId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [adminGroups, setAdminGroups] = useState<
    {
      checked: boolean;
      name: string;
      _id: string;
    }[]
  >([]);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [blockLoading, setBlockLoading] = useState<boolean>(false);

  const onOpenEditGroupModal = (data: adminUsersObjectType) => {
    setOpenEditGroupModal(true);
    setSelectedAdminData(data);
  };
  const onCloseEditGroupModal = () => {
    setOpenEditGroupModal(false);
  };

  const onCloseModal = () => {
    setOpenAddNewAdminModal(false);
  };
  const onCloseDeleteConfirmationModal = () => {
    setOpenDeleteConfirmationModal(false);
  };

  const onCloseUnBlockConfirmationModal = () => {
    setOpenUnBlockConfirmationModal(false);
  };

  //effects and renders

  useEffect(() => {
    if (!hasPermission("ADMIN_USERS_LISTING_VIEW")) return;

    const getAllAdmins = async () => {
      setFetchLoading(true);
      setTimeout(async () => {
        const response = await getAdminUsers({
          keyword: "",
          page: queryParams.page,
        });
        setFetchLoading(false);

        if (response) {
          setQueryParams({
            page: response.metaData.page,
            limit: response.metaData.limit,
            total: response.metaData.total,
            totalPage: response.metaData.totalPage,
          });
          setAdminUsersData(response.items);
        }
      }, 1000);
    };
    getAllAdmins();
  }, [
    queryParams.page,
    refreshToken,
    hasPermission("ADMIN_USERS_LISTING_VIEW"),
  ]);

  //all admin groups
  useEffect(() => {
    if (openEditGroupModal || openAddNewAdminModal) {
      const getGroups = async () => {
        setLoading(true);
        const response = await getAdminGroupsWithoutPagination();
        setLoading(false);
        if (response) {
          setAdminGroups(
            response?.data.map((item: any) => ({
              ...item,
              checked: selectedAdminData?.groups
                .map((g) => g.name)
                .includes(item.name)
                ? true
                : false,
            }))
          );
        }
      };
      getGroups();
    } else {
      setAdminGroups([]);
    }
  }, [openEditGroupModal, openAddNewAdminModal]);

  const onSearch = () => {
    setLoading(true);
    setTimeout(async () => {
      const response = await getAdminUsers({
        keyword: watchKeyword ? watchKeyword : "",
        page: queryParams.page,
      });
      setLoading(false);

      if (response) {
        setQueryParams({
          page: response.metaData.page,
          limit: response.metaData.limit,
          total: response.metaData.total,
          totalPage: response.metaData.totalPage,
        });
        setAdminUsersData(response.items);
      }
    }, 1000);
  };

  const renderSearchTextField = () => {
    return (
      <SearchTextField
        onKeyPress={onSearch}
        onSearchClick={onSearch}
        control={control}
        name="keyword"
      />
    );
  };

  const onAddAdminUser = async (data: any) => {
    setLoading(true);
    const finalGroupsArr = watchGroups?.map((item: any) => item?._id);
    setTimeout(async () => {
      const response = await addNewAdminUser(data?.email, finalGroupsArr || []);
      setLoading(false);
      if (response) {
        reset({
          groups: [],
          email: "",
        });
        setOpenAddNewAdminModal(false);
        setRefreshToken(() => refreshToken + 1);
      }
    }, 1000);
  };

  const onDeleteAdminUser = async (adminId: string) => {
    setDeleteLoading(true);
    await deleteAdminUser(adminId);
    setDeleteLoading(false);
    setOpenDeleteConfirmationModal(false);
    setRefreshToken(() => refreshToken + 1);
  };

  const onBlockAdminUser = async (adminId: string) => {
    setBlockLoading(true);
    await blockAdminUser({ reason: watchReason || "", adminId: adminId });
    setOpenBlockReasonModal(false);
    setBlockLoading(false);
    setRefreshToken(() => refreshToken + 1);
  };

  const onUnBlockAdminUser = async (adminId: string) => {
    setBlockLoading(true);
    await unBlockAdminUser(adminId);
    setOpenUnBlockConfirmationModal(false);
    setBlockLoading(false);
    setRefreshToken(() => refreshToken + 1);
  };

  //edit admin's groups

  const onEditAdminsGroups = async (adminGroupData: {
    name: string;
    _id: string;
    checked: boolean;
  }) => {
    setLoading(true);

    if (adminGroupData.checked) {
      setLoading(false);

      adminGroupData.checked = false;
      const filteredGroups = adminGroups.findIndex(
        (item) => item._id === adminGroupData._id
      );

      adminGroups[filteredGroups] = adminGroupData;

      const newGroupsUnchecked = [...adminGroups];
      setAdminGroups(newGroupsUnchecked);

      const finalData = {
        adminId: selectedAdminData?.id,
        adminGroupIds: adminGroups
          .filter((item) => item.checked)
          .map((i) => i._id),
      };
      const response = await editAdminsGroup(finalData);
      setLoading(false);

      setRefreshToken((prev: number) => prev + 1);
    } else {
      adminGroupData.checked = true;
      const filteredGroups = adminGroups.findIndex(
        (item) => item._id === adminGroupData._id
      );

      adminGroups[filteredGroups] = adminGroupData;

      const newGroupsChecked = [...adminGroups];
      setAdminGroups(newGroupsChecked);
      const finalData = {
        adminId: selectedAdminData?.id,
        adminGroupIds: adminGroups
          .filter((item) => item.checked)
          .map((i) => i._id),
      };
      const response = await editAdminsGroup(finalData);
      setLoading(false);

      setRefreshToken((prev: number) => prev + 1);

      // const finalData = {
      //   adminId: selectedAdminData?.id,
      //   adminGroupIds: [
      //     "659fec17f48b07e9c49fc42f",
      //     "659fec17f48b07e9c49fc432",
      //     "65a4d6f7fe02d526360030d8",
      //   ],
      // };
    }

    setLoading(false);
  };

  const renderAddNewAdminModalBody = () => {
    return (
      <Box>
        <ControlledTextField
          error={!errors.email}
          helperText={errors.email?.message}
          textLabel="Email"
          placeholder="example@gmail.com"
          name="email"
          control={control}
        />
        <div className="mt-2">
          <ControlledComboBox
            className={classes.comboBox}
            multiple
            error={!errors.groups}
            helperText={errors.groups?.message}
            textLabel="Groups"
            options={adminGroups}
            name="groups"
            control={control}
          />
        </div>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenAddNewAdminModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={!watchEmail || loading || !watchGroups?.length}
            className={classes.saveButton}
            onClick={handleSubmit(onAddAdminUser)}
            title="Save"
          />
        </Box>
      </Box>
    );
  };

  const renderDeleteConfirmationModal = () => {
    return (
      <Box>
        <p className={classes.confirmationText}>
          Are you sure you want to delete this user?
        </p>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={deleteLoading}
            className={classes.closeButton}
            onClick={() => setOpenDeleteConfirmationModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={deleteLoading}
            disabled={deleteLoading}
            className={classes.saveButton}
            onClick={() => onDeleteAdminUser(adminUserId)}
            title="Delete"
          />
        </Box>
      </Box>
    );
  };

  const renderBlockReasonModal = () => {
    return (
      <>
        <ControlledTextField
          name="reason"
          control={control}
          textLabel="Reason"
        />

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={blockLoading}
            className={classes.closeButton}
            onClick={() => setOpenBlockReasonModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={blockLoading}
            disabled={blockLoading || !watchReason}
            className={classes.saveButton}
            onClick={() => onBlockAdminUser(adminUserId)}
            title="Block"
          />
        </Box>
      </>
    );
  };

  const renderUnBlockConfirmationModal = () => {
    return (
      <Box>
        <p className={classes.confirmationText}>
          Are you sure you want to remove unblock from this user?
        </p>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={blockLoading}
            className={classes.closeButton}
            onClick={() => setOpenUnBlockConfirmationModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={blockLoading}
            disabled={blockLoading}
            className={classes.saveButton}
            onClick={() => onUnBlockAdminUser(adminUserId)}
            title="Unblock"
          />
        </Box>
      </Box>
    );
  };

  const renderEditGroupsModalBody = () => {
    return (
      <>
        <div className="d-flex flex-column gap-3">
          {adminGroups?.map((group) => {
            return (
              <div key={group._id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={group.checked}
                      onChange={() => onEditAdminsGroups(group)}
                      checked={group.checked}
                      defaultChecked={group.checked}
                    />
                  }
                  label={group.name}
                />
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-end mt-2">
          <AdminButtonComponent
            className={classes.closeButton}
            title="Close"
            onClick={onCloseEditGroupModal}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Admin Users | FruPro Admin Portal</title>
      </Helmet>
      <Sidebar pageTitle="Admin Users">
        {loading && <LoadingIndicator loading />}
        <PermissionWrapper
          permission="ADMIN_USERS_LISTING_VIEW"
          unauthorizedComponent
        >
          <AdminUsersTable
            fetchLoading={fetchLoading}
            onOpenEditGroupModal={onOpenEditGroupModal}
            setOpenUnBlockConfirmationModal={setOpenUnBlockConfirmationModal}
            setOpenBlockReasonModal={setOpenBlockReasonModal}
            setAdminUserId={setAdminUserId}
            setOpenDeleteConfirmationModal={setOpenDeleteConfirmationModal}
            setOpenAddNewAdminModal={setOpenAddNewAdminModal}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            renderSearchTextField={renderSearchTextField()}
            adminUsersData={adminUsersData}
          />
        </PermissionWrapper>
        <AdminModalComponent
          children={renderAddNewAdminModalBody()}
          closeModal={onCloseModal}
          headerTitle="Add new admin user"
          openModal={openAddNewAdminModal}
        />
        <AdminModalComponent
          closeModal={onCloseDeleteConfirmationModal}
          openModal={openDeleteConfirmationModal}
          headerTitle="Are you sure?"
          children={renderDeleteConfirmationModal()}
        />
        <AdminModalComponent
          openModal={openBlockReasonModal}
          headerTitle="Block reason"
          children={renderBlockReasonModal()}
        />
        <AdminModalComponent
          openModal={openUnBlockConfirmationModal}
          headerTitle="Are you sure?"
          closeModal={onCloseUnBlockConfirmationModal}
          children={renderUnBlockConfirmationModal()}
        />
        <AdminModalComponent
          openModal={openEditGroupModal}
          headerTitle="Edit Admin's Groups"
          closeModal={onCloseEditGroupModal}
          children={renderEditGroupsModalBody()}
        />
      </Sidebar>
    </>
  );
};

export default AdminUsersContainer;
