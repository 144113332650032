import { ReactNode } from "react";

interface sidebarTypes {
  href: string;
  icon: ReactNode;
  gotToApp?: boolean;
  title: string;
  function?: boolean;
}

export const sidebarItems = [
  {
    href: "/",
    icon: <i style={{ fontSize: 24 }} className="ri-dashboard-line"></i>,
    title: "Dashboard",
  },
  {
    icon: <i style={{ fontSize: 24 }} className="ri-building-line"></i>,
    title: "Company",
    children: [
      {
        href: "/company-list",
        icon: <i style={{ fontSize: 24 }} className="ri-building-line"></i>,
        title: "Companies",
      },
      {
        href: "/company-limits",
        icon: <i style={{ fontSize: 24 }} className="ri-building-line"></i>,
        title: "Company Limits",
      },
      {
        href: "/company-registration",
        icon: <i style={{ fontSize: 24 }} className="ri-government-line"></i>,
        title: "Company Registration",
      },
      {
        href: "/company-verification-requests",
        icon: <i style={{ fontSize: 24 }} className="ri-hotel-line"></i>,
        title: "Company Verifications",
      },
      {
        href: "/company-certification-requests",
        icon: <i style={{ fontSize: 24 }} className="ri-file-pdf-line"></i>,
        title: "Certificate Requests",
      },
      {
        href: "/anonymous-company-names",
        icon: <i style={{ fontSize: 24 }} className="ri-spy-line"></i>,
        title: "Anonymous Companies",
      },
      {
        href: "/seller-fees",
        icon: (
          <i
            style={{ fontSize: 24 }}
            className="ri-money-pound-circle-line"
          ></i>
        ),
        title: "Seller Fees",
      },
      {
        href: "/company-email-preferences",
        icon: (
          <i
            style={{ fontSize: 24 }}
            className="ri-money-pound-circle-line"
          ></i>
        ),
        title: "Email Preferences",
      },
    ],
  },
  {
    icon: <i style={{ fontSize: 24 }} className="ri-user-2-line"></i>,
    title: "Users",
    children: [
      {
        href: "/admin-users",
        icon: <i style={{ fontSize: 24 }} className="ri-user-2-line"></i>,
        title: "Admin Users",
      },
      {
        href: "/admin-groups",
        icon: <i style={{ fontSize: 24 }} className="ri-group-line"></i>,
        title: "Admin Groups",
      },
      {
        href: "/users",
        icon: <i style={{ fontSize: 24 }} className="ri-group-line"></i>,
        title: "Platform Users",
      },
    ],
  },
  {
    icon: <i style={{ fontSize: 24 }} className="ri-price-tag-3-line"></i>,
    title: "Orders",
    children: [
      {
        href: "/surplus-allocation",
        icon: <i style={{ fontSize: 24 }} className="ri-survey-line"></i>,
        title: "Allocation",
      },
      {
        href: "/tradabase-orders",
        icon: <i style={{ fontSize: 24 }} className="ri-survey-line"></i>,
        title: "Tradabase Orders",
      },
      {
        href: "/additional-cost-types",
        icon: <i style={{ fontSize: 24 }} className="ri-price-tag-3-line"></i>,
        title: "Additional Cost Types",
      },
      // {
      //   href: "/credit-notes",
      //   icon: <i style={{ fontSize: 24 }} className="ri-coupon-line"></i>,
      //   title: "Credit Notes",
      // },
      {
        href: "/download-documents",
        icon: <i style={{ fontSize: 24 }} className="ri-coupon-line"></i>,
        title: "Order Documents",
      },
    ],
  },

  {
    icon: <i style={{ fontSize: 24 }} className="ri-file-cloud-line"></i>,
    title: "Invoices",
    children: [
      // {
      //   href: "/premade-invoices",
      //   icon: <i style={{ fontSize: 24 }} className="ri-coupon-line"></i>,
      //   title: "Premade Invoices",
      // },
      {
        href: "/service-fee-invoices",
        icon: <i className="ri-contract-fill"></i>,
        title: "Service Fee Invoices",
      },
      {
        href: "/document-AI-invoices",
        icon: <i className="ri-contract-fill"></i>,
        title: "Uploaded Invoices",
      },

      {
        href: "/upcoming-due-invoices",
        icon: <i className="ri-contract-fill"></i>,
        title: "Upcoming Due Invoices",
      },
    ],
  },

  {
    href: "/bank-accounts",
    icon: <i style={{ fontSize: 24 }} className="ri-bank-card-line"></i>,
    title: "Bank Accounts",
  },

  {
    icon: <i style={{ fontSize: 24 }} className="ri-file-chart-2-line"></i>,
    title: "Reports",
    children: [
      {
        href: "https://europe-west2-frupro-prod.cloudfunctions.net/ExportReportData?apikey=L85JeFMRnP3RmmQCJEzKafn5GRgc5r7gkobHT5pp",
        icon: <i style={{ fontSize: 24 }} className="ri-survey-line"></i>,
        title: "All Data",
      },
      {
        href: "/remittance-reports",
        icon: <i style={{ fontSize: 24 }} className="ri-survey-line"></i>,
        title: "Remittance Reports",
      },
    ],
  },

  {
    icon: <i style={{ fontSize: 24 }} className="ri-line-chart-line"></i>,
    title: "Analytics",
    children: [
      {
        href: "/uninvoiced-sales-tradabase",
        icon: <i className="ri-contract-fill"></i>,
        title: "Uninvoiced Sales",
      },

      {
        href: "/forecasted-trades",
        icon: <i className="ri-contract-fill"></i>,
        title: "Forecasted Trades",
      },
    ],
  },
  {
    icon: <i style={{ fontSize: 24 }} className="ri-notification-3-line"></i>,
    title: "Notifications",
    children: [
      {
        href: "/push-notifications",
        icon: <i className="ri-contract-fill"></i>,
        title: "Push Notifications",
      },
      {
        href: "/notification-reports",
        icon: <i className="ri-contract-fill"></i>,
        title: "Notifications reports",
      },
    ],
  },
  {
    icon: <i style={{ fontSize: 24 }} className="ri-mail-send-fill"></i>,
    title: "Emails",
    href: "/emails",
  },
  {
    href: "/admin-logs",
    icon: <i style={{ fontSize: 24 }} className="ri-user-settings-line"></i>,
    title: "Admin Logs",
  },

  {
    href: "/profile",
    icon: <i style={{ fontSize: 24 }} className="ri-profile-line"></i>,
    title: "Account",
  },
  {
    href: "/settings",
    icon: <i style={{ fontSize: 24 }} className="ri-settings-2-line"></i>,
    title: "Settings",
  },
  {
    icon: <i style={{ fontSize: 24 }} className="ri-apps-line"></i>,
    title: "Trading App",
    children: [
      {
        href: "/variety-size-unit",
        icon: <i className="ri-contract-fill"></i>,
        title: "Varieties,sizes,units",
      },
      {
        href: "/subproduct-types",
        icon: <i className="ri-contract-fill"></i>,
        title: "Subproduct Types",
      },
      {
        goToApp: true,
        href: "/",
        icon: <i className="ri-contract-fill"></i>,
        title: "Go to app",
      },
    ],
  },
  {
    function: true,
    icon: <i style={{ fontSize: 24 }} className="ri-logout-circle-r-line"></i>,
    title: "Logout",
  },
];
