import { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import {
  getAnonymousCompanies,
  updateAnonymousCompany,
} from "../../services/anonymous-companies";
import LoadingIndicator from "../../components/LoadingIndicator";
import TableComponent from "../../components/TableComponent";
import { makeStyles } from "@mui/styles";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import AdminModalComponent from "../../components/AdminModalComponent";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import ControlledTextField from "../../components/ControlledTextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";

const schema = yup.object().shape({
  anonCompanyName: yup.string(),
  anonCompanyLocation: yup.string(),
});

const columns = [
  { name: "Company Name", id: "compName" },
  { name: "Anonymous Name", id: "anonyName" },
  { name: "Anonymous Location", id: "anonyLoc" },
  { name: "", id: "action" },
];

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  edit: {
    fontSize: 26,
    color: "#E77228",
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  ModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
});

export interface DataStateType {
  anonName: string;
  anonLocation: string;
  _id: string;
  companyId: string;
  companyName: string;
  id: string;
}

const AnonymousCompaniesContainer = () => {
  const { control, watch, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      anonCompanyName: "",
      anonCompanyLocation: "",
    },
  });
  const { hasPermission } = usePermissions();

  const watchCompanyName = watch("anonCompanyName");
  const watchLocation = watch("anonCompanyLocation");

  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [selectedData, setSelectedData] = useState<DataStateType>();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [anonymousData, setAnonymousData] = useState<DataStateType[]>([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  const onOpenEditModal = (row: DataStateType) => {
    setOpenEditModal(true);
    setSelectedData(row);
  };
  const onCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const onUpdateData = async () => {
    setLoading(true);
    const data = {
      companyId: selectedData?.companyId,
      anonName: watchCompanyName,
      anonLocation: watchLocation,
    };
    const updated = await updateAnonymousCompany(data);
    setLoading(false);
    if (updated) {
      reset({});
      setRefresh(() => refresh + 1);
      setOpenEditModal(false);
      setSelectedData(undefined);
    }
  };

  useEffect(() => {
    if (selectedData?._id) {
      reset({
        anonCompanyName: selectedData.anonName,
        anonCompanyLocation: selectedData.anonLocation,
      });
    } else {
      reset({});
    }
  }, [selectedData?._id]);

  useEffect(() => {
    if(!hasPermission("COMPANY_VIEW_DETAILS"))return
    const getAnonymousData = async () => {
      setFetchLoading(true);
      const response = await getAnonymousCompanies(queryParams.page);
      setFetchLoading(false);

      if (response) {
        setAnonymousData(response.items);
        setQueryParams({
          page: parseFloat(response?.metaData?.page),
          total: response?.metaData?.total,
          totalPage: response?.metaData?.totalPage,
          limit: response?.metaData?.limit,
        });
      }
    };
    getAnonymousData();
  }, [queryParams.page, refresh,hasPermission("COMPANY_VIEW_DETAILS")]);

  const renderTableBody = () => {
    return (
      <>
        <Helmet>
          <title>Anonymous Companies | FruPro Admin Portal</title>
        </Helmet>
        {anonymousData.map((data, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {data.companyName}
              </TableCell>
              <TableCell component="th" scope="row">
                {data.anonName}
              </TableCell>
              <TableCell component="th" scope="row">
                {data.anonLocation}
              </TableCell>
              <TableCell component="th" scope="row">
                <Tooltip title="Edit anonymous data">
                  <IconButton onClick={() => onOpenEditModal(data)}>
                    <i className={`ri-pencil-line   ${classes.edit}`}></i>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderModalBody = () => {
    return (
      <>
        <div className="row g-3">
          <ControlledTextField
            textLabel="Anonymous Company Name"
            control={control}
            name="anonCompanyName"
          />
          <ControlledTextField
            textLabel="Anonymous Location"
            control={control}
            name="anonCompanyLocation"
          />
        </div>
        <div className={classes.ModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={onCloseEditModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            className={classes.saveButton}
            onClick={onUpdateData}
            title="Update"
          />
        </div>
      </>
    );
  };

  return (
    <Sidebar pageTitle="Anonymous Company Names">
      <LoadingIndicator loading={loading} />
      <PermissionWrapper
        permission="COMPANY_VIEW_DETAILS"
        unauthorizedComponent={true}
      >
        <TableComponent
        loading={fetchLoading}
          columns={columns}
          queryParams={queryParams}
          showRightButton={false}
          setQueryParams={setQueryParams}
          TableBodyComponent={renderTableBody}
        />
      </PermissionWrapper>

      <AdminModalComponent
        children={renderModalBody()}
        headerTitle="Edit anonymous data"
        openModal={openEditModal}
        closeModal={onCloseEditModal}
      />
    </Sidebar>
  );
};

export default AnonymousCompaniesContainer;
