import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Permission, PermissionNameType } from "../permissions";

export interface UserInfoStateType {
  createdAt: string;
  deleted: boolean;
  email: string;
  firstName: string;
  lastName: string;
  id: string;
  fullName: string;
  phoneNumber: string;
  photo: string;
  role: string;
  secret: string;
  status: string;
  updatedAt: string;
  _2fa: boolean;
  permissions: PermissionNameType[];
}

export interface CountriesStateType {
  geonameId: number;
  continent: string;
  continentName: string;
  countryCode: string;
  countryName: string;
  phone: string;
  id: string;
}

export interface Currency {
  name: string;
  symbol: string;
  createdAt: string;
  updatedAt: string;
  code: string;
  status: number;
  id: string;
}

const initialState = {
  adminLoading: true,
  adminUserInfo: {
    createdAt: "",
    deleted: false,
    email: "",
    firstName: "",
    lastName: "",
    id: "",
    fullName: "",
    phoneNumber: "",
    photo: "",
    role: "",
    secret: "",
    status: "",
    updatedAt: "",
    _2fa: false,
    permissions: [],
  } as UserInfoStateType,
  countries: [] as CountriesStateType[],
  currencies: [] as Currency[],
  permissions: [] as Permission[],
  tradeAppLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTradeAppLoading: (state, action: PayloadAction<boolean>) => {
      state.tradeAppLoading = action.payload;
    },
    setAdminUserInfo: (state, action: PayloadAction<UserInfoStateType>) => {
      state.adminUserInfo = action.payload;
    },
    insertAdminUserInfo: (state, action: PayloadAction<UserInfoStateType>) => {
      state.adminUserInfo = {
        ...state.adminUserInfo,
        ...action.payload,
      };
    },
    setCountries: (state, action: PayloadAction<CountriesStateType[]>) => {
      !state.countries.length && state.countries.push(...action.payload);
    },
    setCurrencies: (state, action: PayloadAction<Currency[]>) => {
      !state.countries.length && state.currencies.push(...action.payload);
    },
    logout: (state, action: PayloadAction) => {
      state.adminUserInfo = {
        createdAt: "",
        deleted: false,
        email: "",
        firstName: "",
        lastName: "",
        id: "",
        fullName: "",
        phoneNumber: "",
        photo: "",
        role: "",
        secret: "",
        status: "",
        updatedAt: "",
        _2fa: false,
        permissions: [],
      };
    },
    setAdminInfoLoading: (state, action: PayloadAction<boolean>) => {
      state.adminLoading = action.payload;
    },
    setPermissions: (state, action: PayloadAction<Permission[]>) => {
      state.permissions = action.payload;
    },
  },
});

export default authSlice.reducer;

export const {
  setTradeAppLoading,
  setAdminUserInfo,
  logout,
  setCountries,
  setCurrencies,
  setAdminInfoLoading,
  setPermissions,
  insertAdminUserInfo,
} = authSlice.actions;
