import { useEffect, useMemo, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import {
  addBankAccount,
  deleteBankDetails,
  getBankAccounts,
  getBankSelections,
  getBuyersSellers,
} from "../../services/bank-accounts";
import LoadingIndicator from "../../components/LoadingIndicator";
import { BankAccountsState, BankDetail, BankSelections } from "./interfaces";
import { makeStyles } from "@mui/styles";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledComboBox from "../../components/ControlledComboBox";
import AdminModalComponent from "../../components/AdminModalComponent";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import AccountCardItem from "./AccountCardItem";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const useStyles = makeStyles({
  accountCardStyle: {
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    borderRadius: "5px!important",
    background: "white!important",
    width: "100%",
    minHeight: "100%",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  trash: {
    marginRight: 15,
    fontSize: 22,
    cursor: "pointer",
    color: "red",
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  ModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  deleteButton: {
    width: "50%",
    height: "50px",
    background: "red !important",
    borderRadius: "20px !important",
  },

  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },

  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  iconBox: {
    gap: 30,
    display: "flex",
    justifyContent: "flex-end",
  },
  boldText: {
    fontWeight: 600,
  },
  edit: {
    fontSize: 26,
    color: "#E77228",
  },
  delete: {
    fontSize: 26,
    color: "red",
  },
  colsCell: {
    fontWeight: "600 !important",
    fontSize: "16px !important",
  },
  accountCard: {
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    borderRadius: "5px!important",
    background: "white!important",
    width: "100%",
    minHeight: "100%",
  },
  filtersBox: {
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    marginLeft: 0.1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    padding: 10,
    borderRadius: "5px!important",
    background: "white!important",
    width: "100%",
  },
  hr: {
    opacity: 0.08,
  },
  accountNameText: {
    color: "#5F8CBA",
  },
});

interface BuyerSellersState {
  name: string;
  _id: string;
}

const schema = yup.object().shape({
  sellerCompany: yup.object(),
  buyerCompany: yup.object(),
  seller: yup.object().shape({
    name: yup.string(),
    id: yup.string(),
  }),
  buyer: yup.object().shape({
    name: yup.string(),
    id: yup.string(),
  }),
  bankAccountId: yup.object().shape({
    name: yup.string(),
    id: yup.string(),
  }),
});

const BankAccountsContainer = () => {
  const { hasPermission } = usePermissions();
  const { control, watch, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      seller: {
        name: "",
        id: "",
      },
      buyer: {
        name: "",
        id: "",
      },
      bankAccountId: {
        name: "",
        id: "",
      },
    },
  });

  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    totalPage: 1,
    total: 1,
    limit: 1,
  });

  const [accounts, setAccounts] = useState<BankAccountsState[]>([]);
  const [selections, setSelections] = useState<BankSelections[]>([]);
  const [sellers, setSellers] = useState<BuyerSellersState[]>([]);
  const [selectedBankId, setSelectedBankId] = useState<string>("");
  const [selectedBankObjectId, setSelectedBankObjectId] = useState<string>("");
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [buyers, setBuyers] = useState<BuyerSellersState[]>([]);
  const [filterData, setFilterData] = useState({});

  const [refreshRequest, setRefreshRequest] = useState<number>(0);

  const watchSeller: any = watch("seller.id");
  const watchBuyer: any = watch("buyer.id");

  const watchSellerCompany: any = watch("sellerCompany");
  const watchBuyerCompany: any = watch("buyerCompany");
  const watchBankAccountId: any = watch("bankAccountId.id");

  let allAccounts: BankDetail[] = [];

  accounts.map((i) =>
    i.bankDetails.map((item: BankDetail) => {
      return allAccounts.push({
        ...item,
        seller: i.sellerId?.companyName,
        buyer: i?.buyerId?.companyName,
        bankInfoId:i?._id
      });
    })
  );

  const newSelections = useMemo(() => {
    return selections.map((item: BankSelections) => ({
      id: item._id,
      name: item.bankDetails.title,
    }));
  }, [selections.length]);

  const onOpenDeleteConfirmModal = (
    selected: string,
    selectedBankObject: string
  ) => {
    setOpenConfirmModal(true);
    setSelectedBankId(selected);
    setSelectedBankObjectId(selectedBankObject);
  };
  const onOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const onCloseDeleteConfirmModal = () => {
    setOpenConfirmModal(false);
    setSelectedBankId("");
  };

  const onCloseAddModal = () => {
    setOpenAddModal(false);
  };

  useEffect(() => {
    if (!hasPermission("BANK_ACCOUNTS_LISTING_VIEW")) return;

    const getAccounts = async () => {
      setFetchLoading(true);
      const account = await getBankAccounts({
        sellerCompanyId: (watchSellerCompany as any)?.id
          ? (watchSellerCompany as any)?.id
          : undefined,
        buyerCompanyId: (watchBuyerCompany as any)?.id
          ? (watchBuyerCompany as any)?.id
          : undefined,
      });
      const sellers = await getBuyersSellers("seller");
      const buyers = await getBuyersSellers("buyer");
      const bankSelections = await getBankSelections();

      setFetchLoading(false);
      if (account) {
        setAccounts(account.bankInfos);
        setQueryParams({
          page: account?.pagination?.page,
          total: account?.pagination?.totalDoc,
          totalPage: account?.pagination?.totalPage,
          limit: 20,
        });
      }
      if (bankSelections) {
        setSelections(bankSelections.companyBankDetails);
      }
      setSellers(
        sellers.data.map((item: any) => ({
          id: item?._id,
          name: item?.companyName,
        }))
      );
      setBuyers(
        buyers.data.map((item: any) => ({
          id: item?._id,
          name: item?.companyName,
        }))
      );
    };
    getAccounts();
  }, [
    watchBuyerCompany,
    watchSellerCompany,
    queryParams.page,
    refreshRequest,
    watchBuyerCompany?.id,
    watchSellerCompany?.id,
    hasPermission("BANK_ACCOUNTS_LISTING_VIEW"),
  ]);

  // const onFilter = () => {
  //   setFilterData({
  //     sellerCompanyId: (watchSellerCompany as any)?.id
  //       ? (watchSellerCompany as any)?.id
  //       : undefined,
  //     buyerCompanyId: (watchBuyerCompany as any)?.id
  //       ? (watchBuyerCompany as any)?.id
  //       : undefined,
  //   });
  // };
  const onAddNewAccount = async () => {
    if (!watchSeller?.id) {
      toast.error("Seller is required");
    } else if (!watchBuyer?.id) {
      toast.error("Buyer is required");
    } else if (!watchBankAccountId?.id) {
      toast.error("Bank account is required");
    } else {
      setLoading(true);
      const data = {
        sellerCompanyId: watchSeller?.id,
        buyerCompanyId: watchBuyer?.id,
        companyBankSelectionId: watchBankAccountId?.id,
      };
      await addBankAccount(data);
      setLoading(false);
      setOpenAddModal(false);
      setRefreshRequest(() => refreshRequest + 1);
      reset({});
    }
  };

  const onDeleteAccount = async () => {
    setLoading(true);

    const data = {
      bankInfoId: selectedBankId,
      bankInfoDetailObjectId: selectedBankObjectId,
    };
    await deleteBankDetails(data);
    setLoading(false);
    setOpenConfirmModal(false);
    setRefreshRequest((prev) => prev + 1);
  };

  const renderAddNewAccountModalBody = () => {
    return (
      <>
        <div className="row g-3">
          <ControlledComboBox
            className={classes.comboBox}
            textLabel="Seller"
            options={sellers}
            name="seller.id"
            control={control}
          />

          <ControlledComboBox
            className={classes.comboBox}
            textLabel="Buyer"
            options={buyers}
            name="buyer.id"
            control={control}
          />

          <ControlledComboBox
            className={classes.comboBox}
            textLabel="Bank Account"
            options={newSelections}
            name="bankAccountId.id"
            control={control}
          />
        </div>
        <div className={classes.ModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={onCloseAddModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            className={classes.saveButton}
            onClick={onAddNewAccount}
            title="Submit"
          />
        </div>
      </>
    );
  };

  const renderDeleteModalBody = () => {
    return (
      <>
        <span>Are you sure you want to delete this account?</span>

        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={onCloseDeleteConfirmModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            className={classes.deleteButton}
            onClick={onDeleteAccount}
            title="Delete"
          />
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Bank Accounts | FruPro Admin Portal</title>
      </Helmet>
      <Sidebar
        renderButton={
          <PermissionWrapper permission="BANK_ACCOUNTS_ADD">
            <AdminButtonComponent
              onClick={onOpenAddModal}
              title=" + Add account"
            />
          </PermissionWrapper>
        }
        pageTitle="Bank Accounts"
      >
        {loading && <LoadingIndicator loading />}

        <PermissionWrapper
          permission="BANK_ACCOUNTS_LISTING_VIEW"
          unauthorizedComponent
        >
          <div className={`${classes.filtersBox} mt-2  row`}>
            <div className="col-md-1">
              <span className="fw-bold ">Filters : </span>
            </div>
            <div className="col-md-3">
              <ControlledComboBox
                className={classes.comboBox}
                textLabel=""
                placeholder="Seller"
                name="sellerCompany"
                options={sellers}
                control={control}
              />
            </div>
            <div className="col-md-3">
              <ControlledComboBox
                className={classes.comboBox}
                textLabel=""
                placeholder="Buyer"
                name="buyerCompany"
                options={buyers}
                control={control}
              />
            </div>
          </div>

          <div className="container">
            <div className="row g-3 mt-1">
              {fetchLoading ? (
                <>
                  {Array.from({ length: 6 }).map((item, i) => {
                    return (
                      <div key={i} className="col-4">
                        <Skeleton count={1} height={350} />
                      </div>
                    );
                  })}
                </>
              ) : (
                allAccounts.map((account) => {
                  return (
                    <div className="col-md-4">
                      <AccountCardItem
                        onOpenDeleteConfirmModal={onOpenDeleteConfirmModal}
                        account={account}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </PermissionWrapper>

        <AdminModalComponent
          headerTitle="Are you sure?"
          children={renderDeleteModalBody()}
          openModal={openConfirmModal}
          closeModal={onCloseDeleteConfirmModal}
        />
        <AdminModalComponent
          headerTitle="Add new bank account"
          children={renderAddNewAccountModalBody()}
          openModal={openAddModal}
          closeModal={onCloseAddModal}
        />
      </Sidebar>
    </>
  );
};

export default BankAccountsContainer;
