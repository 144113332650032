import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  boldText: {
    fontWeight: 600,
  },
});

interface PropsType {
  companyStatus: string;
}
const ApproveReqTexts: React.FC<PropsType> = ({ companyStatus }) => {
  const classes = useStyles();
  return (
    <div>
      {companyStatus === "View Only" ? (
        <>
          <p className={classes.boldText}>Confirmed status access right:</p>
          <p>
            This company has been approved onto the platform by a FruPro admin
            from the Admin Panel but they will still have limited access:
          </p>
          <p>
            1.<span className={classes.boldText}>NOT</span> be able to make an
            offer for other product listings on the platform
          </p>
          <p>
            2.<span className={classes.boldText}>NOT</span> be able to receive
            any offers for any product listings they have created on the
            platform
          </p>
          <p>3.Able to create product listings</p>
          <p>
            4.Able to message other users on the platform including having a
            company group chat
          </p>
          <p>6.Able to add colleagues to their company</p>
          <p>
            7.<span className={classes.boldText}>NOT</span> be able to see other
            product listings on the platform’s pricing
          </p>
          <p>
            8.The product listings created by these users will{" "}
            <span className={classes.boldText}>NOT</span> show the pricing from
            their product listings, the product listings they have created will
            have no pricing shown to other users of the platform, it will be
            blank to other users
          </p>
        </>
      ) : (
        <>
          <p className={classes.boldText}>Verified status access right:</p>

          <p>
            This company type has been fully verified by the commercial team
            performing KYC and KYB checks on these users and have received all
            documentation needed from these users, these users will have full
            access to the platform as users have now:
          </p>

          <p>
            1.Users of this company will have full access to the platform as
            they do right now
          </p>
          <p>2.Users of this company can make offers</p>
          <p>3.Users of this company can receive offers etc.</p>
          <p>
            4.When these companies' users create product listings, their product
            listings will show a blue tick verification similar to Instagram or
            Twitter to show that these companies have been verified
          </p>
        </>
      )}
    </div>
  );
};

export default ApproveReqTexts;
