import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import ControlledTextField from "./ControlledTextField";
import { ControlledTextFieldTypes } from "./interfaces/ComponentInterfaces";
const useStyles = makeStyles({
  searchButton: {
    borderRadius: "100% !important",
    width: "50px !important",
    cursor: "pointer",
    height: "50px !important",
    background: "#E77228 !important",
    display: "flex",
    justifyContent: "center",
    color: "white",
    fontSize: 18,
    alignItems: "center",
  },
  searchField: {
    display: "flex",
    gap: 10,
  },
});
const SearchTextField: React.FC<ControlledTextFieldTypes> = ({
  control,
  name,
  onKeyPress,
  onSearchClick,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.searchField}>
      <ControlledTextField
      onKeyPress={(e)=>{
        if(e.key==="Enter"){
        onKeyPress&&  onKeyPress(e)
        }
      }}
        placeholder="Search.."
        name={name}
        control={control}
      />
      <div  onClick={onSearchClick} className={classes.searchButton}>
        <i className="ri-search-line"></i>
      </div>
    </Box>
  );
};

export default SearchTextField;
