import React, {
  forwardRef,
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Sidebar } from "../../components/Sidebar";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ControlledTextField from "../../components/ControlledTextField";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import ControlledTextArea from "../../components/ControlledTextArea";
import ControlledDateField from "../../components/ControlledDateField";
import {
  getCompanyUsers,
  getOfferInvoiceInfoFromCodes,
} from "../../services/common";
import CompanyItem from "./CompanyItem";
import { sendPushNotification } from "../../services/notification";
import { toast } from "react-toastify";
import PermissionWrapper from "../../components/PermissionWrapper";
import { Checkbox, FormControlLabel, Pagination } from "@mui/material";
import SkeletonLoading from "../../components/SkeletonLoading";
import dayjs from "dayjs";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Virtuoso } from "react-virtuoso";

const schema = yup.object().shape({
  filterText: yup.string(),
  orderCode: yup.string(),
  invoiceCode: yup.string(),
  notificationCode: yup.string(),
  orderCodeDocument: yup.string(),
  notificationTitle: yup.string(),
  notificationMessage: yup.string(),
  notificationContent: yup.string(),
  notificationDate: yup.date().nullable(),
});

export interface SelectedCompanies {
  _id: string;
  companyName: string;
  users: { _id: string; fullName: string }[];
}

export interface CompanyUsers {
  _id: string;
  companyName: string;
  createdAt: string;
  users: User[];
}

export interface User {
  _id: string;
  fullName: string;
}

interface AddedLineItemState {
  name: string;
  id: string;
  offerODRCode: string;
  paymentCode: string;
  type: string;
}

interface GridComponentsProps {
  style?: CSSProperties;
  children?: React.ReactNode;
}

interface ItemProps extends GridComponentsProps {}

const gridComponents = {
  List: forwardRef<HTMLDivElement, GridComponentsProps>(
    ({ style, children, ...props }, ref) => (
      <div ref={ref} {...props} className="row">
        {children}
      </div>
    )
  ),
  Item: ({ children, ...props }: ItemProps) => (
    <div {...props} className="col-md-6">
      {children}
    </div>
  ),
};

const useStyles = makeStyles({
  pagination: {
    marginTop:20,
    display: "flex",
    justifyContent: "center",
    padding: 20,
  },
  mainRoot: {
    width: "100%",
    padding: 20,
    background: "white",
    borderRadius: 20,
  },
  filterButton: {
    width: "100%",
  },
  clearButton: {
    width: "100%",
    background: "#69CDAE!important",
  },
  opacity: {
    opacity: 0.08,
    marginTop: 30,
  },
  companyItem: {
    padding: 20,
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    borderRadius: "5px!important",
    background: "#f8f8f8!important",
    width: "100%",
  },
  companiesText: {
    fontWeight: 600,
    color: "#E77228",
  },
});

const PushNotificationsContainer = () => {
  const { control, watch, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      filterText: "",
      notificationContent: "",
      orderCode: "",
      notificationCode: "",
      invoiceCode: "",
      orderCodeDocument: "",
      notificationMessage: "",
      notificationDate: dayjs(new Date() as any) as any,
    },
  });
  const classes = useStyles();

  const [selectedCompanies, setSelectedCompanies] = useState<
    SelectedCompanies[]
  >([]);

  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  const [addedLineItems, setAddedLineItems] = useState<AddedLineItemState[]>(
    []
  );

  const [selectedCodeType, setSelectedCodeType] = useState("ORDER");
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [companyUsersData, setCompanyUsersData] = useState<CompanyUsers[]>([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [findIdCodeLoading, setFindIdCodeLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setFetchLoading(true);
      const fetched = await getCompanyUsers("",queryParams.page);
      setFetchLoading(false);

      if (fetched?.success) {
        setQueryParams({
          page:fetched?.pagination?.page,
          total:fetched?.pagination?.total,
          totalPage:fetched?.pagination?.totalPage,
          limit:fetched?.pagination?.page
        })
        setCompanyUsersData(fetched?.data);
      }
    };

    getData();
  }, [queryParams.page]);

  const onFilter = useCallback(async () => {
    setFilterLoading(true);
    const fetched = await getCompanyUsers(watch("filterText") || "",1);
    setFilterLoading(false);
    if (fetched?.success) {
      // setQueryParams({
      //   page:fetched?.pagination?.page,
      //   total:fetched?.pagination?.page,
      //   totalPage:fetched?.pagination?.page,
      //   limit:fetched?.pagination?.page
      // })
      setSelectedCompanies([]);
      setSelectedUsers([]);
      setCompanyUsersData(fetched?.data);
    }
  }, [watch("filterText")]);

  const onChangeOrderChecbox = useCallback((e: boolean, i: string) => {
    if (e) {
      setSelectedCodeType(i);
    } else {
      setSelectedCodeType("");
      setAddedLineItems([]);
    }
  }, []);

  const onClearFilter = useCallback(async () => {
    setValue("filterText", "");
  }, [watch("filterText")]);

  const onAddLineItem = useCallback(async () => {
    if (!watch("notificationCode")) {
      toast.error("Please type a code");
    } else if (!selectedCodeType) {
      toast.error(
        "Please select one of type(document,order,invoice) to add code"
      );
    } else {
      setFindIdCodeLoading(true);
      const finalData = {
        codeType: "ORDER",
        code: watch("notificationCode"),
      };
      const codeRes = await getOfferInvoiceInfoFromCodes(finalData);
      setFindIdCodeLoading(false);
      if (codeRes?.success) {
        setAddedLineItems((prev: any) => [
          ...prev,
          {
            offerODRCode: codeRes?.data?.offerODRCode,
            paymentCode: codeRes?.data?.paymentCode,
            name: codeRes?.data?.offerODRCode,
            type: selectedCodeType,
            id: codeRes?.data?.offerId,
          },
        ]);
        setValue("notificationCode", "");
      }
    }
  }, [watch("notificationCode"), selectedCodeType]);

  const onSendNotification = useCallback(async () => {
    if (!watch("notificationTitle")) {
      toast.error("Notification title is not allow to be empty");
    } else if (!watch("notificationMessage")) {
      toast.error("Notification message is not allow to be empty");
    } else if (!watch("notificationDate")) {
      toast.error("Notification date is not allow to be empty");
    } else if (!selectedUsers.length) {
      toast.error("Please select users to send notification");
    } else if (selectedCodeType === "ORDER" && !addedLineItems.length) {
      toast.error(
        "You selected order checbox but didn't enter any code please check."
      );
    } else {
      setSendLoading(true);
      const data = {
        userIds: selectedUsers.map((i) => i._id),
        notificationTitle: watch("notificationTitle"),
        deliveryDate: dayjs(watch("notificationDate")).format("YYYY-MM-DD"),
        message: watch("notificationMessage"),
        notificationData: {
          content: watch("notificationContent"),
          notificationDirections:
            selectedCodeType === "ORDER"
              ? addedLineItems.map((i) => ({
                  idType: i.type,
                  offerODRCode: i.offerODRCode,
                  paymentCode: i.paymentCode,
                  id: i.id,
                }))
              : undefined,
        },
      };
      const sent = await sendPushNotification(data);
      setSendLoading(false);

      if (sent?.success) {
        toast.success("Notification sent successfully");
        reset();
        setSelectedCodeType("");
        setAddedLineItems([]);
        setSelectedCompanies([]);
        setSelectedUsers([]);
      } else {
        toast.error("Notification could not send,please try again!");
      }
    }
  }, [
    selectedCodeType,
    addedLineItems?.length,
    watch("notificationContent"),
    selectedUsers.length,
    watch("notificationTitle"),
    watch("notificationMessage"),
    watch("notificationDate"),
  ]);

  const onRemoveAddedLineItem = useCallback((selectedIndex: number) => {
    setAddedLineItems((prev: any) =>
      prev?.filter((i: any, index: number) => index !== selectedIndex)
    );
  }, []);
  return (
    <Sidebar pageTitle="Push Notifications">
      <Helmet>
        <title>Push Notifications | FruPro Admin Portal</title>
      </Helmet>
      <PermissionWrapper unauthorizedComponent permission="PUSH_NOTIFICATIONS">
        <div className="container">
          <div className="row">
            <div className={classes.mainRoot}>
              <div className="row mt-3">
                <div className="col-md-8">
                  <ControlledTextArea
                    minRows={1}
                    multiline
                    maxCharacter={48}
                    textLabel="Notification Title(Max 48 characters)"
                    control={control}
                    name="notificationTitle"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-8">
                  <ControlledTextArea
                    minRows={3}
                    multiline
                    maxCharacter={170}
                    textLabel="Notification Message(Max 170 characters)"
                    control={control}
                    name="notificationMessage"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-8">
                  <ControlledTextArea
                    addResizer
                    minRows={6}
                    multiline
                    textLabel="Notification Content"
                    control={control}
                    name="notificationContent"
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-8">
                  <ControlledDateField
                    textLabel="Notification Delivery Date"
                    control={control}
                    name="notificationDate"
                  />
                </div>
              </div>
              <hr className={classes.opacity} />
              <div className="mt-2">
                {["ORDER"].map((i, index) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={selectedCodeType === i}
                          onChange={(e) =>
                            onChangeOrderChecbox(e.target.checked, i)
                          }
                          checked={selectedCodeType === i}
                        />
                      }
                      label={i}
                    />
                  );
                })}
              </div>
              {selectedCodeType === "ORDER" ? (
                <div className="mt-3 row d-flex flex-row gap-2 align-items-center">
                  <div className="col-md-8">
                    <ControlledTextField
                      textLabel=""
                      placeholder={
                        "Find order from order code,invoice code,payment code"
                      }
                      control={control}
                      name="notificationCode"
                    />
                  </div>
                  <div className="col-md-3">
                    <AdminButtonComponent
                      disabled={findIdCodeLoading}
                      loading={findIdCodeLoading}
                      style={{ minWidth: 200 }}
                      onClick={onAddLineItem}
                      title="Add code"
                    />
                  </div>
                </div>
              ) : null}
              <div className="mt-3 ml-2 row d-flex flex-column gap-2">
                {addedLineItems.map((li, index) => {
                  return (
                    <div
                      className="d-flex flex-row align-items-center gap-2"
                      key={index}
                    >
                      <span>{li.name}</span>
                      <img
                        width={12}
                        height={12}
                        className="cursor-pointer"
                        onClick={() => onRemoveAddedLineItem(index)}
                        src="/static/svg/ic_close_dialog.svg"
                      />
                    </div>
                  );
                })}
              </div>
              {/* <div className="mt-3 row d-flex flex-row gap-2 align-items-center">
                <div className="col-md-8">
                  <ControlledTextField
                    textLabel=""
                    placeholder="Invoice code"
                    control={control}
                    name="invoiceCode"
                  />
                </div>
                <div className="col-md-3">
                  <AdminButtonComponent
                    style={{ minWidth: 200 }}
                    onClick={() => null}
                    title="Add invoice"
                  />
                </div>
              </div>
              <div className="mt-3 row d-flex flex-row gap-2 align-items-center">
                <div className="col-md-8">
                  <ControlledTextField
                    textLabel=""
                    placeholder="Order code for document"
                    control={control}
                    name="orderCodeDocument"
                  />
                </div>
                <div className="col-md-3">
                  <AdminButtonComponent
                    style={{ minWidth: 200 }}
                    onClick={() => null}
                    title="Add order document"
                  />
                </div>
              </div> */}

              <hr className={classes.opacity} />
              <div className="row mt-3 g-3 d-flex align-items-center">
                <div className="col-md-8">
                  <ControlledTextField
                    placeholder="Filter company,name,surname,email"
                    control={control}
                    name="filterText"
                  />
                </div>
                <div className="col-md-2">
                  <AdminButtonComponent
                    loading={filterLoading}
                    disabled={filterLoading}
                    className={classes.filterButton}
                    onClick={onFilter}
                    title="Filter"
                  />
                </div>
                <div className="col-md-2">
                  <AdminButtonComponent
                    disabled={filterLoading}
                    className={classes.clearButton}
                    onClick={onClearFilter}
                    title="Clear"
                  />
                </div>
              </div>

              <div className="mt-4">
                <span className={classes.companiesText}>Companies : </span>
              </div>
              <div className="row mt-2 g-3">
                {fetchLoading || filterLoading ? (
                  <SkeletonLoading />
                ) : (
                
                  companyUsersData.map((user, index) => {
                    return (
                      <CompanyItem
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        selecteds={selectedCompanies}
                        setSelecteds={setSelectedCompanies}
                        data={user}
                        index={index}
                      />
                    );
                  })
                )}
              </div>
              <div className={classes.pagination}>
                <Pagination
                  onChange={(event: React.ChangeEvent<unknown>, page) =>
                    setQueryParams({
                      page: page,
                      limit: queryParams.limit,
                      total: queryParams.total,
                      totalPage: queryParams.totalPage,
                    })
                  }
                  count={queryParams.totalPage}
                  page={queryParams.page}
                />
              </div>
              <div className="d-flex justify-content-end mt-2 p-1">
                <AdminButtonComponent
                  loading={sendLoading}
                  disabled={sendLoading}
                  onClick={onSendNotification}
                  title="Send"
                />
              </div>
            </div>
          </div>
        </div>
      </PermissionWrapper>
    </Sidebar>
  );
};

export default PushNotificationsContainer;
