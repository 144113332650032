import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getCostTypes = async () => {
  try {
    const response = await axiosApiInstance.get(`/additional-cost`);
    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const addCostTypes = async (data: any) => {
  try {
    const response = await axiosApiInstance.post("/additional-cost", {
      ...data,
    });
    if (response) {
      toast.success("Cost type is added successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const editCostTypes = async (id: string, data: any) => {
  try {
    const response = await axiosApiInstance.put(`/additional-cost/${id}`, {
      ...data,
    });
    if (response) {
      toast.success("Cost type is updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const deleteCostTypes = async (id: string) => {
  try {
    const response = await axiosApiInstance.delete(`/additional-cost/${id}`);
    if (response) {
      toast.success("Cost type is deleted successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
