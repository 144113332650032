import React from "react";
import { makeStyles } from "@mui/styles";
import SendEmailCard from "./SendEmailCard";

const useStyles = makeStyles({
  background: {
    backgroundImage: "url(./static/svg/orange_main_blur.svg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    backgroundPosition: "center",
    height: "100% !important",
    width: "100%",
  },
  cardPosition: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

const ForgotPassword: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.background}>
      <div className={classes.cardPosition}><SendEmailCard/></div>
    </div>
  );
};

export default ForgotPassword;
