import Login from "./pages/Login/Login";
import CreatePasswordContainer from "./pages/CreatePassword/CreatePasswordContainer";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Main from "./pages/Dashboard/Main";
import AdminUsersContainer from "./pages/AdminUsers/AdminUsersContainer";
import AdminGroupsContainer from "./pages/AdminGroups/AdminGroupsContainer";
import CompanyListContainer from "./pages/CompanyList/CompanyListContainer";
import ProduceTypesContainer from "./pages/ProduceTypes/ProduceTypesContainer";
import SellerFeeContainer from "./pages/SellerFees/SellerFeeContainer";
import AdminProfileContainer from "./pages/AdminProfile/AdminProfileContainer";
import CurrenciesContainer from "./pages/Currencies/CurrenciesContainer";
import BrandsContainer from "./pages/Brands/BrandsContainer";
import DonateCentersContainer from "./pages/DonateEmails/DonateCentersContainer";
import DonateEmailsContainer from "./pages/DonateEmails/DonateEmailsContainer";
import SettingsContainer from "./pages/Settings/SettingsContainer";
import UsersContainer from "./pages/Users/UsersContainer";
import SurplusOrderDetailContainer from "./pages/SurplusAllocation/OrderTable/SurplusOrderDetails/SurplusOrderDetailContainer";
import CompanyRegistrationContainer from "./pages/CompanyRegistration/CompanyRegistrationContainer";
import CompanyDetailsContainer from "./pages/CompanyList/CompanyDetails/CompanyDetailsContainer";
import VerificationRequestContainer from "./pages/CompanyVerificationRequests/VerificationRequestContainer";
import AdditionalCostsContainer from "./pages/AdditionalCosts/AdditionalCostsContainer";
import BankAccountsContainer from "./pages/BankAccounts/BankAccountsContainer";
import CreditNotesContainer from "./pages/CreditNotes/CreditNotesContainer";
import CertificationRequestsContainer from "./pages/CertificationRequests/CertificationRequestsContainer";
import AnonymousCompaniesContainer from "./pages/AnonymousCompanies/AnonymousCompaniesContainer";
import AddFruPaySellerContainer from "./pages/AddFruPaySeller/AddFruPaySellerContainer";
import AddFruPayBuyerContainer from "./pages/AddFruPayBuyer/AddFruPayBuyerContainer";
import AddNewCompanyContainer from "./pages/AddNewCompany/AddNewCompanyContainer";
import UploadedInvoicesContainer from "./pages/UploadedInvoices/UploadedInvoicesContainer";
import UserDetailsContainer from "./pages/UserDetail/UserDetailsContainer";
import CreateNewSurplusContainer from "./pages/CreateNewSurplus/CreateNewSurplusContainer";
import ServiceFeeInvoicesContainer from "./pages/ServiceFeeInvoices/ServiceFeeInvoicesContainer";
import AIInvoicesListContainer from "./pages/AIInvoices/AIInvoicesListContainer";
import NewAIInvoiceContainer from "./pages/NewAIInvoice/NewAIInvoiceContainer";
import AdminLogsDetail from "./pages/AdminLogs/AdminLogsDetail";
import AdminLogsContainer from "./pages/AdminLogs/AdminLogsContainer";
import RemittanceReportsContainer from "./pages/RemittanceReports/RemittanceReportsContainer";
import DownloadDocumentsContainer from "./pages/DownloadDocuments/DownloadDocumentsContainer";
import SurplusAllocationContainer from "./pages/SurplusAllocation/SurplusAllocationContainer";
import NotFoundPageContainer from "./pages/NotFoundPage/NotFoundPageContainer";
import CompanyLimitsContainer from "./pages/CompanyLimits/CompanyLimitsContainer";
import UninvoicedSalesTradabaseContainer from "./pages/UninvoicedSalesTradabase/UninvoicedSalesTradabaseContainer";
import UpcomingDueInvoicesContainer from "./pages/UpcomingDueInvoices/UpcomingDueInvoicesContainer";
import CompanyEmailPreferencesContainer from "./pages/CompanyEmailPreferences/CompanyEmailPreferencesContainer";
import ForecastedTrades from "./pages/ForecastedTrades/ForecastedTrades";
import TradabaseOrdersContainer from "./pages/TradabaseOrders/TradabaseOrdersContainer";
import TradabaseOrderDetail from "./pages/TradabaseOrders/TradabaseOrderDetail";
import Socket from "./pages/Socket";
import PushNotificationsContainer from "./pages/PushNotifications/PushNotificationsContainer";
import NotificationReportsContainer from "./pages/PushNotifications/NotificationReportsContainer";
import NotificationDetail from "./pages/PushNotifications/NotificationDetail";
import VarietySizeUnit from "./pages/VarietySizeUnitContainer/VarietySizeUnit";
import SubProductTypesContainer from "./pages/SubProductTypeContainer";
import SubProductTypeDetailContainer from "./pages/SubProductTypeContainer/SubProductTypeDetailContainer";
import CreateNewServiceFeeInvoiceContainer from "./pages/CreateNewServiceFeeInvoice/CreateNewServiceFeeInvoiceContainer";
import EmailsContainer from "./pages/Email/EmailsContainer";

export const pageRoutes = [
  {
    path: "/login",
    container: Login,
    isPublic: true,
  },
  {
    isPublic: true,
    path: "/create-password",
    container: CreatePasswordContainer,
  },
  {
    isPublic: true,
    path: "/forgot-password",
    container: ForgotPassword,
  },
  {
    path: "/",
    container: Main,
  },
  {
    path: "/admin-users",
    container: AdminUsersContainer,
  },
  {
    path: "/admin-groups",
    container: AdminGroupsContainer,
  },
  {
    path: "/company-list",
    container: CompanyListContainer,
  },
  {
    path: "/produce-types",
    container: ProduceTypesContainer,
  },
  {
    path: "/seller-fees",
    container: SellerFeeContainer,
  },
  {
    path: "/profile",
    container: AdminProfileContainer,
  },
  {
    path: "/currencies",
    container: CurrenciesContainer,
  },
  {
    path: "/brands",
    container: BrandsContainer,
  },
  {
    path: "/donate-emails",
    container: DonateCentersContainer,
  },
  {
    path: "/donate-emails/:id",
    container: DonateEmailsContainer,
  },
  {
    path: "/settings",
    container: SettingsContainer,
  },
  {
    path: "/users",
    container: UsersContainer,
  },
  {
    path: "/surplus-allocation",
    container: SurplusAllocationContainer,
  },
  {
    path: "/surplus-allocation/:id",
    container: SurplusOrderDetailContainer,
  },
  {
    path: "/company-registration",
    container: CompanyRegistrationContainer,
  },
  {
    path: "/company-list/:id",
    container: CompanyDetailsContainer,
  },
  {
    path: "/company-verification-requests",
    container: VerificationRequestContainer,
  },
  {
    path: "/additional-cost-types",
    container: AdditionalCostsContainer,
  },
  {
    path: "/bank-accounts",
    container: BankAccountsContainer,
  },
  // {
  //   path: "/credit-notes",
  //   container: CreditNotesContainer,
  // },
  {
    path: "/company-certification-requests",
    container: CertificationRequestsContainer,
  },
  {
    path: "/anonymous-company-names",
    container: AnonymousCompaniesContainer,
  },
  {
    path: "/new-seller/:id",
    container: AddFruPaySellerContainer,
  },
  {
    path: "/new-buyer/:id",
    container: AddFruPayBuyerContainer,
  },
  {
    path: "/new-company",
    container: AddNewCompanyContainer,
  },
  {
    path: "/premade-invoices",
    container: UploadedInvoicesContainer,
  },
  {
    path: "/users/:slug",
    container: UserDetailsContainer,
  },
  {
    path: "/create-new-surplus",
    container: CreateNewSurplusContainer,
  },
  {
    path: "/service-fee-invoices",
    container: ServiceFeeInvoicesContainer,
  },
  {
    path: "/service-fee-invoices/create",
    container: CreateNewServiceFeeInvoiceContainer,
  },
  {
    path: "/document-AI-invoices",
    container: AIInvoicesListContainer,
  },
  {
    path: "/invoice/:id",
    container: NewAIInvoiceContainer,
  },
  {
    path: "/admin-logs/:id",
    container: AdminLogsDetail,
  },
  {
    path: "/admin-logs",
    container: AdminLogsContainer,
  },
  {
    path: "/remittance-reports",
    container: RemittanceReportsContainer,
  },
  {
    path: "/download-documents",
    container: DownloadDocumentsContainer,
  },
  {
    path: "/company-limits",
    container: CompanyLimitsContainer,
  },
  {
    path: "/uninvoiced-sales-tradabase",
    container: UninvoicedSalesTradabaseContainer,
  },
  {
    path: "/upcoming-due-invoices",
    container: UpcomingDueInvoicesContainer,
  },
  {
    path: "/company-email-preferences",
    container: CompanyEmailPreferencesContainer,
  },
  {
    path: "/forecasted-trades",
    container: ForecastedTrades,
  },
  {
    path: "/tradabase-orders",
    container: TradabaseOrdersContainer,
  },
  {
    path: "/tradabase-orders/:id",
    container: TradabaseOrderDetail,
  },
  {
    path: "/socket",
    container: Socket,
  },
  {
    path: "/push-notifications",
    container: PushNotificationsContainer,
  },
  {
    path: "/notification-reports",
    container: NotificationReportsContainer,
  },
  {
    path: "/notification-reports/:id",
    container: NotificationDetail,
  },
  {
    path: "/variety-size-unit",
    container: VarietySizeUnit,
  },
  {
    path: "/subproduct-types",
    container: SubProductTypesContainer,
  },
  {
    path: "/subproduct-types/:id",
    container: SubProductTypeDetailContainer,
  },
  {
    path: "/emails",
    container: EmailsContainer,
  },
];
