import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

interface adminUsersBodyType {
  keyword: string;
  page: number;
}
interface blockAdminBodyType {
  adminId: string;
  reason: string;
}

export const getAdminUsers = async (data: adminUsersBodyType) => {
  try {
    const response = await axiosApiInstance.get(
      `/admin/admin-users?page=${data.page}&limit=${10}&keyword=${data.keyword}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addNewAdminUser = async (email: string, groups: string[]) => {
  try {
    const response = await axiosApiInstance.post("/admin/admin-users", {
      email: email,
      groups: groups,
    });

    if (response) {
      toast.success("Admin user is created successfuly.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.errors?.[0]?.message ||
          error.response?.data.message
      );
    }
  }
};

export const deleteAdminUser = async (adminId: string) => {
  try {
    const response = await axiosApiInstance.delete(
      `/admin/admin-users/${adminId}`
    );

    if (response) {
      toast.success("Admin user is deleted successfuly.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const blockAdminUser = async (data: blockAdminBodyType) => {
  try {
    const response = await axiosApiInstance.put(
      `/admin/admin-users/${data.adminId}/block`,
      {
        blockReason: data.reason,
      }
    );

    if (response) {
      toast.success("Admin user is blocked successfuly.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const unBlockAdminUser = async (adminId: string) => {
  try {
    const response = await axiosApiInstance.put(
      `/admin/admin-users/${adminId}/unblock`
    );

    if (response) {
      toast.success("Admin user is unblocked successfuly.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getAdminGroupsWithoutPagination = async () => {
  try {
    const response = await axiosApiInstance.post(
      `/admin/groups/admin-user-groups`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const editAdminsGroup = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/admin/groups/update-admin-group`,
      { ...data }
    );

    if (response) {
      toast.success("Admin's groups is updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
