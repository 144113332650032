import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import AdminButtonComponent from "../../../../components/AdminButtonComponent";
import { AdditionalCost } from "../../orderStateType";
import NumberFormatComponent from "../../../../components/NumberFormatComponent";
import AdminModalComponent from "../../../../components/AdminModalComponent";
import ControlledComboBox from "../../../../components/ControlledComboBox";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ControlledTextField from "../../../../components/ControlledTextField";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { getCostTypes } from "../../../../services/additional-cost-types";
import { toast } from "react-toastify";
import {
  addAddCost,
  removeAddCost,
} from "../../../../services/surplus-allocation";
import usePermissions from "../../../../hooks/usePermissions";
import PermissionWrapper from "../../../../components/PermissionWrapper";
import { InvoicesState } from "./InvoiceInterface";

const schema = yup.object().shape({
  costType: yup.object(),
  description: yup.string(),
  amount: yup.string(),
  vatRate: yup.string(),
});

const useStyles = makeStyles({
  header: {
    fontSize: 20,
  },
  hr: {
    opacity: 0.05,
  },
  amount: {
    fontSize: 17,
  },
  addNew: {
    background: "#E77228!important",
    borderRadius: "10px!important",
  },
  costItem: {
    borderRadius: "10px",
    background: "#F7F7F7",
    padding: 10,
  },
  confirmButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  trash: {
    cursor: "pointer",
    color: "red",
  },
});

const AdditionalCosts = ({
  invoice,
  setRefreshPartial,
  additionalCosts,
  orderId,
  setRefresh,
  currencySymbol,
}: {
  invoice: InvoicesState;
  orderId: string;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  setRefreshPartial: React.Dispatch<React.SetStateAction<number>>;
  currencySymbol?: string;
  additionalCosts?: AdditionalCost[];
}) => {
  const { hasPermission } = usePermissions();
  const classes = useStyles();

  const {
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      costType: undefined,
      description: "",
      amount: "",
      vatRate: "",
    },
  });

  interface CostTypeState {
    name: string;
    id: string;
    vatRate: string;
  }

  const watchCostType = watch("costType");
  const watchAmount = watch("amount");
  const watchDescription = watch("description");

  const isCostActionsDisabled = useMemo(() => {
    return (
      invoice?.status === "COMPLETED" ||
      invoice?.status === "ISSUED" ||
      invoice?.status === "SELLER_PAID"
    );
  }, [invoice?.status]);

  const [openAddAdditionalCostModal, setOpenAddAdditionalCostModal] =
    useState<boolean>(false);
  const [
    openDeleteAdditionalCostConfirmModal,
    setOpenDeleteAdditionalCostConfirmModal,
  ] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCostId, setSelectedCostId] = useState<string>("");
  const [additionalCostTypes, setAdditionalCostTypes] = useState<
    CostTypeState[]
  >([]);

  useEffect(() => {
    if (openAddAdditionalCostModal) {
      const getTitles = async () => {
        setLoading(true);
        const response = await getCostTypes();
        setLoading(false);
        if (response?.length) {
          setAdditionalCostTypes(
            response?.map((item: any) => ({
              id: item?._id,
              name: item?.costName,
              vatRate: item?.vatRate,
            }))
          );
        }
      };
      getTitles();
    }
  }, [openAddAdditionalCostModal]);

  const onOpenAddAdditionalCostModal = () => {
    setOpenAddAdditionalCostModal(true);
  };
  const onCloseAddAdditionalCostModal = () => {
    setOpenAddAdditionalCostModal(false);
  };

  const onOpenDeleteAdditionalCostConfirmModal = (id: string) => {
    setOpenDeleteAdditionalCostConfirmModal(true);
    setSelectedCostId(id);
  };
  const onCloseDeleteAdditionalCostConfirmModal = () => {
    setOpenDeleteAdditionalCostConfirmModal(false);
    setSelectedCostId("");
  };

  // useEffect(() => {
  //   if (watchAmount && parseFloat(watchAmount) < 0) {
  //     setValue("amount", "0");
  //   }
  // }, [watchAmount]);

  const onAddNewCost = async () => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    if (!watchAmount) {
      toast.error("Amount is required");
    } else if (!(watchCostType as any)?.id) {
      toast.error("Cost type is required");
    } else {
      setLoading(true);

      const finalData = {
        costId: (watchCostType as any)?.id,
        amount: parseFloat(watchAmount),
        vatRate: parseFloat(watch("vatRate") ?? "0"),
        description: watchDescription ? watchDescription : undefined,
      };

      const added = await addAddCost(finalData, orderId);
      setLoading(false);

      if (added) {
        setRefresh((prev: number) => prev + 1);
        setRefreshPartial((prev: number) => prev + 1);
        setOpenAddAdditionalCostModal(false);
        reset({});
      }
    }
  };

  const onDeleteCost = async () => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    setLoading(true);

    const deleted = await removeAddCost(orderId, selectedCostId);
    setLoading(false);

    if (deleted) {
      setSelectedCostId("");
      setRefreshPartial((prev: number) => prev + 1);
      setOpenDeleteAdditionalCostConfirmModal(false);
      setRefresh((prev: number) => prev + 1);
    }
  };

  const renderAddCostModalBody = () => {
    return (
      <>
        <div className="row g-3">
          <ControlledComboBox
            error={!!errors.costType}
            helperText={errors.costType?.message}
            className={classes.comboBox}
            textLabel="Cost Type"
            options={additionalCostTypes}
            control={control}
            name="costType"
            onBlur={() => {
              const cost = additionalCostTypes?.find(
                (itm) => itm.id === (watchCostType as any)?.id
              );
              if (cost) {
                setValue("vatRate", cost?.vatRate.toString());
              }
            }}
          />
          <ControlledTextField
            error={!!errors.amount}
            helperText={errors.amount?.message}
            textLabel="Amount"
            control={control}
            name="amount"
          />
          <ControlledTextField
            error={!!errors.vatRate}
            helperText={errors.vatRate?.message}
            textLabel="VAT Rate"
            control={control}
            name="vatRate"
            onBlur={(e) => {
              const value = parseFloat(e.target.value);
              if (isNaN(value)) {
                toast.error("VAT Rate must be a number");
                setValue("vatRate", "");
                return;
              }
              if (value < 0) {
                toast.error("VAT Rate must be greater than 0");
                setValue("vatRate", "");
                return;
              }

              if (value > 100) {
                toast.error("VAT Rate must be less than 100");
                setValue("vatRate", "");
                return;
              }

              e.preventDefault();
            }}
          />
          <ControlledTextField
            textLabel="Description"
            control={control}
            name="description"
          />
        </div>

        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseAddAdditionalCostModal}
            title="Close"
          />{" "}
          <AdminButtonComponent
            className={classes.confirmButton}
            onClick={onAddNewCost}
            title="Submit"
          />
        </div>
      </>
    );
  };

  const renderDeleteConfirmCostModalBody = () => {
    return (
      <>
        <span>Are you sure you want to delete this cost?</span>

        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseDeleteAdditionalCostConfirmModal}
            title="Close"
          />{" "}
          <AdminButtonComponent
            className={classes.confirmButton}
            onClick={onDeleteCost}
            title="Confirm"
          />
        </div>
      </>
    );
  };

  return (
    <div className="p-3">
      {" "}
      {loading && <LoadingIndicator loading />}
      <div className="d-flex align-items-center justify-content-between pb-2">
        <span className={classes.header}>Additional Costs</span>
        <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
          <AdminButtonComponent
            disabled={isCostActionsDisabled}
            className={classes.addNew}
            onClick={onOpenAddAdditionalCostModal}
            title=" + Add new"
          />
        </PermissionWrapper>
      </div>
      <hr className={classes.hr} />
      <div className={`d-flex flex-column gap-3 ${classes.costItem}`}>
        <div className={`d-flex justify-content-between  flex-row gap-3`}>
          <span className="col-md-3 font-extrabold">Cost Type</span>
          <span className="col-md-4 font-extrabold">Description</span>

          <span className="col-md-2 font-extrabold">Amount</span>
          <span className="col-md-2 font-extrabold">VAT</span>
          <span className="col-md-1 font-extrabold"></span>
        </div>
        {additionalCosts?.map((cost, index) => {
          return (
            <div
              key={index}
              className={`d-flex justify-content-between  flex-row gap-3 ${classes.costItem}`}
            >
              <span className="col-md-3">{cost.costName}</span>
              <span className="col-md-4">{cost.description || "-"}</span>

              <span className="col-md-2">
                <NumberFormatComponent
                  prefix={currencySymbol}
                  value={cost.amount}
                />
              </span>
              <span className="col-md-2">
                <NumberFormatComponent value={cost.vatRate} />%
                <div
                  style={{
                    color: "#69cdae",
                  }}
                >
                  (
                  <NumberFormatComponent
                    prefix={currencySymbol}
                    value={cost.amount * (cost.vatRate / 100)}
                  />
                  )
                </div>
              </span>

              {isCostActionsDisabled ? null : (
                <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                  <i
                    onClick={() =>
                      onOpenDeleteAdditionalCostConfirmModal(cost._id)
                    }
                    className={`ri-delete-bin-line ${classes.trash} col-md-1`}
                  ></i>
                </PermissionWrapper>
              )}
            </div>
          );
        })}
      </div>
      <AdminModalComponent
        headerTitle="Add new cost"
        children={renderAddCostModalBody()}
        closeModal={onCloseAddAdditionalCostModal}
        openModal={openAddAdditionalCostModal}
      />
      <AdminModalComponent
        headerTitle="Are you sure"
        children={renderDeleteConfirmCostModalBody()}
        closeModal={onCloseDeleteAdditionalCostConfirmModal}
        openModal={openDeleteAdditionalCostConfirmModal}
      />
    </div>
  );
};

export default AdditionalCosts;
