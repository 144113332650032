import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Title,
} from "@tremor/react";
import { DashboardState } from "../../interface";
import NumberFormatComponent from "../../../../components/NumberFormatComponent";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { isDarkReaderActive } from "../../../../services/common";
import { useAppSelector } from "../../../../hooks/hooks";

const useStyles = makeStyles({
  darkModeTextColor: {
    fontWeight: 600,
    color: "white!important",
  },
  seeAllLimitsText: {
    cursor: "pointer",
    fontSize: 16,
    textDecoration: "underline",
  },
  darkModeHeaderTextColor: {
    fontWeight: 600,
    color: "white!important",
  },
  darkModeSeeAllTextColor: {
    cursor: "pointer",
    fontWeight: 600,
    textDecoration: "underline",
    color: "white!important",
  },
  seeAllCompsText: {
    cursor: "pointer",
    fontSize: 16,
    textDecoration: "underline",
  },
});

const NonInvoicedSalesTable = ({
  isDarkMode,
  dashboardData,
}: {
  isDarkMode: boolean;
  dashboardData?: DashboardState;
}) => {
  const classes = useStyles();

  const currencies = useAppSelector((state) => state.auth.currencies);

  return (
    <Card decoration="bottom" decorationColor="rose" className="mt-2 ">
      <div className="d-flex justify-content-between">
        <Title
          className={
            isDarkMode || isDarkReaderActive()
              ? classes.darkModeHeaderTextColor
              : ""
          }
          color="rose"
        >
          Uninvoiced Sales On Tradabase
        </Title>
        <a href={"/uninvoiced-sales-tradabase"} target="_blank">
          <Title
            className={
              isDarkMode || isDarkReaderActive()
                ? classes.darkModeSeeAllTextColor
                : classes.seeAllCompsText
            }
            color="rose"
          >
            See all
          </Title>
        </a>
      </div>
      <Table className="mt-2">
        <TableHead>
          <TableRow>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Company Name
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Estimated Total Order Value
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Order Count
            </TableHeaderCell>
            <TableHeaderCell
              className={
                isDarkMode || isDarkReaderActive()
                  ? classes.darkModeTextColor
                  : ""
              }
            >
              Last Update
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dashboardData?.notInvoicedSalesOnTradabase.map((item, index) => {
            const itemCurrency = currencies.find(
              (c) => c.code === item.Currency
            );
            return (
              <TableRow key={index}>
                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  {item?.CustomerName}
                </TableCell>
                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  {itemCurrency?.symbol}
                  {<NumberFormatComponent value={item.EstimatedOrderValue} />}
                </TableCell>
                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  {item?.OrderCount}
                </TableCell>

                <TableCell
                  className={
                    isDarkMode || isDarkReaderActive()
                      ? classes.darkModeTextColor
                      : ""
                  }
                >
                  {moment(item?.LatestUpdate).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};

export default NonInvoicedSalesTable;
