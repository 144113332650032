import React, {
  forwardRef,
  memo,
  useCallback,
  useState,
  useImperativeHandle,
  useRef,
  useMemo,
} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dialogIndex: {
    zIndex: "99999999999!important",
    "& .MuiPaper-root": {
      width: 500,
      borderRadius: 20,
      border: "1px solid #E77228",
    },
  },
});

interface InputDialogProps {
  title: string;
  description: string;
  setResult?: (value: string | false) => void;
}

export interface InputDialogRef {
  openDialog: () => void;
}

const InputDialog = memo(
  forwardRef<InputDialogRef, InputDialogProps>(
    ({ title, description, setResult }, ref) => {
      const classes = useStyles();
      const [open, setOpen] = useState(false);
      const [inputValue, setInputValue] = useState("");

      const handleCancel = useCallback(() => {
        setOpen(false);
        setResult?.(false);
        setInputValue("");
      }, [setResult]);

      const handleAccept = useCallback(() => {
        setOpen(false);
        setResult?.(inputValue);
        setInputValue("");
      }, [inputValue, setResult]);

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
      };

      useImperativeHandle(ref, () => ({
        openDialog: () => setOpen(true),
      }));

      return (
        <Dialog
          className={classes.dialogIndex}
          open={open}
          onClose={handleCancel}
          aria-labelledby="input-dialog-title"
          aria-describedby="input-dialog-description"
        >
          <DialogTitle id="input-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="input-dialog-description">
              {description}
            </DialogContentText>
            <TextField
              value={inputValue}
              onChange={handleChange}
              fullWidth
              placeholder="Enter value"
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleAccept} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  )
);

export default InputDialog;

export const useInputDialog = (props: Omit<InputDialogProps, "setResult">) => {
  const ref = useRef<InputDialogRef>(null);
  const [resolveResult, setResolveResult] =
    useState<(value: string | false) => void>();

  const openDialog = useCallback(() => {
    return new Promise<string | false>((resolve) => {
      setResolveResult(() => resolve);
      ref.current?.openDialog();
    });
  }, []);

  const InputDialogComponent = useMemo(
    () => (
      <InputDialog
        {...props}
        ref={ref}
        setResult={(value) => {
          resolveResult?.(value);
        }}
      />
    ),
    [props, resolveResult]
  );

  return {
    InputDialog: InputDialogComponent,
    openDialog,
  };
};
