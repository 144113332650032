import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export interface AdminLoginBodyType {
  email: string;
  password: string;
}

export interface AdminResetPasswordType {
  email: string;
}

export interface ErrorTypes {
  code: string;
  errors: [];
  message: string;
}

interface adminProfileBodyType {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export const AdminLogin = async (data: AdminLoginBodyType) => {
  try {
    const response = await axiosApiInstance.post("/admin/auth/login", {
      ...data,
    });

    if (response.data) {
      localStorage.setItem("isTwoFaVerified", response.data.user._2fa);

      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const ResetPassword = async (data: AdminResetPasswordType) => {
  try {
    const response = await axiosApiInstance.post("/admin/auth/reset-password", {
      ...data,
    });

    if (response) {
      toast.success("We have sent a reset link to your email.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const AdminUserInfo = async () => {
  try {
    const response = await axiosApiInstance.get("/admin/me");

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const updatePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  try {
    const response = await axiosApiInstance.put("/admin/update-password", {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });

    if (response) {
      toast.success("Password is updated successfully");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getTwoFaToken = async (email: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_CLOUD_URL}/TwoFactorAuthentication/?action=setup&email=${email}`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const FirstTimeVerifyTwoFa = async (token: string, email: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_CLOUD_URL}/TwoFactorAuthentication/?action=verify&email=${email}&first=true&token=${token}`
    );
    if (response) {
      return response.status;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error?.response?.data?.message||error.message);
    }
  }
};

export const VerifyTwoFa = async (token: string, email: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_CLOUD_URL}/TwoFactorAuthentication/?action=verify&email=${email}&token=${token}`
    );

    if (response) {
      return response.status;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error?.response?.data?.message||error.message);
    }
  }
};

export const updateAdminProfileInfo = async (data: adminProfileBodyType) => {
  try {
    const response = await axiosApiInstance.put("/admin/update-info", {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
    });

    if (response) {
      toast.success("Information updated successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.message);
    }
  }
};


export const verifyNewPassword = async (code:string) => {
  try {
    const response = await axiosApiInstance.post("/admin/auth/verify-active-code", {
      activeCode:code
    });

    if (response) {
      return response.status;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message||error.message);
    }
  }
};

export const sendNewPassword = async (data:any) => {
  try {
    const response = await axiosApiInstance.post("/admin/auth/active-account", {
      ...data
    });

    if (response) {
      toast.success("Password verify is successful.")
      return response.status;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.message);
    }
  }
};



export const sendResetPasswordEmail = async (data:any) => {
  try {
    const response = await axiosApiInstance.post("/auth/reset-password", {
      ...data
    });

    if (response) {
      toast.success("Email sent successfully")
      return response.status;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message||error.message);
    }
  }
};


