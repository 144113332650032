import React, { useCallback, useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
  exportExcelToRemittanceSummary,
  exportExcelToRemittanceDetailed,
  getRemittanceReports,
  getRemittanceReportsSummary,
} from "../../services/reports";
import TableComponent from "../../components/TableComponent";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import AdminModalComponent from "../../components/AdminModalComponent";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import ControlledTextField from "../../components/ControlledTextField";
import ControlledComboBox from "../../components/ControlledComboBox";
import ControlledDateField from "../../components/ControlledDateField";
import FilterBadge from "../../components/FilterBadge";
import {
  downloadFile,
  exportToExcelAnArray,
  getBuyerSeller,
} from "../../services/common";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";
import NumberFormatComponent from "../../components/NumberFormatComponent";
import { useAppSelector } from "../../hooks/hooks";

const schema = yup.object().shape({
  code: yup.string(),
  sellerId: yup.object(),
  buyerId: yup.object(),
  captureDateFrom: yup.string(),
  captureDateTo: yup.string(),
  payoutDateFrom: yup.string(),
  payoutDateTo: yup.string(),
  invoiceDateFrom: yup.string(),
  invoiceDateTo: yup.string(),
});

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  clickableText: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "#E77228",
    "&:hover": {
      color: "#E77228",
    },
  },
  offersCell: {
    padding: "0!important",
    borderBottom: "0!important",
  },
  boldText: {
    fontWeight: 600,
  },
  exportButton: {
    minWidth: "100px!important",
    background: "#69cdae!important",
  },
});

export interface SummaryState {
  data: Daum[];
  seller: string;
}

export interface Daum {
  payoutDate: string;
  totalAmount: number;
  paymentCount: number;
  currency?: string;
}

export interface RemittanceState {
  _id: string;
  seller: string;
  currency: string;
  sellerId: string;
  buyer: string;
  invoiceNo: string;
  invoiceDate: string;
  offerCode: string;
  paymentCode: string;
  orderId: string;
  refs: {
    buyerReferenceNumber: string;
    sellerReferenceNumber: string;
  };
  pdfPath: string;
  invoiceAmount: string;
  feeDeducted: string;
  finalAmount: string;
  captureDate: string;
  payoutDate: string;
  id: string;
}

const columns = [
  { name: "Buyer", id: "buyer" },
  { name: "Seller", id: "seller" },
  { name: "Code", id: "code" },
  { name: "Refs", id: "refs" },
  { name: "Date", id: "date" },
  { name: "Amount", id: "amount" },
];

const columnsSummary = [{ name: "Seller", id: "seller" }];

const RemittanceReportsContainer = () => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { hasPermission } = usePermissions();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [clickedFilterDelete, setClickedFilterDelete] = useState<number>(0);
  const [showedDataType, setShowedDataType] = useState<string>("summary");
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [companies, setCompanies] = useState<{ name: string; id: string }[]>(
    []
  );

  const [summaryReports, setSummaryReports] = useState<SummaryState[]>([]);
  const [deletedFilterKey, setDeletedFilterKey] = useState<string>("");
  const [exportLoading, setExportLoading] = useState(false);
  const [reports, setReports] = useState<RemittanceState[]>([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });
  const [filterData, setFilterData] = useState({});

  const currencies = useAppSelector((state) => state.auth.currencies);

  useEffect(() => {
    setValue(`${deletedFilterKey}` as any, undefined);
  }, [deletedFilterKey, clickedFilterDelete]);

  useEffect(() => {
    if (openFilterModal) {
      const getCompanies = async () => {
        setLoading(true);
        const response = await getBuyerSeller();
        setLoading(false);

        if (response) {
          setCompanies(
            response?.items.map((c: any) => ({
              name: c?.companyName,
              id: c?.id,
            }))
          );
        }
      };
      getCompanies();
    }
  }, [openFilterModal]);

  useEffect(() => {
    if (!hasPermission("REMITTANCE_REPORT_PERMISSION")) return;
    const getReportData = async () => {
      if (showedDataType === "detail") {
        setSummaryReports([]);
        setFetchLoading(true);

        const response = await getRemittanceReports(
          filterData,
          queryParams.page
        );
        setFetchLoading(false);
        if (response) {
          setQueryParams({
            page: response?.metaData?.page,
            total: response?.metaData?.total,
            totalPage: response?.metaData?.totalPage,
            limit: response?.metaData?.limit,
          });
          setReports(response?.items);
        }
      } else {
        setReports([]);
        setFetchLoading(true);

        const response = await getRemittanceReportsSummary(filterData, 0);
        setFetchLoading(false);
        if (response) {
          setQueryParams({
            page: response?.metaData?.page,
            total: response?.metaData?.total,
            totalPage: response?.metaData?.totalPage,
            limit: response?.metaData?.limit,
          });
          setSummaryReports(response?.items);
        }
      }
    };
    getReportData();
  }, [
    hasPermission("REMITTANCE_REPORT_PERMISSION"),
    showedDataType,
    (filterData as any)?.buyerName,
    (filterData as any)?.sellerId,
    (filterData as any)?.code,
    (filterData as any)?.captureDateFrom,
    (filterData as any)?.captureDateTo,
    (filterData as any)?.payoutDateFrom,
    (filterData as any)?.payoutDateTo,
    (filterData as any)?.invoiceDateFrom,
    (filterData as any)?.invoiceDateTo,
    queryParams.page,
  ]);

  const onFilter = (data: any) => {
    setQueryParams({
      ...queryParams,
      page: 1,
    });
    setFilterData({
      buyerName: data?.buyerName?.name ? data?.buyerName?.name : undefined,
      sellerId: data?.sellerId?.id ? data?.sellerId?.id : undefined,
      code: data?.code ? data?.code : undefined,
      payoutDateFrom: data?.payoutDateFrom
        ? moment(data?.payoutDateFrom).format("YYYY-MM-DD")
        : undefined,
      payoutDateTo: data?.payoutDateTo
        ? moment(data?.payoutDateTo).format("YYYY-MM-DD")
        : undefined,
      captureDateFrom: data?.captureDateFrom
        ? moment(data?.captureDateFrom).format("YYYY-MM-DD")
        : undefined,
      captureDateTo: data?.captureDateTo
        ? moment(data?.captureDateTo).format("YYYY-MM-DD")
        : undefined,
      invoiceDateFrom: data?.invoiceDateFrom
        ? moment(data?.invoiceDateFrom).format("YYYY-MM-DD")
        : undefined,
      invoiceDateTo: data?.invoiceDateTo
        ? moment(data?.invoiceDateTo).format("YYYY-MM-DD")
        : undefined,
    });
    setOpenFilterModal(false);
  };

  const onExportData = useCallback(async () => {
    if (showedDataType === "detail") {
      setExportLoading(true);
      const exportRes = await exportExcelToRemittanceDetailed({
        ...filterData,
        export: true,
      });
      setExportLoading(false);

      if (exportRes?.excelBuffer) {
        exportToExcelAnArray(
          exportRes?.excelBuffer?.data,
          "remittance_reports.xlsx"
        );
      }
    } else {
      setExportLoading(true);
      const exportRes = await exportExcelToRemittanceSummary({
        ...filterData,
        export: true,
      });
      setExportLoading(false);

      if (exportRes?.excelBuffer) {
        exportToExcelAnArray(
          exportRes?.excelBuffer?.data,
          "remittance_reports.xlsx"
        );
      }
    }
  }, [filterData, showedDataType]);

  const renderTableBody = () => {
    return showedDataType === "detail" ? (
      <>
        <Helmet>
          <title>Remittance Reports | FruPro Admin Portal</title>
        </Helmet>
        {reports.map((report, index) => {
          const itemCurr = currencies.find((i) => i.code === report.currency);
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {report.buyer}
              </TableCell>
              <TableCell component="th" scope="row">
                {report.seller}
              </TableCell>
              <TableCell component="th" scope="row">
                <span>
                  <span className="fw-bold">Offer Code :</span>{" "}
                  <a
                    target="_blank"
                    href={`/surplus-allocation/${report.orderId}`}
                    className={classes.clickableText}
                  >
                    {" "}
                    {report.offerCode}
                  </a>
                </span>
                <br />
                <span>
                  <span className="fw-bold">Invoice Code :</span>{" "}
                  <a
                    target="_blank"
                    href={report.pdfPath}
                    className={classes.clickableText}
                  >
                    {report.invoiceNo}
                  </a>
                </span>
                <br />
                <span>
                  <span className="fw-bold">Payment Code :</span>{" "}
                  {report.paymentCode}
                </span>
              </TableCell>

              <TableCell style={{ minWidth: 300 }} component="th" scope="row">
                <div>
                  {report.refs.sellerReferenceNumber ? (
                    <>
                      <span className="fw-bold">Seller Ref : </span>
                      <span>{report.refs.sellerReferenceNumber}</span>
                    </>
                  ) : null}
                </div>

                <div>
                  {report.refs.buyerReferenceNumber ? (
                    <>
                      <span className="fw-bold">Buyer Ref : </span>
                      <span>{report.refs.buyerReferenceNumber}</span>
                    </>
                  ) : null}
                </div>
              </TableCell>

              <TableCell component="th" scope="row">
                <span>
                  <span className="fw-bold">Capture Date :</span>{" "}
                  {moment(report.captureDate).format("DD/MM/YYYY")}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Invoice Date :</span>{" "}
                  {moment(report.invoiceDate).format("DD/MM/YYYY")}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Payout Date :</span>{" "}
                  {moment(report.payoutDate).format("DD/MM/YYYY")}
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                <span>
                  <span className="fw-bold">Invoice Amount :</span>{" "}
                  {itemCurr?.symbol}
                  {report.invoiceAmount}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Deducted Amount :</span>{" "}
                  {itemCurr?.symbol}
                  {report.feeDeducted}
                </span>
                <br />
                <span>
                  <span className="fw-bold">Final Amount :</span>{" "}
                  {itemCurr?.symbol}
                  {report.finalAmount}
                </span>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    ) : (
      <>
        {summaryReports.map((item, index) => {
          return (
            <>
              <TableRow
                className={classes.normalRow}
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item.seller}
                </TableCell>
              </TableRow>

              {item.data.map((historyItem, index) => {
                const itemCurr = currencies.find(
                  (c) => c.code === historyItem.currency
                );
                return (
                  <TableRow className={classes.colorRow}>
                    <TableCell className={classes.offersCell} colSpan={12}>
                      <Collapse in={true}>
                        <Table>
                          <TableBody>
                            <TableRow key={index}>
                              <TableCell>
                                <span className={classes.boldText}>
                                  Payout Date :{" "}
                                </span>
                                {moment(historyItem.payoutDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </TableCell>
                              <TableCell style={{ minWidth: 200 }}>
                                {" "}
                                <span className={classes.boldText}>
                                  Total Amount
                                </span>{" "}
                                : {itemCurr?.symbol}{" "}
                                {
                                  <NumberFormatComponent
                                    value={historyItem.totalAmount}
                                  />
                                }
                              </TableCell>

                              <TableCell style={{ minWidth: 200 }}>
                                {" "}
                                <span className={classes.boldText}>
                                  Payment Count
                                </span>{" "}
                                : {historyItem.paymentCount}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          );
        })}
      </>
    );
  };

  const renderFilterModalBody = () => {
    return (
      <>
        <div className="row g-3">
          <ControlledTextField
            textLabel="Code (Payment,Order,Invoice)"
            control={control}
            name="code"
          />
          <ControlledComboBox
            className={classes.comboBox}
            options={companies}
            textLabel="Seller"
            control={control}
            name="sellerId"
          />
          <ControlledComboBox
            className={classes.comboBox}
            options={companies}
            textLabel="Buyer"
            control={control}
            name="buyerName"
          />
          <div className="row mt-3">
            <div className="col-md-6">
              <ControlledDateField
                textLabel="Payout Date From"
                control={control}
                name="payoutDateFrom"
              />
            </div>
            <div className="col-md-6">
              <ControlledDateField
                textLabel="Payout Date To"
                control={control}
                name="payoutDateTo"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <ControlledDateField
                textLabel="Capture Date From"
                control={control}
                name="captureDateFrom"
              />
            </div>
            <div className="col-md-6">
              <ControlledDateField
                textLabel="Capture Date To"
                control={control}
                name="captureDateTo"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <ControlledDateField
                textLabel="Invoice Date From"
                control={control}
                name="invoiceDateFrom"
              />
            </div>
            <div className="col-md-6">
              <ControlledDateField
                textLabel="Invoice Date To"
                control={control}
                name="invoiceDateTo"
              />
            </div>
          </div>
        </div>

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setOpenFilterModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={handleSubmit(onFilter)}
            title="Submit"
          />
        </Box>
      </>
    );
  };

  const renderExportButton = () => {
    return (
      <>
        <AdminButtonComponent
          disabled={exportLoading}
          loading={exportLoading}
          className={classes.exportButton}
          onClick={onExportData}
          title="Export Data"
        />
      </>
    );
  };
  return (
    <Sidebar pageTitle="Remittance Reports">
      {loading && <LoadingIndicator loading />}
      <Helmet>
        <title>Remittance Reports | FruPro Admin Portal</title>
      </Helmet>
      <PermissionWrapper
        permission="REMITTANCE_REPORT_PERMISSION"
        unauthorizedComponent={true}
      >
        {" "}
        <TableComponent
          showRightHeaderItems={renderExportButton()}
          isRightButtonFunction
          headerButtonTitle={
            showedDataType === "summary"
              ? "Show detailed Report"
              : "Show summary report"
          }
          rightButtonFunction={() =>
            setShowedDataType((prev: string) =>
              prev === "detail" ? "summary" : "detail"
            )
          }
          loading={fetchLoading}
          filterBadge={
            <FilterBadge
              isClearFilterKey={clickedFilterDelete}
              setIsClearFilterKey={setClickedFilterDelete}
              sellers={companies}
              buyers={companies}
              filterData={filterData}
              setFilterData={setFilterData}
              setDeletedFilterKey={setDeletedFilterKey}
            />
          }
          showFilterIcon
          onOpenModal={() => setOpenFilterModal(true)}
          columns={showedDataType === "detail" ? columns : columnsSummary}
          TableBodyComponent={renderTableBody}
          showRightButton={true}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      </PermissionWrapper>

      <AdminModalComponent
        headerTitle="Filter Report"
        children={renderFilterModalBody()}
        closeModal={() => setOpenFilterModal(false)}
        openModal={openFilterModal}
      />
    </Sidebar>
  );
};

export default RemittanceReportsContainer;
