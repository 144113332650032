import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type SellerFeeType = any;

type SellerFeeStateType = {
  sellerFees: SellerFeeType[];
};

const initialState: SellerFeeStateType = {
  sellerFees: [],
};

const sellerFeeSlice = createSlice({
  name: "sellerFee",
  initialState,
  reducers: {
    setSellerFees: (state, action: PayloadAction<SellerFeeType>) => {
      state.sellerFees = action.payload;
    },
  },
});

export default sellerFeeSlice.reducer;

export const { setSellerFees } = sellerFeeSlice.actions;
