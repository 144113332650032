import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";
import OtpInput from "react-otp-input";
import { OTPInputTypes } from "./interfaces/ComponentInterfaces";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFFF",
    borderRadius: "10px",
    flex: "none",
    order: 1,
    alignSelf: "stretch",
    flexGrow: 1,
    marginBottom: "24px",
    marginTop: "24px",
  },
  customInput: {
    fontSize: "30px",
    width: "53px !important",
    border: "none",
    height: "84px !important",
    borderRadius: "10px",
    textAlign: "center",
    "&:focus-visible": {
      outline: 0,
    },
    "&[value='']": {
      width: "53px !important",
      height: "84px !important",
      borderRadius: "10px",
    },
  },
  differentbackgroundcustomInput: {
    fontSize: "30px",
    width: "53px !important",
    border: "none",
    height: "84px !important",
    background:"#f8f8f8",
    borderRadius: "10px",
    textAlign: "center",
    "&:focus-visible": {
      outline: 0,
    },
    "&[value='']": {
      width: "53px !important",
      height: "84px !important",
      borderRadius: "10px",
    },
  },
}));

const AdminOtpInput: React.FC<OTPInputTypes> = ({ value, onChange,id ,differentBackground}) => {
  const classes = useStyles();

  return (
    <OtpInput
      numInputs={6}
      renderInput={(props) => (
        <input {...props} id={id} className={differentBackground?classes.differentbackgroundcustomInput:classes.customInput} />
      )}
      containerStyle={classes.root}
      inputStyle={ differentBackground?classes.differentbackgroundcustomInput: classes.customInput}
      value={value}
      onChange={onChange}
    />
  );
};

export default AdminOtpInput;
