
//change everything here about offer and invoice statuses is enough.

export const OrderStatuses = [
  { name: "PENDING", color: "#FFD700" },
  { name: "CANCELLED", color: "#FF6347" },
  { name: "SIGNED_OFF", color: "#FFC0CB" },
  { name: "DISPATCHED", color: "#4169E1" },
  { name: "SETTLEMENT", color: "#C18560" },
  { name: "DRAFT INVOICE", color: "#505050" },
  { name: "PARTIALLY PAID", color: "#FFA500" },
  { name: "PARTIALLY REPAID", color: "#FFA580" },
  { name: "FINAL DRAFT", color: "#808080" },
  { name: "INVOICED", color: "#4682B4" },
  { name: "BUYER TO PAY", color: "#800080" },
  { name: "COMPLETE" || "COMPLETED", color: "#008000" },
];

export const InvoiceStatuses = [
  { name: "DRAFT", color: "#505050" },
  { name: "PROCESSING", color: "#4169E1" },
  // { name: "PROCESSING/DRAFT", color: "#9370DB" },
  { name: "ISSUED", color: "#C18560" },
  { name: "DUE", color: "#4682B4" },
  { name: "SELLER_PAID", color: " #FFA500" },
  // { name: "PAID", color: "#FFA580" },
  { name: "COMPLETED", color: "#008000" },
];

export const PaymentStatuses = [
  { name: "WAITING", color: "#FFD700" },
  { name: "WAITING_PAYMENT_TERMS", color: "#FFAE82" },
  { name: "CAPTURED", color: "#008000" },
  { name: "PROCESSING", color: "#4169E1" },
  { name: "CANCELLED", color: "#FF6347" },
  { name: "SELLER_PAID", color: "#FFA500" },
  { name: "BUYER_PAID", color: "#9370DB" },
];
