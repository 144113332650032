export const logTypes = {
  createUser: "CREATE_USER",
  updateUser: "UPDATE_USER",
  blockUser: "BLOCK_USER",
  unblockUser: "UNBLOCK_USER",
  createCompany: "CREATE_COMPANY",
  updateCompany: "UPDATE_COMPANY",
  blockCompany: "BLOCK_COMPANY",
  createSurplusAllocation: "CREATE_SURPLUS_ALLOCATION",
  cancelSurplusAllocation: "CANCEL_SURPLUS_ALLOCATION",
  orderDiscountUpdate: "ORDER_DISCOUNT_UPDATE",
  orderDeliveryDateUpdate: "ORDER_DELIVERY_DATE_UPDATE",
  orderInvoiceDateUpdate: "ORDER_INVOICE_DATE_UPDATE",
  orderProducePriceUpdate: "ORDER_PRODUCE_PRICE_UPDATE",
  orderAdditionalCostAdd: "ORDER_ADDITIONAL_COST_ADD",
  orderAdditionalCostRemove: "ORDER_ADDITIONAL_COST_REMOVE",
  documentAttach: "DOCUMENT_ATTACH",
  documentRemove: "DOCUMENT_REMOVE",
  invoiceCreate: "INVOICE_CREATE",
  invoiceCancel: "INVOICE_CANCEL",
  invoiceAccept: "INVOICE_ACCEPT",
  invoiceRegenerate: "INVOICE_REGENERATE",
  invoiceFileForward: "INVOICE_FILE_FORWARD",
  adminCreate: "ADMIN_CREATE",
  adminBlock: "ADMIN_BLOCK",
  adminUnblock: "ADMIN_UNBLOCK",
  adminDelete: "ADMIN_DELETE",
  impersonate: "IMPERSONATE",
  statementGenerate: "STATEMENT_GENERATE",
  statementFileForward: "STATEMENT_FILE_FORWARD",
  creditNoteCreate: "CREDIT_NOTE_CREATE",
  creditNoteRemove: "CREDIT_NOTE_REMOVE",
  finmidBuyerCompanyCreate: "FINMID_BUYER_COMPANY_CREATE",
  finmidSellerCompanyCreate: "FINMID_SELLER_COMPANY_CREATE",
  xeroCompanyCreate: "XERO_COMPANY_CREATE",
  companyCertificateUpdate: "COMPANY_CERTIFICATE_UPDATE",
  companyCertificateRemove: "COMPANY_CERTIFICATE_REMOVE",
  addBankAccountDetail: "ADD_BANK_ACCOUNT_DETAIL",
  updateBankAccountDetail: "UPDATE_BANK_ACCOUNT_DETAIL",
  removeBankAccountDetail: "REMOVE_BANK_ACCOUNT_DETAIL",
  systemSettingsUpdate: "SYSTEM_SETTINGS_UPDATE",
  systemRepresentativeUpdate: "SYSTEM_REPRESENTATIVE_UPDATE",
  serviceFeeFileCreate: "SERVICE_FEE_FILE_CREATE",
  serviceFeeFileRemove: "SERVICE_FEE_FILE_REMOVE",
};
