import React from "react";
import ControlledTextField from "../../../components/ControlledTextField";
import ControlledComboBox from "../../../components/ControlledComboBox";
import {
  CompanyDetailsStateType,
  CompanyTypesType,
  ProductTypesStateType,
  SubProductTypeStateType,
} from "./interfaces";
import { makeStyles } from "@mui/styles";
import { Checkbox, Chip, FormControlLabel, IconButton } from "@mui/material";
import {
  AttachmentStateType,
  DocumentStateType,
  SchemaType,
  VideosStateType,
} from "./CompanyDetailsContainer";
import { FieldErrors } from "react-hook-form";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import { CountriesStateType } from "../../../slices/authSlice";
import { TRADING_YEARS_FOR_INPUT } from "./constants";
import PermissionWrapper from "../../../components/PermissionWrapper";
interface PropsType {
  onUpdateCoverLogo: (type: any, data: any) => void;
  displayGrowerInfoOnInvoice: boolean;
  displaySupplierInfoOnInvoice: boolean;
  displayCertificates: boolean;
  setDisplayCertificates: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplayGrowerInfoOnInvoice: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplaySupplierInfoOnInvoice: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  control: any;
  confirmAllocation: boolean;
  selectedCountry: string;
  setSelectedCountry: React.Dispatch<React.SetStateAction<string>>;
  setVideos: React.Dispatch<React.SetStateAction<VideosStateType[]>>;
  videos: VideosStateType[];
  setCanAllocateSurplus: React.Dispatch<React.SetStateAction<boolean>>;
  canAllocateSurplus: boolean;
  isSelfBilling: boolean;
  setIsSelfBilling: React.Dispatch<React.SetStateAction<boolean>>;
  setDocuments: React.Dispatch<React.SetStateAction<DocumentStateType[]>>;
  documents: DocumentStateType[];
  setConfirmAllocation: React.Dispatch<React.SetStateAction<boolean>>;
  subProductTypes: SubProductTypeStateType[];
  companyForms: string[];
  errors: FieldErrors<SchemaType>;
  setAttachments: React.Dispatch<React.SetStateAction<AttachmentStateType[]>>;
  attachments: AttachmentStateType[];
  companyTypes: CompanyTypesType[] | undefined;
  companyData: CompanyDetailsStateType | undefined;
  productTypes: ProductTypesStateType[];
}

const tradingTypes = ["Buyer", "Seller", "Buyer & Seller"];
const companyStatus = ["Full Trading Account", "View Only"];

const useStyles = makeStyles({
  checboxBox: {
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    color: "#E77228!important",
  },
  chipPointer: {
    cursor: "pointer!important",
    textDecoration: "disable-underline!important",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  logoComp: {
    border: "0.4px solid black",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px!important",
    cursor: "pointer",
    position: "absolute",
    width: 150,
    height: 150,
    borderRadius: "100%",
    bottom: -40,
    left: 20,
  },
  coverEditSvg: {
    cursor: "pointer",
    position: "absolute",
    width: 70,
    height: 70,
    borderRadius: "100%",
    top: 0,
    right: 0,
  },
  editTextBox: {
    bottom: 0,
    left:30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.4)",
    color: "white",
    width: "60%",
    height: "30%",
    borderRadius: "100%",
    position: "absolute",
  },
});

const GeneralInfoContainer: React.FC<PropsType> = ({
  onUpdateCoverLogo,
  displayGrowerInfoOnInvoice,
  displaySupplierInfoOnInvoice,
  setDisplaySupplierInfoOnInvoice,
  displayCertificates,
  setDisplayCertificates,
  setDisplayGrowerInfoOnInvoice,
  companyData,
  isSelfBilling,
  setIsSelfBilling,
  setConfirmAllocation,
  selectedCountry,
  setSelectedCountry,
  videos,
  setVideos,
  setCanAllocateSurplus,
  attachments,
  setAttachments,
  documents,
  setDocuments,
  confirmAllocation,
  canAllocateSurplus,
  subProductTypes,
  companyForms,
  companyTypes,
  errors,
  control,
  productTypes,
}) => {
  const classes = useStyles();

  return (
    <div className="container">
      <div className="row g-3">
        <div
          style={{
            position: "relative",
            borderRadius: 20,
            backgroundImage: `url(${
              companyData?.coverImage.url ||
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            })`,
            height: 200,
            backgroundSize: "cover",
          }}
        >
         <PermissionWrapper permission="COMPANY_UPDATE">
         <label>
            <img
              className={classes.coverEditSvg}
              src="/static/svg/icon_pen.svg"
            />
            <input
              onChange={(e) => onUpdateCoverLogo("cover", e.target.files?.[0])}
              type="file"
              hidden
              accept=".jpeg,.jpg,.svg,.png"
            />
          </label>
         </PermissionWrapper>
      <PermissionWrapper permission="COMPANY_UPDATE">
      <label>
            <div
              className={classes.logoComp}
              style={{
                backgroundSize:"cover",
                backgroundImage: `url(${
                  companyData?.logo?.url ||
                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                })`,
              }}
            >
              <div className={classes.editTextBox}>Edit</div>
            </div>

            <input
              onChange={(e) => onUpdateCoverLogo("logo", e.target.files?.[0])}
              type="file"
              hidden
              accept=".jpeg,.jpg,.svg,.png"
            />
          </label>
      </PermissionWrapper>
        </div>
        <div className="mt-5"></div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.companyName}
            helperText={errors?.companyName?.message}
            control={control}
            textLabel="Company Name"
            name="companyName"
            disabled
          />
        </div>
        <div className="col-4">
          <ControlledComboBox
            className={classes.comboBox}
            error={!!errors?.companyType}
            helperText={errors?.companyType?.message}
            control={control}
            multiple={true}
            textLabel="Company Type"
            name="companyType"
            options={companyTypes}
          />
        </div>
        <div className="col-4">
          <ControlledComboBox
            className={classes.comboBox}
            error={!!errors?.specialities}
            helperText={errors?.specialities?.message}
            control={control}
            multiple={true}
            textLabel="Company Speciality"
            name="specialities"
            options={productTypes}
          />
        </div>
        <div className="col-4">
          {
            <ControlledComboBox
              className={classes.comboBox}
              error={!!errors?.companyForm}
              helperText={errors?.companyForm?.message}
              defaultValue={companyData?.companyForm}
              control={control}
              textLabel="Company Form"
              name="companyForm"
              options={companyForms}
            />
          }
        </div>
        <div className="col-4">
          {
            <ControlledComboBox
              className={classes.comboBox}
              error={!!errors?.companyTradingType}
              helperText={errors?.companyTradingType?.message}
              defaultValue={companyData?.tradingType}
              control={control}
              textLabel="Company Trading Type"
              name="companyTradingType"
              options={tradingTypes}
            />
          }
        </div>
        <div className="col-4">
          {
            <ControlledComboBox
              className={classes.comboBox}
              multiple={true}
              error={!!errors?.companyTradingType}
              helperText={errors?.companyTradingType?.message}
              control={control}
              textLabel="Buyer Interests"
              name="buyerInterests"
              options={subProductTypes}
            />
          }
        </div>
        <div className="col-4">
          {
            <ControlledComboBox
              className={classes.comboBox}
              multiple={true}
              error={!!errors?.companyTradingType}
              helperText={errors?.companyTradingType?.message}
              control={control}
              textLabel="Seller Interests"
              name="sellerInterests"
              options={subProductTypes}
            />
          }
        </div>
        <div className="col-4">
          {
            <ControlledComboBox
              className={classes.comboBox}
              defaultValue={companyData?.tradingYears.display}
              error={!!errors?.tradingYears}
              helperText={errors?.tradingYears?.message}
              control={control}
              textLabel="Trading Years"
              name="tradingYears"
              options={TRADING_YEARS_FOR_INPUT}
            />
          }
        </div>
        <div className="col-12">
          {
            <ControlledTextField
              error={!!errors?.description}
              helperText={errors?.description?.message}
              control={control}
              textLabel="Description"
              name="description"
            />
          }
        </div>

        <hr />

        <h4>Banking Account</h4>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.nameOfAcc}
            helperText={errors?.nameOfAcc?.message}
            control={control}
            textLabel="Name Of Account"
            name="nameOfAcc"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.sortCode}
            helperText={errors?.sortCode?.message}
            control={control}
            textLabel="Sort Code"
            name="sortCode"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            control={control}
            error={!!errors?.accNumber}
            helperText={errors?.accNumber?.message}
            textLabel="Account Number"
            name="accNumber"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.nameOfBank}
            helperText={errors?.nameOfBank?.message}
            control={control}
            textLabel="Name Of Bank"
            name="nameOfBank"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.IBAN}
            helperText={errors?.IBAN?.message}
            control={control}
            textLabel="IBAN Number"
            name="IBAN"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            control={control}
            error={!!errors?.BIC}
            helperText={errors?.BIC?.message}
            textLabel="BIC(Swift Code)"
            name="BIC"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.paymentTerm}
            helperText={errors?.paymentTerm?.message}
            control={control}
            textLabel="Payment Term"
            name="paymentTerm"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            control={control}
            error={!!errors?.arrReportDue}
            helperText={errors?.arrReportDue?.message}
            textLabel="Arrival Report Due"
            name="arrReportDue"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.recPeriod}
            helperText={errors?.recPeriod?.message}
            control={control}
            textLabel="Final Reconciliation Period"
            name="recPeriod"
          />
        </div>

        <hr />

        <h4>Company Status</h4>

        <div className="col-6">
          {companyData?.status && (
            <ControlledComboBox
              error={!!errors?.status}
              helperText={errors?.status?.message}
              defaultValue={
                companyData?.status === "VERIFIED"
                  ? "Full Trading Account"
                  : "View Only"
              }
              control={control}
              textLabel="Company Status"
              name="status"
              options={companyStatus}
            />
          )}
        </div>

        <hr />
        <h4>Permissions</h4>

        <div className={`${classes.checboxBox} col-3`}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setCanAllocateSurplus(e.target.checked)}
                className={classes.checkbox}
                checked={canAllocateSurplus}
              />
            }
            label="Can allocate surplus?"
          />
        </div>
        <div className={`${classes.checboxBox} col-3`}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setConfirmAllocation(e.target.checked)}
                className={classes.checkbox}
                checked={confirmAllocation}
              />
            }
            label="Confirm allocation?"
          />
        </div>
        <div className={`${classes.checboxBox} col-3`}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setIsSelfBilling(e.target.checked)}
                className={classes.checkbox}
                checked={isSelfBilling}
              />
            }
            label="Self billing permission?"
          />
        </div>
        <div className={`${classes.checboxBox} col-3`}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setDisplayCertificates(e.target.checked)}
                className={classes.checkbox}
                checked={displayCertificates}
              />
            }
            label="Display certificates?"
          />
        </div>
        <div className={`${classes.checboxBox} col-4`}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  setDisplayGrowerInfoOnInvoice(e.target.checked)
                }
                className={classes.checkbox}
                checked={displayGrowerInfoOnInvoice}
              />
            }
            label="Display grower info on invoice?"
          />
        </div>
        <div className={`${classes.checboxBox} col-4`}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  setDisplaySupplierInfoOnInvoice(e.target.checked)
                }
                className={classes.checkbox}
                checked={displaySupplierInfoOnInvoice}
              />
            }
            label="Display supplier info on invoice?"
          />
        </div>
        <hr/>

        {/* {attachments.length ? (
          <>
            <h4>Attachments</h4>

            {attachments.map((att,index) => {
              return (
                <div key={index} className="col-6">
                  {" "}
                  <Chip label={`${att.name}`} />
                  <IconButton
                    onClick={() => {
                      const newAtts = attachments.filter(
                        (item) => item.name !== att.name
                      );
                      setAttachments(newAtts);
                    }}
                  >
                    <i className="ri-delete-bin-line"></i>
                  </IconButton>
                </div>
              );
            })}
          </>
        ) : null} */}
        {/* {documents?.length ? (
          <>
            <hr />

            <h4>Documents</h4>

            {documents.map((att,index) => {
              return (
                <div key={index} className="col-6">
                  <a
                    className={classes.chipPointer}
                    href={att.url}
                    target="_blank"
                  >
                    <Chip
                      label={`${att.name} 

      `}
                    />
                  </a>
                  <IconButton
                    onClick={() => {
                      const newAtts = documents.filter(
                        (item) => item.name !== att.name
                      );
                      setDocuments(newAtts);
                    }}
                  >
                    <i className="ri-delete-bin-line"></i>
                  </IconButton>
                </div>
              );
            })}
          </>
        ) : null} */}
        {/* {videos.length ? (
          <>
            <hr />

            <h4>Videos</h4>

            {videos.map((att, index) => {
              return (
                <div key={index} className="col-6">
                  <a
                    className={classes.chipPointer}
                    href={att.videoUrl}
                    target="_blank"
                  >
                    <Chip
                      label={`${att.videoUrl} 

`}
                    />
                  </a>
                  <IconButton
                    onClick={() => {
                      const newAtts = videos.filter(
                        (item) => item.videoUrl !== att.videoUrl
                      );
                      setVideos(newAtts);
                    }}
                  >
                    <i className="ri-delete-bin-line"></i>
                  </IconButton>
                </div>
              );
            })}
          </>
        ) : null} */}

        <h4>Invoice Contact Details</h4>

        <div className="col-4">
          <ControlledTextField
            error={!!errors?.invoiceFullName}
            helperText={errors?.invoiceFullName?.message}
            control={control}
            textLabel="Full Name"
            name="invoiceFullName"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.invoiceEmail}
            helperText={errors?.invoiceEmail?.message}
            control={control}
            textLabel="Email"
            name="invoiceEmail"
          />
        </div>
        <div className="col-4">
          <PhoneNumberInput
            error={!!errors?.invoicePhoneNumber}
            helperText={errors?.invoicePhoneNumber?.message}
            control={control}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            name="invoicePhoneNumber"
          />
        </div>

        <hr />

        <h4>Social Media</h4>

        <div className="col-4">
          <ControlledTextField
            error={!!errors?.linkedin}
            helperText={errors?.linkedin?.message}
            control={control}
            textLabel="Linkedin"
            name="linkedin"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.facebook}
            helperText={errors?.facebook?.message}
            control={control}
            textLabel="Facebook"
            name="facebook"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.instagram}
            helperText={errors?.instagram?.message}
            control={control}
            textLabel="Instagram"
            name="instagram"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.twitter}
            helperText={errors?.twitter?.message}
            control={control}
            textLabel="Twitter"
            name="twitter"
          />
        </div>
        <div className="col-4">
          <ControlledTextField
            error={!!errors?.companyWebsite}
            helperText={errors?.companyWebsite?.message}
            control={control}
            textLabel="Company Website"
            name="companyWebsite"
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralInfoContainer;
