import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ControlledTextField from "../../components/ControlledTextField";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import { makeStyles } from "@mui/styles";
import ControlledComboBox from "../../components/ControlledComboBox";
import { useAppSelector } from "../../hooks/hooks";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { getTrimmedString } from "../../services/common";
import { addNewCompany } from "../../services/company";
import { toast } from "react-toastify";
import LoadingIndicator from "../../components/LoadingIndicator";

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  hr: {
    opacity: 0.05,
  },
  close: {
    borderRadius: "20px!important",
    color: "#e77228!important",

    background: "transparent!important",
    border: "1px solid #E77228!important",
  },
  submit: {
    borderRadius: "20px!important",

    background: "#E77228!important",
  },
});

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  surname: yup.string().required("Surname is required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  accountEmail: yup.string().email("Must be a valid email"),

  phoneNumber: yup.string().required("Phone number is required"),
  companyName: yup.string().required("Company name is required"),
  VAT: yup.string(),
  CRN: yup.string(),
  role: yup.string().required("Role is required"),
  country: yup.object().required("Country is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  postCode: yup.string().required("Post Code is required"),
  address: yup.string().required("Address is required"),
});
const AddCompanyModalBody = ({
  addCompanyModalType,
  invoiceJSON,
  setRefreshCompanyRequest,
  setOpenModal,
}: {
  invoiceJSON: any;
  addCompanyModalType: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshCompanyRequest: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [loading, setLoading] = useState<boolean>(false);
  const countries = useAppSelector((state) => state.auth.countries);

  const [selectedCountry, setSelectedCountry] = useState("");

  const defaultPhoneCode = countries.find((item) =>
    addCompanyModalType === "seller"
      ? invoiceJSON?.supplier_phone?.text?.includes(`+${item?.phone}`)
      : invoiceJSON?.receiver_phone?.text?.includes(item?.phone)
  );

  const onCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setSelectedCountry(defaultPhoneCode?.phone || "");
    setValue(
      "address",
      addCompanyModalType === "seller"
        ? invoiceJSON?.supplier_address?.text
        : invoiceJSON?.receiver_address?.text
    );
    setValue(
      "companyName",
      addCompanyModalType === "seller"
        ? invoiceJSON?.supplier_name?.text
        : invoiceJSON?.receiver_name?.text
    );
    setValue(
      "accountEmail",
      addCompanyModalType === "seller"
        ? invoiceJSON?.supplier_email?.text
        : invoiceJSON?.receiver_email?.text
    );
    setValue(
      "VAT",
      addCompanyModalType === "seller"
        ? invoiceJSON?.supplier_tax_id?.text
        : invoiceJSON?.receiver_tax_id?.text
    );
    setValue(
      "phoneNumber",
      addCompanyModalType === "seller"
        ? defaultPhoneCode?.phone
          ? invoiceJSON?.supplier_phone?.text?.split(defaultPhoneCode?.phone)[1]
          : invoiceJSON?.supplier_phone?.text
        : defaultPhoneCode?.phone
        ? invoiceJSON?.receiver_phone?.text?.split(defaultPhoneCode?.phone)[1]
        : invoiceJSON?.receiver_phone?.text
    );
  }, []);

  const onAddCompany = async (data: any) => {
    const dataScheme = {
      name: data?.name,
      surname: data?.surname,
      email: data?.email,
      mobileNo: `${selectedCountry}${data?.phoneNumber}`,
      companyName: data?.companyName,
      address: data?.address,
      postCode: data?.postCode,
      city: data?.city,
      state: data?.state,
      country: data?.country?.name,
      role: data?.role,
      CRN: data?.CRN,
      VAT: data?.VAT,
      accountEmail: data?.accountEmail,
    };
    const finalData = getTrimmedString(dataScheme);
    setLoading(true);

    if (!selectedCountry) {
      setLoading(false);
      toast.error("Please select phone number code.");
    } else {
      const added = await addNewCompany(finalData);
      setLoading(false);

      if (added) {
        setOpenModal(false);
        setRefreshCompanyRequest((prev: number) => prev + 1);
      }
    }
  };
  return (
    <div>
      <span className="fw-bold">User Information</span>
      {loading && <LoadingIndicator loading />}
      <hr className={classes.hr} />
      <div className="row g-4">
        <div className="col-md-4">
          <ControlledTextField
            textLabel="Name"
            name="name"
            control={control}
            error={!!errors.name}
            helperText={errors?.name?.message as string}
          />
        </div>
        <div className="col-md-4">
          <ControlledTextField
            textLabel="Surname"
            name="surname"
            control={control}
            error={!!errors.surname}
            helperText={errors?.surname?.message as string}
          />
        </div>
        <div className="col-md-4">
          <ControlledTextField
            textLabel="Email"
            name="email"
            control={control}
            error={!!errors.email}
            helperText={errors?.email?.message as string}
          />
        </div>
        <div className="col-md-4">
          <ControlledComboBox
            options={["ADMIN"]}
            className={classes.comboBox}
            textLabel="Role"
            name="role"
            control={control}
            error={!!errors.role}
            helperText={errors?.role?.message as string}
          />
        </div>
        <div className="col-md-4">
          <PhoneNumberInput
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            name="phoneNumber"
            control={control}
            error={!!errors.phoneNumber}
            helperText={errors?.phoneNumber?.message as string}
          />
        </div>
        <div className="col-md-4">
          <ControlledTextField
            textLabel="Account Email"
            name="accountEmail"
            control={control}
            error={!!errors.accountEmail}
            helperText={errors?.accountEmail?.message as string}
          />
        </div>
      </div>
      <div className="mt-3">
        <span className="fw-bold">Company Information</span>
        <hr className={classes.hr} />
        <div className="row g-4">
          <div className="col-md-4">
            <ControlledTextField
              textLabel="Company Name"
              name="companyName"
              control={control}
              error={!!errors.companyName}
              helperText={errors?.companyName?.message as string}
            />
          </div>
          {
            <>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="Company House Registration(CRN)"
                  name="CRN"
                  control={control}
                  error={!!errors.CRN}
                  helperText={errors?.CRN?.message as string}
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="VAT Number"
                  name="VAT"
                  control={control}
                  error={!!errors.VAT}
                  helperText={errors?.VAT?.message as string}
                />
              </div>
              <div className="col-md-4">
                <ControlledComboBox
                  className={classes.comboBox}
                  options={countries.map((item) => ({
                    id: item.geonameId,
                    name: item.countryName,
                  }))}
                  textLabel="Country"
                  name="country"
                  control={control}
                  error={!!errors.country}
                  helperText={errors?.country?.message as string}
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="State"
                  name="state"
                  control={control}
                  error={!!errors.state}
                  helperText={errors?.state?.message as string}
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="City"
                  name="city"
                  control={control}
                  error={!!errors.city}
                  helperText={errors?.city?.message as string}
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="Post Code"
                  name="postCode"
                  control={control}
                  error={!!errors.postCode}
                  helperText={errors?.postCode?.message as string}
                />
              </div>
              <div className="col-md-12">
                <ControlledTextField
                  textLabel="Address"
                  name="address"
                  control={control}
                  error={!!errors.address}
                  helperText={errors?.address?.message as string}
                />
              </div>
            </>
          }
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-end gap-3">
        <AdminButtonComponent
          className={classes.close}
          onClick={onCloseModal}
          title="Close"
        />
        <AdminButtonComponent
          className={classes.submit}
          onClick={handleSubmit(onAddCompany)}
          title="Submit"
        />
      </div>
    </div>
  );
};

export default AddCompanyModalBody;
