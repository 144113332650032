import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import {
  getUsers,
  blockUsers,
  unblockUsers,
  impersonateUser,
  addNewUser,
  editUser,
} from "../../services/user";
import { VerifyTwoFa } from "../../services/auth";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import LoadingIndicator from "../../components/LoadingIndicator";
import SearchTextField from "../../components/SearchTextField";
import AdminModalComponent from "../../components/AdminModalComponent";
import ControlledTextField from "../../components/ControlledTextField";
import OTPInput from "../../components/AdminOtpInput";
import { useAppSelector } from "../../hooks/hooks";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import moment from "moment";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {},
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  confirmationText: {
    textAlign: "center",
  },
  fullNameText: {
    color: "#E77228!important",
    cursor: "pointer",
    textDecoration: "underline",
  },
  detailLineItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  boldText: {
    fontWeight: "600!important",
  },
  detailContainer: {
    display: "flex",
    gap: 20,
    flexDirection: "column",
  },
  edit: {
    color: "#E77228",
  },
  PhoneInput: {
    background: "#F7F7F7",
    fontSize: 20,
    borderRadius: "10px !important",
    width: "330px",
    height: 50,
    padding: 15,
  },
  actionsCell: {
    width: 180,
  },
  cover: {
    width: 40,
    height: 40,
    borderRadius: "100%",
  },
});

interface QueryParamsType {
  limit: number;
  total: number;
  totalPage: number;
  page: number;
}

export interface UsersResponseType {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  country: Country;
  type: string;
  status: string;
  createdAt: string;
  photo: Photo;
  companyId: string;
  undefined: string;
  exportedAt: string;
  firebaseUserId: string;
  companySlug: string;
  isReceiveEmail: boolean;
  companyStatus: string;
  companyInfo: CompanyInfo;
  companyName: string;
  isVerified: boolean;
  verifiedAt: string;
  userSlug: string;
  company: Company;
  id: string;
}

export interface Country {
  code: string;
  name: string;
}

export interface Photo {
  name: string;
  size: number;
  path: string;
  thumbPath: string;
  url: string;
  thumbUrl: string;
}

export interface CompanyInfo {
  types: Type[];
  specialities: Speciality[];
}

export interface Type {
  _id: string;
  name: string;
  code: string;
}

export interface Speciality {
  _id: string;
  name: string;
  code: string;
}

export interface Company {
  _id: string;
  companyName: string;
  registerAddress: string;
  principalPlaceAddress: string;
  registerCountry: RegisterCountry;
  principalPlaceCountry: PrincipalPlaceCountry;
  status: string;
  specialities: Speciality2[];
  types: Type2[];
}

export interface RegisterCountry {
  code: number;
  name: string;
}

export interface PrincipalPlaceCountry {
  code: number;
  name: string;
}

export interface Speciality2 {
  _id: string;
  name: string;
  code: string;
}

export interface Type2 {
  _id: string;
  name: string;
  code: string;
}

interface SchemaType {
  keyword: string;
  reason: string;
  firebaseEmail?: string;
  firebasePhoneNumber?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const validationSchema = yup.object().shape({
  keyword: yup.string(),
  reason: yup.string(),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email().required("Email is required"),
  firebaseEmail: yup.string().email("Must be a valid email"),
  firebasePhoneNumber: yup.string(),
  phoneNumber: yup.string().required("Phone Number is required"),
});

const columns = [
  { name: "", id: "photo" },
  { name: "Name", id: "fullName" },
  { name: "Email", id: "email" },
  { name: "Country", id: "country" },
  { name: "Phone Number", id: "phoneNumber" },
  { name: "Company Name", id: "companyName" },
  { name: "", id: "impersonate" },
  { name: "", id: "block" },
];

const UsersContainer: React.FC = () => {
  const classes = useStyles();
  const { hasPermission } = usePermissions();
  const {
    watch,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const user = useAppSelector((state) => state?.auth?.adminUserInfo);
  const countries = useAppSelector((state) => state?.auth?.countries);

  const watchFirebasePhoneNumber = watch("firebasePhoneNumber");
  const watchFirebaseEmail = watch("firebaseEmail");
  const watchReason = watch("reason");

  const watchKeyword = watch("keyword");

  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const [refreshToken, setRefreshToken] = useState<number>(0);
  const [otp, setOtp] = useState<string>("");
  const [open2faModal, setOpen2faModal] = useState<boolean>(false);
  const [selectedCountryEdit, setSelectedCountryEdit] = useState<string>("");
  const [openAddNewUserModal, setOpenAddNewUserModal] =
    useState<boolean>(false);
  const [openBlockReasonModal, setOpenBlockReasonModal] =
    useState<boolean>(false);
  const [openUnblockConfirmationModal, setOpenUnblockConfirmationModal] =
    useState<boolean>(false);
  const [openUserDetailsModal, setOpenUserDetailsModal] =
    useState<boolean>(false);
  const [selectedFirebaseData, setSelectedFirebaseData] = useState({
    firebaseEmail: "",
    firebasePhoneNumber: "",
    firebaseId: "",
  });
  const [openCompanyDetailsModal, setOpenCompanyDetailsModal] =
    useState<boolean>(false);
  const [openEditUserModal, setOpenEditUserModal] = useState<boolean>(false);
  const [selectedUserDetails, setSelectedUserDetails] =
    useState<UsersResponseType>();
  const [queryParams, setQueryParams] = useState<QueryParamsType>({
    limit: 1,
    total: 1,
    totalPage: 1,
    page: 1,
  });
  const [selectedCountry, setSelectedCountry] = useState<string>("");

  const [users, setUsers] = useState<UsersResponseType[]>([]);

  const editedDataDefaultPhoneCode = countries.find((item) =>
    selectedFirebaseData.firebasePhoneNumber.includes(`+${item.phone}`)
  )?.phone;

  useEffect(() => {
    if (!hasPermission("USER_LISTING_VIEW")) return;

    const getAllUsers = async () => {
      setFetchLoading(true);
      const response = await getUsers(queryParams.page, watchKeyword || "");

      setTimeout(() => {
        setFetchLoading(false);
        if (response) {
          setUsers(response.items);
          setQueryParams({
            page: response.metaData.page,
            limit: response.metaData.limit,
            total: response.metaData.total,
            totalPage: response.metaData.totalPage,
          });
        }
      }, 1000);
    };
    getAllUsers();
  }, [queryParams.page, refreshToken, hasPermission("USER_LISTING_VIEW")]);

  useEffect(() => {
    if (selectedFirebaseData.firebaseId) {
      setSelectedCountryEdit(editedDataDefaultPhoneCode || "");
      reset({
        firebaseEmail: selectedFirebaseData.firebaseEmail,
        firebasePhoneNumber: selectedFirebaseData.firebasePhoneNumber.split(
          editedDataDefaultPhoneCode || ""
        )[1],
      });
    }
  }, [selectedFirebaseData]);

  const onOpenUserEditModal = (data: any) => {
    setOpenEditUserModal(true);
    setSelectedFirebaseData(data);
  };
  const onCloseUserEditModal = () => {
    setOpenEditUserModal(false);
  };

  const renderTableBody = () => {
    return (
      <>
        {users.map((user, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <img className={classes.cover} src={user.photo.url} />
              </TableCell>
              <TableCell component="th" scope="row">
                <span
                  onClick={() => {
                    setSelectedUserDetails(user);
                    setOpenUserDetailsModal(true);
                  }}
                  className={classes.fullNameText}
                >
                  {user.fullName}
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                {user.email}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.country?.name || "N/A"}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.phoneNumber}
              </TableCell>
              <TableCell component="th" scope="row">
                <span
                  onClick={() => {
                    setSelectedUserDetails(user);
                    setOpenCompanyDetailsModal(true);
                  }}
                  className={classes.fullNameText}
                >
                  {" "}
                  {user.company?.companyName || "N/A"}
                </span>
              </TableCell>
              <TableCell>
                <PermissionWrapper permission="COMPANY_ADMINUSER_IMPERSONATE">
                  <AdminButtonComponent
                    disabled={user.status === "BLOCKED"}
                    onClick={() => {
                      onImpersonateUser(user.id);
                      // setOpen2faModal(true);
                      // setUserId(user.id);
                    }}
                    title="Impersonate"
                  />
                </PermissionWrapper>
              </TableCell>
              <TableCell className={classes.actionsCell}>
                {user.status === "BLOCKED" ? (
                  <Tooltip title="Unblock user">
                    <PermissionWrapper permission="USER_UNBLOCK">
                      <IconButton
                        onClick={() => {
                          setOpenUnblockConfirmationModal(true);
                          setUserId(user.id);
                        }}
                      >
                        <i className="ri-lock-unlock-line"></i>
                      </IconButton>
                    </PermissionWrapper>
                  </Tooltip>
                ) : (
                  <Tooltip title="Block user">
                    <PermissionWrapper permission="USER_BLOCK">
                      <IconButton
                        onClick={() => {
                          setUserId(user.id);
                          setOpenBlockReasonModal(true);
                        }}
                      >
                        <i className="ri-lock-line"></i>
                      </IconButton>
                    </PermissionWrapper>
                  </Tooltip>
                )}
                {/* <Tooltip title="Edit user's email or phone number">
                  <PermissionWrapper permission="USER_EDIT_DETAILS_QUICK">
                    <IconButton
                      onClick={() =>
                        onOpenUserEditModal({
                          firebasePhoneNumber: user.phoneNumber,
                          firebaseEmail: user.email,
                          firebaseId: user.firebaseUserId,
                        })
                      }
                    >
                      <i className={`ri-pencil-line`}></i>
                    </IconButton>
                  </PermissionWrapper>
                </Tooltip> */}
                <Tooltip title="See user's Detail">
                  <PermissionWrapper permission="USER_EDIT_DETAILS_VIEW">
                    <a
                      target="_blank"
                      href={`/users/${user.userSlug}`}
                      rel="noreferrer"
                    >
                      <IconButton>
                        <i className="ri-eye-line"></i>
                      </IconButton>
                    </a>
                  </PermissionWrapper>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  //search
  const onSearch = async () => {
    setLoading(true);
    const response = await getUsers(queryParams.page, watchKeyword as any);

    setTimeout(() => {
      setLoading(false);
      setUsers(response.items);
      setQueryParams({
        page: response.metaData.page,
        limit: response.metaData.limit,
        total: response.metaData.total,
        totalPage: response.metaData.totalPage,
      });
    }, 1000);
  };

  const renderSearchTextField = () => {
    return (
      <SearchTextField
        onKeyPress={onSearch}
        onSearchClick={onSearch}
        control={control}
        name="keyword"
      />
    );
  };

  //block reason modal

  const onBlockUser = async () => {
    setLoading(true);

    const userIds = [`${userId}`];

    const data = {
      userIds: userIds,
      blockReason: watchReason,
    };
    await blockUsers(data as any);
    setRefreshToken(() => refreshToken + 1);
    setLoading(false);
    setOpenBlockReasonModal(false);
  };

  const onUnBlockUser = async () => {
    setLoading(true);

    const userIds = [`${userId}`];

    await unblockUsers(userIds);
    setRefreshToken(() => refreshToken + 1);
    setLoading(false);
    setOpenUnblockConfirmationModal(false);
  };

  const onCloseBlockUserModal = () => {
    setOpenBlockReasonModal(false);
  };

  const onCloseUnblockConfirmationModal = () => {
    setOpenUnblockConfirmationModal(false);
  };

  const renderBlockReasonModal = () => {
    return (
      <>
        <ControlledTextField
          name="reason"
          control={control}
          textLabel="Reason"
        />

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenBlockReasonModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={loading || !watchReason}
            className={classes.saveButton}
            onClick={onBlockUser}
            title="Block"
          />
        </Box>
      </>
    );
  };

  const renderUnblockUserConfirmationModal = () => {
    return (
      <Box>
        <p className={classes.confirmationText}>
          Are you sure you want to remove block from this user?
        </p>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenUnblockConfirmationModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={loading}
            className={classes.saveButton}
            onClick={onUnBlockUser}
            title="Unblock"
          />
        </Box>
      </Box>
    );
  };

  //impersonation

  // const onVerifyImpersonate = async () => {
  //   setLoading(true);
  //   const response = await VerifyTwoFa(otp, user.email);
  //   setTimeout(() => {
  //     setLoading(false);

  //     if (response === 200) {
  //       setOpen2faModal(false);
  //       setOtp("");
  //       onImpersonateUser(userId);
  //     }
  //   }, 1000);
  // };

  useEffect(() => {
    document.getElementById("verifyOTP")?.focus();
  }, []);

  // useEffect(() => {
  //   if (otp.length === 6) {
  //     onVerifyImpersonate();
  //   }
  // }, [otp]);

  const onClose2faModal = () => {
    setOpen2faModal(false);
  };

  const render2faModalBody = () => {
    return (
      <>
        <div>
          <OTPInput
            id="verifyOTP"
            differentBackground={true}
            numInputs={6}
            value={otp}
            onChange={setOtp}
          />
        </div>
      </>
    );
  };

  const onImpersonateUser = async (userId: string) => {
    setLoading(true);
    const response = await impersonateUser(userId);

    setTimeout(() => {
      setLoading(false);
      if (response) {
        window.open(
          `${process.env.REACT_APP_MARKETPLACE_URL}/impersonation?signature=${response.signature}&adminEmail=${user.email}`,
          "_blank"
        );
      }
    }, 1000);
  };

  const onEditUser = async () => {
    setLoading(true);

    if (!selectedCountryEdit) {
      setLoading(false);
      toast.error("Please select phone number code");
    } else {
      const finalData = {
        firebaseUserId: selectedFirebaseData.firebaseId,
        phoneNumber:
          `+${selectedCountryEdit}${watchFirebasePhoneNumber}` ===
          selectedFirebaseData.firebasePhoneNumber
            ? undefined
            : watchFirebasePhoneNumber
            ? `+${selectedCountryEdit}${watchFirebasePhoneNumber}`
            : undefined,
        email:
          watchFirebaseEmail === selectedFirebaseData.firebaseEmail
            ? undefined
            : watchFirebaseEmail
            ? watchFirebaseEmail
            : undefined,
      };

      const edited = await editUser(finalData);
      setLoading(false);

      if (edited) {
        setOpenEditUserModal(false);
        setRefreshToken(() => refreshToken + 1);
      }
    }
  };

  //add new user

  const onCloseAddNewUserModal = () => {
    setOpenAddNewUserModal(false);
  };

  const onAddUser = async (data: any) => {
    const body = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phoneNumber: `+${selectedCountry}${data?.phoneNumber}`,
    };
    setLoading(true);
    const response = await addNewUser(body);
    setTimeout(() => {
      setLoading(false);
      if (response === 200) {
        reset({});
        setOpenAddNewUserModal(false);
        setRefreshToken(() => refreshToken + 1);
      }
    }, 1000);
  };

  const renderAddNewUserModal = () => {
    return (
      <div className="container">
        <div className="row g-4">
          <ControlledTextField
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            textLabel="First Name"
            name="firstName"
            control={control}
          />
          <ControlledTextField
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            textLabel="Last Name"
            name="lastName"
            control={control}
          />
          <ControlledTextField
            error={!!errors.email}
            helperText={errors.email?.message}
            textLabel="Email"
            name="email"
            control={control}
          />

          <PhoneNumberInput
            className={classes.PhoneInput}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
            name="phoneNumber"
            control={control}
          />
        </div>

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenAddNewUserModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={loading}
            className={classes.saveButton}
            onClick={handleSubmit(onAddUser)}
            title="Submit"
          />
        </Box>
      </div>
    );
  };

  //user detail modal

  const onCloseUserDetailModal = () => {
    setOpenUserDetailsModal(false);
  };

  const renderUserDetailsModal = () => {
    return (
      <div className={classes.detailContainer}>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Company Name</Typography>
          <Typography>
            {selectedUserDetails?.company?.companyName || "N/A"}
          </Typography>
        </div>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Company Role</Typography>
          <Typography>
            {selectedUserDetails?.type ? (
              <Chip label={selectedUserDetails?.type} />
            ) : (
              "N/A"
            )}
          </Typography>
        </div>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Company Type</Typography>
          {selectedUserDetails?.company?.types &&
          selectedUserDetails?.company?.types.length > 2 ? (
            <Typography>
              {`${selectedUserDetails?.company?.types[0]?.name},${selectedUserDetails?.company?.types[1]?.name}...`}
            </Typography>
          ) : (
            <Typography>
              {selectedUserDetails?.company?.types
                ?.map((item) => item?.name)
                .join(",") || "N/A"}
            </Typography>
          )}
        </div>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Country</Typography>
          <Typography>{selectedUserDetails?.country?.name || "N/A"}</Typography>
        </div>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Created Time</Typography>
          <Typography>
            {moment(selectedUserDetails?.createdAt).format("DD/MM/YYYY") ||
              "N/A"}
          </Typography>
        </div>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Email</Typography>
          <Typography>{selectedUserDetails?.email || "N/A"}</Typography>
        </div>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Full Name</Typography>
          <Typography>{selectedUserDetails?.fullName || "N/A"}</Typography>
        </div>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Phone Number</Typography>
          <Typography>{selectedUserDetails?.phoneNumber || "N/A"}</Typography>
        </div>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Speciality</Typography>
          <Typography>
            {selectedUserDetails?.company?.specialities
              ?.map((item) => item?.name)
              .join(",") || "N/A"}
          </Typography>
        </div>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Status</Typography>
          <Typography>
            {selectedUserDetails?.status === "ACTIVATED" ? (
              <Chip color="success" label={selectedUserDetails?.status} />
            ) : selectedUserDetails?.status === "BLOCKED" ? (
              <Chip color="error" label={selectedUserDetails?.status} />
            ) : (
              <Chip color="warning" label={selectedUserDetails?.status} /> ||
              "N/A"
            )}
          </Typography>
        </div>
      </div>
    );
  };

  //company details

  const onCloseCompanyDetailModal = () => {
    setOpenCompanyDetailsModal(false);
  };

  const renderCompanyDetailsModal = () => {
    return (
      <div className={classes.detailContainer}>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Company Name</Typography>
          <Typography>
            {selectedUserDetails?.company?.companyName || "N/A"}
          </Typography>
        </div>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Company Type</Typography>
          <Typography>
            {selectedUserDetails?.company?.types
              ?.map((item) => item.name)
              .join(",") || "N/A"}
          </Typography>
        </div>
        <div className={classes.detailLineItem}>
          <Typography className={classes.boldText}>Status</Typography>
          <Typography>
            {<Chip label={selectedUserDetails?.company?.status} /> || "N/A"}
          </Typography>
        </div>
      </div>
    );
  };

  const renderEditUserModal = () => {
    return (
      <>
        <div className="row g-2">
          <ControlledTextField
            textLabel="Email"
            control={control}
            name="firebaseEmail"
          />
          <PhoneNumberInput
            className={classes.PhoneInput}
            selectedCountry={selectedCountryEdit}
            setSelectedCountry={setSelectedCountryEdit}
            control={control}
            name="firebasePhoneNumber"
          />
        </div>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={onCloseUserEditModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            loading={loading}
            disabled={loading}
            className={classes.saveButton}
            onClick={onEditUser}
            title="Submit"
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Platform Users | FruPro Admin Portal</title>
      </Helmet>
      <Sidebar pageTitle="Users List">
        {loading && <LoadingIndicator loading />}
        <PermissionWrapper permission="USER_LISTING_VIEW" unauthorizedComponent>
          <TableComponent
            loading={fetchLoading}
            setOpenModal={setOpenAddNewUserModal}
            RenderSearchTextField={renderSearchTextField()}
            headerButtonTitle="+Add New User"
            columns={columns}
            TableBodyComponent={renderTableBody}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            showRightButton={true}
          />
        </PermissionWrapper>
        <AdminModalComponent
          headerTitle="Block user"
          children={renderBlockReasonModal()}
          openModal={openBlockReasonModal}
          closeModal={onCloseBlockUserModal}
        />
        <AdminModalComponent
          headerTitle="Are you sure"
          children={renderUnblockUserConfirmationModal()}
          openModal={openUnblockConfirmationModal}
          closeModal={onCloseUnblockConfirmationModal}
        />
        <AdminModalComponent
          headerTitle="Enter your verification code"
          children={render2faModalBody()}
          openModal={open2faModal}
          closeModal={onClose2faModal}
        />
        <AdminModalComponent
          headerTitle="Add new user"
          children={renderAddNewUserModal()}
          openModal={openAddNewUserModal}
          closeModal={onCloseAddNewUserModal}
        />
        <AdminModalComponent
          headerTitle="User Details"
          children={renderUserDetailsModal()}
          openModal={openUserDetailsModal}
          closeModal={onCloseUserDetailModal}
        />
        <AdminModalComponent
          headerTitle="Company Details"
          children={renderCompanyDetailsModal()}
          openModal={openCompanyDetailsModal}
          closeModal={onCloseCompanyDetailModal}
        />
        <AdminModalComponent
          headerTitle="Edit user's email or phone number"
          children={renderEditUserModal()}
          openModal={openEditUserModal}
          closeModal={onCloseUserEditModal}
        />
      </Sidebar>
    </>
  );
};

export default UsersContainer;
