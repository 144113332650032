import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getRemittanceReports = async (filterData: any, page: number) => {
  try {
    const response = await axiosApiInstance.post(
      `/remittance/get-list-admin?page=${page}`,
      {
        ...filterData,
        order:"-payoutDate",
      }
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getRemittanceReportsSummary = async (
  filterData: any,
  page: number
) => {
  try {
    const response = await axiosApiInstance.post(
      `/remittance/get-summary?page=${page}`,
      {
        ...filterData,
        order:"-payoutDate",
      }
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const getDocumentsWithSearchKey = async (searchKey: string) => {
  try {
    const response = await axiosApiInstance.post(
      `/admin/search-order-documents`,
      {
        searchKeys: searchKey,
      }
    );

    if (response?.data?.success) {
      return response.data;
    } else {
      toast.error(response?.data.message);
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const makeDocumentRequest = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/documents/admin/order-document-request`,
      {
        ...data,
      }
    );

    if (response?.data?.success) {
      toast.success("Request is successful.");
      return response.data;
    } else {
      toast.error(response?.data.message);
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const exportExcelToRemittanceSummary = async (filterData: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/remittance/get-summary?page=${0}`,
      {
        ...filterData,
      },
      
    );

    if (response) {
      toast.success("Data exported successfully.");
      return response.data
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};

export const exportExcelToRemittanceDetailed = async (filterData: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/remittance/get-list-admin?page=${0}`,
      {
        ...filterData,
      },
      
    );

    if (response) {
      toast.success("Data exported successfully.");
      return response.data
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error.message);
    }
  }
};
