import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import { sidebarItems } from "./SidebarItems";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks/hooks";
import { logout, setTradeAppLoading } from "../slices/authSlice";
import { useAppSelector } from "../hooks/hooks";
import LoadingIndicator from "./LoadingIndicator";
import usePermissions from "../hooks/usePermissions";
import PermissionWrapper from "./PermissionWrapper";
import { goToTradeApp } from "../services/common";

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarJustNavbar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `100%)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const useStyles = makeStyles({
  navbar: {
    background: "#E77228",
  },

  logo: {
    width: 50,
    height: 50,
    borderRadius: 20,
  },

  sidebarContainer: {
    width: "600px",
  },

  logoSidebarOpen: {
    width: "100%",
    height: 50,
    borderRadius: 20,
    position: "absolute",
    margin: "15px 5px 0px 0px",
    paddingRight: "15px",
  },

  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },

  appBar: {
    zIndex: "9999999999999!important",
    background: "#87d7be !important",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px!important",
  },
  nameBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContents: "center",
    marginTop: "40px",
    marginBottom: "10px",
  },

  nameBoxAppBar: {
    alignItems: "center",
    alignContents: "center",
    display: "flex!important",
    justifyContent: "center!important",
  },

  nameText: {
    fontSize: 18,
    color: "gray",
    fontWeight: "bold",
  },
  mainBox: {
    minHeight: "100vh",
    background: "#F8F8F8",
    paddingTop: "80px !important",
  },
  pageTitle: {
    color: "#E77228",
    fontSize: 26,
    fontWeight: 500,
  },
  sidebarLinks: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    textDecoration: "none",
    color: "black",
  },
  listItem: {
    "&:hover": {
      background: "transparent!important",
      color: "#69CDAE !important",
    },
  },
  chevronLeftCloseIndex: {
    zIndex: "9999",
  },

  userNameIcon: {
    marginBottom: "18px",
  },

  welcomeStaticText: {
    textAlign: "center",
    color: "gray",
    fontWeight: "500",
  },
  collapseItem: {
    marginLeft: 20,
    padding: 10,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    background: "#F8F8F8",
    borderRadius: 10,
    width: "90%",
    "&:hover": {
      border: "0.1px solid #69CDAE",
    },
  },
  arrows: {
    marginRight: 5,
  },
  childrenItem: {
    fontSize: 15,
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      color: "#000",
    },
  },
  adminInfoBox: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  jobTitle: {
    color: "#000",
    fontSize: 13,
    opacity: 0.6,
  },
  fullName: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000",
  },
});

interface SidebarTypes {
  loading?: boolean;
  isJustNavbar?: boolean;
  children?: React.ReactNode;
  renderButton?: React.ReactNode;
  pageTitle?: string;
  closeSidebar?: boolean;
  setIsExpandedSidebar?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Sidebar: React.FC<SidebarTypes> = ({
  closeSidebar,
  isJustNavbar,
  setIsExpandedSidebar,
  children,
  pageTitle,
  renderButton,
  loading,
}) => {
  const { hasPermission } = usePermissions();
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(true);
  const classes = useStyles();
  const location = useLocation();

  const [isCollapse, setIsCollapse] = React.useState<boolean>(false);
  const [selectedCollapseIndex, setSelectedCollapseIndex] =
    React.useState<number>(-1);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const adminUserInfo = useAppSelector((state) => state.auth.adminUserInfo);

  React.useEffect(() => {
    if (closeSidebar) {
      setOpen(false);
    }
  }, [closeSidebar]);

  const checkRouteActive = React.useCallback(
    (href: string) => {
      return location.pathname === href;
    },
    [location.pathname]
  );

  const onLogout = () => {
    dispatch(logout());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("isTwoFaVerified");
    navigate("/login");
  };

  const onCollapse = (index: number) => {
    setSelectedCollapseIndex(index);
    if (index === selectedCollapseIndex) {
      setIsCollapse(!isCollapse);
    } else {
      setIsCollapse(true);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onGoToTradeApp = React.useCallback(async () => {
    // dispatch(setTradeAppLoading(true));
    // const redirected = await goToTradeApp();
    // dispatch(setTradeAppLoading(false));

    window.open(
      `${
        process.env.REACT_APP_TRADE_URL
      }verify-impersonation?token=${localStorage.getItem("accessToken")}`
    );
  }, []);

  React.useEffect(() => {
    if (open) {
      setIsExpandedSidebar && setIsExpandedSidebar(true);
    } else {
      setIsExpandedSidebar && setIsExpandedSidebar(false);
    }
  }, [open, setIsExpandedSidebar]);

  return (
    <>
      {loading && <LoadingIndicator loading />}

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {isJustNavbar ? (
          <AppBarJustNavbar
            className={classes.appBar}
            position="fixed"
            open={open}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                {" "}
                <img className={classes.logo} src="/static/svg/app-icon.png" />
              </IconButton>
              {/* <div></div>
            <div className={classes.adminInfoBox}>
            <IconButton>
            <i className="ri-notification-3-line"></i>
            </IconButton>

              <img
                style={{ width: 35, height: 35, borderRadius: "100%" }}
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSDPTH45IMOu72NyhIy9lJQFCeuGcn0eOad1EWKrHYlUiXvDNpfrv6jSmsOXHiQrzN854&usqp=CAU"
              />
              <div className="d-flex flex-column ">
              <span className={classes.fullName}>{adminUserInfo.fullName}</span>
              <span className={classes.jobTitle}>Frontend Developer</span>
              </div>
            </div> */}
            </Toolbar>
          </AppBarJustNavbar>
        ) : (
          <AppBar className={classes.appBar} position="fixed" open={open}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                {" "}
                <img className={classes.logo} src="/static/svg/app-icon.png" />
              </IconButton>
              {/* <div></div>
            <div className={classes.adminInfoBox}>
            <IconButton>
            <i className="ri-notification-3-line"></i>
            </IconButton>

              <img
                style={{ width: 35, height: 35, borderRadius: "100%" }}
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSDPTH45IMOu72NyhIy9lJQFCeuGcn0eOad1EWKrHYlUiXvDNpfrv6jSmsOXHiQrzN854&usqp=CAU"
              />
              <div className="d-flex flex-column ">
              <span className={classes.fullName}>{adminUserInfo.fullName}</span>
              <span className={classes.jobTitle}>Frontend Developer</span>
              </div>
            </div> */}
            </Toolbar>
          </AppBar>
        )}
        {!isJustNavbar ? (
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              <img
                className={classes.logoSidebarOpen}
                src="/static/svg/Open_Logo.svg"
              />
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon className={classes.chevronLeftCloseIndex} />
                )}
              </IconButton>
            </DrawerHeader>

            {open ? (
              <>
                {adminUserInfo.id ? (
                  <div className={classes.nameBox}>
                    <div>
                      <div className={classes.welcomeStaticText}>Welcome</div>{" "}
                      <span className={classes.nameText}>
                        {" "}
                        {adminUserInfo.fullName}
                      </span>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
            <Divider />
            <List>
              {sidebarItems.map((text, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    className={classes.listItem}
                    key={index}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    {text.function ? (
                      <ListItemButton
                        className={classes.listItem}
                        onClick={onLogout}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          backgroundColor: "transparent",
                          color: "#000",

                          ":hover": {
                            backgroundColor: "#F8F8F8!important",

                            color: "#000!important",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#000",
                          }}
                        >
                          {text.icon}
                        </ListItemIcon>

                        <ListItemText
                          primary={text.title}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    ) : text.href ? (
                      <Link to={text.href} className={classes.childrenItem}>
                        <ListItemButton
                          className={classes.listItem}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            backgroundColor: checkRouteActive(text.href)
                              ? "#69CDAE"
                              : "transparent",
                            color: checkRouteActive(text.href)
                              ? "#fff"
                              : "#000",

                            ":hover": {
                              backgroundColor: checkRouteActive(text.href)
                                ? "#3eb590!important"
                                : "#F8F8F8!important",

                              color: checkRouteActive(text.href)
                                ? "#fff!important"
                                : "#000!important",
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: checkRouteActive(text.href)
                                ? "#fff"
                                : "#000",
                            }}
                          >
                            {text.icon}
                          </ListItemIcon>

                          <ListItemText
                            primary={text.title}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </Link>
                    ) : (
                      <span
                        onClick={() => {
                          onCollapse(index);
                          setOpen(true);
                        }}
                        className={classes.sidebarLinks}
                      >
                        <ListItemButton
                          className={classes.listItem}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            backgroundColor: "transparent",
                            color: "#000",

                            ":hover": {
                              backgroundColor: "#F8F8F8!important",

                              color: "#000!important",
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {text.icon}
                          </ListItemIcon>

                          <ListItemText
                            primary={text.title}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                          {!open ? null : index === selectedCollapseIndex &&
                            isCollapse ? (
                            <i
                              className={`ri-arrow-up-s-line ${classes.arrows}`}
                            ></i>
                          ) : (
                            <i
                              className={`ri-arrow-down-s-line ${classes.arrows}`}
                            ></i>
                          )}
                        </ListItemButton>
                      </span>
                    )}
                  </ListItem>
                  {index === selectedCollapseIndex && open && isCollapse ? (
                    <div className="d-flex flex-column gap-2 py-2">
                      {text?.children?.map((children, index) => {
                        return children.title === "All Data" ? (
                          <PermissionWrapper
                            key={index}
                            permission="REPORTS_ALL_DATA"
                          >
                            <Link
                              key={index}
                              className={classes.childrenItem}
                              to={children.href}
                            >
                              <div
                                className={`ml-8 mr-2 p-2  ${
                                  checkRouteActive(children.href)
                                    ? "bg-[#69CDAE] text-white hover:bg-[#3eb590]"
                                    : "hover:bg-[#F8F8F8] hover:text-black"
                                }`}
                              >
                                {children.title}
                              </div>
                            </Link>
                          </PermissionWrapper>
                        ) : children.goToApp ? (
                          <PermissionWrapper
                            unauthorizedComponent={false}
                            permission="GOTO_TRADE_APP"
                          >
                            <div
                              onClick={onGoToTradeApp}
                              key={index}
                              className={classes.childrenItem}
                            >
                              <div
                                className={`ml-8 mr-2 p-2  ${
                                  checkRouteActive(children.href)
                                    ? "bg-[#69CDAE] text-white hover:bg-[#3eb590]"
                                    : "hover:bg-[#F8F8F8] hover:text-black"
                                }`}
                              >
                                {children.title}
                              </div>
                            </div>
                          </PermissionWrapper>
                        ) : (
                          <Link
                            key={index}
                            className={classes.childrenItem}
                            to={children.href}
                          >
                            <div
                              className={`ml-8 mr-2 p-2  ${
                                checkRouteActive(children.href)
                                  ? "bg-[#69CDAE] text-white hover:bg-[#3eb590]"
                                  : "hover:bg-[#F8F8F8] hover:text-black"
                              }`}
                            >
                              {children.title}
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
            </List>
          </Drawer>
        ) : null}
        <Box
          className={classes.mainBox}
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
        >
          <div className="d-flex justify-content-between">
            <p className={classes.pageTitle}>{pageTitle}</p>
            <p className={classes.pageTitle}>{renderButton}</p>
          </div>
          <div>{children}</div>
        </Box>
      </Box>
    </>
  );
};
