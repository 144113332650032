import React, { useState, useEffect } from "react";
import { Sidebar } from "../../components/Sidebar";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import InventoryTableContainer from "./InventoryTable/InventoryTableContainer";
import InvoiceTableContainer from "./InvoiceTable/InvoiceTableContainer";
import OrderTableContainer from "./OrderTable/OrderTableContainer";
import ReportsContainer from "./Reports/ReportsContainer";
import {
  OrderOrInvoiceList,
  getInventoryItems,
} from "../../services/surplus-allocation";
import { InventoryStateType, InvoiceStateType } from "./interfaces";
import { OrderStateType } from "./orderStateType";
import LoadingIndicator from "../../components/LoadingIndicator";
import InventoryDetailsModal from "./InventoryTable/InventoryDetailsModal";
import { Helmet } from "react-helmet";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const useStyles = makeStyles({
  mainBox: {
    background: "white",
    marginTop: 10,
    borderRadius: 20,
    padding: 20,
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#E77228",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#E77228",
    },
  },
  containerBox: {
    padding: 10,
  },
  goBack: {
    cursor: "pointer",
  },
  newAllocation: {
    borderRadius: "20px!important",
    background: "#5F8CBA!important",
  },
});

interface QueryParamsType {
  page: number;
  limit: number;
  total: number;
  totalPage: number;
}

const sortSchema = yup.object().shape({
  sortType: yup.object(),
  sortName: yup.object(),
});

const SurplusAllocationContainer: React.FC = () => {
  const { control, watch } = useForm({
    resolver: yupResolver(sortSchema),
    defaultValues: {
      sortName: { name: "Create Date", id: "createdAt" },
      sortType: { name: "Descending", id: "-" },
    },
  });

  const [searchParams, setSearchParams] = useSearchParams();

  // Get a specific query parameter
  const invoiceStatus = searchParams.get("status");
  const tradeStatus = searchParams.get("tradeStatus");
  const { hasPermission } = usePermissions();
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = useState(tradeStatus ? 2 : invoiceStatus ? 3 : 2);
  const [loading, setLoading] = useState<boolean>(false);
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false);
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);
  const [inventoryData, setInventoryData] = useState<InventoryStateType[]>([]);
  const [invoiceData, setInvoiceData] = useState<InvoiceStateType[]>([]);
  const [orderData, setOrderData] = useState<OrderStateType[]>([]);
  const [inventoryFilterData, setInventoryFilterData] = useState<
    InventoryStateType[]
  >([]);
  const [selectedInventoryItem, setSelectedInventoryItem] =
    useState<InventoryStateType>();
  const [orderFilterData, setOrderFilterData] = useState({});
  const [invoiceFilterData, setInvoiceFilterData] = useState({});

  const [isShowInventoryDetails, setIsShowInventoryDetails] =
    useState<boolean>(false);

  const [invoiceQueryParams, setInvoiceQueryParams] = useState<QueryParamsType>(
    {
      page: 1,
      limit: 1,
      total: 1,
      totalPage: 1,
    }
  );
  const [orderQueryParams, setOrderQueryParams] = useState<QueryParamsType>({
    page: 1,
    limit: 1,
    total: 1,
    totalPage: 1,
  });

  const watchSortType = watch("sortType");
  const watchSortName = watch("sortName");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!hasPermission("ORDERS_INVENTORY_LIST_VIEW")) return;

    if (value === 1) {
      const getInventories = async () => {
        setInventoryLoading(true);
        const response = await getInventoryItems();

        setInventoryLoading(false);

        if (response) {
          setInventoryFilterData(response);
          setInventoryData(response);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      };
      getInventories();
    }
  }, [value, hasPermission("ORDERS_INVENTORY_LIST_VIEW")]);

  useEffect(() => {
    if (!hasPermission("ORDERS_INVOICES_LIST_VIEW")) return;

    if (value === 3 && !invoiceStatus) {
      const getInvoices = async () => {
        setInvoiceLoading(true);
        const response = await OrderOrInvoiceList(
          "invoice",
          invoiceFilterData,
          invoiceQueryParams.page
        );
        setInvoiceLoading(false);

        if (response) {
          setInvoiceData(response.items);
          setInvoiceQueryParams({
            page: response.metaData.page,
            limit: response.metaData.limit,
            total: response.metaData.total,
            totalPage: response.metaData.totalPage,
          });

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      };
      getInvoices();
    }
  }, [
    invoiceStatus,
    invoiceQueryParams.page,
    invoiceFilterData,
    value,
    hasPermission("ORDERS_INVOICES_LIST_VIEW"),
  ]);

  useEffect(() => {
    if (!hasPermission("ORDERS_ORDER_LIST_VIEW")) return;

    if (value === 2) {
      const getOrders = async () => {
        setOrdersLoading(true);
        const response = await OrderOrInvoiceList(
          "offer",
          orderFilterData,
          orderQueryParams.page,
          `${(watchSortType as any)?.id || ""}${
            (watchSortName as any)?.id || ""
          }`
        );
        setOrdersLoading(false);
        if (response) {
          setOrderQueryParams({
            page: response.metaData.page,
            limit: response.metaData.limit,
            total: response.metaData.total,
            totalPage: response.metaData.totalPage,
          });
          setOrderData(response.items);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      };
      getOrders();
    }
  }, [
    value,
    watchSortName,
    watchSortType,
    orderQueryParams.page,
    orderFilterData,
    hasPermission("ORDERS_ORDER_LIST_VIEW"),
  ]);

  //this effect for filter status with dasboard data click

  useEffect(() => {
    if (tradeStatus) {
      setOrderFilterData({
        tradeStatus: tradeStatus,
      });
    } else if(invoiceStatus) {
      setInvoiceFilterData({
        status: [invoiceStatus],
      });
    }
  }, [invoiceStatus,tradeStatus]);

  useEffect(() => {
    if (!hasPermission("ORDERS_INVOICES_LIST_VIEW")) return;

    if (value === 3 && invoiceStatus) {
      const getInvoices = async () => {
        setInvoiceLoading(true);
        const response = await OrderOrInvoiceList(
          "invoice",
          invoiceFilterData,
          invoiceQueryParams.page
        );
        setInvoiceLoading(false);

        if (response) {
          setInvoiceData(response?.items);
          setInvoiceQueryParams({
            page: response.metaData.page,
            limit: response.metaData.limit,
            total: response.metaData.total,
            totalPage: response.metaData.totalPage,
          });

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      };
      getInvoices();
    }
  }, [
    invoiceStatus,
    invoiceQueryParams.page,
    invoiceFilterData,
    value,
    hasPermission("ORDERS_INVOICES_LIST_VIEW"),
  ]);

  const renderContainers = () => {
    switch (value) {
      case 1:
        return (
          <PermissionWrapper
            permission="ORDERS_INVENTORY_LIST_VIEW"
            unauthorizedComponent
          >
            {isShowInventoryDetails ? (
              <div>
                <span
                  onClick={() => {
                    setIsShowInventoryDetails(false);
                    setSelectedInventoryItem(undefined);
                  }}
                  className={classes.goBack}
                >
                  {" "}
                  <i className="ri-arrow-left-line"></i>Go back
                </span>

                <div className="mt-3">
                  <InventoryDetailsModal
                    selectedInventory={selectedInventoryItem}
                  />
                </div>
              </div>
            ) : (
              <InventoryTableContainer
                selectedInventoryItem={selectedInventoryItem}
                setSelectedInventoryItem={setSelectedInventoryItem}
                setIsShowInventoryDetails={setIsShowInventoryDetails}
                isShowInventoryDetails={isShowInventoryDetails}
                inventoryFilterData={inventoryFilterData}
                setInventoryData={setInventoryData}
                setLoading={setLoading}
                loading={loading}
                setInventoryLoading={setInventoryLoading}
                inventoryLoading={inventoryLoading}
                inventoryData={inventoryData}
              />
            )}
          </PermissionWrapper>
        );
      case 2:
        return (
          <PermissionWrapper
            permission="ORDERS_ORDER_LIST_VIEW"
            unauthorizedComponent
          >
            <OrderTableContainer
              orderStatus={tradeStatus || ""}
              sortControl={control}
              ordersLoading={ordersLoading}
              orderQueryParams={orderQueryParams}
              setOrderQueryParams={setOrderQueryParams}
              setLoading={setLoading}
              orderFilterData={orderFilterData}
              orderData={orderData}
              setOrderFilterData={setOrderFilterData}
              setOrderData={setOrderData}
            />
          </PermissionWrapper>
        );
      case 3:
        return (
          <PermissionWrapper
            permission="ORDERS_INVOICES_LIST_VIEW"
            unauthorizedComponent
          >
            <InvoiceTableContainer
              invoiceStatus={invoiceStatus || ""}
              searchParams={invoiceStatus}
              invoiceLoading={invoiceLoading}
              setInvoiceFilterData={setInvoiceFilterData}
              invoiceFilterData={invoiceFilterData}
              invoiceQueryParams={invoiceQueryParams}
              setInvoiceQueryParams={setInvoiceQueryParams}
              setLoading={setLoading}
              invoiceData={invoiceData}
              setInvoiceData={setInvoiceData}
            />
          </PermissionWrapper>
        );
      case 4:
        return (
          <PermissionWrapper
            permission="ORDERS_REPORTS_VIEW"
            unauthorizedComponent
          >
            <ReportsContainer />
          </PermissionWrapper>
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>Orders | FruPro Admin Portal</title>
      </Helmet>
      <Sidebar
        renderButton={
          value === 2 ? (
            <PermissionWrapper permission="ORDERS_ORDER_LIST_NEW_ALLOCATION">
              <AdminButtonComponent
                className={classes.newAllocation}
                title=" + New allocation"
                onClick={() => (window.location.href = "/create-new-surplus")}
              />
            </PermissionWrapper>
          ) : null
        }
        pageTitle="Orders & Allocation"
      >
        {loading && <LoadingIndicator loading />}

        <Box className={classes.mainBox}>
          <Tabs className={classes.tabs} value={value} onChange={handleChange}>
            <Tab value={1} label="Inventory List" />
            <Tab value={2} label="Order List" />
            <Tab value={3} label="Invoice List" />
            <Tab value={4} label="Reports" />
          </Tabs>

          <Box className={classes.containerBox}>{renderContainers()}</Box>
        </Box>
      </Sidebar>
    </>
  );
};

export default SurplusAllocationContainer;
