import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getCompanyRequests = async (page: number, keyword: string) => {
  try {
    const response = await axiosApiInstance.get(
      `company-requests?page=${page}&limit=10&keyword=${keyword ? keyword : ""}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const getCompanyRequestById = async (id:string) => {
  try {
    const response = await axiosApiInstance.get(
      `company-requests/${id}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const verifyRequest = async (companyId: string) => {
  try {
    const response = await axiosApiInstance.put(
      `companies/${companyId}/verify`
    );
    if (response) {
      toast.success("Company Request is verified successfully");
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const approveRequest = async (companyId: string) => {
  try {
    const response = await axiosApiInstance.put(
      `companies/${companyId}/approve`
    );

    if (response) {
      toast.success("Company Request is approved successfully");
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const rejectRequest = async (
  companyId: string,
  rejectionReason: string
) => {
  try {
    const response = await axiosApiInstance.put(
      `company-requests/${companyId}/reject`,
      {
        rejectionReason: rejectionReason,
      }
    );

    if (response) {
      toast.success("Company Request is rejected successfully");
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
