import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getBrands = async (page: number, keyword?: string) => {
  try {
    const response = await axiosApiInstance.get(
      `/statistic-brands?page=${page}&limit=10&keyword=${
        keyword ? keyword : ""
      }`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
