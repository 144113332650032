import React, { useCallback, useState } from "react";
import { CompanyDetailsStateType } from "./interfaces";
import AdminButtonComponent from "../../../components/AdminButtonComponent";
import { makeStyles } from "@mui/styles";
import { getSignedUrl, uploadFile } from "../../../services/common";
import { deleteDocument, uploadDocument } from "../../../services/company";
import AdminModalComponent from "../../../components/AdminModalComponent";
import PermissionWrapper from "../../../components/PermissionWrapper";

const useStyles = makeStyles({
  headerDocsText: {
    fontWeight: 600,
    fontSize: 20,
  },
  uploadDocButton: {
    borderRadius: "10px!important",
  },
  docItem: {
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    marginTop: 15,
    padding: 10,
    borderRadius: 10,
    background: "#f8f8f8",
  },
  hr: {
    opacity: 0.05,
    marginTop: 15,
  },
  trash: {
    marginRight: 15,
    fontSize: 22,
    cursor: "pointer",
    color: "red",
  },
  urlText: {
    fontWeight: 600,
    color: "#5F8CBA",
  },
  uploadDoc: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
    color: "#e77228",
    border: "1px solid",
    borderRadius: 10,
    padding: 5,
    fontWeight: 600,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "red !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  ModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
});

const Documents = ({
  refresh,
  loading,
  setLoading,
  setRefresh,
  companyData,
}: {
  loading: boolean;
  refresh: number;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  companyData: CompanyDetailsStateType | undefined;
}) => {
  const classes = useStyles();

  const [docLoading, setDocLoading] = useState(false);
  const [docDeleteLoading, setDocDeleteLoading] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const onOpenModal = (id: string) => {
    setOpenModal(true);
    setSelectedDocId(id);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onUpload = useCallback(
    async (file: any) => {
      setDocLoading(true);
      const response = await uploadFile(file, "company");
      setDocLoading(false);

      if (response) {
        await uploadDocument(response, companyData?.id || "");
        setRefresh((prev: number) => prev + 1);
      }
    },
    [companyData?.id]
  );

  const onDeleteDoc = useCallback(async () => {
    setDocDeleteLoading(true);
    const response = await deleteDocument(selectedDocId, companyData?.id || "");
    setDocDeleteLoading(false);
    if (response) {
      setRefresh((prev) => prev + 1);
      setOpenModal(false);
      setSelectedDocId("");
    }
  }, [selectedDocId, companyData?.id]);

  const renderModalBody = () => {
    return (
      <>
        Are you sure you want to delete this document?
        <div className={classes.ModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            disabled={docDeleteLoading}
            loading={docDeleteLoading}
            className={classes.saveButton}
            onClick={onDeleteDoc}
            title="Delete"
          />
        </div>
      </>
    );
  };
  return (
    <div className="mt-2 container">
      <div className="row">
        <div className="d-flex justify-content-between align-items-center">
          <span className={classes.headerDocsText}>Documents</span>
          <PermissionWrapper permission="COMPANY_UPDATE">
            <label>
              <input
                disabled={docLoading}
                accept=".jpg,.jpeg,.png,.svg,.xls,.xlsx,.pdf"
                onChange={(e) => onUpload(e.target?.files?.[0])}
                hidden
                type="file"
              />
              <span className={classes.uploadDoc}>
                {" "}
                {docLoading ? "Uploading.." : "+ Upload Document"}
              </span>
            </label>
          </PermissionWrapper>
        </div>
        <hr className={classes.hr} />
        <div>
          {companyData?.documents.map((item, index) => {
            let type = "pdf";
            let { name = "pdf" } = item;
            if (name?.includes(".pdf")) type = "pdf";
            else if (name?.includes(".xlsx")) type = "xls";
            else if (name?.includes(".xls")) type = "xls";
            else if (name?.includes(".doc")) type = "doc";
            else if (name?.includes(".docx")) type = "doc";
            return (
              <div key={index} className="col-md-12">
                <div className={classes.docItem}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-row gap-2 align-items-center">
                      <img
                        src={`/static/svg/ic_file_${type}.svg`}
                        alt="ic_file_pdf"
                      />
                      <a
                        className={classes.urlText}
                        href={item?.url}
                        target="_blank"
                      >
                        {item?.name}
                      </a>
                    </div>
                    <PermissionWrapper permission="COMPANY_UPDATE">
                      <i
                        onClick={() => onOpenModal(item?._id)}
                        className={`ri-delete-bin-line ${classes.trash}`}
                      ></i>
                    </PermissionWrapper>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <AdminModalComponent
          headerTitle="Are you sure"
          openModal={openModal}
          closeModal={onCloseModal}
          children={renderModalBody()}
        />
      </div>
    </div>
  );
};

export default Documents;
