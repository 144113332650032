import axios from "axios";
import { axiosApiInstance } from "./axios-instance";
import { toast } from "react-toastify";

export const getAIInvoices = async (page: number, data: any) => {
  try {
    const response = await axiosApiInstance.post(
      `/list-document-ai-invoices?page=${page}`,
      {
        ...data,
      }
    );

    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error?.response?.data?.message || error.message);
    }
  }
};

export const processAIInvoice = async (id: string) => {
  try {
    const response = await axiosApiInstance.post(`/process-document`, {
      documentID: id,
    });

    if (response) {
      toast.success("Process completed successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error.message
      );
    }
  }
};

export const deleteAIInvoice = async (id: string) => {
  try {
    const response = await axiosApiInstance.post(`/delete-document`, {
      documentID: id,
    });

    if (response) {
      toast.success("Invoice deleted successfully.");
      return response.status;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error.message
      );
    }
  }
};

export const uploadAIIInvoice = async (file: any) => {
  try {
    const formData = new FormData();
    for (const key in file) {
      formData.append("file", file[key]);
    }

    const response = await axiosApiInstance.post(
      `/upload-document`,
      formData,

      {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }
    );

    if (response) {
      toast.success(file?.length===1? "Invoice uploaded successfully.":"Invoices uploaded successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error?.response?.data?.message || error.message);
    }
  }
};
