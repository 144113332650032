import { Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CompanyUsers, SelectedCompanies } from "./PushNotificationsContainer";

const useStyles = makeStyles({
  opacity: {
    opacity: 0.08,
    marginTop: 30,
  },
  companyItem: {
    minHeight: "100%",
    padding: 20,
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    borderRadius: "5px!important",
    background: "#f8f8f8!important",
    width: "100%",
  },
  companiesText: {
    fontWeight: 600,
    color: "#E77228",
  },
});

const CompanyItem = ({
  selectedUsers,
  data,
  setSelectedUsers,
  selecteds,
  setSelecteds,
  index,
}: {
  selectedUsers: { fullName: string; _id: string }[];
  data: CompanyUsers;
  selecteds: SelectedCompanies[];
  setSelectedUsers: React.Dispatch<
    React.SetStateAction<{ fullName: string; _id: string }[]>
  >;
  setSelecteds: React.Dispatch<React.SetStateAction<SelectedCompanies[]>>;
  index: number;
}) => {
  const classes = useStyles();

  const isCompanyChecboxChecked = useMemo(() => {
    return selecteds.map((i) => i._id).includes(data._id);
  }, [selecteds.length, data._id]);

  const [defaultCompanyChecboxChecked, setDefaultCompanyCheckboxChecked] =
    useState(false);

  // useEffect(() => {
  //   const isInSelecteds=selecteds.find((it)=>it._id===data._id)
  //   if (isInSelecteds?._id) {
  //    const newSelectedUsers= selectedUsers.concat(data.users)
  //     setSelectedUsers(newSelectedUsers);
  //     setDefaultCompanyCheckboxChecked(true);
  //   } else {
  //     console.log("girdi")
  //     setDefaultCompanyCheckboxChecked(false);

  //     setSelectedUsers([...(selecteds.map((sel) => sel.users) as any)]);
  //   }
  // }, [data.users.length, selectedUsers.length,selecteds.length]);

  const onSelectCompany = useCallback(
    (item: SelectedCompanies) => {
      const isCompanyInArray = selecteds.map((i) => i._id).includes(data._id);

      if (isCompanyInArray) {
        const filteredComps = selecteds.filter((item) => item._id !== data._id);
        setSelecteds(filteredComps);
        const newUsersFilteredCompUsers = filteredComps.length
          ? [
              ...filteredComps
                .map((i) => i.users)
                .reduce((prev, curr) => [...prev, ...curr]),
            ]
          : [];

        const newUsers = selectedUsers.filter(function (item1) {
          return newUsersFilteredCompUsers.some(function (item2) {
            return item1._id === item2._id;
          });
        });

        setSelectedUsers(newUsers as any);
      } else {
        setSelectedUsers((prev) => [...prev, ...data.users]);
        setSelecteds((prev) => [...prev, item]);
      }
    },
    [selecteds.length, data._id, selectedUsers.length]
  );

  const onSelectUser = useCallback(
    (item: { _id: string; fullName: string }) => {
      const isUserInArray = selectedUsers.map((i) => i._id).includes(item._id);

      if (isUserInArray) {
        const filteredUsers = selectedUsers.filter((it) => it._id !== item._id);
        setSelectedUsers(filteredUsers);
        setDefaultCompanyCheckboxChecked(false);

        const newUsers = filteredUsers.filter(function (item1) {
          return data.users.some(function (item2) {
            return item1._id === item2._id;
          });
        });

        !newUsers.length &&
          setSelecteds((prev) => prev.filter((item) => item._id !== data._id));
      } else {
        setSelectedUsers((prev) => [...prev, item]);
      }
      // const selectedCompany = selecteds.find((c) => c._id === data._id);

      // const isUserInArray = selectedCompany?.users
      //   .map((us) => us._id)
      //   .includes(item._id);
      // // const isUserInArray = selecteds.find((i) =>
      // //   i.users.map((user) => user._id).includes(item._id)
      // // );

      // console.log("ss",selecteds);
      // console.log("arr", isUserInArray);

      // if (isUserInArray) {
      //   setDefaultCompanyCheckboxChecked(false)
      //   setSelecteds((prev) => [
      //     ...prev.map((p) => ({
      //       ...p,
      //       users: p.users.filter((i) => i._id !== item._id),
      //     })),
      //   ]);
      // } else {
      //   console.log("girdi")
      //   setDefaultCompanyCheckboxChecked(true)
      //   setSelecteds((prev) => [
      //     ...prev.map((p) => ({
      //       ...p,
      //       users: [],
      //     })),
      //   ]);
      // }
    },
    [selectedUsers.length, data.users.length]
  );

  return (
    <div key={index} className="col-md-6">
      <div className={classes.companyItem}>
        <div className="d-flex flex-row align-items-center gap-2">
          <Checkbox
            checked={isCompanyChecboxChecked}
            value={isCompanyChecboxChecked}
            onChange={() =>
              onSelectCompany({
                companyName: data.companyName,
                _id: data._id,
                users: data.users,
              })
            }
          />
          <span>{data.companyName}</span>
        </div>
        {data.users.map((u, userIndex) => {
          const isUserInArray = selectedUsers.map((c) => c._id).includes(u._id);

          // const isUserInArray = selectedCompany?.users
          //   .map((us) => us._id)
          //   .includes(u._id);
          return (
            <div
              key={userIndex}
              style={{ marginLeft: 50 }}
              className="d-flex  flex-row align-items-center gap-2"
            >
              <Checkbox
                disabled={!isCompanyChecboxChecked}
                value={isUserInArray || defaultCompanyChecboxChecked}
                checked={isUserInArray || defaultCompanyChecboxChecked}
                onChange={() => onSelectUser(u)}
              />
              <span>{u.fullName}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CompanyItem;
