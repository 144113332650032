import React, { useCallback, useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import {
  getForecastedTrades,
  getForecastedTradesFilters,
  updateForecastedPrices,
} from "../../services/forecasted-trades";
import usePermissions from "../../hooks/usePermissions";
import PermissionWrapper from "../../components/PermissionWrapper";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow } from "@mui/material";
import moment from "moment";
import NumberFormatComponent from "../../components/NumberFormatComponent";
import { useAppSelector } from "../../hooks/hooks";
import AdminModalComponent from "../../components/AdminModalComponent";
import LoadingIndicator from "../../components/LoadingIndicator";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledComboBox from "../../components/ControlledComboBox";
import FilterBadge from "../../components/FilterBadge";
import ControlledDateField from "../../components/ControlledDateField";
import ControlledTextField from "../../components/ControlledTextField";
import TableComponentForecasted from "./TableComponentForecasted";
import { Helmet } from "react-helmet";

const schema = yup.object().shape({
  SalesRevenue: yup.string(),
  SalesCost: yup.string(),
  LotCharges: yup.string(),
  TransferCost: yup.string(),
  ServiceCost: yup.string(),
  ProductCost: yup.string(),

  ConsignmentNumber: yup.string(),
  SupplierName: yup.string(),
  sortType: yup.object(),
  sortName: yup.object(),
  ArrivalDateFrom: yup.string(),
  ArrivalDateTo: yup.string(),
});

export interface TradesForeType {
  _id: string;
  SalesRevenue: SalesRevenue;
  ProductCost: ProductCost;
  SalesCost: SalesCost;
  LotCharges: LotCharges;
  TransferCost: TransferCost;
  ServiceCost: ServiceCost;
  ConsignmentNumber: string;
  CurrencyID: string;
  SupplierName: string;
  ArrivalDate: string;
  TotalCost: number;
  Margin: number;
  MarginPercentage: number;
  GrossMargin: number;
  GrossMarginPercentage: number;
  createdAt: string;
  updatedAt: string;
  admins:Admin[]
  notes:Notes[]
  __v: number;
}


export interface Admin {
  _id: string
  fullName: string
}

export interface Notes {
  _id: string
  note: string
  adminId: string
  noteType: string
  relatedId: string
  createdAt: string
}


export interface SalesRevenue {
  tradabase: number;
  admin: number;
}

export interface ProductCost {
  tradabase: number;
  admin: number;
}

export interface SalesCost {
  tradabase: number;
  admin: number;
}

export interface LotCharges {
  tradabase: number;
  admin: number;
}

export interface TransferCost {
  tradabase: number;
  admin: number;
}

export interface ServiceCost {
  tradabase: number;
  admin: number;
}

const columns = [
  { name: "Consignment", id: "consignment" },
  { name: "Supplier", id: "supplier" },
  { name: "Arrival Date", id: "arrivalDate" },
  { name: "Sales Price", id: "salesPrice" },
  { name: "Product Costs", id: "productCosts" },
  { name: "Sales Costs", id: "salesCosts" },
  { name: "Lot Costs", id: "lotCosts" },
  { name: "Transfer Costs", id: "transferCosts" },
  { name: "Gross Margin", id: "grossMargin" },
  { name: "Gross%", id: "gross" },
  { name: "Net Margin", id: "netMargin" },
  { name: "Net%", id: "net" },
];

const useStyles = makeStyles({
  editPen: {
    cursor: "pointer",
    fontSize: 20,
  },
  textField: {
    background: "#F8F8F8",
    fontSize: 20,
    borderRadius: 10,
    border: "none !important",
    width: "150px",
    height: 30,
    padding: 15,
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red !important",
    },
    "& .MuiInputBase-root": {
      minHeight: 30,
      width: "auto",
      borderColor: "black !important",
      border: "0.1px solid black !important",
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  greenColor: {
    color: "#69CDAE",
  },
  redColor: {
    color: "red",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
});

const ForecastedTrades = () => {
  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      sortName: { name: "ArrivalDate", id: "arrival" },
      sortType: { name: "Descending", id: "-" },
      ConsignmentNumber: "",
      SupplierName: "",
      ArrivalDateFrom: "",
      ArrivalDateTo: "",
      SalesRevenue: "",
      SalesCost: "",
      LotCharges: "",
      TransferCost: "",
      ServiceCost: "",
      ProductCost: "",
    },
  });
 
  const classes = useStyles();
  const currencies = useAppSelector((state) => state.auth.currencies);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const { hasPermission } = usePermissions();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [consignments, setConsignments] = useState<string[]>([]);
  const [suppliers, setSuppliers] = useState<string[]>([]);
  const [deletedFilterKey, setDeletedFilterKey] = useState("");
  const [isClearFilterKey, setIsClearFilterKey] = useState(0);
  const [fetchFilterDatasLoading, setFetchFilterDatasLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [trades, setTrades] = useState<TradesForeType[]>([]);
  const [filterData, setFilterData] = useState<any>({});
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  const watchSortType = watch("sortType");
  const watchSortName = watch("sortName");

  useEffect(() => {
    setValue(`${deletedFilterKey as any}` as any, "");
  }, [deletedFilterKey, isClearFilterKey]);

  useEffect(() => {
    if (openFilterModal && !suppliers.length && !consignments.length) {
      const getFilterDatas = async () => {
        setFetchFilterDatasLoading(true);
        const response = await getForecastedTradesFilters();
        setFetchFilterDatasLoading(false);

        if (response?.data) {
          setSuppliers(response?.data?.SupplierName);
          setConsignments(response?.data?.ConsignmentNumber);
        }
      };
      getFilterDatas();
    }
  }, [openFilterModal, suppliers.length, consignments.length]);

  useEffect(() => {
    if (!hasPermission("FORECASTED_SALES_TRADABASE")) return;

    const getData = async () => {
      setFetchLoading(true);
      const response = await getForecastedTrades({
        sorting: {
          sort:
            (watchSortType as any)?.name === "Ascending"
              ? "asc"
              : (watchSortType as any)?.name === "Descending"
              ? "desc"
              : undefined,
          sortBy: (watchSortName as any)?.name,
        },
        filterData: {
          ArrivalDate:
            filterData?.ArrivalDateFrom || filterData?.ArrivalDateTo
              ? {
                  from: filterData?.ArrivalDateFrom
                    ? filterData?.ArrivalDateFrom
                    : undefined,
                  to: filterData?.ArrivalDateTo
                    ? filterData?.ArrivalDateTo
                    : undefined,
                }
              : undefined,
          SupplierName: filterData?.SupplierName
            ? filterData?.SupplierName
            : undefined,
          ConsignmentNumber: filterData?.ConsignmentNumber
            ? filterData?.ConsignmentNumber
            : undefined,
        },
        page: queryParams.page,
      });

      if (response?.success) {
        setTrades(response?.data);
        setQueryParams({
          page: response?.pagination?.page,
          total: response?.pagination?.total,
          totalPage: response?.pagination?.totalPage,
          limit: response?.pagination?.limit,
        });
      }

      setFetchLoading(false);
    };
    getData();
  }, [
    watchSortName,
    watchSortType,
    refresh,
    queryParams.page,
    filterData,
    hasPermission("FORECASTED_SALES_TRADABASE"),
  ]);

  const onFilter = (data: any) => {
    setQueryParams({
      ...queryParams,
      page: 1,
    });
    setOpenFilterModal(false);
    setFilterData({
      ConsignmentNumber: data?.ConsignmentNumber
        ? data?.ConsignmentNumber
        : undefined,
      SupplierName: data?.SupplierName ? data?.SupplierName : undefined,
      ArrivalDateFrom: data?.ArrivalDateFrom
        ? data?.ArrivalDateFrom
        : undefined,
      ArrivalDateTo: data?.ArrivalDateTo ? data?.ArrivalDateTo : undefined,
    });
  };

  const renderTableBody = () => {
    return (
      <>
        {trades.map((trade, index) => {
          return (
          <>
            <TableComponentForecasted
              trades={trades}
              setTrades={setTrades}
              setRefresh={setRefresh}
          
              index={index}
              trade={trade}
          
            />
        
          </>
          );
        })}
      </>
    );
  };

  const renderFilterBody = () => {
    return (
      <>
        <div className="row">
          <div className="mt-2">
            <ControlledComboBox
              className={classes.comboBox}
              control={control}
              textLabel="Consignment"
              name="ConsignmentNumber"
              options={consignments}
            />
          </div>

          <div className="row mt-2">
            <div className="col-md-6">
              <ControlledDateField
                textLabel="Arrival Date From"
                control={control}
                name="ArrivalDateFrom"
              />
            </div>
            <div className="col-md-6">
              <ControlledDateField
                textLabel="Arrival Date To"
                control={control}
                name="ArrivalDateTo"
              />
            </div>
          </div>
          <div>
            <ControlledComboBox
              className={classes.comboBox}
              control={control}
              textLabel="Supplier Name"
              name="SupplierName"
              options={suppliers}
            />
          </div>
        </div>

        <div className={classes.filterModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setOpenFilterModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={handleSubmit(onFilter)}
            title="Submit"
          />
        </div>
      </>
    );
  };

  return (
    <Sidebar pageTitle="Forecasted Trades">
       <Helmet>
          <title>Forecasted Trades | FruPro Admin Portal</title>
        </Helmet>
      <PermissionWrapper
        unauthorizedComponent
        permission="FORECASTED_SALES_TRADABASE"
      >
        {fetchFilterDatasLoading && (
          <LoadingIndicator loading={fetchFilterDatasLoading} />
        )}
        <TableComponent
          control={control}
          isShowSortingTypes
          isShowSortingNames
          sortOptions={[
            { name: "ConsignmentNumber", id: "consignment" },
            { name: "ArrivalDate", id: "arrival" },
            { name: "SupplierName", id: "supplier" },
          ]}
          filterBadge={
            <FilterBadge
              isClearFilterKey={isClearFilterKey}
              setIsClearFilterKey={setIsClearFilterKey}
              setDeletedFilterKey={setDeletedFilterKey}
              filterData={filterData}
              setFilterData={setFilterData}
            />
          }
          loading={fetchLoading}
          onOpenModal={() => setOpenFilterModal(true)}
          showFilterIcon
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          showRightButton={false}
          TableBodyComponent={renderTableBody}
          columns={columns}
        />{" "}
      </PermissionWrapper>

      <AdminModalComponent
        children={renderFilterBody()}
        headerTitle="Filter Trades"
        openModal={openFilterModal}
        closeModal={() => setOpenFilterModal(false)}
      />
    </Sidebar>
  );
};

export default ForecastedTrades;
