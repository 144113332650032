import React from "react";
import { Sidebar } from "../../components/Sidebar";
import { useGetNotificationList } from "../../hooks/useGetNotifications";
import TableComponent from "../../components/TableComponent";
import { Box, Chip, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import AdminModalComponent from "../../components/AdminModalComponent";
import ControlledTextField from "../../components/ControlledTextField";
import ControlledDateField from "../../components/ControlledDateField";
import FilterBadge from "../../components/FilterBadge";
import PermissionWrapper from "../../components/PermissionWrapper";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  pendingChip: {
    background: "#E0A600!important",
    color: "white!important",
    minWidth: 100,
  },
  successChip: {
    color: "white!important",
    background: "#69cdae!important",
    minWidth: 100,
  },
  rejectChip: {
    color: "white!important",
    background: "red!important",
    minWidth: 100,
  },
  normalChip: {
    minWidth: 100,
  },
  successText: {
    fontWeight: 600,
    color: "#097969",
  },
  errorText: {
    fontWeight: 600,
    color: "red",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});

const columns = [
  { name: "Create Date", id: "createDate" },
  { name: "Delivery Date", id: "deliveryDate" },
  { name: "Notification Message", id: "message" },
  { name: "Notification Type", id: "notificationType" },
  // { name: "Status", id: "status" },
  { name: "Sent", id: "sent" },
  { name: "Delivered", id: "delivered" },
  { name: "Failed", id: "failed" },
  { name: "", id: "action" },
];

const NotificationReportsContainer = () => {
  const classes = useStyles();
  const {
    deletedFilterKey,
    setDeletedFilterKey,
    isClearFilterKey,
    setIsClearFilterKey,
    control,
    setShowFilterModal,
    showFilterModal,
    handleSubmit,
    watchSortName,
    watchSortType,
    queryParams,
    fetchLoading,
    setFetchLoading,
    setFilterData,
    setQueryParams,
    setNotifications,
    notifications,
    filterData,
  } = useGetNotificationList();

  const onOpenFilterModal = () => {
    setShowFilterModal(true);
  };

  const onCloseFilterModal = () => {
    setShowFilterModal(false);
  };

  const onFilter = (data: any) => {
    setShowFilterModal(false);
    setFilterData({
      keyword: data?.keyword ? data?.keyword : undefined,
      message: data?.message ? data?.message : undefined,
      createDateFrom: data?.createDateFrom
        ? moment(data?.createDateFrom).format("DD/MM/YYYY")
        : undefined,
      createDateTo: data?.createDateTo
        ? moment(data?.createDateTo).format("DD/MM/YYYY")
        : undefined,
      deliveryDateFrom: data?.deliveryDateFrom
        ? moment(data?.deliveryDateFrom).format("DD/MM/YYYY")
        : undefined,
      deliveryDateTo: data?.deliveryDateTo
        ? moment(data?.deliveryDateTo).format("DD/MM/YYYY")
        : undefined,
      // createdAt:
      //   data?.createDateFrom || data?.createDateTo
      //     ? {
      //         from: data?.createDateFrom ? data?.createDateFrom : undefined,
      //         to: data?.createDateTo ? data?.createDateTo : undefined,
      //       }
      //     : undefined,
      // deliveryDate:
      //   data?.deliveryDateFrom || data?.deliveryDateTo
      //     ? {
      //         from: data?.deliveryDateFrom ? data?.deliveryDateFrom : undefined,
      //         to: data?.deliveryDateTo ? data?.deliveryDateTo : undefined,
      //       }
      //     : undefined,
    });
  };

  const renderTableBody = () => {
    return (
      <>
         <Helmet>
          <title>Push Notifications | FruPro Admin Portal</title>
        </Helmet>
        {notifications.map((n, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {moment(n.createdAt).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell component="th" scope="row">
                {moment(n.deliveryDate).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell style={{ maxWidth: 200 }} component="th" scope="row">
                {n.message}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.messageType}
              </TableCell>
              {/* <TableCell component="th" scope="row">
                <Chip
                  className={
                    n.status === "SENT"
                      ? classes.successChip
                      : n.status === "PENDING"
                      ? classes.pendingChip
                      : n.status === "FAILED"
                      ? classes.rejectChip
                      : classes.normalChip
                  }
                  label={n.status}
                />
              </TableCell> */}
              <TableCell style={{ fontWeight: 600 }} component="th" scope="row">
                {n.results.successCount + n.results.failureCount}
              </TableCell>
              <TableCell component="th" scope="row">
                <span className={classes.successText}>
                  {" "}
                  {n.results.successCount}
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                <span className={classes.errorText}>
                  {" "}
                  {n.results.failureCount}
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                <AdminButtonComponent
                  onClick={() => window.open(`/notification-reports/${n._id}`)}
                  title="Details"
                />
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderFilterModalBody = () => {
    return (
      <>
        <ControlledTextField
          name="keyword"
          textLabel="Keyword"
          placeholder="Company,name,surname,email"
          control={control}
        />
        <ControlledTextField
          name="message"
          textLabel="Message"
          placeholder="message"
          control={control}
        />
        <div className="row">
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Create Date From"
              control={control}
              name="createDateFrom"
            />
          </div>
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Create Date To"
              control={control}
              name="createDateTo"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Delivery Date From"
              control={control}
              name="deliveryDateFrom"
            />
          </div>
          <div className="col-md-6">
            <ControlledDateField
              textLabel="Delivery Date To"
              control={control}
              name="deliveryDateTo"
            />
          </div>
        </div>

        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setShowFilterModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={handleSubmit(onFilter)}
            title="Submit"
          />
        </Box>
      </>
    );
  };

  return (
    <Sidebar pageTitle="Notification Reports">
      <PermissionWrapper unauthorizedComponent permission="PUSH_NOTIFICATIONS">
        <TableComponent
          filterBadge={
            <FilterBadge
              isClearFilterKey={isClearFilterKey}
              setIsClearFilterKey={setIsClearFilterKey}
              filterData={filterData}
              setFilterData={setFilterData}
              setDeletedFilterKey={setDeletedFilterKey}
            />
          }
          showFilterIcon
          onOpenModal={onOpenFilterModal}
          control={control}
          isShowSortingTypes
          isShowSortingNames
          sortOptions={[
            { name: "CreateDate", id: "createdAt" },
            { name: "DeliveryDate", id: "deliveryDate" },
          ]}
          loading={fetchLoading}
          TableBodyComponent={renderTableBody}
          showRightButton={false}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          columns={columns}
        />
      </PermissionWrapper>

      <AdminModalComponent
        children={renderFilterModalBody()}
        headerTitle="Filter Notifications"
        openModal={showFilterModal}
        closeModal={onCloseFilterModal}
      />
    </Sidebar>
  );
};

export default NotificationReportsContainer;
