import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoadingIndicator from "../../components/LoadingIndicator";
import SearchTextField from "../../components/SearchTextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addDonateCenter,
  getDonateCenter,
  editDonateCenter,
} from "../../services/donateEmails";
import AdminModalComponent from "../../components/AdminModalComponent";
import ControlledTextField from "../../components/ControlledTextField";
import AdminButtonComponent from "../../components/AdminButtonComponent";
const useStyles = makeStyles({
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});

interface paginationDataType {
  limit: number;
  total: number;
  totalPage: number;
  page: number;
}

interface donateCentersResponseDataType {
  address: string;
  createdAt: string;
  createdBy: string;
  id: string;
  location: {
    lat: string;
    long: string;
  };
  name: string;
  updatedAt: string;
}

interface schemaType {
  keyword: string;
  name: string;
  address: string;
  long: string;
  lat: string;
}

const searchSchema = yup.object().shape({
  keyword: yup.string(),
  name: yup.string().required("Name is required"),
  address: yup.string().required("Address is required"),
  long: yup.string().required("Longtitude is required"),
  lat: yup.string().required("Latitude is required"),
});

const columns = [
  { name: "Donation Center", id: "donationCenter" },
  { name: "Address", id: "address" },
  { name: "Long", id: "long" },
  { name: "Lat", id: "lat" },
  { name: "", id: "edit" },
  { name: "", id: "detail" },
];

const DonateCentersContainer: React.FC = () => {
  const classes = useStyles();

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(searchSchema),
    defaultValues: {
      keyword: "",
      name: "",
      address: "",
      long: "",
      lat: "",
    },
  });

  const watchKeyword = watch("keyword");

  const [queryParams, setQueryParams] = useState<paginationDataType>({
    limit: 1,
    total: 1,
    totalPage: 1,
    page: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const [openAddDonateCenterModal, setOpenAddDonateCenterModal] =
    useState<boolean>(false);
  const [openEditDonationCenterModal, setOpenEditDonationCenterModal] =
    useState<boolean>(false);
  const [donateCenters, setDonateCenters] = useState<
    donateCentersResponseDataType[]
  >([]);
  const [selectedRowDonateCenterData, setSelectedRowDonateCenterData] =
    useState<donateCentersResponseDataType>({
      address: "",
      createdAt: "",
      createdBy: "",
      location: {
        lat: "",
        long: "",
      },
      name: "",
      id: "",
      updatedAt: "",
    });
  const [refreshToken, setRefreshToken] = useState<number>(0);

  useEffect(() => {
    const getAllDonateCenters = async () => {
      setLoading(true);
      const response = await getDonateCenter(queryParams.page);

      setTimeout(() => {
        setLoading(false);
        if (response) {
          setDonateCenters(response.items);
          setQueryParams({
            page: response.metaData.page,
            limit: response.metaData.limit,
            total: response.metaData.total,
            totalPage: response.metaData.totalPage,
          });
        }
      }, 1000);
    };
    getAllDonateCenters();
  }, [queryParams.page, refreshToken]);

  const renderDonateCenterTableBody = () => {
    return (
      <>
        {donateCenters.map((center, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {center.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {center.address}
              </TableCell>
              <TableCell>{center.location.long}</TableCell>
              <TableCell>{center.location.lat}</TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    setOpenEditDonationCenterModal(true);
                    setSelectedRowDonateCenterData(center);
                  }}
                >
                  <Tooltip title="Edit donation center">
                    <i className="ri-edit-box-line"></i>
                  </Tooltip>
                </IconButton>
              </TableCell>
              <TableCell>
            <a href={`/donate-emails/${center.id}`}>
            <IconButton
                >
                  <Tooltip title="See Donate Emails">
                  <i className="ri-eye-line"></i>
                  </Tooltip>
                </IconButton>
            </a>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };
  

  //search fields

  const onSearch = async () => {
    setLoading(true);
    const response = await getDonateCenter(queryParams.page, watchKeyword);

    setTimeout(() => {
      setLoading(false);
      if (response) {
        setDonateCenters(response.items);
        setQueryParams({
          page: response.metaData.page,
          limit: response.metaData.limit,
          total: response.metaData.total,
          totalPage: response.metaData.totalPage,
        });
      }
    }, 1000);
  };
  const renderSearchField = () => {
    return (
      <>
        <SearchTextField
          onSearchClick={onSearch}
          control={control}
          name="keyword"
        />
      </>
    );
  };

  //add donate modal

  const onCloseAddDonateEmailModal = () => {
    setOpenAddDonateCenterModal(false);
  };

  const onAddNewCenter = async (data: any) => {
    const body = {
      address: data?.address,
      name: data?.name,
      lat: data?.lat,
      long: data?.long,
    };

    setLoading(true);
    const response = await addDonateCenter(body);

    setTimeout(async () => {
      setLoading(false);
      if (response) {
        reset({});
        setOpenAddDonateCenterModal(false);
        setRefreshToken(() => refreshToken + 1);
      }
    }, 1000);
  };

  const renderAddDonateCenterBody = () => {
    return (
      <>
        <div className="container ">
          <div className="row g-4">
            <ControlledTextField
              error={!!errors.name}
              helperText={errors.name?.message}
              textLabel="Name"
              control={control}
              name="name"
            />
            <ControlledTextField
              error={!!errors.address}
              helperText={errors.address?.message}
              textLabel="Address"
              control={control}
              name="address"
            />
            <ControlledTextField
              error={!!errors.lat}
              helperText={errors.lat?.message}
              textLabel="Latitude"
              control={control}
              name="lat"
            />
            <ControlledTextField
              error={!!errors.long}
              helperText={errors.long?.message}
              textLabel="Longtitude"
              control={control}
              name="long"
            />
          </div>
        </div>

        <div className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={() => setOpenAddDonateCenterModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={handleSubmit(onAddNewCenter)}
            title="Submit"
          />
        </div>
      </>
    );
  };

  //edit center modal

  useEffect(() => {
    reset({
      address: selectedRowDonateCenterData?.address,
      name: selectedRowDonateCenterData?.name,
      lat: selectedRowDonateCenterData?.location.lat,
      long: selectedRowDonateCenterData?.location.long,
    });
  }, [selectedRowDonateCenterData]);

  const onEditCenterInfo = async (data: any) => {
    const { keyword, ...rest } = data;

    const donateCenterId: string = selectedRowDonateCenterData?.id;

    setLoading(true);
    const response = await editDonateCenter(rest, donateCenterId);
    setLoading(false);
    setOpenEditDonationCenterModal(false);
    setSelectedRowDonateCenterData({
      address: "",
      createdAt: "",
      createdBy: "",
      location: {
        lat: "",
        long: "",
      },
      name: "",
      id: "",
      updatedAt: "",
    });
    setRefreshToken(() => refreshToken + 1);
  };

  const onCloseEditDonateCenterModal = () => {
    setOpenEditDonationCenterModal(false);
    setSelectedRowDonateCenterData({
      address: "",
      createdAt: "",
      createdBy: "",
      location: {
        lat: "",
        long: "",
      },
      name: "",
      id: "",
      updatedAt: "",
    });
  };

  const renderEditDonateCenterModalBody = () => {
    return (
      <>
        <div className="container ">
          <div className="row g-4">
            <ControlledTextField
              error={!!errors.name}
              helperText={errors.name?.message}
              textLabel="Name"
              control={control}
              name="name"
            />
            <ControlledTextField
              error={!!errors.address}
              helperText={errors.address?.message}
              textLabel="Address"
              control={control}
              name="address"
            />
            <ControlledTextField
              error={!!errors.lat}
              helperText={errors.lat?.message}
              textLabel="Latitude"
              control={control}
              name="lat"
            />
            <ControlledTextField
              error={!!errors.long}
              helperText={errors.long?.message}
              textLabel="Longtitude"
              control={control}
              name="long"
            />
          </div>
        </div>

        <div className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseEditDonateCenterModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={handleSubmit(onEditCenterInfo)}
            title="Submit"
          />
        </div>
      </>
    );
  };

  return (
    <Sidebar pageTitle="Donate Centers">
      {loading && <LoadingIndicator loading />}
      <TableComponent
        setOpenModal={setOpenAddDonateCenterModal}
        RenderSearchTextField={renderSearchField()}
        headerButtonTitle="+Add new donation center"
        TableBodyComponent={renderDonateCenterTableBody}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        showRightButton={true}
        columns={columns}
      />
      <AdminModalComponent
        children={renderAddDonateCenterBody()}
        headerTitle="Add New Donate Center"
        closeModal={onCloseAddDonateEmailModal}
        openModal={openAddDonateCenterModal}
      />
      <AdminModalComponent
        children={renderEditDonateCenterModalBody()}
        headerTitle="Edit Donation Center"
        closeModal={onCloseEditDonateCenterModal}
        openModal={openEditDonationCenterModal}
      />
    </Sidebar>
  );
};

export default DonateCentersContainer;
