import { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { Sidebar } from "../../components/Sidebar";
import CertificationRequestTable from "./CertificationRequestTable";
import CertificationTypesTable from "./CertificationTypesTable";
import { getCertificationTypes } from "../../services/company-certificates";
import { Helmet } from "react-helmet";
import PermissionWrapper from "../../components/PermissionWrapper";
import usePermissions from "../../hooks/usePermissions";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#E77228",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#E77228",
    },
  },
});

export interface Types {
  _id: string;
  title: string;
  imagePath: string;
  createdAt: string;
  updatedAt: string;
}

export interface QueryParams {
  page: number;
  total: number;
  totalPage: number;
  limit: number;
}

const CertificationRequestsContainer = () => {
  const classes = useStyles();

  const [value, setValue] = useState("request");

  const {hasPermission}=usePermissions()
  const [refresh, setRefresh] = useState<number>(0);
  const [certificateTypes, setCertificateTypes] = useState<Types[]>([]);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(!hasPermission("COMPANY_VIEW_DETAILS"))return 
    const getTypes = async () => {
      const response = await getCertificationTypes(1);

      if (response) {
        setCertificateTypes(response?.certificationTypes);
        setQueryParams({
          page: response?.pagination?.page,
          total: response?.pagination?.total,
          totalPage: response?.pagination?.totalPages,
          limit: 1,
        });
      }
    };
    getTypes();
  }, [refresh,hasPermission("COMPANY_VIEW_DETAILS")]);

  const renderTabs = () => {
    return (
      <>
        {value === "request" ? (
          <CertificationRequestTable certificationTypes={certificateTypes} />
        ) : (
          <CertificationTypesTable
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setRefresh={setRefresh}
            refresh={refresh}
            certificationTypes={certificateTypes}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Certification Requests | FruPro Admin Portal</title>
      </Helmet>
      <Sidebar pageTitle="Certification Requests">
        <PermissionWrapper unauthorizedComponent permission="COMPANY_VIEW_DETAILS">
        <Box sx={{ width: "100%" }}>
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab value="request" label="Certification Requests" />
            <Tab value="types" label="Certification Types" />
          </Tabs>
          <Box marginTop={2}>{renderTabs()}</Box>
        </Box>
        </PermissionWrapper>
      </Sidebar>
    </>
  );
};

export default CertificationRequestsContainer;
