import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import TableComponent from "../../components/TableComponent";
import LoadingIndicator from "../../components/LoadingIndicator";
import { getAdminLogs } from "../../services/admin-logs";
import { makeStyles } from "@mui/styles";
import { Box, TableCell, TableRow } from "@mui/material";
import { Helmet } from "react-helmet";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { AdminLogsState } from "./interface";
import moment from "moment";
import AdminModalComponent from "../../components/AdminModalComponent";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../components/ControlledTextField";
import FilterBadge from "../../components/FilterBadge";
import ControlledComboBox from "../../components/ControlledComboBox";
import { logTypes } from "./constants";
import usePermissions from "../../hooks/usePermissions";
import PermissionWrapper from "../../components/PermissionWrapper";
import { getBuyerSeller } from "../../services/common";

const schema = yup.object().shape({
  adminName: yup.string(),
  logType: yup.string(),
  offerCode: yup.string(),
  paymentCode: yup.string(),
  invoiceCode: yup.string(),
  companyName: yup.object(),
});

const useStyles = makeStyles({
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  colorRow: {
    background: "#F8F8F8",
  },
  normalRow: {
    background: "white",
  },
  details: {
    borderRadius: "10px!important",
    background: "#5F8CBA!important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
});

const columns = [
  { name: "Action Type", id: "actionType" },

  // { name: "Admin", id: "actionUser" },
  { name: "Message", id: "message" },
  { name: "Date", id: "date" },
  { name: "", id: "details" },
];

const AdminLogsContainer = () => {
  const { control, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const { hasPermission } = usePermissions();
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [deletedFilterKey, setDeletedFilterKey] = useState<string>("");
  const [companies, setCompanies] = useState<{ name: string; id: string }[]>(
    []
  );
  const [isClearFilterKey, setIsClearFilterKey] = useState<number>(0);
  const [logs, setLogs] = useState<AdminLogsState[]>([]);
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [filterData, setFilterData] = useState({});
  const [queryParams, setQueryParams] = useState({
    page: 1,
    total: 1,
    totalPage: 1,
    limit: 1,
  });

  const watchAdminName = watch("adminName");
  const watchLogType = watch("logType");
  const watchOfferCode = watch("offerCode");
  const watchInvoiceCode = watch("invoiceCode");
  const watchPaymentCode = watch("paymentCode");
  const watchCompany = watch("companyName");

  const onCloseFilterModal = () => {
    setOpenFilterModal(false);
  };
  const onOpenFilterModal = () => {
    setOpenFilterModal(true);
  };

  const onFilter = () => {
    setOpenFilterModal(false);
    setQueryParams({
      ...queryParams,
      page: 1,
    });
    setFilterData({
      invoiceCode: watchInvoiceCode ? watchInvoiceCode : undefined,
      paymentCode: watchPaymentCode ? watchPaymentCode : undefined,
      offerCode: watchOfferCode ? watchOfferCode : undefined,
      companyName: (watchCompany as any)?.name
        ? (watchCompany as any)?.name
        : undefined,
      adminName: watchAdminName ? watchAdminName : undefined,
      logType: watchLogType ? watchLogType : undefined,
    });
  };

  useEffect(() => {
    if (!hasPermission("ADMIN_LOGS_VIEW")) return;

    if (openFilterModal) {
      const getCompanies = async () => {
        setLoading(true);
        const companyRes = await getBuyerSeller();
        setLoading(false);

        if (companyRes) {
          setCompanies(
            companyRes?.items?.map((item: any) => ({
              name: item?.companyName,
              id: item?.id,
            }))
          );
        }
      };
      getCompanies();
    }
  }, [openFilterModal, hasPermission("ADMIN_LOGS_VIEW")]);

  useEffect(() => {
    const getLogs = async () => {
      setFetchLoading(true);
      const response = await getAdminLogs(queryParams.page, filterData);
      setFetchLoading(false);

      if (response) {
        setLogs(response?.data);
        setQueryParams({
          page: response?.pagination?.page,
          total: response?.pagination?.total,
          totalPage: response?.pagination?.totalPage,
          limit: response?.pagination?.limit,
        });
      }
    };
    getLogs();
  }, [queryParams.page, filterData]);

  useEffect(() => {
    setValue(`${deletedFilterKey}` as any, undefined);
  }, [deletedFilterKey]);

  const renderTableBody = () => {
    return (
      <>
        <Helmet>
          <title>Admin Logs | FruPro Admin Portal</title>
        </Helmet>
        {logs.map((log, index) => {
          return (
            <TableRow
              className={index % 2 !== 0 ? classes.colorRow : classes.normalRow}
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {log.type}
              </TableCell>

              {/* <TableCell component="th" scope="row">
                {log.admin[0].fullName}
              </TableCell> */}
              <TableCell component="th" scope="row">
                {log.message}
              </TableCell>
              <TableCell component="th" scope="row">
                {moment(log.createdAt).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell component="th" scope="row">
                <div className="d-flex justify-content-end">
                  <a
                    target="_blank"
                    href={`/admin-logs/${log._id}`}
                    rel="noreferrer"
                  >
                    <AdminButtonComponent
                      className={classes.details}
                      onClick={() => null}
                      title="Details"
                    />
                  </a>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const renderFilterModalBody = () => {
    return (
      <>
        <ControlledTextField
          control={control}
          name="adminName"
          textLabel="Admin User"
        />
        <div className="mt-2">
          <ControlledComboBox
            className={classes.comboBox}
            options={Object.values(logTypes)}
            control={control}
            name="logType"
            textLabel="Log Type"
          />
        </div>
        <div className="mt-2">
          <ControlledComboBox
            className={classes.comboBox}
            options={companies}
            control={control}
            name="companyName"
            textLabel="Company Name"
          />
        </div>
        <div className="mt-2">
          <ControlledTextField
            control={control}
            name="invoiceCode"
            textLabel="Invoice Code"
          />{" "}
        </div>
        <div className="mt-2">
          <ControlledTextField
            control={control}
            name="offerCode"
            textLabel="Offer Code"
          />{" "}
        </div>
        <div className="mt-2">
          <ControlledTextField
            control={control}
            name="paymentCode"
            textLabel="Payment Code"
          />{" "}
        </div>

        <Box className={classes.filterModalButtons}>
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={() => setOpenFilterModal(false)}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            className={classes.saveButton}
            onClick={onFilter}
            title="Submit"
          />
        </Box>
      </>
    );
  };
  return (
    <Sidebar pageTitle="Admin Logs">
      {loading && <LoadingIndicator loading />}
      <PermissionWrapper permission="ADMIN_LOGS_VIEW" unauthorizedComponent>
        <TableComponent
          loading={fetchLoading}
          filterBadge={
            <FilterBadge
              isClearFilterKey={isClearFilterKey}
              setIsClearFilterKey={setIsClearFilterKey}
              sellers={companies}
              buyers={companies}
              filterData={filterData}
              setFilterData={setFilterData}
              setDeletedFilterKey={setDeletedFilterKey}
            />
          }
          onOpenModal={onOpenFilterModal}
          showFilterIcon
          showRightButton={false}
          columns={columns}
          TableBodyComponent={renderTableBody}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      </PermissionWrapper>
      <AdminModalComponent
        headerTitle="Filter Logs"
        children={renderFilterModalBody()}
        closeModal={onCloseFilterModal}
        openModal={openFilterModal}
      />
    </Sidebar>
  );
};

export default AdminLogsContainer;
