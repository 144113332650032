import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DashboardState } from "../pages/Dashboard/interface";

type DashboardStateType = {
  dashboardData: DashboardState | null;
};

const initialState: DashboardStateType = {
  dashboardData: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardData: (state, action: PayloadAction<DashboardState>) => {
      state.dashboardData = action.payload;
    },
  },
});

export default dashboardSlice.reducer;

export const { setDashboardData } = dashboardSlice.actions;
