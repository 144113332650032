import React, { useState } from "react";
import ControlledTextField from "./ControlledTextField";
import { ControlledTextFieldTypes } from "./interfaces/ComponentInterfaces";

export const PasswordField: React.FC<ControlledTextFieldTypes> = ({
  name,
  control,
  textLabel,
  onKeyPress,
  placeholder,
  error,
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const onShowOrClosePassField = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <ControlledTextField
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      error={error}
      helperText={helperText}
      control={control}
      textLabel={textLabel}
      showPassword={showPassword}
      onShowOrClosePassField={onShowOrClosePassField}
      name={name}
      type={showPassword ? "text" : "password"}
      isPasswordTextField={true}
    />
  );
};
