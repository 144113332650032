import React from "react";
import { NumericFormat } from "react-number-format";

interface NumberFormatType {
  value: number | string;
  prefix?: any;
  style?: any;
}

const NumberFormatComponent: React.FC<NumberFormatType> = ({
  value,
  prefix,
  style,
}) => {
  return (
    <NumericFormat
      style={style}
      prefix={prefix}
      decimalScale={2}
      fixedDecimalScale
      displayType="text"
      thousandSeparator={true}
      value={value}
    />
  );
};

export default NumberFormatComponent;
